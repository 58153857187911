import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  IonLabel,
} from "@ionic/react";
import "./InternalTalentReferrals.css";
import back_arrow from "../../../assets/images/common/arrow-left-white.png";
import plus_circle_orange from "../../../assets/images/common/plus_circle_orange.png";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import EmptyScreen from "../../../push-components/EmptyScreen/EmptyScreen";
import "./accodion.js";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import {
  getInternalReferralsList,
} from "../../../data-services/edit-profile";

type ModalProps = {
  is_open_internal_referral: boolean;
  closeInternalReferral: Function;
};

const InternalTalentReferrals: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    is_open_internal_referral,
    closeInternalReferral,
  } = props;

  const [modelReferralsErrorMessage, setModelReferralsErrorMessage] =
  useState<any>("");
  const [modelReferrals, setModelReferrals] = useState<any>([]);

  const accordionHandler = (id: number) => {
    var acc: any = document.querySelector(`#first_${id}`);
    acc.classList.toggle("active");
    let panel: any = document.querySelector(`#panel_${id}`);
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  };



  const referralInit = async () => {
    let internal_ref_list: any = await getInternalReferralsList();
    if (internal_ref_list?.success) {
      setModelReferrals(internal_ref_list.data);
    } else if (internal_ref_list?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (internal_ref_list?.data?.errorCode == offlineErrorCode) {
      setModelReferralsErrorMessage(internal_ref_list?.data?.message);
      showToastMessage(internal_ref_list?.data?.message);
    } else if (internal_ref_list?.message) {
      setModelReferralsErrorMessage(internal_ref_list?.message);
      showToastMessage(internal_ref_list.message);
    } else {
      setModelReferralsErrorMessage(somethingWentWrongErrMsg);
      showToastMessage(somethingWentWrongErrMsg);
    }
  };

  useEffect(() => {
    (async () => {
      await referralInit();
    })();
  }, []);
  return (
    <IonModal isOpen={is_open_internal_referral} mode="ios">
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>Internal Talent Referrals</IonTitle>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeInternalReferral();
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="internal_talent_ref">
          {modelReferrals.length == 0 && (
            <EmptyScreen title={modelReferralsErrorMessage ? modelReferralsErrorMessage : "No Referrals"} />
          )}
          {modelReferrals.length > 0 &&
            modelReferrals.map((referral: any, index: number) => (
              <IonCard className="internal_talent_ref__card" key={index}>
                <IonGrid className="internal_talent_ref__card__grid">
                  <IonRow className="internal_talent_ref__card__grid__row">
                    <IonCol
                      size="2"
                      className="internal_talent_ref__card__left_col"
                    >
                      <IonAvatar className="internal_talent_ref__card__avatar">
                        <img src={referral.photo} alt="" />
                        <img
                          className="card__avatar_plus"
                          src={plus_circle_orange}
                          alt=""
                        />
                      </IonAvatar>
                    </IonCol>
                    <IonCol
                      size="10"
                      className="internal_talent_ref__accordion internal_talent_ref__card__right_col"
                      id={`first_${index}`}
                      onClick={() => accordionHandler(index)}
                    >
                      <h2 className="internal_talent_ref__card__name">
                        {referral.model_name}
                      </h2>
                      <IonLabel>{referral.email}</IonLabel>
                      <br />
                      <div className="internal_talent_ref__card__badges">
                        <IonBadge
                          className={
                            referral.status === "Pending"
                              ? "internal_talent_ref__badge internal_talent_ref__badge__blue"
                              : "internal_talent_ref__badge internal_talent_ref__badge__orange"
                          }
                        >
                          {referral.status}
                        </IonBadge>
                        {referral.points_earned != "" && (
                          <IonBadge className="customer_referral__badge">
                            {referral.points_earned}
                          </IonBadge>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                {referral.events.length > 0 && (
                  <div
                    className="internal_talent_ref__panel ref_event_list"
                    id={`panel_${index}`}
                  >
                    <br />
                    <IonText className="ref_event_list__text">
                      Booked/Applied Assignments
                    </IonText>
                    <IonGrid className="ref_event_list__grid">
                      {referral.events.map((event: any, counter: number) => (
                        <IonRow className="ref_event_list__row" key={counter}>
                          <IonCol size="2">
                            <img
                              className="ref_event_list__img"
                              src={event.photo}
                              alt=""
                            />
                          </IonCol>
                          <IonCol size="10">
                            <h2 className="ref_event_list__event_name">
                              {event.name}
                            </h2>
                            <IonLabel>{event.start_date}</IonLabel>
                          </IonCol>
                        </IonRow>
                      ))}
                    </IonGrid>
                  </div>
                )}
              </IonCard>
            ))}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default InternalTalentReferrals;

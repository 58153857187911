import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonPage,
  IonText,
  IonCheckbox,
  IonSpinner,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import "./NotificationFrequency.css";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { authActions } from "../../../store/auth";
import {
  getNotificationSettings,
  saveNotificationSettings,
} from "../../../data-services/edit-profile";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg
} from "../../../common/common";

const NotificationFrequency: React.FC = () => {
  const [notification_frequency, setNotificationFrequency] = useState<any>(0);
  const dispatch = useDispatch();
  const [showSpinnerTillApiResponse, setShowSpinnerTillApiResponse] =
    useState<boolean>(false);

  const [refreshComponent, setRefreshComponent] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      notification_frequency: 1,
    },
    mode: "onBlur",
  });

  const epInit = async () => {
    let notification_settings: any = await getNotificationSettings();
    if (!notification_settings?.success) {
      if (notification_settings?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (notification_settings?.data?.errorCode == offlineErrorCode) {
        showToastMessage(notification_settings?.data?.message);
      } else if (notification_settings?.message) {
        await showToastMessage(notification_settings.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    notification_settings = notification_settings.data;
    setNotificationFrequency(notification_settings.mns_frequency);
  };

  const onSubmitTalentEditMarket = async (event: any) => {
    event.preventDefault();
    setShowSpinnerTillApiResponse(true);

    let edit_profile_form: any = {
      mns_frequency: notification_frequency,
    };

    let update_api_response = await saveNotificationSettings(edit_profile_form);
    if (update_api_response?.success) {
      await showToastMessage("Successfully Updated!");
    } else if (update_api_response?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (update_api_response?.data?.errorCode == offlineErrorCode) {
      showToastMessage(update_api_response?.data?.message);
    } else if (update_api_response?.message) {
      showToastMessage(update_api_response.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    } 

    setShowSpinnerTillApiResponse(false);
    setRefreshComponent(!refreshComponent);
  };

  useEffect(() => {
    (async () => {
      await epInit();
    })();
  }, [refreshComponent]);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Frequency"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <form className="talent_rate__form" onSubmit={onSubmitTalentEditMarket}>
          <div className="noti-freqency-container">
            <IonCard className="noti-freqency">
              <div className="noti-freqency__text">
                <IonText>
                  How often would you like to notified about new opportunities?
                </IonText>
              </div>
              <IonItem className="noti-freqency__type">
                <IonCheckbox
                  mode="ios"
                  slot="start"
                  value="1"
                  checked={notification_frequency == 1}
                  onIonChange={(e) => {
                    e.detail.value as number;
                    e.detail.checked = true;
                    setNotificationFrequency(e.detail.value);
                  }}
                ></IonCheckbox>
                <IonText className="noti-freqency__type__label">Daily</IonText>
              </IonItem>
              <IonItem className="noti-freqency__type">
                <IonCheckbox
                  mode="ios"
                  slot="start"
                  value="2"
                  checked={notification_frequency == 2}
                  onIonChange={(e) => {
                    e.detail.value as number;
                    e.detail.checked = true;
                    setNotificationFrequency(e.detail.value);
                  }}
                ></IonCheckbox>
                <IonText className="noti-freqency__type__label">Weekly</IonText>
              </IonItem>

              <IonItem className="noti-freqency__type">
                <IonCheckbox
                  mode="ios"
                  slot="start"
                  value="3"
                  checked={notification_frequency == 3}
                  onIonChange={(e) => {
                    e.detail.value as number;
                    e.detail.checked = true;
                    setNotificationFrequency(e.detail.value);
                  }}
                ></IonCheckbox>
                <IonText className="noti-freqency__type__label">
                  Monthly
                </IonText>
              </IonItem>

              <IonItem className="noti-freqency__type no-border">
                <IonCheckbox
                  mode="ios"
                  slot="start"
                  value="4"
                  checked={notification_frequency == 4}
                  onIonChange={(e) => {
                    e.detail.value as number;
                    e.detail.checked = true;
                    setNotificationFrequency(e.detail.value);
                  }}
                ></IonCheckbox>
                <IonText className="noti-freqency__type__label">Never</IonText>
              </IonItem>
            </IonCard>
            {showSpinnerTillApiResponse ? (
              <IonButton className="form-group__button talent-rate__submit_btn">
                <IonSpinner name="dots"></IonSpinner>
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                mode="ios"
                type="submit"
                className="form-group__button noti-freqency__submit_btn"
              >
                Save
              </IonButton>
            )}
          </div>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default NotificationFrequency;

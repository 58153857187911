import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonContent,
  IonInput,
  IonLabel,
  IonPage,
  IonTitle,
  IonRange,
  IonSpinner,
} from "@ionic/react";

import "./NotificationMarket.css";
import locatin_icon from "../../../assets/images/signup/img/icon-location.png";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getTalentZipcode } from "../../../data-services/signup";
import { Toast } from "@capacitor/toast";
import { getUserCurrentPosition } from "../../../common/common";
import { authActions } from "../../../store/auth";
import {
  getNotificationSettings,
  saveNotificationSettings,
} from "../../../data-services/edit-profile";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg
} from "../../../common/common";

const NotificationMarket: React.FC = () => {
  const [distance_miles, setDistanceMiles] = useState<any>(0);
  const [talent_zipcode, setZipcode] = useState<any>("");
  // get current location btn
  const [getLocationBtn, setGetLocationBtn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [showSpinnerTillApiResponse, setShowSpinnerTillApiResponse] =
    useState<boolean>(false);

  const [refreshComponent, setRefreshComponent] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_zipcode: "",
    },
    mode: "onBlur",
  });

  const getTalentCurrentPosition = async () => {
    setGetLocationBtn(true);
    const { latitude, longitude }: any = await getUserCurrentPosition();
    if (latitude && longitude) {
      let talent_zipcode = await getTalentZipcode(latitude, longitude);
      if (talent_zipcode?.success) {
        setZipcode(talent_zipcode.data.zipcode);
      } else {
        if (talent_zipcode?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (talent_zipcode?.data?.errorCode == offlineErrorCode) {
          showToastMessage(talent_zipcode?.data?.message);
        } else if (talent_zipcode?.data?.message) {
          await showToastMessage(talent_zipcode.data.message);
        } else {
          showToastMessage("Unable to fetch zipcode");
        }
      }
    }
    setGetLocationBtn(false);
  };
  const epInit = async () => {
    let notification_settings: any = await getNotificationSettings();
    if (!notification_settings?.success) {
      if (notification_settings?.data?.errorCode == 0) {
        showToastMessage(notification_settings?.data?.message);
      } else {
        dispatch(authActions.logout());
        return;
      }
    }
    notification_settings = notification_settings.data;
    setDistanceMiles(
      notification_settings.mns_opp_distance
        ? notification_settings.mns_opp_distance
        : 0
    );
    setZipcode(notification_settings.mns_opp_zipcode);
  };

  const onSubmitTalentEditMarket = async (event: any) => {
    event.preventDefault();
    setShowSpinnerTillApiResponse(true);

    let edit_profile_form: any = {
      mns_opp_distance: distance_miles,
      mns_opp_zipcode: talent_zipcode,
    };

    let update_api_response = await saveNotificationSettings(edit_profile_form);
    if (update_api_response?.success) {
      await showToastMessage("Successfully Updated!");
    } else if (update_api_response?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (update_api_response?.data?.errorCode == offlineErrorCode) {
      showToastMessage(update_api_response?.data?.message);
    } else if (update_api_response?.message) {
      showToastMessage(update_api_response.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setShowSpinnerTillApiResponse(false);
    setRefreshComponent(!refreshComponent);
  };

  useEffect(() => {
    (async () => {
      await epInit();
    })();
  }, [refreshComponent]);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Distance "
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <form className="talent_rate__form" onSubmit={onSubmitTalentEditMarket}>
          <section className="markets-sec">
            <div>
              <div className="form-group">
                <IonLabel className="form-group__label">Zip code</IonLabel>
                <input
                  type="tel"
                  placeholder="Eg. 83971"
                  value={talent_zipcode}
                  className={
                    errors.talent_zipcode !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_zipcode", {
                    required: "This field is required.",
                  })}
                  onChange={(e) => {
                    setZipcode(e?.target?.value);
                  }}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="talent_zipcode"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="zip-code__use-location-btn">
                <IonButton
                  fill="clear"
                  onClick={getTalentCurrentPosition}
                  disabled={getLocationBtn}
                >
                  <img src={locatin_icon} alt="" /> Use current location
                </IonButton>
              </div>
            </div>

            <div>
              <div className="form-group">
                <IonLabel className="form-group__label">
                  Distance from Location
                </IonLabel>
                <IonTitle className="markets-sec__miles">
                  {distance_miles} miles away
                </IonTitle>

                <IonRange
                  className="markets-sec__miles_range"
                  value={distance_miles}
                  mode="ios"
                  min={0}
                  max={100}
                  onIonChange={(e) => {
                    e.detail.value as number;
                    setDistanceMiles(e.detail.value);
                  }}
                />
              </div>
            </div>
            {showSpinnerTillApiResponse ? (
              <IonButton className="form-group__button talent-rate__submit_btn">
                <IonSpinner name="dots"></IonSpinner>
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                mode="ios"
                type="submit"
                className="form-group__button markets-sec__submit-btn"
              >
                Save
              </IonButton>
            )}
          </section>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default NotificationMarket;

import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import map_notification from "../../assets/images/common/map_notification.svg";
import check_notification from "../../assets/images/common/check_notification.svg";
import { Network } from "@capacitor/network";
import "./AppNotification.css";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import { getAccountNotifications } from "../../data-services/home-api";
import {
  notificationCountHandler,
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
  noInternetMsg,
} from "../../common/common";
import PushModelAgreement from "../MyCalendar/PushModelAgreement/PushModelAgreement";
import AddVaccination from "../Home/SetupAccount/AddVaccination/AddVaccination";
import W9Form from "../Home/SetupAccount/W9Form/W9Form";
import W2Form from "../Home/SetupAccount/W2Form/W2Form";
import DirectDepositForm from "../../push-components/DirectDepositForm/DirectDepositForm";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import location_icon from "../../assets/images/common/no_notification.svg";
import close_x_circle from "../../assets/images/common/close_x_circle.png";
import InAppNotification from "./InAppNotification";
import { CapacitorHttp } from '@capacitor/core';
import { get } from "../../storage/storage";

import bell from "../../assets/images/common/bell-con.svg";
import exclamation_mark from "../../assets/images/common/exclamation-mark-in-a-circle-svgrepo-com.svg";
import RedeemRewardModal from "../MyRewards/RedeemRewardModal";
import ClaimRewardSuccessModal from "../MyRewards/ClaimRewardSuccessModal";

import { useHistory } from "react-router";

import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";

const AppNotification: React.FC = () => {
  const history = useHistory();
  const paperworkIcon = "/images/push_notifications_icons/paperwork.svg";
  const dispatch = useDispatch();
  const [notificationTypes, setNotificationTypes] = useState<any>([]);
  const [allhNotificationData, setAllhNotificationData] = useState<any>([]);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [notificationsLoaded, setNotificationsLoaded] =
    useState<boolean>(false);
  const [isOpenModalAgreement, setIsopenModalAgreement] =
    useState<boolean>(false);
  const [isOpenAddVaccine, setIsopenAddVaccine] = useState<boolean>(false);
  const [isOpenW9Form, setIsopenW9Form] = useState<boolean>(false);
  const [isOpenW2Form, setIsopenW2Form] = useState<boolean>(false);
  const [isOpenDespositForm, setIsOpenDespositForm] = useState<boolean>(false);
  const [isPushNotifDisabled, setIsPushNotifDisabled] =
    useState<boolean>(false);
  const [isPushNotifNotAllowed, setIsPushNotifNotAllowed] =
    useState<boolean>(false);
  const [isOpenRewardModal, setIsOpenRewardModal] = useState<boolean>(false);
  const [claimRewardSuccessMsg, setClaimRewardSuccessMsg] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });
  const [checkedAttributesValues, setCheckedAttributesValues] = useState<any>(
    {}
  );

  const openAppSettings = () => {
    /**
     * Note that the only supported option by Apple is "App".
     * Using other options might break in future iOS versions
     * or have your app rejected from the App Store.
     */
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  const checkPushNotifications = async () => {
    if (!process.env?.REACT_APP_IS_NATIVE) {
      return;
    }

    const permissions = await PushNotifications.checkPermissions();
    if (permissions.receive === "granted") {
      // Send the notification key to the push server
      registerForPush();
      setIsPushNotifDisabled(false);
      setIsPushNotifNotAllowed(false);
      return true;
    }

    setIsPushNotifDisabled(true);
    return false;
  };

  const registerForPush = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", async (token: Token) => {
      const url = `${process.env.REACT_APP_HP_API_URL}/userEvent/addFirebasePushNotificationToken`;
      const options = {
        url: encodeURI(url),
        headers: {
          "Content-Type": "application/json",
          token: await get("login_user_token"),
        },
        data: { pushNotificationToken: token?.value },
      };
      const response = await CapacitorHttp.post(options);
    });

    // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //     (error: any) => {
    //         alert('Error on registration: ' + JSON.stringify(error));
    //     }
    // );
  };

  const pushNotificationsEnable = async () => {
    if (!process.env?.REACT_APP_IS_NATIVE) {
      return;
    }

    const permissions = await PushNotifications.checkPermissions();

    if (permissions.receive === "granted") {
      registerForPush();
      setIsPushNotifDisabled(false);
      setIsPushNotifNotAllowed(false);
    } else {
      let res = await PushNotifications.requestPermissions();
      if (res.receive === "denied") {
        setIsPushNotifNotAllowed(true);
      } else {
        registerForPush();
      }
    }

    // setShowPushNotificationsPopup(false);
  };

  const account_model_notification = async () => {
    let account_notification = await getAccountNotifications();
    setNotificationsLoaded(true);
    if (account_notification?.success) {
      setAllhNotificationData(account_notification.data.notifications);

      let notification_types: any = Object.keys(
        account_notification.data.notifications
      );
      setNotificationTypes(notification_types);

      let noti_object = account_notification.data.notifications;
      let noti_count = await notificationCountHandler(noti_object);

      const pushNotifEnabled = await checkPushNotifications();
      if (Capacitor.isNativePlatform() && !pushNotifEnabled) {
        noti_count++;
      }

      setNotificationCount(noti_count);
    } else if (account_notification?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (account_notification?.data?.errorCode == offlineErrorCode) {
      showToastMessage(account_notification?.message);
    } else if (account_notification?.message) {
      showToastMessage(account_notification.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };
  const closeModalAgreement = () => {
    setIsopenModalAgreement(false);
    let container = document.getElementById("agreementFormFill");
    if (container !== null) {
      container.remove();
    }
    setNotificationCount(notificationCount - 1);
  };
  const closeAddVaccine = () => {
    setIsopenAddVaccine(false);
    let container = document.getElementById("covid19VaccineFormFill");
    if (container !== null) {
      container.remove();
    }
    setNotificationCount(notificationCount - 1);
  };
  const closeW9Form = () => {
    setIsopenW9Form(false);
    let container = document.getElementById("agreementFormFillRequest");
    if (container !== null) {
      container.remove();
    }
    setNotificationCount(notificationCount - 1);
  };
  const closeW2Form = () => {
    setIsopenW2Form(false);
    let container = document.getElementById("w2FormFill");
    if (container !== null) {
      container.remove();
    }
    setNotificationCount(notificationCount - 1);
  };
  const closeDepositForm = () => {
    setIsOpenDespositForm(false);
    let container = document.getElementById("directDepositFormFill");
    if (container !== null) {
      container.remove();
    }
    setNotificationCount(notificationCount - 1);
  };

  const markNoteAsSeen = async (note_id: number) => {
    let networkStatus = (await Network.getStatus()).connectionType;
    if (networkStatus !== "none") {
      let container = document.getElementById(
        "notification_" + note_id.toString()
      );
      if (container !== null) {
        container.remove();
      }
      showToastMessage("Notification cleared!");
      setNotificationCount(notificationCount - 1);
      let options: any = {
        url: encodeURI(
          `${process.env.REACT_APP_HP_API_URL}/userEvent/markNoteAsSeen`
        ),
        headers: {
          "Content-Type": "application/json",
          token: await get("login_user_token"),
        },
        data: {
          note_id: note_id,
        },
      };
      let endpoint_res = await CapacitorHttp.post(options);
    } else {
      showToastMessage(noInternetMsg);
    }
  };

  const markAppNotificationAsSeen = async (app_notification_id: number) => {
    let networkStatus = (await Network.getStatus()).connectionType;
    if (networkStatus !== "none") {
      let container = document.getElementById(
        "app_notification_" + app_notification_id.toString()
      );
      if (container !== null) {
        container.remove();
      }
      showToastMessage("Notification cleared!");
      setNotificationCount(notificationCount - 1);
      let options: any = {
        url: encodeURI(
          `${process.env.REACT_APP_HP_API_URL}/userEvent/markAppNotificationAsSeen`
        ),
        headers: {
          "Content-Type": "application/json",
          token: await get("login_user_token"),
        },
        data: {
          app_notification_id: app_notification_id,
        },
      };
      let endpoint_res = await CapacitorHttp.post(options);
    } else {
      showToastMessage(noInternetMsg);
    }
  };

  const closeRewardModal = (title: string = "", message: string = "") => {
    setIsOpenRewardModal(false);
    if (title && message) {
      setClaimRewardSuccessMsg({ title: title, message: message, show: true });
    }
  };

  const closeClaimdModal = async () => {
    setClaimRewardSuccessMsg({ title: "", message: "", show: false });
    let container = document.getElementById("elite_reward__" + talenRewardId);
    if (container !== null) {
      container.remove();
    }
    setNotificationCount(notificationCount - 1);
  };

  const redeemRewardHandler = (
    redeemId: string,
    checkedAttributesValues: any,
    targetReward: any
  ) => {
    setTalenRewardId(redeemId);
    setTargetReward(targetReward);
    //TODO
    checkedAttributesValues["reward" + targetReward.model_reward_id] = {};
    if (targetReward?.attributes?.length) {
      for (const attribute of targetReward.attributes) {
        checkedAttributesValues["reward" + targetReward.model_reward_id][
          "attribute" + attribute.attribute.ra_id
        ] = {};
        if (attribute?.values?.length) {
          for (const value of attribute.values) {
            checkedAttributesValues["reward" + targetReward.model_reward_id][
              "attribute" + attribute.attribute.ra_id
            ]["value" + value.marav_id] = false;
          }
        }
      }
    }

    setTargetAttributesValuesChecks(
      checkedAttributesValues["reward" + targetReward.model_reward_id]
    );
    setIsOpenRewardModal(true);
  };

  const [talenRewardId, setTalenRewardId] = useState<string>("");
  const [targetReward, setTargetReward] = useState<any>("");
  const [targetAttributesValuesChecks, setTargetAttributesValuesChecks] =
    useState<any>("");

  useEffect(() => {
    (async () => {
      await account_model_notification();
    })();
  }, []);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Notifications"
        counter={notificationCount}
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <div className="cus_notication">
          {isPushNotifDisabled && (
            <IonCard className="cus_notication__card">
              <IonGrid className="cus_notication__card__gird">
                {!isPushNotifNotAllowed && (
                  <IonRow>
                    <IonCol
                      size="1"
                      size-xs="2"
                      size-sm="1"
                      className="notication__card__gird__left_col"
                    >
                      <img src={bell} className="noti_icon_img" alt="" />
                    </IonCol>
                    <IonCol
                      size="11"
                      size-xs="10"
                      size-sm="11"
                      className="notication__card__gird__right_col"
                    >
                      <IonText>
                        🔔 <b>Never miss an update! </b>
                        <br />
                        Allow PUSH to notify you for nearby opportunities,
                        assignment updates, points earned, epic rewards, and
                        more.
                      </IonText>
                      <br />
                      <IonButton
                        expand="block"
                        mode="ios"
                        type="button"
                        onClick={() => pushNotificationsEnable()}
                      >
                        Enable Notifications
                      </IonButton>
                    </IonCol>
                  </IonRow>
                )}

                {isPushNotifNotAllowed && (
                  <IonRow>
                    <IonCol>
                      <div>
                        <div
                          style={{ textAlign: "center" }}
                          className="app-notif__no-push-allowed-to-ask"
                        >
                          <img src={exclamation_mark} />
                          <div>
                            Please update app permissions in phone settings to
                            push notifications.
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <br />
                            <IonButton
                              type="button"
                              // expand="block"
                              mode="ios"
                              onClick={openAppSettings}
                            >
                              Open Settings
                            </IonButton>

                            <div
                              onClick={() => checkPushNotifications()}
                              className="new-referral__items__list__no-contact__refresh"
                            >
                              <span>Refresh</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </IonCard>
          )}

          {notificationCount > 0 &&
            notificationTypes.map((type: any, index: any) => (
              <div key={index}>
                {type === "directDepositFormFill" &&
                  allhNotificationData[type] == 1 && (
                    <div id="directDepositFormFill">
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                To fill in your Direct Deposit please CLICK
                                HERE.
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                onClick={() => setIsOpenDespositForm(true)}
                              >
                                Complete
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {type === "w2FormFill" && allhNotificationData[type] == 1 && (
                  <div id="w2FormFill">
                    <IonCard className="cus_notication__card">
                      <IonGrid className="cus_notication__card__gird">
                        <IonRow>
                          <IonCol
                            size="1"
                            size-xs="2"
                            size-sm="1"
                            className="notication__card__gird__left_col"
                          >
                            <img
                              src={paperworkIcon}
                              className="noti_icon_img"
                              alt=""
                            />
                          </IonCol>
                          <IonCol
                            size="11"
                            size-xs="10"
                            size-sm="11"
                            className="notication__card__gird__right_col"
                          >
                            <IonText>
                              <div className="notication__card__gird__right_col__title">
                                <strong>Important paperwork is missing!</strong>
                              </div>
                              Please submit the required{" "}
                              <strong>PUSH Placement</strong> form to begin
                              setting up your account as a W-2 employee.
                            </IonText>
                            <br />
                            <IonButton
                              expand="block"
                              mode="ios"
                              type="button"
                              onClick={() => setIsopenW2Form(true)}
                            >
                              Complete
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </div>
                )}
                {(type === "agreementFormFillRequest" ||
                  type === "w9t4FormFill") &&
                  allhNotificationData[type].form_type == "W9" && (
                    <div id="agreementFormFillRequest">
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                <div className="notication__card__gird__right_col__title">
                                  <strong>Introducing PUSH App Payments!</strong>
                                </div>
                                <div className="notification__content"></div>
                                {type === "w9t4FormFill"
                                  ? "You have not yet completed your W9 form. After you complete you can start applying for opportunities. To complete form please CLICK HERE"
                                  : "Please re enter the information for your W9 form. After you complete you cam continue applying for opportunities. To complete form please CLICK HERE"}
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                onClick={() =>  history.push("/PaymentDetails")}
                              >
                                Complete
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {(type === "agreementFormFillRequest" ||
                  type === "w9t4FormFill") &&
                  allhNotificationData[type].form_type == "T4" && (
                    <div id="agreementFormFillRequest">
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                {type === "w9t4FormFill"
                                  ? "You have not yet completed your T4 form. After you complete you can start applying for opportunities. To complete form please CLICK HERE"
                                  : "Please re enter the information for your T4 form. After you complete you cam continue applying for opportunities. To complete form please CLICK HERE"}
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                onClick={() => setIsopenW9Form(true)}
                              >
                                Complete
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {type === "modelCityFill" &&
                  allhNotificationData[type] == 1 && (
                    <div>
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                Please fill in your State/Providence and your
                                Neareast City.
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                routerLink="/EditProfile"
                              >
                                Complete
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {type === "modelProfileFill" &&
                  allhNotificationData[type] == 1 && (
                    <div>
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                Updates to your profile are requested to make
                                updates
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                routerLink="/EditProfile"
                              >
                                Update
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {type === "covid19VaccineFormFill" &&
                  allhNotificationData[type] == 1 && (
                    <div id="covid19VaccineFormFill">
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                To fill in your vaccination status please CLICK
                                HERE.
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                onClick={() => setIsopenAddVaccine(true)}
                              >
                                Complete
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {type === "agreementFormFill" &&
                  allhNotificationData[type] == 1 && (
                    <div id="agreementFormFill">
                      <IonCard className="cus_notication__card">
                        <IonGrid className="cus_notication__card__gird">
                          <IonRow>
                            <IonCol
                              size="1"
                              size-xs="2"
                              size-sm="1"
                              className="notication__card__gird__left_col"
                            >
                              <img
                                src={check_notification}
                                className="noti_icon_img"
                                alt=""
                              />
                            </IonCol>
                            <IonCol
                              size="11"
                              size-xs="10"
                              size-sm="11"
                              className="notication__card__gird__right_col"
                            >
                              <IonText>
                                Please complete the online registration
                                paperwork. You will not be able to sign up for
                                events until you complete all the online
                                registrations steps. To go to agreement CLICK
                                HERE
                              </IonText>
                              <br />
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="button"
                                onClick={() => setIsopenModalAgreement(true)}
                              >
                                Complete
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </div>
                  )}
                {type === "assignmentCanCheckIn" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard className="cus_notication__card" key={index}>
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img src={map_notification} alt="" />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.items.text_ui,
                                      }}
                                    ></div>
                                  </IonText>
                                  <IonButton
                                    expand="block"
                                    mode="ios"
                                    type="button"
                                    routerLink={`AssignmentView/${item.eventToCheckIn.ue_id}`}
                                  >
                                    Check In
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "assignmentsToday" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard
                            className="cus_notication__card"
                            key={index}
                            routerLink={`AssignmentView/${item.event.ue_id}`}
                          >
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img src={map_notification} alt="" />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.items.text_ui,
                                      }}
                                    ></div>
                                  </IonText>
                                  <br />
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "assignmentsConfirmUpdate" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard className="cus_notication__card" key={index}>
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img src={map_notification} alt="" />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.items.text_ui,
                                      }}
                                    ></div>
                                  </IonText>

                                  {item?.items?.failed_test && (
                                    <p className="notication__card__gird__right_col__failed_test">
                                      {item.items.alert_text}
                                    </p>
                                  )}

                                  {!item?.items?.failed_test && (
                                    <IonButton
                                      expand="block"
                                      mode="ios"
                                      type="button"
                                      routerLink={`AssignmentView/${item.event.ue_id}`}
                                    >
                                      Confirm
                                    </IonButton>
                                  )}
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "assignmentsNotConfirmed" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard className="cus_notication__card" key={index}>
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img src={map_notification} alt="" />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.items.text_ui,
                                      }}
                                    ></div>
                                  </IonText>
                                  {item?.items?.failed_test && (
                                    <p className="notication__card__gird__right_col__failed_test">
                                      {item.items.alert_text}
                                    </p>
                                  )}

                                  {!item?.items?.failed_test && (
                                    <IonButton
                                      expand="block"
                                      mode="ios"
                                      type="button"
                                      routerLink={`AssignmentView/${item.event.ue_id}`}
                                    >
                                      Confirm
                                    </IonButton>
                                  )}
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "recapsFill" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard className="cus_notication__card" key={index}>
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img
                                    src={check_notification}
                                    className="noti_icon_img"
                                    alt=""
                                  />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.text_ui,
                                      }}
                                    ></div>
                                  </IonText>
                                  <IonButton
                                    expand="block"
                                    mode="ios"
                                    type="button"
                                    routerLink={`/AssignmentRecap/${item.ue_id}`}
                                  >
                                    {+item?.ue_is_in_store_report ? (<>Fill Report</>) : (<>Access Recap</>)}
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "eliteRewardToClaim" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard
                            id={"elite_reward__" + item.model_reward_id}
                            className="cus_notication__card "
                            key={index}
                          >
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img
                                    src={check_notification}
                                    className="noti_icon_img"
                                    alt=""
                                  />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <div className="notication__card__gird__right_col__title">
                                      <strong>{item.notification_title}</strong>
                                    </div>
                                    <div
                                      className="notification__content"
                                      dangerouslySetInnerHTML={{
                                        __html: item.notification_body,
                                      }}
                                    ></div>
                                  </IonText>
                                  <IonButton
                                    expand="block"
                                    mode="ios"
                                    type="button"
                                    onClick={() =>
                                      redeemRewardHandler(
                                        item.model_reward_id,
                                        checkedAttributesValues,
                                        item
                                      )
                                    }
                                  >
                                    Redeem
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "positiveFeedbacks" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard
                            id={"notification_" + item.mh_id}
                            className="cus_notication__card"
                            key={item.mh_id}
                          >
                            <IonGrid className="cus_notication__card__gird">
                              <IonRow>
                                <IonCol
                                  size="1"
                                  size-xs="2"
                                  size-sm="1"
                                  className="notication__card__gird__left_col"
                                >
                                  <img
                                    src={check_notification}
                                    className="noti_icon_img"
                                    alt=""
                                  />
                                </IonCol>
                                <IonCol
                                  size="11"
                                  size-xs="10"
                                  size-sm="11"
                                  className="notication__card__gird__right_col"
                                >
                                  <IonText>
                                    <img
                                      src={close_x_circle}
                                      className="notification__positive-feedback__closed"
                                      onClick={() => markNoteAsSeen(item.mh_id)}
                                    />
                                    <div
                                      className="notification__content"
                                      dangerouslySetInnerHTML={{
                                        __html: item.text_ui,
                                      }}
                                    ></div>
                                  </IonText>
                                  <br />
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
                {type === "appNotifications" && (
                  <div>
                    {allhNotificationData[type].length > 0 &&
                      allhNotificationData[type].map(
                        (item: any, index: any) => (
                          <IonCard
                            id={"app_notification_" + item.an_id}
                            className="cus_notication__card"
                            key={item.an_id}
                          >
                            <InAppNotification
                              id={item.an_id}
                              message={item.an_message}
                              link={item.an_route}
                              markAppNotificationAsSeen={
                                markAppNotificationAsSeen
                              }
                              icon_name={item.an_icon}
                              title={item?.an_title}
                            ></InAppNotification>
                          </IonCard>
                        )
                      )}
                  </div>
                )}
              </div>
            ))}
        </div>
        {notificationsLoaded && notificationCount == 0 && (
          <EmptyScreen title="No Notifications" icon={location_icon} />
        )}
        {!notificationsLoaded && (
          <div className="slider-card_spinner">
            <IonSpinner color="primary" name="crescent" />
          </div>
        )}
      </IonContent>
      <PushModelAgreement
        isOpen_modal_agreement={isOpenModalAgreement}
        closeModalAgreement={closeModalAgreement}
      />
      <AddVaccination
        isOpen_add_vaccine={isOpenAddVaccine}
        closeAddVaccine={closeAddVaccine}
      />
      <W9Form isOpen_w9_form={isOpenW9Form} closeW9Form={closeW9Form} />
      <W2Form isOpen_w2_form={isOpenW2Form} closeW2Form={closeW2Form} />
      <DirectDepositForm
        is_open_deposit_form={isOpenDespositForm}
        closeDepositForm={closeDepositForm}
      />
      {isOpenRewardModal && (
        <RedeemRewardModal
          rewardModalType="save_modal"
          isOpenRewardModal={isOpenRewardModal}
          closeRewardModal={closeRewardModal}
          talenRewardId={talenRewardId}
          targetReward={targetReward}
          targetAttributesValuesChecks={targetAttributesValuesChecks}
          eliteReward={true}
        />
      )}
      {claimRewardSuccessMsg.show && (
        <ClaimRewardSuccessModal
          isOpenClaimModal={claimRewardSuccessMsg.show}
          closeClaimdModal={closeClaimdModal}
          title_message={{
            title: claimRewardSuccessMsg.title,
            message: claimRewardSuccessMsg.message,
          }}
        />
      )}
    </IonPage>
  );
};

export default AppNotification;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import {
  IonContent,
  IonPage,
  IonButtons,
  IonGrid,
  IonIcon,
  IonItem,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
  IonHeader,
  IonBackButton,
  IonToolbar,
  IonTitle,
  IonText,
  IonAvatar,
  IonLabel,
  IonBadge,
} from "@ionic/react";
import rectangle_perpul from "../../assets/images/common/rectangle-perpul.svg";
import rectangle_blue from "../../assets/images/common/rectangle-blue.svg";
import rectangle_grey from "../../assets/images/common/rectangle-grey.svg";
import rectangle_orange from "../../assets/images/common/rectangle-orange.svg";
import chevron_right from "../../assets/images/common/chevron-right.svg";
import chevron_down from "../../assets/images/common/chevero-down.svg";

import { faStarHalf, faStar } from "@fortawesome/free-solid-svg-icons";

import { get } from "../../storage/storage";

import "./ProfileView.css";
import talent_profile from "../../assets/talent-profile.png";
import whilte_location from "../../assets/images/common/white-location-icon.svg";
import unsplash from "../../assets/images/common/unsplash.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addModelCrewMembers,
  getModelPublicProfileData,
} from "../../data-services/edit-profile";
import TalentPhotosModal from "./TalentPhotosModal";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import AgeHeightHire from "./AgeHeightHire";
const FaStarHalfIcon = <FontAwesomeIcon icon={faStarHalf} />;
const FaStarIcon = <FontAwesomeIcon icon={faStar} />;

// Declare Plyr as a global variable
declare global {
  interface Window {
    Plyr: any;
  }
}

const ProfileView: React.FC = () => {
  const dispatch = useDispatch();
  const [talentFullname, setTalentFullname] = useState<string>("");
  const [talentHeadshotImage, setTalentHeadshotImage] = useState<string>("");
  const [listedMessages, setListedMessages] = useState<any>(0);
  const [showMoreMessages, setShowMoreMessages] = useState<any>(0);
  const [talentProfilePhotos, setTalentProfilePhotos] = useState<any>([]);
  const [showProfilePhoto, setShowProfilePhoto] = useState<boolean>(false);
  // const [ProfilePhotoIndex, setProfilePhotoIndex] = useState<number>(0);
  const [modelData, setModelData] = useState<any>("");
  let showLimit = 10;

  const modal = useRef<HTMLIonModalElement>(null);
  const mySlides = useRef<any>(null);
  const slideOpts = {
    initialSlide: 1,
  };

  const dismiss = () => {
    modal.current?.dismiss();
  };

  const ratings: any = [1, 2, 3, 4, 5];

  const renderElement = (rating: any, index: any) => {
    if (index <= rating) {
      return (
        <i className="rating-star rating-highlight" key={index}>
          {FaStarIcon}
        </i>
      );
    } else if (rating % 1 != 0 && parseInt(rating) + 1 == index) {
      return (
        <i className="rating-star rating-highlight" key={index}>
          {FaStarHalfIcon}
        </i>
      );
    } else {
      return (
        <i className="rating-star" key={index}>
          {FaStarIcon}
        </i>
      );
    }
  };

  const getModelPublicProfileinfo = async () => {
    let experience_state_data: any = await getModelPublicProfileData();
    if (experience_state_data?.success && experience_state_data?.data) {
      setTalentProfilePhotos(experience_state_data.data.model.profile_photos);
      setModelData(experience_state_data.data.model);
      if (modelData != "") {
        if (modelData?.feedback?.length) {
          showMore();
        }
      }
    } else if (
      experience_state_data?.data?.errorCode == unauthorizedErrorCode
    ) {
      dispatch(authActions.logout());
      return;
    } else if (experience_state_data?.data?.errorCode == offlineErrorCode) {
      showToastMessage(experience_state_data?.data?.message);
    } else if (experience_state_data?.message) {
      showToastMessage(experience_state_data.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };

  const showMore = () => {
    let totalcount = modelData?.feedback?.length;
    let listmsg = listedMessages + 1;
    setListedMessages(listmsg);
    setShowMoreMessages(
      totalcount - listmsg * showLimit > showLimit
        ? showLimit
        : totalcount - listmsg * showLimit
    );
  };

  const showTalentProfilePhotos = () => {
    setShowProfilePhoto(true);
  };
  const closePhotoModal = () => {
    setShowProfilePhoto(false);
  };
  const addTalnetInCrew = async (talent_id: string) => {
    let add_api: any = await addModelCrewMembers(talent_id);
    if (add_api?.success) {
      await showToastMessage("Request saved!");
    } else {
      await showToastMessage(somethingWentWrongErrMsg);
    }
  };
  useEffect(() => {
    (async () => {
      await getModelPublicProfileinfo();

      let talentHeadshot = await get("login_user_headshot");
      if (talentHeadshot) {
        setTalentHeadshotImage(talentHeadshot);
      }
      let talent_last_name = await get("login_model_last_name");
      talent_last_name = talent_last_name ? talent_last_name : "";
      setTalentFullname(
        `${await get("login_model_first_name")} ${talent_last_name
          .toUpperCase()
          .charAt(0)}.`
      );

      if (window.Plyr) {
        // Get all video elements with a specific class or any other criteria
        const videoElements = document.querySelectorAll(
          ".talen-photos__videos video"
        );
        const videoArray = Array.from(videoElements);

        // Loop through each video element and initialize Plyr
        for (const videoElement of videoArray) {
          const player = new window.Plyr(videoElement, {
            controls: ["play", "volume", "fullscreen"],
            fullscreen: {
              enabled: true,
            },
          });
        }

        const talentIntroVideoElements = document.querySelectorAll(
          ".talent-intro-video-container__video"
        );
        const talentIntroVideoArray = Array.from(talentIntroVideoElements);

        // Loop through each video element and initialize Plyr
        for (const videoElement of talentIntroVideoArray) {
          const player = new window.Plyr(videoElement, {
            controls: ["play", "volume", "fullscreen", 'play-large'],
            fullscreen: {
              enabled: true,
            },
          });
        }
      }
    })();
  }, [talentFullname, talentHeadshotImage]);
  return (
    <IonPage>
      <IonHeader class="ion-no-border profile-header">
        <IonToolbar mode="ios" color="translucent">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" mode="md" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="profile-view">
          {modelData && (
            <div className="profile-view__container">
              <div>
                <div className="profile-level__talent_info">
                  <IonAvatar className="profile-level__talent_info__img">
                    <img
                      src={
                        modelData.model_headshot
                          ? modelData.model_headshot
                          : talent_profile
                      }
                      alt=""
                    />
                    {modelData.elite_talent && (
                      <span className="profile-level__talent_info__statu">
                        ELITE
                      </span>
                    )}
                  </IonAvatar>
                  <IonTitle className="profile-level__talent_info__name">
                    {talentFullname}
                  </IonTitle>
                  <IonText className="profile-level__talent_info__level">
                    {modelData.jobs_str}
                  </IonText>
                  <br />
                  <IonText className="profile-level__talent_info__location">
                    <IonIcon src={whilte_location} />
                    {modelData.location}
                  </IonText>
                </div>
                <AgeHeightHire
                  elite_talent={modelData.elite_talent}
                  age={modelData.age}
                  height={modelData.height}
                  hired={modelData.asignments_worked}
                />
              </div>
              {/* need to add model_user_id here  */}
              {/* <IonButton mode="ios" className="form-group__button"  expand="block" onClick={()=> addTalnetInCrew("123")} >ADD TO CREW</IonButton> */}
              <div className="quater-result">
                {modelData.quarter && (
                  <IonGrid className="quater-result__qauter">
                    <IonRow>
                      <IonCol size="4">
                        <div className="quater-result__qauter__left_sep"></div>
                      </IonCol>
                      <IonCol size="4" className="quater-result__qauter__text">
                        {modelData.quarter}
                      </IonCol>
                      <IonCol size="4">
                        <div className="quater-result__qauter__right_sep"></div>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}

                {modelData.elite_talent && (
                  <div className="quater-result__grid">
                    <IonGrid>
                      <IonRow>
                        {modelData.performance && (
                          <IonCol
                            size="6"
                            className="quater-result__grid__left_col"
                          >
                            <IonCard className="quater-result__grid__card">
                              <h1 className="quater-result__grid__card__title">
                                <IonIcon src={rectangle_perpul} />
                                Performance
                              </h1>

                              <div className="quater-result__grid__card__num">
                                <h2>{modelData.performance.score}</h2>
                                <IonLabel className="quater-result__grid__card__lable">
                                  out of {modelData.performance.total}
                                </IonLabel>
                              </div>
                            </IonCard>
                          </IonCol>
                        )}
                        {modelData.experience && (
                          <IonCol
                            size="6"
                            className="quater-result__grid__right_col"
                          >
                            <IonCard className="quater-result__grid__card">
                              <h1 className="quater-result__grid__card__title">
                                <IonIcon src={rectangle_blue} />
                                Experience
                              </h1>
                              <div className="quater-result__grid__card__num">
                                <h2>{modelData.experience.score}</h2>
                                <IonLabel className="quater-result__grid__card__lable">
                                  Criteria: {modelData.experience.total} jobs
                                </IonLabel>
                              </div>
                            </IonCard>
                          </IonCol>
                        )}
                      </IonRow>
                      <IonRow>
                        {modelData.cancellations && (
                          <IonCol
                            size="6"
                            className="quater-result__grid__left_col"
                          >
                            <IonCard className="quater-result__grid__card">
                              <h1 className="quater-result__grid__card__title">
                                <IonIcon src={rectangle_grey} />
                                Cancellations
                              </h1>

                              <div className="quater-result__grid__card__num">
                                <h2>{modelData.cancellations.score}</h2>
                                <IonLabel className="quater-result__grid__card__lable">
                                  Criteria: {modelData.cancellations.total}
                                </IonLabel>
                              </div>
                            </IonCard>
                          </IonCol>
                        )}
                        {modelData.proactive && (
                          <IonCol
                            size="6"
                            className="quater-result__grid__right_col"
                          >
                            <IonCard className="quater-result__grid__card">
                              <h1 className="quater-result__grid__card__title">
                                <IonIcon src={rectangle_orange} />
                                Proactive
                              </h1>

                              <div className="quater-result__grid__card__num">
                                <h2>{modelData.proactive.score}</h2>
                                <IonLabel className="quater-result__grid__card__lable">
                                  Criteria: {modelData.proactive.total}
                                </IonLabel>
                              </div>
                            </IonCard>
                          </IonCol>
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                )}
              </div>
              {modelData.measurements != null && (
                <div className="talent-measurements">
                  <IonGrid className="talent-measurements__grid">
                    <IonRow>
                      {modelData.measurements.hair && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Hair</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.hair}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                      {modelData.measurements.bust && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Chest</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.bust}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                      {modelData.measurements.eyes && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Eyes</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.eyes}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                      {modelData.measurements.waist && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Waist</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.waist}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                      {modelData.measurements.skin_tone && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Skin Tone</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.skin_tone}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                      {modelData.measurements.shoe && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Shoe</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.shoe}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                      {modelData.measurements.ethnicity && (
                        <IonCol size="6">
                          <IonCard className="talent-measurements__grid__item">
                            <IonLabel>Ethnicity</IonLabel>
                            <p className="measurement-item__value">
                              {modelData.measurements.ethnicity}
                            </p>
                          </IonCard>
                        </IonCol>
                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}

              {modelData.profile_photos.length > 0 && (
                <div className="talen-photos">
                  <IonItem>
                    <h1 slot="start" className="talen-photos__title">
                      Photos
                    </h1>
                    <div
                      slot="end"
                      className="talen-photos__see_all"
                      onClick={showTalentProfilePhotos}
                    >
                      See all <IonIcon src={chevron_right}></IonIcon>
                    </div>
                  </IonItem>
                  <Splide
                    options={{
                      fixedWidth: "140px",
                      arrows: false,
                      pagination: false,
                      perPage: 1,
                      gap: "12px",
                      perMove: 1,
                    }}
                  >
                    {modelData.profile_photos &&
                      modelData.profile_photos.map((itm: any, index: any) => (
                        <SplideSlide key={index}>
                          <div className="talen-photos__images">
                            <img src={itm.photo} alt="" />
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                </div>
              )}

              {modelData?.talent_intro_video && (
                <div className="talent-measurements">
                  <IonItem>
                    <h1 slot="start" className="talent-measurements__title">
                      Get to know {talentFullname}!
                    </h1>
                  </IonItem>
                  <p className="talent-intro-video-container">
                    <video className="talent-intro-video-container__video" controls >
                      <source
                        src={`${modelData.talent_intro_video.full_path}`}
                        type={`video/${modelData.talent_intro_video.extension}`}
                      />
                      Video not supported.
                    </video>
                  </p>
                </div>
              )}

              <div className="talent-about">
                <IonItem>
                  <h1 slot="start" className="talent-about__title">
                    About
                  </h1>
                </IonItem>
                <IonText className="talent-about__text">
                  {modelData.about}
                </IonText>
              </div>
              {modelData.experiences?.length > 0 && (
                <div className="talent-experiences">
                  <IonItem>
                    <h1 slot="start" className="talent-experiences__title">
                      Experiences
                    </h1>
                  </IonItem>
                  {modelData.experiences.map((data: any, index: number) => (
                    <div className="talent-experiences__name" key={index}>
                      <h1>{data.eventName}</h1>
                      <IonText>
                        {data.meCategory} <span className="exp-dot">.</span>{" "}
                        {data.meYear}
                        <span className="exp-dot">.</span> {data.meCity},{" "}
                        {data.meState}, {data.meCountry}
                      </IonText>
                    </div>
                  ))}
                </div>
              )}

              {modelData?.areas_of_interest?.length > 0 && (
                <div className="talent-interest">
                  <IonItem>
                    <h1 slot="start" className="talent-interest__title">
                      Areas of Interest
                    </h1>
                  </IonItem>
                  <div className="">
                    {modelData.areas_of_interest.map(
                      (data: any, index: number) => (
                        <IonBadge key={index}>{data}</IonBadge>
                      )
                    )}
                  </div>
                </div>
              )}
              {modelData?.videos?.length >0 && (
                <div className="talen-assign-photos">
                  <IonItem>
                    <h1 slot="start" className="talen-assign-photos__title">
                      Assignment Videos
                    </h1>
                  </IonItem>
                  <Splide
                    options={{
                      arrows: false,
                      pagination: false,
                      perPage: 3,
                      gap: "5px",
                      fixedWidth: "none",
                      perMove: 1,
                    }}
                  >
                    {modelData.videos.map((itm: any, index: any) => (
                        <SplideSlide key={index}>
                          <div className="talen-photos__images talen-photos__videos">
                            <video controls>
                              <source
                                src={`${itm.full_path}`}
                                type={`video/${itm.extension}`}
                              />
                              Video not supported.
                            </video>
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                </div>
              )}
              {modelData?.assignment_photos?.length > 0 && (
                <div className="talen-assign-photos">
                  <IonItem>
                    <h1 slot="start" className="talen-assign-photos__title">
                      Assignment Photos
                    </h1>
                    <div slot="end" className="talen-assign-photos__see_all">
                      See all <IonIcon src={chevron_right}></IonIcon>
                    </div>
                  </IonItem>
                  <Splide
                    options={{
                      arrows: false,
                      pagination: false,
                      perPage: 3,
                      gap: "5px",
                      fixedWidth: "none",
                      perMove: 1,
                    }}
                  >
                    {modelData.assignment_photos &&
                      modelData.assignment_photos.map(
                        (itm: any, index: any) => (
                          <SplideSlide key={index}>
                            <div className="talen-photos__images">
                              <img src={itm.photo} alt="" />
                            </div>
                          </SplideSlide>
                        )
                      )}
                  </Splide>
                </div>
              )}
              {modelData?.feedback?.length > 0 && (
                <div className="talent-review">
                  <IonItem>
                    <h1 slot="start" className="talent-review__title">
                      Client Feedback
                    </h1>
                  </IonItem>
                  <IonCard className="talent-review__overview">
                    <IonCardContent>
                      <div className="talent-review__overview__title">
                        {modelData?.feedback_average}
                      </div>
                      <div>
                        {ratings.map((itm: any, index: any) =>
                          renderElement(modelData?.feedback_average, itm)
                        )}
                      </div>
                      <IonLabel className="talent-review__overview__total_rating">
                        {modelData?.feedback_count} reviews
                      </IonLabel>
                    </IonCardContent>
                  </IonCard>
                  {modelData?.feedback.map((items: any, index: any) => (
                    <div key={index}>
                      {index < listedMessages * showLimit && (
                        <div className="talent-review__user_feedback">
                          <IonItem className="talent-review__user_feedback__user">
                            <IonAvatar slot="start">
                              <img src={items.customer_logo_path} alt="" />
                              <IonIcon src={unsplash}></IonIcon>
                            </IonAvatar>
                            <div className="talent-review__user_feedback__user__name">
                              <IonText>{items.customer_company}</IonText>
                              <div className="talent-review__user_feedback__user__name__rating">
                                {ratings.map((itm: any, index: any) =>
                                  renderElement(items.cfm_rate, itm)
                                )}
                                <IonLabel>{items.cfm_date}</IonLabel>
                              </div>
                            </div>
                          </IonItem>
                          <IonText className="talent-review__user_feedback__text">
                            {items.cfm_message}
                          </IonText>
                        </div>
                      )}
                    </div>
                  ))}
                  {showMoreMessages && (
                    <div className="talent-review__more" onClick={showMore}>
                      <IonText>{showMoreMessages} More Reviews</IonText>
                      <IonIcon src={chevron_down}></IonIcon>
                    </div>
                  )}
                </div>
              )}
              {modelData?.crew?.length > 0 && (
                <div className="talen-crew">
                  <IonItem>
                    <h1 slot="start" className="talen-crew__title">
                      Crew
                    </h1>
                    <div slot="end" className="talen-crew__see_all">
                      See all <IonIcon src={chevron_right}></IonIcon>
                    </div>
                  </IonItem>
                  <Splide
                    options={{
                      fixedWidth: "124px",
                      arrows: false,
                      pagination: false,
                      perPage: 1,
                      gap: "16px",
                      perMove: 1,
                    }}
                  >
                    {modelData.crew &&
                      modelData.crew.map((itm: any, index: any) => (
                        <SplideSlide key={index}>
                          <div className="talen-crew__images">
                            <div className="talen-crew__image">
                              <img src={itm.photo} alt="" />
                            </div>
                            <IonLabel className="talen-crew__image_label">
                              {itm.name}
                            </IonLabel>
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                </div>
              )}
            </div>
          )}
        </div>
      </IonContent>
      <TalentPhotosModal
        isOpen={showProfilePhoto}
        closeAction={closePhotoModal}
        photosArray={modelData.profile_photos}
      />
    </IonPage>
  );
};
export default ProfileView;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useStore } from "react-redux";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
} from "@ionic/react";
import { closeCircle, closeCircleOutline } from "ionicons/icons";
import ProfileFileuploaderSparklingIce from "../../push-components/ProfileFileuploader/ProfileFileuploaderSparklingIce";
import "./../../theme/theme-consumer.css";
import { useForm } from "react-hook-form";
import { talentFileUploader } from "../../data-services/assignmets";
import { getQrCodeInfo } from "../../common/common";

const PulseStepUploadPhoto: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState('');
  const [talentHeadshot, setTalentHeadshot] = useState<string>("");

  const handleFileChange = (event:any) => {
    const file = event?.target?.files[0];
    if (file) {
      setFileName(file.name);
    }
  }

  const showWidgetsToUpdateMainPhotos = (param: string) => {
    if (param === "headshot") {
      setTalentHeadshot("");
    }
  };

  const onRemoveCallback = useCallback(() => {
    setTalentHeadshot("");
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  
  const submitTestHandler = async (event: any) => {

    event.preventDefault();
    let model_photo = new FormData(event.target);
    model_photo.append("type", "profile_photos");

    console.log("model_photo", model_photo);
    // try {
      let file_upload: any = await talentFileUploader(model_photo, "");

    //   if (file_upload?.success) {
    //     let update_api_response = await updateTalenProfiletData({
    //       model: edit_profile_form,
    //     });
    //     if (update_api_response?.success) {
    //       await showToastMessage("Successfully Updated!");
    //       if (update_api_response?.data?.model_account_level_changed) {
    //         await invalidateOppsCache();
    //       }
    //     } else if (
    //       update_api_response?.data?.errorCode == unauthorizedErrorCode
    //     ) {
    //       dispatch(authActions.logout());
    //       return;
    //     } else if (update_api_response?.data?.errorCode == offlineErrorCode) {
    //       showToastMessage(update_api_response?.data?.message);
    //     } else if (update_api_response?.message) {
    //       showToastMessage(update_api_response.message);
    //     } else {
    //       showToastMessage(somethingWentWrongErrMsg);
    //     }
    //   } else if (file_upload?.data?.errorCode == unauthorizedErrorCode) {
    //     dispatch(authActions.logout());
    //     return;
    //   } else if (file_upload?.data?.errorCode == offlineErrorCode) {
    //     showToastMessage(file_upload?.data?.message);
    //   } else if (file_upload?.message) {
    //     showToastMessage(file_upload.message);
    //   } else {
    //     showToastMessage(somethingWentWrongErrMsg);
    //   }
    //   setShowSpinnerTillApiResponse(false);
    //   setUploadProgress(0);
    //   setRefreshComponent(!refreshComonent);
    // } catch (e: any) {
    //   if (e.message == unauthorizedErrMsg) {
    //     dispatch(authActions.logout());
    //     return;
    //   } else {
    //     showToastMessage(somethingWentWrongErrMsg);
    //     await logError(
    //       "Edit Profile - onSubmitTalentEditProfile - Exception thrown",
    //       "Talent App - Edit Profile",
    //       JSON.stringify(e.message)
    //     );
        
    //   }
    //   setShowSpinnerTillApiResponse(false);
    //   setUploadProgress(0);
    //   setRefreshComponent(!refreshComonent);
    // }

    history.push('/PulseStepShare');
  };

  const [qrDetails, setQrDetails] = useState<any>();
  const populateQrCodeInfo = () => {
    let qrDetails = getQrCodeInfo();
    setQrDetails(qrDetails);
  }

  useEffect(() => {
    populateQrCodeInfo();
  }, []);


  return (
    <IonPage>

      <IonContent className={`lay__talent-signup--container--${qrDetails?.theme} step-upload-photo`}>
        {typeof qrDetails != 'undefined' && 
          <div className={`lay__talent-signup lay__talent-signup--sparklingice1 step-upload-photo--final-step`}>
            <div className="lay__logo">
              <img src="/images/cons/cp1/logo--3.png" />
            </div>

            <div className="lay__lt">
              <img style={{height: '100px'}}  src="/images/cons/cp1/icon-photo-l.png" />
            </div>

            <div className="lay__rt" style={{top: '200px'}}>
              <img style={{height: '100px'}} src="/images/cons/cp1/icon-photo-r.png" />
            </div>

            <div className="lay__bottom-bar">
              <img src="/images/cons/cp1/bottom-bar--greenbg.png" />
            </div>

            {true && 
              <div className="sn__id1">
                <div className="talent-signup__section-title">
                  <img style={{marginTop: '25px'}} src="/images/cons/cp1/step-upload-photo1.png" />
                </div>
              </div>
            }

            {false && <form id="psup__form" onSubmit={submitTestHandler}>
              <div className="">
                <ProfileFileuploaderSparklingIce
                  name="head_shot"
                  limit="1"
                  id="model_headshot"
                  cropp_ratio="headshot"
                  files={[]}
                  click={onRemoveCallback}
                />
              </div>

              <div className="">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="submit"
                  className="form-group__button upload-photo__submit-btn"
                  // onClick={submitTestHandler}
                >
                  Submit
                </IonButton>
              </div>
            </form>}
          </div>
        }
      </IonContent>
      
    </IonPage>
  );
};

export default PulseStepUploadPhoto;
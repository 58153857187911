import axios from "axios";
import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import { Network } from "@capacitor/network";
import { noInternetResponse, unauthorizedResponse, setTalnetInfoInReduxStore } from "../common/common";
import { invalidateOppsCache } from "./opportunities";

export const getRequiredSignUpInformation = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/users/getRequiredSignUpInformation`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let response = await CapacitorHttp.get(config);
    return response.data.data;
  } else {
    return noInternetResponse;
  }
};

export const getStatesForSignUp = async (country: string = "USA") => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let config: any = {
      method: "GET",
      url: `${process.env.REACT_APP_HP_API_URL}/users/getStatesForSignUp?country=${country}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let state_list = await axios(config);
    return state_list.data.data;
  } else {
    return noInternetResponse;
  }
};

export const isMobileNumberValid = async (
  phone_no: string,
  token: string = ""
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    phone_no = phone_no.replace(/\s/g, "");
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/users/isMobileNumberValid?mobile=${phone_no}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let is_valid = await CapacitorHttp.get(config);
    return is_valid.data;
  } else {
    return noInternetResponse;
  }
};

export const checkIfProfileUrlIsAvailable = async (
  display_name: string,
  token: string = ""
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/checkIfProfileUrlIsAvailable?model_site_url=${display_name}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let is_valid = await CapacitorHttp.get(config);
    return is_valid.data;
  } else {
    return noInternetResponse;
  }
};

export const isEmailAddressValid = async (
  email: string,
  token: string = ""
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/users/isEmailAddressValid?email=${email}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let is_valid = await CapacitorHttp.get(config);
    return is_valid.data;
  } else {
    return noInternetResponse;
  }
};

export const sendSignupDataToApi = async (talent_info: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const options: any = {
      method: "post",
      url: `${process.env.REACT_APP_HP_API_URL}/users/signUpModelConsumer`,
      data: talent_info,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let api_response = await axios(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

export const updateTalenProfiletData = async (talent_info: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelProfileData`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: talent_info,
    };
    let singup_api_reponse = await CapacitorHttp.post(options);
    if(singup_api_reponse?.data?.success && singup_api_reponse?.data?.data?.model) {
      setTalnetInfoInReduxStore(singup_api_reponse?.data?.data?.model);
      await invalidateOppsCache();
    }
    return singup_api_reponse.data;
  } else {
    return noInternetResponse;
  }
};
export const getPoliciesContent = async (policy_type: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/users/${policy_type}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let is_valid = await CapacitorHttp.get(config);
    return is_valid.data;
  } else {
    return noInternetResponse;
  }
};

export const getTalentZipcode = async (latitude: number, longitude: number) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/users/getZipcode?latitude=${latitude}&longitude=${longitude}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let response = await CapacitorHttp.get(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const getSocialMediaPlatforms = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: `${
        process.env.REACT_APP_HP_API_URL
      }/userEvent/getInfluencerSocialMediaPlatforms?token=${authToken}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let api_reponse = await CapacitorHttp.get(options);
    if (!api_reponse?.data?.success) {
      return api_reponse.data;
    }

    let platforms = api_reponse.data.data.platforms;

    let selected_platform: any = {};
    for (let index = 0; index < platforms.length; index++) {
      let element = platforms[index];

      if (element.msmp_url) {
        element.status = "connected";
      } else {
        element.status = "disconnected";
      }
      if (element.smp_name === "Instagram") {
        selected_platform.instagram = { ...element };
      }
      if (element.smp_name === "Facebook") {
        selected_platform.facebook = { ...element };
      }
      if (element.smp_name === "Twitter") {
        selected_platform.twitter = { ...element };
      }
      if (element.smp_name === "YouTube") {
        selected_platform.youtube = { ...element };
      }
      if (element.smp_name === "LinkedIn") {
        selected_platform.linkedin = { ...element };
      }
      if (element.smp_name === "Pinterest") {
        selected_platform.pinterest = { ...element };
      }
      if (element.smp_name === "Periscope") {
        selected_platform.periscope = { ...element };
      }
      if (element.smp_name === "Snapchat") {
        selected_platform.snapchat = { ...element };
      }
      if (element.smp_name === "Yelp") {
        selected_platform.yelp = { ...element };
      }
      if (element.smp_name === "TikTok") {
        selected_platform.tiktok = { ...element };
      }
      if (element.smp_name === "Threads") {
        selected_platform.threads = { ...element };
      }
    }
    return {
      success: true,
      orignal_array: api_reponse.data.data.platforms,
      changed_array: selected_platform,
    };
  } else {
    return noInternetResponse;
  }
};

export const saveInfluencerSocialMediaPlatforms = async (platforms: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let create_obj: any = { platforms: {} };
    for (let index = 0; index < platforms.length; index++) {
      const element = platforms[index];

      create_obj.platforms[`${element.smp_id}`] = {
        msmp_url: element.msmp_url,
        msmp_followers_number: element.msmp_followers_number,
      };
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveInfluencerSocialMediaPlatforms`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: create_obj,
    };
    let api_reponse = await CapacitorHttp.post(options);
    return api_reponse.data;
  } else {
    return noInternetResponse;
  }
};

export const generateTextVerificationCode = async (phone_no: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    var form_data = new FormData();
    form_data.append("phone", phone_no);
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/users/generateTextVerificationCode`,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    let state_list = await axios(config);
    return state_list.data;
  } else {
    return noInternetResponse;
  }
};

export const checkTextVerificationCode = async (
  phone_no: string,
  otp: string
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    var form_data = new FormData();
    form_data.append("phone", phone_no);
    form_data.append("code", otp);
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/users/checkTextVerificationCode`,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    let state_list = await axios(config);
    return state_list.data;
  } else {
    return noInternetResponse;
  }
};

export const generateEmailVerificationCode = async (email: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    var form_data = new FormData();
    form_data.append("email", email);
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/users/generateEmailVerificationCode`,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    let state_list = await axios(config);
    return state_list.data;
  } else {
    return noInternetResponse;
  }
};

export const checkEmailVerificationCode = async (
  email: string,
  otp: string
) => {
  
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    var form_data = new FormData();
    form_data.append("email", email);
    form_data.append("code", otp);
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/users/checkEmailVerificationCode`,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    let state_list = await axios(config);
    return state_list.data;
  } else {
    return noInternetResponse;
  }
};

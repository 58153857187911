import { set, get } from "../storage/storage";
import { Toast } from "@capacitor/toast";
import { Geolocation } from "@capacitor/geolocation";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Contacts } from "@capacitor-community/contacts";
import { isPlatform } from "@ionic/react";
import { getAndCacheTalentThumb } from "../data-services/home-api";
import { useDispatch } from "react-redux";
import { getAppVersion } from "../data-services/general";

const blazeface = require("@tensorflow-models/blazeface");

const unauthorizedErrorCode = 401;
const offlineErrorCode = 0;
const somethingWentWrongErrMsg = "Something went wrong";
const noInternetMsg = "Please check your internet connection and try again!";
const unauthorizedErrMsg = "Error: Request failed with status code 401";
const noInternetResponse = {
  success: false,
  message: noInternetMsg,
  data: { errorCode: offlineErrorCode, message: noInternetMsg },
};
const unauthorizedResponse = {
  success: false,
  message: "Unauthorized",
  data: { errorCode: unauthorizedErrorCode, message: "Unauthorized" },
};

const PHOTOS_ONLY_RECAP_ID = 280;

const showToastMessage = async (msg: any, showAlertOnError?: false) => {
  await Toast.show({
    text:
      msg.includes("CDbCommand") || msg.includes("SQL")
        ? somethingWentWrongErrMsg
        : msg,
  });
};
const getUserContactList = async () => {
  try {
    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      return { granted: false };
    }

    // New method
    const projection = {
      // Specify which fields should be retrieved.
      name: true,
      phones: true
    };
  
    const contact_list = await Contacts.getContacts({
      projection,
    });

    return { granted: true, contacts: contact_list };

    // End of new method

    // let is_permited = await Contacts.getPermissions();
    // if (is_permited?.granted) {
    //   let contact_obj = await Contacts.getContacts();
    //   let contact_list = contact_obj.contacts;
    //   return { granted: true, contacts: contact_list };
    // } else {
    //   return { granted: false };
    // }
  } catch (error) {
    console.error(error);
    return { granted: false };
  }
};
// push all contact numbers of particular contact in single array
const sortUserContactNumbers = (contact_list: any) => {
  let talent_contact: any = [];
  for (let index = 0; index < contact_list.length; index++) {
    let a_contact = contact_list[index];
    
    // emails
    let a_cotact_emails = [];
    for (let e = 0; e < a_contact?.emails?.length; e++) {
      const email = a_contact.emails[e];
      a_cotact_emails.push(email.address);
    }

    // phone numbers
    let a_contact_phones = [];
    for (let p = 0; p < a_contact?.phones?.length; p++) {
      const phone = a_contact.phones[p];
      a_contact_phones.push(phone.number);
    }

    talent_contact.push({
      id: a_contact.contactId,
      id2: index + 1,
      name: a_contact?.name?.given + ' ' + a_contact?.name?.family,
      phoneNumbers: a_contact_phones,
      emails: a_cotact_emails,
    });
  }
  return talent_contact;
};
const getUserCurrentPosition = async () => {
  let check_locattion_permi: any = {};
  if (isPlatform("mobileweb") || isPlatform("desktop")) {
    check_locattion_permi = { location: "granted" };
  } else {
    check_locattion_permi = await Geolocation.checkPermissions();
  }

  let coordinates: any;
  let permision: any;
  // Warning: check_locattion_permi.location appears as "granted" event when denying access
  if (check_locattion_permi.location === "granted") {
    try {
      coordinates = await Geolocation.getCurrentPosition();
      return {
        latitude: coordinates.coords.latitude,
        longitude: coordinates.coords.longitude,
      };
    } catch (e: any) {
      if (e?.code == 1) {
        alert("Please enable location access in your device settings.");
        showToastMessage(
          "Please enable location access in your device settings."
        );
      } else {
        alert(somethingWentWrongErrMsg);
        showToastMessage(somethingWentWrongErrMsg);
      }
      return false;
    }
  } else {
    permision = await Geolocation.requestPermissions();
    if (permision.location === "granted") {
      try {
        coordinates = await Geolocation.getCurrentPosition();
        return {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };
      } catch (e: any) {
        if (e?.code == 1) {
          alert("Please enable location access in your device settings.");
          showToastMessage(
            "Please enable location access in your device settings."
          );
        } else {
          alert(somethingWentWrongErrMsg);
          showToastMessage(somethingWentWrongErrMsg);
        }
        return false;
      }
    } else {
      alert("Please enable location access in your device settings.");
      showToastMessage(
        "Please enable location access in your device settings."
      );
      return false;
    }
  }
};

/**
 * Get the Location only if it's been enabled
 * @returns 
 */
const getUserCurrentPositionIfGranted = async () => {
  try {
      const permissionStatus = await Geolocation.checkPermissions();
      if (permissionStatus.location === "granted") {
          const coordinates = await Geolocation.getCurrentPosition();
          return {
              latitude: coordinates.coords.latitude,
              longitude: coordinates.coords.longitude,
          };
      } else {
          return false;
      }
  } catch (error) {
      return false;
  }
};


const getUserCameraPhoto = async (img_type: string = "uri") => {
  let cameraRequest: any;
  let image: any;
  let image_obj: any = {
    quality: 90,
    allowEditing: false,
    saveToGallery: false,
    source: CameraSource.Camera,
    resultType: CameraResultType.Uri,
  };
  if (img_type === "base64") {
    image_obj.resultType = CameraResultType.Base64;
  }
  let cameraPermission: any = {};
  if (isPlatform("mobileweb") || isPlatform("desktop")) {
    cameraPermission = { camera: "granted" };
  } else {
    cameraPermission = await Camera.checkPermissions();
  }

  if (cameraPermission.camera === "granted") {
    try {
      image = await Camera.getPhoto(image_obj);
      return image;
    } catch (error: any) {
      // Use cancelled the camera
      return false;
    }
  } else {
    cameraRequest = await Camera.requestPermissions();
    if (cameraRequest.camera === "granted") {
      try {
        image = await Camera.getPhoto(image_obj);
        return image;
      } catch (error: any) {
        // Use cancelled the camera
        return false;
      }
    } else {
      alert("Please enable camera access in your device settings.");
      showToastMessage("Please enable camera access in your device settings.");
      return false;
    }
  }
};

const setTalnetInfoInReduxStore = async (user_details: any) => {
  await set("login_user_id", user_details.user_id);
  await set("login_user_name", user_details.user_name);
  await set("login_user_type", user_details.user_type);
  await set("login_user_status", user_details.user_status);
  await set("login_user_token", user_details.user_token);
  await set("login_model_first_name", user_details.modelFirstName);
  await set("login_model_last_name", user_details.modelLastName);
  await set("login_model_email", user_details.model_email);
  await set("login_model_phone", user_details.model_phone);
  await set("login_model_gender", user_details.model_gender);
  await set("login_model_address", user_details.model_address);
  await set("login_model_apartment_number", user_details.model_apartment_number);
  await set("login_model_country", user_details.modelCountry);
  await set("login_model_state", user_details.model_state);
  await set("login_model_city", user_details.model_city2);
  await set("login_model_zipcode", user_details.model_zipcode);

  await set("login_model_w2_status", user_details.model_w2_status);
  await set("login_model_is_elite", user_details.is_elite);

  await set(
    "login_model_is_brand_ambassador",
    user_details.is_brand_ambassador
  );
  await set("login_model_is_model", user_details.is_model);
  await set("login_model_is_influencer", user_details.is_influencer);
  await set("login_model_account_level_str", user_details.account_level_str);
  await set("login_faq_links", user_details.faq_links);
  await set("login_page_links", user_details.pages_links);

  await set("login_faq_info_buttons", user_details.faq_info_buttons);

  await set(
    "none_primary_address",
    user_details.selected_location_data.none_primary_address
  );
  await set("latitude", user_details.selected_location_data.latitude);
  await set("longitude", user_details.selected_location_data.longitude);
  await set(
    "selected_loction",
    user_details.selected_location_data.selected_loction
  );
  await set("you_are_in", user_details.selected_location_data.you_are_in);
  await set(
    "model_mobile_first_time_slides_shown",
    user_details.model_mobile_first_time_slides_shown
  );
  await set(
    "model_p_card_agreement_confirmed_datetime",
    user_details?.model_p_card_agreement_confirmed_datetime
  );
  await set(
    "model_p_card_agreement_confirmed",
    user_details?.model_p_card_agreement_confirmed
  );

  await set(
    "model_tipalti_payment_available",
    user_details?.model_tipalti_payment_available
  );

  await set(
    "login_model_tipalti_payment_status",
    user_details?.model_tipalti_payment_status
  );

  await set(
    "login_model_show_insta_book_intro",
    user_details?.model_show_insta_book_intro
  );

  await set(
    "login_expand_tapp_insta_book_preview",
    user_details?.expand_tapp_insta_book_preview
  );

  await set(
    "login_model_latitude",
    user_details?.model_latitude
  );

  await set(
    "login_model_longitude",
    user_details?.model_longitude
  );


  await set(
    "login_defaultExternalReferralPoints",
    user_details?.defaultExternalReferralPoints
  );

  await set(
    "login_has_intro_video",
    user_details?.has_intro_video
  );
  

  // set  model headshot in local storage
  if (typeof user_details !== "undefined" && user_details?.model_headshot) {
    await getAndCacheTalentThumb(user_details?.model_headshot);
  }
  return {
    is_authenticated: true,
    user_id: user_details.user_id,
    user_name: user_details.user_name,
    user_password: user_details.user_password,
    user_reg_date: user_details.user_reg_date,
    user_lastlogin_datetime: user_details.user_lastlogin_datetime,
    user_comes_from: user_details.user_comes_from,
    user_type: user_details.user_type,
    user_status: user_details.user_status,
    user_acquired: user_details.user_acquired,
    user_flag_account: user_details.user_flag_account,
    user_token: user_details.user_token,
    user_token_expire: user_details.user_token_expire,
    user_confirm_hash: user_details.user_confirm_hash,
    user_is_contact_id: user_details.user_is_contact_id,
    user_one_signal_token: user_details.user_one_signal_token,
    modelFirstName: user_details.modelFirstName,
    modelHasToFillPaperwork: user_details.modelHasToFillPaperwork,
    modelCountry: user_details.modelCountry,
  };
};

//  Human face predictions
const validatePhoto = async (imgClass: any) => {
  try {
    const model = await blazeface.load();
    const img: any = document.querySelector(imgClass);
    const predictions = await model.estimateFaces(img, false);
    return predictions;
  } catch (e: any) {
    // Consider it as FINE in case an exception is thrown
    return {
      length: 1,
      errorMessage: e.message
    };
  }
};

const getDataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const notificationCountHandler = async (noti_object: any) => {
  let noti_count: number = 0;
  if (noti_object.assignmentsConfirmUpdate) {
    noti_count = noti_count + noti_object.assignmentsConfirmUpdate.length;
  }
  if (noti_object.assignmentsNotConfirmed) {
    noti_count = noti_count + noti_object.assignmentsNotConfirmed.length;
  }
  if (noti_object.assignmentsToday) {
    noti_count = noti_count + noti_object.assignmentsToday.length;
  }
  if (noti_object.assignmentCanCheckIn) {
    noti_count = noti_count + noti_object.assignmentCanCheckIn.length;
  }
  if (noti_object.recapsFill) {
    noti_count = noti_count + noti_object.recapsFill.length;
  }

  if(noti_object.eliteRewardToClaim) {
    noti_count = noti_count + noti_object.eliteRewardToClaim.length;
  }

  if (noti_object.positiveFeedbacks) {
    noti_count = noti_count + noti_object.positiveFeedbacks.length;
  }
  if (noti_object.agreementFormFill) {
    noti_count = noti_count + 1;
  }
  if (noti_object.covid19VaccineFormFill) {
    noti_count = noti_count + 1;
  }
  if (noti_object.modelProfileFill) {
    noti_count = noti_count + 1;
  }
  if (noti_object.modelCityFill) {
    noti_count = noti_count + 1;
  }
  if (noti_object.agreementFormFillRequest) {
    noti_count = noti_count + 1;
  }
  if (noti_object.w2FormFill) {
    noti_count = noti_count + 1;
  }
  if (noti_object.w2FormPending) {
    noti_count = noti_count + 1;
  }
  if (noti_object.directDepositFormFill) {
    noti_count = noti_count + 1;
  }
  if(noti_object.appNotifications) {
    noti_count = noti_count + noti_object.appNotifications.length;
  }
  return noti_count;
};

/**
 * Return a prettier number format. E.g.: (312) 231-5232
 * @param phone_no Phone number to be formatted
 * @returns Formatted number
 */
const formatPhoneNumber = (phone_no: any) => {
  if (/^[0-9\ \)\(\-\/]+$/.test(phone_no)) {
    // check is number

    let cleaned = cleanPhoneNumber(phone_no);
    let formatted_no: any = "";
    let match: any = [];
    if (cleaned.length === 3) {
      match = cleaned.match(/^(\d{3})$/);
      formatted_no = `(${match[1]}) `;
    } else if (cleaned.length === 6) {
      match = cleaned.match(/^(\d{3})(\d{3})$/);
      formatted_no = `(${match[1]}) ${match[2]}`;
    } else if (cleaned.length === 10) {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      formatted_no = `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return formatted_no;
  }
  return "";
};

/**
 * Remove any non numeric characters (!0-9) from the provided phone number.
 * E.g.: '(312) 231-5232' becomes '3212315232'
 * @param phone_no Phone Number
 * @returns Phone Number without non numeric characters
 */
const cleanPhoneNumber = (phone_no: any) => {
  return phone_no ? ("" + phone_no).replace(/\D/g, "") : "";
};

/**
 * Check if the provided phone number is valid.
 * Current validation criteria: has 10 digits.
 * TO DO: Improve the validation method. Right now it works only for 10 digits phone numbers that do not have Country Code ahead
 * It is assumed that we have only US/ Canada phone numbers
 * @param phone_no Phone Number to be validated
 * @returns true is valid, false if not valid
 */
const validatePhoneNumber = (phone_no: any) => {
  return phone_no ? cleanPhoneNumber(phone_no).length === 10 : false;
};

const getFAQLink = async (class_name?: any, page_class_name?: any) => {
  let link = "/FAQs";
  // Search by the provided Page class name
  if (page_class_name) {
    const page_links = await get("login_page_links");

    const page_link_data = page_links.filter(
      (faq: any) => faq.fp_class == page_class_name
    );
    if (page_link_data.length) {
      link = "/FAQsProfileManagement/p" + page_link_data[0].fp_id;
    }
  }

  // Search by the provided FAQ class name (this will override the Page link if a FAQ is found)
  if (class_name) {
    const faq_links = await get("login_faq_links");
    const link_data = faq_links.filter(
      (faq: any) => faq.faq_class == class_name
    );
    if (link_data.length) {
      link =
        "/FAQsProfileManagement/p" +
        link_data[0].faq_fp_id +
        "#q" +
        link_data[0].faq_id;
    }
  }

  return link;
};

const getInfoButtonData = async (class_name: any) => {
  const faq_info_buttons = await get("login_faq_info_buttons");

  const link_data = faq_info_buttons.filter(
    (info_button_data: any) => info_button_data.fib_class == class_name
  );
  let info_button = {};
  if (link_data.length) {
    info_button = link_data[0];
  }
  return info_button;
};

const getAppLatestVersion = async () => {
  if (isPlatform("mobileweb") || isPlatform("desktop")) {
    return false;
  }

  const apiVersion = await getAppVersion();
  let versionFromTheAPI;

  if (isPlatform("ios")) {
    versionFromTheAPI = apiVersion.ios;
  } else if (isPlatform("android")) {
    versionFromTheAPI = apiVersion.android;
  } else {
    return false;
  }

  return versionFromTheAPI;
};

const isNewAppVersion = async () => {
  if (isPlatform("mobileweb") || isPlatform("desktop")) {
    return false;
  }

  const apiVersion = await getAppVersion();
  let versionFromTheAPI;
  let versionFromTheApplication;
  if (isPlatform("ios")) {
    versionFromTheAPI = apiVersion.ios;
    versionFromTheApplication = process.env.REACT_APP_VERSION_IOS;
  } else if (isPlatform("android")) {
    versionFromTheAPI = apiVersion.android;
    versionFromTheApplication = process.env.REACT_APP_VERSION_ANDROID;
  } else {
    return false;
  }

  if (!versionFromTheAPI || !versionFromTheApplication) {
    return false;
  }

  try {
    if (
      typeof versionFromTheApplication !== "undefined" &&
      versionFromTheApplication
    ) {
      if (
        latestVersion(versionFromTheApplication, versionFromTheAPI) ==
        versionFromTheAPI
      ) {
        return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

function latestVersion(v1: string, v2: string): string {
  const v1Parts = v1.split(".");
  const v2Parts = v2.split(".");

  for (let i = 0; i < 3; i++) {
    const v1Part = parseInt(v1Parts[i], 10);
    const v2Part = parseInt(v2Parts[i], 10);

    if (v1Part > v2Part) {
      return v1;
    } else if (v2Part > v1Part) {
      return v2;
    }
  }

  return "equal";
}

function roundNumber(num: number, decimalPlaces: number): number {
  const factor = 10 ** decimalPlaces;
  return Math.round(num * factor) / factor;
}


/**
 * Calculate distance (miles) between two points
 **/ 
const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
  // Radius of the Earth in miles
  const R = 3958.8;
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in miles
  const distance = R * c; 
  return distance;
}

const getQrCodeInfo = () => {
  let qrInfoData = localStorage.getItem('qrInfo');
    if (!qrInfoData) {
      console.log("Error, QR info not available");
      return;
    }

    let qrInfo = JSON.parse(qrInfoData);

    return {
      customer: {
        name: qrInfo?.customerName,
        logo: ''
      },
      eventId: qrInfo?.ue_id,
      modelUserId: qrInfo?.modelUserId,
      theme: qrInfo?.theme,
      signupFields: qrInfo?.signupFields
    };
}

export {
  setTalnetInfoInReduxStore,
  showToastMessage,
  validatePhoto,
  getUserCurrentPosition,
  getUserCurrentPositionIfGranted,
  getUserCameraPhoto,
  getDataURLtoFile,
  notificationCountHandler,
  getUserContactList,
  sortUserContactNumbers,
  formatPhoneNumber,
  cleanPhoneNumber,
  validatePhoneNumber,
  getFAQLink,
  getInfoButtonData,
  isNewAppVersion,
  getAppLatestVersion,
  roundNumber,
  calculateDistance,
  noInternetResponse,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  unauthorizedResponse,
  offlineErrorCode,
  unauthorizedErrMsg,
  noInternetMsg,
  PHOTOS_ONLY_RECAP_ID,
  getQrCodeInfo
};

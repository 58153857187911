import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonLabel
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import "./AssignmentRecap.css";
import {
  saveRecapTestQuestions,
  talentFileUploader,
  logError,
} from "../../data-services/assignmets";
import InputTypeCheckbox from "../../push-components/InputTypes/InputTypeCheckbox";
import InputTypeRadio from "../../push-components/InputTypes/InputTypeRadio";
import InputTypeSelect from "../../push-components/InputTypes/InputTypeSelect";
import InputTypeText from "../../push-components/InputTypes/InputTypeText";
import InputTypeTextArea from "../../push-components/InputTypes/InputTypeTextArea";
import InpuTypeBlank from "../../push-components/InputTypes/InputTypeBlank";
import { useForm } from "react-hook-form";
import InputTypeRange from "../../push-components/InputTypes/InputTypeRange";
import InputTypePercentage from "../../push-components/InputTypes/InputTypePercentage";
import { get, set } from "../../storage/storage";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
  getUserCameraPhoto
} from "../../common/common";
import {
  deleteRecapPhotos,
  getassignmentquestion,
  getNextReportQuestionId,
  getPreviousReportQuestionId,
} from "../../data-services/assignmets";
import FileUploaderAddmore from "../../push-components/ProfileFileuploader/FileUploaderAddMore.js";
import { closeCircle } from "ionicons/icons";
import ProgressBar from "../../push-components/ProgressBar/ProgressBar";
import InputTypeCounter from "../../push-components/InputTypes/InputTypeCounter";
import arrow_back from "../../assets/images/signup/img/arrow-left.png";
import cameraIcon from "../../assets/images/common/camera-icon.png";
import { decode } from "base64-arraybuffer";

type ModalProps = {
  recap_test_show: boolean;
  recapTestClosed: Function;
  recap_ue_id: number;
  recap_ue_name: string;
  recap_ue_status: string;
  recap_ue_customer_name: string;
  recap_ue_customer_image: string;
  react_test_info: any;
  recap_completed?: any;
  recap_display_title?: any;
};
const AssignmentRecapTest: React.FC<ModalProps> = (props) => {
  const {
    recap_test_show,
    recapTestClosed,
    recap_ue_id,
    recap_ue_name,
    recap_ue_status,
    recap_ue_customer_name,
    recap_ue_customer_image,
    react_test_info,
    recap_completed,
    recap_display_title,
  } = props;
  const dispatch = useDispatch();
  const [show_section, set_show_section] = useState({
    recap_question_id: 1,
    progress_bar: 0.1,
  });

  const [recapAnswerImages, setRecapAnswerImages] = useState<any>([]);

  const [progressPerStep, setProgressPerStep] = useState<any>(0.1);
  const [questionList, setQuestionList] = useState<any>([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deletePhotoId, setDeletePhotoId] = useState<any>("");

  /**
   * Use these to save each report form answer from the multistep form
   */
  const [allAnswersList, setAllAnswersList] = useState<any>([]);
  const [allCountanswer, setAllCountanswer] = useState<any>([]);
  const [allOptionsanswer, setAllOptionsanswer] = useState<any>([]);

  /**
   * Use these to save each report form answer image upload from the multistep form
   */
  const [fileSubmitFormData, setFileSubmitFormData] = useState(new FormData());

  const [reportFormId, setReportFormId] = useState<any>(null);

  const [stepNumbersAnswerIds, setStepNumbersAnswerIds] = useState<any>([]);

  const [firstReportQuestionId, setFirstReportQuestionId] = useState<any>(null);

  const [cameraPhoto, setCameraPhoto] = useState<
    {
      name: string;
      type: string;
      size: number;
      file: string;
      data: any;
      ui_image: any;
      extension: any;
      rq_id: any;
    }[]
  >([]);

  /**
   * Store the Report Question ID of the current's report Question's next Question ID
   * We need this to determine if we'll have a question ahead in order to show either 'Next or 'Submit'
   */
  const [upcomingReportQuestionId, setUpcomingReportQuestionId] =
    useState<any>(null);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const submitRecapTestAnswersOnSubmit = async (e: any) => {
    submitRecapTestAnswers(false);
  };

  const [cameraImagesCount, setCameraImagesCount] = useState<any>(0);

  const saveAndExit = async () => {
    try {
      await submitRecapTestAnswers(true);
      await recapTestInit(true);
    } catch (e: any) {
      showToastMessage("Error! Answer could not be saved!");
      recapTestClosed();
    }

    recapTestClosed();
  };

  const submitRecapTestAnswers = async (saveOnly = false, saveFile = true) => {
    let recap_form: any = document.querySelector("form");
    setFileSubmitFormData(new FormData());
    let current_report_question_id = show_section.recap_question_id;

    setDisabledSubmitBtn(true);

    // Form Test Answers
    var test_answers: any = Object.fromEntries(
      new FormData(recap_form).entries()
    );

    let percentageQList: any = [];

    // Group the checkbox & counter answers by their questions
    let checkbox_selection: any = {};
    let counter_selection: any = {};
    Object.keys(test_answers).forEach(function (key) {
      if (key.startsWith("checkbox_")) {
        let splited: any = key.split("-");
        let checkboxes: any = document.querySelectorAll(
          "input[name='" + key + "']:checked"
        );
        for (var i = 0; i < checkboxes.length; i++) {
          if (typeof checkbox_selection[splited[1]] === "undefined") {
            checkbox_selection[splited[1]] = [];
          }
          checkbox_selection[splited[1]].push(checkboxes[i].value);
        }
      }

      if (key.startsWith("counter_")) {
        let splited: any = key.split("-");
        let counters: any = document.querySelectorAll(
          "input[name='" + key + "']"
        );
        console.log(counters);
        for (var i = 0; i < counters.length; i++) {
          if (typeof counter_selection[splited[1]] === "undefined") {
            counter_selection[splited[1]] = [];
          }
          counter_selection[splited[1]].push(counters[i].value);
        }
      }
    });

    // Assign the questions answers
    let recap_test_answers: any = {};
    Object.keys(test_answers).forEach(function (key) {
      if (key.startsWith("checkbox_")) {
        let splited: any = key.split("-");
        if (typeof checkbox_selection[splited[1]] === "undefined") {
          checkbox_selection[splited[1]] = [];
        }
        recap_test_answers[splited[1]] = checkbox_selection[splited[1]];
      } else if (key.startsWith("counter_")) {
        let splited: any = key.split("-");
        if (typeof counter_selection[splited[1]] === "undefined") {
          counter_selection[splited[1]] = [];
        }
        recap_test_answers[splited[1]] = counter_selection[splited[1]];
      } else {
        recap_test_answers[key] = test_answers[key];
      }
    });

    let assignmentRecapTestAnswers: any = {};
    let qlist: any = {};

    // form validations start
    let no_error_found = false;
    questionList.forEach((a_question: any) => {
      a_question.requiredmessage = false; // make all error message false
      a_question.requiredFileUpload = false;
      a_question.othermessages = false;
      a_question.numberValidateMessages = false;
      qlist[a_question.rq_id] = a_question.rq_question;
    });

    // Fileupload data
    let recap_form_data_photos = new FormData(recap_form);
    recap_form_data_photos.append("type", "recap_question_photos");
    recap_form_data_photos.append("ue_id", recap_ue_id.toString());

    // Using this index, we'll link the Report Question ID to the fileuploads
    let fileKey = 0;

    // TODO: Maybe get the index directly from forEach?
    let questionIndex = 0;

    let filesGroupedByRqId: any = [];
    let fileKeys: any = [];
    let files: any = [];

    // console.log('recap answer images');
    // console.log(recap_answer_images);

    // Add the File Uploads
    let currentStepImageUploaded = false;
    if (questionList.length !== 0 && saveFile) {
      let existingImage = false;
      questionList.forEach((a_question: any) => {
        let rq_all_image = recap_form_data_photos.getAll(
          "all_files" + a_question.rq_id + "[]"
        );

        // Check if we don't have any image uploaded for the current question but it requires at least one file upload.

        // Complete recap scenario - check if at least one file is uploaded
        if (
          rq_all_image.length < 2 &&
          current_report_question_id == a_question.rq_id &&
          a_question.rq_image_upload == 2
        ) {
          // Update recap scenario - also check if we have at least one existing file
          
          if (recapAnswerImages.length) {
            for (let i = 0; i < recapAnswerImages.length; i++) {
              if (recapAnswerImages[i]["mrd_rq_id"] === a_question.rq_id) {
                existingImage = true;
                break;
              }
            }
          }

          if (!existingImage) {
            a_question.requiredFileUpload = true;
            no_error_found = true;
          }
        }
        for (let index = 0; index < rq_all_image.length; index++) {
          let file: any = rq_all_image[index];
          if (file.name) {
            recap_form_data_photos.append(
              "files_grouped_by_rq_id[" + a_question.rq_id + "][]",
              fileKey.toString()
            );
            recap_form_data_photos.append("files[]", file);

            filesGroupedByRqId.push(
              "files_grouped_by_rq_id[" + a_question.rq_id + "][]"
            );

            fileSubmitFormData.append(
              "files_grouped_by_rq_id[" + a_question.rq_id + "][]",
              fileKey.toString()
            );
            fileSubmitFormData.append("files[]", file);

            fileKeys.push(fileKey.toString());
            files.push(file);

            fileKey++;
            currentStepImageUploaded = true;
          }
          recap_form_data_photos.delete("all_files" + a_question.rq_id + "[]");
        }
        questionIndex++;
      });

      console.log(cameraPhoto);
       // Add the Camera Uploads
      for (let i = 0; i < cameraPhoto.length; i++) {
        let camera: any = cameraPhoto[i];
        let blob = new Blob([new Uint8Array(decode(camera.file))], {
          type: "image/jpeg",
        });
        let file_obj = new File([blob], camera.name, {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        recap_form_data_photos.append(
          "files_grouped_by_rq_id[" + camera.rq_id + "][]",
          fileKey.toString()
        );
        recap_form_data_photos.append("files[]", file_obj);

        fileSubmitFormData.append(
          "files_grouped_by_rq_id[" + camera.rq_id + "][]",
          fileKey.toString()
        );
        fileSubmitFormData.append("files[]", file_obj);

        fileKeys.push(fileKey.toString());
        files.push(file_obj);

        fileKey++;

        // If we have a camera upload - file validation should be considered as succesfull
        questionList.forEach((a_question: any) => {
          a_question.requiredFileUpload = false;
        });

        currentStepImageUploaded = true;

        no_error_found = false;
       
      }

      
    }

    assignmentRecapTestAnswers["question"] = Object.assign(qlist);
    let answersList: any = {};
    let countanswer: any = {};
    let optionsanswer: any = {};

    // TODO: Maybe get the index directly from forEach?
    // above we have questionIndex which is similar..
    let validationIndex = 0;
    if (questionList.length !== 0) {
      for (let a_question of questionList) {
        // Validate only the current step
        if (current_report_question_id != a_question.rq_id) {
          validationIndex++;
          continue;
        }

        // validation for checkbox only
        if (a_question.rq_question_type === "checkbox") {
          if (recap_test_answers[`answer_${a_question.rq_id}`].length === 0) {
            a_question.requiredmessage = true;
            no_error_found = true;
          }
          answersList[`${a_question.rq_id}`] =
            recap_test_answers[`answer_${a_question.rq_id}`];
        } else if (a_question.rq_question_type === "counter") {
          // Any numeric value is accepted including 0...
          answersList[`${a_question.rq_id}`] =
            recap_test_answers[`answer_${a_question.rq_id}`];
        } else if (a_question.rq_question_type === "barchart") {
          if (recap_test_answers[`answer_${a_question.rq_id}`] == 0) {
            a_question.requiredmessage = true;
            no_error_found = true;
          }
          answersList[`${a_question.rq_id}`] =
            recap_test_answers[`answer_${a_question.rq_id}`];
        } else if (
          a_question.rq_question_type === "text" &&
          a_question.rq_question_report_type == "Numbers"
        ) {
          const re = /^[0-9\b]+$/;
          if (!re.test(recap_test_answers[`answer_${a_question.rq_id}`])) {
            a_question.numberValidateMessages = true;
            no_error_found = true;
          } else {
            a_question.numberValidateMessages = false;
          }
          answersList[`${a_question.rq_id}`] =
            recap_test_answers[`answer_${a_question.rq_id}`];
        } else if (a_question.rq_question_type === "percentage") {
          let total = 0;
          let percentagelist: any = {};
          let list = a_question.rq_options.split("||");
          let count = 0;
          let selectedlist: any = [];
          percentageQList.push(`answer[${a_question.rq_id}]`);
          if (list.length !== 0) {
            list.forEach((elem: any) => {
              if (
                recap_test_answers[
                  `answer_${a_question.rq_id}_${elem}`
                ].toString() != ""
              ) {
                count++;
                selectedlist.push(elem);
                percentagelist[`${elem}`] =
                  recap_test_answers[
                    `answer_${a_question.rq_id}_${elem}`
                  ].toString();
                total =
                  total +
                  parseInt(
                    recap_test_answers[
                      `answer_${a_question.rq_id}_${elem}`
                    ].toString()
                  );
              }
            });
          }

          if (total > 100 || total < 100) {
            a_question.othermessages = true;
            no_error_found = true;
          } else if (total == 0) {
            a_question.requiredmessage = true;
          }

          answersList[`${a_question.rq_id}`] = percentagelist;

          countanswer[`${a_question.rq_id}`] = count.toString();
          optionsanswer[`${a_question.rq_id}`] = selectedlist.join("||");

          // console.log(answersList);
        } else {
          if (recap_test_answers[`answer_${a_question.rq_id}`] === "") {
            a_question.requiredmessage = true;
            no_error_found = true;
          }
          answersList[`${a_question.rq_id}`] =
            recap_test_answers[`answer_${a_question.rq_id}`];
        }
        if (current_report_question_id == a_question.rq_id) {
          break;
        }
        validationIndex++;
      }
    }

    /**
     * Validation succesful - answer provided & file uploaded (if required)
     * => Save Recap Answer(s) & provided file uploads
     */
    if (!no_error_found) {
      // That's the field that actually matters
      let existingAnswersList = { ...answersList };
      setAllAnswersList(existingAnswersList);

      // Those don't seem to be used at all (to be removed...)
      let existingCountAnswer = [...allCountanswer, countanswer];
      let existingOptionAnswer = [...allOptionsanswer, optionsanswer];
      setAllCountanswer(existingCountAnswer);
      setAllOptionsanswer(existingOptionAnswer);

      assignmentRecapTestAnswers["countanswer"] =
        Object.keys(existingAnswersList).length.toString();
      assignmentRecapTestAnswers["optionsanswer"] = existingOptionAnswer;
      assignmentRecapTestAnswers["percentageQuestionNames"] =
        percentageQList.join(",");
      assignmentRecapTestAnswers["answer"] = existingAnswersList;
      assignmentRecapTestAnswers["ue_id"] = recap_ue_id;
      assignmentRecapTestAnswers["modelUserID"] = await get("login_user_id");

      fileSubmitFormData.append("type", "recap_question_photos");
      fileSubmitFormData.append("ue_id", recap_ue_id.toString());

      assignmentRecapTestAnswers["partial_save"] = true;
      assignmentRecapTestAnswers["current_report_question_id"] =
        current_report_question_id;
      assignmentRecapTestAnswers["last_question"] = !upcomingReportQuestionId;
      await set("recap_test_question_answers", assignmentRecapTestAnswers);
      try {
        let save_recap_test_questions_api_response =
          await saveRecapTestQuestions(assignmentRecapTestAnswers);

        // console.log(model_test);
        if (save_recap_test_questions_api_response?.success) {
          if (currentStepImageUploaded) {
            // Save the photos
            try {
              // Fileupload Started
              let file_upload_api_response: any = await talentFileUploader(
                fileSubmitFormData
                //"",
                //handleProgress
              );

              // Fileupload Finished
              if (file_upload_api_response?.success) {
                // Clear the camera photos
                setCameraPhoto([]);
                // Fileupload Success
                // Last Question - Submit button clicked

                if (!saveOnly) {
                  if (
                    !save_recap_test_questions_api_response?.data
                      ?.next_report_question_id
                  ) {
                    set("last_report_form_id", null);
                    set("last_report_question_id", null);

                    let tempLastEventIdModelAnswerId = await get(
                      "last_event_id_model_answer_id"
                    );

                   
                    if (
                      tempLastEventIdModelAnswerId !== null &&
                      typeof tempLastEventIdModelAnswerId[recap_ue_id] !==
                      "undefined"
                    ) {
                      tempLastEventIdModelAnswerId[recap_ue_id] = null;
                    }
                    set(
                      "last_event_id_model_answer_id",
                      tempLastEventIdModelAnswerId
                    );

                    recapTestClosed(
                      assignmentRecapTestAnswers["last_question"] &&
                        save_recap_test_questions_api_response?.data
                          ?.recap_started_live &&
                        save_recap_test_questions_api_response?.data
                          ?.canModelCheckout
                    );

                    // Not the Last Question - Nex button clicked
                  } else {
                    questionList.forEach((a_question: any) => {
                      fileSubmitFormData.delete(
                        "files_grouped_by_rq_id[" + a_question.rq_id + "][]"
                      );
                    });
                    fileSubmitFormData.delete("files[]");

                    setUpcomingReportQuestionId(
                      save_recap_test_questions_api_response?.data
                        ?.next_next_report_question_id
                    );
                    nextStepHandler(
                      save_recap_test_questions_api_response?.data
                        ?.next_report_question_id,
                      save_recap_test_questions_api_response?.data
                        ?.question_progress_count
                    );
                  }
                }
              } else if (
                file_upload_api_response?.data?.errorCode ==
                unauthorizedErrorCode
              ) {
                // Fileupload Error
                dispatch(authActions.logout());
                return;
              } else if (
                file_upload_api_response?.data?.errorCode == offlineErrorCode
              ) {
                showToastMessage(file_upload_api_response?.message);
              } else if (file_upload_api_response?.message) {
                showToastMessage(file_upload_api_response.message);
              } else {
                showToastMessage(somethingWentWrongErrMsg);
              }
              // Re-enable Submit
              setDisabledSubmitBtn(false);
              // setUploadProgress(0);
            } catch (e: any) {
              // Fileupload Exception
              // Re-enable Submit
              setDisabledSubmitBtn(false);
              // setUploadProgress(0);
              if (e.message == unauthorizedErrMsg) {
                dispatch(authActions.logout());
                return;
              } else {
                showToastMessage(somethingWentWrongErrMsg);
                await logError(
                  "Assignment Recap Test - submitRecapTestAnswers - File Upload - Exception thrown",
                  "Talent App - Assignment Recap Test",
                  JSON.stringify(e.message)
                );
              }
            }
          } else if (!saveOnly) {
            // Clear the camera photos
            setCameraPhoto([]);
            if (
              !save_recap_test_questions_api_response?.data
                ?.next_report_question_id
            ) {
              set("last_report_form_id", null);
              set("last_report_question_id", null);

              let tempLastEventIdModelAnswerId = await get(
                "last_event_id_model_answer_id"
              );
              if (
                tempLastEventIdModelAnswerId !== null &&
                typeof tempLastEventIdModelAnswerId[recap_ue_id] !== "undefined"
              ) {
                tempLastEventIdModelAnswerId[recap_ue_id] = null;
              }
              set(
                "last_event_id_model_answer_id",
                tempLastEventIdModelAnswerId
              );
              recapTestClosed(
                assignmentRecapTestAnswers["last_question"] &&
                  save_recap_test_questions_api_response?.data
                    ?.recap_started_live &&
                  save_recap_test_questions_api_response?.data?.canModelCheckout
              );
            } else {
              setUpcomingReportQuestionId(
                save_recap_test_questions_api_response?.data
                  ?.next_next_report_question_id
              );
              nextStepHandler(
                save_recap_test_questions_api_response?.data?.next_report_question_id,
                save_recap_test_questions_api_response?.data?.question_progress_count
              );
            }
          }
        } else if (
          save_recap_test_questions_api_response?.data?.errorCode ==
          unauthorizedErrorCode
        ) {
          dispatch(authActions.logout());
          return;
        } else if (
          save_recap_test_questions_api_response?.data?.errorCode ==
          offlineErrorCode
        ) {
          showToastMessage(save_recap_test_questions_api_response?.message);
        } else if (save_recap_test_questions_api_response?.message) {
          showToastMessage(save_recap_test_questions_api_response.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } catch (e: any) {
        // Fileupload Exception
        // Re-enable Submit
        setDisabledSubmitBtn(false);
        // setUploadProgress(0);
        if (e.message == unauthorizedErrMsg) {
          dispatch(authActions.logout());
          return;
        } else {
          showToastMessage(somethingWentWrongErrMsg);
          await logError(
            "Assignment Recap Test - submitRecapTestAnswers - Save Recap Answers - Exception thrown",
            "Talent App - Assignment Recap Test",
            JSON.stringify(e.message)
          );
        }
      }
    } else {
      setShowErrorAlert(true);
    }
    setDisabledSubmitBtn(false);
  };

  const deleteEventRecapPhotos = async () => {
    for (let index = 0; index < deletePhotoId?.image_array.length; index++) {
      let element = deletePhotoId?.image_array[index];
      if (element.mrd_id == deletePhotoId.mrd_id) {
        deletePhotoId?.image_array.splice(index, 1);

        break;
      }
    }
    let delete_api = await deleteRecapPhotos(deletePhotoId.mrd_id);
    if (delete_api?.success) {
      await showToastMessage(delete_api.message);
    } else if (delete_api?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (delete_api?.data?.errorCode == offlineErrorCode) {
      showToastMessage(delete_api?.message);
    } else if (delete_api?.message) {
      showToastMessage(delete_api.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };

  const nextStepHandler = async (next_report_question_id: any, question_progress_count = 1) => {

    if (Object.keys(errors).length === 0) {
      let increase_bar = question_progress_count / questionList.length;
      set_show_section({
        recap_question_id: next_report_question_id,
        progress_bar: increase_bar,
      });

      set("last_report_form_id", reportFormId);
      set("last_report_question_id", next_report_question_id);
      let tempLastEventIdModelAnswerId = await get(
        "last_event_id_model_answer_id"
      );
      if (tempLastEventIdModelAnswerId === null) {
        tempLastEventIdModelAnswerId = {};
      }
      tempLastEventIdModelAnswerId[recap_ue_id] =
        stepNumbersAnswerIds[next_report_question_id];
      set("last_event_id_model_answer_id", tempLastEventIdModelAnswerId);
    }
  };
  const backStepHandle = async () => {
    setDisabledSubmitBtn(true);
    let previous_report_question_id = null;
    let getPreviousReportQuestionAPIResponse =
      await getPreviousReportQuestionId(
        await get("last_report_question_id"),
        recap_ue_id
      );
    if (getPreviousReportQuestionAPIResponse?.success) {
      previous_report_question_id =
        getPreviousReportQuestionAPIResponse?.data?.previous_report_question_id;
    }

    if (previous_report_question_id) {
      await recapTestInit(true);
      let decrease_bar =
        getPreviousReportQuestionAPIResponse?.data?.question_progress_count /
        questionList.length;

      set_show_section({
        recap_question_id: previous_report_question_id,
        progress_bar: decrease_bar,
      });
      setDisabledSubmitBtn(false);
      set("last_report_question_id", previous_report_question_id);

      let tempLastEventIdModelAnswerId = await get(
        "last_event_id_model_answer_id"
      );
      if (tempLastEventIdModelAnswerId === null) {
        tempLastEventIdModelAnswerId = {};
      }
      tempLastEventIdModelAnswerId[recap_ue_id] =
        stepNumbersAnswerIds[previous_report_question_id];
      set("last_event_id_model_answer_id", tempLastEventIdModelAnswerId);
    } else {
      setDisabledSubmitBtn(false);
      set("last_report_form_id", null);
      set("last_report_question_id", null);

      let tempLastEventIdModelAnswerId = await get(
        "last_event_id_model_answer_id"
      );
      if (
        tempLastEventIdModelAnswerId !== null &&
        typeof tempLastEventIdModelAnswerId[recap_ue_id] !== "undefined") {
        tempLastEventIdModelAnswerId[recap_ue_id] = null;
      }
      set("last_event_id_model_answer_id", tempLastEventIdModelAnswerId);

      recapTestClosed();
    }
  };

  /**
   * Get the Recap Questions and Answer + Images
   * @param fromBackButton - true - Method was called via Back button; false - Method was called on page init
   * @returns
   */
  const recapTestInit = async (fromBackButton = false) => {
    setDisabledSubmitBtn(true);
    let model_test;
    try {
      if (fromBackButton) {
        model_test = await getassignmentquestion(recap_ue_id);
      } else {
        model_test = react_test_info;
      }
      if (model_test?.success) {
        let test_details: any = model_test.data.questions;
        let editAnswerList: any = model_test.data.answers;
        setRecapAnswerImages(model_test?.data?.answer_images);
        setReportFormId(model_test?.data?.event?.ue_rf_id);
        let reportQuestionsProgressBarSteps: any[] = [];
        test_details.forEach((ele: any, index: any) => {
          reportQuestionsProgressBarSteps[ele.rq_id] = ele.rq_progress_bar_step;
          editAnswerList.forEach((element: any, answerIndex: any) => {
            ele.requiredmessage = false;
            ele.othermessages = false;
            ele.numberValidateMessages = false;
            if (element.ma_rq_id == ele.rq_id) {
              test_details[index].ma_id = element.ma_id;
              if (ele.rq_question_type == "percentage") {
                let val = element.ma_answer?.split("%%%");
                let result: any = [];
                val?.forEach(function (key: any) {
                  let value = key.split("|||");
                  if (value.length > 0) {
                    result[value[0]] = value[1];
                  }
                });
                test_details[index].rq_answer = result;
              } else if (ele.rq_question_type == "checkbox") {
                let val = element.ma_answer?.split("||");
                let result: any = [];
                val?.forEach(function (key: any) {
                  result[key] = test_details[index].rq_options.includes(key)
                    ? true
                    : false;
                });
                test_details[index].rq_answer = result;
              } else {
                test_details[index].rq_answer =
                  editAnswerList[answerIndex].ma_answer;
              }
            }
          });
        });

        // Store the Step Number <-> Model Answer
        let tempStepNumbersAnswerIds: any[] = [];

        editAnswerList.forEach((element: any) => {
          tempStepNumbersAnswerIds[element.ma_rq_id] = element.ma_id;
        });
        setStepNumbersAnswerIds(tempStepNumbersAnswerIds);
        setQuestionList(test_details);
        if (!fromBackButton) {
          // Scenario 1: Open the Report form (Complete/ Edit) which leads to the first question
          // First recap question ID
          let recap_question_id =
            typeof test_details[0] !== "undefined" ? test_details[0].rq_id : 0;
          setFirstReportQuestionId(recap_question_id);
          // TBD
          let progress_per_step = 1 / test_details.length;

          // Scenario 2: Recap reopened later during the session
          // Resume to the last opened question
          // TODO: Correct the progress per step
          let last_event_id_model_answer_id = await get(
            "last_event_id_model_answer_id"
          );
          if (
            last_event_id_model_answer_id &&
            typeof last_event_id_model_answer_id[recap_ue_id] !== "undefined" &&
            tempStepNumbersAnswerIds.find(
              (item) => item === last_event_id_model_answer_id[recap_ue_id]
            )
          ) {
            recap_question_id = tempStepNumbersAnswerIds.findIndex(
              (item) => item === last_event_id_model_answer_id[recap_ue_id]
            );

            let current_question_data = test_details.find(
              (item: any) => +item?.rq_id === +recap_question_id
            );

            // Will also need to store the progress per step?
            progress_per_step =
              typeof current_question_data?.rq_progress_bar_step !== "undefined"
                ? current_question_data?.rq_progress_bar_step /
                  test_details.length
                : 1 / test_details.length;
          }

          set_show_section({
            recap_question_id: recap_question_id,
            progress_bar: progress_per_step,
          });
          setProgressPerStep(progress_per_step);

          let getNextReportQuestionAPIResponse = await getNextReportQuestionId(
            typeof test_details[0] !== "undefined" ? test_details[0].rq_id : 0,
            model_test?.data?.event?.ue_id
          );
          if (getNextReportQuestionAPIResponse?.success) {
            setUpcomingReportQuestionId(
              getNextReportQuestionAPIResponse?.data?.next_report_question_id
            );
          }
        }
      } else if (model_test?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (model_test?.data?.errorCode == offlineErrorCode) {
        showToastMessage(model_test?.message);
      } else if (model_test?.message) {
        showToastMessage(model_test.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      // Fileupload Exception
      // Re-enable Submit
      setDisabledSubmitBtn(false);
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignment Recap Test - recapTestInit - Exception thrown",
          "Talent App - Assignment Recap Test",
          JSON.stringify(e.message)
        );
      }
    }

    setDisabledSubmitBtn(false);
  };

  const takePhoto = async (rq_id: any) => {
    let image = await getUserCameraPhoto("base64");
    if (image?.base64String) {
      let image_count = cameraImagesCount;
      image_count = image_count + 1;
      if (image.base64String) {
        setCameraPhoto([
          ...cameraPhoto,
          {
            name: `image${image_count}.${image.format}`,
            type: "image/jpeg",
            size: 789,
            file: image.base64String,
            data: "",
            ui_image: "data:text/plain;base64," + image.base64String,
            extension: image.format,
            rq_id: rq_id,
          },
        ]);
        setCameraImagesCount(image_count);
      }
    }
  }

  const removeImage = (index: any) => {
    cameraPhoto.splice(index, 1);
    setCameraPhoto([...cameraPhoto]);
  };

  useEffect(() => {
    (async () => {
      recapTestInit();
    })();
  }, []);
  return (
    <IonModal isOpen={recap_test_show} mode="ios">
      <IonHeader>
        <IonToolbar mode="ios">
          <IonTitle>{recap_display_title}</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => backStepHandle()}>
              <img src={arrow_back} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <ProgressBar progress_value={show_section.progress_bar} />
      <IonContent className="assign-recap">
        <div className="assign-recap-container">
          {show_section.recap_question_id == firstReportQuestionId && (
            <div>
              <div className="recap__welcome-text">
                <IonText>
                  Thank you for participating in
                  <span> {recap_ue_name}</span>.
                  {!recap_completed && (
                    <>
                      Complete this {recap_display_title} of the event to begin
                      processing your payment.
                    </>
                  )}
                </IonText>
              </div>
              <IonCard className="recap__event__details">
                <IonBadge>
                  <span style={{ backgroundColor: "green" }}></span>{" "}
                  {recap_ue_status}
                </IonBadge>
                <h2>{recap_ue_name}</h2>
                <IonItem className="assignment-view__event__status">
                  <img
                    slot="start"
                    src={decodeURIComponent(recap_ue_customer_image)}
                    alt=""
                  />
                  <IonText slot="start">{recap_ue_customer_name}</IonText>
                </IonItem>
              </IonCard>
              <h2 className="assign-recap-content__title">Recap</h2>
            </div>
          )}
          <form
            className="assign-recap-container__form"
            onSubmit={handleSubmit(submitRecapTestAnswersOnSubmit)}
          >
            {questionList &&
              questionList.map((question: any, index: number) => (
                <>
                  {show_section.recap_question_id == question.rq_id && (
                    <div
                      className="assign-recap-container__form__question"
                      key={index}
                    >
                      <div>
                        {question?.rq_head_label && (
                          <p className="assign-recap-container__form__question__head-label">
                            {question.rq_head_label}
                          </p>
                        )}
                        {question.rq_question_type === "text" && (
                          <InputTypeText
                            question={question.rq_question}
                            question_id={question.rq_id}
                            numberValidateMessage={
                              question.numberValidateMessages
                            }
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "textarea" && (
                          <InputTypeTextArea
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "checkbox" && (
                          <InputTypeCheckbox
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "radio" && (
                          <InputTypeRadio
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "select" && (
                          <InputTypeSelect
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            key={index}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "percentage" && (
                          <InputTypePercentage
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            key={index}
                            othermessage={question.othermessages}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "barchart" && (
                          <InputTypeRange
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            key={index}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "blank" && (
                          <InpuTypeBlank
                            question={question.rq_question}
                            question_id={question.rq_id}
                            options={question.rq_options}
                            key={index}
                            requiredmessage={question.requiredmessage}
                            answer={question.rq_answer}
                            allowHtmlQuestion={
                              +question?.rq_allow_html_question
                            }
                          />
                        )}
                        {question.rq_question_type === "counter" && (
                          <>
                            {question.rq_question
                              .split("||")
                              .map(
                                (questionLabel: any, questionIndex: number) => (
                                  <InputTypeCounter
                                    question={questionLabel}
                                    question_id={question.rq_id}
                                    options={question.rq_options}
                                    key={questionIndex}
                                    index={questionIndex}
                                    requiredmessage={question.requiredmessage}
                                    answer={
                                      question.rq_answer.split("||")[
                                        questionIndex
                                      ]
                                    }
                                    submitRecapTestAnswers={
                                      submitRecapTestAnswers
                                    }
                                    allowHtmlQuestion={
                                      +question?.rq_allow_html_question
                                    }
                                  />
                                )
                              )}
                          </>
                        )}

                        {(question.rq_image_upload == 1 ||
                          question.rq_image_upload == 2) && (
                          <div className="assign-recap-content__fileupload-container">
                            {question?.rq_file_upload_label && (
                              <div className="assign-recap-content__fileupload-container__label">
                                {question?.rq_file_upload_label}
                              </div>
                            )}
                            {(question?.rq_file_upload_description ||
                              question?.full_path) && (
                              <p
                                className={
                                  question.rq_image_upload == 2
                                    ? "assign-recap-content__fileupload-description assign-recap-content__fileupload-description--required"
                                    : "assign-recap-content__fileupload-description"
                                }
                              >
                                <span className="assign-recap-content__fileupload-description__description">
                                  {question?.rq_file_upload_description}
                                </span>
                                {question?.full_path && (
                                  <div className="assign-recap-content__fileupload-container__example-container">
                                    <img src={question?.full_path} />
                                  </div>
                                )}
                              </p>
                            )}
                            {recapAnswerImages.filter(
                              (item: any) => item.mrd_ma_id == question.ma_id
                            ).length > 0 && (
                              <div className="assign-recap-content__exsting_img">
                                {recapAnswerImages
                                  .filter(
                                    (item: any) =>
                                      item.mrd_ma_id == question.ma_id
                                  )
                                  .map((image: any, index: number) => (
                                    <div
                                      key={index}
                                      className="react_exsting_image"
                                    >
                                      <IonIcon
                                        icon={closeCircle}
                                        onClick={() => {
                                          setDeletePhotoId({
                                            mrd_id: image.mrd_id,
                                            image_array: recapAnswerImages,
                                          });
                                          setShowDeleteAlert(true);
                                        }}
                                      />
                                      <img
                                        // src={`${process.env.REACT_APP_HP_IMAGE_URL}/report_document/${image.mrd_document}`}
                                        src={`${image.full_path}`}
                                        alt=""
                                      />
                                    </div>
                                  ))}
                              </div>
                            )}
                            <div
                              className={
                                question.rq_image_upload == 2 &&
                                !question?.rq_file_upload_description
                                  ? "assign-decline__upload-btn assign-recap__upload-btn"
                                  : "assign-decline__upload-btn assign-recap__upload-btn"
                              }
                            >
                              <FileUploaderAddmore
                                name={"all_files" + question.rq_id + "[]"}
                                limit="200"
                                id="model_additional"
                                files={[]}
                              />
                              <div className="assign-decline_take-camera">
                                <IonCard
                                  onClick={() => takePhoto(question.rq_id)}
                                >
                                  <img alt="" src={cameraIcon} />
                                  <IonLabel>Take Photo</IonLabel>
                                </IonCard>
                                <div className="assign-decline_photo-items">
                                  {cameraPhoto.map(
                                    (item: any, index: number) => (
                                      <>
                                        {item.rq_id == question.rq_id && (
                                          <div className="assign-decline_photo-list">
                                            <img
                                              className="img_file"
                                              alt=""
                                              src={item.ui_image}
                                            />
                                            <div className="assign-decline_camera-img">
                                              <span className="img_name">
                                                {item.name}
                                              </span>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  removeImage(index)
                                                }
                                              >
                                                <i className="fileuploader-icon-remove remove-color"></i>
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              </div>

                              
                            </div>
                            {question.requiredFileUpload && (
                                <div className="error-message">
                                  Please provide at least one photo.
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                      <div className="assign-recap-content__bottom-section">
                        {question?.rq_confirm_response_label && (
                          <p className="assign-recap-content__footer-description">
                            {question.rq_confirm_response_label}
                          </p>
                        )}
                        <div className="assign-recap-content__bottom-section__buttons">
                          <IonButton
                            expand="block"
                            mode="ios"
                            fill="clear"
                            className="assign-recap-content__bottom-section__buttons__exit"
                            onClick={() => saveAndExit()}
                            disabled={disabledSubmitBtn}
                          >
                            Save & Exit
                          </IonButton>
                          {(!upcomingReportQuestionId &&
                            question.rq_id != firstReportQuestionId) ||
                          question.rq_question_type == "counter" ? (
                            <>
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="submit"
                                disabled={disabledSubmitBtn}
                              >
                                Submit
                              </IonButton>
                            </>
                          ) : (
                            <>
                              <IonButton
                                expand="block"
                                mode="ios"
                                type="submit"
                                disabled={disabledSubmitBtn}
                              >
                                Next
                              </IonButton>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </form>
        </div>
      </IonContent>
      <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        cssClass="my-custom-class"
        header={"Error"}
        message={"Please fix the errors in red!"}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showDeleteAlert}
        // mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Are You Sure?"}
        message={"Please confirm if you want to delete the photo"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Yes",
            handler: () => {
              deleteEventRecapPhotos();
            },
          },
        ]}
      />
    </IonModal>
  );
};

export default AssignmentRecapTest;

// function handleSubmit(
//   submitTestHandler: any
// ): React.FormEventHandler<HTMLFormElement> | undefined {
//   throw new Error("Function not implemented.");
// }

// function submitTestHandler(
//   submitTestHandler: any
// ): React.FormEventHandler<HTMLFormElement> | undefined {
//   throw new Error("Function not implemented.");
// }

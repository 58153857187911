/** */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonButton,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonRow,
  IonLabel,
  IonCheckbox,
  IonToolbar,
  IonModal,
} from "@ionic/react";
import "./PushModelAgreement.css";

import { authActions } from "../../../store/auth";
import { CapacitorHttp } from '@capacitor/core';
import PolicyModal from "../../Users/policyModal/policyModal";
import { getPoliciesContent } from "../../../data-services/signup";
import { get } from "../../../storage/storage";
import { useHistory } from "react-router";
import { showToastMessage, noInternetResponse } from "../../../common/common";
import back_arrow from "../../../assets/images/common/arrow-left.png";
import { Network } from "@capacitor/network";

type ModalProps = {
  isOpen_modal_agreement: boolean;
  closeModalAgreement: Function;
};

const PushModelAgreement: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_modal_agreement, closeModalAgreement } = props;
  const [agreementList, setAgreementList] = useState([]);
  const [showPolicyModal, setShowPolicyModal] = useState<boolean>(false);
  const [policiesContent, setPoliciesContent] = useState<any>("");
  const [policiesTitle, setPoliciesTitle] = useState<string>("");
  const [agreementchecked, setAgreementchecked] = useState<any>(false);
  const history = useHistory();
  const [isrequired, setIsrequired] = useState(false);

  const submitAgreement = async () => {
    let isChecked = agreementchecked == true ? 1 : 0;
    if (isChecked == 1) {
      setIsrequired(false);
      let response;
      let networkStatus = (await Network.getStatus()).connectionType;
      if (networkStatus !== "none") {
        const authToken = await get("login_user_token");
        if (!authToken) {
          dispatch(authActions.logout());
          return;
        }
        let url =
          `${process.env.REACT_APP_HP_API_URL}/userEvent/saveUserAgreement?token=` +
          authToken;
        const options = {
          url: url,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ` + authToken,
          },
          data: {
            model_agreement: isChecked,
          },
        };
        response = await CapacitorHttp.post(options);
        response = response.data;
      } else {
        response = noInternetResponse;
      }
      if (response?.success) {
        await showToastMessage(response.message);
        closeModalAgreement();
      }
    } else {
      setIsrequired(true);
    }
  };

  const closePolicyModal = () => {
    setShowPolicyModal(false);
  };

  const getPoliciesAndTerms = async (
    policy_type: string,
    policy_title: string
  ) => {
    let policy_api = await getPoliciesContent(policy_type);
    if (policy_api?.success) {
      setShowPolicyModal(true);
      setPoliciesTitle(policy_title);
      setPoliciesContent(policy_api.data);
    } else {
      if (policy_api?.data?.errorCode == 0) {
        showToastMessage(policy_api?.data?.message);
      }
    }
  };

  useEffect((): any => {
    let isSubscribed = true;
    (async () => {
      let response;
      let networkStatus = (await Network.getStatus()).connectionType;
      if (networkStatus !== "none") {
        const authToken = await get("login_user_token");
        if (!authToken) {
          dispatch(authActions.logout());
          return;
        }
        let url =
          `${process.env.REACT_APP_HP_API_URL}/userEvent/getUserAgreementAndWorkForm2?token=` +
          authToken;

        const options = {
          url: url,
          headers: {
            authorization: `Bearer ` + authToken,
          },
        };
        response = await CapacitorHttp.get(options);
        response = response.data;
      } else {
        response = noInternetResponse;
      }
      if (response?.success) {
        let data = response.data.userAgreement;
        if (isSubscribed) setAgreementList(data);
      }
    })();

    return () => (isSubscribed = false);
  }, []);

  return (
    <IonModal isOpen={isOpen_modal_agreement}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeModalAgreement();
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="push-aggrement-view">
        <div className="push-aggrement-view">
          {agreementList.map((item: any, index: any) => (
            <div key={index}>
              {item.type == "agreement_head" && index == 0 && (
                <div className="push-aggrement-view__main-header">
                  <h1>{item.text}</h1>
                </div>
              )}
              {item.type == "agreement_head" && index != 0 && (
                <div className="push-aggrement-view__header">
                  <p>{item.text}</p>
                </div>
              )}
              {(item.type == "agreement_paragraph" ||
                item.type == "agreement_text") && (
                <div className="push-aggrement-view__paragraph">
                  <p>{item.text}</p>
                </div>
              )}
            </div>
          ))}
          <div className="push-aggrement-view__section-footer">
            <div className="push-aggrement-view__mobile_terms">
              <div>
                <IonCheckbox
                  className={
                    isrequired === true
                      ? "push-agreement-view__agreecheck push-aggrement-check_required-field"
                      : "push-agreement-view__agreecheck"
                  }
                  mode="md"
                  onIonChange={(e) => setAgreementchecked(e.detail.checked)}
                ></IonCheckbox>
              </div>
              <div>
                <IonLabel>
                  I agree to PUSH's Terms and Conditions as set out by the User
                  Agreement.
                </IonLabel>
                <br />
              </div>
            </div>
            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button section-next-button"
              onClick={submitAgreement}
            >
              Submit
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default PushModelAgreement;

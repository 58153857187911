import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonPage,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router";
import "./NotificationDashBoard.css";
import right_chevron from "../../../assets/images/common/grey-chevron-right.svg";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { getModelProfileData } from "../../../data-services/edit-profile";
import { getNotificationSettings } from "../../../data-services/edit-profile";
import { authActions } from "../../../store/auth";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";

const NotificationDashBoard: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [minimumhourrate, setMinimumhourrate] = useState<any>("Loading...");
  const [notification_frequency, setNotificationFrequency] =
    useState<any>("Loading...");
  const [notificationTypes, setNotificationTypes] = useState<any>("Loading...");
  const [notificationDistance, setNotificationDistance] =
    useState<any>("Loading...");

  const navigateTo = (page: any) => {
    history.push(page);
  };

  const epInit = async () => {
    let notification_settings: any = await getNotificationSettings();
    if (!notification_settings?.success) {
      if (notification_settings?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (notification_settings?.data?.errorCode == offlineErrorCode) {
        showToastMessage(notification_settings?.message);
      } else if (notification_settings?.message) {
        await showToastMessage(notification_settings.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }

    let profile_data: any = await getModelProfileData();
    if (!profile_data?.success) {
      if (profile_data?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (profile_data?.data?.errorCode == offlineErrorCode) {
        showToastMessage(profile_data?.message);
      } else if (profile_data?.message) {
        await showToastMessage(profile_data.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }

    notification_settings = notification_settings.data;
    profile_data = profile_data.data;

    let notifications_arr = [];
    if (profile_data.modelMarketing.model_receive_mail == "yes") {
      notifications_arr.push("Email");
    }

    if (profile_data.modelMarketing.model_receive_sms == "yes") {
      notifications_arr.push("SMS");
    }

    if (profile_data.modelMarketing.model_receive_push_notification == "yes") {
      notifications_arr.push("In-App");
    }

    setNotificationTypes(
      notifications_arr.length == 3 ? "All" : notifications_arr.join(", ")
    );

    let distance_arr = [];
    if (notification_settings.mns_opp_zipcode) {
      distance_arr.push(notification_settings.mns_opp_zipcode);
    }

    if (notification_settings.mns_opp_distance) {
      distance_arr.push(notification_settings.mns_opp_distance + " mi");
    }

    if (!distance_arr.length) {
      distance_arr.push("All");
    }

    setNotificationDistance(distance_arr.join(", "));

    if (notification_settings.mns_frequency == 1) {
      setNotificationFrequency("Daily");
    } else if (notification_settings.mns_frequency == 2) {
      setNotificationFrequency("Weekly");
    } else if (notification_settings.mns_frequency == 3) {
      setNotificationFrequency("Monthly");
    } else {
      setNotificationFrequency("Never");
    }

    setMinimumhourrate(
      profile_data.model.model_min_hourly_rate > 0
        ? "$" + profile_data.model.model_min_hourly_rate + ">"
        : "Any"
    );
  };

  useEffect(() => {
    (async () => {
      await epInit();
    })();
  });

  return (
    <IonPage>
      <SimpleHeaderView
        title="Notifications"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <IonCard className="notification-dashboard-card-view">
          <IonItem
            onClick={(_) => navigateTo("/NotificationMarket")}
            className="notification-dashboard-card-view__items"
          >
            <IonText className="notification-dashboard-card-view__label">
              Distance
            </IonText>
            <IonIcon
              className="notification-dashboard_selection_icon"
              icon={right_chevron}
              slot="end"
            />
            <IonText className="notification-dashboard-card-view__values">
              {notificationDistance}
            </IonText>
          </IonItem>
          <IonItem
            onClick={(_) => navigateTo("/NotificationRate")}
            className="notification-dashboard-card-view__items"
          >
            <IonText className="notification-dashboard-card-view__label">
              Rate
            </IonText>
            <IonIcon
              className="notification-dashboard_selection_icon"
              icon={right_chevron}
              slot="end"
            />
            <IonText className="notification-dashboard-card-view__values">
              {minimumhourrate}
            </IonText>
          </IonItem>
          <IonItem
            className="notification-dashboard-card-view__items"
            onClick={(_) => navigateTo("/NotificationFrequency")}
          >
            <IonText className="notification-dashboard-card-view__label">
              Frequency
            </IonText>

            <IonIcon
              className="notification-dashboard_selection_icon"
              icon={right_chevron}
              slot="end"
            />
            <IonText className="notification-dashboard-card-view__values">
              {notification_frequency}
            </IonText>
          </IonItem>
          <IonItem
            className="notification-dashboard-card-view__items no-border"
            onClick={(_) => navigateTo("/NotificationType")}
          >
            <IonText className="notification-dashboard-card-view__label">
              Notification Type
            </IonText>
            <IonIcon
              className="notification-dashboard_selection_icon"
              icon={right_chevron}
              slot="end"
            />
            <IonText className="notification-dashboard-card-view__values">
              {notificationTypes}
            </IonText>
          </IonItem>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default NotificationDashBoard;

import React, { useState } from "react";
import {
  IonContent,
  IonPage
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import "@splidejs/splide/dist/css/splide.min.css";
import "./ChangePassword.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../components/Modal/Modal";

import ChangePasswordView from "../../../components/ChangePasswordView/ChangePasswordView";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { Toast } from "@capacitor/toast";
import { changePassword } from "../../../data-services/home-api";
import { get } from "http";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { showToastMessage, unauthorizedErrMsg, somethingWentWrongErrMsg } from "../../../common/common";
import { logError } from "../../../data-services/assignmets";

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState("");

  async function closeModal() {
    setShowModal(false);
  }

  const onsubmit = async (
    currentPassword: any,
    newPassword: any,
    confirmPassword: any
  ) => {
    let datas = {
      old_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
      token: await get("login_user_token"),
    };
    try {
      let api_res = await changePassword(datas);
      if (api_res?.success == true) {
        await showToastMessage(api_res.message);
        history.push(`/Home`);
        return;
      } else {
        await showToastMessage(api_res.message);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Change Password - onsubmit - Exception thrown",
          "Talent App - Change Password",
          JSON.stringify(e.message)
        );
        
      }
    }
  };

  return (
    <IonPage className="changepwd_view">
      <SimpleHeaderView
        title="Change Password"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <ChangePasswordView
          passwordtype="1"
          callbackFromParent={onsubmit}
        ></ChangePasswordView>
      </IonContent>
      <Modal
        title={title}
        description={description}
        buttonText={buttonText}
        isOpen={showModal}
        closeAction={closeModal}
      ></Modal>
    </IonPage>
  );
};

export default ChangePassword;

import {
  IonButton, IonContent, IonModal
} from '@ionic/react';
import React from 'react';
import './Modal.css';

type ModalProps = {
  logo?: string;
  title?: string;
  description: string;
  buttonText:string;
  isOpen: boolean;
  closeAction: Function;
};

const Modal: React.FC<ModalProps> = (props) => {
  const { logo, title, description,buttonText, isOpen, closeAction } = props;

  return (
    <IonModal isOpen={isOpen} cssClass='my-custom-class' mode="md">
      <IonContent className='ion-padding' >
        <div className='modal-content'>
        {logo &&  <img src={logo} alt="" className="image-wrapper-modal" />}
        <h4 className="small-modal-title">{title}</h4>
        <div className="modal-description">{description}</div>
        <IonButton onClick={() => closeAction()}>{buttonText}</IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default Modal;
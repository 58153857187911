import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonBackButton,
} from "@ionic/react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import "./W9Form.css";
import { getStatesForSignUp } from "../../../../data-services/signup";
import { useStore } from "react-redux";
import { get } from "../../../../storage/storage";
import { saveWorkFormw9 } from "../../../../data-services/home-api";
import {
  showToastMessage,
  unauthorizedErrMsg,
  somethingWentWrongErrMsg
} from "../../../../common/common";
import back_arrow from "../../../../assets/images/common/arrow-left.png";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/auth";
import { logError } from "../../../../data-services/assignmets";

type ModalProps = {
  isOpen_w9_form: boolean;
  closeW9Form: Function;
};

const W9Form: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_w9_form, closeW9Form } = props;
  const [certificationOpt1, setCertificationOpt1] = useState<boolean>(false);
  const [certificationOpt2, setCertificationOpt2] = useState<boolean>(false);
  const [certificationOpt3, setCertificationOpt3] = useState<boolean>(false);
  const [isAgreeOpt, setIsAgreeOpt] = useState<boolean>(false);
  const [w9FormStateList, setW9FormStateList] = useState<any>("");
  const [isTalentIndividaul, setTalnetIndividaul] = useState<string>("1");
  const [w9Country, setW9Country] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const [institutionFullName, setInstitutionFullName] = useState<string>("");
  const [institutionShortForm, setInstitutionShortForm] = useState<string>("");
  const [citizenship, setCitizenship] = useState<string>("");
  const [securityNumberLabel, setSecurityNumberLabel] = useState<string>("SSN");
  const [securityNumberPlaceholder, setSecurityNumberPlaceholder] = useState<string>("000-000-000");
  const [modelCountry, setModelCountry] = useState<string>("USA");
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      w9_talent_fname: "",
      w9_talent_lname: "",
      w9_talent_type: "",
      w9_company: "",
      w9_address: "",
      w9_state: "",
      w9_city: "",
      w9_zipcode: "",
      w9_ssn: "",
      w9_emp_number: "",
      w9_cerificate_opt_1: false,
      w9_cerificate_opt_2: false,
      w9_cerificate_opt_3: false,
      w9_agree_opt: false,
    },
    mode: "onBlur",
  });
  const w9FormSubmitHandler = async () => {
    if (!certificationOpt1) {
      setError("w9_cerificate_opt_1", {
        type: "manual",
        message: "This field is required.",
      });
      return;
    }
    if (!certificationOpt2) {
      setError("w9_cerificate_opt_2", {
        type: "manual",
        message: "This field is required.",
      });
      return;
    }
    if (!certificationOpt3) {
      setError("w9_cerificate_opt_3", {
        type: "manual",
        message: "This field is required.",
      });
      return;
    }
    if (!isAgreeOpt) {
      setError("w9_agree_opt", {
        type: "manual",
        message: "This field is required.",
      });
      return;
    }

    let w9_form_values = {
      model_fname: getValues("w9_talent_fname"),
      model_lname: getValues("w9_talent_lname"),
      model_individual: getValues("w9_talent_type"),
      model_company_name: getValues("w9_company"),
      model_address: getValues("w9_address"),
      model_state: getValues("w9_state"),
      model_city2: getValues("w9_city"),
      model_zipcode: getValues("w9_zipcode"),
      model_social_number: getValues("w9_ssn"),
      model_company_number: getValues("w9_emp_number"),
      model_certification_option1: certificationOpt1,
      model_certification_option2: certificationOpt1,
      model_certification_option3: certificationOpt1,
    };
    try {
      let api_res = await saveWorkFormw9(w9_form_values);
      if (api_res?.success) {
        showToastMessage(api_res.message);
        closeW9Form();
      } else {
        showToastMessage(api_res.message);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "W9 Form - w9FormSubmitHandler - Exception thrown",
          "Talent App - W9 Form",
          JSON.stringify(e.message)
        );
        
      }
    }
  };
  const formatssnNumber = (event: any) => {
    let ss_n = event.target.value;

    if (/^[0-9\ \)\(\-\/]+$/.test(ss_n)) {
      // check is number

      let cleaned = ("" + ss_n).replace(/\D/g, "");
      let formatted_no: any = "";
      let match: any = [];

      if(modelCountry != "USA") {
        if (cleaned.length === 3) {
          match = cleaned.match(/^(\d{3})$/);
          formatted_no = `${match[1]}-`;
        } else if (cleaned.length === 6) {
          match = cleaned.match(/^(\d{3})(\d{3})$/);
          formatted_no = `${match[1]}-${match[2]}-`;
        } else if (cleaned.length === 9) {
          match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
          formatted_no = `${match[1]}-${match[2]}-${match[3]}`;
        }
      } else {
        if (cleaned.length === 3) {
          match = cleaned.match(/^(\d{3})$/);
          formatted_no = `${match[1]}-`;
        } else if (cleaned.length === 5) {
          match = cleaned.match(/^(\d{3})(\d{2})$/);
          formatted_no = `${match[1]}-${match[2]}-`;
        } else if (cleaned.length === 9) {
          match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
          formatted_no = `${match[1]}-${match[2]}-${match[3]}`;
        }
      }

      if (formatted_no) {
        setValue("w9_ssn", formatted_no);
        return;
      }
    }
    // return null
  };
  useEffect((): any => {
    let isSubscribed = true;
    (async () => {
      if (isSubscribed) {
        setValue("w9_talent_fname", await get("login_model_first_name"));
        setValue("w9_talent_lname", await get("login_model_last_name"));
        setValue("w9_address", await get("login_model_address"));
        setValue("w9_state", await get("login_model_state"));
        setValue("w9_city", await get("login_model_city"));
        setValue("w9_zipcode", await get("login_model_zipcode"));
        setModelCountry(await get("login_model_country"));
        if(await get("login_model_country") === "USA"){
          setAddress("and Push Models, LLC. (\"Company\"), located at 414 S. Mill Avenue, Suite 214, Tempe, AZ 85281.");
          setDocumentType("W9");
          setInstitutionFullName("Internal Revenue Service (IRS)");
          setInstitutionShortForm("IRS");
          setCitizenship("U.S.");
          setSecurityNumberLabel("SSN");
          setSecurityNumberPlaceholder("000-00-0000")
        
        } else {
          setAddress("and Push Models Canada, INC. (\"Company\") located at 2818 Main Street, Suite 542 Vancouver, BCV5T 0C1.");
          setDocumentType("T4");
          setInstitutionFullName("Canadian Revenue Agency (CRA)");
          setInstitutionShortForm("CRA");
          setCitizenship("Canadian");
          setSecurityNumberLabel("SIN");
          setSecurityNumberPlaceholder("000-000-000")
        }

        setW9FormStateList(await getStatesForSignUp(await get("login_model_country")));
        let data = await getStatesForSignUp(await get("login_model_country"));
        if (isSubscribed) setW9FormStateList(data);
      }
    })();
    return () => (isSubscribed = false);
  }, [isTalentIndividaul]);

  return (
    <IonModal isOpen={isOpen_w9_form} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeW9Form();
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(w9FormSubmitHandler)}>
          <section className="w9-form-sec">
            <div className="w9-form-sec__title">
              <h6>{documentType} DOCUMENT GENERATOR</h6>
              <p>
                Below is the form that will generate your {documentType}. Please confirm
                that the information below is accurate. It is VERY IMPORTANT to
                use your legal name, and NOT your stage name, as this is a legal
                document. Please make updates if needed.
              </p>
            </div>
            <div>
              <div className="form-group">
                <IonLabel className="form-group__label">
                  Legal First Name
                </IonLabel>
                <input
                  type="text"
                  placeholder="John"
                  className={
                    errors.w9_talent_fname !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                
                  {...register("w9_talent_fname", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="w9_talent_fname"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="form-group">
                <IonLabel className="form-group__label">
                  Legal Last Name
                </IonLabel>
                <input
                  type="text"
                  placeholder="Doe"
                  className={
                    errors.w9_talent_lname !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
               
                  {...register("w9_talent_lname", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="w9_talent_lname"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="form-group">
                <IonLabel className="form-group__label">
                  Select Appropriate Type
                </IonLabel>
                <IonSelect
                  placeholder="--Select--"
                  className={
                    errors.w9_talent_type !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("w9_talent_type", {
                    required: "This field is required.",
                  })}
                  onIonChange={(e: any) => {
                    setTalnetIndividaul(e.detail.value);
                  }}
                >
                  <IonSelectOption value="1">
                    Individual/Sole proprietor
                  </IonSelectOption>
                  <IonSelectOption value="0">Corporation</IonSelectOption>
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="w9_talent_type"
                  as={<div className="error-message" />}
                />
              </div>
              {isTalentIndividaul === "0" && (
                <div className="form-group">
                  <IonLabel className="form-group__label">Company</IonLabel>
                  <input
                    type="text"
                    placeholder="Company name"
                    className={
                      errors.w9_company !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                
                    {...register("w9_company", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w9_company"
                    as={<div className="error-message" />}
                  />
                </div>
              )}

              <div className="form-group">
                <IonLabel className="form-group__label">Address</IonLabel>
                <input
                  type="text"
                  placeholder="Street number & name"
                  className={
                    errors.w9_address !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                 
                  {...register("w9_address", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="w9_address"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="form-group">
                <IonLabel className="form-group__label">State</IonLabel>
                <IonSelect
                  placeholder="--Select--"
                  className={
                    errors.w9_state !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("w9_state", {
                    required: "This field is required.",
                  })}
                >
                  {Object.keys(w9FormStateList).map(
                    (state_key, state_index) => (
                      <IonSelectOption key={state_key} value={state_key}>
                        {w9FormStateList[state_key]}
                      </IonSelectOption>
                    )
                  )}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="w9_state"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="form-group">
                <IonLabel className="form-group__label">City</IonLabel>
                <input
                  type="text"
                  placeholder="Eg. Los Angeles"
                  className={
                    errors.w9_city !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
               
                  {...register("w9_city", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="w9_city"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="form-group">
                <IonLabel className="form-group__label">Zip code</IonLabel>
                <input
                  type="tel"
                  placeholder="Eg. 83971"
                  className={
                    errors.w9_zipcode !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
               
                  {...register("w9_zipcode", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="w9_zipcode"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label">{securityNumberLabel}</IonLabel>
                <input
                  type="tel"
                  placeholder={securityNumberPlaceholder}
                  className={
                    errors.w9_ssn !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                
                  {...register("w9_ssn", {
                    required: "This field is required.",
                  })}
                  onInput={formatssnNumber}
                  maxLength={11}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="w9_ssn"
                  as={<div className="error-message" />}
                />
              </div>
              {isTalentIndividaul === "0" && (
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Employer Identification Number
                  </IonLabel>
                  <input
                    type="tel"
                    placeholder="Employer Identification Number"
                    className={
                      errors.w9_emp_number !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
             
                    {...register("w9_emp_number", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w9_emp_number"
                    as={<div className="error-message" />}
                  />
                </div>
              )}
            </div>

            <div className="w9-form-sec__title">
              <h6>Certification</h6>
              <p>Under penalties of perjury I certify that:</p>
            </div>
            <div className="w9-form-sec__certifcate">
              <div className="w9-form-sec__certifcate--1">
                <div>
                  <IonCheckbox
                    className="form-group__checkbox-styles"
                    mode="md"
                    {...register("w9_cerificate_opt_1", {
                      required: "This field is required.",
                    })}
                    checked={certificationOpt1}
                    onIonChange={(e) => setCertificationOpt1(e.detail.checked)}
                  ></IonCheckbox>
                </div>
                <IonLabel className="form-group__label">
                  1. The number shown on this form is my correct taxpayer
                  identification number (or I am waiting for a number to be
                  issued to me), and
                </IonLabel>
              </div>

              <ErrorMessage
                errors={errors}
                name="w9_cerificate_opt_1"
                as={<div className="error-message w9-form-sec__error-message-checkbox-text" />}
              />
              <div className="w9-form-sec__certifcate--2">
                <div>
                  <IonCheckbox
                    className="form-group__checkbox-styles"
                    mode="md"
                    {...register("w9_cerificate_opt_2", {
                      required: "This field is required.",
                    })}
                    checked={certificationOpt2}
                    onIonChange={(e) => setCertificationOpt2(e.detail.checked)}
                  ></IonCheckbox>
                </div>
                <IonLabel className="form-group__label">
                  2. I am not subject to backup withholding because: (a) I am
                  exempt from backup withholding, or (b) I have not been
                  notified by the {institutionFullName} that I am
                  subject to backup withholding as a result of a failure to
                  report all interest or dividends, or (c) the {institutionShortForm} has notified
                  me that I am no longer subject to backup withholding, and
                </IonLabel>
              </div>

              <ErrorMessage
                errors={errors}
                name="w9_cerificate_opt_2"
                as={<div className="error-message w9-form-sec__error-message-checkbox-text" />}
              />
              <div className="w9-form-sec__certifcate--3">
                <div>
                  <IonCheckbox
                    className="form-group__checkbox-styles"
                    mode="md"
                    {...register("w9_cerificate_opt_3", {
                      required: "This field is required.",
                    })}
                    checked={certificationOpt3}
                    onIonChange={(e) => setCertificationOpt3(e.detail.checked)}
                  ></IonCheckbox>
                </div>
                <IonLabel className="form-group__label">
                  3. I am a {citizenship} citizen or other {citizenship} person (defined below).
                </IonLabel>
              </div>

              <ErrorMessage
                errors={errors}
                name="w9_cerificate_opt_3"
                as={<div className="error-message w9-form-sec__error-message-checkbox-text" />}
              />
            </div>
            <div className="w9-form-sec__title">
              <h6>Certification Instructions</h6>
              <p>
                You must cross out item 2 above if you have been notified by the {institutionShortForm} that you are currently subject to backup withholding because
                you have failed to report all interest and dividends on your tax
                return. For real estate transactions, item 2 does not apply. For
                mortgage interest paid, acquisition or abandonment of secured
                property, cancellation of debt, contributions to an individual
                retirement arrangement (IRA), and generally, payments other than
                interest and dividends, you are not required to sign the
                Certification, but you must provide your correct TIN. See the
                instructions on page 4.
              </p>
            </div>
            <div className="w9-form-sec__certifcate--4">
              <div>
                <IonCheckbox
                  className="form-group__checkbox-styles"
                  mode="md"
                  {...register("w9_agree_opt", {
                    required: "This field is required.",
                  })}
                  checked={isAgreeOpt}
                  onIonChange={(e) => setIsAgreeOpt(e.detail.checked)}
                ></IonCheckbox>
              </div>
              <IonLabel className="form-group__label">
                I certify the information about is accurate
              </IonLabel>
            </div>

            <ErrorMessage
              errors={errors}
              name="w9_agree_opt"
              as={<div className="error-message w9-form-sec__error-message-checkbox-text" />}
            />
            <div className="w9-form-sec__submit--btn">
              <IonButton
                expand="block"
                mode="ios"
                type="submit"
                className="form-group__button"
              >
                Submit
              </IonButton>
            </div>
          </section>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default W9Form;

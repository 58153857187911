import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonRadioGroup,
  IonCard,
  IonRadio,
  IonLabel,
  IonCardContent,
  IonButton,
  IonCardTitle,
  IonCheckbox,
} from "@ionic/react";
import "./MyRewards.css";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { authActions } from "../../store/auth";
import roundArrowIcon from "../../assets/images/score-board/icons/round-arrow-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import chevron_right from "../../assets/images/common/chevron-right.svg";
import orange_heart from "../../assets/images/common/orange_heart2.svg";
import outlined_heart from "../../assets/images/common/outlined_heart.svg";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import {
  getAvailableRewardsByCategories,
  getAvailableRewardsByCategory,
  setRewardFavoriteStatus,
} from "../../data-services/scoreboard";
import ItemRedeemCard from "../../components/ItemRedeemCard/ItemRedeemCard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import RedeemRewardModal from "./RedeemRewardModal";
import ClaimRewardSuccessModal from "./ClaimRewardSuccessModal";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import Loading from "../../push-components/loading/Loading";
import check_icon from "../../assets/images/common/check.png";
import check_black from "../../assets/images/common/check_black.svg";
import fireball_icon from "../../assets/images/rewards/fireball.png";
import sold_out_icon from "../../assets/images/rewards/sold_out.png";
import featured_brand from "../../assets/images/rewards/featured_brand.png";

const StarIcon = <FontAwesomeIcon icon={faStar} />;

const RewardCategory: React.FC = () => {
  const history = useHistory();
  const op_url_param: any = useParams();
  const dispatch = useDispatch();
  const [isOpenRewardModal, setIsOpenRewardModal] = useState<boolean>(false);
  const [talenRewardId, setTalenRewardId] = useState<string>("");
  const [targetReward, setTargetReward] = useState<any>("");
  const [targetAttributesValuesChecks, setTargetAttributesValuesChecks] =
    useState<any>("");
  const [rewardsArray, setRewardsArray] = useState<any>([]);
  const [categoryName, setCategoryName] = useState<any>("");
  const [checkedAttributesValues, setCheckedAttributesValues] = useState<any>(
    {}
  );
  const [favoriteRewardsStatus, setFavoriteRewardsStatus] = useState<any>({});
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);

  const [claimRewardSuccessMsg, setClaimRewardSuccessMsg] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);

  const closeRewardModal = (title: string = "", message: string = "") => {
    setIsOpenRewardModal(false);
    setIsViewOnly(false);
    if (title && message) {
      setClaimRewardSuccessMsg({ title: title, message: message, show: true });
    }
  };

  const navigatetohistory = () => {
    history.push("/RewardHistory");
  };

  const mrInit = async () => {
    setWaitingForApiResonse(true);
    let rewards = await getAvailableRewardsByCategory(
      op_url_param.category_id,
      10
    );
    if (rewards?.success) {
      if (rewards?.data.rewards) {
        setRewardsArray(rewards?.data.rewards);
        setCategoryName(rewards?.data.category_name);
        // Initialize object of rewards / attributes / values with all values as false (nothing is checked)
        for (const reward of rewards.data.rewards) {
          checkedAttributesValues["reward" + reward.model_reward_id] = {};
          favoriteRewardsStatus["reward" + reward.model_reward_id] =
            reward.favorite_reward;
          if (reward?.attributes?.length) {
            for (const attribute of reward.attributes) {
              checkedAttributesValues["reward" + reward.model_reward_id][
                "attribute" + attribute.attribute.ra_id
              ] = {};
              if (attribute?.values?.length) {
                for (const value of attribute.values) {
                  checkedAttributesValues["reward" + reward.model_reward_id][
                    "attribute" + attribute.attribute.ra_id
                  ]["value" + value.marav_id] = false;
                }
              }
            }
          }
        }
        setCheckedAttributesValues(checkedAttributesValues);
        setFavoriteRewardsStatus(favoriteRewardsStatus);

        if(op_url_param?.reward_id) {
          redeemRewardHandler(op_url_param.reward_id, true, rewards?.data.rewards);
        }

      }
    } else if (rewards?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (rewards?.data?.errorCode == offlineErrorCode) {
      showToastMessage(rewards?.message);
    } else if (rewards?.message) {
      await showToastMessage(rewards.data.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setWaitingForApiResonse(false);
  };
  const redeemRewardHandler = (redeemId: string, viewOnly?: any, rewards?: any) => {
    // Check if the current reward has attributes
    // If so, parse the selected attribute ID and value ID
    let checkedAttributesValuesCount = 0;
    let targetReward: any;
    let targetAttributesValuesChecks = [];
    let uncheckedAttributeName = "";
    let rewardsToLoop = !rewardsArray?.length && rewards?.length ? rewards : rewardsArray;
    for (const reward of rewardsToLoop) {
      if (reward.model_reward_id == redeemId) {
        targetReward = reward;
        targetAttributesValuesChecks =
          checkedAttributesValues["reward" + redeemId];
        if (reward?.attributes?.length) {
          // For each Attribute, check that we have at least one selected value and retrieve it!
          for (const attribute of reward.attributes) {
            // Validate only for Color and Flavor attributes as they are the only attributes that may appear in the Reward Category list
            if (
              attribute.attribute.ra_name == "Color" ||
              attribute.attribute.ra_name == "Flavor"
            ) {
              if (attribute?.values?.length) {
                let noValueChecked = true;
                for (const value of attribute.values) {
                  if (
                    checkedAttributesValues["reward" + redeemId][
                      "attribute" + attribute.attribute.ra_id
                    ]["value" + value.marav_id] === true
                  ) {
                    checkedAttributesValuesCount++;
                    noValueChecked = false;
                  }
                }
                if (noValueChecked) {
                  uncheckedAttributeName = attribute.attribute.ra_name;
                  break;
                }
              }
            } else {
              checkedAttributesValuesCount++;
            }
          }
        }
      }
    }
    if(targetReward) {
      setIsViewOnly(viewOnly);
      setTalenRewardId(redeemId);
      setTargetReward(targetReward);
      setTargetAttributesValuesChecks(targetAttributesValuesChecks);
      setIsOpenRewardModal(true);
    } else {
      showToastMessage('Reward is no longer available!');
    }
    
    
  };
  const closeClaimdModal = async () => {
   
    setClaimRewardSuccessMsg({ title: "", message: "", show: false });
    setIsViewOnly(false);
    await mrInit();
  };

  const favoriteRewardHandler = async (rewardId: any) => {
    // Create a copy of the current favoriteRewardsStatus object so the state hook triggers when setting favoriteRewardsStatus
    let newArray = Object.assign({}, favoriteRewardsStatus);

    // Iterate through the rewardsArray and set the proper favorite status ONLY for the given reward
    for (const reward of rewardsArray) {
      if (reward.model_reward_id == rewardId) {
        newArray["reward" + reward.model_reward_id] =
          favoriteRewardsStatus["reward" + reward.model_reward_id] == 1 ? 0 : 1;
        setRewardFavoriteStatus(
          rewardId,
          newArray["reward" + reward.model_reward_id]
        );
      }
    }
    setFavoriteRewardsStatus(newArray);
  };

  const attributeValueHandler = (
    reward_id: any,
    attribute_id: any,
    value_id: any,
    amount: any,
  ) => {
    if(amount == 0) {
      return;
    }
    // Create a copy of the current checkedAttributesValues object so the state hook triggers when setting checkedAttributesValues
    let newArray = Object.assign({}, checkedAttributesValues);

    // Iterate through the rewardsArray and set the proper values ONLY for the given reward_id/ attribute_id
    for (const reward of rewardsArray) {
      if (reward.model_reward_id == reward_id) {
        if (reward?.attributes?.length) {
          for (const attribute of reward.attributes) {
            if (attribute.attribute.ra_id == attribute_id) {
              if (attribute?.values?.length) {
                for (const value of attribute.values) {
                  // Value is true if the current value is equal to the provided one (value_id), false otherwise
                  newArray["reward" + reward.model_reward_id][
                    "attribute" + attribute.attribute.ra_id
                  ]["value" + value.marav_id] = value.marav_id == value_id;
                }
              }
            }
          }
        }
      }
    }
    setCheckedAttributesValues(newArray);
  };

  useEffect(() => {
    (async () => {
      await mrInit();
    })();
  }, []);

  return (
    <>
      <IonPage>
        <SimpleHeaderView
          title={categoryName}
          bgcolor="primary"
          buttonType="roundArrowIcon"
          iconImgSrc={roundArrowIcon}
          clickEventHandler={navigatetohistory}
        ></SimpleHeaderView>
        <IonContent>
          {rewardsArray.length > 0 && (
            <div className="my_reward_list">
              {rewardsArray.map((reward: any, index: any) => (
                <IonCard className={reward?.mar_amount == 0 ? "my_reward_list__card my_reward_list__card--sold-out" : "my_reward_list__card"} key={index}>
                  <div className={reward?.mar_amount == 0 ? "my_reward_list__card_img-container my_reward_list__card_img-container--sold-out" : "my_reward_list__card_img-container"}>
                    <div className="my_reward_list__card_badge">
                      {`${Math.round(
                        Number(reward.model_reward_points)
                      )} POINT` + ( Number(reward.model_reward_points) != 1 ? "S" : "")}
                    </div>
                    <div className="my_reward_list__card_favorite">
                      <img
                        src={
                          favoriteRewardsStatus[
                            "reward" + reward.model_reward_id
                          ] == 1
                            ? orange_heart
                            : outlined_heart
                        }
                        onClick={() =>
                          favoriteRewardHandler(reward.model_reward_id)
                        }
                      ></img>
                    </div>
                    <div className="my_reward_list__card_img__parent">
                    <img
                      src={reward.image}
                      className="my_reward_list__card_img"
                      onClick={() =>
                        redeemRewardHandler(reward.model_reward_id, true)
                      }
                      alt=""
                    />
                    </div>
                    
                    {reward?.mar_amount == 0 && (<img
                      src={sold_out_icon}
                      className="my_reward_list__card_img__sold-out"
                      alt=""
                    />)}

                    <div className="my_reward_list__card_extra">
                      {(reward?.featured_brand) && (<div className="my_reward_list__card_featured_brand">
                        <img src={featured_brand}></img><span>FEATURED BRAND</span>
                      </div>)}
                      {(reward?.mar_amount <= 10 && reward?.mar_amount > 0) && (<div className="my_reward_list__card_left">
                        <img src={fireball_icon}></img><span>Only {reward?.mar_amount} left!</span>
                      </div>)}
                    </div>
                    
                  </div>
                  <IonCardContent className="my_reward_list__card_content">
                    <div>
                      <IonCardTitle className="my_reward_list__card_lesser_title">
                        {reward.model_reward_name}
                      </IonCardTitle>
                      <p>{reward.mar_subtitle}</p>
                    </div>
                    <div className="my_reward_list__card_content__attributes">
                      {reward.attributes.map((attribute: any, index: any) => (
                        <div>
                          Multiple {attribute.attribute.ra_name}s
                        </div>
                      ))}
                    </div>
                    

                    <div>
                    {reward.mar_msrp != 0 && (
                      <p className="my_reward_list__card_content__msrp">
                        MSRP: {reward.mar_msrp}
                      </p>
                    )}
                      <IonButton
                        onClick={() =>
                          redeemRewardHandler(reward.model_reward_id)
                        }
                        expand="block"
                        className="my_reward_list__card_btn"
                        disabled={reward.track_percentage != 100 || reward?.mar_amount == 0}
                        color= {(reward.track_percentage != 100 || reward?.mar_amount == 0) ? "medium": "primary"}
                      >
                        REDEEM
                      </IonButton>
                      <p className="my_reward_list__card_text">
                        <span className="my_reward_list__card_text--normal">
                          {`Track this goal: ${reward.track_percentage}%`}
                        </span>
                        <span className="my_reward_list__card_text--small-width">
                          {`Goal: ${reward.track_percentage}%`}
                        </span>
                        
                      </p>

                    </div>
                  </IonCardContent>
                </IonCard>
              ))}
            </div>
          )}
          {(!waitingForApiResonse && rewardsArray.length == 0) && (
            <EmptyScreen title="No Rewards" description="" />
          )}
        </IonContent>
        <RedeemRewardModal
          rewardModalType="save_modal"
          isOpenRewardModal={isOpenRewardModal}
          closeRewardModal={closeRewardModal}
          talenRewardId={talenRewardId}
          targetReward={targetReward}
          targetAttributesValuesChecks={targetAttributesValuesChecks}
          viewOnly={isViewOnly}
        />
        <ClaimRewardSuccessModal
          isOpenClaimModal={claimRewardSuccessMsg.show}
          closeClaimdModal={closeClaimdModal}
          title_message={{
            title: claimRewardSuccessMsg.title,
            message: claimRewardSuccessMsg.message,
          }}
        />
        <Loading showLoading={waitingForApiResonse} />
      </IonPage>
    </>
  );
};

export default RewardCategory;

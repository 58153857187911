import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonTextarea,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import "./NewCustomerReferral.css";
import back_arrow from "../../../assets/images/common/arrow-left.png";
import { createCustomerReferral } from "../../../data-services/edit-profile";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
  validatePhoneNumber,
  formatPhoneNumber,
} from "../../../common/common";
import { arrowBackOutline } from "ionicons/icons";
type ModalProps = {
  is_open_referral: boolean;
  closeReferral: Function;
};

const NewCustomerReferral: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { is_open_referral, closeReferral } = props;
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false); // true if phone number is valid

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contact_name: "",
      company_name: "",
      contact_email: "",
      contact_phone: "",
      additional_details: "",
    },
    mode: "onBlur",
  });
  const newReferralSubmitHandler = async () => {
    if (
      getValues("contact_phone").length &&
      !validatePhoneNumber(getValues("contact_phone"))
    ) {
      setError("contact_phone", {
        type: "manual",
        message: "Invalid phone number!",
      });
      showToastMessage("Invalid phone number!");
      return false;
    }

    setDisabledSubmitBtn(true);

    let form_date: any = {
      ucr_first_name: getValues("contact_name"),
      ucr_company_name: getValues("company_name"),
      ucr_email: getValues("contact_email").trim(),
      ucr_phone: getValues("contact_phone"),
      ucr_details: getValues("additional_details"),
    };
    let api_res = await createCustomerReferral(form_date);
    if (api_res?.success) {
      await showToastMessage(api_res.message);
      closeReferral();
    } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (api_res?.data?.errorCode == offlineErrorCode) {
      showToastMessage(api_res?.data?.message);
    } else if (api_res?.message) {
      showToastMessage(api_res.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setDisabledSubmitBtn(false);
  };

  const formatAndSetPhoneNumber = (event: any) => {
    let phone_no = event.target.value;

    let formatted_no = formatPhoneNumber(phone_no);

    if (formatted_no) {
      setValue("contact_phone", formatted_no);
      setIsValidPhone(validatePhoneNumber(formatted_no));
      return;
    } else {
      setIsValidPhone(false);
    }
  };
  return (
    <IonModal isOpen={is_open_referral} mode="ios">
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>New Referral</IonTitle>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeReferral();
              }}
            >
              <IonIcon slot="icon-only" icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="new_referral">
          <form onSubmit={handleSubmit(newReferralSubmitHandler)}>
            <h1 className="new_referral__title">Customer Referral</h1>
            <IonText className="new_referral__text">
              Do you know a customer that could use our services? Refer a
              business and earn points when they become a customer.
            </IonText>
            <span className="new_referral__text new-referral__title__earn-points">
              + Earn 500 Points
            </span>
            <div className="form-group">
              <IonLabel className="form-group__label form-group__label--required">
                Contact name
              </IonLabel>
              <input
                type="text"
                placeholder="John Doe"
                className={
                  errors.contact_name !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("contact_name", {
                  required: "This field is required.",
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name="contact_name"
                as={<div className="error-message" />}
              />
            </div>

            <div className="form-group">
              <IonLabel className="form-group__label form-group__label--required">
                Customer name
              </IonLabel>
              <input
                type="text"
                placeholder="Acme Inc."
                className={
                  errors.company_name !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("company_name", {
                  required: "This field is required.",
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name="company_name"
                as={<div className="error-message" />}
              />
            </div>
            <div className="form-group">
              <IonLabel className="form-group__label form-group__label--required">
                Email address
              </IonLabel>
              <input
                type="text"
                placeholder="Type an email"
                className={
                  errors.contact_email !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("contact_email", {
                  required: "This field is required.",
                  pattern: {
                    value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
                    message: "Invalid email address",
                  },
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name="contact_email"
                as={<div className="error-message" />}
              />
            </div>
            <div className="form-group">
              <IonLabel className="form-group__label form-group__label--required">
                Phone number
              </IonLabel>
              <input
                type="tel"
                placeholder="(___) ___ ____"
                className={
                  errors.contact_phone !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("contact_phone", {
                  required: "This field is required.",
                })}
                maxLength={14}
                onInput={formatAndSetPhoneNumber}
              ></input>
              <ErrorMessage
                errors={errors}
                name="contact_phone"
                as={<div className="error-message" />}
              />
            </div>
            <div className="form-group">
              <IonLabel className="form-group__label">
                Additional Notes/Comments
              </IonLabel>
              <IonTextarea
                className="form-group__input-styles"
                {...register("additional_details", {})}
                placeholder="Feel free to provide any context or information about the company's needs or preferences that could help us understand the referral better."
                rows={5}
                mode="md"
              ></IonTextarea>
            </div>

            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button new_referral__submit_btn"
              disabled={disabledSubmitBtn}
            >
              Submit
            </IonButton>
          </form>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default NewCustomerReferral;

import react, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/auth";
import "./AddVaccination.css";
import VaccineFileUploader from "../../../../push-components/ProfileFileuploader/VaccineFileUploader";
import {
  getUserCameraPhoto,
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrMsg,
  offlineErrorCode
} from "../../../../common/common";
import back_arrow from "../../../../assets/images/common/arrow-left.png";
import { saveTalentVaccineAnswer } from "../../../../data-services/home-api";
import { decode } from "base64-arraybuffer";
import close_icon from "../../../../assets/images/common/close_x_circle.png";
type ModalProps = {
  isOpen_add_vaccine: boolean;
  closeAddVaccine: Function;
};
const AddVaccination: react.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_add_vaccine, closeAddVaccine } = props;
  const [takeVaccineImage, setTakeVaccineImage] = useState<any>({});
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(true);
  const [usedCameraOrPhoto, setUsedCameraOrPhoto] = useState<string>("photo");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<string>("");
  const [dropdownError, setdropdownError] = useState<boolean>(false);
  const [vaccineFileError, setVaccineFileError] = useState<boolean>(false);

  const isImageSelect = useCallback((type) => {
    setShowSubmitButton(true);
    setUsedCameraOrPhoto("photo");
  }, []);
  const removeLibraryPhoto = useCallback((type) => {
    setShowSubmitButton(false);
    setUsedCameraOrPhoto("photo");
    setTakeVaccineImage({});
  }, []);

  const takeVaccinePhoto = async () => {
    let image: any = await getUserCameraPhoto("base64");
    if (image?.base64String) {
      if (image.base64String) {
        setTakeVaccineImage({
          name: `vaccine_image.${image.format}`,
          type: "image/jpeg",
          size: 789,
          file: image.base64String,
          data: "",
          ui_image: "data:text/plain;base64," + image.base64String,
          extension: image.format,
        });
      }
    }
    setShowSubmitButton(true);
    setUsedCameraOrPhoto("camera");
  };
  const saveVaccinePhotoHandler = async (event: any) => {
    event.preventDefault();
    if (dropdownValue == "") {
      setdropdownError(true);
      return;
    }

    setDisabledSubmitBtn(true);
    let vaccine_photo: any = "";
    if (usedCameraOrPhoto === "photo") {
      vaccine_photo = new FormData(event.target);
    } else {
      vaccine_photo = new FormData();
      let camera: any = takeVaccineImage;
      let blob = new Blob([new Uint8Array(decode(camera.file))], {
        type: "image/jpeg",
      });
      let file_obj = new File([blob], camera.name, {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      });
      vaccine_photo.append("vaccinationFile", file_obj);
    }
    let isEmpty = vaccine_photo.get("vaccinationFile");
    if (dropdownValue == "1" && !isEmpty) {
      setVaccineFileError(true);
      setDisabledSubmitBtn(false);
    }

    try {
      let api_res: any = await saveTalentVaccineAnswer(vaccine_photo);
      if (api_res?.success) {
        await showToastMessage("Vaccine record added");
        closeAddVaccine();
        setShowSubmitButton(false);
        setUsedCameraOrPhoto("photo");
        setTakeVaccineImage({});
        setdropdownError(false);
        setVaccineFileError(false);
      } else {
        if(api_res?.data?.errorCode == offlineErrorCode) {
          await showToastMessage(api_res?.data?.message);
        } else if (api_res?.message) {
          showToastMessage(api_res.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
       
      }
      setDisabledSubmitBtn(false);
    } catch (e) {
      if (e == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(e);
      }
    }
  };
  const removeCameraImage = () => {
    setShowSubmitButton(false);
    setUsedCameraOrPhoto("photo");
    setTakeVaccineImage({});
  };
  const dropDownChangeHandler = (event: any) => {
    setDropdownValue(event.detail.value);
    if (event.detail.value == "1") {
      setShowSubmitButton(false);
    } else {
      setShowSubmitButton(true);
    }
  };
  useEffect(() => {}, [takeVaccineImage, usedCameraOrPhoto, dropdownValue]);
  return (
    <IonModal isOpen={isOpen_add_vaccine} cssClass="policyModal">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeAddVaccine();
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={saveVaccinePhotoHandler} className="add-vaccine_form">
          <section className="add-vaccine">
            <div className="add-vaccine__top">
              <div className="add-vaccine__top-title">
                <h1>
                  Add Vaccine Record <br />
                  Card
                </h1>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Have you received the Covid-19 vaccine?
                  </IonLabel>
                  <IonSelect
                    placeholder="--Select--"
                    className={
                      dropdownError
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    name="model_vaccinated"
                    onIonChange={(e: any) => dropDownChangeHandler(e)}
                  >
                    <IonSelectOption value="1">Yes</IonSelectOption>
                    <IonSelectOption value="2">No</IonSelectOption>
                    <IonSelectOption value="3">
                      Rather not answer
                    </IonSelectOption>
                  </IonSelect>
                  {dropdownError && (
                    <div className="error-message">This field is required.</div>
                  )}
                </div>
              </div>
              <input type="hidden" name="test_field" value="ew" />
              {dropdownValue == "1" && (
                <>
                  <p className="file_upload_label">
                    Upload your vaccine card to be considered <br /> for all
                    opportunities
                  </p>
                  {usedCameraOrPhoto === "photo" && (
                    <div className="add-vaccine__file-upload">
                      <VaccineFileUploader
                        name="vaccinationFile"
                        limit="1"
                        preloaded_file=""
                        files={[]}
                        id="vaccination_img"
                        click={isImageSelect}
                        remove={removeLibraryPhoto}
                      />
                    </div>
                  )}
                  {usedCameraOrPhoto === "camera" && (
                    <div className="add-vaccine__file-upload">
                      <img
                        src={close_icon}
                        alt=""
                        className="add-vaccine__file-uploa__close_icon"
                        onClick={removeCameraImage}
                      />
                      <img
                        src={takeVaccineImage.ui_image}
                        className="add-vaccine__file-upload__camera_img"
                        alt=""
                      />
                    </div>
                  )}
                </>
              )}
              {vaccineFileError && (
                <div className="error-message">Select file to upload.</div>
              )}
            </div>

            <div className="add-vaccine__footer">
              {!showSubmitButton && (
                <IonButton
                  size="large"
                  className="form-group__button"
                  expand="block"
                  mode="ios"
                  type="button"
                  onClick={takeVaccinePhoto}
                >
                  Take Photo
                </IonButton>
              )}

              {showSubmitButton && (
                <IonButton
                  size="large"
                  className="form-group__button"
                  expand="block"
                  mode="ios"
                  type="submit"
                  disabled={disabledSubmitBtn}
                >
                  Submit
                </IonButton>
              )}
            </div>
          </section>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default AddVaccination;

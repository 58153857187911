/** */
import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonItem,
  IonCard,
  IonDatetime,
  IonIcon,
  IonTitle,
  IonLabel,
  IonCardTitle,
  IonPopover,
  IonSpinner,
  IonButton,
} from "@ionic/react";
import "./Payments.css";
import moment from "moment";

import { useHistory } from "react-router";
import HeaderView from "../../components/HeaderView/HeaderView";
import calender_icon from "../../assets/images/common/calendar-icon.svg";
import chevron_right from "../../assets/images/common/chevron-right.svg";
import chevron_right_grey from "../../assets/images/common/chevron-right-grey.svg";
import { getTalentRevenue } from "../../data-services/revenue-api";
import PointBoard from "../../push-components/PointBoard/PointBoard";
import { scoreboardLanding } from "../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import info_circle from "../../assets/images/common/info-circle.svg";
import MyRewardSliding from "../../push-components/MyRewardSliding/MyRewardSliding";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import InfoButtonPopover from "../../push-components/InfoButtonPopover/InfoButtonPopover";
import { get, set } from "../../storage/storage";
import { invalidateOppsCache } from "../../data-services/opportunities";

const Payments: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [revenueApiData, setrevenueApiData] = useState<any>();
  const [scoreBoradData, setScoreBoardData] = useState<any>(null);
  const [showDateInfoTooltip, setShowDateInfoTooltip] = useState<string>("");
  const [modelTipaltiPaymentStatus, setModelTipaltiPaymentStatus] =
    useState<string>("");
  const [modelState, setModelState] = useState<string>("");
  const [modelCountry, setModelCountry] = useState<string>("");

  let current_date: any = moment().endOf("month");
  let year_first_date: any = moment().startOf("year");

  const [filterFromDate, setFilterFromDate] = useState<string>(
    year_first_date.format("YYYY-MM-DD")
  );
  const [filterToDate, setFilterToDate] = useState<string>(
    current_date.format("YYYY-MM-DD")
  );

  const getRevenueData = async (start_date: string, end_date: string) => {
    let revenue_data = await getTalentRevenue(start_date, end_date);
    if (revenue_data?.success) {
      setrevenueApiData(revenue_data.data);
    } else if (revenue_data?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (revenue_data?.data?.errorCode == offlineErrorCode) {
      showToastMessage(revenue_data?.data?.message);
    } else if (revenue_data?.message) {
      showToastMessage(revenue_data.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };
  const gotopaidrevenue = () => {
    history.push("/PaidRevenue");
  };

  useEffect(() => {
    (async () => {
      await getRevenueData(filterFromDate, filterToDate);
      let scoreboard_api = await scoreboardLanding(
        filterFromDate,
        filterToDate
      );
      if (scoreboard_api?.success) {
        setScoreBoardData(scoreboard_api.data);
        setModelTipaltiPaymentStatus(
          scoreboard_api.data.model_tipalti_payment_status
        );
        if(await get("login_model_tipalti_payment_status") != scoreboard_api.data.model_tipalti_payment_status) {
          invalidateOppsCache();
          await set("login_model_tipalti_payment_status", scoreboard_api.data.model_tipalti_payment_status)
        }

        setModelState(await get("login_model_state"));
        setModelCountry(await get("login_model_country"));
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    })();
  }, [filterFromDate, filterToDate]);

  return (
    <>
      <IonPage>
        <HeaderView></HeaderView>
        <IonContent className="pricing-view">
          <IonCard className="payment-calendar">
            <IonIcon src={calender_icon} className="calender_icon" />
            <div className="payment-calendar_from_to">
              <IonDatetime
                doneText="Done"
                cancelText=""
                onIonChange={(e: any) =>
                  setFilterFromDate(
                    moment(e.detail.value).startOf("month").format("YYYY-MM-DD")
                  )
                }
                className="payment-calendar__calendar_from"
                displayFormat="MMM YYYY"
                placeholder={moment().startOf("year").format("MMM YYYY")}
                name="from_date"
              ></IonDatetime>
              <IonDatetime
                doneText="Done"
                cancelText=""
                onIonChange={(e: any) =>
                  setFilterToDate(
                    moment(e.detail.value).endOf("month").format("YYYY-MM-DD")
                  )
                }
                className="payment-calendar__calendar_to"
                displayFormat="MMM YYYY"
                placeholder={moment().format("MMM YYYY")}
                name="to_date"
              ></IonDatetime>
            </div>
            <IonIcon
              onClick={() => setShowDateInfoTooltip("revenue_date_range")}
              src={info_circle}
              className="chevron_icon"
            />
          </IonCard>
          <div className="total-revenu-container">
            <IonItem className="total-revenu__title">
              <IonTitle className="total-revenu__title__container" slot="start">
                My Payments
                <IonIcon
                  onClick={() => setShowDateInfoTooltip("revenue_my_revenue")}
                  src={info_circle}
                  className="chevron_icon"
                />
              </IonTitle>
              {(revenueApiData?.paid_revenue_events?.length > 0 ||
                revenueApiData?.pending_revenue_events?.length > 0) && (
                <div
                  slot="end"
                  className="total-revenu__title__view_all"
                  onClick={gotopaidrevenue}
                >
                  See all&nbsp;
                  <IonIcon slot="icon-only" src={chevron_right}></IonIcon>
                </div>
              )}
              {revenueApiData?.paid_revenue_events?.length == 0 &&
                revenueApiData?.pending_revenue_events?.length == 0 && (
                  <div
                    slot="end"
                    className="total-revenu__title__view_all_grey"
                  >
                    See all&nbsp;
                    <IonIcon
                      slot="icon-only"
                      src={chevron_right_grey}
                    ></IonIcon>
                  </div>
                )}
            </IonItem>
            {modelTipaltiPaymentStatus != "-1" && (
              <>
                {modelTipaltiPaymentStatus == "2" && (
                  <IonCard className="total-revenu__details">
                    <div className="total-revenu__details__earned">
                      <IonLabel>ESTIMATED REVENUE </IonLabel>
                      <IonCardTitle>
                        {/* {!revenueApiData && (
                          <IonSpinner name="dots"></IonSpinner>
                        )}
                        {revenueApiData?.total_revenue_str} */}
                        <div className="total-revenu__details__under-development">
                          <div>
                            <i>This feature is under development.</i>
                          </div>
                          <div>
                            <i>Please check back soon.</i>
                          </div>
                        </div>
                      </IonCardTitle>
                    </div>
                  </IonCard>
                )}
                {(modelTipaltiPaymentStatus == null ||
                  modelTipaltiPaymentStatus == "0" ||
                  modelTipaltiPaymentStatus == "1") && (
                  <div className="orange-border">
                    <div className="orange-border__content">
                      {modelTipaltiPaymentStatus == "0" ||
                      modelTipaltiPaymentStatus == null
                        ? "Please complete your payment paperwork and setup your payment method. Please note: The review process can take up to 1 business day."
                        : "Your paperwork is complete and is now being reviewed for approval. Please note: The review process can take up to 1 business day."}
                    </div>
                    <p className="orange-border__content"></p>
                    <div className="orange-border__content">
                      <IonButton
                        mode="ios"
                        fill="outline"
                        size="large"
                        onClick={() =>
                          history.push(
                            modelTipaltiPaymentStatus == "0" ||
                              modelTipaltiPaymentStatus == null
                              ? "/PaymentDetails"
                              : "/FAQsProfileManagement/p3"
                          )
                        }
                      >
                        {modelTipaltiPaymentStatus == "0" ||
                        modelTipaltiPaymentStatus == null
                          ? "Setup Payment"
                          : "Learn More"}
                      </IonButton>
                    </div>
                  </div>
                )}
              </>
            )}

            {modelTipaltiPaymentStatus == "-1" && (
              <>
                {modelState == "CA" ? (
                  <div className="orange-border">
                    <div className="orange-border__content">
                      To view payment details, please check your Payreel
                      account.
                    </div>
                    <p className="orange-border__content"></p>
                    <div className="orange-border__content">
                      <IonButton
                        mode="ios"
                        fill="outline"
                        size="large"
                        onClick={() =>
                          history.push("/FAQsProfileManagement/p3")
                        }
                      >
                        Learn More
                      </IonButton>
                    </div>
                  </div>
                ) : (
                  <IonCard className="total-revenu__details">
                    <div className="total-revenu__details__earned">
                      <IonLabel>ESTIMATED REVENUE </IonLabel>
                      <IonCardTitle>
                        {/* {!revenueApiData && (
                          <IonSpinner name="dots"></IonSpinner>
                        )}
                        {revenueApiData?.total_revenue_str} */}
                        <div className="total-revenu__details__under-development">
                          <div>
                            <i>This feature is under development.</i>
                          </div>
                          <div>
                            <i>Please check back soon.</i>
                          </div>
                        </div>
                      </IonCardTitle>
                    </div>
                  </IonCard>
                )}
              </>
            )}

            <PointBoard scoreBoradData={scoreBoradData} />
            <MyRewardSliding />
          </div>
        </IonContent>

        {showDateInfoTooltip === "revenue_date_range" && (
          <InfoButtonPopover
            infoButtonClass={"revenue_date_range"}
            updatePopoverState={setShowDateInfoTooltip}
          ></InfoButtonPopover>
        )}

        {showDateInfoTooltip === "revenue_my_revenue" && (
          <InfoButtonPopover
            infoButtonClass={"revenue_my_revenue"}
            updatePopoverState={setShowDateInfoTooltip}
          ></InfoButtonPopover>
        )}

        <IonPopover
          isOpen={showDateInfoTooltip == "earnings"}
          onDidDismiss={() => setShowDateInfoTooltip}
          cssClass="scoreboard_tooltip"
        >
          <IonContent class="ion-padding">
            This reporting is under development
          </IonContent>
        </IonPopover>
      </IonPage>
    </>
  );
};

export default Payments;

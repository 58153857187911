import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import axios from "axios";
import { noInternetResponse, unauthorizedResponse } from "../common/common";
import { Network } from "@capacitor/network";

/**
 * Get talent booked assignments list
 * @param filter_type 
 * @returns 
 */
export const getModelUpcomingevents = async (filter_type: string = "") => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${
          process.env.REACT_APP_HP_API_URL
        }/userEvent/modelupcomingevents?token=${authToken}${filter_type}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let upcoming_event_api_res = await CapacitorHttp.get(config);
    return upcoming_event_api_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 *  Get talent booked assignment
 * @param assign_event_id 
 * @returns 
 */
export const getModelAssignment = async (assign_event_id: number) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/modelupcomingevents?ue_id=${assign_event_id}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let assignment_res = await CapacitorHttp.get(config);
    return assignment_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getNextReportQuestionId = async (rq_id: any, event_id: number) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getNextReportQuestionId?rq_id=${rq_id}&event_id=${event_id}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let assignment_res = await CapacitorHttp.get(config);
    return assignment_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getPreviousReportQuestionId = async (rq_id: any, event_id: number) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getPreviousReportQuestionId?rq_id=${rq_id}&event_id=${event_id}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let assignment_res = await CapacitorHttp.get(config);
    return assignment_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Confirm or Decline Assignment Booking. Reason also provided in case of Declining  
 * @param confirmation 
 * @returns 
 */
export const saveModelConfirmationSelection = async (confirmation: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelConfirmationSelection`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        ue_id: confirmation.ue_id,
        model_confirms: confirmation.selection,
        reason: confirmation.reason,
        note: confirmation.note
      },
    };
    let confirmation_res = await CapacitorHttp.post(options);
    return confirmation_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Get Recap info (questions, answers, photos, etc.) (GET) 
 * @param event_id 
 * @returns 
 */
export const getassignmentquestion = async (event_id: number) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/recapEvent?ue_id=${event_id}&includeFilledReports=1`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        ue_id: event_id,
      },
    };
    let recap_test_questions = await CapacitorHttp.post(config);
    return recap_test_questions.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Save Recap Test Questions (POST)
 * @param submiRecapTestQuestionsData 
 * @returns 
 */
export const saveRecapTestQuestions = async (
  submiRecapTestQuestionsData: any
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/recapEventSave`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: submiRecapTestQuestionsData,
    };
    let submitRecapTestQuestions = await CapacitorHttp.post(options);
    return submitRecapTestQuestions.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Model DBC/ DOC Confirm
 * @param event_id
 * @param type 
 * @returns 
 */
export const saveModelDC = async (
  event_id: any,
  type: any
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelDC`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        ue_id: event_id,
        type: type
      },
    };
    let submitRecapTestQuestions = await CapacitorHttp.post(options);
    return submitRecapTestQuestions.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Save Agency/ Agent Recap Feedback (POST)
 * @param saveRecapAssignmentRatingData 
 * @returns 
 */
export const saveRecapAssignmentRating = async (
  saveRecapAssignmentRatingData: any
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelRating`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: saveRecapAssignmentRatingData,
    };
    let saveRecapAssignmentRating = await CapacitorHttp.post(options);
    return saveRecapAssignmentRating.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Get PUSH Agency/ Agent Recap feedback (GET)
 * @param event_id 
 * @returns 
 */
export const getAssignmentRecapFeedback = async (event_id: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelFeedback?ue_id=${event_id}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let recap_feedback = await CapacitorHttp.get(config);
    return recap_feedback.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Check In POST Action
 * @param talentCheckinInfo 
 * @returns 
 */
export const talentEventCheckin = async (talentCheckinInfo: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(`${process.env.REACT_APP_HP_API_URL}/userEvent/checkin`),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: talentCheckinInfo,
    };
    let api_response = await CapacitorHttp.post(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Save (Error) Log Info (Not necessarily event related)
 * @param message 
 * @param type 
 * @param data 
 * @returns 
 */
export const logError = async (message: any, type?: any, data?: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    var form_data = new FormData();
    form_data.append("errorMessage", message);

    if(type) {
      form_data.append("errorType", type);
    }

    if(data) {
      form_data.append("errorData", data);
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/users/logError`,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Profile Photos (not related to an event) Save Recap Photos or Receipts 
 * @param formData 
 * @param token 
 * @param onProgress 
 * @returns 
 */
export const talentFileUploader = async (formData: any, token: string = "", onProgress?: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    let authToken = token;
    if (!authToken) {
      authToken = await get("login_user_token");
      if(!authToken) {
        return unauthorizedResponse;
      }
    }
    const options: any = {
      method: "post",
      onUploadProgress: (progressEvent: any) => {
        if (onProgress) {
          onProgress(progressEvent);
        }
      },
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/fileupload`
      ),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        token: authToken,
      },
    };
    let api_response = await axios(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Save Recap Photo or Receipts
 * @param formData 
 * @param onProgress 
 * @returns 
 */
export const saveModelUploadedImages = async (formData: any, onProgress?: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      method: "post",
      onUploadProgress: (progressEvent: any) => {
        if (onProgress) {
          onProgress(progressEvent);
        }
      },
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelUploadedImages`
      ),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        token: authToken,
      },
    };
    let api_response = await axios(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Delete Recap Photo
 * @param mrd_id 
 * @returns 
 */
export const deleteRecapPhotos = async (mrd_id: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/deleteRecapPhotos`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { mrd_id: mrd_id },
    };
    let api_response = await CapacitorHttp.post(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Social Media Platforms for Recap - Share
 * @returns 
 */
export const socialMediaPlatformsForRating = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getPlatforms`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let api_res = await CapacitorHttp.get(config);
    return api_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Can Model Take Break? 
 * @param event_id 
 * @returns 
 */
export const canModelTakeBreak = async (event_id: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/cantakebreak?ue_id=${event_id}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let api_res = await CapacitorHttp.get(config);
    return api_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Can Model Resume Work?
 * @param event_id 
 * @returns 
 */
export const canModelResumWork = async (event_id: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/canresumework?ue_id=${event_id}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let api_res = await CapacitorHttp.get(config);
    return api_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Take Break POST Action
 * @param break_info 
 * @returns 
 */
export const modelTakeBreak = async (break_info: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(`${process.env.REACT_APP_HP_API_URL}/userEvent/takebreak`),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: break_info,
    };
    let api_response = await CapacitorHttp.post(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Resume Work POST action
 * @param resume_info 
 * @returns 
 */
export const modelResumeWork = async (resume_info: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/resumework`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: resume_info,
    };
    let api_response = await CapacitorHttp.post(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Check Out POST action
 * @param talentCheckoutInfo 
 * @returns 
 */
export const talentEventCheckout = async (talentCheckoutInfo: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(`${process.env.REACT_APP_HP_API_URL}/userEvent/checkout`),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: talentCheckoutInfo,
    };
    let api_response = await CapacitorHttp.post(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Get talent intro video
 * @param assign_event_id 
 * @returns 
 */
export const getModelIntroVideo = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelIntroVideo`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let assignment_res = await CapacitorHttp.get(config);
    return assignment_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Delete talent intro video
 * @param assign_event_id 
 * @returns 
 */
export const deleteModelIntroVideo = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/deleteModelIntroVideo`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let assignment_res = await CapacitorHttp.post(config);
    return assignment_res.data;
  } else {
    return noInternetResponse;
  }
};
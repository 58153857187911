import React  from "react";
import { IonProgressBar } from '@ionic/react'
import './ProgressBar.css';
interface progress_bar_types {
    progress_value:number
}
const ProgressBar:React.FC<progress_bar_types> = (props) =>{
    const { progress_value } = props;
return (
    <IonProgressBar
            className="signup-progress-bar"
          color="primary"
          value={progress_value}
        ></IonProgressBar>
)
}

export default ProgressBar
import { IonButton, IonContent, IonModal } from "@ionic/react";
import React from "react";
import './SmallModal.css'

type ModalProps = {
  logo?: string;
  description: string;
  buttonText: string;
  isOpen: boolean;
  closeAction: Function;
};

const SmallModal: React.FC<ModalProps> = (props) => {
  const { logo, description, buttonText, isOpen, closeAction } = props;

  return (
    <IonModal isOpen={isOpen} cssClass="small_modal_container" mode="md" backdropDismiss={false}>
      <IonContent className="ion-padding">
        <div className="small_modal__content">
          {logo && <img src={logo} alt="" className="small_modal__content__img" />}
          <div className="small_modal__content__text">{description}</div>
          <IonButton
            expand="block"
            mode="ios"
            type="button"
            className="form-group__button small_modal__content__btn"
            onClick={() => closeAction()}
          >
            {buttonText}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default SmallModal;

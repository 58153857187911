/** */
import React, { useEffect, useState } from "react";

import { IonContent, IonPage } from "@ionic/react";

import "./PaidRevenue.css";
import { useDispatch } from "react-redux";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import EmptyState from "./EmptyState";
import { getTalentRevenue } from "../../data-services/revenue-api";
import EventRevenueCard from "./EventRevenueCard";
import Loading from "../../push-components/loading/Loading";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg
} from "../../common/common";
import { authActions } from "../../store/auth";

// ##################
//  this  component not in use
// #####################
const PendingRevenue: React.FC = () => {
  const [pendingRevenueApiData, setPendingRevenueApiData] = useState<any>([]);
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);
  const dispatch = useDispatch();

  const getPendingRevenueData = async () => {
    setWaitingForApiResonse(true);
    let start_date = "";
    let end_date = "";
    let revenue_data = await getTalentRevenue(start_date, end_date);
    if (revenue_data?.success) {
      setPendingRevenueApiData(revenue_data.data.pending_revenue_events);
    } else if (revenue_data?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (revenue_data?.data?.errorCode == offlineErrorCode) {
      showToastMessage(revenue_data?.data?.message);
    } else if (revenue_data?.message) {
      showToastMessage(revenue_data.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setWaitingForApiResonse(false);
  };
  useEffect(() => {
    (async () => {
      await getPendingRevenueData();
    })();
  }, []);
  return (
    <IonPage>
      <SimpleHeaderView
        title="Pending Revenue"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        {pendingRevenueApiData.length === 0 && <EmptyState />}
        {pendingRevenueApiData.length > 0 && (
          <div className="paid-revenue-container">
            {pendingRevenueApiData.map((paid_event: any, index: number) => {
              let adjustments =
                paid_event.adjustments && paid_event?.adjustments.length > 0
                  ? paid_event.adjustments
                  : [];
              return (
                <EventRevenueCard
                  key={index}
                  payout_str={paid_event?.payout_str}
                  adjustments={adjustments}
                  event_name={paid_event?.event_name}
                  date={paid_event?.date}
                  time={paid_event?.time}
                  pay_date={paid_event?.pay_date}
                />
              );
            })}
          </div>
        )}
      </IonContent>
      <Loading showLoading={waitingForApiResonse} />
    </IonPage>
  );
};

export default PendingRevenue;

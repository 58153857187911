import react, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { get } from "../../../storage/storage";

import W2Form from "./W2Form/W2Form";
import W9Form from "./W9Form/W9Form";

import StarIconWhite from "../../../assets/images/my-calendar/star-outline-white.svg";
import StarIconOrange from "../../../assets/images/my-calendar/star-outline-orange.svg";

import creditCardOrange from "../../../assets/images/my-calendar/credit-card-orange.svg";
import creditCardWhite from "../../../assets/images/my-calendar/credit-card-white.svg";

import formsOrange from "../../../assets/images/my-calendar/forms-orange.svg";
import formsWhite from "../../../assets/images/my-calendar/forms-white.svg";

import reportMedicalOrange from "../../../assets/images/my-calendar/report-medical-orange.svg";
import reportMedicalWhite from "../../../assets/images/my-calendar/report-medical-white.svg";
import check_icon from "../../../assets/images/common/check-icon.svg";
import plus_icon from "../../../assets/images/common/plug-icon.svg";

import signatureCardOrange from "../../../assets/images/common/signature--orange.png";
import signatureCardWhite from "../../../assets/images/common/signature--white.png";

import "./SetupAccount.css";
import PushModelAgreement from "../../MyCalendar/PushModelAgreement/PushModelAgreement";
import PCardAgreement from "./PCardAgreement/PCardAgreement";

const PlusIcon = <FontAwesomeIcon icon={faPlus} />;
const CheckIcon = <FontAwesomeIcon icon={faCheck} />;

type accoutProps = {
  talentRequiredActions: any;
  getTalentRequiredAction: Function;
};
const SetupAccount: React.FC<accoutProps> = (props) => {
  const history = useHistory();
  const { talentRequiredActions, getTalentRequiredAction } = props;
  const dispatch = useDispatch();

  const [isOpenW2Form, setIsopenW2Form] = useState<boolean>(false);
  const [isOpenW9Form, setIsopenW9T4Form] = useState<boolean>(false);
  const [isOpen_PCardAgreement, setIsOpenPCardAgreement] =
    useState<boolean>(false);
  const [isOpenModalAgreement, setIsopenModalAgreement] =
    useState<boolean>(false);
  const [talentCurrentState, setTalentCurrentState] = useState<string>("W9");
  const [talentCurrentCountry, setTalentCurrentCountry] =
    useState<string>("USA");
  const [modelTipaltiPaymentAvailable, setModelTipaltiPaymentAvailable] =
    useState<string>("");

  const closeW9Form = async () => {
    await getTalentRequiredAction();
    setIsopenW9T4Form(false);
  };
  const closeW2Form = async () => {
    await getTalentRequiredAction();
    setIsopenW2Form(false);
  };
  const closeModalAgreement = async () => {
    await getTalentRequiredAction();
    setIsopenModalAgreement(false);
  };

  const close_PCardAgreement = async (confirmed?: false) => {
    await getTalentRequiredAction();
    setIsOpenPCardAgreement(false);
  };

  const openPaymentDetails = async () => {
    history.push("/PaymentDetails");
  };

  useEffect(() => {
    (async () => {
      setTalentCurrentState(await get("login_model_state"));
      setTalentCurrentCountry(await get("login_model_country"));
      setModelTipaltiPaymentAvailable(
        await get("model_tipalti_payment_available")
      );
    })();
  }, []);
  return (
    <>
      {talentRequiredActions && (
        <div className="setup-account-card">
          <h1 className="setup-account-card__title">Setup Your Account</h1>

          <IonCard className="setup-account__content__list">
            <IonCard
              onClick={() => setIsopenModalAgreement(true)}
              className={
                talentRequiredActions.items.talent_contract === 1
                  ? "account_steup_btn account_steup_btn_active"
                  : "account_steup_btn"
              }
            >
              <div className="btn_account_steup__item">
                <IonIcon
                  className="account_steup__icon_left"
                  src={
                    talentRequiredActions.items.talent_contract === 1
                      ? StarIconWhite
                      : StarIconOrange
                  }
                />
                <IonText className="account_steup__btn_text">
                  Complete Talent Contract
                </IonText>
                {talentRequiredActions.items.talent_contract === 1 && (
                  <IonIcon
                    className="account_steup__icon_right"
                    src={check_icon}
                  />
                )}
                {talentRequiredActions.items.talent_contract !== 1 && (
                  <IonIcon
                    className="account_steup__icon_right"
                    src={plus_icon}
                  />
                )}
              </div>
            </IonCard>
            {(talentRequiredActions.items.t4form !== 2) && 
            <IonCard
              onClick={() => setIsopenW9T4Form(true)}
              className={
                talentRequiredActions.items.t4form === 1
                  ? "account_steup_btn account_steup_btn_active"
                  : "account_steup_btn"
              }
            >
              <div className="btn_account_steup__item">
                <IonIcon
                  className="account_steup__icon_left"
                  src={
                    talentRequiredActions.items.t4form === 1
                      ? formsWhite
                      : formsOrange
                  }
                />
                <IonText className="account_steup__btn_text extra_margin_top">
                  Setup T4 Form 
                </IonText>
                {talentRequiredActions.items.t4form === 1 && (
                  <IonIcon
                    className="account_steup__icon_right"
                    src={check_icon}
                  />
                )}
                {talentRequiredActions.items.t4form !== 1 && (
                  <IonIcon
                    className="account_steup__icon_right"
                    src={plus_icon}
                  />
                )}
              </div>
            </IonCard>
            }
           {(talentRequiredActions.items.w9form !== 2) && 
           (<IonCard
            onClick={() => setIsopenW9T4Form(true)}
            className={
              talentRequiredActions.items.w9form === 1
                ? "account_steup_btn account_steup_btn_active"
                : "account_steup_btn"
            }
          >
            <div className="btn_account_steup__item">
              <IonIcon
                className="account_steup__icon_left"
                src={
                  talentRequiredActions.items.w9form === 1
                    ? formsWhite
                    : formsOrange
                }
              />
              <IonText className="account_steup__btn_text extra_margin_top">
                Setup W9 Form
              </IonText>
              {talentRequiredActions.items.w9form === 1 && (
                <IonIcon
                  className="account_steup__icon_right"
                  src={check_icon}
                />
              )}
              {talentRequiredActions.items.w9form !== 1 && (
                <IonIcon
                  className="account_steup__icon_right"
                  src={plus_icon}
                />
              )}
            </div>
          </IonCard>)}
            

            {(talentRequiredActions.items.w2form === 0 ||
              talentRequiredActions.items.w2form === 1) &&
              talentCurrentState === "CA" && (
                <IonCard
                  onClick={() => setIsopenW2Form(true)}
                  className={
                    talentRequiredActions.items.w2form === 1
                      ? "account_steup_btn account_steup_btn_active"
                      : "account_steup_btn"
                  }
                >
                  <div className="btn_account_steup__item">
                    <IonIcon
                      className="account_steup__icon_left"
                      src={
                        talentRequiredActions.items.w2form === 1
                          ? formsWhite
                          : formsOrange
                      }
                    />
                    <IonText className="account_steup__btn_text extra_margin_top">
                      Setup Form W2
                    </IonText>
                    {talentRequiredActions.items.w2form === 1 && (
                      <IonIcon
                        className="account_steup__icon_right"
                        src={check_icon}
                      />
                    )}
                    {talentRequiredActions.items.w2form !== 1 && (
                      <IonIcon
                        className="account_steup__icon_right"
                        src={plus_icon}
                      />
                    )}
                  </div>
                </IonCard>
              )}
            {talentRequiredActions.items.pCardAgreement != 0 && (
              <IonCard
                onClick={() => setIsOpenPCardAgreement(true)}
                className={
                  talentRequiredActions.items.pCardAgreement != 1
                    ? "account_steup_btn account_steup_btn_active"
                    : "account_steup_btn"
                }
              >
                <div className="btn_account_steup__item">
                  <img
                    className="account_steup__icon_left account_steup__icon__p-card"
                    src={
                      talentRequiredActions.items.pCardAgreement != 1
                        ? signatureCardWhite
                        : signatureCardOrange
                    }
                  />
                  <IonText className="account_steup__btn_text extra_margin_top">
                    Sign P-Card Agreement
                  </IonText>
                  {talentRequiredActions.items.pCardAgreement != 1 && (
                    <IonIcon
                      className="account_steup__icon_right"
                      src={check_icon}
                    />
                  )}
                  {talentRequiredActions.items.pCardAgreement == 1 && (
                    <IonIcon
                      className="account_steup__icon_right"
                      src={plus_icon}
                    />
                  )}
                </div>
              </IonCard>
            )}
            {modelTipaltiPaymentAvailable && (
              <IonCard
                onClick={() => openPaymentDetails()}
                className={
                  talentRequiredActions.items.tipaltiPaymentStatus === 1
                    ? "account_steup_btn account_steup_btn_active"
                    : "account_steup_btn"
                }
              >
                <div className="btn_account_steup__item">
                  <IonIcon
                    className="account_steup__icon_left"
                    src={
                      talentRequiredActions.items.tipaltiPaymentStatus === 1
                        ? formsWhite
                        : formsOrange
                    }
                  />
                  <IonText className="account_steup__btn_text extra_margin_top">
                    Complete Payment Paperwork
                  </IonText>
                  {talentRequiredActions.items.tipaltiPaymentStatus === 1 && (
                    <IonIcon
                      className="account_steup__icon_right"
                      src={check_icon}
                    />
                  )}
                  {talentRequiredActions.items.tipaltiPaymentStatus !== 1 && (
                    <IonIcon
                      className="account_steup__icon_right"
                      src={plus_icon}
                    />
                  )}
                </div>
              </IonCard>
            )}
          </IonCard>
        </div>
      )}
      <W9Form isOpen_w9_form={isOpenW9Form} closeW9Form={closeW9Form} />
      <W2Form isOpen_w2_form={isOpenW2Form} closeW2Form={closeW2Form} />
      <PushModelAgreement
        isOpen_modal_agreement={isOpenModalAgreement}
        closeModalAgreement={closeModalAgreement}
      />
      <PCardAgreement
        isOpen_modal_agreement={isOpen_PCardAgreement}
        closeModalAgreement={close_PCardAgreement}
      />
    </>
  );
};

export default SetupAccount;


import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import { noInternetResponse, unauthorizedResponse } from "../common/common";
import { Network } from "@capacitor/network";

export const getModelProfileData = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${
        process.env.REACT_APP_HP_API_URL
      }/userEvent/getModelProfileData?token=${authToken}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let response = await CapacitorHttp.get(config);
    if (response?.data?.success) {
      return { success: true, data: response.data.data };
    } else {
      if(response?.data?.data) {
        return { success: false, data: response.data.data };
      }
      return { success: false, data: {}};
    }
  } else {
    return noInternetResponse;
  }
};

export const getNotificationSettings = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${
        process.env.REACT_APP_HP_API_URL
      }/userEvent/getNotificationSettings?token=${authToken}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let response = await CapacitorHttp.get(config);
    if (response?.data?.success) {
      return { success: true, data: response.data.data };
    } else {
      if(response?.data?.data) {
        return { success: false, data: response.data.data };
      }
      return { success: false, data: {}};
    }
  } else {
    return noInternetResponse;
  }
};

export const deleteAccountRequest = async (comment: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/handleDeleteRequest`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        reason: comment,
      },
    };
    let response = await CapacitorHttp.post(options);
    return response;
  } else {
    return noInternetResponse;
  }
};

export const getExperiences = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${
        process.env.REACT_APP_HP_API_URL
      }/userEvent/getResumeExperiences?token=${authToken}&order_by2=DESC&order_by=me_category`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    let response = await CapacitorHttp.get(config);
    if (response?.data?.success) {
      return { success: true, data: response.data.data };
    } else {
      if(response?.data?.data) {
        return { success: false, data: response.data.data };
      }
      return { success: false, data: {}};
    }
  } else {
    return noInternetResponse;
  }
};

export const getResumeExperienceCategories = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/getResumeExperienceCategories`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let response = await CapacitorHttp.get(config);
    if (response?.data?.success) {
      return { success: true, data: response.data.data };
    } else {
      if(response?.data?.data) {
        return { success: false, data: response.data.data };
      }
      return { success: false, data: {}};
    }
  } else {
    return noInternetResponse;
  }
};

export const updateExperienceData = async (data: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveResumeExperience`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: data,
    };
    let response = await CapacitorHttp.post(options);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const saveNotificationSettings = async (data: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveNotificationSettings`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: data,
    };
    let response = await CapacitorHttp.post(options);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const deleteProfilePhoto = async (data: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/deleteProfilePhoto`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: data,
    };
    let response = await CapacitorHttp.post(options);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const deleteExperience = async (id: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/deleteResumeExperience`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { meId: id },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const getModelPublicProfileData = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelPublicProfileData`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let response = await CapacitorHttp.get(config);
    if (response?.data?.success) {
      return { success: true, data: response.data.data };
    } else {
      if(response?.data?.data) {
        return { success: false, data: response.data.data };
      }
      return { success: false, data: {}};
    }
  } else {
    return noInternetResponse;
  }
};
export const createCustomerReferral = async (data: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/createCustomerReferral`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: data,
    };
    let response = await CapacitorHttp.post(options);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const getCustomerReferrals = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/getCustomerReferrals`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let response = await CapacitorHttp.get(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const getModelReferrals = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelReferrals`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let response = await CapacitorHttp.get(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const getAvailableModelReferralList = async (contact_list: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getAvailableModelReferralList`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { contacts: contact_list },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const createModelReferral = async (contact_list: any, eventId?: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/createModelReferral`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { referrals: contact_list, eventId: eventId },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const getInternalReferralsList = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/getInternalReferralsList`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let response = await CapacitorHttp.get(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const getAvailableInternalReferralsList = async (contact_list: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getAvailableInternalReferralsList`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { contacts: contact_list },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const createModelEventReferral = async (contact_list: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/createModelEventReferral`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { referrals: contact_list },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const addModelCrewMembers = async (model_ids: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/addModelCrewMembers`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { model_user_id: model_ids },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const deleteModelCrewMembers = async (model_ids: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/deleteModelCrewMembers`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { model_user_id: model_ids },
    };
    let response = await CapacitorHttp.post(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const searchCrewTalent = async (search_pattern: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/searchCrewTalent?search_pattern=${search_pattern}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let response = await CapacitorHttp.get(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
import { Storage,Drivers  } from '@ionic/storage';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

const storage = new Storage({
    name: '__push_talent_app',
    driverOrder: [CordovaSQLiteDriver._driver,Drivers.LocalStorage,Drivers.IndexedDB ]
});

export const set = async(key: any , value:any) =>{
    await storage.create();
    await storage.set(key, value);
}

export const get = async(key: any ) =>{
    await storage.create();
    let item  = await storage.get(key);
    return item;

}

export const clear =  async ()=>{
    await storage.create();
    await storage.clear();
}

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  IonProgressBar,
} from "@ionic/react";
import "@splidejs/splide/dist/css/splide.min.css";
import ProfileFileuploader from "../../../push-components/ProfileFileuploader/ProfileFileuploader";
import InfoButtonPopover from "../../../push-components/InfoButtonPopover/InfoButtonPopover";
import { authActions } from "../../../store/auth";
import {
  getModelProfileData,
  getExperiences,
  deleteExperience,
  deleteProfilePhoto,
} from "../../../data-services/edit-profile";
import moment from "moment";
import "./EditProfile.css";
import facebook_icon from "../../../assets/images/common/brand-facebook.png";
import instagram_icon from "../../../assets/images/common/brand-instagram.png";
import linkedin_icon from "../../../assets/images/common/brand-linkedin.png";
import twitter_icon from "../../../assets/images/common/brand-twitter-new.png";
import tiktok_icon from "../../../assets/images/common/brand-tik-tok.png";
import threads_icon from "../../../assets/images/common/brand-threads.svg";
import youtube_icon from "../../../assets/images/common/brand-youtube.png";
import pinterest from "../../../assets/images/common/pinterest.svg";
import snapchat from "../../../assets/images/common/snapchat.svg";
import yelp from "../../../assets/images/common/yelp.svg";
import close_grey_btn from "../../../assets/images/common/close_grey_btn.png";

import check_icon from "../../../assets/images/common/check.png";
import check_black from "../../../assets/images/common/check_black.svg";
import check_orange from "../../../assets/images/common/checked.png";
import refesh_icon from "../../../assets/images/signup/img/reload.png";
import send_icon from "../../../assets/images/signup/img/icon-location.png";
import { closeCircle, closeCircleOutline } from "ionicons/icons";

import {
  getSocialMediaPlatforms,
  saveInfluencerSocialMediaPlatforms,
  updateTalenProfiletData,
  generateTextVerificationCode,
  checkTextVerificationCode,
} from "../../../data-services/signup";
import { useHistory } from "react-router";
import Loading from "../../../push-components/loading/Loading";
import AddEditExperience from "./AddEditExperience/AddEditExperience";
import AddSocialPlatforms from "../../../push-components/AddSocialPlatforms/AddSocialPlatforms";
import {
  showToastMessage,
  formatPhoneNumber,
  validatePhoneNumber,
  cleanPhoneNumber,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
} from "../../../common/common";
import ProfileAdditionalFileUploader from "../../../push-components/ProfileFileuploader/ProfileAdditionalFileUploader";
import { talentFileUploader } from "../../../data-services/assignmets";
import { get, set } from "../../../storage/storage";
import LockField from "../../../push-components/LockField/LockField";
import { getStatesForSignUp } from "../../../data-services/signup";
import { getAndCacheTalentThumb } from "../../../data-services/home-api";
import { invalidateOppsCache } from "../../../data-services/opportunities";
import info_circle from "../../../assets/images/common/info-circle.svg";
import { logError } from "../../../data-services/assignmets";

const EditProfile: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showInfo, setShowInfo] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  // talent personal info states
  const [talentFname, setTalentFname] = useState<string>("");
  const [talentLname, setTalentLname] = useState<string>("");
  const [talentStagename, setTalentStagename] = useState<string>("");
  const [talentEmail, setTalentEmail] = useState<string>("");
  const [talentPhone, setTalentPhone] = useState<string>("");
  /**
   * The initial phone number (stored when accessing the Edit Profile form)
   */
  const [initialTalentPhone, setInitialTalentPhone] = useState<string>("");
  /**
   * Mark if the currently inputted Phone number is valid
   */
  const [talentValidPhone, setTalentValidPhone] = useState<boolean>(false);
  /**
   * Determine whether we should display the Verification Code field or not
   * We display the Verification Code when the phone number has been changed
   */
  const [talentIsNewPhoneNumber, setTalentIsNewPhoneNumber] =
    useState<boolean>(false);

  const [newPhoneNumberCodeSent, setNewPhoneNumberCodeSent] =
    useState<boolean>(false);
  const [talentPhoneOneTimePass, setTalentPhoneOneTimePass] =
    useState<string>("");

  const [talentPhoneOneTimePassValid, setTalentPhoneOneTimePassValid] =
    useState<boolean>(false);

  const [talentBirthDate, setTalentBirthDate] = useState<any>("");
  const [talentGender, setTalentGeneder] = useState("Male");
  //height
  const [cmHeightValue, setCmHeightValue] = useState<number>(165);
  const [feetHeightRange, setFeetHeightRange] = useState<any>("5.5");
  const [heightConstantId, setHeightConstantId] = useState<any>({});
  const [heightConstantObject, setHeightConstantObject] = useState<any>({});
  // weight
  const [weightValue, setWeightValue] = useState<number>(55);
  //shirt
  const [shirtValueNumber, setShirtValueNumber] = useState<string>("");
  const [shirtValueString, setShirtValueString] = useState<any>("");
  const [shirtValueObject, setShirtValueObject] = useState<any>("");
  //pant
  const [pantValueNumber, setPantValueNumber] = useState<string>("");
  const [pantValueString, setPantValueString] = useState<string>("");
  const [pantValueObject, setPantValueObject] = useState<any>("");
  //dress
  const [dressValueNumber, setDressValueNumber] = useState<number>(34); // standart dress size number
  const [dressValueString, setDressValueString] = useState<any>("S"); // standart dress size string
  const [dressSizeConstIds, setDressSizeConstIds] = useState<any>(); // dress size DB ids arry from API
  const [dressSizeConstId, setDressSizeConstId] = useState<string>(); // current dress id from api
  // bust size
  const [bustSizeNumValue, setBustSizeNumValue] = useState<number>(32); // for value of input range
  const [bustSizeStringValue, setBustSizeStringValue] = useState<string>("32"); // for showing to use on above input
  const [bustSizeConstId, setBustSizeConstId] = useState<string>(); // database id
  const [bustSizeConstObject, setBustSizeConstObject] = useState<any>(); // database pre define values object
  // hip size
  const [hipSizeConstObject, setHipSizeConstObject] = useState<any>(); // database pre define values object
  const [hipSizeConstId, setHipSizeConstId] = useState<number>(); // database id
  const [hipSizeNumValue, setHipSizeNumValue] = useState<number>(32); // for value of input range and to user
  // cup cup size
  const [cupSizeConstObject, setCupSizeConstObject] = useState<any>(); // database pre define values object
  const [cupSizeConstId, setCupSizeConstId] = useState<number>(); // database id
  const [cuptSizeNumValue, setCuptSizeNumValue] = useState<number>(3); // for value of input range
  const [cuptSizeStringValue, setCuptSizeStringValue] = useState<string>("C"); // for showing to use on above input
  //waist
  const [waistSizeNumValue, setWaistSizeNumValue] = useState<number>(26);
  const [waistSizeConstId, setWaistSizeConstId] = useState<number>();
  const [waistSizeConstObject, setWaistSizeConstObject] = useState<any>();
  //shoe
  const [shoetSizeNumValue, setShoetSizeNumValue] = useState<number>(7);
  const [shoetSizeConstId, setShoetSizeConstId] = useState<number>(20);
  const [shoetSizeConstObject, setShoetSizeConstObject] = useState<any>();

  // suit size
  const [suitSizeNumValue, setSuitSizeNumValue] = useState<number>(2);
  const [suitSizeStringValue, setSuitSizeStringValue] = useState<string>("XL");
  const [suitSizeConstId, setSuitSizeConstId] = useState<number>();
  const [suitSizeConstObject, setSuitSizeConstObject] = useState<any>();
  // neck size
  const [neckSizeNumValue, setNeckSizeNumValue] = useState<number>(16);
  const [neckSizeConstId, setNeckSizeConstId] = useState<number>();
  const [neckSizeConstObject, setNeckSizeConstObject] = useState<any>();
  // chest size
  const [chestSizeNumValue, setChestSizeNumValue] = useState<number>(40);
  const [chestSizeConstId, setChestSizeConstId] = useState<number>();
  const [chestSizeConstObject, setChestSizeConstObject] = useState<any>();
  // talnet about us
  const [talentAboutus, setTalentAboutus] = useState<string>("");
  // talent images from apis
  const [talentHeadshot, setTalentHeadshot] = useState<string>("");
  // const [talentMidshot, setTalentMidshot] = useState<string>("");
  const [talentBodyshot, setTalentBodyshot] = useState<string>("");
  const [talentAdditionalPhoto, setTalentAdditionalPhoto] = useState<any>([]);

  // hair color
  const [checkHairColorId, SetCheckHairColorId] = useState<string>("");
  const [hairColorObject, setHairColorObject] = useState<any>([]);

  // eye color
  const [checkEyeColorId, SetCheckEyeColorId] = useState<string>();
  const [eyeColorObject, setEyeColorObject] = useState<any>();
  // skin tone
  const [checkSkinToneId, SetCheckSkinToneId] = useState<string>();

  const [skinColortObject, setSkinColorObject] = useState<any>();
  // gender

  // talent existing profile data
  const [talentProfileData, setTalentProfileData] = useState<any>();

  const [experienceList, setExperienceList] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isOpenModalExperience, setIsopenModalExperience] =
    useState<boolean>(false);
  const [experienceId, setExperienceId] = useState<any>(0);
  const [experienceObject, setExperienceObject] = useState<any>({});
  const [deleteExperienceId, setDeleteExperienceId] = useState<any>(0);

  const [socialPlatforms, setSocialPlatforms] = useState<any>();
  const [orignalApiObject, setOrignalApiObject] = useState<any>([]);
  const [openPlatformModal, setOpenPlatformModal] = useState<boolean>(false);
  const [addRemovePlatform, setAddRemovePlatform] = useState<{
    id: string;
    type: string;
    title: string;
  }>({ id: "", type: "", title: "" });

  const [talentInrestObject, setTalentInterstObject] = useState<any>({});
  const [talentInrestIds, setTalentInterstIds] = useState<any>([]);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showSpinnerTillApiResponse, setShowSpinnerTillApiResponse] =
    useState<boolean>(false);
  // talent account levet state
  const [isBrandAmbassador, setIsBrandAmbassador] = useState<boolean>(false);
  const [isModel, setIsModel] = useState<boolean>(false);
  const [isInfluencer, setIsInfluencer] = useState<boolean>(false);

  const [signupStateList, setSignupStateList] = useState<any>([]);

  const [talentStreetAddress, setTalentStreetAddress] = useState<string>("");
  const [talentApartmentNumber, setTalentApartmentNumber] =
    useState<string>("");
  const [talentCity, setTalentCity] = useState<string>("");
  const [talentZipcode, setTalentZipcode] = useState<string>("");
  const [talentState, setTalentState] = useState<string>("");
  const [talentCountry, setTalentCountry] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [weightRange, setWeightRange] = useState<any>([]);

  const connectToPlatform = async (id: string = "", type: string = "", title: string = "") => {
    if (type === "disconnect") {
      for (let index = 0; index < orignalApiObject.length; index++) {
        const element = orignalApiObject[index];
        if (id == element.smp_id) {
          orignalApiObject.splice(index, 1);
        }
      }
      let api_res = await saveInfluencerSocialMediaPlatforms(orignalApiObject);
      if (!api_res?.success) {
        if (api_res?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (api_res?.data?.errorCode == offlineErrorCode) {
          showToastMessage(api_res?.data?.message);
        } else if (api_res?.message) {
          showToastMessage(api_res.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } else {
        getSocialMediaInfo();
      }
    } else {
      setAddRemovePlatform({ id: id, type: type, title: title });
      setOpenPlatformModal(true);
    }
  };

  const closePlatformModal = async () => {
    getSocialMediaInfo();
    setOpenPlatformModal(false);
  };
  // convert height centimeter to feet
  const talentHeightHandler = (cent: number) => {
    setCmHeightValue(cent);
    let feet_unit = (cent * 0.3937) / 12;
    var feet = Math.floor(feet_unit);
    var inches = Math.round((feet_unit - feet) * 11);
    let real_feet = `${feet}'${inches}`;
    const talent_height_ranage: any = heightConstantObject;
    let height_map_value = "";
    Object.keys(talent_height_ranage).map(function (key) {
      let const_val = talent_height_ranage[key]?.split(" ")[0]; // string something like "4'11 \\\" (1m 50cm)"
      if (const_val === real_feet) {
        height_map_value = key;
      }
    });
    setHeightConstantId(height_map_value);
    setFeetHeightRange(real_feet);
  };

  const shoeSizeHandler = (size: number) => {
    setShoetSizeNumValue(size);
    let talent_shoe_ranage: any = shoetSizeConstObject;

    let shoe_map_value = "";
    Object.keys(talent_shoe_ranage).map(function (key) {
      let const_val = talent_shoe_ranage[key]?.split("<")[0]; // string something like "4<br>"

      if (const_val == size?.toString()) {
        shoe_map_value = key;
      }
    });
    setShoetSizeConstId(Number(shoe_map_value));
  };
  const dress_string_to_number: any = {
    "34": "XS",
    "36": "S",
    "38": "M",
    "40": "L",
  };
  const mapShirtWhitConstValue = (shirt_size: string) => {
    setShirtValueNumber(shirt_size);

    let shirt_string_val: any = Object.keys(shirtValueObject).map(function (
      key
    ) {
      if (key == shirt_size?.toString()) {
        return shirtValueObject[key];
      }
    });
    setShirtValueString(shirt_string_val);
  };

  const mapPantWhitConstValue = (pant_size: string) => {
    setPantValueNumber(pant_size);

    let pant_string_val: any = Object.keys(pantValueObject).map(function (key) {
      if (key == pant_size?.toString()) {
        return pantValueObject[key];
      }
    });
    setPantValueString(pant_string_val);
  };

  const dressSizeHandler = (dress_size: number) => {
    setDressValueNumber(dress_size);
    let dress_constant: any = dressSizeConstIds;

    // get dress string size like S,M,XS
    let dress_string_val = "";
    Object.keys(dress_string_to_number).map((key) => {
      if (key == dress_size?.toString()) {
        dress_string_val = dress_string_to_number[key];
      }
    });
    setDressValueString(dress_string_val);
    let dress_size_id = "";
    Object.keys(dress_constant).map((key: string) => {
      if (dress_constant[key] === dress_string_val) {
        dress_size_id = key;
      }
    });
    setDressSizeConstId(dress_size_id);
  };

  const bustSizeHandler = (bust_size: number) => {
    let bust_constant: any = bustSizeConstObject;

    let bust_size_id = "";
    Object.keys(bust_constant).map((key: string) => {
      if (bust_constant[key] == bust_size?.toString()) {
        return (bust_size_id = key);
      }

      if ("46" == bust_size?.toString()) {
        if (bust_constant[key] === "32a") {
          return (bust_size_id = key);
        }
      }

      if ("47" == bust_size?.toString()) {
        if (bust_constant[key] === "32b") {
          return (bust_size_id = key);
        }
      }
    });
    if (bust_size == 46) {
      setBustSizeStringValue("32a");
    } else if (bust_size === 47) {
      setBustSizeStringValue("32b");
    } else {
      setBustSizeStringValue(bust_size?.toString());
    }
    setBustSizeNumValue(bust_size);
    setBustSizeConstId(bust_size_id);
  };
  const hipSizeHandler = (hip_size: number) => {
    setHipSizeNumValue(hip_size);
    let bust_constant: any = hipSizeConstObject;

    let hip_size_id = "";
    Object.keys(bust_constant).map((key: string) => {
      if (bust_constant[key] == hip_size?.toString()) {
        return (hip_size_id = key);
      }
    });
    setHipSizeConstId(Number(hip_size_id));
  };
  const cupSizeHandler = (cup_size: number) => {
    setCuptSizeNumValue(cup_size);
    let cup_constant: any = cupSizeConstObject;

    let cup_size_string_val = "";
    let cup_size_id = "";

    Object.keys(cup_constant).map((key: string) => {
      if (key == cup_size?.toString()) {
        cup_size_id = key;
        cup_size_string_val = cup_constant[key];
      }
    });

    setCupSizeConstId(Number(cup_size_id));
    setCuptSizeStringValue(cup_size_string_val);
  };

  const waistSizeHandler = (waist_size: number) => {
    const waist_obj: any = waistSizeConstObject;
    setWaistSizeNumValue(waist_size);
    let waist_size_id = "";

    Object.keys(waist_obj).map((key: string) => {
      if (waist_obj[key] == waist_size?.toString()) {
        waist_size_id = key;
      }
    });

    setWaistSizeConstId(Number(waist_size_id));
  };

  const neckSizeHandler = (neck_size: number) => {
    setNeckSizeNumValue(neck_size);
    let neck_constant: any = neckSizeConstObject;

    let neck_size_id = "";
    Object.keys(neck_constant).map((key: string) => {
      if (neck_constant[key] == neck_size?.toString()) {
        return (neck_size_id = key);
      }
    });
    setNeckSizeConstId(Number(neck_size_id));
  };
  const chestSizeHandler = (chest_size: number) => {
    setChestSizeNumValue(chest_size);
    let chest_constant: any = chestSizeConstObject;

    let chest_size_id = "";
    Object.keys(chest_constant).map((key: string) => {
      if (chest_constant[key] == chest_size?.toString()) {
        return (chest_size_id = key);
      }
    });
    setChestSizeConstId(Number(chest_size_id));
  };
  const suitSizeHandler = (suit_size: number) => {
    setSuitSizeNumValue(suit_size);
    let suit_constant: any = suitSizeConstObject;

    let suit_size_id = "";
    let suit_string_val = "";
    Object.keys(suit_constant).map((key: string) => {
      if (key.toString() == suit_size?.toString()) {
        suit_size_id = key;
        suit_string_val = suit_constant[key];
      }
    });
    setSuitSizeStringValue(suit_string_val);
    setSuitSizeConstId(Number(suit_size_id));
  };
  const eyeColorHandler = (color_id: string) => {
    SetCheckEyeColorId(color_id);
  };
  const hairColorHandler = (color_id: string) => {
    SetCheckHairColorId(color_id);
  };
  const skinColorHandler = (color_id: string) => {
    SetCheckSkinToneId(color_id);
  };

  // const addeditExperience = (id: any, data: any) => {
  //   history.push({
  //     pathname: "/AddEditExperience/" + id,
  //     state: { data: data },
  //   });
  // };

  const addeditExperience = (id: any, data: any) => {
    setExperienceId(id);
    setExperienceObject(data);
    setIsopenModalExperience(true);
  };

  const closeModalExperience = async () => {
    setIsopenModalExperience(false);
    await getexperiencelist();
  };

  const deleteExperienceData = async (id: any) => {
    setShowLoader(true);
    let update_api_response = await deleteExperience(id);

    if (update_api_response?.success == 1) {
      setShowLoader(false);
      await getexperiencelist();
    } else {
      setShowLoader(false);
      await showToastMessage(update_api_response.message);
    }
  };

  const onSubmitTalentEditProfile = async (event: any) => {
    setUploadProgress(5);
    event.preventDefault();
    setShowSpinnerTillApiResponse(true);
    let model_photo = new FormData(event.target);
    model_photo.append("type", "profile_photos");

    // let additional_img = model_photo.getAll("photos[]");
    // model_photo.delete("photos[]");
    // for (let index = 0; index < additional_img.length; index++) {
    //   let file: any = additional_img[index];
    //   if (file.name) {
    //     console.log("file", file);
    //     model_photo.append("photos[]", file);
    //   }
    // }

    if (talentFname === "") {
      setErrorMsg("model_fname");
      showToastMessage("Legal First Name is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }
    if (talentLname === "") {
      setErrorMsg("model_lname");
      showToastMessage("Legal Last Name is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentBirthDate === "") {
      setErrorMsg("model_birth_date");
      showToastMessage("Birthday is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentStreetAddress === "") {
      setErrorMsg("model_street_address");
      showToastMessage("Street Address is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentCity === "") {
      setErrorMsg("model_city");
      showToastMessage("City is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentZipcode === "") {
      setErrorMsg("model_zipcode");
      showToastMessage("Zip Code is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentCountry === "") {
      setErrorMsg("model_country");
      showToastMessage("Country is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentState === "") {
      setErrorMsg("model_state");
      showToastMessage("State is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentPhone === "") {
      setErrorMsg("talent_phone");
      showToastMessage("Phone Number is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentEmail === "") {
      setErrorMsg("talent_email");
      showToastMessage("Email is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    if (talentIsNewPhoneNumber && talentPhoneOneTimePass === "") {
      setErrorMsg("model_phone_otp_empty_code");
      showToastMessage("Verification Code is required!");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    // find the missing photos from array and delete those images
    let new_img_array: any = model_photo.get("fileuploader-list-photos");
    if (new_img_array) {
      new_img_array = JSON.parse(new_img_array);
      let filter_array: any = [];
      for (let i = 0; i < new_img_array.length; i++) {
        let element = new_img_array[i];
        filter_array.push(element.file);
      }

      let missing_img = talentAdditionalPhoto.filter(function (x: any) {
        // return elements in previousArray matching...
        return !filter_array.includes(x.file); // "this element doesn't exist in talentAdditionalPhoto"
      });
      for (let j = 0; j < missing_img.length; j++) {
        const element = missing_img[j];
        await deleteAdditionalPhotoHandler(element.id);
      }
    }
    let years = moment(moment(), "YYYY-MM-DD").diff(
      talentBirthDate,
      "years",
      false
    );

    if (years < 18) {
      await showToastMessage("You must be at least 18 years old to proceed");
      setShowSpinnerTillApiResponse(false);
      return;
    }

    let edit_profile_form: any = {
      model_fname: talentFname,
      model_lname: talentLname,
      model_stage_name: talentStagename,
      model_birth_date: talentBirthDate,
      model_gender: talentGender,
      model_about: talentAboutus,
      model_hair_color_id: checkHairColorId,
      model_eye_color: checkEyeColorId,
      model_skin_tone_id: checkSkinToneId,
      model_intrest: talentInrestIds?.toString(),
      model_apartment_number: talentApartmentNumber,
      model_address: talentStreetAddress,
      model_city2: talentCity,
      model_zipcode: talentZipcode,
      model_state: talentState,
      model_country: talentCountry,
      model_phone: talentPhone,
      model_email: talentEmail,
      model_verification_code: talentPhoneOneTimePass,
    };

    if (isBrandAmbassador) {
      edit_profile_form.model_height_id = heightConstantId;
      edit_profile_form.model_shirt_size = shirtValueNumber;
      edit_profile_form.model_pant_size = pantValueNumber;
      edit_profile_form.model_show_size = shoetSizeConstId;
    }

    if (isModel) {
      if (talentGender === "Male") {
        edit_profile_form.model_suit_size_id = suitSizeConstId;
        edit_profile_form.model_neck_size_id = neckSizeConstId;
        edit_profile_form.model_chest_id = chestSizeConstId;
      }
      if (talentGender === "Female") {
        edit_profile_form.model_dress_size_id = dressSizeConstId;
        edit_profile_form.model_bust_size_id = bustSizeConstId;
        edit_profile_form.model_hip_size = hipSizeConstId;
        edit_profile_form.model_bust_cup_id = cupSizeConstId;
      }
      edit_profile_form.model_waist_id = waistSizeConstId;
      edit_profile_form.model_weight = weightValue;
    }

    try {
      let file_upload: any = await talentFileUploader(model_photo, "", handleProgress);

      if (file_upload?.success) {
        let update_api_response = await updateTalenProfiletData({
          model: edit_profile_form,
        });
        if (update_api_response?.success) {
          await showToastMessage("Successfully Updated!");
          if (update_api_response?.data?.model_account_level_changed) {
            await invalidateOppsCache();
          }
        } else if (
          update_api_response?.data?.errorCode == unauthorizedErrorCode
        ) {
          dispatch(authActions.logout());
          return;
        } else if (update_api_response?.data?.errorCode == offlineErrorCode) {
          showToastMessage(update_api_response?.data?.message);
        } else if (update_api_response?.message) {
          showToastMessage(update_api_response.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } else if (file_upload?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (file_upload?.data?.errorCode == offlineErrorCode) {
        showToastMessage(file_upload?.data?.message);
      } else if (file_upload?.message) {
        showToastMessage(file_upload.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
      setShowSpinnerTillApiResponse(false);
      setUploadProgress(0);
      setRefreshComponent(!refreshComonent);
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Edit Profile - onSubmitTalentEditProfile - Exception thrown",
          "Talent App - Edit Profile",
          JSON.stringify(e.message)
        );
        
      }
      setShowSpinnerTillApiResponse(false);
      setUploadProgress(0);
      setRefreshComponent(!refreshComonent);
    }
  };
  const getSocialMediaInfo = async () => {
    let platforms: any = await getSocialMediaPlatforms();
    if (!platforms?.success) {
      if (platforms?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (platforms?.data?.errorCode == offlineErrorCode) {
        showToastMessage(platforms?.data?.message);
      } else if (platforms?.message) {
        showToastMessage(platforms.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    setSocialPlatforms(platforms.changed_array);
    setOrignalApiObject(platforms.orignal_array);
  };

  const getexperiencelist = async () => {
    let experience_data: any = await getExperiences();

    if (experience_data?.success) {
      setExperienceList(experience_data.data);
    }
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  const epInit = async () => {
    setShowSpinner(true);

    // Clear phone verification code related fields (on refresh)
    setTalentValidPhone(false);
    setTalentIsNewPhoneNumber(false);
    setNewPhoneNumberCodeSent(false);
    setTalentPhoneOneTimePass("");
    setTalentPhoneOneTimePassValid(false);
    setErrorMsg("");

    let profile_data: any = await getModelProfileData();
    if (!profile_data?.success) {
      if (profile_data?.data?.errorCode == 0) {
        showToastMessage(profile_data?.data?.message);
        return;
      } else {
        dispatch(authActions.logout());
        return;
      }
    }
    profile_data = profile_data.data;

    await getSocialMediaInfo();
    let experience_data: any = await getExperiences();
    if (experience_data?.success) {
      setExperienceList(experience_data.data);
    }

    await set(
      "login_model_is_brand_ambassador",
      profile_data.accountLevel.is_brand_ambassador
    );
    await set("login_model_is_model", profile_data.accountLevel.is_model);
    await set(
      "login_model_is_influencer",
      profile_data.accountLevel.is_influencer
    );

    if (profile_data?.photos?.model_headshot) {
      setTalentHeadshot(profile_data.photos.model_headshot);

      let splited_imge =
        profile_data.photos.model_headshot?.split("uploaded_files");
      let image_name = splited_imge[1].replace("/", "");
      // set  model headshot in local storage
      await getAndCacheTalentThumb(image_name);
    }
    // if (profile_data.photos.model_midshot) {
    //   setTalentMidshot(profile_data.photos.model_midshot);
    // }
    if (profile_data.photos.model_fullbody) {
      setTalentBodyshot(profile_data.photos.model_fullbody);
    }
    if (profile_data.photos.additional) {
      let additional_img = profile_data.photos.additional;
      let img_array = [];
      for (let index = 0; index < additional_img.length; index++) {
        let img = additional_img[index];
        img_array.push({
          name: img.up_name,
          type: "image/jpeg",
          size: 458,
          id: img.up_id,
          file: img.up_full_path,
          data: {},
        });
      }
      setTalentAdditionalPhoto(img_array);
    }

    let model_height_id =
      profile_data?.formPossibleValues.commonGenderFields.model_height_id;
    let model_waist_id =
      profile_data?.formPossibleValues.commonGenderFields.model_waist_id;
    let model_show_size =
      profile_data?.formPossibleValues.commonGenderFields.model_show_size;
    let model_dress_size_id =
      profile_data?.formPossibleValues.femaleSpecificFields.model_dress_size_id;
    let model_bust_size_id =
      profile_data?.formPossibleValues.femaleSpecificFields.model_bust_size_id;
    let model_hip_size =
      profile_data?.formPossibleValues.femaleSpecificFields.model_hip_size;
    let model_bust_cup_id =
      profile_data?.formPossibleValues.femaleSpecificFields.model_bust_cup_id;
    let model_neck_size_id =
      profile_data?.formPossibleValues.maleSpecificFields.model_neck_size_id;
    let model_chest_id =
      profile_data?.formPossibleValues.maleSpecificFields.model_chest_id;
    let model_suit_size_id =
      profile_data?.formPossibleValues.maleSpecificFields.model_suit_size_id;
    let model_eye_color =
      profile_data?.formPossibleValues.commonGenderFields.model_new_eye_color;
    let model_hair_color_id =
      profile_data?.formPossibleValues.commonGenderFields
        .model_new_hair_color_id;
    let model_skin_tone =
      profile_data?.formPossibleValues.commonGenderFields.model_skin_tone;
    let model_intrest_ar =
      profile_data?.formPossibleValues.commonGenderFields.model_intrest_ar;
    let model_shirt_size =
      profile_data?.formPossibleValues.commonGenderFields.model_shirt_size;
    let model_pant_size =
      profile_data?.formPossibleValues.commonGenderFields.model_pant_size;
      let model_weight =
      profile_data?.formPossibleValues.commonGenderFields.model_weight;

    let existing_profile_data = profile_data?.model;

    setTalentProfileData(existing_profile_data);
    // possible  values
    setHeightConstantObject(model_height_id);
    setWaistSizeConstObject(model_waist_id);
    setShoetSizeConstObject(model_show_size);
    setDressSizeConstIds(model_dress_size_id);
    setBustSizeConstObject(model_bust_size_id);
    setHipSizeConstObject(model_hip_size);
    setCupSizeConstObject(model_bust_cup_id);
    setNeckSizeConstObject(model_neck_size_id);
    setChestSizeConstObject(model_chest_id);
    setSuitSizeConstObject(model_suit_size_id);
    setEyeColorObject(model_eye_color);
    setHairColorObject(model_hair_color_id);
    setSkinColorObject(model_skin_tone);
    setTalentInterstObject(model_intrest_ar);
    setShirtValueObject(model_shirt_size);
    setPantValueObject(model_pant_size);
    setWeightRange(model_weight);
    // setSkinColorConstObject()

    // talent existing data
    setTalentFname(profile_data.model.model_fname);
    setTalentLname(profile_data.model.model_lname);
    setTalentStagename(profile_data.model.model_stage_name);
    setTalentEmail(profile_data.model.model_email);
    setTalentPhone(profile_data.model.model_phone);
    setInitialTalentPhone(profile_data.model.model_phone);
    formatAndSetPhoneNumber(profile_data.model.model_phone);
    setTalentBirthDate(profile_data.model.model_birth_date);
    setTalentGeneder(profile_data.model.model_gender);
    SetCheckHairColorId(profile_data.model.model_hair_color_id);
    SetCheckEyeColorId(profile_data.model.model_eye_color);
    SetCheckSkinToneId(profile_data.model.model_skin_tone_id);
    setShirtValueNumber(profile_data.model.model_shirt_size);
    setShirtValueString(model_shirt_size[profile_data.model.model_shirt_size]);
    setPantValueNumber(profile_data.model.model_pant_size);
    setPantValueString(model_pant_size[profile_data.model.model_pant_size]);
    setHeightConstantId(profile_data.model.model_height_id);

    if (profile_data.model.model_intrest) {
      setTalentInterstIds(profile_data.model.model_intrest?.split(","));
    }
    // set height
    if (
      existing_profile_data.model_height_id &&
      typeof model_height_id[existing_profile_data.model_height_id] !==
        "undefined"
    ) {
      let height_in_cem =
        model_height_id[existing_profile_data.model_height_id]?.split(" ")[0]; // get height in feet
      setFeetHeightRange(height_in_cem);
      setHeightConstantId(existing_profile_data.model_height_id);
      height_in_cem = Number(height_in_cem.replace("'", ".")); // string to number
      setCmHeightValue(Math.ceil(height_in_cem * 30.48 - 3.048)); // convert feet height
    }

    //set weight
    setWeightValue(Number(profile_data?.model?.model_weight));

    // set waist size
    setWaistSizeNumValue(model_waist_id[profile_data.model.model_waist_id]);
    setWaistSizeConstId(profile_data.model.model_waist_id);
    if (profile_data.model.model_show_size !== "0") {
      // set shoe size
      setShoetSizeNumValue(
        model_show_size[profile_data.model.model_show_size]?.split("<")[0]
      );
      setShoetSizeConstId(profile_data.model.model_show_size);
    }

    if (profile_data.model.model_gender === "Female") {
      // set dress value
      let dress_string_val =
        model_dress_size_id[existing_profile_data.model_dress_size_id];
      setDressValueString(dress_string_val);
      setDressSizeConstId(existing_profile_data.model_dress_size_id);
      if (dress_string_val === "XS") {
        setDressValueNumber(34);
      }
      if (dress_string_val === "S") {
        setDressValueNumber(36);
      }
      if (dress_string_val === "M") {
        setDressValueNumber(38);
      }
      if (dress_string_val === "L") {
        setDressValueNumber(34);
      }

      if (profile_data.model.model_bust_size_id !== "0") {
        //set bust size
        setBustSizeConstId(profile_data.model.model_bust_size_id);
        if (
          model_bust_size_id[profile_data.model.model_bust_size_id] === "32a"
        ) {
          setBustSizeNumValue(46);
          setBustSizeStringValue("32a");
        } else if (
          model_bust_size_id[profile_data.model.model_bust_size_id] === "32b"
        ) {
          setBustSizeNumValue(47);
          setBustSizeStringValue("32b");
        } else {
          setBustSizeNumValue(
            model_bust_size_id[profile_data.model.model_bust_size_id]
          );
          setBustSizeStringValue(
            model_bust_size_id[profile_data.model.model_bust_size_id]?.toString()
          );
        }
      }

      // set hip size
      setHipSizeConstId(profile_data.model.model_hip_size);
      setHipSizeNumValue(model_hip_size[profile_data.model.model_hip_size]);
      //set bust cup size
      setCupSizeConstId(profile_data.model.model_bust_cup_id);
      setCuptSizeNumValue(profile_data.model.model_bust_cup_id);
      setCuptSizeStringValue(
        model_bust_cup_id[profile_data.model.model_bust_cup_id]
      );
    }
    if (profile_data.model.model_gender === "Male") {
      // set suit size
      setSuitSizeConstId(profile_data.model.model_suit_size_id);
      setSuitSizeStringValue(
        model_suit_size_id[profile_data.model.model_suit_size_id]
      );
      // set neck size
      setNeckSizeNumValue(
        model_neck_size_id[profile_data.model.model_neck_size_id]
      );
      setNeckSizeConstId(profile_data.model.model_neck_size_id);
      // set chest size
      setChestSizeNumValue(model_chest_id[profile_data.model.model_chest_id]);
      setChestSizeConstId(profile_data.model.model_chest_id);
    }
    setTalentAboutus(profile_data.model.model_about);

    setIsBrandAmbassador(await get("login_model_is_brand_ambassador"));
    setIsModel(await get("login_model_is_model"));
    setIsInfluencer(await get("login_model_is_influencer"));
    setShowSpinner(false);

    await getStateListBaseOnCountry(profile_data.model.model_country);
    setTalentStreetAddress(profile_data.model.model_address);
    setTalentApartmentNumber(profile_data.model.model_apartment_number);
    setTalentCountry(profile_data.model.model_country);
    setTalentState(profile_data.model.model_state);
    setTalentCity(profile_data.model.model_city2);
    setTalentZipcode(profile_data.model.model_zipcode);

    const anchor = window.location.hash.slice(1);
    if (anchor) {
      const id_element = document.querySelector(`#${anchor}`);
      if (id_element) {
        id_element.scrollIntoView();
      }
    }
  };

  const showWidgetsToUpdateMainPhotos = (param: string) => {
    if (param === "headshot") {
      setTalentHeadshot("");
    }
    // if (param === "midshot") {
    //   setTalentMidshot("");
    // }
    if (param === "bodyshot") {
      setTalentBodyshot("");
    }
  };
  const deleteAdditionalPhotoHandler = async (id: string) => {
    await deleteProfilePhoto({ up_id: id });
  };
  const removeTalentIntrest = async (intrest_id: any) => {
    let array_index = talentInrestIds.indexOf(intrest_id);
    talentInrestIds.splice(array_index, 1);
    let remove_badge: any = document.querySelector(
      `#interest_badge_${intrest_id}`
    );
    if (remove_badge) {
      remove_badge.style.display = "none";
    }
    await updateTalenProfiletData({
      model: { model_intrest: talentInrestIds?.toString() },
    });
    showToastMessage(
      "The '" +
        talentInrestObject[intrest_id] +
        "' area of interest has been removed."
    );
    // setRefreshComponent(!refreshComonent)
  };
  const addTalendInrrest = (intrest_id: any) => {
    for (let index = 0; index < intrest_id.length; index++) {
      let element = intrest_id[index];
      if (!talentInrestIds.includes(element)) {
        talentInrestIds.push(element);
      }
    }
    setTalentInterstIds(talentInrestIds);
  };
  const onRemoveCallback = useCallback(() => {}, []);
  const [refreshComonent, setRefreshComponent] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await epInit();
      await getexperiencelist();
    })();
  }, [refreshComonent]);

  const getStateListBaseOnCountry = async (country: string) => {
    setSignupStateList(await getStatesForSignUp(country));
  };

  const formatAndSetPhoneNumber = (event: any) => {
    let phone_no;
    if (event?.target?.value) {
      phone_no = event.target.value;
    } else {
      phone_no = event;
    }

    let formatted_no = formatPhoneNumber(phone_no);

    if (formatted_no) {
      setTalentPhone(formatted_no);
      setTalentValidPhone(validatePhoneNumber(formatted_no));
      return;
    } else {
      setTalentValidPhone(false);
    }
  };

  const generateOTP = async () => {
    let text_varification: any = await generateTextVerificationCode(
      talentPhone
    );
    if (text_varification?.success) {
      showToastMessage("Code has been generated!");
      setNewPhoneNumberCodeSent(true);
    } else {
      if (text_varification?.data?.errorCode == 0) {
        showToastMessage(text_varification?.data?.message);
      } else {
        showToastMessage(text_varification.message);
      }
    }
  };

  const checkOTP = async (code: any) => {
    setTalentPhoneOneTimePassValid(false);
    setTalentPhoneOneTimePass(code);
    if (code.length != 4) {
      return;
    }
    let text_varification: any = await checkTextVerificationCode(
      talentPhone,
      code
    );
    if (text_varification?.success) {
      showToastMessage(text_varification.message);
      setTalentPhoneOneTimePassValid(true);
      setErrorMsg("");
    } else {
      showToastMessage(text_varification.message);
      setTalentPhoneOneTimePassValid(false);
      setErrorMsg("model_phone_otp_invalid_code");
    }
  };

  const handlePhoneChange = async (number: string) => {
    // Set the Phone Number
    setTalentPhone(number);

    // Check if the Inputted Phone differs than the Initial Phone
    // If so, show the Verfication code field
    // Otherwise, hide the Verification code field (make sure it's not required)
    // Verification code will be required in order to submit the form.
    if (cleanPhoneNumber(number) !== initialTalentPhone) {
      setTalentIsNewPhoneNumber(true);
    } else {
      setTalentIsNewPhoneNumber(false);
    }
  };

  return (
    <IonPage>
      <IonContent>
        {showSpinner && (
          <div className="edit-profile__spinner">
            <IonSpinner color="primary" name="crescent" />
          </div>
        )}
        {!showSpinner && (
          <>
            <form onSubmit={onSubmitTalentEditProfile}>
              <IonHeader className="edit-profile__header">
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonBackButton defaultHref="/" mode="md" />
                  </IonButtons>
                  <IonTitle className="header-toolbar-title edit-profile__header__title">
                    Edit Profile
                  </IonTitle>
                  <IonButtons slot="end">
                    {showSpinnerTillApiResponse ? (
                      <IonButton>
                        <IonSpinner name="dots"></IonSpinner>
                      </IonButton>
                    ) : (
                      <IonButton
                        className="edit-profile__header__save form-group__button"
                        type="submit"
                      >
                        Save
                      </IonButton>
                    )}
                  </IonButtons>
                  {showSpinnerTillApiResponse && (<IonProgressBar value={uploadProgress / 100} />)}
                </IonToolbar>
              </IonHeader>
              <div className="edit-profile">
                <section className="edit-profile__file-upload-section">
                  <div className="ep__file-upload">
                    {talentHeadshot && (
                      <>
                        <label
                          htmlFor=""
                          className="edit-profile__headshot_label"
                        >
                          Headshot
                        </label>
                        <div className="ep__file-upload__widgets">
                          <IonIcon
                            src={closeCircle}
                            className="ep__file-upload__preload_close"
                            onClick={() =>
                              showWidgetsToUpdateMainPhotos("headshot")
                            }
                          ></IonIcon>
                          <img
                            className="ep__file-upload__preload"
                            src={talentHeadshot}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                    {talentHeadshot == "" && (
                      <div className="ep__file-upload__widgets">
                        <label
                          htmlFor="one"
                          className="ep__file-upload-widget-label ep__file-required ep__file-upload-widget-label-primary"
                        >
                          Headshot
                        </label>
                        <ProfileFileuploader
                          name="head_shot"
                          limit="1"
                          id="model_headshot"
                          cropp_ratio="headshot"
                          files={[]}
                          click={onRemoveCallback}
                        />
                        <input type="hidden" name="head_shot" />
                      </div>
                    )}
                    {/* hide midshot Emil said  */}
                    {/* {talentMidshot && (
                      <div className="ep__file-upload__widgets">
                        <IonIcon
                          src={closeCircle}
                          className="ep__file-upload__preload_close"
                          onClick={() =>
                            showWidgetsToUpdateMainPhotos("midshot")
                          }
                        ></IonIcon>
                        <img
                          className="ep__file-upload__preload"
                          src={talentMidshot}
                          alt=""
                        />
                      </div>
                    )} */}
                    {/* {talentMidshot === "" && (
                      <div className="ep__file-upload__widgets">
                        <label
                          htmlFor="one"
                          className="ep__file-upload-widget-label ep__file-required ep__file-upload-widget-label-primary"
                        >
                          Midshot
                        </label>
                        <ProfileFileuploader
                          name="square_shot"
                          limit="1"
                          id="model_midshot"
                          files={[]}
                          click={onRemoveCallback}
                        />
                        <input type="hidden" name="square_shot" />
                      </div>
                    )} */}
                    {talentBodyshot && (
                      <>
                        <label
                          htmlFor=""
                          className="edit-profile__bodyshot_label"
                        >
                          Body shot
                        </label>

                        <div className="ep__file-upload__widgets">
                          <IonIcon
                            src={closeCircle}
                            className="ep__file-upload__preload_close"
                            onClick={() =>
                              showWidgetsToUpdateMainPhotos("bodyshot")
                            }
                          ></IonIcon>
                          <img
                            className="ep__file-upload__preload"
                            src={talentBodyshot}
                            alt=""
                          />
                        </div>
                      </>
                    )}
                    {talentBodyshot === "" && (
                      <div className="ep__file-upload__widgets">
                        <label
                          htmlFor="two"
                          className="ep__file-upload-widget-label ep__file-required ep__file-upload-widget-label-primary"
                        >
                          Body shot
                        </label>
                        <ProfileFileuploader
                          name="url_shot"
                          limit="1"
                          id="model_fullbody"
                          files={[]}
                          cropp_ratio="fullbody"
                          click={onRemoveCallback}
                        />
                        <input type="hidden" name="url_shot" />
                      </div>
                    )}
                  </div>

                  <div className="ep__file-upload__additional_photo">
                    <h3 className="additional_photo__h3">Additional photos</h3>
                    <ProfileAdditionalFileUploader
                      name="photos[]"
                      limit="200"
                      id="model_additional"
                      files={talentAdditionalPhoto}
                      // preloaded_file={JSON.stringify(talentAdditionalPhoto)}
                    />
                    <input type="hidden" name="photos[]" value="" />
                  </div>

                  <div className="ep__file-upload__required">
                    <label htmlFor="">* is required</label>
                  </div>
                </section>

                <section className="edit-profile__basic-info">
                  <div className="edit-profile__titles">
                    <h3>Profile</h3>
                  </div>
                  <IonItem>
                    <IonLabel slot="start" className="basic-info__label">
                      Legal First Name
                    </IonLabel>

                    <input
                      type="text"
                      className="basic-info__input"
                      value={talentFname}
                      onChange={(e: any) => setTalentFname(e.target.value)}
                      readOnly={true}
                    ></input>
                  </IonItem>
                  {errorMsg === "model_fname" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">
                        This field is required.
                      </div>
                    </div>
                  )}

                  <IonItem>
                    <IonLabel slot="start" className="basic-info__label">
                      Legal Last Name
                    </IonLabel>
                    <input
                      type="text"
                      className="basic-info__input"
                      value={talentLname}
                      readOnly={true}
                      onChange={(e: any) => setTalentLname(e.target.value)}
                    ></input>
                  </IonItem>
                  {errorMsg === "model_lname" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">
                        This field is required.
                      </div>
                    </div>
                  )}

                  <IonItem>
                    <IonLabel slot="start" className="basic-info__label">
                      Stage Name
                    </IonLabel>
                    <input
                      type="text"
                      className="basic-info__input"
                      value={talentStagename}
                      onChange={(e: any) => setTalentStagename(e.target.value)}
                    ></input>
                  </IonItem>
                  {errorMsg === "model_stagename" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">
                        This field is required.
                      </div>
                    </div>
                  )}
                  <IonItem>
                    <IonLabel
                      slot="start"
                      className="basic-info__label ep__field-required"
                    >
                      Birthday
                    </IonLabel>
                    <input
                      type="date"
                      className="basic-info__input"
                      value={talentBirthDate}
                      onChange={(e: any) => setTalentBirthDate(e.target.value)}
                    ></input>
                  </IonItem>
                  {errorMsg === "model_birth_date" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">
                        This field is required.
                      </div>
                    </div>
                  )}
                  <IonItem>
                    <IonLabel
                      slot="start"
                      className="basic-info__label ep__field-required"
                    >
                      Email
                    </IonLabel>
                    <input
                      type="text"
                      className="basic-info__input"
                      value={talentEmail}
                      onChange={(e: any) => setTalentEmail(e.target.value)}
                    ></input>
                  </IonItem>
                  {errorMsg === "model_email" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">
                        This field is required.
                      </div>
                    </div>
                  )}
                  <IonItem>
                    <IonLabel
                      slot="start"
                      className="basic-info__label ep__field-required"
                    >
                      Phone Number
                    </IonLabel>
                    <input
                      type="tel"
                      className="basic-info__input"
                      value={talentPhone}
                      placeholder="(___) ___ ____"
                      onChange={(e: any) => handlePhoneChange(e.target.value)}
                      maxLength={14}
                      onInput={formatAndSetPhoneNumber}
                    ></input>
                    {talentValidPhone && talentIsNewPhoneNumber && (
                      <img
                        className="basic-info__check-icon"
                        src={check_orange}
                        alt=""
                      />
                    )}
                  </IonItem>
                  {errorMsg === "model_phone" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">
                        This field is required.
                      </div>
                    </div>
                  )}
                  {errorMsg === "model_phone_invalid" && (
                    <div className="edit-profile__error--msg">
                      <div className="error-message">Invalid Phone Number</div>
                    </div>
                  )}

                  {talentIsNewPhoneNumber && (
                    <div>
                      <IonItem>
                        <IonLabel
                          slot="start"
                          className="basic-info__label ep__field-required"
                        >
                          Verification Code
                        </IonLabel>
                        <input
                          type="tel"
                          className="basic-info__input"
                          disabled={!newPhoneNumberCodeSent}
                          maxLength={4}
                          value={talentPhoneOneTimePass}
                          onChange={(e: any) => checkOTP(e.target.value)}
                        ></input>
                        {talentPhoneOneTimePassValid && (
                          <img
                            className="basic-info__check-icon"
                            src={check_orange}
                            alt=""
                          />
                        )}
                      </IonItem>
                      {errorMsg === "model_phone_otp_empty_code" && (
                        <div className="edit-profile__error--msg">
                          <div className="error-message">
                            This field is required.
                          </div>
                        </div>
                      )}
                      {errorMsg === "model_phone_otp_invalid_code" && (
                        <div className="edit-profile__error--msg">
                          <div className="error-message">Invalid code</div>
                        </div>
                      )}
                      <div className="verification-code__options">
                        {!newPhoneNumberCodeSent && (
                          <div
                            className="verification-code__option"
                            onClick={() => generateOTP()}
                          >
                            <img alt="" src={send_icon} />
                            Get Code
                          </div>
                        )}
                        {newPhoneNumberCodeSent && (
                          <div
                            className="verification-code__option"
                            onClick={() => generateOTP()}
                          >
                            <img alt="" src={refesh_icon} />
                            Resend code
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </section>
                <section className="edit-profile__street_address">
                  <div className="edit-profile__titles">
                    <h3 id="address">Address</h3>
                  </div>
                  <IonItem>
                    <IonLabel
                      slot="start"
                      className="basic-info__label ep__field-required"
                    >
                      Street Address
                    </IonLabel>
                    <input
                      className="basic-info__input"
                      onChange={(e: any) =>
                        setTalentStreetAddress(e.target.value)
                      }
                      value={talentStreetAddress}
                    ></input>
                    {errorMsg === "model_street_address" && (
                      <div className="edit-profile__error--msg">
                        <div className="error-message">
                          This field is required.
                        </div>
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel slot="start" className="basic-info__label">
                      Apartment Number
                    </IonLabel>
                    <input
                      className="basic-info__input"
                      maxLength={20}
                      onChange={(e: any) =>
                        setTalentApartmentNumber(e.target.value)
                      }
                      value={talentApartmentNumber}
                    ></input>
                  </IonItem>
                  <IonItem>
                    <IonLabel
                      slot="start"
                      className="basic-info__label ep__field-required"
                    >
                      City
                    </IonLabel>
                    <input
                      className="basic-info__input"
                      onChange={(e: any) => setTalentCity(e.target.value)}
                      value={talentCity}
                    ></input>
                    {errorMsg === "model_city" && (
                      <div className="edit-profile__error--msg">
                        <div className="error-message">
                          This field is required.
                        </div>
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel
                      slot="start"
                      className="basic-info__label ep__field-required"
                    >
                      Zip Code
                    </IonLabel>
                    <input
                      className="basic-info__input"
                      onChange={(e: any) => setTalentZipcode(e.target.value)}
                      value={talentZipcode}
                    ></input>
                    {errorMsg === "model_zipcode" && (
                      <div className="edit-profile__error--msg">
                        <div className="error-message">
                          This field is required.
                        </div>
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel className="basic-info__label ep__field-required">
                      Country
                    </IonLabel>
                    <IonSelect
                      className="basic-info__input"
                      onIonChange={(e: any) =>
                        getStateListBaseOnCountry(e.detail.value)
                      }
                      value={talentCountry?.toString()}
                    >
                      <IonSelectOption key="usa" value="USA">
                        USA
                      </IonSelectOption>
                      <IonSelectOption key="canada" value="Canada">
                        Canada
                      </IonSelectOption>
                    </IonSelect>
                    {errorMsg === "model_country" && (
                      <div className="edit-profile__error--msg">
                        <div className="error-message">
                          This field is required.
                        </div>
                      </div>
                    )}
                  </IonItem>
                  <IonItem>
                    <IonLabel className="basic-info__label ep__field-required">
                      State
                    </IonLabel>
                    <IonSelect
                      className="basic-info__input"
                      onIonChange={(e: any) => setTalentState(e.detail.value)}
                      value={talentState?.toString()}
                      placeholder="--Select--"
                    >
                      {Object.keys(signupStateList).map(
                        (state_key, state_index) => (
                          <IonSelectOption key={state_key} value={state_key}>
                            {signupStateList[state_key]}
                          </IonSelectOption>
                        )
                      )}
                    </IonSelect>
                    {errorMsg === "model_state" && (
                      <div className="edit-profile__error--msg">
                        <div className="error-message">
                          This field is required.
                        </div>
                      </div>
                    )}
                  </IonItem>
                </section>
                <section className="edit-profile__gender">
                  <div className="edit-profile__titles">
                    <h3>Gender</h3>
                  </div>
                  <IonRadioGroup
                    onIonChange={(e: any) => setTalentGeneder(e.detail.value)}
                    value={talentGender}
                  >
                    <IonItem>
                      <IonRadio
                        mode="md"
                        value="Male"
                        className="gender__radio-btn"
                      />
                      <IonLabel className="gender__label">Male</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonRadio
                        mode="md"
                        value="Female"
                        className="gender__radio-btn"
                      />
                      <IonLabel className="gender__label">Female</IonLabel>
                    </IonItem>
                    {/* <IonItem>
                  <IonRadio
                    mode="md"
                    value="Other"
                    className="gender__radio-btn"
                  />
                  <IonLabel className="gender__label">Other</IonLabel>
                </IonItem> */}
                  </IonRadioGroup>
                </section>
                <section className="edit-profile__hair-color">
                  <div className="edit-profile__titles">
                    <h3>Hair</h3>
                  </div>
                  <div className="hair-color__items">
                    {hairColorObject &&
                      hairColorObject.map((color: any, index: number) => (
                        <div className="hair-color__item" key={index}>
                          {color.color === "#ffffff" ? (
                            <div
                              style={{
                                backgroundColor: color.color,
                                border: "1px solid black",
                              }}
                              className="color-item-type"
                              onClick={() => hairColorHandler(color.id)}
                            >
                              {checkHairColorId === color.id && (
                                <div className="edit-profile__check">
                                  <img src={check_black} alt="" />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{ backgroundColor: color.color }}
                              className="color-item-type"
                              onClick={() => hairColorHandler(color.id)}
                            >
                              {checkHairColorId === color.id && (
                                <div className="edit-profile__check">
                                  <img src={check_icon} alt="" />
                                </div>
                              )}
                            </div>
                          )}

                          <label className="hair-color__label">
                            {color.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </section>

                <section className="edit-profile__eye-color">
                  <div className="edit-profile__titles">
                    <h3>Eyes</h3>
                  </div>

                  <div className="eye-color__items">
                    {eyeColorObject &&
                      eyeColorObject.map((color: any, index: number) => (
                        <div className="eye-color__item" key={index}>
                          {color.color === "#ffffff" ? (
                            <div
                              style={{
                                backgroundColor: color.color,
                                border: "1px solid black",
                              }}
                              className="color-item-type"
                              onClick={() => eyeColorHandler(color.id)}
                            >
                              {checkEyeColorId == color.id && (
                                <div className="edit-profile__check">
                                  <img src={check_black} alt="" />
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{ backgroundColor: color.color }}
                              className="color-item-type"
                              onClick={() => eyeColorHandler(color.id)}
                            >
                              {checkEyeColorId == color.id && (
                                <div className="edit-profile__check">
                                  <img src={check_icon} alt="" />
                                </div>
                              )}
                            </div>
                          )}
                          <label className="eye-color__lable">
                            {color.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </section>
                <section className="edit-profile__skin-color">
                  <div className="edit-profile__titles">
                    <h3>Skin Tone</h3>
                  </div>

                  <div className="skin-color__items">
                    {skinColortObject &&
                      skinColortObject.map((color: any, index: number) => (
                        <div className="skin-color__item" key={index}>
                          <div
                            style={{ backgroundColor: color.st_color }}
                            className="color-item-type"
                            onClick={() => skinColorHandler(color.st_id)}
                          >
                            {checkSkinToneId == color.st_id && (
                              <div className="edit-profile__check">
                                <img src={check_icon} alt="" />
                              </div>
                            )}
                          </div>
                          <label className="skin-tone__lable">
                            {color.st_name}
                          </label>
                        </div>
                      ))}
                  </div>
                </section>

                <section className="edit-profile__measurements">
                  {isBrandAmbassador ? (
                    <>
                      <IonItem>
                        <div className="edit-profile__measurement">
                          <div className="measurement__labels">
                            <div className="edit-profile__titles">
                              <h3>Height</h3>
                            </div>
                            <IonSelect
                              className="basic-info__input"
                              onIonChange={(e: any) =>
                                setHeightConstantId(e.detail.value)
                              }
                              value={heightConstantId?.toString()}
                              interfaceOptions={{
                                header: "Height",
                              }}
                              placeholder="--Select--"
                            >
                              {Object.entries(heightConstantObject).map(
                                ([key, value]) => (
                                  <IonSelectOption key={key} value={key}>
                                    {value}
                                  </IonSelectOption>
                                )
                              )}
                            </IonSelect>
                          </div>
                        </div>
                      </IonItem>
                      <IonItem>
                        <div className="edit-profile__measurement">
                          <div className="measurement__labels">
                            <div className="edit-profile__titles">
                              <h3>Shirt</h3>
                            </div>
                            <IonSelect
                              className="basic-info__input"
                              onIonChange={(e: any) =>
                                setShirtValueNumber(e.detail.value)
                              }
                              value={shirtValueNumber?.toString()}
                              placeholder="--Select--"
                              interfaceOptions={{
                                header: "Shirt",
                              }}
                            >
                              {Object.entries(shirtValueObject).map(
                                ([key, value]) => (
                                  <IonSelectOption key={key} value={key}>
                                    {value}
                                  </IonSelectOption>
                                )
                              )}
                            </IonSelect>
                          </div>
                        </div>
                      </IonItem>
                      <IonItem>
                        <div className="edit-profile__measurement">
                          <div className="measurement__labels">
                            <div className="edit-profile__titles">
                              <h3>Pant</h3>
                            </div>
                            <IonSelect
                              className="basic-info__input"
                              onIonChange={(e: any) =>
                                setPantValueNumber(e.detail.value)
                              }
                              value={pantValueNumber?.toString()}
                              placeholder="--Select--"
                              interfaceOptions={{
                                header: "Pant",
                              }}
                            >
                              {Object.entries(pantValueObject).map(
                                ([key, value]) => (
                                  <IonSelectOption key={key} value={key}>
                                    {value}
                                  </IonSelectOption>
                                )
                              )}
                            </IonSelect>
                          </div>
                        </div>
                      </IonItem>
                      <IonItem>
                        <div className="edit-profile__measurement">
                          <div className="measurement__labels">
                            <div className="edit-profile__titles">
                              <h3>Shoe</h3>
                            </div>
                            <IonSelect
                              className="basic-info__input"
                              onIonChange={(e: any) =>
                                setShoetSizeConstId(e.detail.value)
                              }
                              value={shoetSizeConstId?.toString()}
                              placeholder="--Select--"
                              interfaceOptions={{
                                header: "Shoe",
                              }}
                            >
                              {Object.entries(shoetSizeConstObject).map(
                                ([key, value]) => (
                                  <IonSelectOption key={key} value={key}>
                                    {(value as string).replace("<br>", "")}US
                                  </IonSelectOption>
                                )
                              )}
                            </IonSelect>
                          </div>
                        </div>
                      </IonItem>
                    </>
                  ) : (
                    <LockField
                      closeAction={() => setIsBrandAmbassador(true)}
                      description="Unlock more opportunities by upgrading your profile to PUSH Ambassador"
                    />
                  )}

                  {isModel && (
                    <IonItem>
                      <div className="edit-profile__measurement">
                        <div className="measurement__labels">
                          <div className="edit-profile__titles">
                            <h3>Weight</h3>
                          </div>
                          <IonSelect
                            className="basic-info__input"
                            onIonChange={(e: any) =>
                              setWeightValue(e.detail.value)
                            }
                            value={weightValue?.toString()}
                            placeholder="--Select--"
                            interfaceOptions={{
                              header: "Weight",
                            }}
                          >
                            {weightRange.map((weight: any) => (
                              <IonSelectOption key={weight} value={weight?.toString()}>
                                {weight}lbs
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </div>
                      </div>
                    </IonItem>
                  )}
                  {!isModel && (
                    <LockField
                      closeAction={() => setIsModel(true)}
                      description="Unlock more opportunities by upgrading your profile to PUSH Model"
                    />
                  )}

                  {talentGender === "Female" && (
                    <>
                      {isModel && (
                        <>
                          <IonItem>
                            <div className="edit-profile__measurement">
                              <div className="measurement__labels">
                                <div className="edit-profile__titles">
                                  <h3>Dress</h3>
                                </div>

                                <IonSelect
                                  className="basic-info__input"
                                  onIonChange={(e: any) =>
                                    setDressSizeConstId(e.detail.value)
                                  }
                                  value={dressSizeConstId?.toString()}
                                  placeholder="--Select--"
                                  interfaceOptions={{
                                    header: "Dress",
                                  }}
                                >
                                  {Object.entries(dressSizeConstIds).map(
                                    ([key, value]) => (
                                      <IonSelectOption key={key} value={key}>
                                        {value}
                                      </IonSelectOption>
                                    )
                                  )}
                                </IonSelect>
                              </div>
                            </div>
                          </IonItem>

                          <IonItem>
                            <div className="edit-profile__measurement">
                              <div className="measurement__labels">
                                <div className="edit-profile__titles">
                                  <h3>Bust Size</h3>
                                </div>
                                <IonSelect
                                  className="basic-info__input"
                                  onIonChange={(e: any) =>
                                    setBustSizeConstId(e.detail.value)
                                  }
                                  value={bustSizeConstId?.toString()}
                                  placeholder="--Select--"
                                  interfaceOptions={{
                                    header: "Bust Size",
                                  }}
                                >
                                  {Object.entries(bustSizeConstObject).map(
                                    ([key, value]) => (
                                      <IonSelectOption key={key} value={key}>
                                        {value}
                                      </IonSelectOption>
                                    )
                                  )}
                                </IonSelect>
                              </div>
                            </div>
                          </IonItem>
                        </>
                      )}

                      <IonItem>
                        <div className="edit-profile__measurement">
                          <div className="measurement__labels">
                            <div className="edit-profile__titles">
                              <h3>Hip Size</h3>
                            </div>
                            <IonSelect
                              className="basic-info__input"
                              onIonChange={(e: any) =>
                                setHipSizeConstId(e.detail.value)
                              }
                              value={hipSizeConstId?.toString()}
                              placeholder="--Select--"
                              interfaceOptions={{
                                header: "Hip Size",
                              }}
                            >
                              {Object.entries(hipSizeConstObject).map(
                                ([key, value]) => (
                                  <IonSelectOption key={key} value={key}>
                                    {value}
                                  </IonSelectOption>
                                )
                              )}
                            </IonSelect>
                          </div>
                        </div>
                      </IonItem>
                      <IonItem>
                        <div className="edit-profile__measurement">
                          <div className="measurement__labels">
                            <div className="edit-profile__titles">
                              <h3>Bust Cup Size</h3>
                            </div>
                            <IonSelect
                              className="basic-info__input"
                              onIonChange={(e: any) =>
                                setCupSizeConstId(e.detail.value)
                              }
                              value={cupSizeConstId?.toString()}
                              placeholder="--Select--"
                              interfaceOptions={{
                                header: "Bust Cup Size",
                              }}
                            >
                              {Object.entries(cupSizeConstObject).map(
                                ([key, value]) => (
                                  <IonSelectOption key={key} value={key}>
                                    {value}
                                  </IonSelectOption>
                                )
                              )}
                            </IonSelect>
                          </div>
                        </div>
                      </IonItem>
                    </>
                  )}
                  {talentGender !== "Female" && (
                    <>
                      {isModel && (
                        <>
                          <IonItem>
                            <div className="edit-profile__measurement">
                              <div className="measurement__labels">
                                <div className="edit-profile__titles">
                                  <h3>Suit Size</h3>
                                </div>
                                <IonSelect
                                  className="basic-info__input"
                                  onIonChange={(e: any) =>
                                    setSuitSizeConstId(e.detail.value)
                                  }
                                  value={suitSizeConstId?.toString()}
                                  placeholder="--Select--"
                                  interfaceOptions={{
                                    header: "Suit Size",
                                  }}
                                >
                                  {Object.entries(suitSizeConstObject).map(
                                    ([key, value]) => (
                                      <IonSelectOption key={key} value={key}>
                                        {value}
                                      </IonSelectOption>
                                    )
                                  )}
                                </IonSelect>
                              </div>
                            </div>
                          </IonItem>
                          <IonItem>
                            <div className="edit-profile__measurement">
                              <div className="measurement__labels">
                                <div className="edit-profile__titles">
                                  <h3>Chest Size</h3>
                                </div>
                                <IonSelect
                                  className="basic-info__input"
                                  onIonChange={(e: any) =>
                                    setChestSizeConstId(e.detail.value)
                                  }
                                  value={chestSizeConstId?.toString()}
                                  placeholder="--Select--"
                                  interfaceOptions={{
                                    header: "Chest Size",
                                  }}
                                >
                                  {Object.entries(chestSizeConstObject).map(
                                    ([key, value]) => (
                                      <IonSelectOption key={key} value={key}>
                                        {value}
                                      </IonSelectOption>
                                    )
                                  )}
                                </IonSelect>
                              </div>
                            </div>
                          </IonItem>
                          <IonItem>
                            <div className="edit-profile__measurement">
                              <div className="measurement__labels">
                                <div className="edit-profile__titles">
                                  <h3>Neck Size</h3>
                                </div>
                                <IonSelect
                                  className="basic-info__input"
                                  onIonChange={(e: any) =>
                                    setNeckSizeConstId(e.detail.value)
                                  }
                                  value={neckSizeConstId?.toString()}
                                  placeholder="--Select--"
                                  interfaceOptions={{
                                    header: "Neck Size",
                                  }}
                                >
                                  {Object.entries(neckSizeConstObject).map(
                                    ([key, value]) => (
                                      <IonSelectOption key={key} value={key}>
                                        {value}
                                      </IonSelectOption>
                                    )
                                  )}
                                </IonSelect>
                              </div>
                            </div>
                          </IonItem>
                        </>
                      )}
                    </>
                  )}
                  {isModel && (
                    <IonItem>
                      <div className="edit-profile__measurement">
                        <div className="measurement__labels">
                          <div className="edit-profile__titles">
                            <h3>Waist</h3>
                          </div>
                          <IonSelect
                            className="basic-info__input"
                            onIonChange={(e: any) =>
                              setWaistSizeConstId(e.detail.value)
                            }
                            value={waistSizeConstId?.toString()}
                            placeholder="--Select--"
                            interfaceOptions={{
                              header: "Waist",
                            }}
                          >
                            {Object.entries(waistSizeConstObject).map(
                              ([key, value]) => (
                                <IonSelectOption key={key} value={key}>
                                  {value}
                                </IonSelectOption>
                              )
                            )}
                          </IonSelect>
                        </div>
                      </div>
                    </IonItem>
                  )}
                </section>
                <section className="edit-profile__about">
                  <IonItem>
                    <div className="about__section">
                      <div className="edit-profile__titles">
                        <h3>
                          About Me
                          <IonIcon
                            icon={info_circle}
                            onClick={() => setShowInfo("about_me")}
                          />
                        </h3>
                      </div>
                      <div className="about__textarea">
                        <IonTextarea
                          placeholder="Tell us a little about yourself"
                          rows={5}
                          onIonChange={(e: any) =>
                            setTalentAboutus(e.detail.value)
                          }
                          value={talentAboutus}
                        ></IonTextarea>
                      </div>
                    </div>
                  </IonItem>
                </section>

                <section className="edit-profile__experience">
                  <div className="edit-profile__titles">
                    <h3>Experiences</h3>
                  </div>

                  {experienceList.length > 0 &&
                    experienceList.map((experience: any, index: number) => (
                      <div className="experience__title" key={index}>
                        <div>
                          <h4>
                            <span
                              onClick={() => {
                                addeditExperience(experience.meId, experience);
                              }}
                            >
                              {experience.eventName}
                            </span>
                            <IonIcon
                              onClick={() => {
                                setDeleteExperienceId(experience.meId);
                              }}
                              slot="icon-only"
                              src={closeCircleOutline}
                            />
                          </h4>
                        </div>

                        <small
                          onClick={() => {
                            addeditExperience(experience.meId, experience);
                          }}
                        >
                          {experience.meCategory} - {experience.meYear} -{" "}
                          {experience.meCity}, {experience.meState}
                        </small>
                      </div>
                    ))}
                  <IonItem>
                    <IonButton
                      onClick={() => {
                        addeditExperience(0, {});
                      }}
                      type="button"
                      mode="ios"
                      fill="clear"
                    >
                      <h1>+</h1> Add New experience
                    </IonButton>
                  </IonItem>
                </section>
                {/* <section className="edit-profile__videos">
                  <div className="edit-profile__titles">
                    <h3>Videos</h3>
                    <Splide
                      options={{
                        arrows: false,
                        pagination: false,
                        type: "loop",
                        perPage: 2,
                        perMove: 1,
                        focus: "center",
                        width: "350px",
                        height: "100px",
                      }}
                    >
                      <SplideSlide>
                        <div className="video__thumnail">
                          <img src={sampleImage} alt="" />
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="video__thumnail">
                          <img src={sampleImage} alt="" />
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="video__thumnail">
                          <img src={sampleImage} alt="" />
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="video__thumnail">
                          <img src={sampleImage} alt="" />
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="video__thumnail">
                          <img src={sampleImage} alt="" />
                        </div>
                      </SplideSlide>
                    </Splide>
                  </div>
                </section> */}
                <section className="edit-profile__interest">
                  <div className="edit-profile__titles">
                    <h3>Areas of Interest</h3>
                  </div>
                  <IonItem>
                    <div className="interest__section">
                      <IonSelect
                        onIonChange={(e: any) =>
                          addTalendInrrest(e.detail.value)
                        }
                        className="form-group__input-styles"
                        placeholder="--Select--"
                        multiple={true}
                        value={talentInrestIds?.toString()}
                      >
                        {talentInrestObject &&
                          Object.keys(talentInrestObject).map(
                            (key: any, index: number) => (
                              <>
                                {talentInrestObject[key] && (
                                  <IonSelectOption value={key} key={index}>
                                    {talentInrestObject[key]}
                                  </IonSelectOption>
                                )}
                              </>
                            )
                          )}
                      </IonSelect>

                      <div className="interest__section__list">
                        {talentInrestIds.length > 0 &&
                          talentInrestIds.map((intrest: any, index: number) => (
                            <>
                              {talentInrestObject[intrest] && (
                                <IonBadge
                                  className="interest-section__badge"
                                  id={`interest_badge_${intrest}`}
                                  key={index}
                                >
                                  {talentInrestObject[intrest]}
                                  <img
                                    src={close_grey_btn}
                                    alt=""
                                    onClick={() => removeTalentIntrest(intrest)}
                                  />
                                </IonBadge>
                              )}
                            </>
                          ))}
                      </div>
                    </div>
                  </IonItem>
                </section>
                {isInfluencer ? (
                  <section className="edit-profile__social-media">
                    {socialPlatforms && (
                      <>
                        <div className="edit-profile__titles">
                          <h3>Social Media</h3>
                        </div>

                        <div className="social-media__items">
                          <IonCard>
                            <IonItem>
                              <img className="social-media__items__twitter-icon" src={twitter_icon} alt="" slot="start" />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.twitter.smp_name}
                              </IonText>

                              {socialPlatforms?.twitter.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.twitter.smp_id,
                                      "disconnect",
                                      socialPlatforms?.twitter.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.twitter.smp_id,
                                      "connect",
                                      socialPlatforms?.twitter.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>

                          <IonCard>
                            <IonItem>
                              <img src={instagram_icon} alt="" slot="start" />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.instagram.smp_name}
                              </IonText>
                              {socialPlatforms?.instagram.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.instagram.smp_id,
                                      "disconnect",
                                      socialPlatforms?.instagram.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.instagram.smp_id,
                                      "connect",
                                      socialPlatforms?.instagram.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                          <IonCard>
                            <IonItem>
                              <img src={facebook_icon} alt="" slot="start" />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.facebook.smp_name}
                              </IonText>
                              {socialPlatforms?.facebook.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.facebook.smp_id,
                                      "disconnect",
                                      socialPlatforms?.facebook.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.facebook.smp_id,
                                      "connect",
                                      socialPlatforms?.facebook.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                          <IonCard>
                            <IonItem>
                              <img src={youtube_icon} alt="" slot="start" />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.youtube.smp_name}
                              </IonText>
                              {socialPlatforms?.youtube.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.youtube.smp_id,
                                      "disconnect",
                                      socialPlatforms?.youtube.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.youtube.smp_id,
                                      "connect",
                                      socialPlatforms?.youtube.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                          <IonCard>
                            <IonItem>
                              <img src={linkedin_icon} alt="" slot="start" />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.linkedin.smp_name}
                              </IonText>
                              {socialPlatforms?.linkedin.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.linkedin.smp_id,
                                      "disconnect",
                                      socialPlatforms?.linkedin.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.linkedin.smp_id,
                                      "connect",
                                      socialPlatforms?.linkedin.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>

                          <IonCard>
                            <IonItem>
                              <IonIcon
                                src={pinterest}
                                slot="start"
                                className="social-media__svg_icon"
                              />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.pinterest.smp_name}
                              </IonText>
                              {socialPlatforms?.pinterest.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.pinterest.smp_id,
                                      "disconnect",
                                      socialPlatforms?.pinterest.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.pinterest.smp_id,
                                      "connect",
                                      socialPlatforms?.pinterest.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>

                          <IonCard>
                            <IonItem>
                              <IonIcon
                                src={snapchat}
                                slot="start"
                                className="social-media__svg_icon"
                              />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.snapchat.smp_name}
                              </IonText>
                              {socialPlatforms?.snapchat.status ===
                              "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.snapchat.smp_id,
                                      "disconnect",
                                      socialPlatforms?.snapchat.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.snapchat.smp_id,
                                      "connect",
                                      socialPlatforms?.snapchat.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                          <IonCard>
                            <IonItem>
                              <IonIcon
                                src={yelp}
                                slot="start"
                                className="social-media__svg_icon"
                              />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.yelp.smp_name}
                              </IonText>
                              {socialPlatforms?.yelp.status === "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.yelp.smp_id,
                                      "disconnect",
                                      socialPlatforms?.yelp.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.yelp.smp_id,
                                      "connect",
                                      socialPlatforms?.yelp.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                          <IonCard>
                            <IonItem>
                            <img className="social-media__items__tiktok-icon" src={tiktok_icon} alt="" slot="start" />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.tiktok.smp_name}
                              </IonText>
                              {socialPlatforms?.tiktok.status === "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.tiktok.smp_id,
                                      "disconnect",
                                      socialPlatforms?.tiktok.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.tiktok.smp_id,
                                      "connect",
                                      socialPlatforms?.tiktok.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                          <IonCard>
                            <IonItem>
                              <IonIcon
                                src={threads_icon}
                                slot="start"
                                className="social-media__svg_icon"
                              />
                              <IonText
                                className="social-media__label"
                                slot="start"
                              >
                                {socialPlatforms?.threads.smp_name}
                              </IonText>
                              {socialPlatforms?.threads.status === "connected" ? (
                                <IonText
                                  className="social-media__label-grey"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.threads.smp_id,
                                      "disconnect",
                                      socialPlatforms?.threads.smp_name
                                    )
                                  }
                                >
                                  Disconnect
                                </IonText>
                              ) : (
                                <IonText
                                  className="social-media__label-orange"
                                  slot="end"
                                  onClick={() =>
                                    connectToPlatform(
                                      socialPlatforms.threads.smp_id,
                                      "connect",
                                      socialPlatforms?.threads.smp_name
                                    )
                                  }
                                >
                                  Connect
                                </IonText>
                              )}
                            </IonItem>
                          </IonCard>
                        </div>
                      </>
                    )}
                  </section>
                ) : (
                  <section className="edit-profile__social-media">
                    <LockField
                      closeAction={() => setIsInfluencer(true)}
                      description="Unlock more opportunities by upgrading your profile to PUSH Influencer"
                    />
                  </section>
                )}
              </div>
            </form>
          </>
        )}
      </IonContent>
      {showInfo === "about_me" && (
        <InfoButtonPopover
          infoButtonClass={"about_me"}
          updatePopoverState={setShowInfo}
        ></InfoButtonPopover>
      )}
      <Loading showLoading={showLoader} />
      <IonAlert
        isOpen={deleteExperienceId > 0}
        header={"Confirmation"}
        message={"Are you sure you want to delete?"}
        buttons={[
          {
            text: "CANCEL",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              setDeleteExperienceId(0);
            },
          },
          {
            text: "OK",
            handler: () => {
              deleteExperienceData(deleteExperienceId);
              setDeleteExperienceId(0);
            },
          },
        ]}
      />
      <AddSocialPlatforms
        isOpenPlatformModal={openPlatformModal}
        closePlatformModal={closePlatformModal}
        updateInfo={addRemovePlatform}
        orignalApiObject={orignalApiObject}
      />
      <AddEditExperience
        isOpen_modal_experience={isOpenModalExperience}
        closeModalExperience={closeModalExperience}
        experienceId={experienceId}
        experiencedata={experienceObject}
      />
    </IonPage>
  );
};

export default EditProfile;

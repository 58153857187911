import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonItem,
  IonCard,
  IonTitle,
  IonIcon,
  IonCardContent,
  IonButton,
  IonCardTitle,
} from "@ionic/react";
import "./MyRewards.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { authActions } from "../../store/auth";
import roundArrowIcon from "../../assets/images/score-board/icons/round-arrow-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import chevron_right from "../../assets/images/common/chevron-right.svg";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import { getAvailableRewardsByCategories } from "../../data-services/scoreboard";
import ItemRedeemCard from "../../components/ItemRedeemCard/ItemRedeemCard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import RedeemRewardModal from "./RedeemRewardModal";
import ClaimRewardSuccessModal from "./ClaimRewardSuccessModal";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import Loading from "../../push-components/loading/Loading";
const StarIcon = <FontAwesomeIcon icon={faStar} />;

const MyRewards: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenRewardModal, setIsOpenRewardModal] = useState<boolean>(false);
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [talenRewardId, setTalenRewardId] = useState<string>("");
  const [rewardsArray, setRewardsArray] = useState<any>([]);
  const [targetReward, setTargetReward] = useState<any>("");
  const [targetAttributesValuesChecks, setTargetAttributesValuesChecks] =
    useState<any>("");
  const [categoriesAndRewardsArray, setCategoriesAndRewardsArray] = useState<any>([]);

  const [claimRewardSuccessMsg, setClaimRewardSuccessMsg] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);

  const closeRewardModal = (title: string = "", message: string = "") => {
    setIsOpenRewardModal(false);
    setIsViewOnly(false);
    if (title && message) {
      setClaimRewardSuccessMsg({ title: title, message: message, show: true });
    }
  };

  const navigatetohistory = () => {
    history.push("/RewardHistory");
  };

  const goToRewards = (category: any) => {
    // Vertical list of rewards, filtered by selected Category
    history.push(`/RewardCategory/${category}`);
  };

  const mrInit = async () => {
    setWaitingForApiResonse(true);
    let categoriesAndRewards = await getAvailableRewardsByCategories(10);
    if (categoriesAndRewards?.success) {
      if (categoriesAndRewards?.data.categoriesAndRewards) {
        setCategoriesAndRewardsArray(
          categoriesAndRewards?.data.categoriesAndRewards
        );
        for (const category of categoriesAndRewards.data.categoriesAndRewards) {
           if(category?.rewards?.length) {
             for(const reward of category.rewards) {
              rewardsArray.push(reward);
             }
           }
        }
        
        setRewardsArray(rewardsArray);
      }
    } else if (categoriesAndRewards?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (categoriesAndRewards?.data?.errorCode == offlineErrorCode) {
      showToastMessage(categoriesAndRewards?.data?.message);
    } else if (categoriesAndRewards?.message) {
      await showToastMessage(categoriesAndRewards.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setWaitingForApiResonse(false);
  };
  const redeemRewardHandler = (
    redeemId: string,
    checkedAttributesValues: any,
    viewOnly?: any
  ) => {
    // Parse the attribute ID and value ID

    let targetReward: any;
    let targetAttributesValuesChecks = [];
    for (const reward of rewardsArray) {
      if (reward.model_reward_id == redeemId) {
        targetReward = reward;
        targetAttributesValuesChecks =
          checkedAttributesValues["reward" + redeemId];
      }
    }

    setTalenRewardId(redeemId);
    setTargetReward(targetReward);
    
    setTargetAttributesValuesChecks(targetAttributesValuesChecks);
    setIsOpenRewardModal(true);
    setIsViewOnly(viewOnly);
  };
  const closeClaimdModal = async () => {
    setIsViewOnly(false);
    setClaimRewardSuccessMsg({ title: "", message: "", show: false });
    await mrInit();
  };
  useEffect(() => {
    (async () => {
      await mrInit();
    })();
  }, []);

  return (
    <IonPage>
      <SimpleHeaderView
        title="My Rewards"
        bgcolor="primary"
        buttonType="roundArrowIcon"
        iconImgSrc={roundArrowIcon}
        clickEventHandler={navigatetohistory}
      ></SimpleHeaderView>
      <IonContent>
        {categoriesAndRewardsArray.length > 0 && (
          <div className="reward-categories-container">
            {categoriesAndRewardsArray.map((item: any, index: any) => (
              <div key = {index} className="scoreboard_reward">
                <IonItem>
                  <IonTitle className="scoreboard_reward__title">
                    {item.category.rc_name}
                  </IonTitle>
                  <div
                    slot="end"
                    className="scoreboard_reward__all-item"
                    onClick={() => goToRewards(item.category.rc_id)}
                  >
                    <span>See all</span> <IonIcon src={chevron_right}></IonIcon>
                  </div>
                </IonItem>
                <ItemRedeemCard
                  items={item.rewards}
                  redeemRewardHandler={redeemRewardHandler}
                ></ItemRedeemCard>
              </div>
            ))}
          </div>
        )}
        {(!waitingForApiResonse && categoriesAndRewardsArray.length == 0) && (
          <EmptyScreen title="No Rewards" description="" />
        )}
      </IonContent>
      <RedeemRewardModal
        rewardModalType="save_modal"
        isOpenRewardModal={isOpenRewardModal}
        closeRewardModal={closeRewardModal}
        talenRewardId={talenRewardId}
        targetReward={targetReward}
        targetAttributesValuesChecks={targetAttributesValuesChecks}
        viewOnly={isViewOnly}
      />
      <ClaimRewardSuccessModal
        isOpenClaimModal={claimRewardSuccessMsg.show}
        closeClaimdModal={closeClaimdModal}
        title_message={{
          title: claimRewardSuccessMsg.title,
          message: claimRewardSuccessMsg.message,
        }}
      />
      <Loading showLoading={waitingForApiResonse} />
    </IonPage>
  );
};

export default MyRewards;

import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle
} from "@ionic/react";

import "@splidejs/splide/dist/css/splide.min.css";
import "./MyProfile.css";
import { useHistory, useParams } from "react-router";

import user from "../../../assets/images/common/user-icon.svg";
import users from "../../../assets/images/common/multi-users.svg";
import affiliate from "../../../assets/images/common/affliate.svg";
import invite_friends from "../../../assets/images/common/invite-friends-icon.svg";
import settings from "../../../assets/images/common/settings.svg";
import edit from "../../../assets/images/common/edit-profile.svg";
import help from "../../../assets/images/common/faq-icon.svg";
import introVideo from "../../../assets/images/common/intro-video.png";
import NewCustomerReferral from "../../Referrals/NewCustomerReferral/NewCustomerReferral";
import HeaderView from "../../../components/HeaderView/HeaderView";
import { get } from "../../../storage/storage";
import talent_profile from "../../../assets/talent-profile.png";
import right_chevron from "../../../assets/images/common/grey-chevron-right.svg";
import ExternalTalentReferrals from "../../Referrals/ExternalTalentReferrals/ExternalTalentReferrals";
import ExternalTalentReferralsOptions from "../../Referrals/ExternalTalentReferrals/ExternalTalentReferralsOptions";
import DollarSignIcon from "../../../assets/images/common/dollar.png";
import Loading from "../../../push-components/loading/Loading";

const MyProfile: React.FC = () => {
  const history = useHistory();
  const url_param: any = useParams();
  const [talentFullname, setTalentFullname] = useState<string>("");
  const [talentisElite, setTalentIsElite] = useState<string>("");
  const [talentHeadshotImage, setTalentHeadshotImage] = useState<string>("");
  const [isOpenNewReferralModal, setIsOpenNewReferralModal] =
    useState<boolean>(false);
  const [isOpenNewReferralModalOptions, setIsOpenNewReferralModalOptions] =
    useState<boolean>(false);

  const [isOpenExternalReferralModal, setIsOpenExternalReferralModal] =
    useState<boolean>(false);
  const [isApiResponseComplete, setIsApiResponseComplete] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [menuItemLoading, setMenuItemLoading] = useState<boolean>(false);
  const [modelAccountLevelStr, setModelAccountLevelStr] = useState<string>("");
  const [externalReferralPoints, setExternalReferralPoints] = useState<any>(0);

  const navigateTo = (path: any) => {
    history.push(path);
  };
  const [modelTipaltiPaymentAvailable, setModelTipaltiPaymentAvailable] =
    useState<string>("");

  const closeNewReferralModal = async () => {
    setIsOpenNewReferralModal(false);
  };

  const closeExternalReferralModal = async () => {
    setIsOpenExternalReferralModal(false);
  };

  const openExternalReferralModalOptions = async () => {
    setMenuItemLoading(true);
    setIsOpenNewReferralModalOptions(true);

  }

  const closeExternalReferralModalOptions = async (type?: any, points?: any) => {
    setIsOpenNewReferralModalOptions(false);
    if(points) {
      setExternalReferralPoints(points);
    }
    if (type == 2) {
        // Invite Contacts
        setIsOpenExternalReferralModal(true);
    }
  };

  const goToFAQs = () => {
    history.push("/FAQs");
  };

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      setIsApiResponseComplete(false);

      let talentHeadshot = await get("login_user_headshot");
      if (talentHeadshot) {
        setTalentHeadshotImage(talentHeadshot);
      }
      let talent_last_name = await get("login_model_last_name");
      talent_last_name = talent_last_name ? talent_last_name : "";
      setTalentFullname(
        `${await get("login_model_first_name")} ${talent_last_name
          .toUpperCase()
          .charAt(0)}.`
      );

      let talent_is_elite = await get("login_model_is_elite");
      setTalentIsElite(talent_is_elite);

      setIsApiResponseComplete(true);
      setModelAccountLevelStr(await get("login_model_account_level_str"));
      setModelTipaltiPaymentAvailable(
        await get("model_tipalti_payment_available")
      );

      setShowLoader(false);
      if(url_param?.display == "referralPage"){
        setIsOpenNewReferralModalOptions(true)
      }
    })();
  }, []);
  return (
    <>
      <IonPage className="myprofile_view">
        <HeaderView></HeaderView>
        <IonContent>
          {showLoader && (
            <div className="slider-card_spinner">
              <IonSpinner color="primary" name="crescent" />
            </div>
          )}
          <IonCard className="my-profile-card">
            {isApiResponseComplete && (
              <>
                <div className="my-profile-card__talent_info">
                  <div className="my-profile-card__talent_info__img">
                    <img
                      src={
                        talentHeadshotImage
                          ? talentHeadshotImage
                          : talent_profile
                      }
                      alt=""
                    />
                    {talentisElite && (
                      <span className="my-profile-card__talent_info__statu">
                        ELITE
                      </span>
                    )}
                  </div>

                  <IonTitle className="my-profile-card__talent_info__name">
                    {talentFullname}
                  </IonTitle>
                  <IonText className="my-profile-card__talent_info__level">
                    {modelAccountLevelStr}
                  </IonText>
                </div>
                <div className="my-profile-card__profile_items">
                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => navigateTo("/ProfileView")}
                  >
                    <img src={user} alt="" slot="start" />
                    <IonText className="myprofile_label">View Profile</IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>

                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => navigateTo("/EditProfile")}
                  >
                    <img src={edit} alt="" slot="start" />
                    <IonText>Edit Profile</IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>
                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => navigateTo("/ManageIntroVideo")}
                  >
                    <img
                      className="my-profile-card__profile_item__intro-video"
                      src={introVideo}
                      alt=""
                      slot="start"
                    />
                    <IonText>Create Intro Video</IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>
                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => navigateTo("/MyCrew")}
                  >
                    <img src={users} alt="" slot="start" />
                    <IonText>View Crew</IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>

                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => openExternalReferralModalOptions() }
                  >
                    <img
                      className="my-profile-card__profile_item__refer__img"
                      src={invite_friends}
                      alt=""
                      slot="start"
                    />
                    <IonText>Invite Friends to Join PUSH</IonText>
                    <IonText
                      className="my-profile-card__profile_item__earn-points"
                      slot="end"
                    >
                      Earn Points +
                    </IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>
                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => setIsOpenNewReferralModal(true)}
                  >
                    <img src={affiliate} alt="" slot="start" />
                    <IonText>Refer a Customer</IonText>
                    <IonText
                      className="my-profile-card__profile_item__earn-points"
                      slot="end"
                    >
                      Earn Points +
                    </IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>
                  {modelTipaltiPaymentAvailable && (
                    <IonItem
                      className="my-profile-card__profile_item profile_item__no_border"
                      onClick={() => navigateTo("/PaymentDetails")}
                    >
                      <img
                        className="my-profile-card__profile_item__refer__img"
                        src={DollarSignIcon}
                        alt=""
                        slot="start"
                      />
                      <IonText>Setup Payment</IonText>
                      <IonIcon icon={right_chevron} slot="end" />
                    </IonItem>
                  )}

                  <IonItem
                    className="my-profile-card__profile_item profile_item__no_border"
                    onClick={() => navigateTo("/MySettings")}
                  >
                    <img src={settings} alt="" slot="start" />
                    <IonText>Settings</IonText>
                    <IonIcon icon={right_chevron} slot="end" />
                  </IonItem>

                  <IonItem
                    className="my-profile-card__profile_item"
                    onClick={() => goToFAQs()}
                  >
                    <img src={help} alt="" slot="start" />
                    <IonText className="">FAQs</IonText>
                    <IonIcon
                      className="my_setting_selection_icon"
                      icon={right_chevron}
                      slot="end"
                    />
                  </IonItem>
                </div>
              </>
            )}
          </IonCard>
        </IonContent>
        {isOpenNewReferralModal && (
          <NewCustomerReferral
            is_open_referral={isOpenNewReferralModal}
            closeReferral={closeNewReferralModal}
          />
        )}
        {isOpenExternalReferralModal && (
          <ExternalTalentReferrals
            is_open_external_referral={isOpenExternalReferralModal}
            closeNewExternalModal={closeExternalReferralModal}
            knownReferralPoints={externalReferralPoints}
          />
        )}
        {menuItemLoading && (<Loading showLoading={menuItemLoading} />)}
      </IonPage>

      {isOpenNewReferralModalOptions && (
          <ExternalTalentReferralsOptions
            closeModalHandler={closeExternalReferralModalOptions}
            isOpenNewReferralModalOptions={isOpenNewReferralModalOptions}
            setIsWidgetLoading={setMenuItemLoading}
            
            
          />
      )}


      
    </>
  );
};

export default MyProfile;

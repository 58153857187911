import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonItem,
  IonButton,
  IonRange,
  IonCheckbox,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonDatetime,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonLabel,
} from "@ionic/react";
import moment from "moment";
import "./EventFilters.css";
import { useDispatch } from "react-redux";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import backArrow from "../../assets/images/common/arrow-left.png";
import {
  getAssignmentTypes,
  getEventTypes,
} from "../../data-services/opportunities";
import {
  getUserCurrentPosition,
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
} from "../../common/common";
import { getTalentZipcode } from "../../data-services/signup";
import { off } from "process";
import { authActions } from "../../store/auth";

type ModalProps = {
  isOpen_opp_filter: boolean;
  closeOppFilter: Function;
  applyEventFilters: Function;
};

const EventFilters: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_opp_filter, closeOppFilter, applyEventFilters } = props;

  // DUPLICATE: Same "empty values" should be set also for resetFilterParam / submitFilter(true)
  const [minimumhourrate, setMinimumhourrate] = useState<number>(0);
  const [farout, setFarout] = useState<number>(0);
  const [eventTypes, setEventTypes] = useState<any>([]);
  const [assignmentTypes, setAssignmentTypes] = useState<any>([]);
  const [talentZipcode, setTalentZipcode] = useState<string>("");
  const [disabledCheckedBox, setDisabledCheckedbox] = useState<boolean>(false);

  const [eventDateFilter, setEventDateFilter] = useState<{
    start_date: string;
    end_date: string;
  }>({ start_date: "", end_date: "" });
  const [showCustomDates, setShowCustomDate] = useState<boolean>(false);
  const [selectedEventTypes, setSelectedEventTypes] = useState<any>([]);
  const [selectedAssignmentTypes, setSelectedAssignmentTypes] = useState<any>(
    []
  );
  const [dateFilterValue, setDateFilterValue] = useState<string>("");
  const [talentAccountLevels, setTalentAccountLevels] = useState<any>([]);

  const filterGetCurrentLocation = async (checked: boolean) => {
    if (checked) {
      setDisabledCheckedbox(true);
      const { latitude, longitude }: any = await getUserCurrentPosition();
      if (latitude && longitude) {
        let talent_zipcode = await getTalentZipcode(latitude, longitude);
        if (talent_zipcode?.success) {
          setTalentZipcode(talent_zipcode.data.zipcode);
        } else {
          await showToastMessage(
            talent_zipcode?.data?.errorCode == 0
              ? talent_zipcode?.data?.message
              : "Unable to fetch zipcode "
          );
        }
      }
      setDisabledCheckedbox(false);
    }
  };
  
  /**
   * Date Radio Box handler
   */
  const dateFilterByRadio = (range: string) => {
    setDateFilterValue(range);
    if (range === "any_time") {
      setEventDateFilter({ start_date: "", end_date: "" });
      setShowCustomDate(false);
    }
    if (range === "two_week") {
      let start = moment()
        .add(1, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      let end = moment().add(2, "weeks").endOf("isoWeek").format("YYYY-MM-DD");
      setEventDateFilter({ start_date: start, end_date: end });
      setShowCustomDate(false);
    }
    if (range === "next_week") {
      let start = moment()
        .add(1, "weeks")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
      let end = moment().add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD");
      setEventDateFilter({ start_date: start, end_date: end });
      setShowCustomDate(false);
    }
    if (range === "select_date") {
      setShowCustomDate(true);
    }
  };
  
  /**
   * Event Type checkbox click handler
   */
  const eventTypeFilterHandler = (event_type: any, event_value: any) => {
    if (event_type.target.checked) {
      setSelectedEventTypes([...selectedEventTypes, event_value]);
    } else {
      let index_of = selectedEventTypes.indexOf(event_value);

      if (index_of > -1) {
        // if item found in array
        selectedEventTypes.splice(index_of, 1);
      }
      setSelectedEventTypes(selectedEventTypes);
    }
  };

  /**
   * Assignment Type checkbox click handler
   */
  const assignmentTypeHandler = (event_type: any, event_value: any) => {
    if (event_type.target.checked) {
      setSelectedAssignmentTypes([...selectedEventTypes, event_value]);
    } else {
      let index_of = selectedEventTypes.indexOf(event_value);

      if (index_of > -1) {
        // if item found in array
        selectedEventTypes.splice(index_of, 1);
      }
      setSelectedAssignmentTypes(selectedEventTypes);
    }
  };

  /**
   * Apply Filter click handler
   */
  const submitFilters = (filtersCleared: any) => {
    let filter_param;
    if(filtersCleared) {
      // Reset button clicked scenario, search opps with no filters
      // DUPLICATE: Same "empty values" should be set also for resetFilterParam
      filter_param = {
        start_date: "",
        end_date: "",
        hour_rate: 0,
        location_zipcode: "",
        event_type: [],
        assignment_type: [],
        search_location_distance: 0,
        account_levels: [],
      };
    } else {
      filter_param = {
        start_date: eventDateFilter.start_date,
        end_date: eventDateFilter.end_date,
        hour_rate: minimumhourrate,
        location_zipcode: talentZipcode,
        event_type: selectedEventTypes,
        assignment_type: selectedAssignmentTypes,
        search_location_distance: farout,
        account_levels: talentAccountLevels,
      };
    }    
    applyEventFilters(filter_param);
  };

  /**
   * Reset button click handler
   */
  const resetFilterParam = () => {
    // Clear the UI Inputs filters before searching
    // DUPLICATE: Same "empty values" should be set also for submitFilter(true)
    setEventDateFilter({ start_date: "", end_date: "" });
    setMinimumhourrate(0);
    setTalentZipcode("");
    setSelectedEventTypes([]);
    setFarout(0);
    setDateFilterValue("");
    setTalentAccountLevels([]);
    setAssignmentTypes([]);
    let all_checkbox: any = document.querySelector("#account_level_checkbox");
    if (all_checkbox) {
      all_checkbox.checked = false;
    }
    let account_type_check: any = document.querySelector("#account_type_check");
    if (account_type_check) {
      account_type_check.checked = false;
    }
    submitFilters(true);
  };

  /**
   * Select all Gig Types
   */
  const selectAllItem = (status: any) => {
    if (status) {
      let result = eventTypes.map((a: any) => a.id);
      setSelectedEventTypes(result);
    } else {
      setSelectedEventTypes([]);
    }
  };

  /**
   * Select all Assignment Types
   */
  const selectAllAssignmentTypes = (status: any) => {
    if (status) {
      let result = assignmentTypes.map((a: any) => a.id);
      setSelectedAssignmentTypes(result);
    } else {
      setSelectedAssignmentTypes([]);
    }
  };

  const accountLevelChangeHandler = (level: any) => {
    if (level.detail.checked) {
      setTalentAccountLevels([...talentAccountLevels, level.detail.value]);
    } else {
      let index_of = talentAccountLevels.indexOf(level.detail.value);
      if (index_of > -1) {
        talentAccountLevels.splice(index_of, 1);
      }
      setTalentAccountLevels(talentAccountLevels);
    }
  };
  const accountLevelAllChangeHandler = (e: any) => {
    if (e.detail.checked) {
      // fix this logic
      setTalentAccountLevels(["Temp"]);
      setTalentAccountLevels(["Temp", "Ambassador"]);
      setTalentAccountLevels(["Temp", "Ambassador", "Model"]);
      setTalentAccountLevels(["Influencer", "Temp", "Ambassador", "Model"]);
    } else {
      setTalentAccountLevels([]);
    }
  };
  useEffect(() => {
    (async () => {
      let api_response = await getEventTypes();
      if (
        typeof api_response.success !== "undefined" &&
        api_response.success === false
      ) {
        if (api_response?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (api_response?.data?.errorCode == offlineErrorCode) {
          showToastMessage(api_response?.data?.message);
        } else if (api_response?.message) {
          showToastMessage(api_response.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } else {
        setEventTypes(api_response);
      }

      let assignment_types_api_response = await getAssignmentTypes();
      if (
        typeof assignment_types_api_response.success !== "undefined" &&
        assignment_types_api_response.success === false
      ) {
        if (
          assignment_types_api_response?.data?.errorCode ==
          unauthorizedErrorCode
        ) {
          dispatch(authActions.logout());
          return;
        } else if (
          assignment_types_api_response?.data?.errorCode == offlineErrorCode
        ) {
          showToastMessage(assignment_types_api_response?.data?.message);
        } else if (assignment_types_api_response?.message) {
          showToastMessage(assignment_types_api_response.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } else {
        setAssignmentTypes(assignment_types_api_response);
      }
    })();
  }, []);
  return (
    <IonModal isOpen={isOpen_opp_filter} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButton
            className="opp_filter_header__back_btn"
            fill="clear"
            slot="start"
            onClick={() => closeOppFilter()}
          >
            <img alt="" src={backArrow} className="" />
          </IonButton>
          <IonTitle className="opp_filter_header_title">Filters</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => submitFilters(false)}>
              <IonLabel>Save</IonLabel>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="opportunity_filter__container">
          <div className="min_hour_rate">
            <h1 className="min_hour_rate__title">Minimum Hourly Rate</h1>
            <h3 className="min_hour_rate__amount">${minimumhourrate} / hr</h3>
            <IonRange
              className="min_hour_rate__range"
              mode="ios"
              value={minimumhourrate}
              min={0}
              max={100}
              onIonChange={(e) => {
                setMinimumhourrate(e.detail.value as number);
              }}
            />
            <span className="min_hour_rate_label">{minimumhourrate}$</span>
          </div>

          <div className="location_filter">
            <h1 className="location_filter__title">Location</h1>
            <input
              type="text"
              placeholder="Enter Zip Code"
              className="form-group__input-styles"
              value={talentZipcode}
              onChange={(e: any) => setTalentZipcode(e.target.value)}
            ></input>
            <IonItem className="location_filter__use_current">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                onIonChange={(e: any) =>
                  filterGetCurrentLocation(e.detail.checked)
                }
                disabled={disabledCheckedBox}
              ></IonCheckbox>
              Use current location
            </IonItem>
          </div>

          <div className="how_far">
            <h1 className="how_far__title">How Far Out</h1>
            <h3 className="how_far__title__amount">{farout} miles</h3>
            <IonRange
              className="how_far__range"
              mode="ios"
              value={farout}
              min={0}
              max={100}
              onIonChange={(e) => {
                setFarout(e.detail.value as number);
              }}
            />
            <span className="how_far__label_left">{farout} miles</span>
            <span className="how_far__label_right">100 miles</span>
          </div>

          <div className="filter_date filter__no_border">
            <h1 className="filter_date__title">Date</h1>
            <IonRadioGroup
              onIonChange={(e: any) => dateFilterByRadio(e.detail.value)}
              value={dateFilterValue}
            >
              <IonItem className="filter_date__items">
                <IonRadio value="any_time" mode="md" slot="start" /> Any time
              </IonItem>
              <IonItem className="filter_date__items">
                <IonRadio value="two_week" mode="md" slot="start" /> Next two
                weeks
              </IonItem>
              <IonItem className="filter_date__items">
                <IonRadio value="next_week" mode="md" slot="start" /> Next week
              </IonItem>
              <IonItem className="filter_date__items">
                <IonRadio value="select_date" mode="md" slot="start" /> Select
                Dates
              </IonItem>
            </IonRadioGroup>
            {showCustomDates && (
              <IonItem className="filter_date__items">
                <IonDatetime
                  slot="start"
                  displayFormat="MMM-YYYY"
                  placeholder="Start Date"
                  onIonChange={(e: any) =>
                    setEventDateFilter({
                      ...eventDateFilter,
                      start_date: moment(e.detail.value)
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                    })
                  }
                ></IonDatetime>
                <IonDatetime
                  slot="start"
                  displayFormat="MMM-YYYY"
                  placeholder="End Date"
                  onIonChange={(e: any) =>
                    setEventDateFilter({
                      ...eventDateFilter,
                      end_date: moment(e.detail.value)
                        .endOf("month")
                        .format("YYYY-MM-DD"),
                    })
                  }
                ></IonDatetime>
              </IonItem>
            )}
          </div>

          <div className="filter_event_type">
            <h1 className="filter_event_type__title">Assignment Types</h1>
            <IonItem className="filter_event_type__items">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                id="account_type_check"
                onIonChange={(e: any) =>
                  selectAllAssignmentTypes(e.detail.checked)
                }
              ></IonCheckbox>
              Select all
            </IonItem>

            {assignmentTypes.map((type: any, index: number) => (
              <IonItem className="filter_event_type__items" key={index}>
                <IonCheckbox
                  mode="ios"
                  slot="start"
                  className="form-group__checkbox-styles"
                  value={type.id}
                  checked={selectedAssignmentTypes.includes(type.id)}
                  onClick={(e: any) => {
                    assignmentTypeHandler(e, type.id);
                  }}
                ></IonCheckbox>
                {type.name}
              </IonItem>
            ))}
          </div>

          <div className="filter_event_type">
            <h1 className="filter_event_type__title">Gig Types</h1>

            {eventTypes.length > 0 && (
              <>
                <IonItem className="filter_event_type__items">
                  <IonCheckbox
                    mode="ios"
                    slot="start"
                    className="form-group__checkbox-styles"
                    id="account_type_check"
                    onIonChange={(e: any) => selectAllItem(e.detail.checked)}
                  ></IonCheckbox>
                  Select all
                </IonItem>

                {eventTypes.map((type: any, index: number) => (
                  <IonItem className="filter_event_type__items" key={index}>
                    <IonCheckbox
                      mode="ios"
                      slot="start"
                      className="form-group__checkbox-styles"
                      value={type.id}
                      checked={selectedEventTypes.includes(type.id)}
                      onClick={(e: any) => {
                        eventTypeFilterHandler(e, type.id);
                      }}
                    ></IonCheckbox>
                    {type.name}
                  </IonItem>
                ))}
              </>
            )}
          </div>
          <div className="filter_account_level">
            <h1 className="filter_account_level__title">Account Level</h1>
            <IonItem className="filter_account_level__items">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                value="all"
                id="account_level_checkbox"
                onIonChange={(e: any) => accountLevelAllChangeHandler(e)}
              ></IonCheckbox>
              Select all
            </IonItem>
            <IonItem className="filter_account_level__items">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                value="Temp"
                checked={talentAccountLevels.includes("Temp")}
                onIonChange={(e: any) => accountLevelChangeHandler(e)}
              ></IonCheckbox>
              Basic
            </IonItem>
            <IonItem className="filter_account_level__items">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                value="Ambassador"
                checked={talentAccountLevels.includes("Ambassador")}
                onIonChange={(e: any) => accountLevelChangeHandler(e)}
              ></IonCheckbox>
              Ambassador
            </IonItem>
            <IonItem className="filter_account_level__items">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                value="Model"
                checked={talentAccountLevels.includes("Model")}
                onIonChange={(e: any) => accountLevelChangeHandler(e)}
              ></IonCheckbox>
              Model
            </IonItem>
            <IonItem className="filter_account_level__items">
              <IonCheckbox
                mode="ios"
                slot="start"
                className="form-group__checkbox-styles"
                value="Influencer"
                checked={talentAccountLevels.includes("Influencer")}
                onIonChange={(e: any) => accountLevelChangeHandler(e)}
              ></IonCheckbox>
              Influencer
            </IonItem>
          </div>

          

          <div className="filter_submit_btn">
            <IonButton
              expand="block"
              mode="ios"
              type="button"
              fill="outline"
              className="form-group__button"
              onClick={resetFilterParam}
            >
              Reset
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default EventFilters;

import React, { useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonLabel,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router";
import "./AddEditExperience.css";
import { Toast } from "@capacitor/toast";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  updateExperienceData,
  getResumeExperienceCategories,
} from "../../../../data-services/edit-profile";
import { showToastMessage } from "../../../../common/common";
import { getStatesForSignUp } from "../../../../data-services/signup";
import arrow_left from "../../../../assets/images/common/arrow-left.png";
type ModalProps = {
  isOpen_modal_experience: boolean;
  closeModalExperience: Function;
  experienceId: any;
  experiencedata: any;
};

const AddEditExperience: React.FC<ModalProps> = (props) => {
  const {
    isOpen_modal_experience,
    closeModalExperience,
    experienceId,
    experiencedata,
  } = props;
  const location: any = useLocation();
  const [eventcategory, setEventCategory] = useState<any>([]);
  const [eventcountry, setEventCountry] = useState<any>([]);
  const [eventstate, setEventState] = useState<any>([]);
  const [eventyear, setEventYear] = useState<any>([]);
  const [currentCountry, setCurrentCountry] = useState<any>("");
  const [enabledSubmitBtn, setEnabledSubmitBtn] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      meId: 0,
      event_category: "",
      event_name: "",
      event_year: "",
      event_city: "",
      event_state: "",
      event_country: "",
    },
    mode: "onBlur",
  });

  const getStateList = async (country: any, state: any = "") => {
    setCurrentCountry(country);
    let State: any = [];
    let experience_state_data: any = await getStatesForSignUp(country);
    if (experience_state_data) {
      let states = experience_state_data;

      Object.entries(states).forEach(([key, value]) => {
        State.push({ state_key: key, state_value: value });
      });
      setEventState(State);

      if (state != "") {
        setTimeout(async () => {
          await setValue("event_state", state);
        }, 100);
      }
    }
  };

  const experienceInit = async () => {
    let Country = [
      {
        key: "USA",
        value: "USA",
      },
      {
        key: "Canada",
        value: "Canada",
      },
    ];
    setEventCountry(Country);

    let Category = [];

    let Year = [];

    let experience_data: any = await getResumeExperienceCategories();

    if (experience_data?.success) {
      if (experience_data.data.categories) {
        for (var elem in experience_data.data.categories) {
          Category.push({
            key: elem,
            value: experience_data.data.categories[elem],
          });
        }
        setEventCategory(Category);

        for (var elem in experience_data.data.years) {
          Year.push({
            key: elem,
            value: experience_data.data.years[elem]?.toString(),
          });
        }

        setEventYear(Year);
      }
    }
  };

  const singupFormHandler = async (data: any) => {
    setEnabledSubmitBtn(true);
    let meid: any = "";
    if (getValues("meId") > 0) {
      meid = getValues("meId");
    }
    let event_form: any = {
      meId: meid,
      experienceNameEvent: getValues("event_name"),
      experienceCategory: getValues("event_category"),
      experienceCountry: getValues("event_country"),
      experienceState: getValues("event_state"),
      experienceCity: getValues("event_city"),
      experienceYear: getValues("event_year"),
    };

    let update_api_response = await updateExperienceData(event_form);

    if (update_api_response?.success == 1) {
      clearData();
      closeModalExperience();
    } else {
      await showToastMessage(update_api_response.message);
    }
    setEnabledSubmitBtn(false);
  };
  const ispresent = async () => {
    await experienceInit();
    if (experienceId > 0) {
      let data = experiencedata;
      setValue("event_name", data.eventName);
      setValue("event_category", data.meCategory);
      setValue("event_country", data.meCountry);
      setValue("event_city", data.meCity);
      setValue("event_year", data.meYear);
      setValue("meId", data.meId);
      getStateList(data.meCountry, data.meState);
    } else {
      clearData();
    }
  };

  const onBackButton = () => {
    clearData();
    closeModalExperience();
  };

  const clearData = () => {
    setValue("event_name", "");
    setValue("event_category", "");
    setValue("event_country", "");
    setValue("event_city", "");
    setValue("event_year", "");
    setValue("meId", 0);
    setEventState([]);
    reset();
  };

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <IonModal isOpen={isOpen_modal_experience} onDidPresent={ispresent}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => closeModalExperience()}>
              <img src={arrow_left} alt="" />
            </IonButton>
          </IonButtons>
          {/* <IonButtons slot="start">
                <IonBackButton defaultHref="/" mode="md" />
              </IonButtons> */}
          <IonTitle className="header-toolbar-title">
            {experienceId == 0 ? "Add Experience" : "Edit Experience"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          onSubmit={handleSubmit(singupFormHandler)}
          className="experience-container"
        >
          <p className="experience-container__title">
            Share your job <br />
            experience
          </p>
          <p className="experience-container__content">
            Upload your relevant experience to further complete your profile.
            You may add any additional experience in your profile settings.
          </p>

          <section className="mobile">
            <div className="form-group">
              <IonLabel className="form-group__label">Event Category</IonLabel>
              <IonSelect
                placeholder="--Select--"
                className={
                  errors.event_category !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("event_category", {
                  required: "This field is required.",
                })}
              >
                {eventcategory &&
                  eventcategory.map((category: any) => (
                    <IonSelectOption value={category.value}>
                      {category.key}
                    </IonSelectOption>
                  ))}
              </IonSelect>

              <ErrorMessage
                errors={errors}
                name="event_category"
                as={<div className="error-message" />}
              />
            </div>

            <div className="form-group">
              <IonLabel className="form-group__label">Event Name</IonLabel>

              <input
                type="text"
                placeholder="Event Name"
                className={
                  errors.event_name !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("event_name", {
                  required: "This field is required.",
                })}
                onInput={(e: any) => setValue("event_name", e.target.value)}
              ></input>

              <ErrorMessage
                errors={errors}
                name="event_name"
                as={<div className="error-message" />}
              />
            </div>

            <div className="form-group">
              <IonLabel className="form-group__label">Event Year</IonLabel>

              <IonSelect
                placeholder="--Select--"
                className={
                  errors.event_year !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("event_year", {
                  required: "This field is required.",
                })}
              >
                {eventyear &&
                  eventyear.map((year: any) => (
                    <IonSelectOption value={year.value}>
                      {year.key}
                    </IonSelectOption>
                  ))}
              </IonSelect>

              <ErrorMessage
                errors={errors}
                name="event_year"
                as={<div className="error-message" />}
              />
            </div>

            <div className="form-group">
              <IonLabel className="form-group__label">Country</IonLabel>

              <IonSelect
                placeholder="--Select--"
                onIonChange={(e) => getStateList(e.detail.value)}
                className={
                  errors.event_country !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("event_country", {
                  required: "This field is required.",
                })}
              >
                {eventcountry &&
                  eventcountry.map((country: any, index: any) => (
                    <IonSelectOption value={country.value} key={index}>
                      {country.key}
                    </IonSelectOption>
                  ))}
              </IonSelect>

              <ErrorMessage
                errors={errors}
                name="event_country"
                as={<div className="error-message" />}
              />
            </div>

            <div className="form-group">
              <IonLabel className="form-group__label">State</IonLabel>
              <IonSelect
                placeholder="--Select--"
                disabled={currentCountry == "" ? true : false}
                className={
                  errors.event_state !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("event_state", {
                  required: "This field is required.",
                })}
              >
                {eventstate &&
                  eventstate.map((state: any) => (
                    <IonSelectOption value={state.state_key}>
                      {state.state_value}
                    </IonSelectOption>
                  ))}
              </IonSelect>
              <ErrorMessage
                errors={errors}
                name="event_state"
                as={<div className="error-message" />}
              />
            </div>

            <div className="form-group">
              <IonLabel className="form-group__label">City</IonLabel>

              <input
                type="text"
                placeholder="Eg. Los Angles"
                className={
                  errors.event_city !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("event_city", {
                  required: "This field is required.",
                })}
                onInput={(e: any) => setValue("event_city", e.target.value)}
              ></input>
              <ErrorMessage
                errors={errors}
                name="event_city"
                as={<div className="error-message" />}
              />
            </div>
          </section>
          <div className="experience__submit">
            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button"
              disabled={enabledSubmitBtn}
            >
              {experienceId == 0 ? "Add Experience" : "Edit Experience"}
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default AddEditExperience;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonModal,
  IonToolbar,
  IonLabel,
  IonTitle,
  IonButtons,
  IonList,
  IonItem,
} from "@ionic/react";
import white_back_arrowa from "../../assets/images/common/white_back_arrowa.svg";
import trash_icon from "../../assets/images/common/trash_icon.svg";
import edit_pencil from "../../assets/images/common/edit_pencil.svg";
import check_orange_icon from "../../assets/images/common/check_orange_icon.svg";
import {
  getUserCurrentPosition,
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
} from "../../common/common";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import locatin_icon from "../../assets/images/signup/img/icon-location.png";
import plus_icon from "../../assets/images/common/plus_icon.png";
import {
  getTalentZipcode
} from "../../data-services/signup";
import "./AddLocation.css";
import {
  deleteModelLocation,
  getModelLocations,
  insertModelLocation,
  selectModelLocation,
} from "../../data-services/home-api";
import { useHistory } from "react-router";
import { get, set } from "../../storage/storage";
import { invalidateOppsCache } from "../../data-services/opportunities";
import { logError } from "../../data-services/assignmets";

type locationProps = {
  isLcationModalOpen: boolean;
  closeLocationModal: Function;
  locationId?: string;
  isEdit?: string;
};

const AddLocation: React.FC<locationProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLcationModalOpen, closeLocationModal, locationId, isEdit } = props;

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [getLocationBtn, setGetLocationBtn] = useState<boolean>(false);
  const [zipcodeApiData, setZipcodeApiData] = useState<any>(null);
  const [showListOrAddAddress, setShowListOrAddAddress] = useState(false);
  const [addressList, setAddressList] = useState<any>([]);
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_zipcode: "",
    },
    mode: "onBlur",
  });

  const addLocationSubmitHandler = async () => {
    setDisabledSubmitBtn(true);
    let form_data: any = {
      zipcode: getValues("talent_zipcode"),
      country: zipcodeApiData ? zipcodeApiData.country : "",
      state: zipcodeApiData ? zipcodeApiData.state : "",
      city2: zipcodeApiData ? zipcodeApiData.model_city : "",
      latitude: zipcodeApiData ? zipcodeApiData.latitude : "",
      longitude: zipcodeApiData ? zipcodeApiData.longitude : "",
    };
    try {
      let api_response = await insertModelLocation(form_data);
      if (api_response?.success) {
        // Clear stored opps as the added location will be selected (and opps will be searched by it)
        await invalidateOppsCache();
        await showToastMessage(api_response.message);
        await getAddressList(form_data.zipcode);
        closeModalHandler();
      } else {
        await showToastMessage(api_response.message);
      }
      setZipcodeApiData(null);
      setDisabledSubmitBtn(false);
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Add Location - addLocationSubmitHandler - Exception thrown",
          "Talent App - Add Location",
          JSON.stringify(e.message)
        );
        
      }
    }
  };
  const getTalentCurrentPosition = async () => {
    setGetLocationBtn(true);
    const { latitude, longitude }: any = await getUserCurrentPosition();
    if (latitude && longitude) {
      let talent_zipcode = await getTalentZipcode(latitude, longitude);
      if (talent_zipcode?.success) {
        setZipcodeApiData(talent_zipcode.data);
        setValue("talent_zipcode", talent_zipcode.data.zipcode);
      } else {
        await showToastMessage(
          talent_zipcode?.data?.errorCode == 0
            ? talent_zipcode?.data?.message
            : "Unable to fetch zipcode "
        );
      }
    }
    setGetLocationBtn(false);
  };
  const getAddressList = async (zipcode: string = "") => {
    let locations: any = await getModelLocations();
    if (locations?.success) {
      // newly added address make default one
      if (zipcode) {
        for (let i = 0; i < locations.data.length; i++) {
          let a_address = locations.data[i];
          if (a_address.zipcode == zipcode) {
            await selectLocationHandler(a_address);
            return;
          }
        }
      }

      for (let index = 0; index < locations.data.length; index++) {
        let element = locations.data[index];

        if (element.selected == "1") {
          if (!element.primary_location) {
            await set("selected_loction", element.location_id);
            await set("none_primary_address", true);
            await set("latitude", element.latitude);
            await set("longitude", element.longitude);
            await set("you_are_in", (element.city2 && element.state) ? `${element.city2}, ${element.state}` : `${element.zipcode}`);
          } else {
            await set("none_primary_address", false);
            await set("latitude", "");
            await set("longitude", "");
            await set("selected_loction", "selected");
            await set("you_are_in", (element.city2 && element.state) ? `${element.city2}, ${element.state}` : `${element.zipcode}`);
          }
        }
      }

      setAddressList(locations.data);
    } else if (locations?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
    } else if (locations?.data?.errorCode == offlineErrorCode) {
      showToastMessage(locations?.data?.message);
    } else if (locations?.message) {
      showToastMessage(locations.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };
  const selectLocationHandler = async (address: any = null) => {
    let api_res: any = "";
    if (address != null) {
      try {
        api_res = await selectModelLocation(address.location_id);
        await set("selected_loction", address.location_id);
        await set("none_primary_address", true);
        await set("latitude", address.latitude);
        await set("longitude", address.longitude);
        await set("you_are_in", (address.city2 && address.state) ? `${address.city2}, ${address.state}` : `${address.zipcode}`);
      } catch (e: any) {
        if (e.message == unauthorizedErrMsg) {
          dispatch(authActions.logout());
          return;
        } else {
          showToastMessage(somethingWentWrongErrMsg);
          await logError(
            "Add Location - selectLocationHandler - Exception thrown",
            "Talent App - Add Location",
            JSON.stringify(e.message)
          );
          
        }
      }
    } else {
      try {
        api_res = await selectModelLocation("");
        await set("none_primary_address", false);
        await set("latitude", "");
        await set("longitude", "");
        await set("selected_loction", "selected");
        await set(
          "you_are_in",
          `${await get("login_model_city")}, ${await get("login_model_state")}`
        );
      } catch (e: any) {
        if (e.message == unauthorizedErrMsg) {
          dispatch(authActions.logout());
          return;
        } else {
          showToastMessage(somethingWentWrongErrMsg);
          await logError(
            "Add Location - selectLocationHandler - Exception thrown",
            "Talent App - Add Location",
            JSON.stringify(e.message)
          );
          
        }
      }
    }
    // Clear stored opps as opps will be retrieved based on the newly selected location
    await invalidateOppsCache();
    if (api_res?.success) {
      await getAddressList();
      closeModalHandler();
    } else {
      if (api_res?.data?.errorCode == offlineErrorCode) {
        await showToastMessage(api_res?.data?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };
  const deleteLocationHandler = async (address: any) => {
    if (address.selected == "1") {
      await selectLocationHandler();
    }
    try {
      let api_res = await deleteModelLocation(address.location_id);
      if (api_res?.success) {
        await showToastMessage("Address removed!");
        await getAddressList();
      } else {
        if (api_res?.data?.errorCode == offlineErrorCode) {
          await showToastMessage(api_res?.data?.message);
        } else if (api_res?.message) {
          showToastMessage(api_res.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Add Location - deleteLocationHandler - Exception thrown",
          "Talent App - Add Location",
          JSON.stringify(e.message)
        );
        
      }
    }
  };
  const showAddLocationForm = () => {
    setShowListOrAddAddress(true);
  };
  const closeModalHandler = () => {
    if (showListOrAddAddress) {
      setShowListOrAddAddress(false);
    } else {
      closeLocationModal();
    }
  };

  useEffect(() => {
    (async () => {
      await getAddressList();
    })();
  }, []);
  return (
    <>
      <IonModal isOpen={isLcationModalOpen} mode="md">
        <IonHeader>
          <IonToolbar mode="ios" color="primary">
            <IonButtons>
              <IonButton onClick={() => closeModalHandler()}>
                <img src={white_back_arrowa} alt="" />
              </IonButton>
            </IonButtons>
            <IonTitle> Add address</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {!showListOrAddAddress && (
            <div className="add_location__list">
              {addressList.length > 0}
              <IonList mode="ios" className="add_location__list_items">
                {addressList.map((address: any, index: number) => (
                  <IonItem key={index}>
                    <IonLabel
                      onClick={() =>
                        selectLocationHandler(
                          address.location_id ? address : null
                        )
                      }
                    >{(address.city2 && address.state) && (<>{address.city2}, {address.state}</>)}
                    {!(address.city2 && address.state) && (<>{address.zipcode}</>)}</IonLabel>
                    {address.selected == "1" && (
                      <IonIcon slot="end" src={check_orange_icon}></IonIcon>
                    )}
                    {address.primary_location ? (
                      <IonIcon
                        onClick={() => history.push("/EditProfile#address")}
                        slot="end"
                        src={edit_pencil}
                      ></IonIcon>
                    ) : (
                      <IonIcon
                        onClick={() => deleteLocationHandler(address)}
                        slot="end"
                        src={trash_icon}
                      ></IonIcon>
                    )}
                  </IonItem>
                ))}
              </IonList>
              <IonButton
                fill="clear"
                onClick={showAddLocationForm}
                disabled={getLocationBtn}
                className="add_location__open_form"
              >
                <img src={plus_icon} alt="" /> Add location
              </IonButton>
            </div>
          )}
          {showListOrAddAddress && (
            <form
              className="add_location"
              onSubmit={handleSubmit(addLocationSubmitHandler)}
            >
              <div className="form-group">
                <IonLabel className="form-group__label">Zip Code</IonLabel>
                <input
                  type="text"
                  placeholder="Eg. 83971"
                  className={
                    errors.talent_zipcode !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
        
                  {...register("talent_zipcode", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="talent_zipcode"
                  as={<div className="error-message" />}
                />
              </div>

              <IonButton
                fill="clear"
                onClick={getTalentCurrentPosition}
                disabled={getLocationBtn}
                className="add_location__get_current_loc"
              >
                <img src={locatin_icon} alt="" /> Use current location
              </IonButton>

              <div className="add_location__submit_btn">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="submit"
                  className="form-group__button"
                  disabled={disabledSubmitBtn}
                >
                  Next
                </IonButton>
              </div>
            </form>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default AddLocation;

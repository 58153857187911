import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonToolbar,
  IonLabel,
  IonText,
  IonTitle,
  IonInput,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import "./MyRewards.css";
import edit_icon from "../../assets/images/signup/img/edit.png";
import white_back_arrowa from "../../assets/images/common/white_back_arrowa.svg";
import { get } from "../../storage/storage";
import { claimModelReward } from "../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { getStatesForSignUp } from "../../data-services/signup";
import check_icon from "../../assets/images/common/check.png";
import check_black from "../../assets/images/common/check_black.svg";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";

type claimRewards = {
  isOpenRewardModal: boolean;
  closeRewardModal: Function;
  talenRewardId: string;
  targetReward?: any;
  targetAttributesValuesChecks?: any;
  rewardModalType: string;
  eliteReward?: any;
  viewOnly?: any;
};

const RedeemRewardModal: React.FC<claimRewards> = (props) => {
  const dispatch = useDispatch();
  const {
    isOpenRewardModal,
    closeRewardModal,
    talenRewardId,
    targetReward,
    targetAttributesValuesChecks,
    rewardModalType,
    eliteReward,
    viewOnly,
  } = props;

  const [talentEmail, setTalentEmail] = useState<string>("");
  const [talentName, setTalentName] = useState<string>("");
  const [talentFullAdress, setTalentFullAddress] = useState<{
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    appartment: string;
  }>({
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    appartment: "",
  });
  const [editTalentInfo, setEditTalentInfo] = useState<string>(rewardModalType);
  const [attributesValuesChecks, setAttributesValuesChecks] = useState<any>(
    targetAttributesValuesChecks
  );
  const [signupStateList, setSignupStateList] = useState<any>([]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [reedemable, setIsRedeemable] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_email: "",
      address: "",
      appartment_no: "",
      country: "",
      state: "",
      city: "",
      zipcode: ""
    },
    mode: "onBlur",
  });

  const claimModelRewardHandler = async () => {
    // Check if the current reward has attributes
    // If so, parse the selected attribute ID and value ID
    let uncheckedAttributeName = "";
    let checkedAttributesValuesCount = 0;
    let finalCheckedAttributesValues = [];

    if (targetReward?.attributes?.length) {
      // For each Attribute, check that we have at least one selected value and retrieve it!
      for (const attribute of targetReward.attributes) {
        if (attribute?.values?.length) {
          let noValueChecked = true;
          for (const value of attribute.values) {
            if (
              attributesValuesChecks["attribute" + attribute.attribute.ra_id][
                "value" + value.marav_id
              ] === true
            ) {
              finalCheckedAttributesValues.push([
                attribute.attribute.ra_name,
                value.marava_value_name,
              ]);
              checkedAttributesValuesCount++;
              noValueChecked = false;
            }
          }
          if (noValueChecked) {
            uncheckedAttributeName = attribute.attribute.ra_name;
            break;
          }
        }
      }
    }

    if (
      !targetReward?.attributes?.length ||
      targetReward?.attributes?.length == checkedAttributesValuesCount
    ) {
      setDisabledSubmitBtn(true);
      let reward_api = await claimModelReward(
        talenRewardId,
        talentEmail,
        talentFullAdress.appartment
          ? `${talentFullAdress.address}, Apt ${talentFullAdress.appartment}; ${talentFullAdress.city} ${talentFullAdress.state}, ${talentFullAdress.zipcode}; ${talentFullAdress.country}`
          : `${talentFullAdress.address} ${talentFullAdress.city} ${talentFullAdress.state}, ${talentFullAdress.zipcode}; ${talentFullAdress.country}`,
        finalCheckedAttributesValues,
        eliteReward
      );
      if (reward_api?.success) {
        closeRewardModal(reward_api.message, reward_api.data.message_body);
      } else if (reward_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (reward_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(reward_api?.data?.message);
      } else if (reward_api?.message) {
        showToastMessage(reward_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
      setDisabledSubmitBtn(false);
    } else {
      showToastMessage(
        "Please select " +
          (uncheckedAttributeName.match("^[aieouAIEOU].*") ? "an" : "a") +
          " " +
          uncheckedAttributeName
      );
    }
  };
  const submitEditInfoForm = async () => {
    setTalentEmail(getValues("talent_email").trim());
    setTalentName(
      (await get("login_model_first_name")) +
        " " +
        (await get("login_model_last_name"))
    );
    setTalentFullAddress({
      address: getValues("address"),
      city: getValues("city"),
      state: getValues("state"),
      zipcode: getValues("zipcode"),
      country: getValues("country"),
      appartment: getValues("appartment_no"),
    });
    setEditTalentInfo("save_modal");
  };
  const getStateListBaseOnCountry = async (country: string) => {
    setSignupStateList(await getStatesForSignUp(country));
  };

  const attributeValueHandler = (attribute_id: any, value_id: any) => {
    // Create a copy of the current checkedAttributesValues object so the state hook triggers when setting checkedAttributesValues
    let newArray = Object.assign({}, attributesValuesChecks);

    // Iterate through the rewardsArray and set the proper values ONLY for the given reward_id/ attribute_id

    if (targetReward?.attributes?.length) {
      for (const attribute of targetReward.attributes) {
        if (attribute.attribute.ra_id == attribute_id) {
          if (attribute?.values?.length) {
            for (const value of attribute.values) {
              // Value is true if the current value is equal to the provided one (value_id), false otherwise
              newArray["attribute" + attribute.attribute.ra_id][
                "value" + value.marav_id
              ] = value.marav_id == value_id;
            }
          }
        }
      }
    }
    setAttributesValuesChecks(newArray);
  };
  useEffect(() => {
    (async () => {
      if (
        targetReward?.track_percentage == 100 &&
        targetReward?.mar_amount != 0
      ) {
        setIsRedeemable(true);
      } else {
        setIsRedeemable(false);
      }
      setEditTalentInfo(rewardModalType);
      setAttributesValuesChecks(targetAttributesValuesChecks);
      setTalentEmail(await get("login_model_email"));
      setTalentName(
        (await get("login_model_first_name")) +
          " " +
          (await get("login_model_last_name"))
      );
      setTalentFullAddress({
        address: await get("login_model_address"),
        city: await get("login_model_city"),
        state: await get("login_model_state"),
        zipcode: await get("login_model_zipcode"),
        country: await get("login_model_country"),
        appartment: await get("login_model_apartment_number"),
      });
      await getStateListBaseOnCountry(await get("login_model_country"));
      setValue("talent_email", await get("login_model_email"));
      setValue("address", await get("login_model_address"));
      setValue("country", await get("login_model_country"));
      setValue("state", await get("login_model_state"));
      setValue("city", await get("login_model_city"));
      setValue("zipcode", await get("login_model_zipcode"));
      setValue("appartment_no", await get("login_model_apartment_number"));
    })();
  }, [isOpenRewardModal]);
  return (
    <>
      {editTalentInfo === "save_modal" && (
        <IonModal
          isOpen={isOpenRewardModal}
          cssClass="redeem_reward_modal"
          mode="ios"
          backdropDismiss={false}
        >
          <SimpleHeaderView
            title={
              eliteReward
                ? "Claim FREE Reward"
                : viewOnly
                ? "View Reward"
                : "Claim Reward"
            }
            bgcolor="primary"
            buttonType="undefined"
            onBack={closeRewardModal}
          ></SimpleHeaderView>

          <IonContent>
            <div className="redeem_reward__content">
              {targetReward?.model_reward_name && (
                <div className="redeem_reward__name">
                  <IonLabel>Reward</IonLabel>
                  <IonText>{targetReward.model_reward_name}</IonText>
                  <div className="redeem_reward__content__img">
                    <img src={targetReward.image} className="" alt="" />
                  </div>
                </div>
              )}
              {targetReward.attributes && (
                <div className="redeem_reward__attributes">
                  <div className="my_reward_list__card_content__attributes">
                    {targetReward.attributes.map(
                      (attribute: any, index: any) => (
                        <div className="my_reward_list__card_content__attributes__attribute">
                          <IonLabel>
                            {viewOnly ? "" : "Select "}
                            {attribute.attribute.ra_name}
                          </IonLabel>
                          {attribute.attribute.ra_name == "Color" && (
                            <div className="my_reward_list__card_content__attributes__color">
                              {attribute?.values &&
                                attribute.values.map(
                                  (color: any, index: number) => (
                                    <div
                                      className="my_reward_list__card_content__attributes__color__item"
                                      key={index}
                                    >
                                      {color.marava_value_name === "#ffffff" ||
                                      color.marava_value_name.toLowerCase() ===
                                        "white" ? (
                                        <div
                                          style={{
                                            backgroundColor:
                                              color.marava_value_name,
                                            border: "1px solid black",
                                          }}
                                          className="my_reward_list__card_content__attributes__color__item__type"
                                          onClick={() =>
                                            attributeValueHandler(
                                              attribute.attribute.ra_id,
                                              color.marav_id
                                            )
                                          }
                                        >
                                          {typeof attributesValuesChecks[
                                            "attribute" +
                                              attribute.attribute.ra_id
                                          ] !== "undefined" &&
                                            typeof attributesValuesChecks[
                                              "attribute" +
                                                attribute.attribute.ra_id
                                            ]["value" + color.marav_id] !==
                                              "undefined" &&
                                            attributesValuesChecks[
                                              "attribute" +
                                                attribute.attribute.ra_id
                                            ]["value" + color.marav_id] ==
                                              true && (
                                              <div className="my_reward_list__card_content__attributes__color__item__type__checked">
                                                <img src={check_black} alt="" />
                                              </div>
                                            )}
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor:
                                              color.marava_value_name,
                                          }}
                                          className="my_reward_list__card_content__attributes__color__item__type"
                                          onClick={() =>
                                            attributeValueHandler(
                                              attribute.attribute.ra_id,
                                              color.marav_id
                                            )
                                          }
                                        >
                                          {typeof attributesValuesChecks[
                                            "attribute" +
                                              attribute.attribute.ra_id
                                          ] !== "undefined" &&
                                            typeof attributesValuesChecks[
                                              "attribute" +
                                                attribute.attribute.ra_id
                                            ]["value" + color.marav_id] !==
                                              "undefined" &&
                                            attributesValuesChecks[
                                              "attribute" +
                                                attribute.attribute.ra_id
                                            ]["value" + color.marav_id] ==
                                              true && (
                                              <div className="my_reward_list__card_content__attributes__color__item__type__checked">
                                                <img src={check_icon} alt="" />
                                              </div>
                                            )}
                                        </div>
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          )}
                          {attribute.attribute.ra_name != "Color" && (
                            <div className="my_reward_list__card_content__attributes__value">
                              <IonSelect
                                className="form-group__input-styles my_reward_list__card_content__attributes__select"
                                placeholder="--Select--"
                                interfaceOptions={{
                                  header: "Select " + attribute.attribute.ra_name,
                                }}
                                onIonChange={(e: any) => {
                                  attributeValueHandler(
                                    attribute.attribute.ra_id,
                                    e.detail.value
                                  );
                                }}
                              >
                                {attribute?.values &&
                                  attribute.values.map(
                                    (value: any, index: number) => (
                                      <IonSelectOption value={value.marav_id}>
                                        {value.marava_value_name}
                                      </IonSelectOption>
                                    )
                                  )}
                              </IonSelect>
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {viewOnly && (
                <>
                  <div className="redeem_reward__email">
                    <IonLabel>Description</IonLabel>
                    <IonText>{targetReward.mar_subtitle}</IonText>
                  </div>
                  <div className="redeem_reward__email">
                    <IonLabel>MSRP</IonLabel>
                    <IonText>{targetReward.mar_msrp}</IonText>
                  </div>
                </>
              )}

              {(!viewOnly || (viewOnly && reedemable)) && (
                <>
                  <div className="redeem_reward__email">
                    <IonLabel>Email address</IonLabel>
                    <IonText>{talentEmail}</IonText>
                  </div>
                  <div className="redeem_reward__address">
                    <IonLabel>Shipping address</IonLabel>
                    <IonText>
                      {talentName && (
                        <>
                          {talentName}
                          <br />
                        </>
                      )}
                      {talentFullAdress.address}
                      {talentFullAdress.appartment
                        ? ", Apt " + talentFullAdress.appartment
                        : ""}{" "}
                      <br /> {talentFullAdress.city} {talentFullAdress.state},{" "}
                      {talentFullAdress.zipcode}
                    </IonText>
                  </div>
                  <div className="redeem_reward__edit">
                    <IonButton
                      fill="clear"
                      onClick={() => {
                        setEditTalentInfo("edit_modal");
                      }}
                    >
                      Edit Info <img src={edit_icon} alt="" />
                    </IonButton>
                  </div>
                </>
              )}

              <IonButton
                expand="block"
                className="redeem_reward__submit"
                onClick={claimModelRewardHandler}
                disabled={disabledSubmitBtn || (viewOnly && !reedemable)}
              >
                Submit
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      )}
      {editTalentInfo === "edit_modal" && (
        <IonModal isOpen={editTalentInfo === "edit_modal"} mode="md">
          <IonHeader>
            <IonToolbar mode="ios" color="primary">
              <IonTitle>Edit Info</IonTitle>
              <IonButton
                onClick={() => {
                  setEditTalentInfo("save_modal");
                }}
              >
                <img src={white_back_arrowa} alt="" />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="edit_info__container">
              <form onSubmit={handleSubmit(submitEditInfoForm)}>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Email address
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="Doe@gmail.com"
                    className={
                      errors.talent_email !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("talent_email", {
                      required: "This field is required.",
                      pattern: {
                        value:
                          /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
                        message: "Invalid email address",
                      },
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="talent_email"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Address</IonLabel>
                  <input
                    type="text"
                    placeholder="Street number & name"
                    className={
                      errors.address !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("address", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="address"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Apartment Number
                    <span className="optional-text"> (Optional)</span>
                  </IonLabel>
                  <input
                    type="text"
                    maxLength={20}
                    placeholder="Eg. ERT232"
                    className="form-group__input-styles"
                    {...register("appartment_no")}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="appartment_no"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">City</IonLabel>
                  <input
                    type="text"
                    placeholder="Eg. Los Angeles"
                    className={
                      errors.city !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("city", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Zip Code</IonLabel>
                  <input
                    type="tel"
                    placeholder="Eg. 83971"
                    className={
                      errors.zipcode !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("zipcode", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Country</IonLabel>
                  <IonSelect
                    onIonChange={(e: any) =>
                      getStateListBaseOnCountry(e.detail.value)
                    }
                    placeholder="--Select--"
                    className={
                      errors.country !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("country", {
                      required: "This field is required.",
                    })}
                  >
                    <IonSelectOption key="USA" value="USA">
                      USA
                    </IonSelectOption>
                    <IonSelectOption key="Canada" value="Canada">
                      Canada
                    </IonSelectOption>
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="country"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">State</IonLabel>
                  <IonSelect
                    placeholder="--Select--"
                    className={
                      errors.state !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("state", {
                      required: "This field is required.",
                    })}
                  >
                    {Object.keys(signupStateList).map(
                      (state_key, state_index) => (
                        <IonSelectOption key={state_key} value={state_key}>
                          {signupStateList[state_key]}
                        </IonSelectOption>
                      )
                    )}
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="state"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="edit_info__container__btn">
                  <IonButton
                    expand="block"
                    mode="ios"
                    type="submit"
                    className="form-group__button"
                    disabled={disabledSubmitBtn}
                  >
                    Save
                  </IonButton>
                </div>
              </form>
            </div>
          </IonContent>
        </IonModal>
      )}
    </>
  );
};

export default RedeemRewardModal;

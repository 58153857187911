import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
  IonButton,
  IonInput,
  IonLabel,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCheck } from "@fortawesome/free-solid-svg-icons";
import { authActions } from "../../../store/auth";
import LoginWrapper from "../LoginWrapper/LoginWrapper";
import {
  setTalnetInfoInReduxStore,
  showToastMessage,
} from "../../../common/common";
import "./Login.css";
import { invalidateOppsCache } from "../../../data-services/opportunities";
import AppUpdatePopover from "../../../components/AppUpdatePopover/AppUpdatePopover";
import { initializeApp } from "firebase/app";
import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
  getDocs,
  Timestamp,
  doc,
  setDoc,
  addDoc,
  orderBy,
  OrderByDirection,
  updateDoc,
} from "firebase/firestore";

import {
  getPoliciesContent,
} from "../../../data-services/signup";
import PolicyModal from "../policyModal/policyModal";
import { Network } from "@capacitor/network";
import { CapacitorHttp } from '@capacitor/core';
import { noInternetResponse, isNewAppVersion } from "../../../common/common";
import { NativeBiometric, BiometryType } from "capacitor-native-biometric";
import { getDownloadNewAppImage } from "../../../data-services/home-api";
import { get } from "../../../storage/storage";

type inputs = {
  accessedRoute?: any;
};

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
const checkmark = <FontAwesomeIcon icon={faCheck} />;

const Login: React.FC<inputs> = (props) => {
  
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const accessRouteFromLanding = urlParams.get('accessedRouteLanding');
  const { accessedRoute } = props;

  // Accessed route is set as a prop when you are logged in and as a GET param when you are not
  let accessRouteFwd = (accessRouteFromLanding != 'undefined' && accessRouteFromLanding != '%2F' && accessRouteFromLanding != '/') ? accessRouteFromLanding : accessedRoute;

  const history = useHistory();
  const dispatch = useDispatch();
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [showNewAppVersionPopover, setShowNewAppVersionPopover] =
    useState(false);
  const[newAppVersionPopoverImage, setNewAppVersionPopoverImage] =
    useState("");
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "onBlur",
  });

  const [wrong_credentials, set_wrong_credentials] = useState<string>("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [showCheck, setshowCheck] = useState(false);
  const [msgOnetimePasscode, setMsgOnetimePasscode] = useState<string>("");
  const [otpTriggered, setOtpTriggered] = useState(false);
  const [disabledOnetimePasscodeBtn, setDisabledOnetimePasscodeBtn] =
    useState<boolean>(false);
  const [ltoken, setLtoken] = useState(urlParams.get('ltoken'));
  // terms and privacy policy modal state
  const [showPolicyModal, setShowPolicyModal] = useState<boolean>(false);
  const [policiesContent, setPoliciesContent] = useState<any>("");
  const [policiesTitle, setPoliciesTitle] = useState<string>("");
  // Saved in Apple keychain or Android keystore
  const BIOMETRICS_SAVE_DOMAIN = 'app.pushmodels.com';

  const saveLoginInfo = async (loginUsername: any, loginPassword: any) => {
    // If one time passcode has been triggered, do not save the otp credentials because they 
    // will not work a second time.
    if(otpTriggered) {
      return;
    }

    if (process.env?.REACT_APP_IS_NATIVE) {
      const biometricsEnabled = await NativeBiometric.isAvailable();
  
      if(!biometricsEnabled.isAvailable) return;

      // Save user's credentials
      NativeBiometric.setCredentials({
        username: loginUsername,
        password: loginPassword,
        server: BIOMETRICS_SAVE_DOMAIN,
      }).then();
    }
  }

  const deleteLoginInfo = async () => {
    if (process.env?.REACT_APP_IS_NATIVE) {
      const biometricsEnabled = await NativeBiometric.isAvailable();
  
      if(!biometricsEnabled.isAvailable) return;
      
      // Delete user's credentials
      NativeBiometric.deleteCredentials({
        server: BIOMETRICS_SAVE_DOMAIN,
      }).then();
    }
  }

  const userLoginSubmit = async (formData:any) => {

    setDisabledSubmitBtn(true);
    const loginAdmintoken = formData?.ltoken?.trim();
    const loginUsername = formData?.username?.trim();
    const loginPassword = formData?.password?.trim();
    const postParam = loginAdmintoken ? `{token:"${loginAdmintoken}"}` : `{username:"${loginUsername}",password:"${loginPassword}"}`;
    let networkStatus = (await Network.getStatus()).connectionType;
    
    if (networkStatus !== "none") {
      const config: any = {
        method: "post",
        url: process.env.REACT_APP_HP_API_URL + "/users/login",
        headers: {
          "Content-Type": "text/plain",
        },
        data: postParam,
      };

      axios(config)
        .then(async function (response) {
          if (response?.data?.success === true) {
            await invalidateOppsCache();
            saveLoginInfo(loginUsername, loginPassword);
            
            let user_details = response.data.data;
            let dispatchObj = await setTalnetInfoInReduxStore(user_details);
            dispatch(authActions.login(dispatchObj));

            if(accessRouteFwd){
              window.location.href=accessRouteFwd;
            }
          }
        })
        .catch(function (error) {
          console.log("login exception: ", error);
          let errMessage;
          // Check if API provides a relevant error message
          if (error?.response?.data?.message?.length) {
            errMessage = error.response.data.message;
          } else {
            // Otherwise use the Exception error message
            errMessage =
              error?.message?.length > 0
                ? "Error: " + error.message
                : "Something went wrong.";
          }

          set_wrong_credentials(errMessage);
          deleteLoginInfo();

        });
    } else {
      showToastMessage(noInternetResponse.message);
    }
    setDisabledSubmitBtn(false);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const oneTimePasscodeLogin = async () => {
    setOtpTriggered(true);
    setDisabledOnetimePasscodeBtn(true);
    if (getValues("username") === "") {
      setError("username", {
        type: "manual",
        message: "This field is required",
      });
      showToastMessage("Email or Phone Number is required!");
      setDisabledOnetimePasscodeBtn(false);
      return;
    }
    let networkStatus = (await Network.getStatus()).connectionType;
    if (networkStatus !== "none") {
      let config: any = {
        method: "post",
        url:
          process.env.REACT_APP_HP_API_URL +
          "/users/generateOneTimeLoginPasscode",
        headers: {
          "Content-Type": "text/plain",
        },
        data: { username: getValues("username").trim() },
      };
      let one_time_passcode = await axios(config);
      setMsgOnetimePasscode(one_time_passcode?.data?.message);
    } else {
      showToastMessage(noInternetResponse.message);
    }
    setDisabledOnetimePasscodeBtn(false);
  };

  const performBiometricVerification = async () => {

    // Somehow this is still triggered after a deep link redirect with window.location.href.
    // Because redux is not loaded immediately after the route change with window.location.href.
    if (await get("login_user_token")) {
      return;
    }

    if (!process.env?.REACT_APP_IS_NATIVE) {
      return;
    }
    const result = await NativeBiometric.isAvailable();
  
    if(!result.isAvailable) return;
  
    const isFaceID = result.biometryType == BiometryType.FACE_ID;
  
    const verified = await NativeBiometric.verifyIdentity({
      reason: "For easy log in",
      title: "Log in",
      subtitle: "",
      description: "",
    })
      .then(() => true)
      .catch(() => false);
  
    if(!verified) return;
  
    const credentials = await NativeBiometric.getCredentials({
      server: BIOMETRICS_SAVE_DOMAIN,
    });

    if (credentials?.username && credentials?.password) {
      const inputUsername = document.querySelector("#login-username");
      if (inputUsername instanceof HTMLInputElement) {
        inputUsername.value = credentials.username;
      }

      const inputPassword = document.querySelector("#login-password");
      if (inputPassword instanceof HTMLInputElement) {
        inputPassword.value = credentials.password;
      }

      userLoginSubmit({
        username: credentials.username, 
        password: credentials.password
      });
    }
  }

  const closePolicyModal = () => {
    setShowPolicyModal(false);
  };
  const getPoliciesAndTerms = async (
    policy_type: string,
    policy_title: string
  ) => {
    let policy_api = await getPoliciesContent(policy_type);
    if (policy_api?.success) {
      setShowPolicyModal(true);
      setPoliciesTitle(policy_title);
      setPoliciesContent(policy_api.data);
    } else {
      if (policy_api?.data?.errorCode == 0) {
        showToastMessage(policy_api?.data?.message);
      }
    }
  };

  useEffect(() => {

    performBiometricVerification();

    if(urlParams.get('ltoken')) {
      userLoginSubmit({
        ltoken: ltoken
      });
      // Stop here
      return;
    }

    (async () => {
      const newAppVersion = await isNewAppVersion();
      setShowNewAppVersionPopover(newAppVersion);
      if(newAppVersion) {
        let downloadNewAppImage = await getDownloadNewAppImage();
        if (downloadNewAppImage?.success) {
          setNewAppVersionPopoverImage(downloadNewAppImage?.data?.image);
        }
      }
      
    })();
  }, [msgOnetimePasscode, disabledOnetimePasscodeBtn]);
  return (
    <LoginWrapper>
      <div className="login" style={{width: '100%'}}>
        <form onSubmit={handleSubmit(userLoginSubmit)}>
          <section className="login__section">
            <div className="login-headings">
              <h1 className="login-headings__login-header">Welcome back</h1>
              <div className="login-headings__login-text">
                Please login to continue.
              </div>
            </div>
            <div className="login__section__inputs">
              <div className="form-group">
                <IonLabel className="form-group__label">Username</IonLabel>
                <input
                  id="login-username"
                  {...register("username", {
                    required: "This field is required.",
                  })}
                  className={
                    errors.username !== undefined
                      ? "login-input-styles-error"
                      : "login-input-styles"
                  }
                  type="text"
                  placeholder="Email or Phone Number"
                />
                <i className="check-icon">{showCheck ? checkmark : ""}</i>
                <ErrorMessage
                  errors={errors}
                  name="username"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label">Password</IonLabel>
                <input
                  id="login-password"
                  {...register("password", {
                    required: "This field is required.",
                  })}
                  className={
                    errors.password !== undefined
                      ? "login-input-styles-error"
                      : "login-input-styles"
                  }
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter a password"
                />
                <i
                  className="create-password__eye-icon"
                  onClick={togglePasswordVisiblity}
                >
                  {passwordShown ? eyeSlash : eye}
                </i>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  as={<div className="error-message" />}
                />
              </div>
              {wrong_credentials && (
                <div className="error-message">{wrong_credentials}</div>
              )}
            </div>
            <div className="login__section__other_links">
              <IonRouterLink
                routerLink="/Forgot"
                className="other_links_align_left"
              >
                <IonText className="login__lost-pass-link ion-padding-start">
                  Forgot Password ?
                </IonText>
              </IonRouterLink>
              <IonRouterLink
                onClick={oneTimePasscodeLogin}
                className={
                  disabledOnetimePasscodeBtn
                    ? "other_links__disabled other_links_align_right"
                    : "other_links_align_right"
                }
              >
                <IonText className="login__lost-pass-link ion-padding-start">
                  One-time passcode
                </IonText>
              </IonRouterLink>
            </div>
            <div className="form-group">
              <IonButton
                className="form-group__button"
                mode="md"
                expand="block"
                type="submit"
                disabled={disabledSubmitBtn}
              >
                Log In
              </IonButton>
            </div>

            <div className="one_time_passcode_msg">{msgOnetimePasscode}</div>
          </section>
        </form>
      </div>
      <div className="login__policy-section">
        <IonLabel>
            By continuing you agree to PUSH's &nbsp;
            <a
              onClick={() =>
                getPoliciesAndTerms(
                  "getModelTermsOfUse",
                  "Terms of Use"
                )
              }
            >
              Terms of Use
            </a>
            ,&nbsp;
            <a
              onClick={() =>
                getPoliciesAndTerms(
                  "getPrivacyPolicy",
                  "Privacy Policy"
                )
              }
            >
              Privacy Policy
            </a>{" "}
            and &nbsp;
            <a
              onClick={() =>
                getPoliciesAndTerms(
                  "getAcceptableUsePolicy",
                  "Acceptable Use Policy"
                )
              }
            >
              Acceptable Use Policy
            </a>
            .
        </IonLabel>
      </div>
      <PolicyModal
        title={policiesTitle}
        heading={`heading`}
        description={policiesContent}
        isOpen={showPolicyModal}
        closeAction={closePolicyModal}
      />
      {newAppVersionPopoverImage && (<AppUpdatePopover
            showPopover={showNewAppVersionPopover}
            setShowPopover={setShowNewAppVersionPopover}
            newAppVersionPopoverImage = {newAppVersionPopoverImage}
          />)}
    </LoginWrapper>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  IonProgressBar,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import "./AssignmentRecap.css";
import "./RecapReceiptFileUpload.css";
import back_arrow_white from "../../assets/images/common/arrow-left-white.png";
import FileUploaderAddmore from "../../push-components/ProfileFileuploader/FileUploaderAddMore.js";
import { saveModelUploadedImages, logError } from "../../data-services/assignmets";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
  somethingWentWrongErrMsg,
  PHOTOS_ONLY_RECAP_ID,
} from "../../common/common";

type ModalProps = {
  recap_receipt_show: boolean;
  recapReceiptClosed: Function;
  recap_receipt_ue_id: string;
  recap_receipt_ue_name: string;
  recap_receipt_ue_status: string;
  recap_receipt_ue_customer_name: string;
  recap_receipt_ue_customer_image: string;
  recapEventData: any;
  recap_receipt_ue_rf_id?: any;
};

const AssignmentRecapReceipts: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    recap_receipt_show,
    recapReceiptClosed,
    recap_receipt_ue_id,
    recap_receipt_ue_name,
    recap_receipt_ue_status,
    recap_receipt_ue_customer_name,
    recap_receipt_ue_customer_image,
    recapEventData,
    recap_receipt_ue_rf_id,
  } = props;
  const [showAlertUpload, setShowAlertUpload] = useState<boolean>(false);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [formInputError, setFormInputError] = useState<{
    amount: boolean;
    type: boolean;
    image: boolean;
  }>({
    amount: false,
    type: false,
    image: false,
  });

  const [uploadProgress, setUploadProgress] = useState(0);

  const submitReceiptFormHandler = async (event: any) => {
    event.preventDefault();

    let receipt_form = new FormData(event.target);
    receipt_form.append("ue_id", recap_receipt_ue_id);
    let receipt_file: any = receipt_form.get("files");
    let receipt_type: any = receipt_form.get("receipt_type");
    let receipt_amount: any = receipt_form.get("receipt_amount");
    receipt_form.append("isReceipt", "1");

    if (receipt_amount === "") {
      setFormInputError({ amount: true, type: false, image: false });
      return;
    }
    if (receipt_type === "") {
      setFormInputError({ amount: false, type: true, image: false });
      return;
    }
    if (receipt_file.name === "") {
      setFormInputError({ amount: false, type: false, image: true });
      return;
    }
    setDisabledSubmitBtn(true);
    try {
      let api_res: any = await saveModelUploadedImages(
        receipt_form,
        handleProgress
      );
      if (api_res?.success) {
        let reset_form: any = document.querySelector(".assign-recap__form");
        if (reset_form) {
          reset_form.reset();
        }
        setShowAlertUpload(true);
      } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignments Recap Receipts - submitReceiptFormHandler - Exception thrown",
          "Talent App - Assignments Recap Receipts",
          JSON.stringify(e.message)
        );
      }
      setDisabledSubmitBtn(false);
    }
    setDisabledSubmitBtn(false);
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  useEffect(() => {}, [formInputError]);
  return (
    <IonModal isOpen={recap_receipt_show} mode="ios">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Recap Receipts</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => recapReceiptClosed(false)}>
              <img src={back_arrow_white} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="assign-recap-receipt-container">
          <form
            onSubmit={submitReceiptFormHandler}
            className="assign-recap__form"
          >
            <div className="recap__welcome-text">
              <IonText>
                Thank you for participating in
                <span> {recap_receipt_ue_name}</span>. Please upload your
                receipts, then press 'Submit'.
              </IonText>
            </div>
            <IonCard className="recap__event__details">
              <IonBadge>
                <span style={{ backgroundColor: "green" }}></span>{" "}
                {recap_receipt_ue_status}
              </IonBadge>
              <h2>{recap_receipt_ue_name}</h2>
              <IonItem className="assignment-view__event__status">
                <img
                  slot="start"
                  src={decodeURIComponent(recap_receipt_ue_customer_image)}
                  alt=""
                />
                <IonText slot="start">{recap_receipt_ue_customer_name}</IonText>
              </IonItem>
            </IonCard>
            <h2 className="assign-recap-content__title">Receipts</h2>

            <div className="form-group">
              <IonLabel className="form-group__label">Total Amount</IonLabel>
              <div className="assign-recap__input_label">USD ($)</div>
              <input
                type="text"
                placeholder="00.0"
                className={
                  formInputError.amount
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                name="receipt_amount"
              ></input>
              {formInputError.amount && (
                <div className="error-message">This fiels is required</div>
              )}
            </div>
            <div className="form-group">
              <IonLabel className="form-group__label">Receipt Type</IonLabel>
              <IonSelect
                placeholder="--Select--"
                className={
                  formInputError.type
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                name="receipt_type"
              >
                {recapEventData?.receipts?.map((type: any, index: number) => (
                  <IonSelectOption key={index} value={type.option_id}>
                    {type.option_name}
                  </IonSelectOption>
                ))}
              </IonSelect>
              {formInputError.type && (
                <div className="error-message">This fiels is required</div>
              )}
            </div>
            <div className="form-group upload-receipt">
              <IonLabel className="form-group__label">
                Receipt Verification
              </IonLabel>
              <IonText className="upload-receipt-text">
                Upload your receipt as an image here
              </IonText>
            </div>
            {formInputError.image && (
              <div className="upload-receipt__error-message error-message">
                Receipt Photo required
              </div>
            )}
            <div className="assign-recap-receipt__upload-btn">
              <FileUploaderAddmore limit="1" name="files" />
            </div>
            {disabledSubmitBtn && (
              <IonProgressBar value={uploadProgress / 100} />
            )}
            <div className="assign-recap-container__btn">
              <IonButton
                type="submit"
                className="form-group__button"
                expand="block"
                disabled={disabledSubmitBtn}
              >
                Submit
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
      <IonAlert
        isOpen={showAlertUpload}
        // mode="ios"
        onDidDismiss={() => setShowAlertUpload(false)}
        header={"Receipt Saved!"}
        message={"Do you want to upload another receipt?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              recapReceiptClosed();
            },
          },
          {
            text: "Yes",
            handler: () => {},
          },
        ]}
      />
    </IonModal>
  );
};

export default AssignmentRecapReceipts;

/** */
import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonText,
  IonCardTitle,
  IonButton,
  IonAlert,
  IonBadge,
} from "@ionic/react";
import "./ItemCard.css";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import AssignmentDecline from "../../pages/AssignmentView/AssignmentDecline/AssignmentDecline";
import AssignmentConfirm from "../../pages/AssignmentView/AssignmentConfirm/AssignmentConfirm";
import { useHistory } from "react-router";
import {
  getUserCurrentPosition,
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
} from "../../common/common";
import AssignmentCheckin from "../../pages/AssignmentView/AssignmentCheckin/AssignmentCheckin";
import {
  modelResumeWork,
  modelTakeBreak,
  talentEventCheckout,
  saveModelDC,
} from "../../data-services/assignmets";
import moment from "moment";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import pointsMultiplied from "../../assets/images/opportunity/3fcd9cf2361f5ffd0e5851b19721d403.gif";
import instantBookIcon from "../../assets/images/opportunity/lightning_bolt.png";

const ItemCard: React.FC<{
  title: string;
  items: any;
  emptyString: string;
  reinit: Function;
  openOppViewModal?: (event_id: any) => any;
  additional_opps_widget?: boolean;
}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    title,
    items,
    emptyString,
    reinit,
    openOppViewModal,
    additional_opps_widget,
  } = props;
  const [itemCardSliderConfig, setItemCardSliderConfig] = useState<any>({
    fixedWidth: "264px",
    arrows: false,
    pagination: false,
    perPage: 1,
    gap: "16px",
    perMove: 1,
  });
  const [isOpenAssignDecline, setIsOpenAssignDecline] = useState<{
    event_name: string;
    event_start_date: any;
    is_open: boolean;
    event_id: any;
  }>({ event_name: "", event_start_date: "", is_open: false, event_id: "" });
  const [isOpenAssignConfirm, setIsOpenAssignConfirm] = useState<{
    event_name: string;
    start_date: string;
    start_time: string;
    talent_role: string;
    event_id: string;
    is_open: boolean;
    assign_p_card_required: boolean;
    assign_p_card_instructions: string;
    backupTypeInt: any;
    backupInstructions: any;
    backupType: any;
    autoBookEnabled: any;
    requiredCustomerAgreementText: string;
  }>({
    event_name: "",
    start_date: "",
    start_time: "",
    talent_role: "",
    event_id: "",
    is_open: false,
    assign_p_card_required: false,
    assign_p_card_instructions: "",
    backupTypeInt: 1,
    backupInstructions: "",
    backupType: "",
    autoBookEnabled: false,
    requiredCustomerAgreementText: ""
  });
  const [enabledChekinBtn, setEnabledChekinBtn] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: "", longitude: "" });
  const [isOpenCheckin, setIsOpenCheckin] = useState<{
    is_open_checkin: boolean;
    event_id: string;
  }>({ is_open_checkin: false, event_id: "" });
  const [checkinConfirmed, setCheckinConfirmed] = useState<{
    show: boolean;
    message: string;
  }>({ show: false, message: "" });
  const closeAssignDecline = () => {
    setIsOpenAssignDecline({
      event_name: "",
      event_start_date: "",
      is_open: false,
      event_id: "",
    });
  };
  const closeAssignConfirm = async (
    param: string = "",
    event_name: string = "",
    event_id: any = "",
    event_start_date: any = ""
  ) => {
    if (param === "decline") {
      setIsOpenAssignConfirm({
        event_name: "",
        start_date: "",
        start_time: "",
        talent_role: "",
        event_id: "",
        is_open: false,
        assign_p_card_required: false,
        assign_p_card_instructions: "",
        backupTypeInt: 1,
        backupType: "",
        backupInstructions: "",
        autoBookEnabled: false,
        requiredCustomerAgreementText: ""
      });
      setIsOpenAssignDecline({
        event_name: event_name,
        event_start_date: event_start_date,
        is_open: true,
        event_id: event_id,
      });
    } else {
      setIsOpenAssignConfirm({
        event_name: "",
        start_date: "",
        start_time: "",
        talent_role: "",
        event_id: "",
        is_open: false,
        assign_p_card_required: false,
        assign_p_card_instructions: "",
        backupTypeInt: 1,
        backupType: "",
        backupInstructions: "",
        autoBookEnabled: false,
        requiredCustomerAgreementText: ""
      });
      await reinit();
    }
  };
  const checkInHandler = async (event_id: string) => {
    setEnabledChekinBtn(true); // disabled checkin button till getUserCurrentPosition() response
    let { latitude, longitude }: any = await getUserCurrentPosition();
    if (latitude && longitude) {
      setCoordinates({ latitude: latitude, longitude: longitude });
      setIsOpenCheckin({ is_open_checkin: true, event_id: event_id });
    }
    setEnabledChekinBtn(false);
  };
  const closeCheckin = async (success_msg: any = "") => {
    setIsOpenCheckin({ is_open_checkin: false, event_id: "" });
    await reinit();
    if (success_msg) {
      setCheckinConfirmed({ show: true, message: success_msg.message });
    }
  };
  const checkhOutHandler = async (fore_redirect: string, event_id: string) => {
    setEnabledChekinBtn(true);
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let break_api = await talentEventCheckout({
      checkInUeId: event_id,
      latitude,
      longitude,
    });
    if (break_api?.success) {
      if (fore_redirect == "1") {
        history.push(`/AssignmentRecap/${event_id}`);
      }
    } else {
      if (break_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (break_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(break_api?.message);
      } else if (break_api?.message) {
        await showToastMessage(break_api.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await reinit();
    setEnabledChekinBtn(false);
  };
  const takeBreackHandler = async (event_id: string) => {
    setEnabledChekinBtn(true);
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let break_api = await modelTakeBreak({
      checkInUeId: event_id,
      latitude,
      longitude,
    });
    if (break_api?.success) {
      await showToastMessage(break_api.data.message);
    } else {
      if (break_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (break_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(break_api?.message);
      } else if (break_api?.message) {
        await showToastMessage(break_api.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await reinit();
    setEnabledChekinBtn(false);
  };
  const resumeWorkHandler = async (event_id: string) => {
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let resume_api = await modelResumeWork({
      checkInUeId: event_id,
      latitude,
      longitude,
    });
    if (resume_api?.success) {
      await showToastMessage(resume_api.data.message);
    } else {
      if (resume_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (resume_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(resume_api?.message);
      } else if (resume_api?.message) {
        await showToastMessage(resume_api.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await reinit();
    setEnabledChekinBtn(false);
  };
  const gotoEventDetail = (link: string, event_id: any) => {
    if (openOppViewModal) {
      openOppViewModal(event_id);
    } else {
      history.replace(link);
    }
  };

  const takeGradeTest = async (event_id: string) => {
    let status_type = 1; // pass one for Grade test
    history.push(`/TakeTest/${event_id}/${status_type}`);
  };

  /**
   * Confirm Model DBC/ DOC
   * @param event_id
   * @param type
   * @returns
   */
  const submitDC = async (event_id: string, type: string) => {
    setEnabledChekinBtn(true);
    let dc_api = await saveModelDC(event_id, type);
    if (dc_api?.success) {
      await showToastMessage(dc_api?.message);
    } else {
      if (dc_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (dc_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(dc_api?.message);
      } else if (dc_api?.message) {
        await showToastMessage(dc_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await reinit();
    setEnabledChekinBtn(false);
  };

  return (
    <div>
      {items.length > 0 && (
        <div className="slider_view">
          <Splide options={itemCardSliderConfig}>
            {items.map((item: any, index: any) => (
              <SplideSlide key={index}>
                <IonCard className="slider_view__card">
                  <div className="event-list__event__card__img">
                    <img
                      alt=""
                      src={item.image}
                      className="slider_view__image"
                      onClick={() =>
                        gotoEventDetail(item.detail_view_link, item.event_id)
                      }
                    />
                    <div className="event-list__event_badges">
                      {item?.autoBookEnabled && (
                        <IonBadge className="auto-book-placeholder">
                          <img src={instantBookIcon} /> <span>Instant Book</span>
                        </IonBadge>
                      )}
                      {additional_opps_widget && (
                        <>
                          {item?.card_miles_badge && (
                            <IonBadge className="event-list__event__card__mile">
                              {item?.card_miles_badge}
                            </IonBadge>
                          )}
                          {item?.card_waitlist_only && (
                            <IonBadge className="event-list__event__card__mile">
                              {item?.card_waitlist_only}
                            </IonBadge>
                          )}
                        </>
                      )}
                    </div>
                    {item?.backupType && (
                      <IonBadge className="slider_view__card__toolbar-bottom">
                        <span className="slider_view__card__toolbar-bottom__label">
                          {item?.backupType}
                        </span>
                      </IonBadge>
                    )}
                  </div>
                  <IonCardContent className="slider_view__image__content">
                    <IonText className="slider_view__date">{item.hint}</IonText>
                    {item?.ue_worked_hours_points_multiplier &&
                      item?.ue_worked_hours_points_multiplier > 1 && (
                        <>
                          <IonText className="slider_view__points">
                            {item.ue_worked_hours_points_multiplier}X Points
                            <img
                              className="slider_view__points__img"
                              src={pointsMultiplied}
                            />
                          </IonText>
                        </>
                      )}
                    <div>
                      <IonCardTitle
                        className="slider_view__title"
                        onClick={() =>
                          gotoEventDetail(item.detail_view_link, item.event_id)
                        }
                      >
                        {item.title}
                      </IonCardTitle>
                      {!(
                        item.showConfirmation ||
                        item.canModelCheckIn ||
                        item.canModelCheckOut ||
                        item.canresumework ||
                        item.hasToTakeTests ||
                        item.showDBC ||
                        item.showDOC ||
                        item.fillRecapCTA == "1"
                      ) && (
                        <div>
                          <p className="slider_view__address">{item.address}</p>
                        </div>
                      )}

                      {(item.showConfirmation || item.hasToTakeTests) && (
                        <div>
                          <p className="slider_view__cta">
                            Please respond ASAP to confirm this booking
                          </p>
                        </div>
                      )}

                      {item.showDOC && (
                        <div>
                          <p className="slider_view__cta slider_view__cta--lesser-line-height">
                            Please confirm that you are all set for today's
                            shift.
                          </p>
                        </div>
                      )}

                      {item.showDBC && (
                        <div>
                          <p className="slider_view__cta slider_view__cta--lesser-line-height">
                            Please confirm that you are 100% ready to go for
                            tomorrow's assignment.
                          </p>
                        </div>
                      )}

                      {item.fillRecapCTA == "1" && (
                        <div>
                          <p className="slider_view__cta slider_view__cta--lesser-line-height">
                            {item.fillRecapCTAText}
                          </p>
                        </div>
                      )}

                      {(item.canModelCheckIn || item.canresumework) &&
                        item.event_start_time &&
                        item.event_end_time && (
                          <div>
                            <p className="slider_view__cta slider_view__cta--lesser-line-height">
                              {item.ue_is_in_store_report ? (
                                <>
                                  Please check-in when you arrive on-site to
                                  begin your on-site Report.
                                </>
                              ) : (
                                <>
                                  Shift Time:{" "}
                                  {moment(
                                    item.event_start_time,
                                    "HH:mm:ss A"
                                  ).format("LT")}{" "}
                                  -{" "}
                                  {moment(
                                    item.event_end_time,
                                    "HH:mm:ss A"
                                  ).format("LT")}
                                </>
                              )}
                            </p>
                          </div>
                        )}
                    </div>
                    {item.showConfirmation && (
                      <div className="assignment-view__action_btn">
                        <IonButton
                          mode="ios"
                          slot="start"
                          fill="outline"
                          size="large"
                          onClick={() =>
                            setIsOpenAssignDecline({
                              event_name: item.title,
                              event_start_date: item.event_start_date,
                              is_open: true,
                              event_id: item.event_id,
                            })
                          }
                        >
                          Decline
                        </IonButton>
                        <IonButton
                          mode="ios"
                          slot="end"
                          color="primary"
                          size="large"
                          onClick={() =>
                            setIsOpenAssignConfirm({
                              event_name: item.title,
                              start_date: item.event_start_date,
                              start_time: item.event_start_time,
                              talent_role: item.talent_role,
                              event_id: item.event_id,
                              is_open: true,
                              assign_p_card_required:
                                item?.ue_p_card_agreement_required,
                              assign_p_card_instructions:
                                item?.ue_p_card_agreement_instructions_html,
                              backupTypeInt: item?.backupTypeInt,
                              backupType: item?.backupType,
                              backupInstructions: item?.backupInstructions,
                              autoBookEnabled: item?.autoBookEnabled,
                              requiredCustomerAgreementText: item?.requiredCustomerAgreementText
                            })
                          }
                        >
                          Confirm
                        </IonButton>
                      </div>
                    )}
                    {(item.showDBC || item.showDOC) && (
                      <div className="assignment-view__action_btn">
                        <IonButton
                          mode="ios"
                          slot="start"
                          fill="outline"
                          size="large"
                          onClick={() =>
                            setIsOpenAssignDecline({
                              event_name: item.title,
                              event_start_date: item.event_start_date,
                              is_open: true,
                              event_id: item.event_id,
                            })
                          }
                        >
                          Decline
                        </IonButton>
                        <IonButton
                          mode="ios"
                          slot="end"
                          color="primary"
                          size="large"
                          disabled={enabledChekinBtn}
                          onClick={() =>
                            submitDC(
                              item.event_id,
                              item.showDBC ? "DBC" : "DOC"
                            )
                          }
                        >
                          Confirm
                        </IonButton>
                      </div>
                    )}
                    {item.show_check_in_button_only == '1' && (
                      <div className="assignment-view__action_btn">
                        <IonButton
                          mode="ios"
                          size="large"
                          onClick={() => checkInHandler(item.event_id)}
                          disabled={enabledChekinBtn}
                        >
                          Check In
                        </IonButton>
                        {/* <IonButton
                          mode="ios"
                          fill="outline"
                          size="large"
                          onClick={() =>
                            setIsOpenAssignDecline({
                              event_name: item.title,
                              is_open: true,
                            })
                          }
                        >
                          Cancel
                        </IonButton> */}
                      </div>
                    )}
                    {item.show_check_out_and_break_return_buttons == "1" && (
                      <div className="assignment-view__action_btn">
                        {item.cantakebreak && (
                          <IonButton
                            mode="ios"
                            fill="outline"
                            size="large"
                            disabled={enabledChekinBtn}
                            onClick={() => takeBreackHandler(item.event_id)}
                          >
                            Break
                          </IonButton>
                        )}
                        {item.canresumework && (
                          <IonButton
                            mode="ios"
                            fill="outline"
                            size="large"
                            disabled={enabledChekinBtn}
                            onClick={() => resumeWorkHandler(item.event_id)}
                          >
                            Return
                          </IonButton>
                        )}

                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() =>
                            checkhOutHandler(
                              item.force_recap_on_checkout,
                              item.event_id
                            )
                          }
                          disabled={enabledChekinBtn}
                        >
                          Check Out
                        </IonButton>
                      </div>
                    )}
                    {item.hasToTakeTests && (
                      <div className="assignment-view__take_test">
                        <IonButton
                          expand="block"
                          mode="ios"
                          type="button"
                          onClick={() => takeGradeTest(item.event_id)}
                        >
                          Take Test
                        </IonButton>
                      </div>
                    )}
                    {item.show_check_out_and_complete_recap_buttons == "1" && (
                      <div className="assignment-view__action_btn">
                        {item.canModelCheckOut && (
                          <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() =>
                            checkhOutHandler(
                              item.force_recap_on_checkout,
                              item.event_id
                            )
                          }
                          disabled={enabledChekinBtn}
                        >
                          Check Out
                        </IonButton>
                        )}
                        
                        
                        <IonButton
                          expand="block"
                          mode="ios"
                          type="button"
                          routerLink={`/AssignmentRecap/${item.event_id}`}
                        >
                          {+item?.ue_is_in_store_report ? (
                            <>Fill Report</>
                          ) : (
                            <>Access Recap</>
                          )}
                        </IonButton>

                        
                      </div>
                    )}
                    {item.show_complete_recap_button_only == "1" && (
                      <div className="assignment-view__take_test">
                        <IonButton
                          expand="block"
                          mode="ios"
                          type="button"
                          routerLink={`/AssignmentRecap/${item.event_id}`}
                        >
                          {+item?.ue_is_in_store_report ? (
                            <>Fill Report</>
                          ) : (
                            <>Access Recap</>
                          )}
                        </IonButton>
                      </div>
                    )}
                    {!(
                      item.showConfirmation ||
                      item.showDBC ||
                      item.showDOC ||
                      item.canModelCheckIn ||
                      item.canModelCheckOut ||
                      item.canresumework ||
                      item.hasToTakeTests ||
                      item.fillRecapCTA == "1"
                    ) && (
                      <div className="slider_view__est_payment">
                        <IonText>
                          Est. Pay: {item.payment}{" "}
                          {item?.points && (
                            <span
                              className={
                                item?.ue_worked_hours_points_multiplier &&
                                item?.ue_worked_hours_points_multiplier > 1
                                  ? "points-background"
                                  : ""
                              }
                            >
                              {item?.points}
                            </span>
                          )}
                        </IonText>
                      </div>
                    )}
                  </IonCardContent>
                </IonCard>
              </SplideSlide>
            ))}
          </Splide>
          <AssignmentDecline
            assign_event_name={isOpenAssignDecline.event_name}
            assign_event_start_date={isOpenAssignDecline.event_start_date}
            isopen_assign_decline={isOpenAssignDecline.is_open}
            assign_event_id={isOpenAssignDecline.event_id}
            closeAssignDecline={closeAssignDecline}
          />
          {isOpenAssignConfirm.is_open && (
            <AssignmentConfirm
              isopen_assign_confirm={isOpenAssignConfirm.is_open}
              closeAssignConfirm={closeAssignConfirm}
              assign_event_name={isOpenAssignConfirm.event_name}
              assign_date={isOpenAssignConfirm.start_date}
              assign_time={isOpenAssignConfirm.start_time}
              assign_talent_role={isOpenAssignConfirm.talent_role}
              assign_id={Number(isOpenAssignConfirm.event_id)}
              assign_p_card_required={
                isOpenAssignConfirm?.assign_p_card_required
              }
              assign_p_card_instructions={
                isOpenAssignConfirm?.assign_p_card_instructions
              }
              backup_type={isOpenAssignConfirm?.backupTypeInt}
              backup_type_str={isOpenAssignConfirm?.backupType}
              backup_instructions={isOpenAssignConfirm?.backupInstructions}
              auto_book_enabled={isOpenAssignConfirm?.autoBookEnabled}
              requiredCustomerAgreementText={isOpenAssignConfirm?.requiredCustomerAgreementText}
            />
          )}
          {isOpenCheckin?.is_open_checkin && (
            <AssignmentCheckin
              isopen_checkin={isOpenCheckin.is_open_checkin}
              closeCheckin={closeCheckin}
              checkin_event_id={isOpenCheckin.event_id}
              latitude={coordinates.latitude}
              longitude={coordinates.longitude}
            />
          )}
          <IonAlert
            isOpen={checkinConfirmed.show}
            mode="ios"
            onDidDismiss={() =>
              setCheckinConfirmed({ ...checkinConfirmed, show: false })
            }
            header={"Check In is successful"}
            message={checkinConfirmed.message}
            buttons={[
              {
                text: "CONTINUE",
                handler: () => {},
              },
            ]}
          />
        </div>
      )}
      {items.length === 0 && (
        <div className="slider_view__no_item_found">{emptyString}</div>
      )}
    </div>
  );
};

export default ItemCard;

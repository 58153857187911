import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonContent,
  IonPage,
  IonSpinner,
  IonModal,
} from "@ionic/react";

import { authActions } from "./../../store/auth";
import HeaderView from "../../components/HeaderView/HeaderView";
import {
  markFirstTimeLoginSlideShowAsSeen
} from "../../data-services/home-api";
import RevenueAndPoints from "./RevenueAndPoints/RevenueAndPoints";
import SetupAccount from "./SetupAccount/SetupAccount";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  noInternetResponse,
} from "../../common/common";
import "./Home.css";
import SlidingCardWidget from "../../push-components/SlidingCardWidget/SlidingCardWidget";
import MyRewardSliding from "../../push-components/MyRewardSliding/MyRewardSliding";
import FirstTimeLoginSlideshow from "../../components/FirstTimeLoginSlideshow/FirstTimeLoginSlideshow";
import { get, set } from "../../storage/storage";
import { isNewAppVersion } from "../../common/common";
import AppUpdatePopover from "../../components/AppUpdatePopover/AppUpdatePopover";
import { Network } from "@capacitor/network";
import { CapacitorHttp } from '@capacitor/core';
import { getDownloadNewAppImage } from "../../data-services/home-api";
import { getRewardInfo } from "../../data-services/scoreboard";
import RedeemRewardModal from "../MyRewards/RedeemRewardModal";
import ClaimRewardSuccessModal from "../MyRewards/ClaimRewardSuccessModal";
import { invalidateOppsCache } from "../../data-services/opportunities";
import OpportunitiesView from "../OpportunitiesView/OpportunitiesView";
import CTASlider from "./CTASlider/CTASlider";
import { PushNotifications, Token } from "@capacitor/push-notifications";

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const [talentRequiredAction, setTalentRequiredAction] = useState<any>("");
  const [refreshHome, setRefreshHome] = useState<boolean>(false);
  const [assignmentsWithCTALoaded, setAssignmentsWithCTALoaded] =
    useState<boolean>(false);
  const [homeCTALoaded, setHomeCTALoaded] =
    useState<boolean>(false);
  const [isSlideshowModalOpen, setIsSlideshowModalOpen] = useState(false);
  const [showNewAppVersionPopover, setShowNewAppVersionPopover] =
    useState(false);
  const [newAppVersionPopoverImage, setNewAppVersionPopoverImage] =
    useState("");

  const [showAccountSetup, setShowAccountSetup] = useState(false);

  const handleCloseSlideshowModal = () => {
    setIsSlideshowModalOpen(false);
  };

  const getRequiredTalentAccountActions = async () => {
    const authToken = await get("login_user_token");
    if (!authToken) {
      dispatch(authActions.logout());
      return;
    }

    let networkStatus = (await Network.getStatus()).connectionType;
    if (networkStatus !== "none") {
      let config: any = {
        url: encodeURI(
          `${process.env.REACT_APP_HP_API_URL}/userEvent/getRequiredTalentAccountActions?withTipalti=1`
        ),
        headers: {
          "Content-Type": "application/json",
          token: await get("login_user_token"),
        },
      };
      try {
        let talent_actions = await CapacitorHttp.get(config);
        if (talent_actions.status !== 200) {
          dispatch(authActions.logout());
          return;
        }

        return talent_actions?.data;
      } catch (e) {
        dispatch(authActions.logout());
        return;
      }
    } else {
      return noInternetResponse;
    }
  };

  const homeInit = async () => {
    const newAppVersion = await isNewAppVersion();

    setShowNewAppVersionPopover(newAppVersion);
    if (newAppVersion) {
      let downloadNewAppImage = await getDownloadNewAppImage();
      if (downloadNewAppImage?.success) {
        setNewAppVersionPopoverImage(downloadNewAppImage?.data?.image);
      }
    }
    setIsSlideshowModalOpen(
      !Number(await get("model_mobile_first_time_slides_shown"))
    );

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const claimReward = urlParams.get("claimReward");
    if (claimReward) {
      redeemRewardHandler(claimReward, checkedAttributesValues);
    }

    let talent_required_actions = await getRequiredTalentAccountActions();
    if (talent_required_actions?.success) {
      setTalentRequiredAction(talent_required_actions.data);
      if (
        (await get("login_model_tipalti_payment_status")) !=
        talent_required_actions.data.items.tipaltiPaymentStatus
      ) {
        invalidateOppsCache();
        await set(
          "login_model_tipalti_payment_status",
          talent_required_actions.data.items.tipaltiPaymentStatus
        );
      }

      if(talent_required_actions.data.remaining !== 0 &&
        !(
          talent_required_actions.data.remaining === 1 &&
          (talent_required_actions.data.items.tipaltiPaymentStatus == "0" ||
          talent_required_actions.data.items.tipaltiPaymentStatus == "1" ||
          talent_required_actions.data.items.tipaltiPaymentStatus == null ||
          talent_required_actions.data.items.w2form == "1" ||
          talent_required_actions.data.items.w2form == "4")
        )) {
        setShowAccountSetup(true);
        setHomeCTALoaded(true);
      } else {
        setShowAccountSetup(false);
      }
      
    } else if (
      talent_required_actions?.data?.errorCode == unauthorizedErrorCode
    ) {
      dispatch(authActions.logout());
      return;
    } else if (talent_required_actions?.data?.errorCode == offlineErrorCode) {
      showToastMessage(talent_required_actions?.data?.message);
    } else if (talent_required_actions?.message) {
      showToastMessage(talent_required_actions.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    
    markFirstTimeLoginSlideShowAsSeen();

    // Send the push notifications token just once if permission already granted
    // (e.g. when the app has been reinstalled and the token was regenerated)
    let pushTokenRetrieved = await get("pushTokenRetrieved");
    if (!pushTokenRetrieved) {
      checkPushNotifications();
      await set("pushTokenRetrieved", true);
    }
  };

  const homeRefreshOpportunitiesOnAddressChange = async () => {
    setRefreshHome(!refreshHome);
  };

  const redeemRewardHandler = async (
    redeemId: string,
    checkedAttributesValues: any,
    targetReward?: any
  ) => {
    setTalenRewardId(redeemId);
    if (!targetReward) {
      let targetRewardInfo = await getRewardInfo(redeemId);
      if (targetRewardInfo?.success) {
        targetReward = targetRewardInfo.data;
      } else {
        showToastMessage("Reward no longer available!");
        return;
      }
    }
    setTargetReward(targetReward);
    setIsEliteReward(targetReward?.elite_reward);
    checkedAttributesValues["reward" + redeemId] = {};
    if (targetReward?.attributes?.length) {
      for (const attribute of targetReward.attributes) {
        checkedAttributesValues["reward" + redeemId][
          "attribute" + attribute.attribute.ra_id
        ] = {};
        if (attribute?.values?.length) {
          for (const value of attribute.values) {
            checkedAttributesValues["reward" + redeemId][
              "attribute" + attribute.attribute.ra_id
            ]["value" + value.marav_id] = false;
          }
        }
      }
    }

    setTargetAttributesValuesChecks(
      checkedAttributesValues["reward" + redeemId]
    );
    setIsOpenRewardModal(true);
  };

  const closeRewardModal = (title: string = "", message: string = "") => {
    setIsOpenRewardModal(false);
    if (title && message) {
      setClaimRewardSuccessMsg({ title: title, message: message, show: true });
    }
  };

  const closeClaimdModal = async () => {
    setClaimRewardSuccessMsg({ title: "", message: "", show: false });
  };

  const handleOppViewDismiss = () => {
    closeOppViewModal();
  };

  const [talenRewardId, setTalenRewardId] = useState<string>("");
  const [targetReward, setTargetReward] = useState<any>("");
  const [targetAttributesValuesChecks, setTargetAttributesValuesChecks] =
    useState<any>("");
  const [isOpenRewardModal, setIsOpenRewardModal] = useState<boolean>(false);
  const [claimRewardSuccessMsg, setClaimRewardSuccessMsg] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });
  const [checkedAttributesValues, setCheckedAttributesValues] = useState<any>(
    {}
  );

  const [oppViewModalEventID, setOppViewModalEventID] = useState<any>(false);
  const [oppViewModalOpen, setOppViewModalOpen] = useState<any>(false);

  const [oppViewModalEventIDs, setOppViewModalEventIDs] = useState<any>([]);

  const [isEliteReward, setIsEliteReward] = useState<boolean>(false);

  const closeOppViewModal = () => {
    setOppViewModalOpen(false);
  };

  const openOppViewModal = (event_id: any) => {
    setOppViewModalEventID(event_id);
    let newOppViewEventIds = [...oppViewModalEventIDs];
    newOppViewEventIds.push(event_id);
    setOppViewModalEventIDs(newOppViewEventIds);
    setOppViewModalOpen(true);
  };

  const checkPushNotifications = async () => {
    if (!process.env?.REACT_APP_IS_NATIVE) {
      return;
    }

    const permissions = await PushNotifications.checkPermissions();
    if (permissions.receive === "granted") {
      // Send the notification key to the push server
      registerForPush();
      return true;
    }
    return false;
  };

  const registerForPush = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", async (token: Token) => {
      const url = `${process.env.REACT_APP_HP_API_URL}/userEvent/addFirebasePushNotificationToken`;
      const options = {
        url: encodeURI(url),
        headers: {
          "Content-Type": "application/json",
          token: await get("login_user_token"),
        },
        data: { pushNotificationToken: token?.value },
      };
      const response = await CapacitorHttp.post(options);
    });
  };


  useEffect((): any => {
    closeOppViewModal();
    let isSubscribed = true;
    (async () => {
      if (isSubscribed) {
        await homeInit();
      }
    })();
    return () => (isSubscribed = false);
  }, [refreshHome]);
  return (
    <>
      <IonPage>
        <HeaderView
          refreshOppotunities={homeRefreshOpportunitiesOnAddressChange}
        ></HeaderView>
        <IonContent class="homepage">
          {(!assignmentsWithCTALoaded) && (
            <div className="slider-card_spinner">
              <IonSpinner color="primary" name="crescent" />
            </div>
          )}

          <div className="homepage__content">
            {/* Show the Setup Payment as a separate section along with the Revenue and points if this is the only Model Account Action Left
                  Goal: Do not show the entire Setup your Account widget for existing Profiles if they have to complete the new Payment Form
                  // TODO: replace payreelStatus with the correct one
              */}
            {((assignmentsWithCTALoaded) && !showAccountSetup) && (
                <>
                  <RevenueAndPoints />
                  <CTASlider
                    talentRequiredAction={talentRequiredAction}
                    setWidgetLoaded={setHomeCTALoaded}
                  />
                </>
              )}
            
            {/* Setup your account will not display if there are not talent account action or the only talent account action is Setup Payment form
             */}
            {((assignmentsWithCTALoaded) &&
              showAccountSetup) && (
                <SetupAccount
                  talentRequiredActions={talentRequiredAction}
                  getTalentRequiredAction={homeInit}
                />
              )}
            <SlidingCardWidget
              slider_card_title={`My Assignments`}
              list_route="Assignments"
              widget_type="my_assignments_with_cta"
              setWidgetLoaded={setAssignmentsWithCTALoaded}
              no_data_text="You have no  assignments yet."
            />
            {(assignmentsWithCTALoaded) && (
              <SlidingCardWidget
                slider_card_title="Nearby Gigs"
                list_route="Opportunities"
                widget_type="nearby_opportunities"
                no_data_text="You have no gigs yet"
                refresh_sliding_card={refreshHome}
                openOppViewModal={openOppViewModal}
                additional_opps_widget={true}
              />
            )}
            {(assignmentsWithCTALoaded) && <MyRewardSliding />}
          </div>

          {newAppVersionPopoverImage && (
            <AppUpdatePopover
              showPopover={showNewAppVersionPopover}
              setShowPopover={setShowNewAppVersionPopover}
              newAppVersionPopoverImage={newAppVersionPopoverImage}
            />
          )}
          {isOpenRewardModal && (
            <RedeemRewardModal
              rewardModalType="save_modal"
              isOpenRewardModal={isOpenRewardModal}
              closeRewardModal={closeRewardModal}
              talenRewardId={talenRewardId}
              targetReward={targetReward}
              targetAttributesValuesChecks={targetAttributesValuesChecks}
              eliteReward={isEliteReward}
            />
          )}
          {claimRewardSuccessMsg.show && (
            <ClaimRewardSuccessModal
              isOpenClaimModal={claimRewardSuccessMsg.show}
              closeClaimdModal={closeClaimdModal}
              title_message={{
                title: claimRewardSuccessMsg.title,
                message: claimRewardSuccessMsg.message,
              }}
            />
          )}

          <IonModal
            isOpen={oppViewModalOpen}
            onDidDismiss={handleOppViewDismiss}
            mode="md"
          >
            <OpportunitiesView
              modal_event_id={oppViewModalEventID}
              closeOppViewModal={closeOppViewModal}
              openOppViewModal={openOppViewModal}
              oppViewModalEventIDs={oppViewModalEventIDs}
            />
          </IonModal>
        </IonContent>
      </IonPage>
    </>
  );
};
export default Home;

import React, { useState } from "react";
import { IonItem, IonRadio, IonRadioGroup, IonText } from "@ionic/react";
import "./InputTypes.css";
type componentProps = {
  question: string;
  answer?: string;
  question_id: number;
  options: string;
  questionno?: number;
  requiredmessage?: boolean;
  allowHtmlQuestion?: any;
};
const InputTypeRadio: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    allowHtmlQuestion
  } = Props;
  const [radioDefaultValue, setradioDefaultValue] = useState<string>(
    answer ? answer : ""
  );

  return (
    <div className="take-test-question take-test-question__radio">
      <div className="take-test-question__label">
          {/* {questionno && <>{questionno}. </>} */}
          {allowHtmlQuestion ? (
          <span
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          ></span>
        ) : (
          <>{question}</>
        )}
        </div>
      <IonRadioGroup
        value={radioDefaultValue}
        onIonChange={(e: any) => setradioDefaultValue(e.detail.value)}
      >
        {options &&
          options.split("||").map((data: any, index: number) => (
            <div className="take-test-question__radio__container" key={index}>
              <IonRadio
                slot="start"
                id={question_id.toString()}
                value={data}
                mode="md"
              />
              <span>{data}</span>
            </div>
          ))}
      </IonRadioGroup>
      {requiredmessage == true && (
        <div className="error-message">This field is required.</div>
      )}
      <input
        type="hidden"
        value={radioDefaultValue}
        name={"answer_" + question_id.toString()}
      />
    </div>
  );
};

export default InputTypeRadio;

/** */
import React from "react";

import { IonTitle, IonText } from "@ionic/react";
import "./Payments.css";

import empty_icon from "../../assets/images/my-revenue/icons/empty_icon.png";

const EmptyState: React.FC = () => {
  return (
    <div className="empty-state">
      <img src={empty_icon} alt="" className="empty-state__img" />
      <IonTitle className="empty-state__title">Empty State</IonTitle>
      {/* <IonText className="empty-state__text">
        Lorem ipsum dolor sit amet, consectetur
        <br />
        adipiscing elit sed do.
      </IonText> */}
    </div>
  );
};

export default EmptyState;

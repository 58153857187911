import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import { noInternetResponse } from "../common/common";
import { Network } from "@capacitor/network";

export const getAppVersion = async () => {
    let networkStatus = (await Network.getStatus()).connectionType;
    if (networkStatus !== "none") {
      let url =
        `https://app.pushmodels.com/mobileapps.php`;
  
      const options = {
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await CapacitorHttp.get(options);

      return response.data;
    } else {
      return noInternetResponse;
    }
};
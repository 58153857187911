import React, { useEffect, useState } from "react";
import { IonButton, IonInput, IonItem, IonText } from "@ionic/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./InputTypes.css";
type componentProps = {
  question: string;
  answer?: string;
  question_id: number;
  options?: string;
  questionno?: number;
  requiredmessage?: boolean;
  numberValidateMessage?: boolean;
  index?: number
  submitRecapTestAnswers?: Function
  allowHtmlQuestion?: any;
};
const InputTypeCounter: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    numberValidateMessage,
    index,
    submitRecapTestAnswers,
    allowHtmlQuestion
  } = Props;
  const [answerValue, setAnswerValue] = useState<any>(answer ? +answer : 0);
  const [disabled, setDisabled] = useState<boolean>(false);

  const updateCount = async (newValue: any) => {
    setDisabled(true);
    if (newValue >= 0) {
      setAnswerValue(newValue);
      // A better approach would be for the hidden input to update and then trigger tha submitRecapTestAnswer...
      setTimeout(async () => {
        if(typeof submitRecapTestAnswers !== "undefined") {
          try {
            let save_response = await submitRecapTestAnswers(true, false);
            setDisabled(false)
          } catch (e: any) {
            setDisabled(false);
          }
          
        }
      }, 500);
      
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <div className="take-test-question input-type-counter">
        <div className="take-test-question__label">
          {questionno && <>{questionno}. </>}
          {allowHtmlQuestion ? (
            <span
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            ></span>
          ) : (
            <>{question}</>
          )}
        </div>
        <div style={{display: 'flex', alignContent: 'center'}}>
          <IonButton
            className="input-type-counter__buttons input-type-counter__buttons--decrease"
            onClick={() => {
              updateCount(answerValue - 1);
            }}
            disabled={disabled}
          >
            -
          </IonButton>
          <span className={disabled ? "input-type-counter__value input-type-counter__value--disabled" : "input-type-counter__value"}>
            <span>{answerValue}</span>
          </span>
          <input
            type="hidden"
            placeholder=""
            readOnly={true}
            className={
              requiredmessage == true
                ? "form-input-styles-error"
                : "form-group__input-styles"
            }
            name={`counter_${index}-answer_${question_id}`}
            value={answerValue}
          ></input>
          <IonButton
            className="input-type-counter__buttons input-type-counter__buttons--increase"
            onClick={() => {
              updateCount(answerValue + 1);
            }}
            disabled={disabled}
          >
            +
          </IonButton>
        </div>
        {requiredmessage == true && (
          <div className="error-message">This field is required.</div>
        )}
        {numberValidateMessage == true && (
          <div className="error-message">
            The field should contain number only.
          </div>
        )}
      </div>
    </>
  );
};

export default InputTypeCounter;

import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonPage,
  IonText,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { useHistory } from "react-router";
import "./FAQs.css";
import right_chevron from "../../../assets/images/common/grey-chevron-right.svg";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { showToastMessage, offlineErrorCode, somethingWentWrongErrMsg, unauthorizedErrorCode } from "../../../common/common";
import { getFAQPages } from "../../../data-services/faq";

const FAQs: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [categoryData, setCategoryData] = useState<any>([]);

  const navigateTo = (page: any, param: any) => {
    history.push(page + "/" + param);
  };

  const getQuestions = async () => {
    const response = await getFAQPages();

    if (response?.success) {
      let objects = [];

      for (let i = 0; i < response?.data?.order?.length; i++) {
        objects.push({
          key: response.data.order[i],
          value: response.data.categories[i],
        });
      }

      setCategoryData(objects);
    } else {
      if (response?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (response?.data?.errorCode == offlineErrorCode) {
        showToastMessage(response?.data?.message);
      } else if (response?.message) {
        showToastMessage(response.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <IonPage>
      <SimpleHeaderView
        title="FAQ's"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        {categoryData.length > 0 && (
          <IonCard className="faqs-card-view">
            {categoryData.map((item: any, index: any) => (
              <IonItem
                className="faqs-card-view__items"
                key={index}
                onClick={(_) => navigateTo("/FAQsProfileManagement", item.key)}
              >
                <IonText className="faqs-card-view__label">
                  {index + 1}. {item.value}
                </IonText>
                <IonIcon
                  className="faqs_selection_icon"
                  icon={right_chevron}
                  slot="end"
                />
              </IonItem>
            ))}
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default FAQs;

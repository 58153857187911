import React, { useEffect, useState } from "react";
import { IonInput, IonItem, IonText } from "@ionic/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./InputTypes.css";
type componentProps = {
  question: string;
  answer?: string;
  question_id: number;
  options?: string;
  questionno?: number;
  requiredmessage?: boolean;
  numberValidateMessage?: boolean;
  allowHtmlQuestion?: any;
};
const InpuTypeBlank: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    numberValidateMessage,
    allowHtmlQuestion
  } = Props;
  const [answerValue, setAnswerValue] = useState<any>(answer);

  return (
    <>
      <div className="take-test-question">
      <div className="take-test-question__label take-test-question__label--blank">
          {questionno && <>{questionno}. </>}
          {allowHtmlQuestion ? (
            <span
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            ></span>
          ) : (
            <>{question}</>
          )}
        </div>

        {requiredmessage == true && (
          <div className="error-message">This field is required.</div>
        )}
        {numberValidateMessage == true && (
          <div className="error-message">
            The field should contain number only.
          </div>
        )}
      </div>
    </>
  );
};

export default InpuTypeBlank;

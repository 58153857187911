import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonRouterLink
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './LoginWrapper.css';
import footerImage from '../../../assets/copyright.png';
import { CapacitorHttp } from '@capacitor/core';
import PolicyModal from '../policyModal/policyModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const backIcon = <FontAwesomeIcon icon={faArrowLeft} />;

const LoginWrapper: React.FC<{ title?: String }> = ({ children, title }) => {
  const defaultTitle =
    'Log in to start building a stronger, more informed and connected community.';
  const [showModal, setShowModal] = useState(false);
  const [modaltitle, setModalTitle] = useState('');
  const [description, setDescription] = useState('');
  const [heading, setHeading] = useState('');
  
  const fetchTerms = async () => {

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-resource?resource=terms_of_use_page`;
    const options = {
      url: url,
      headers: { 'Content-Type': 'text/html' },
    };
    const response = await CapacitorHttp.get(options);
    if (response.status = 200) {
    setShowModal(true);
    setModalTitle('TERMS OF USE');
    setHeading('');
    setDescription(response.data.content);
    }

  };
  const fetchPolicy = async () => {

    const url = `${process.env.REACT_APP_HP_API_URL}/index.php/auth/view-resource?resource=privacy_policy_page`;
    const options = {
      url: url,
      headers: { 'Content-Type': 'text/html' },
    };
    const response = await CapacitorHttp.get(options);
    if (response.status = 200) {
    setShowModal(true);
    setModalTitle('PRIVACY POLICY');
    setHeading('');
    setDescription(response.data.content);
    }
  };

  const hideModal = async () => {
    await setShowModal(false);
}

  return (
    <IonPage>
      <IonContent>
        <div className="section-container onboarding__wrapper">
          <IonRouterLink routerLink="/Landing">
            <i className="fa-2x" color="#ffffff">
              {backIcon}
            </i>
          </IonRouterLink>
          {children}
        </div>
      </IonContent>

      <PolicyModal
        title={modaltitle}
        heading={heading}
        description={description}
        isOpen={showModal}
        closeAction={hideModal} />
      </IonPage>

    
    
  );
};

export default LoginWrapper;

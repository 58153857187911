import React, { useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import "./DeleteAccountRequest.css";
import Modal from "../../../components/Modal/Modal";
import { deleteAccountRequest } from "../../../data-services/edit-profile";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
type ModalProps = {
  isOpen_delete_account: boolean;
  closeDeleteAcctountRequest: Function;
};

const DeleteAccountRequest: React.FC<ModalProps> = (props) => {
  const { isOpen_delete_account, closeDeleteAcctountRequest } = props;
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      delete_reason: "",
    },
    mode: "onBlur",
  });
  async function closeModal() {
    setShowModal(false);
    if (title == "Done") closeDeleteAcctountRequest();
  }

  const submitComment = async () => {
    let deleteAccountResponse = await deleteAccountRequest(
      getValues("delete_reason")
    );

    if (deleteAccountResponse?.data?.success) {
      setTitle("Done");
      setDescription(deleteAccountResponse.data.message);
      setButtonText("Return to Settings");
      setShowModal(true);
    } else {
      setTitle("Oops!");
      setDescription(deleteAccountResponse.data.message);
      setButtonText("Try again");
      setShowModal(false);
    }
  };

  return (
    <IonModal isOpen={isOpen_delete_account} mode="ios">
      <IonHeader>
        <IonToolbar mode="ios" color="light">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/"
              mode="md"
              className="delete-account-container_back_btn"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form onSubmit={handleSubmit(submitComment)}>
          <div className="delete-account-container">
            <p className="delete-account-container__title">
              Delete Account Request
            </p>
            <p className="delete-account-container__content">
              You are about to delete your PUSH models profile account.
            </p>
            <p className="delete-account-container__content">
              Please note, once you delete your account, you will lose all
              access to apply for any upcoming opportunity and your PUSH models
              profile page will be deleted forever.
            </p>
            <p className="delete-account-container__content">
              Please confirm that you are 100% sure this is something you want
              to do, and if so, provide us with the reason.
            </p>
            <p className="delete-account-container__content">
              Our staff will contact you within 2 business days.
            </p>
            <IonTextarea
              placeholder="Enter reason here"
              className={
                errors.delete_reason !== undefined
                  ? "form-input-styles-error delete-account-container__txt-area"
                  : "form-group__input-styles delete-account-container__txt-area"
              }
              mode="md"
              {...register("delete_reason", {
                required: "This field is required.",
              })}
              rows={6}
            ></IonTextarea>
            <ErrorMessage
              errors={errors}
              name="delete_reason"
              as={<div className="error-message" />}
            />
            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button delete-account__btn-submit"
            >
              Submit
            </IonButton>
          </div>
        </form>
      </IonContent>

      <Modal
        title={title}
        description={description}
        buttonText={buttonText}
        isOpen={showModal}
        closeAction={closeModal}
      ></Modal>
    </IonModal>
  );
};

export default DeleteAccountRequest;

import React, { useEffect, useState } from "react";
import {
  IonBadge,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  PHOTOS_ONLY_RECAP_ID,
} from "../../common/common";
import "./AssignmentRecap.css";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router";
import AssignmentRecapTest from "./AssignmentRecapTest";
import AssignmentRecapPhoto from "./AssignmentRecapPhoto";
import AssignmentRecapReceipts from "./AssignmentRecapReceipts";
import AssignmentRecapConfirmation from "./AssignmentRecapConfirmation";
import { getassignmentquestion } from "../../data-services/assignmets";
import AssignmentRecapRating from "./AssignmentRecapRating/AssignmentRecapRating";
// import AssignmentRecapConfirmation from "./AssignmentRecapConfirmation";
import { getUserCurrentPosition } from "../../common/common";
import { talentEventCheckout } from "../../data-services/assignmets";
import { isNewAppVersion } from "../../common/common";
import { getDownloadNewAppImage } from "../../data-services/home-api";
import AppUpdatePopover from "../../components/AppUpdatePopover/AppUpdatePopover";

const checkIcon = <FontAwesomeIcon icon={faCheck} />;

const AssignmentRecap: React.FC = () => {
  const dispatch = useDispatch();
  const av_url_param: any = useParams();
  const history = useHistory();
  const [showRecapTestModal, setShowRecapTestModal] = useState<boolean>(false);
  const [showRecapPhotoModal, setShowRecapPhotoModal] =
    useState<boolean>(false);
  const [showRecapReceiptModal, setShowRecapReceiptModal] =
    useState<boolean>(false);
  const [showRecapRatingModal, setShowRecapRatingModal] =
    useState<boolean>(false);
  const [recapTestQuestions, setRecapTestQuestions] = useState<any>(null);

  const [isPhotosOnlyRecap, setIsPhotosOnlyRecap] = useState<boolean>(false);
  const [noRecapAddedTest, setNoRecapAddedTest] = useState<boolean>(true);
  const [noRecapAddedPhoto, setNoRecapAddedPhoto] = useState<boolean>(false);
  const [noRecapAddedReceipt, setNoRecapAddedReceipt] =
    useState<boolean>(false);

  const [recapEventPhotos, setRecapEventPhotos] = useState<any>([]);
  const [recapEventVideos, setRecapEventVideos] = useState<any>([]);
  const [componentRefresh, setComponentRefresh] = useState<boolean>(false);
  const [recapEventInfo, setRecapEventInfo] = useState<any>("");
  const [showRecapConfirmationModa, setShowRecapConfirmationModal] =
    useState<boolean>(false);
  const [showNewAppVersionPopover, setShowNewAppVersionPopover] =
    useState(false);
  const [newAppVersionPopoverImage, setNewAppVersionPopoverImage] =
    useState("");

  /**
   * Close the Report Questions Form
   * @param show_recap_confirmation
   */
  const closeRecapTestModal = async (show_recap_confirmation = false) => {
    setShowRecapTestModal(false);

    if (show_recap_confirmation) {
      // Show the Report Form Confirmation Popup
      setShowRecapConfirmationModal(true);
    } else {
      await recapInit();
    }
  };

  /**
   * Close the Report Form Confirmation popup
   */
  const closeRecapConfirmationModal = async (checkout = false) => {
    if (checkout) {
      // Check Out
      let { latitude, longitude }: any = await getUserCurrentPosition();

      if (latitude && longitude) {
        try {
          talentEventCheckout({
            checkInUeId: recapEventInfo?.ue_id,
            latitude,
            longitude,
          });
        } catch (e: any) {
          setShowRecapConfirmationModal(false);
        }
      }
    }
    // Close
    setShowRecapConfirmationModal(false);
    await recapInit();
  };

  const closeRecapPhotoModal = async () => {
    setComponentRefresh(!componentRefresh);
    setShowRecapPhotoModal(false);
  };
  const closeRecapReceiptModal = async (refresh_com: boolean = true) => {
    setComponentRefresh(!componentRefresh);
    setShowRecapReceiptModal(false);
  };

  const closeRatingModal = () => {
    setComponentRefresh(!componentRefresh);
    setShowRecapRatingModal(false);
  };
  const recapInit = async () => {
    setRecapTestQuestions(null);
    let api_res = await getassignmentquestion(av_url_param.eventid);

    if (api_res?.success) {
      setRecapEventInfo(api_res.data.event);
      let answers = api_res.data.answers;
      let questions = api_res.data.questions;
      // Photos Only Recap do not have any questions, but require at least one Event Photo uploaded
      if (!questions.length) {
        setIsPhotosOnlyRecap(true);
      } else {
        setNoRecapAddedTest(api_res?.data?.event?.recap_completed);
      }

      let images = api_res.data.images;
      for (let index = 0; index < images.length; index++) {
        let element = images[index];
        if (element.mrd_is_receipt == 0) {
          setNoRecapAddedPhoto(true);
        }
        if (element.mrd_is_receipt == 1) {
          setNoRecapAddedReceipt(true);
        }
      }
      let event_photos = [];
      for (let index = 0; index < images.length; index++) {
        let element = images[index];
        if (element.mrd_is_receipt == 0) {
          event_photos.push(element);
        }
      }
      if (api_res?.data?.videos?.length) {
        setRecapEventVideos(api_res?.data?.videos);
      }
      setRecapEventPhotos(event_photos);
      setRecapTestQuestions(api_res);
    } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (api_res?.data?.errorCode == offlineErrorCode) {
      showToastMessage(api_res?.message);
    } else if (api_res?.message) {
      showToastMessage(api_res.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };

  const openRecapQuestionsHandler = async () => {
    if (+recapEventInfo?.rf_post_branch_questions_update) {
      const newAppVersion = await isNewAppVersion();

      setShowNewAppVersionPopover(newAppVersion);
      if (newAppVersion) {
        let downloadNewAppImage = await getDownloadNewAppImage();
        if (downloadNewAppImage?.success) {
          setNewAppVersionPopoverImage(downloadNewAppImage?.data?.image);
        }
      } else {
        setShowRecapTestModal(true);
      }
    } else {
      setShowRecapTestModal(true);
    }
  };

  useEffect(() => {
    (async () => {
      await recapInit();
    })();
  }, [componentRefresh]);
  return (
    <IonPage>
      {recapTestQuestions !== null && (
        <>
          <AssignmentRecapTest
            react_test_info={recapTestQuestions}
            recap_test_show={showRecapTestModal}
            recapTestClosed={closeRecapTestModal}
            recap_ue_id={av_url_param.eventid}
            recap_ue_name={recapEventInfo?.ue_name}
            recap_ue_status={recapEventInfo?.ue_status}
            recap_ue_customer_name={recapEventInfo?.customer_company}
            recap_ue_customer_image={recapEventInfo?.customer_logo}
            recap_completed={recapEventInfo?.recap_completed}
            recap_display_title={recapEventInfo?.display_title}
          />
          {showRecapPhotoModal && (
            <AssignmentRecapPhoto
              photo_test_show={showRecapPhotoModal}
              photoTestClosed={closeRecapPhotoModal}
              photo_ue_id={av_url_param.eventid}
              photo_ue_name={recapEventInfo?.ue_name}
              photo_ue_status={recapEventInfo?.ue_status}
              photo_ue_customer_name={recapEventInfo?.customer_company}
              photo_ue_customer_image={recapEventInfo?.customer_logo}
              recap_existing_photo={recapEventPhotos}
              recap_existing_video={recapEventVideos}
              photo_ue_rf_id={recapEventInfo?.ue_rf_id}
            />
          )}
          <AssignmentRecapReceipts
            recapEventData={recapTestQuestions.data}
            recap_receipt_show={showRecapReceiptModal}
            recapReceiptClosed={closeRecapReceiptModal}
            recap_receipt_ue_id={av_url_param.eventid}
            recap_receipt_ue_name={recapEventInfo?.ue_name}
            recap_receipt_ue_status={recapEventInfo?.ue_status}
            recap_receipt_ue_customer_name={recapEventInfo?.customer_company}
            recap_receipt_ue_customer_image={recapEventInfo?.customer_logo}
            recap_receipt_ue_rf_id={recapEventInfo?.ue_rf_id}
          />
          <AssignmentRecapConfirmation
            isOpen={showRecapConfirmationModa}
            closeModalHandler={closeRecapConfirmationModal}
            showCheckOut={recapEventInfo?.can_model_check_out}
          />
          <AssignmentRecapRating
            show_rating_modal={showRecapRatingModal}
            closeRatingModal={closeRatingModal}
            rating_ue_id={av_url_param.eventid}
          />
          {newAppVersionPopoverImage && (
            <AppUpdatePopover
              showPopover={showNewAppVersionPopover}
              setShowPopover={setShowNewAppVersionPopover}
              newAppVersionPopoverImage={newAppVersionPopoverImage}
            />
          )}

          <SimpleHeaderView
            bgcolor="primary"
            buttonType=""
            title={recapEventInfo?.display_title}
          />
          <IonContent>
            <div className="assign-recap-container">
              <div className="recap__welcome-text">
                <h1>{recapEventInfo?.display_title}</h1>
                <IonText>
                  Thank you for participating in
                  <span> {recapEventInfo?.ue_name}</span>.
                  {!recapEventInfo.recap_completed && (
                    <>
                      {(recapEventInfo.ue_rf_id == PHOTOS_ONLY_RECAP_ID ||
                        recapEventInfo.ue_rf_id == 0) && (
                        <>
                          {" "}
                          To complete this assignment, please upload your event
                          photos, then press 'Submit'.
                        </>
                      )}
                      {recapEventInfo.ue_rf_id != PHOTOS_ONLY_RECAP_ID &&
                        recapEventInfo.ue_rf_id != 0 && (
                          <>
                            {" "}
                            Complete this {recapEventInfo?.display_title} of the
                            event to begin processing your payment.
                          </>
                        )}
                    </>
                  )}
                </IonText>
              </div>
              <IonCard className="recap__event__details">
                <IonBadge>
                  <span style={{ backgroundColor: "green" }}></span>{" "}
                  {recapEventInfo?.ue_status}
                </IonBadge>
                <h2>{recapEventInfo?.ue_name}</h2>
                <IonItem className="assignment-view__event__status">
                  <img
                    slot="start"
                    src={decodeURIComponent(recapEventInfo?.customer_logo)}
                    alt=""
                  />
                  <IonText slot="start">
                    {recapEventInfo?.customer_company}
                  </IonText>
                </IonItem>
              </IonCard>
              {!isPhotosOnlyRecap && (
                <div className="recap-test recap-test__info__border">
                  <div className="recap-test__info">
                    <IonTitle>
                      {recapEventInfo?.display_title}
                      <span className="recap-test__field-required">*</span>
                    </IonTitle>
                    <div className="recap-test__status">
                      <IonText>
                        {noRecapAddedTest
                          ? "Done"
                          : "No " +
                            recapEventInfo?.display_title +
                            " added yet"}
                      </IonText>
                      {noRecapAddedTest && (
                        <i className="fa_icon_left">{checkIcon}</i>
                      )}
                    </div>
                    <IonButton
                      fill={"outline"}
                      onClick={() => openRecapQuestionsHandler()}
                    >
                      Edit
                    </IonButton>
                  </div>
                </div>
              )}
              <div className="recap-test recap-test__info__border">
                <div className="recap-test__info">
                  <IonTitle>
                    Photos / Videos
                    <span className="recap-test__field-required">*</span>
                  </IonTitle>
                  <div className="recap-test__status">
                    <IonText>
                      {noRecapAddedPhoto ? "Done" : "No Photos added yet"}
                    </IonText>
                    {noRecapAddedPhoto && (
                      <i className="fa_icon_left">{checkIcon}</i>
                    )}
                  </div>
                  <IonButton
                    fill={"outline"}
                    onClick={() => setShowRecapPhotoModal(true)}
                  >
                    Edit
                  </IonButton>
                </div>
              </div>
              <div className="recap-test">
                <div className="recap-test__info">
                  <IonTitle>Receipts</IonTitle>
                  <div className="recap-test__status">
                    <IonText>
                      {noRecapAddedReceipt ? "Done" : "No Receipts added yet"}
                    </IonText>
                    {noRecapAddedReceipt && (
                      <i className="fa_icon_left">{checkIcon}</i>
                    )}
                  </div>
                  {recapEventInfo?.can_upload_receipts?.success && (
                    <IonButton
                      fill={"outline"}
                      onClick={() => setShowRecapReceiptModal(true)}
                    >
                      Edit
                    </IonButton>
                  )}
                  {!recapEventInfo?.can_upload_receipts?.success && (
                    <p>
                      <IonText className="recap-test__info__receipt-not-uploadable">
                        {recapEventInfo.can_upload_receipts.message}
                      </IonText>
                    </p>
                  )}
                </div>
              </div>
              <div className="assign-recap-container__btn">
                <IonButton
                  className={
                    noRecapAddedTest && noRecapAddedPhoto
                      ? "form-group__button"
                      : "form-group__button recap-test__scondary"
                  }
                  onClick={() => setShowRecapRatingModal(true)}
                  expand="block"
                  disabled={
                    noRecapAddedTest && noRecapAddedPhoto ? false : true
                  }
                >
                  Submit
                </IonButton>
              </div>
            </div>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export default AssignmentRecap;

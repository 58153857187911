import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonModal,
  IonToolbar,
  IonLabel,
  IonText,
} from "@ionic/react";
import "./MyRewards.css";
import { closeOutline } from "ionicons/icons";
import { get } from "../../storage/storage";
import blue_checked from "../../assets/images/common/checked_blue_3d.png";
type claimRewars = {
  isOpenClaimModal: boolean;
  closeClaimdModal: Function;
  title_message: { title: string; message: string };
};

const ClaimRewardSuccessModal: React.FC<claimRewars> = (props) => {
  const { isOpenClaimModal, closeClaimdModal, title_message } = props;

  return (
    <IonModal isOpen={isOpenClaimModal} cssClass="claim_reward_modal" mode="md">
      <IonContent>
        <div className="claim_reward_content">
          <IonIcon
            src={closeOutline}
            slot="end"
            onClick={() => closeClaimdModal()}
          />
          <div className="claim_reward_content__body">
            <img src={blue_checked} alt="" />
            <h1>{title_message?.title}</h1>
            <IonText>{title_message?.message}</IonText>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ClaimRewardSuccessModal;

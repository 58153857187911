import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  IonProgressBar,
} from "@ionic/react";
import "./AssignmentRecap.css";
import FileUploaderAddmore from "../../push-components/ProfileFileuploader/FileUploaderAddMore.js";
import {
  deleteRecapPhotos,
  saveModelUploadedImages,
} from "../../data-services/assignmets";
import {
  getUserCameraPhoto,
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
  somethingWentWrongErrMsg,
} from "../../common/common";
import back_arrow_white from "../../assets/images/common/arrow-left-white.png";
import cameraIcon from "../../assets/images/common/camera-icon.png";
import { closeCircle } from "ionicons/icons";
import { decode } from "base64-arraybuffer";
type ModalProps = {
  photo_test_show: boolean;
  photoTestClosed: Function;
  photo_ue_id: number;
  photo_ue_name: string;
  photo_ue_status: string;
  photo_ue_customer_name: string;
  photo_ue_customer_image: string;
  recap_existing_photo: any;
  recap_existing_video?: any;
};

// Declare Plyr as a global variable
declare global {
  interface Window {
    Plyr: any;
  }
}

let image_count = 1;
const UploadEventPhotosWithoutRecap: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    photo_test_show,
    photoTestClosed,
    photo_ue_id,
    photo_ue_name,
    photo_ue_status,
    photo_ue_customer_name,
    photo_ue_customer_image,
    recap_existing_photo,
    recap_existing_video,
  } = props;
  const [fileEmptyError, setFileEmptyError] = useState<boolean>(false);
  const [eventExistingPhotos, setEventExistingPhotos] = useState<any>([]);
  const [eventExistingVideos, setEventExistingVideos] = useState<any>([]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [isVideoBeingDeleted, setIsVideoBeingDeleted] =
    useState<boolean>(false);
  const [deletePhotoId, setDeletePhotoId] = useState<any>("");
  const [plyrInstances, setPlyrInstances] = useState<any>([]);
  const [cameraPhoto, setCameraPhoto] = useState<
    {
      name: string;
      type: string;
      size: number;
      file: string;
      data: any;
      ui_image: any;
      extension: any;
    }[]
  >([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const recapPhotoUploadHandler = async (event: any) => {
    setUploadProgress(5);
    event.preventDefault();
    setDisabledSubmitBtn(true);
    setFileEmptyError(false);
    let recap_event = new FormData(event.target);
    recap_event.append("isReceipt", "0");
    recap_event.append("ue_id", `${photo_ue_id}`);
    let all_images = recap_event.getAll("all_files[]");
    recap_event.delete("all_files[]");
    for (let index = 0; index < all_images.length; index++) {
      let file: any = all_images[index];
      if (file.name) {
        recap_event.append("files[]", file);
      }
    }
    // image  uploaded by file mobile camera
    for (let i = 0; i < cameraPhoto.length; i++) {
      let camera: any = cameraPhoto[i];
      let blob = new Blob([new Uint8Array(decode(camera.file))], {
        type: "image/jpeg",
      });
      let file_obj = new File([blob], camera.name, {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      });
      recap_event.append("files[]", file_obj);
    }

    //  formData give array length 1 event there is no image selected
    if (cameraPhoto.length == 0 && recap_event.getAll("files[]").length == 1) {
      setFileEmptyError(true);
      setDisabledSubmitBtn(false);
      return;
    }
    try {
      let api_res: any = await saveModelUploadedImages(
        recap_event,
        handleProgress
      );
      if (api_res?.success) {
        photoTestClosed();
        setCameraPhoto([]);
      } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.data?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e) {
      if (e == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(e);
      }
    }

    setDisabledSubmitBtn(false);
    setUploadProgress(0);
  };

  const deleteEventRecapPhotos = async (is_video?: boolean) => {
    for (let index = 0; index < deletePhotoId?.image_array.length; index++) {
      let element = deletePhotoId?.image_array[index];
      if (element.mrd_id == deletePhotoId.mrd_id) {
        if (is_video) {
          plyrInstances[index].destroy();
          let videoElementToRemovePlyrFrom = document.getElementById(
            "video_" + element.mrd_id
          );
          videoElementToRemovePlyrFrom?.parentNode?.removeChild(
            videoElementToRemovePlyrFrom
          );
        } else {
          deletePhotoId?.image_array.splice(index, 1);
          setEventExistingPhotos(deletePhotoId?.image_array);
        }
        break;
      }
    }
    let delete_api = await deleteRecapPhotos(deletePhotoId.mrd_id);
    if (delete_api?.success) {
      await showToastMessage(delete_api.message);
    } else if (delete_api?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (delete_api?.data?.errorCode == offlineErrorCode) {
      showToastMessage(delete_api?.data?.message);
    } else if (delete_api?.message) {
      showToastMessage(delete_api.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };
  const takePhoto = async () => {
    let image = await getUserCameraPhoto("base64");
    if (image?.base64String) {
      image_count = image_count + 1;
      if (image.base64String) {
        setCameraPhoto([
          ...cameraPhoto,
          {
            name: `image${image_count}.${image.format}`,
            type: "image/jpeg",
            size: 789,
            file: image.base64String,
            data: "",
            ui_image: "data:text/plain;base64," + image.base64String,
            extension: image.format,
          },
        ]);
      }
    }
  };
  const removeImage = (index: any) => {
    cameraPhoto.splice(index, 1);
    setCameraPhoto(cameraPhoto);
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  useEffect(() => {
    setEventExistingPhotos(recap_existing_photo);
    setEventExistingVideos(recap_existing_video);

    if (window.Plyr) {
      setTimeout(() => {
        // Get all video elements with a specific class or any other criteria
        const videoElements = document.querySelectorAll(
          ".assign-recap-content__exsting_img video"
        );
        const videoArray = Array.from(videoElements);
        let plyrInstancesTemp = [];
        // Loop through each video element and initialize Plyr
        for (const videoElement of videoArray) {
          plyrInstancesTemp.push(
            new window.Plyr(videoElement, {
              controls: ["play", "volume", "fullscreen"],
              fullscreen: {
                enabled: true,
              },
            })
          );
        }
        setPlyrInstances(plyrInstancesTemp);
      }, 500);
    }
  }, [
    eventExistingPhotos,
    recap_existing_photo,
    eventExistingVideos,
    recap_existing_video,
  ]);
  return (
    <IonModal isOpen={photo_test_show} mode="ios">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Recap Photos</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => photoTestClosed(false)}>
              <img src={back_arrow_white} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/* <SimpleHeaderView bgcolor="primary" buttonType="" title="Recap Photos" /> */}
      <IonContent>
        <div className="assign-recap-container">
          <form
            onSubmit={recapPhotoUploadHandler}
            className="assign-recap__from"
          >
            <div className="recap__welcome-text">
              <IonText>
                Thank you for participating in
                <span> {photo_ue_name}</span>. Please upload your event photos,
                then press 'Submit'.
              </IonText>
            </div>
            <IonCard className="recap__event__details">
              <IonBadge>
                <span style={{ backgroundColor: "green" }}></span>{" "}
                {photo_ue_status}
              </IonBadge>
              <h2>{photo_ue_name}</h2>
              <IonItem className="assignment-view__event__status">
                <img
                  slot="start"
                  src={decodeURIComponent(photo_ue_customer_image)}
                  alt=""
                />
                <IonText slot="start">{photo_ue_customer_name}</IonText>
              </IonItem>
            </IonCard>
            <h1 className="assign-recap-content__title">Photos</h1>
            {eventExistingPhotos?.length > 0 && (
              <div className="assign-recap-content__exsting_img">
                {eventExistingPhotos.map((image: any, index: number) => (
                  <div key={index} className="react_exsting_image">
                    <IonIcon
                      icon={closeCircle}
                      onClick={() => {
                        setDeletePhotoId({
                          mrd_id: image.mrd_id,
                          image_array: eventExistingPhotos,
                        });
                        setShowDeleteAlert(true);
                        setIsVideoBeingDeleted(false);
                      }}
                    />
                    <img
                      // src={`${process.env.REACT_APP_HP_IMAGE_URL}/report_document/${image.mrd_document}`}
                      src={`${image.full_path}`}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            )}

            {eventExistingVideos?.length > 0 && (
              <>
                <h1 className="assign-recap-content__title">Videos</h1>
                <div className="assign-recap-content__exsting_img">
                  {eventExistingVideos.map((image: any, index: number) => (
                    <>
                      <div
                        id={"video_" + image.mrd_id}
                        key={index}
                        className="react_exsting_image"
                      >
                        <IonIcon
                          icon={closeCircle}
                          onClick={() => {
                            setDeletePhotoId({
                              mrd_id: image.mrd_id,
                              image_array: eventExistingVideos,
                            });
                            setShowDeleteAlert(true);
                            setIsVideoBeingDeleted(true);
                          }}
                        />
                        <video controls>
                          <source
                            src={`${image.full_path}`}
                            type={`video/${image.extension}`}
                          />
                          Video not supported.
                        </video>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}

            {/* below div component use css of AssignmentDeclineAddMore.css which is located src\pages\AssignmentView\UploadEventRecapPhotos\UploadEventRecapPhotos.tsx */}
            {/* file upload component */}
            <div className="assign-decline__upload-btn add_min_height">
              <FileUploaderAddmore
                name="all_files[]"
                limit="200"
                id="model_additional"
                files={[]}
              />
              <input type="hidden" name="files[]" />
              <div className="assign-decline_take-camera">
                <IonCard onClick={takePhoto}>
                  <img alt="" src={cameraIcon} />
                  <IonLabel>Take Photo</IonLabel>
                </IonCard>
                <div className="assign-decline_photo-items">
                  {cameraPhoto.map((item: any, index: number) => (
                    <div className="assign-decline_photo-list">
                      <img className="img_file" alt="" src={item.ui_image} />
                      <div className="assign-decline_camera-img">
                        <span className="img_name">{item.name}</span>
                        <button onClick={() => removeImage(index)}>
                          <i className="fileuploader-icon-remove remove-color"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {fileEmptyError && (
              <div className="error-message">Image is required</div>
            )}
            {disabledSubmitBtn && (
              <IonProgressBar value={uploadProgress / 100} />
            )}
            <div className="assign-recap-container__btn">
              <IonButton
                type="submit"
                className="form-group__button"
                expand="block"
                disabled={disabledSubmitBtn}
              >
                Submit
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
      <IonAlert
        isOpen={showDeleteAlert}
        // mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Are You Sure?"}
        message={
          "Please confirm if you want to delete the " +
          (isVideoBeingDeleted ? "video" : "photo")
        }
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Yes",
            handler: () => {
              deleteEventRecapPhotos(isVideoBeingDeleted);
            },
          },
        ]}
      />
    </IonModal>
  );
};

export default UploadEventPhotosWithoutRecap;

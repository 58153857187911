import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonButton,
  IonText,
  IonModal,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import "./ApplicationConfirmation.css";
// TO be replaced with WARNING icon
import warningIcon from "../../assets/images/common/orange-alert-icon.png";
import backArrow from "../../assets/images/common/arrow-left.png";
type ModalProps = {
  isOpen: boolean;
  closeModalHandler: Function;
};

const TooFarAwayWarning: React.FC<ModalProps> = (Props) => {
  const history = useHistory();
  const { isOpen, closeModalHandler } = Props;
  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <IonModal isOpen={isOpen} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar>
          <IonButton
            fill="clear"
            slot="start"
            onClick={() => closeModalHandler(false)}
          >
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="application-confirm__content">
          <div className="opportunities-survey__application-confirm">
            <img
              className="opportunities-survey__success-icon"
              src={warningIcon}
              alt=""
            />
            <h2>Seems like you're far away from this opportunity</h2>
            <IonText>
              Unless otherwise stated, travel is not included and additional
              costs may not be eligibile for reimbursement.
            </IonText>
            <>
              <div className="orange-border">
                <div className="orange-border__content">
                  By selecting Book, you understand that any travel expenses are
                  your own responsibility.
                </div>
                <p className="orange-border__content"></p>
                <div className="orange-border__content">
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    onClick={() => closeModalHandler(true)}
                  >
                    Book
                  </IonButton>
                </div>
              </div>
            </>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default TooFarAwayWarning;

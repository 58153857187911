import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { useHistory } from "react-router";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./PaymentDetails.css";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import { getTipaltiPaymentsPage, saveTipaltiPaymentPaperworkStatus } from "../../../data-services/home-api";
import back_arrow_white from "../../../assets/images/common/arrow-left-white.png";

const PaymentDetails: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [requestedPage, setRequestedPage] = useState<any>(1);
  const [requestParams, setRequestParams] = useState<any>("");
  const [iframePageURL, setIframePageUrl] = useState<any>("");

  const initTipaltiPaymentsPage = async () => {
    let getTipaltiPaymentsPageResponse = await getTipaltiPaymentsPage(
      requestedPage,
      requestParams
    );
    if (getTipaltiPaymentsPageResponse?.success) {
      setIframePageUrl(getTipaltiPaymentsPageResponse.data.iframePageURL);
      if(getTipaltiPaymentsPageResponse.data.model_tipalti_payment_status === null) {
        saveTipaltiPaymentPaperworkStatus(0);
      }
     
    } else if (getTipaltiPaymentsPageResponse?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (getTipaltiPaymentsPageResponse?.data?.errorCode == offlineErrorCode) {
      showToastMessage(getTipaltiPaymentsPageResponse?.data?.message);
    } else if (getTipaltiPaymentsPageResponse?.message) {
      showToastMessage(getTipaltiPaymentsPageResponse.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
  };

  useEffect(() => {
    (async () => {
      await initTipaltiPaymentsPage();
    })();
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>Payment Details</IonTitle>
          <IonButtons slot="start">
          <IonButton onClick={() => history.push(`/MyAccount`)}>
              <img src={back_arrow_white} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ width: "100%", height: "calc(100vh - 95px)" }}>
          {(iframePageURL != "") && (
            <iframe
              style={{ width: "100%", height: "100%", border: "none" }}
              src={iframePageURL}
            />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PaymentDetails;

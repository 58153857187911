import React from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import "./BecomeInfluencer.css";
import check_icon from "../../assets/images/common/checked.png";
import star_img from "../../assets/images/become-influencer/become-influencer.png";
import { useParams } from "react-router";

const BecomeInfluencer: React.FC = () => {
  const url_params: any = useParams();
  return (
    <IonPage>
      <IonContent>
        <section className="become-influencer">
          <div className="become-influencer__image">
            <img src={star_img} alt="" />
          </div>

          <div className="become-influencer__title">
            <h1>
              Become a<br />
              <span>PUSH Influencer</span>
              <br />
              today!
            </h1>
          </div>
          <div className="become-influencer__description">
            Unlock more opportunities by becoming a<br />
            <span> PUSH Influencer</span> today!
          </div>
          <div className="become-influencer__additional-info-text">
            <p>
              Fill out additional information to be eligible <br /> for
              influencer perks and opportunities
            </p>

            <div className="become-influencer__additional-info">
              <ul>
                <li>
                  <img src={check_icon} alt="" /> P4P (Post for Product)
                </li>
                <li>
                  <img src={check_icon} alt="" /> Social Media Campaigns
                </li>
                <li>
                  <span>And more...</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="become-influencer__buttons">
            <IonButton
              className="form-group__button"
              expand="block"
              mode="ios"
              type="button"
              routerLink={`/InfluencerForm/${url_params.talent_signup_id}`}
            >
              Get Started
            </IonButton>
            <IonButton
              className="form-group__button"
              fill="clear"
              expand="block"
              mode="ios"
              type="button"
              href={`/Home`}
            >
              Not Now
            </IonButton>
          </div>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default BecomeInfluencer;

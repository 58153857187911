/** */
import React, { useState } from "react";
import {
  IonIcon,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonTitle,
  IonLabel,
  IonHeader,
  IonSearchbar,
} from "@ionic/react";
import "./SimpleHeaderView.css";
import info_circle from "../../assets/images/common/info-circle-white.svg";
import { refreshCircle, searchOutline, arrowBackOutline } from "ionicons/icons";
type componentProps = {
  title?: string;
  subTitle?: string;
  counter?: number;
  bgcolor: string;
  buttonType: string; // button type can be 'search' , 'save'
  clickEventHandler?: () => any;
  onTextChange?: any;
  imgSrc?: string;
  iconImgSrc?: string;
  searchPlaceholder?: string;
  onBack?: any;
  backStepUrl?: string;
  clickInfoButtonHandler?: () => any;
};
const SimpleHeaderView: React.FC<componentProps> = (props) => {
  const {
    backStepUrl,
    searchPlaceholder,
    title,
    subTitle,
    bgcolor,
    counter,
    buttonType,
    imgSrc,
    iconImgSrc,
    clickEventHandler,
    onTextChange,
    onBack,
    clickInfoButtonHandler,
  } = props;

  const [searchView, setsearchView] = useState<any>(false);
  const [searchTextChange, setSearchTextChange] = useState<string>("");

  /**
   * Display the search form
   */
  const showSearchView = () => {
    // If we have the search input on...
    if (searchView) {
      // Handle the event of search input text change
      onTextChange(searchTextChange);
    } else {
      // Show the search input!
      setsearchView(true);
    }
  };

  return (
    <IonHeader>
      <IonToolbar mode="ios" color={bgcolor}>
        {onBack && (
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                onBack();
              }}
            >
              <IonIcon slot="icon-only" icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
        )}
        {!onBack && (
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={backStepUrl ? `/${backStepUrl}` : `/`}
              mode="md"
            />
          </IonButtons>
        )}
        {/* The search input */}
        {searchView && (
          <IonSearchbar
            placeholder={searchPlaceholder}
            slot="start"
            mode="md"
            className="header-view_SearchBar"
            onIonChange={(e: any) => onTextChange(e.detail.value)}
            showCancelButton="never"
          ></IonSearchbar>
        )}
        <IonButtons slot="primary" className="header-view__search_btn">
          {/* The search icon */}
          {buttonType === "search" && (
            <IonButton
              onClick={() => {
                showSearchView();
              }}
            >
              <IonIcon slot="icon-only" icon={searchOutline} />
            </IonButton>
          )}
          {buttonType === "Save" && (
            <IonButton onClick={clickEventHandler}>
              <IonLabel>Save</IonLabel>
            </IonButton>
          )}
          {buttonType === "Reset" && (
            <IonButton onClick={() => clickEventHandler}>
              <IonLabel color="primary">Reset</IonLabel>
            </IonButton>
          )}
          {buttonType === "refreshCircle" && (
            <IonButton onClick={clickEventHandler}>
              <IonIcon slot="icon-only" icon={refreshCircle} />
            </IonButton>
          )}
          {buttonType === "roundArrowIcon" && (
            <IonButton onClick={clickEventHandler}>
              <img
                src={iconImgSrc}
                className="header-item__round-arrow-icon"
              ></img>
            </IonButton>
          )}
          {clickInfoButtonHandler && (
            <IonIcon className="header-item__info-icon" icon={info_circle} onClick={clickInfoButtonHandler} />
          )}
        </IonButtons>
        <div slot="start" className="title_items">
          {imgSrc != "" && imgSrc != null && (
            <IonButton>
              <img src={imgSrc} className="userImg"></img>
            </IonButton>
          )}
          <IonTitle className={subTitle ? "header_text" : ""}>
            {searchView == false && (
              <>
                <div
                  className={subTitle ? "header_title chat" : "header_title"}
                >
                  {title}&nbsp;{counter != 0 && counter && `(${counter})`}
                </div>
              </>
            )}
            {subTitle != "" && subTitle != null && (
              <div className="subheader_title">{subTitle}</div>
            )}
          </IonTitle>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default SimpleHeaderView;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./AssignmentRecap.css";
import ProfileAdditionalFileUploader from "../../push-components/ProfileFileuploader/ProfileAdditionalFileUploader";
import {
  deleteRecapPhotos,
  saveModelUploadedImages,
  logError,
} from "../../data-services/assignmets";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
  somethingWentWrongErrMsg,
  PHOTOS_ONLY_RECAP_ID,
} from "../../common/common";
import back_arrow_white from "../../assets/images/common/arrow-left-white.png";
import { closeCircle } from "ionicons/icons";
type ModalProps = {
  photo_test_show: boolean;
  photoTestClosed: Function;
  photo_ue_id: number;
  photo_ue_name: string;
  photo_ue_status: string;
  photo_ue_customer_name: string;
  photo_ue_customer_image: string;
  recap_existing_photo: any;
  photo_ue_rf_id?: any;
  recap_existing_video?: any;
};

// Declare Plyr as a global variable
declare global {
  interface Window {
    Plyr: any;
  }
}

const AssignmentRecapPhoto: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    photo_test_show,
    photoTestClosed,
    photo_ue_id,
    photo_ue_name,
    photo_ue_status,
    photo_ue_customer_name,
    photo_ue_customer_image,
    recap_existing_photo,
    photo_ue_rf_id,
    recap_existing_video,
  } = props;
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [recapExistingVideo, setRecapExistingVideo] = useState<any>([]);
  const [deletePhotoId, setDeletePhotoId] = useState<string>("");
  const [isVideoBeingDeleted, setIsVideoBeingDeleted] =
    useState<boolean>(false);
  const recapPhotoUploadHandler = async (event: any) => {
    event.preventDefault();
    setDisabledSubmitBtn(true);
    let recap_event = new FormData(event.target);
    recap_event.append("isReceipt", "0");
    recap_event.append("ue_id", `${photo_ue_id}`);
    let all_images = recap_event.getAll("all_files[]");
    recap_event.delete("all_files[]");
    for (let index = 0; index < all_images.length; index++) {
      let file: any = all_images[index];
      if (file.name) {
        recap_event.append("files[]", file);
      }
    }
    try {
      let api_res: any = await saveModelUploadedImages(recap_event);
      if (api_res?.success) {
        photoTestClosed();
      } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignments Recap Photo - recapPhotoUploadHandler - Exception thrown",
          "Talent App - Assignments Recap Photo",
          JSON.stringify(e.message)
        );
      }
      setDisabledSubmitBtn(false);
    }
    setDisabledSubmitBtn(false);
  };

  const deleteEventRecapPhotos = async () => {
    try {
      let delete_api = await deleteRecapPhotos(deletePhotoId);
      if (delete_api?.success) {
        showToastMessage(delete_api.message);
        photoTestClosed();
      } else if (delete_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (delete_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(delete_api?.message);
      } else if (delete_api?.message) {
        showToastMessage(delete_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignments Recap Photo - deleteEventRecapPhotos - Exception thrown",
          "Talent App - Assignments Recap Photo",
          JSON.stringify(e.message)
        );
      }
      setDisabledSubmitBtn(false);
    }
  };

  useEffect(() => {
    (async () => {
      setRecapExistingVideo(recap_existing_video);
      if (window.Plyr) {
        setTimeout(() => {
          // Get all video elements with a specific class or any other criteria
          const videoElements = document.querySelectorAll(
            ".assign-recap-content__exsting_img video"
          );
          const videoArray = Array.from(videoElements);
          // Loop through each video element and initialize Plyr
          for (const videoElement of videoArray) {
            const player = new window.Plyr(videoElement, {
              controls: ["play", "volume", "fullscreen"],
              fullscreen: {
                enabled: true,
              },
            });
          }
        }, 500);
      }
    })();
  }, [recap_existing_video, recapExistingVideo]);

  return (
    <IonModal isOpen={photo_test_show} mode="ios">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Recap Photos</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => photoTestClosed()}>
              <img src={back_arrow_white} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/* <SimpleHeaderView bgcolor="primary" buttonType="" title="Recap Photos" /> */}
      <IonContent>
        <div className="assign-recap-container">
          <form
            onSubmit={recapPhotoUploadHandler}
            className="assign-recap__from"
          >
            <div className="recap__welcome-text">
              <IonText>
                Thank you for participating in
                <span> {photo_ue_name}</span>. Please upload your event photos,
                then press 'Submit'.
              </IonText>
            </div>
            <IonCard className="recap__event__details">
              <IonBadge>
                <span style={{ backgroundColor: "green" }}></span>{" "}
                {photo_ue_status}
              </IonBadge>
              <h2>{photo_ue_name}</h2>
              <IonItem className="assignment-view__event__status">
                <img
                  slot="start"
                  src={decodeURIComponent(photo_ue_customer_image)}
                  alt=""
                />
                <IonText slot="start">{photo_ue_customer_name}</IonText>
              </IonItem>
            </IonCard>
            <h1 className="assign-recap-content__title">Photos</h1>
            {recap_existing_photo.length > 0 && (
              <div className="assign-recap-content__exsting_img">
                {recap_existing_photo.map((image: any, index: number) => (
                  <div key={index} className="react_exsting_image">
                    <IonIcon
                      icon={closeCircle}
                      onClick={() => {
                        setDeletePhotoId(image.mrd_id);
                        setShowDeleteAlert(true);
                        setIsVideoBeingDeleted(false);
                      }}
                    />
                    <img src={`${image.full_path}`} alt="" />
                  </div>
                ))}
              </div>
            )}

            {recapExistingVideo?.length > 0 && (
              <>
                <h1 className="assign-recap-content__title">Videos</h1>
                <div className="assign-recap-content__exsting_img">
                  {recapExistingVideo.map((image: any, index: number) => (
                    <div key={index} className="react_exsting_image">
                      <IonIcon
                        icon={closeCircle}
                        onClick={() => {
                          setDeletePhotoId(image.mrd_id);
                          setShowDeleteAlert(true);
                          setIsVideoBeingDeleted(true);
                        }}
                      />
                      <video controls>
                        <source
                          src={`${image.full_path}`}
                          type={`video/${image.extension}`}
                        />
                        Video not supported.
                      </video>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="assign-recap-content__event_photos">
              <ProfileAdditionalFileUploader
                name="all_files[]"
                limit="200"
                id="model_additional"
                files={[]}
              />
              <input type="hidden" name="all_files[]" />
            </div>

            <div
              className={
                recap_existing_photo.length > 0
                  ? "assign-recap-container__btn"
                  : "assign-recap-container__btn assign-recap_submit_btn"
              }
            >
              <IonButton
                type="submit"
                className="form-group__button"
                expand="block"
                disabled={disabledSubmitBtn}
              >
                Submit
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
      <IonAlert
        isOpen={showDeleteAlert}
        // mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Are You Sure?"}
        message={
          "Please confirm if you want to delete the " +
          (isVideoBeingDeleted ? "video" : "photo")
        }
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Yes",
            handler: () => {
              deleteEventRecapPhotos();
            },
          },
        ]}
      />
    </IonModal>
  );
};

export default AssignmentRecapPhoto;

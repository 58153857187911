/** */
import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonRow,
  IonCard,
  IonCol,
  IonDatetime,
  IonIcon,
  IonGrid,
  IonLabel,
  IonItem,
  IonTitle,
} from "@ionic/react";
import moment from "moment";
import "./PaidRevenue.css";
import "./Payments.css"; // css for calender component

import calender_icon from "../../assets/images/common/calendar-icon.svg";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import chevron_dow from "../../assets/images/common/chevero-down-grey.svg";

import EmptyState from "./EmptyState";
import { getTalentRevenue } from "../../data-services/revenue-api";
import EventRevenueCard from "./EventRevenueCard";
import Loading from "../../push-components/loading/Loading";
import { useDispatch } from "react-redux";
import {
  showToastMessage,
  offlineErrorCode,
  unauthorizedErrorCode,
  somethingWentWrongErrMsg,
} from "../../common/common";
import { authActions } from "../../store/auth";

// t060730
const PaidRevenue: React.FC = () => {
  const [paidRevenueApiData, setPaidRevenueApiData] = useState<any>([]);
  const [pendingRevenueApiData, setPendingRevenueApiData] = useState<any>([]);
  let current_date: any = moment();
  let year_ago_date: any = moment().subtract(1, "years");
  const dispatch = useDispatch();
  const [filterFromDate, setFilterFromDate] = useState<string>(
    year_ago_date.format("YYYY-MM-DD")
  );
  const [filterToDate, setFilterToDate] = useState<string>(
    current_date.format("YYYY-MM-DD")
  );
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);

  const getPaidRevenueData = async (start_date: string, end_date: string) => {
    setWaitingForApiResonse(true);
    let revenue_data = await getTalentRevenue(start_date, end_date);
    if (revenue_data?.success) {
      setPaidRevenueApiData(revenue_data.data.paid_revenue_events);
      setPendingRevenueApiData(revenue_data.data.pending_revenue_events);
    } else if (revenue_data?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (revenue_data?.data?.errorCode == offlineErrorCode) {
      showToastMessage(revenue_data?.data?.message);
    } else if (revenue_data?.message) {
      showToastMessage(revenue_data.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setWaitingForApiResonse(false);
  };

  useEffect(() => {
    (async () => {
      await getPaidRevenueData(filterFromDate, filterToDate);
    })();
  }, [filterFromDate, filterToDate]);

  return (
    <IonPage>
      <SimpleHeaderView
        title="My Revenue"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        {paidRevenueApiData.length === 0 &&
          pendingRevenueApiData.length === 0 && <EmptyState />}
        <>
          {(paidRevenueApiData.length > 0 ||
            pendingRevenueApiData.length > 0) && (
            <IonCard className="payment-calendar">
              <IonIcon src={calender_icon} className="calender_icon" />
              <div className="payment-calendar_from_to">
                <IonDatetime
                  doneText="Done"
                  cancelText=""
                  onIonChange={(e: any) =>
                    setFilterFromDate(
                      moment(e.detail.value)
                        .startOf("month")
                        .format("YYYY-MM-DD")
                    )
                  }
                  className="payment-calendar__calendar_from"
                  displayFormat="MMM YYYY"
                  placeholder={moment().subtract(1, "years").format("MMM YYYY")}
                  name="from_date"
                ></IonDatetime>
                <IonDatetime
                  doneText="Done"
                  cancelText=""
                  onIonChange={(e: any) =>
                    setFilterToDate(
                      moment(e.detail.value).endOf("month").format("YYYY-MM-DD")
                    )
                  }
                  className="payment-calendar__calendar_to"
                  displayFormat="MMM YYYY"
                  placeholder={moment().format("MMM YYYY")}
                  name="to_date"
                ></IonDatetime>
              </div>
              <IonIcon src={chevron_dow} className="chevron_icon" />
            </IonCard>
          )}

          {paidRevenueApiData.length > 0 && (
            <div className="paid-revenue-container">
              <IonItem className="total-revenu__title">
                <IonTitle>My Revenue</IonTitle>
              </IonItem>
              {paidRevenueApiData.map((paid_event: any, index: number) => {
                let adjustments =
                  paid_event.adjustments && paid_event?.adjustments.length > 0
                    ? paid_event.adjustments
                    : [];
                return (
                  <EventRevenueCard
                    key={index}
                    payout_str={paid_event?.payout_str}
                    adjustments={adjustments}
                    event_name={paid_event?.event_name}
                    date={paid_event?.date}
                    time={paid_event?.time}
                    pay_date={paid_event?.pay_date}
                  />
                );
              })}
            </div>
          )}

          {pendingRevenueApiData.length > 0 && (
            <div className="paid-revenue-container">
              <IonItem className="total-revenu__title">
                <IonTitle>Pending Revenue</IonTitle>
              </IonItem>
              {pendingRevenueApiData.map((paid_event: any, index: number) => {
                let adjustments =
                  paid_event.adjustments && paid_event?.adjustments.length > 0
                    ? paid_event.adjustments
                    : [];
                return (
                  <EventRevenueCard
                    key={index}
                    payout_str={paid_event?.payout_str}
                    adjustments={adjustments}
                    event_name={paid_event?.event_name}
                    date={paid_event?.date}
                    time={paid_event?.time}
                    pay_date={paid_event?.pay_date}
                  />
                );
              })}
            </div>
          )}
        </>
      </IonContent>
      <Loading showLoading={waitingForApiResonse} />
    </IonPage>
  );
};

export default PaidRevenue;

import React, { useState } from "react";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import {
  chevronForwardOutline,
} from "ionicons/icons";
import "@splidejs/splide/dist/css/splide.min.css";
import "./MySettings.css";
import { useHistory } from "react-router";
import bell from "../../../assets/images/common/bell-con.svg";
import lock from "../../../assets/images/common/lock-icon.svg";
import logout from "../../../assets/images/common/logout-icon.svg";
import toggleLeft from "../../../assets/images/common/toggle-icon.svg";
import userOff from "../../../assets/images/common/user-delete.svg";
import right_chevron from "../../../assets/images/common/grey-chevron-right.svg";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import DeleteAccountRequest from "../DeleteAccountRequest/DeleteAccountRequest";

const MySettings: React.FC = () => {

  const [isOpenDeleteAccount, setIsOpenDeleteAccount] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateTo = () =>{
    history.push("/ChangePassword");
  }

  const closeDeleteAcctountRequest = () => {
    setIsOpenDeleteAccount(false);
  };


  const goToNotification = () =>{
    history.push("/NotificationDashBoard");
  }

  const deleteAccount = () => {
    history.push("/DeleteAccountRequest");
  } 

  const logoutHandler = () => {
    dispatch(authActions.logout());
  } 

  return (
    <IonPage >
      <SimpleHeaderView
          title="Settings"
          bgcolor="primary"
          buttonType="undefined"
        ></SimpleHeaderView>
      <IonContent>
      <IonCard className="my_setting_selection_view">
            <IonItem className="my_setting_items" onClick={() => navigateTo()}>
              <img src={lock} alt="" slot="start" />
                <IonText className="mysetting_label">
                  Change Password
                </IonText>
                <IonIcon 
                  className="my_setting_selection_icon"
                  icon={right_chevron}
                  slot="end"
                />
              </IonItem>
            <IonItem className="my_setting_items" onClick={() => goToNotification()}>
              <img src={bell} alt="" slot="start"/>
                <IonText className="mysetting_label">
                  Notifications
                </IonText>
                <IonIcon
                  className="my_setting_selection_icon"
                  icon={right_chevron}
                  slot="end"
                />
            </IonItem>
            {/* <IonItem className="my_setting_items border_none">
              <img src={toggleLeft} alt="" slot="start" />
                <IonText className="mysetting_label">
                  Profile Settings
                </IonText>
                <IonIcon
                  className="my_setting_selection_icon"
                  icon={right_chevron}
                  slot="end"
                />
            </IonItem> */}
        </IonCard>

        <IonCard className="my_setting_selection_view">
            <IonItem className="my_setting_items" onClick={() => logoutHandler()}>
              <img src={logout} alt="" slot="start" />
              <IonItem className="ion-no-padding" >
                <IonText className="mysetting_label" slot="start">
                  Sign Out
                </IonText>
              </IonItem>
            </IonItem>
            <IonItem className="my_setting_items border_none delete_item" onClick={() => setIsOpenDeleteAccount(true)}>
              <img src={userOff} alt="" slot="start" />
                <IonText className="mysetting_label_delete" slot="start">
                  Delete Account
                </IonText>
            </IonItem>
        </IonCard>
      </IonContent>
      <DeleteAccountRequest isOpen_delete_account={isOpenDeleteAccount} closeDeleteAcctountRequest={closeDeleteAcctountRequest} />
    </IonPage>
  );
};

export default MySettings;

import React from "react";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import close_x_circle from "../../assets/images/common/close_x_circle.png";
import { useHistory } from "react-router";
type claimRewars = {
  id: any;
  message: any;
  markAppNotificationAsSeen: Function;
  link?: any;
  icon_name: any;
  title?: any;
};


const InAppNotification: React.FC<claimRewars> = (props) => {
  const { id, message, markAppNotificationAsSeen, link, icon_name, title } = props;
  const pushNotificationsIconsPath =
    "/images/push_notifications_icons/";
  let history: any = useHistory();

  const handleLinkClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <IonGrid className="cus_notication__card__gird" >
      <IonRow>
        <IonCol
          size="1"
          size-xs="2"
          size-sm="1"
          className="notication__card__gird__left_col"
        >
          <img
            src={pushNotificationsIconsPath + icon_name}
            className="app_noti_icon_img noti_icon_img"
            alt=""
          />
        </IonCol>
        <IonCol
          size="11"
          size-xs="10"
          size-sm="11"
          className="notication__card__gird__right_col"
        >
          <IonText>
            <img
              src={close_x_circle}
              className="notification__positive-feedback__closed"
              onClick={() => markAppNotificationAsSeen(id)}
            />
            {title && (
              <div className="notication__card__gird__right_col__title"><strong >{title}</strong></div>
            )}
            <div
              onClick={() => handleLinkClick()}
              className="notification__content"
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            ></div>
          </IonText>

          <br />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default InAppNotification;

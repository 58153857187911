import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { showToastMessage, unauthorizedErrorCode, offlineErrorCode } from "../../common/common";
import FormHeader from "../../push-components/FormHeader/FormHeader";
import ProgressBar from "../../push-components/ProgressBar/ProgressBar";
import {
  getRequiredSignUpInformation,
  updateTalenProfiletData,
} from "../../data-services/signup";
import { logError } from "../../data-services/assignmets";

import "./BecomehModel.css";
import { get } from "../../storage/storage";
const ModalForm: React.FC = () => {
  const history = useHistory();
  const url_params: any = useParams();
  const [mfShowSection, setMfShowSection] = useState({
    step: 1,
    progress_bar: 0.4,
  });
  const [mfRequireSignupInfo, setMfRequireSignupInfo] = useState<any>();
  const [mfGenderBaseField, setMfGenderBaseField] = useState<string>("Female");

  const [talentIntrestObject, setTalentIntrestObject] = useState<any>("");
  const [intrestArCheckBox, setIntrestArCheckBox] = useState<any>([]);

  const [talentTattooObject, setTalentTattooObject] = useState<any>("");
  const [talentTattooCheckbox, setTalentTattooCheckbox] = useState<any>([]);
  const [weightRange, setWeightRange] = useState<any>([]);
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_waist_size: "",
      talent_weight: "",
      talent_hair_color: "",
      talent_eye_color: "",
      talent_dress_size: "",
      talent_bust_size: "",
      talent_cup_size: "",
      talent_hip_size: "",

      talent_suit_size: "",
      talent_neck_size: "",
      talent_chest_size: "",
    },
    mode: "onBlur",
  });
  const afBackStepHandle = async () => {
    let back_step = mfShowSection.step - 1;
    if (back_step) {
      let decrease_bar = mfShowSection.progress_bar - 0.2;
      setMfShowSection({
        step: back_step,
        progress_bar: decrease_bar,
      });
    } else {
      history.push(`/BecomeModel/${url_params.talent_signup_id}`);
    }
  };
  const afNextStepHandler = async () => {
    //  check if there any validations error
    if (Object.keys(errors).length === 0) {
      let next_step = mfShowSection.step + 1;
      let increase_bar = mfShowSection.progress_bar + 0.2;
      setMfShowSection({
        step: next_step,
        progress_bar: increase_bar,
      });
    }
  };
  const modelFormHandler = async () => {
    if (mfShowSection.step >= 4) {
      // 4 is last step of form so if last step is complete then call the API
      await onSubmitModelForm();
    }
    afNextStepHandler();
  };

  const onSubmitModelForm = async () => {
    let tattoo_array: any = [];
    for (let index = 0; index < talentTattooCheckbox.length; index++) {
      const element = talentTattooCheckbox[index];
      tattoo_array.push(talentTattooObject[element]);
    }
    let modal_form_data: any = {
      model_intrest: intrestArCheckBox.toString(),
      model_hair_color_id: getValues("talent_hair_color"),
      model_eye_color: getValues("talent_eye_color"),
      model_dress_size_id: getValues("talent_dress_size"),
      model_bust_size_id: getValues("talent_bust_size"),
      model_bust_cup_id: getValues("talent_cup_size"),
      model_hip_size: getValues("talent_hip_size"),
      model_waist_id: getValues("talent_waist_size"),
      model_weight: getValues("talent_weight"),
      model_suit_size_id: getValues("talent_suit_size"),
      model_neck_size_id: getValues("talent_neck_size"),
      model_chest_id: getValues("talent_chest_size"),
      tattoos: tattoo_array,
      model_user_id: url_params.talent_signup_id,
    };
    try {
      let singup_api_response = await updateTalenProfiletData({
        model: modal_form_data,
      });
      if (singup_api_response?.success) {
        history.push(`/BecomeInfluencer/${url_params.talent_signup_id}`);
      } else if (
        singup_api_response?.data?.errorCode == unauthorizedErrorCode
      ) {
        showToastMessage(
          "An error has occured! Please log in to proceed with profile updates."
        );
      } else if (singup_api_response?.data?.errorCode == offlineErrorCode) {
        showToastMessage(singup_api_response?.message);
      } else if (singup_api_response?.message) {
        await showToastMessage(singup_api_response.data.message);
      } else {
        showToastMessage(
          "An error has occured! Please log in to proceed with profile updates."
        );
      }
    } catch (e: any) {
      showToastMessage(
        "An error has occured! Please log in to proceed with profile updates."
      );
      await logError(
        "Become Model - onSubmitModelForm - Exception thrown",
        "Signup - Become Model",
        JSON.stringify(e.message)
      );
    }
  };
  const intrestValueHandler = (e: any) => {
    let element = document.querySelector(`#intrest_label${e.detail.value}`);
    if (e.detail.checked) {
      if (element) {
        element.classList.add("model-form__checkbox--label");
      }
      intrestArCheckBox.push(e.detail.value);
    } else {
      if (element) {
        element.classList.remove("model-form__checkbox--label");
      }
      let index_of = intrestArCheckBox.indexOf(e.detail.value);
      intrestArCheckBox.splice(index_of, 1);
    }

    setIntrestArCheckBox(intrestArCheckBox);
  };
  const tattooValueHandler = (e: any) => {
    let element = document.querySelector(`#tattoo_label${e.detail.value}`);
    if (e.detail.checked) {
      if (element) {
        element.classList.add("model-form__checkbox--label");
      }
      talentTattooCheckbox.push(e.detail.value);
    } else {
      if (element) {
        element.classList.remove("model-form__checkbox--label");
      }
      let index_of = talentTattooCheckbox.indexOf(e.detail.value);
      talentTattooCheckbox.splice(index_of, 1);
    }

    setTalentTattooCheckbox(talentTattooCheckbox);
  };
  useEffect(() => {
    (async () => {
      setMfGenderBaseField(await get("login_model_gender"));
      let mf_required_info = await getRequiredSignUpInformation();
      setMfRequireSignupInfo(mf_required_info);
      setTalentIntrestObject(
        mf_required_info.commonGenderFields.model_intrest_ar_model
      );
      setTalentTattooObject(mf_required_info.commonGenderFields.model_tattoos);
      setWeightRange(mf_required_info.commonGenderFields.model_weight);
    })();
  }, []);
  return (
    <IonPage>
      <FormHeader title="" backStepHandle={afBackStepHandle} />
      <ProgressBar progress_value={mfShowSection.progress_bar} />

      <IonContent>
        <form
          onSubmit={handleSubmit(modelFormHandler)}
          className="model-form__form"
        >
          {mfShowSection.step === 1 && (
            <section className="model-form__section-1">
              <div>
                <div className="become-model__title model-form__align">
                  <h1>
                    What are you <br /> interested in doing?
                  </h1>
                </div>
                <div className="become-model__description model-form__align">
                  Select all the types of jobs that you would be interested in
                  doing. You may update this information in your profile
                  settings.
                </div>
                {talentIntrestObject &&
                  Object.keys(talentIntrestObject).map(
                    (key: string, index: number) => (
                      <IonCard mode="md" key={index}>
                        <IonItem>
                          <label
                            id={`intrest_label${key}`}
                            className="model-form-form__card__label"
                          >
                            {talentIntrestObject[key]}
                          </label>
                          <IonCheckbox
                            className="form-group__checkbox-styles"
                            mode="md"
                            slot="start"
                            value={key}
                            checked={intrestArCheckBox.includes(key)}
                            onIonChange={(e: any) => {
                              intrestValueHandler(e);
                            }}
                          ></IonCheckbox>
                        </IonItem>
                      </IonCard>
                    )
                  )}
              </div>
              <div className="model-form__footer">
                <div className="model-form__next--btn">
                  <IonButton
                    mode="ios"
                    expand="block"
                    type="submit"
                    className="form-group__button"
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            </section>
          )}

          {mfShowSection.step === 2 && (
            <section className="model-form__section-2">
              <div>
                <div className="become-model__title model-form__align">
                  <h1>
                    Select your hair and <br />
                    eye colors
                  </h1>
                </div>
                <div className="become-model__description model-form__align">
                  Updating your hair and eye colors helps qualify you for more
                  opportunities. You may update this information in your profile
                  settings.
                </div>

                <div className="form-group">
                  <IonLabel className="form-group__label form-group__label--required">
                    Hair Color
                  </IonLabel>
                  <IonSelect
                    mode="ios"
                    placeholder="--Select--"
                    className={
                      errors.talent_hair_color !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("talent_hair_color", {
                      required: "This field is required.",
                    })}
                  >
                    {mfRequireSignupInfo &&
                      Object.keys(
                        mfRequireSignupInfo.commonGenderFields
                          .model_hair_color_id
                      ).map((hair_color: any) => (
                        <IonSelectOption key={hair_color} value={hair_color}>
                          {
                            mfRequireSignupInfo.commonGenderFields
                              .model_hair_color_id[hair_color]
                          }
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="talent_hair_color"
                    as={<div className="error-message" />}
                  />
                </div>

                <div className="form-group">
                  <IonLabel className="form-group__label form-group__label--required">
                    Eye Color
                  </IonLabel>
                  <IonSelect
                    mode="ios"
                    placeholder="--Select--"
                    className={
                      errors.talent_eye_color !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("talent_eye_color", {
                      required: "This field is required.",
                    })}
                  >
                    {mfRequireSignupInfo &&
                      Object.keys(
                        mfRequireSignupInfo.commonGenderFields.model_eye_color
                      ).map((eye_color: any) => (
                        <IonSelectOption key={eye_color} value={eye_color}>
                          {mfRequireSignupInfo.commonGenderFields.model_eye_color[
                            eye_color
                          ]
                            .charAt(0)
                            .toUpperCase() +
                            mfRequireSignupInfo.commonGenderFields.model_eye_color[
                              eye_color
                            ].slice(1)}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="talent_eye_color"
                    as={<div className="error-message" />}
                  />
                </div>
              </div>
              <div className="model-form__footer">
                <div className="model-form__next--btn">
                  <IonButton
                    mode="ios"
                    expand="block"
                    type="submit"
                    className="form-group__button"
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            </section>
          )}

          {mfShowSection.step === 3 && (
            <section className="model-form__section-3">
              <div className="become-model__title model-form__align">
                <h1>
                  Share your <br /> measurements
                </h1>
              </div>
              <div className="become-model__description model-form__align">
                Add your basic measurements to get more job recommendations. You
                may add other details in your profile settings.
              </div>
              {mfGenderBaseField === "Male" && (
                <>
                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Suit Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_suit_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_suit_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.maleSpecificFields
                            .model_suit_size_id
                        ).map((suit_size: any) => (
                          <IonSelectOption key={suit_size} value={suit_size}>
                            {
                              mfRequireSignupInfo.maleSpecificFields
                                .model_suit_size_id[suit_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_suit_size"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Neck Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_neck_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_neck_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.maleSpecificFields
                            .model_neck_size_id
                        ).map((neck_size: any) => (
                          <IonSelectOption key={neck_size} value={neck_size}>
                            {
                              mfRequireSignupInfo.maleSpecificFields
                                .model_neck_size_id[neck_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_neck_size"
                      as={<div className="error-message" />}
                    />
                  </div>

                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Chest Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_chest_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_chest_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.maleSpecificFields.model_chest_id
                        ).map((chest_size: any) => (
                          <IonSelectOption key={chest_size} value={chest_size}>
                            {
                              mfRequireSignupInfo.maleSpecificFields
                                .model_chest_id[chest_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_chest_size"
                      as={<div className="error-message" />}
                    />
                  </div>
                </>
              )}

              {mfGenderBaseField === "Female" && (
                <>
                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Dress Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_dress_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_dress_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.femaleSpecificFields
                            .model_dress_size_id
                        ).map((dress_size: any) => (
                          <IonSelectOption key={dress_size} value={dress_size}>
                            {
                              mfRequireSignupInfo.femaleSpecificFields
                                .model_dress_size_id[dress_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_dress_size"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Bust Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_bust_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_bust_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.femaleSpecificFields
                            .model_bust_size_id
                        ).map((bust_size: any) => (
                          <IonSelectOption key={bust_size} value={bust_size}>
                            {
                              mfRequireSignupInfo.femaleSpecificFields
                                .model_bust_size_id[bust_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_bust_size"
                      as={<div className="error-message" />}
                    />
                  </div>

                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Cup Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_cup_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_cup_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.femaleSpecificFields
                            .model_bust_cup_id
                        ).map((cup_size: any) => (
                          <IonSelectOption key={cup_size} value={cup_size}>
                            {
                              mfRequireSignupInfo.femaleSpecificFields
                                .model_bust_cup_id[cup_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_cup_size"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label form-group__label--required">
                      Hip Size
                    </IonLabel>
                    <IonSelect
                      mode="ios"
                      placeholder="--Select--"
                      className={
                        errors.talent_hip_size !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_hip_size", {
                        required: "This field is required.",
                      })}
                    >
                      {mfRequireSignupInfo &&
                        Object.keys(
                          mfRequireSignupInfo.femaleSpecificFields
                            .model_hip_size
                        ).map((hip_size: any) => (
                          <IonSelectOption key={hip_size} value={hip_size}>
                            {
                              mfRequireSignupInfo.femaleSpecificFields
                                .model_hip_size[hip_size]
                            }
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_hip_size"
                      as={<div className="error-message" />}
                    />
                  </div>
                </>
              )}

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Waist Size
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_waist_size !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_waist_size", {
                    required: "This field is required.",
                  })}
                >
                  {mfRequireSignupInfo &&
                    Object.keys(
                      mfRequireSignupInfo.commonGenderFields.model_waist_id
                    ).map((waist_size: any) => (
                      <IonSelectOption key={waist_size} value={waist_size}>
                        {
                          mfRequireSignupInfo.commonGenderFields.model_waist_id[
                            waist_size
                          ]
                        }
                      </IonSelectOption>
                    ))}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_waist_size"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Weight
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_weight !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_weight", {
                    required: "This field is required.",
                  })}
                >
                  {weightRange.map((weight: any) => (
                    <IonSelectOption key={weight} value={weight}>
                      {weight} lbs
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_weight"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="model-form__footer">
                <div className="model-form__next--btn">
                  <IonButton
                    mode="ios"
                    expand="block"
                    type="submit"
                    className="form-group__button"
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            </section>
          )}
          {mfShowSection.step === 4 && (
            <section className="model-form__section-4">
              <div className="become-model__visible-form">
                <div className="become-model__title model-form__align">
                  <h1>Share any visible tattoos</h1>
                </div>
                <div className="become-model__description model-form__align">
                  This information will not qualify or disqualify you.
                </div>
                {talentTattooObject &&
                  Object.keys(talentTattooObject).map(
                    (tattoo: any, index: number) => (
                      <IonCard mode="md" key={index}>
                        <IonItem>
                          <label
                            id={`tattoo_label${tattoo}`}
                            className="model-form-form__card__label"
                          >
                            {talentTattooObject[tattoo]}
                          </label>
                          <IonCheckbox
                            className="form-group__checkbox-styles"
                            mode="md"
                            slot="start"
                            value={tattoo}
                            checked={talentTattooCheckbox.includes(tattoo)}
                            onIonChange={(e: any) => tattooValueHandler(e)}
                          ></IonCheckbox>
                        </IonItem>
                      </IonCard>
                    )
                  )}
              </div>
              <div className="model-form__footer">
                <div className="model-form__next--btn">
                  <IonButton
                    mode="ios"
                    expand="block"
                    type="submit"
                    className="form-group__button"
                  >
                    Finish
                  </IonButton>
                </div>
              </div>
            </section>
          )}
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ModalForm;

import React, { Component, useEffect } from "react";
import "./jquery.fileuploader.min.css";
import "./jquery.fileuploader-theme-thumbnails.css";
import "./jquery.fileuploader-theme-thumbnails-hoampage.css";
import './jquery.fileuploader-theme-cropping-popup.css';

const $ = window.jQuery;
let fullbody_crop_size =  {
  showGrid: false,
  ratio: "0.664:1",
  width: 208,
  height: 313,
}
let headshot_crop_size =  {
  showGrid: false,
  ratio: "1.64:1",
  width: 260,
  height: 158,
}
let default_crop_size =  {
  // ratio: "1:1",
  minWidth: 100,
  minHeight: 100,
  showGrid: true,
}

class ProfileFileuploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      props: props,
      name: "files",
      id: props?.id,
      options: {
        files: props?.files,
        extensions: null,
        changeInput: " ",
        enableApi: true,
        addMore: true,
        theme: "thumbnails",
        thumbnails: {
          box:
            '<div class="fileuploader-items">' +
            '<ul class="fileuploader-items-list">' +
            '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner"><i>+</i></div></li>' +
            "</ul>" +
            "</div>",
          item:
            '<li class="fileuploader-item">' +
            '<div class="fileuploader-item-inner">' +
            '<div class="type-holder">${extension}</div>' +
            '<div class="actions-holder">' +
            '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
            "</div>" +
            '<div class="thumbnail-holder">' +
            "${image}" +
            "</div>" +
            '<div class="content-holder"><h5>${name}</h5><span>${size2}</span></div>' +
            '<div class="progress-holder">${progressBar}</div>' +
            "</div>" +
            "</li>",
          item2:
            '<li class="fileuploader-item">' +
            '<div class="fileuploader-item-inner">' +
            '<div class="type-holder">${extension}</div>' +
            '<div class="actions-holder">' +
            '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i class="fileuploader-icon-download"></i></a>' +
            '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
            "</div>" +
            '<div class="thumbnail-holder">' +
            "${image}" +
            "</div>" +
            '<div class="content-holder"><h5 title="${name}">${name}</h5><span>${size2}</span></div>' +
            '<div class="progress-holder">${progressBar}</div>' +
            "</div>" +
            "</li>",
          startImageRenderer: true,
          canvasImage: false,
          _selectors: {
            list: ".fileuploader-items-list",
            item: ".fileuploader-item",
            start: ".fileuploader-action-start",
            retry: ".fileuploader-action-retry",
            remove: ".fileuploader-action-remove",
          },
          onImageLoaded: function(item, listEl, parentEl, newInputEl, inputEl) {
            if (item.choosed && !item.isSaving) {
                if(item?.editor) {
                  // item.image.hide();
                  item.popup.open();
                  item.editor.cropper();
              } else {
              }
    } else if (item.data.isDefault)
      item.html.addClass('is-default');
    else if (item.image.hasClass('fileuploader-no-thumbnail'))
      item.html.hide();
        },
          onItemShow: function (item, listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find(".fileuploader-thumbnails-input"),
              api = $.fileuploader.getInstance(inputEl.get(0));

            // plusInput.insertAfter(item.html)[api.getOptions().limit && api.getChoosedFiles().length >= api.getOptions().limit ? 'hide' : 'show'](); actul code
            plusInput
              .insertAfter(item.html)
              [
                api.getOptions().limit &&
                api.getChoosedFiles().length >= api.getOptions().limit
                  ? "hide"
                  : "hide"
              ](); // hide in both condition

            if (item.format == "image") {
              item.html.find(".fileuploader-item-icon").hide();
            }
          },
          onItemRemove: function (html, listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find(".fileuploader-thumbnails-input"),
              api = $.fileuploader.getInstance(inputEl.get(0));
              if (props.id) {
                props.click(props.id, "remove", inputEl);
              }
            html.children().animate({ opacity: 0 }, 200, function () {
              html.remove();

              if (
                api.getOptions().limit &&
                api.getChoosedFiles().length - 1 < api.getOptions().limit
              )
                plusInput.show();
            });
          },
        },
        dragDrop: {
          container: ".fileuploader-thumbnails-input",
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
          var plusInput = listEl.find(".fileuploader-thumbnails-input"),
            api = $.fileuploader.getInstance(inputEl.get(0));

          plusInput.on("click", function () {
            api.open();
          });
          api.getOptions().dragDrop.container = plusInput;
        },
        // by default - false
        editor: {
          // editor cropper
          cropper: (props.cropp_ratio === "headshot") ? headshot_crop_size : (props.cropp_ratio === "fullbody") ? fullbody_crop_size : default_crop_size,

          // editor on save quality (0 - 100)
          // only for client-side resizing
          quality: null,

          // editor on save maxWidth in pixels
          // only for client-side resizing
          maxWidth: 1920,

          // editor on save maxHeight in pixels
          // only for client-size resizing
          maxHeight: 1080,

          // Callback fired after saving the image in editor
          onSave: function (
            blobOrDataUrl,
            item,
            listEl,
            parentEl,
            newInputEl,
            inputEl
          ) {
        
          
          },
        },
      },
    };

    if (props && Array.isArray(props)) {
      this.state.name = props[0];
      this.state.options = props[1];
    } else if (props && typeof props === "object") {
      this.state.name = props.name;
      for (var key in props) {
        var val = props[key];

        if (typeof val != "string") continue;
        if (
          ["limit", "maxSize", "fileMaxSize", "theme", "listInput"].indexOf(
            key
          ) > -1
        )
          this.state.options[key] = val;
        if ("extensions" == key)
          this.state.options[key] = val.replace(/ /g, "").split(",");
        if ("files" == key) {
          this.state.options[key] = JSON.parse(val);
        }
      }
      if (props["disabled"]) this.state.options["limit"] = 0;
    }
  }

  componentDidMount() {
    this.$el = $(this.el);
    this.$el.fileuploader(
      $.extend(this.state.options, {
        enableApi: true,
      })
    );
    this.api = $.fileuploader.getInstance(this.$el);
  }

  componentWillUnmount() {
    if (this.api) this.api.destroy();
  }

  render() {
    let image = this.props?.preloaded_file || "";
    return (
        <input
        type="file"
        name={this.state.name}
        ref={(el) => (this.el = el)}
        // data-fileuploader-files={image}
        id={this.props.id}
      />
    );
  }
}

export default ProfileFileuploader;

import React, { useEffect, useState, useRef } from "react";

const Chat: React.FC = () => {
    const groupbymessages = () => {
      return;
      // const msglist: any = JSON.parse(localStorage.getItem("messages") || "");

      // if (msglist && msglist != "") {
      //   const groups = msglist.reduce((groups: any, message: any) => {
      //     const fulldate: any = new Date(message.CreatedAt.seconds * 1000);
      //     const todaydate: any = new Date().toISOString().slice(0, 10);
      //     let date: any = fulldate.toISOString().slice(0, 10);

      //     if (date == todaydate) {
      //       date = "today";
      //     }

      //     var h = fulldate.getHours();
      //     var m = fulldate.getMinutes();
      //     if (h < 10) h = "0" + h;
      //     if (m < 10) m = "0" + m;

      //     const time: any = h + ":" + m;
      //     if (!groups[date]) {
      //       groups[date] = [];
      //     }
      //     message.time = time;
      //     groups[date].push(message);
      //     return groups;
      //   }, {});

      //   const groupArrays = Object.keys(groups).map((date) => {
      //     return {
      //       date,
      //       messages: groups[date],
      //     };
      //   });
      //   setMessageList(groupArrays);
      // }
    };

  return (
    <>
    </>
  );
};
export default Chat;
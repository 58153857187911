import React, { useState } from "react";
import { IonItem, IonText, IonTextarea } from "@ionic/react";
import "./InputTypes.css";
type componentProps = {
  question: string;
  answer?: string;
  question_id: number;
  options: string;
  questionno?: number;
  requiredmessage?: boolean;
  allowHtmlQuestion?:any;
};
const InputTypeTextArea: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    allowHtmlQuestion
  } = Props;
  const [textAreaValue, settextAreaValue] = useState<any>(answer);

  return (
    <div className="take-test-question">
      <div className="take-test-question__label">
          {/* {questionno && <>{questionno}. </>} */}
          {allowHtmlQuestion ? (
            <span
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            ></span>
          ) : (
            <>{question}</>
          )}
        </div>
      <IonTextarea
        rows={4}
        placeholder="Answer"
        name={"answer_" + question_id.toString()}
        className={
          requiredmessage == true
            ? "form-input-styles-error"
            : "form-group__input-styles"
        }
        value={textAreaValue}
        onIonChange={(e: any) => settextAreaValue(e.detail.value)}
      ></IonTextarea>
      {requiredmessage == true && (
        <div className="error-message">This field is required.</div>
      )}
    </div>
  );
};

export default InputTypeTextArea;

import React, { useState } from "react";
import { IonItem, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import "./InputTypes.css";
type componentProps = {
  question: string;
  question_id: number;
  options: string;
  questionno?: number;
  requiredmessage?: boolean;
  answer?: string;
  allowHtmlQuestion?: any;
};
const InputTypeSelect: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    allowHtmlQuestion
  } = Props;
  const [selectAnswerValue, setSelectAnswerValue] = useState<any>(answer);

  return (
    <div className="take-test-question">
      <div className="take-test-question__label">
          {/* {questionno && <>{questionno}. </>} */}
          {allowHtmlQuestion ? (
            <span
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            ></span>
          ) : (
            <>{question}</>
          )}
        </div>
      <IonSelect
        mode="ios"
        name={"answer_" + question_id.toString()}
        placeholder="--Select--"
        className={
          requiredmessage == true
            ? "form-input-styles-error"
            : "form-group__input-styles"
        }
        value={selectAnswerValue}
        onIonChange={(e: any) => setSelectAnswerValue(e.detail.value)}
      >
        {options &&
          options.split("||").map((data: any, index: number) => (
            <IonSelectOption key={index} value={data}>
              {data}
            </IonSelectOption>
          ))}
      </IonSelect>
      {requiredmessage == true && (
        <div className="error-message">This field is required.</div>
      )}
    </div>
  );
};

export default InputTypeSelect;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonPage,
  IonText,
} from "@ionic/react";
import "./BecomeInfluencer.css";
import FormHeader from "../../push-components/FormHeader/FormHeader";
import ProgressBar from "../../push-components/ProgressBar/ProgressBar";
import facebook from "../../assets/images/common/brand-facebook.png";
import instagram from "../../assets/images/common/brand-instagram.png";
import linkedin from "../../assets/images/common/brand-linkedin.png";
import twitter from "../../assets/images/common/brand-twitter-new.png";
import tiktok from "../../assets/images/common/brand-tik-tok.png";
import threads from "../../assets/images/common/brand-threads.svg";
import youtube from "../../assets/images/common/brand-youtube.png";
import pinterest from "../../assets/images/common/pinterest.svg";
import periscope from "../../assets/images/common/periscope.svg";
import snapchat from "../../assets/images/common/snapchat.svg";
import yelp from "../../assets/images/common/yelp.svg";
import { useHistory, useParams } from "react-router";
import { getSocialMediaPlatforms } from "../../data-services/signup";
import AddSocialPlatforms from "../../push-components/AddSocialPlatforms/AddSocialPlatforms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { authActions } from "../../store/auth";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";

const eye = <FontAwesomeIcon icon={faEyeSlash} />;
// http://localhost:8100/InfluencerForm/159989
const InfluencerForm: React.FC = () => {
  const dispatch = useDispatch();
  const url_params: any = useParams();
  const history = useHistory();
  const [socialPlatforms, setSocialPlatforms] = useState<any>();
  const [orignalApiObject, setOrignalApiObject] = useState<any>([]);
  const [addRemovePlatform, setAddRemovePlatform] = useState<{
    id: string;
    type: string;
    title: string;
  }>({ id: "", type: "", title: "" });
  const [openPlatformModal, setOpenPlatformModal] = useState<boolean>(false);
  const backStepHandle = () => {
    history.push(`/BecomeInfluencer/${url_params.talent_signup_id}`);
  };
  const ifInit = async () => {
    let platforms: any = await getSocialMediaPlatforms();
    if (!platforms?.success) {
      if (platforms?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (platforms?.data?.errorCode == offlineErrorCode) {
        showToastMessage(platforms?.data?.message);
      } else if (platforms?.message) {
        showToastMessage(platforms.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    setSocialPlatforms(platforms.changed_array);
    setOrignalApiObject(platforms.orignal_array);
  };
  const connectToPlatform = (id: string = "", type: string = "", title: string = "") => {
    setAddRemovePlatform({ id: id, type: type, title: title });
    setOpenPlatformModal(true);
  };
  const closePlatformModal = async () => {
    await ifInit();
    setOpenPlatformModal(false);
  };
  useEffect(() => {
    (async () => {
      await ifInit();
    })();
  }, []);
  return (
    <IonPage>
      <FormHeader title="" backStepHandle={backStepHandle} />
      <ProgressBar progress_value={1} />
      <IonContent>
        <div className="influencer-form">
          <div className="influencer-form__title">
            <h1>
              Connect your social <br />
              media networks
            </h1>
          </div>
          {socialPlatforms && (
            <>
              <IonCard mode="md">
                <IonItem>
                  <img className="influencer-form__twitter-icon" src={twitter} alt="" slot="start" />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.twitter.smp_name}
                  </IonText>
                  {socialPlatforms?.twitter.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.twitter.smp_id,
                          "connect",
                          socialPlatforms?.twitter.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <img src={instagram} alt="" slot="start" />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.instagram.smp_name}
                  </IonText>
                  {socialPlatforms?.instagram.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.instagram.smp_id,
                          "connect",
                          socialPlatforms?.instagram.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <img src={facebook} alt="" slot="start" />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.facebook.smp_name}
                  </IonText>
                  {socialPlatforms?.facebook.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.facebook.smp_id,
                          "connect",
                          socialPlatforms?.facebook.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <img src={youtube} alt="" slot="start" />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.youtube.smp_name}
                  </IonText>
                  {socialPlatforms?.youtube.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.youtube.smp_id,
                          "connect",
                          socialPlatforms?.youtube.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <img src={linkedin} alt="" slot="start" />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.linkedin.smp_name}
                  </IonText>
                  {socialPlatforms?.linkedin.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.linkedin.smp_id,
                          "connect",
                          socialPlatforms?.linkedin.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>

              <IonCard mode="md">
                <IonItem>
                  <IonIcon
                    src={pinterest}
                    slot="start"
                    className="influencer-form__social_icon"
                  />

                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.pinterest.smp_name}
                  </IonText>
                  {socialPlatforms?.pinterest.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.pinterest.smp_id,
                          "connect",
                          socialPlatforms?.pinterest.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>

              <IonCard mode="md">
                <IonItem>
                  <IonIcon
                    src={snapchat}
                    slot="start"
                    className="influencer-form__social_icon"
                  />

                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.snapchat.smp_name}
                  </IonText>
                  {socialPlatforms?.snapchat.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.snapchat.smp_id,
                          "connect",
                          socialPlatforms?.snapchat.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <IonIcon
                    src={yelp}
                    slot="start"
                    className="influencer-form__social_icon"
                  />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.yelp.smp_name}
                  </IonText>
                  {socialPlatforms?.yelp.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.yelp.smp_id,
                          "connect",
                          socialPlatforms?.yelp.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <img className="influencer-form__tiktok-icon" src={tiktok} alt="" slot="start" />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.tiktok.smp_name}
                  </IonText>
                  {socialPlatforms?.tiktok.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.tiktok.smp_id,
                          "connect",
                          socialPlatforms?.tiktok.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <IonCard mode="md">
                <IonItem>
                  <IonIcon
                    src={threads}
                    slot="start"
                    className="influencer-form__social_icon"
                  />
                  <IonText className="influencer-form__label" slot="start">
                    {socialPlatforms?.threads.smp_name}
                  </IonText>
                  {socialPlatforms?.threads.status === "connected" ? (
                    <IonText
                      className="influencer-form__label-green"
                      slot="end"
                    >
                      Connected
                    </IonText>
                  ) : (
                    <IonText
                      className="influencer-form__label-orange"
                      slot="end"
                      onClick={() =>
                        connectToPlatform(
                          socialPlatforms.threads.smp_id,
                          "connect",
                          socialPlatforms?.threads.smp_name
                        )
                      }
                    >
                      Connect
                    </IonText>
                  )}
                </IonItem>
              </IonCard>
              <div className="influencer-form__footer">
                <IonButton
                  className="form-group__button"
                  expand="block"
                  mode="ios"
                  type="button"
                  size="large"
                  routerLink={`/ReferFriend/${url_params.talent_signup_id}`}
                >
                  Finish
                </IonButton>
              </div>
            </>
          )}
        </div>
      </IonContent>
      <AddSocialPlatforms
        isOpenPlatformModal={openPlatformModal}
        closePlatformModal={closePlatformModal}
        updateInfo={addRemovePlatform}
        orignalApiObject={orignalApiObject}
      />
    </IonPage>
  );
};

export default InfluencerForm;

import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonButton,
  IonFooter,
  IonText,
  IonModal,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import "./SuccessScreen.css";
import bigCheckedIcon from "../../assets/images/common/big-checked-icon.png";

import backArrow from "../../assets/images/common/arrow-left.png";
type ModalProps = {
  isopen_ss_modal: boolean;
  closeSsModal: Function;
  redirect_to:string
};
const SuccessScreens: React.FC<ModalProps> = (Props) => {
  const { isopen_ss_modal, closeSsModal,redirect_to } = Props;
  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <IonModal isOpen={isopen_ss_modal} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => closeSsModal()}>
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="opportunities-survey__success-screen">
          <img
            className="opportunities-survey__success-icon"
            src={bigCheckedIcon}
            alt=""
          />
          <h2>Confirmation Test</h2>
          <IonText>
            You have passed the confirmation test required to participate in
            this event. You may continue to view assignment details.
          </IonText>
          <IonButton
            className="opportunities-survey__success-footer form-group__button"
            expand="block"
            mode="ios"
            type="button"
            routerLink={redirect_to}>
            Continue
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SuccessScreens;

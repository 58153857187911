import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import { Network } from "@capacitor/network";
import { noInternetResponse, unauthorizedResponse } from "../common/common";

export const getTalentRevenue = async (
  start_date: string,
  end_date: string
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/getRevenue`;
    if (start_date !== "" && start_date !== "") {
      api_url = `${api_url}?start_date=${start_date}&end_date=${end_date}`;
    }
    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let revenu_api_res = await CapacitorHttp.get(config);
    // TO DO: Update the API to return a proper success/ message/ data response and make sure both this APP and API are on the same version
    return {success: true, message: '', data : revenu_api_res.data};
  } else {
    return noInternetResponse;
  }
};

import React from "react";
import {
  IonModal,
  IonContent,
  IonIcon,
} from "@ionic/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./ProfileView.css";
import close_icon from '../../assets/images/common/close_x_circle.png'
import { closeOutline } from "ionicons/icons";
type ModalProps = {
  photosArray: any;
  isOpen: boolean;
  closeAction: any;
};


const TalentPhotosModal: React.FC<ModalProps> = (props) => {
  const { photosArray, isOpen, closeAction } = props;

  return (
    <IonModal backdropDismiss={false} isOpen={isOpen}  mode="ios" cssClass="profile_photo_slider">
      
      <IonContent>
      <img
            className="profile_photo_slider_icon"
            src={close_icon}
            onClick={closeAction}
            alt="icon-only"
          />
          <Splide
            options={{
              arrows: true,
              pagination: false,
              perPage: 1,
              perMove: 1,
              
            }}
          >
            {photosArray &&
              photosArray.map((itm: any, index: any) => (
                <SplideSlide key={index}>
                  <div className="profile_photo_slider_image">
                    <img src={itm.photo} alt="" />
                  </div>
                </SplideSlide>
              ))}
          </Splide>
      </IonContent>
    </IonModal>
  );
};
export default TalentPhotosModal;

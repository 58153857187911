import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonInput,
  IonIcon,
} from "@ionic/react";
import "./MyCrew.css";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import {
  addModelCrewMembers,
  deleteModelCrewMembers,
  getAvailableInternalReferralsList,
  searchCrewTalent,
} from "../../../data-services/edit-profile";
import { searchOutline } from "ionicons/icons";

// Crew + Not Crew unfiltered list from the API
let unfilter_crew_data: any = [];
// Crew unfiltered list from the API
let unfilter_crew: any = [];
// Not Crew unfiltered list from the API
let unfilter_non_crew: any = [];
const MyCrew: React.FC = () => {
  const dispatch = useDispatch();
  const [talentCircle, setTalentCircle] = useState<any>([]);
  const [crewListLoaded, setCrewListLoaded] = useState(false);
  const [crewMembers, setCrewMembers] = useState<any>([]);
  const [nonCrewMembers, setNonCrewMembers] = useState<any>([]);
  const [talentSearchPattern, setTalentSearchPattern] = useState<any>("");
  const [talentSearchLoading, setTalentSearchLoading] =
    useState<boolean>(false);

  const [foundTalent, setFoundTalent] = useState<any>([]);
  const [noTalentFoundErrorMessage, setNoTalentFoundErrorMessage] =
    useState<any>("");

  const addTalnetInCrew = async (talent_id: string) => {
    const button_element = document.getElementById(
      "mycrew_card__btn_" + talent_id
    );
    // Disable the button
    if (button_element) {
      button_element.setAttribute("disabled", "disabled");
    }
    let add_api = await addModelCrewMembers(talent_id);

    if (add_api?.success) {
      await showToastMessage("Crew member added!");
    } else {
      await showToastMessage(somethingWentWrongErrMsg);
    }
    fetchTalentCrewAndFriends({ talent_id: talent_id, action: "add" });
    // Enable it
    if (button_element) {
      button_element.removeAttribute("disabled");
    }
  };
  const removeTalnetFromCrew = async (talent_id: string) => {
    // Disable the button
    const button_element = document.getElementById(
      "mycrew_card__btn_" + talent_id
    );
    if (button_element) {
      button_element.setAttribute("disabled", "disabled");
    }
    let remove_api = await deleteModelCrewMembers(talent_id);

    if (remove_api?.success) {
      await showToastMessage("Crew member removed!");
    } else {
      await showToastMessage(somethingWentWrongErrMsg);
    }
    fetchTalentCrewAndFriends({ talent_id: talent_id, action: "remove" });
    // Enable it
    if (button_element) {
      button_element.removeAttribute("disabled");
    }
  };
  const filterCrewMembers = (ev: any) => {
    const target = ev.target as HTMLIonSearchbarElement;

    if (target.value) {
      let search_str: any = target.value;
      let obj: any = unfilter_crew_data.filter((x: any) =>
        x.model_name.toLowerCase().includes(search_str.toLowerCase())
      );
      setTalentCircle(obj);
    } else {
      setTalentCircle(unfilter_crew_data);
    }
  };
  const fetchTalentCrewAndFriends = async (changed_talent?: any) => {
    let talent_contact: any = [];

    let crew: any = [];
    let non_crew: any = [];

    if (unfilter_crew.length || unfilter_non_crew.length) {
      crew = unfilter_crew;
      non_crew = unfilter_non_crew;
      if (changed_talent?.talent_id && changed_talent?.action) {
        if (changed_talent?.action == "add") {
          // Find the added talent in the Non Crew List
          let talentAddedToCrewList = non_crew.find(
            (item: any) => item.model_user_id == changed_talent.talent_id
          );

          if (!talentAddedToCrewList) {
            talentAddedToCrewList = foundTalent.find(
              (item: any) => item.model_user_id == changed_talent.talent_id
            );
          }
          talentAddedToCrewList.type = "crew";

          // Remove the talent from the Non Crew List
          non_crew = non_crew.filter(
            (item: any) => item.model_user_id != changed_talent.talent_id
          );

          // Add the talent to the Crew List
          crew.push(talentAddedToCrewList);
          setTalentSearchPattern("");
          setFoundTalent([]);
        } else if (changed_talent?.action == "remove") {
          // Find the removed talent in the Crew List
          let talentRemovedFromCrewList = crew.find(
            (item: any) => item.model_user_id == changed_talent.talent_id
          );
          talentRemovedFromCrewList.type = "just_added";

          // Remove the talent from the Crew List
          crew = crew.filter(
            (item: any) => item.model_user_id != changed_talent.talent_id
          );

          // Add the talent to the Non Crew List
          non_crew.push(talentRemovedFromCrewList);
        }
      }
    } else {
      let api_response = await getAvailableInternalReferralsList(
        talent_contact
      );
      if (api_response?.success) {
        // Store Crew members under crew
        // Store Worked in the Past and Contacts (Friends) under non_crew

        for (let i = 0; i < api_response.crew.length; i++) {
          let element = api_response.crew[i];
          crew.push({ ...element, type: "crew" });
        }

        for (let i = 0; i < api_response.worked_in_the_past.length; i++) {
          let element = api_response.worked_in_the_past[i];
          non_crew.push({ ...element, type: "work_in_post" });
        }

        for (let i = 0; i < api_response.friends.length; i++) {
          let element = api_response.friends[i];
          non_crew.push({ ...element, type: "friends" });
        }
      } else if (api_response?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_response?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_response?.data?.message);
      } else if (api_response?.message) {
        showToastMessage(api_response.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }

    // Alphabetically sort the crew persons
    crew.sort(function (a: any, b: any) {
      let nameA = a.model_name.trim().toLowerCase();
      let nameB = b.model_name.trim().toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    // Alphabetically sort the non_crew persons
    non_crew.sort(function (a: any, b: any) {
      let nameA = a.model_name.trim().toLowerCase();
      let nameB = b.model_name.trim().toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    unfilter_crew = crew;
    unfilter_non_crew = non_crew;
    unfilter_crew_data = [...crew, ...non_crew];
    setTalentCircle([...crew, ...non_crew]);
    setCrewMembers(crew);
    setNonCrewMembers(non_crew);

    let search_bttn = document.getElementsByClassName("mycrew_searchbar");
    if (search_bttn?.length) {
      (search_bttn[0] as HTMLInputElement).value = "";
    }
    setCrewListLoaded(true);
  };

  const searchTalent = async () => {
    setNoTalentFoundErrorMessage("");

    if (talentSearchPattern == "" || talentSearchPattern.trim() == "") {
      setNoTalentFoundErrorMessage("Please type the Full Name, Phone or Email");
      return;
    }

    setTalentSearchLoading(true);
    let api_response = await searchCrewTalent(talentSearchPattern);
    if (api_response?.success) {
      let found_talent: any = [];
      for (let i = 0; i < api_response.data.found_talent.length; i++) {
        let element = api_response.data.found_talent[i];
        found_talent.push({ ...element });
      }
      if (!found_talent.length) {
        setNoTalentFoundErrorMessage("No profile found");
      }
      setFoundTalent(found_talent);
    } else if (api_response?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (api_response?.data?.errorCode == offlineErrorCode) {
      showToastMessage(api_response?.data?.message);
    } else if (api_response?.message) {
      showToastMessage(api_response.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setTalentSearchLoading(false);
  };

  const handleSearchTalentInputChange = (event: any) => {
    setTalentSearchPattern(event.target.value);
  };

  useEffect(() => {
    fetchTalentCrewAndFriends();
  }, [talentSearchPattern]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>My Crew</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" mode="md"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="mycrew_container">
          <p>
            Add friends to your crew to easily share and refer to upcoming
            opportunities!
          </p>
          <IonTitle>Profile Search</IonTitle>
          <div className="mycrew_container__search-talent">
            <input
              type="text"
              className="form-group__input-styles"
              value={talentSearchPattern}
              onChange={(e) => handleSearchTalentInputChange(e)}
              placeholder="Full Name, Phone or Email"
            ></input>
            <IonButtons
              slot="primary"
              className="mycrew_container__search-talent__search_btn"
            >
              <IonButton
                onClick={() => {
                  searchTalent();
                }}
              >
                <IonIcon slot="icon-only" icon={searchOutline} />
              </IonButton>
            </IonButtons>
          </div>
          {noTalentFoundErrorMessage != "" && (
            <div className="error-message">{noTalentFoundErrorMessage}</div>
          )}
          {talentSearchLoading && (
            <div className="slider-card_spinner">
              <IonSpinner color="primary" name="crescent" />
            </div>
          )}
          {foundTalent.length > 0 &&
            foundTalent.map((talent: any, index: number) => (
              <IonCard
                className="mycrew_card--found-talent mycrew_card"
                key={talent.talent_user_id}
              >
                <IonGrid className="mycrew_card__grid">
                  <IonRow>
                    <IonCol size="2">
                      <IonAvatar>
                        <img
                          className="mycrew_card__img"
                          src={talent.photo}
                          alt=""
                        />
                      </IonAvatar>
                    </IonCol>
                    <IonCol size="10" className="mycrew_card__col_mid">
                      <h2 className="mycrew_card__title">
                        {talent.model_name}
                      </h2>
                      <IonLabel className="mycrew_card__label">
                        {talent.status}
                      </IonLabel>

                      <IonButton
                        size="small"
                        type="button"
                        className="mycrew_card__add_btn"
                        id={"mycrew_card__btn_" + talent.model_user_id}
                        onClick={() => addTalnetInCrew(talent.model_user_id)}
                      >
                        Add
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}

          <br />
          <br />
          {crewMembers.length > 0 && (
            <IonTitle>
              <span>My Crew</span>
            </IonTitle>
          )}
          {crewMembers.length > 0 &&
            crewMembers.map((crew: any, index: number) => (
              <IonCard className="mycrew_card" key={crew.model_user_id}>
                <IonGrid className="mycrew_card__grid">
                  <IonRow>
                    <IonCol size="2">
                      <IonAvatar>
                        <img
                          className="mycrew_card__img"
                          src={crew.photo}
                          alt=""
                        />
                      </IonAvatar>
                    </IonCol>
                    <IonCol size="10" className="mycrew_card__col_mid">
                      <h2 className="mycrew_card__title">{crew.model_name}</h2>
                      <IonLabel className="mycrew_card__label">
                        {crew.status}
                      </IonLabel>
                      {crew.type !== "crew" && (
                        <IonButton
                          size="small"
                          type="button"
                          className="mycrew_card__add_btn"
                          id={"mycrew_card__btn_" + crew.model_user_id}
                          onClick={() => addTalnetInCrew(crew.model_user_id)}
                        >
                          Add
                        </IonButton>
                      )}
                      {crew.type == "crew" && (
                        <IonButton
                          fill="outline"
                          size="small"
                          type="button"
                          className="mycrew_card__remove_btn"
                          id={"mycrew_card__btn_" + crew.model_user_id}
                          onClick={() =>
                            removeTalnetFromCrew(crew.model_user_id)
                          }
                        >
                          Remove
                        </IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}
          {nonCrewMembers.length > 0 && (
            <IonTitle>
              <span>Previously Worked With</span>
            </IonTitle>
          )}
          {nonCrewMembers.length > 0 &&
            nonCrewMembers.map((crew: any, index: number) => (
              <IonCard className="mycrew_card" key={crew.model_user_id}>
                <IonGrid className="mycrew_card__grid">
                  <IonRow>
                    <IonCol size="2">
                      <IonAvatar>
                        <img
                          className="mycrew_card__img"
                          src={crew.photo}
                          alt=""
                        />
                      </IonAvatar>
                    </IonCol>
                    <IonCol size="10" className="mycrew_card__col_mid">
                      <h2 className="mycrew_card__title">{crew.model_name}</h2>
                      <IonLabel className="mycrew_card__label">
                        {crew.status}
                      </IonLabel>
                      {crew.type !== "crew" && (
                        <IonButton
                          size="small"
                          type="button"
                          className="mycrew_card__add_btn"
                          id={"mycrew_card__btn_" + crew.model_user_id}
                          onClick={() => addTalnetInCrew(crew.model_user_id)}
                        >
                          Add
                        </IonButton>
                      )}
                      {crew.type == "crew" && (
                        <IonButton
                          fill="outline"
                          size="small"
                          type="button"
                          className="mycrew_card__remove_btn"
                          id={"mycrew_card__btn_" + crew.model_user_id}
                          onClick={() =>
                            removeTalnetFromCrew(crew.model_user_id)
                          }
                        >
                          Remove
                        </IonButton>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}
        </div>
        {!crewListLoaded && (
          <div className="slider-card_spinner">
            <IonSpinner color="primary" name="crescent" />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MyCrew;

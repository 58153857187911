import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonSpinner
} from "@ionic/react";

import "./Badges.css";

import { getTalentBadges } from "../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import { get } from "../../storage/storage";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import Loading from "../../push-components/loading/Loading";

const Badges: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [talentBadges, setTalentBadges] = useState<any>([]);
  const [talentBadgesLoaded, setTalentBadgesLoaded] = useState<any>(false);
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);

  const sbInit = async () => {


    let badges_api = await getTalentBadges();
      if (badges_api?.success) {
        setTalentBadges(badges_api.data.talent_badges);
      } else {
        if (badges_api?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (badges_api?.data?.errorCode == offlineErrorCode) {
          showToastMessage(badges_api?.data?.message);
        } else if (badges_api?.message) {
          await showToastMessage(badges_api.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }
      setTalentBadgesLoaded(true);
  };

  useEffect(() => {
    (async () => {
      await sbInit();
    })();
  }, []);
  return (
    <IonPage>
      <SimpleHeaderView
        title="My Badges"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        {talentBadgesLoaded && (<div className="talent-badges-content">
          {talentBadges.length > 0 &&
            talentBadges.map((badge: any, index: number) => (
              <IonCard className="talent-badges-content__card" key={badge.tb_id}>
                <IonGrid className="talent-badges-content__card__grid">
                  <IonRow>
                    <IonCol sizeSm="1" sizeXs="2">
                    <div className="talent-badges-content__card__grid__photo">
                      <img className={!badge.tb_earned ? "talent-badges-content__card__grid__photo__not-earned" : ""} src={badge.tb_photo} alt="" />
                         </div>
                    </IonCol>
                    <IonCol sizeSm="11" sizeXs="10">
                      <IonGrid className="talent-badges-content__card__grid">
                        <IonRow>
                          <IonCol size="12">
                            <div className="talent-badges-content__card__name">
                              {badge.tb_name}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <div>{badge.tb_description}</div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}
          {talentBadges.length == 0 && (
            <EmptyScreen title="No Badges" description="" />
          )}
        </div>)}
        {!talentBadgesLoaded && ( 
          <div className="slider-card_spinner">
          <IonSpinner color="primary" name="crescent" />
        </div>
        )}
      </IonContent>
      <Loading showLoading={waitingForApiResonse} />
    </IonPage>
  );
};
export default Badges;

/** */
import React, { useState, useEffect } from "react";
import { IonPopover, IonContent } from "@ionic/react";
import "./InfoButtonPopover.css";
import close_x_circle from "../../assets/images/common/close_x_circle.png";
import { getFAQLink, getInfoButtonData } from "../../common/common";
import { useHistory } from "react-router";

type InfoButtonPopover = {
  infoButtonClass: any;
  updatePopoverState: any;
};
const InfoButtonPopover: React.FC<InfoButtonPopover> = (props) => {
  const history = useHistory();
  const { infoButtonClass, updatePopoverState } = props;
  const [infoButtonData, setInfoButtonData] =
  useState<any>({});
  const handleDismissPopover = () => {
    updatePopoverState("");
  };

  useEffect(() => {
    (async () => {
      setInfoButtonData(
        await getInfoButtonData(infoButtonClass)
      );
    })();
  }, []);
  return (
    <IonPopover
      isOpen={true}
      onDidDismiss={() => handleDismissPopover()}
      cssClass="info-button-popover"
    >
      <IonContent class="ion-padding">
        <h2 className="info-button-popover__heading">
          {infoButtonData.fib_title}
          <img
            src={close_x_circle}
            className="info-button-popover__close"
            onClick={() => handleDismissPopover()}
          />{" "}
        </h2>
        <div>
          <div dangerouslySetInnerHTML={{ __html: infoButtonData.fib_description }}></div>
          {(infoButtonData?.fib_faq_class || infoButtonData?.fib_page_class) && (<div>
            <a
              onClick={async () =>
                history.push(await getFAQLink(infoButtonData.fib_faq_class, infoButtonData.fib_page_class))
              }
            >
              <br/>
              Learn more{" "}
            </a>
          </div>)}
        </div>
      </IonContent>
    </IonPopover>
  );
};

export default InfoButtonPopover;

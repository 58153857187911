import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import {
  IonBadge,
  IonButton,
  IonInput,
  IonLabel,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { Network } from "@capacitor/network";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import "./Forgot.css";
import { CapacitorHttp } from '@capacitor/core';
import LargeModal from "../../../components/Modal/LargeModal";
import LoginWrapper from "../LoginWrapper/LoginWrapper";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../components/Modal/Modal";
import { showToastMessage, noInternetMsg, somethingWentWrongErrMsg } from "../../../common/common";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const Forgot: React.FC = () => {
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const emailRef = useRef<HTMLIonInputElement>(null);

  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [ispwdlowercase, setIspwdlowercase] = useState(false);
  const [ispwduppercase, setIspwduppercase] = useState(false);
  const [ispwdnumeric, setIspwdnumeric] = useState(false);
  const [ispwdlength, setIspwdlength] = useState(false);

  async function closeModal() {
    // await setShowModal(false); nu mai trebuie folosit deoarece va da memory leek oricum nu mai e nevoie deoparece se navigheaza la alt page
    history.replace("/login");
  }

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_data: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onBlur",
  });
  const forgetPasswordHandler = async (data: any) => {
    if (getValues("newPassword") !== getValues("confirmPassword")) {
      setError("confirmPassword", {
        type: "manual",
        message: "The new password and the confirmed password should match.",
      });
      showToastMessage(
        "The new password and the confirmed password should match."
      );
    } else {
      setShowModal(false);
      let networkStatus = (await Network.getStatus()).connectionType;
      if (networkStatus !== "none") {
        const options = {
          url: `${process.env.REACT_APP_HP_API_URL}/users/forgotpassword`,
          headers: { "Content-Type": "application/json" },
          data: {
            user_phone_email: getValues("talent_data"),
            new_password: getValues("newPassword"),
            confirm_password: getValues("confirmPassword"),
            user_type: "Model",
          },
        };
        const response = await CapacitorHttp.post(options);
        if (response.status == 200) {
          if (response?.data?.success) {
            setTitle("Done");
            setDescription(response.data.message);
            setButtonText("Return to Login");
            setShowModal(true);
          } else {
            setTitle("Oops!");
            setDescription(response.data.message);
            setButtonText("Try again");
            setShowModal(true);
          }
        } else if (response?.data?.message) {
          setServerErrors(response.data.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } else {
        showToastMessage(noInternetMsg);
      }
    }
  };

  const togglePasswordVisiblity = (item: string) => {
    if (item == "newPasswordIcon") {
      setNewPasswordShown(newPasswordShown ? false : true);
    } else if (item == "confirmPasswordIcon") {
      setConfirmPasswordShown(confirmPasswordShown ? false : true);
    }
  };

  const validation = (event: any, type: any) => {
    var re1 = new RegExp("(?=.*[A-Z])");
    var re2 = new RegExp("(?=.*[a-z])");
    var re3 = new RegExp("(?=.*[0-9])");

    if (re1.test(event.target.value)) {
      setIspwduppercase(true);
    } else {
      setIspwduppercase(false);
    }
    if (re2.test(event.target.value)) {
      setIspwdlowercase(true);
    } else {
      setIspwdlowercase(false);
    }
    if (re3.test(event.target.value)) {
      setIspwdnumeric(true);
    } else {
      setIspwdnumeric(false);
    }
    if (event.target.value.length >= 8) {
      setIspwdlength(true);
    } else {
      setIspwdlength(false);
    }
  };

  return (
    <LoginWrapper>
      <div className="forgot">
        <form onSubmit={handleSubmit(forgetPasswordHandler)}>
          <section className="login__section">
            <div className="form-forgot">
              <div className="forgot-main-content">
                <div className="login-headings">
                  <h1 className="login-headings__login-header">
                    Reset your password
                  </h1>
                  <div className="login-headings__login-text">
                    Enter email address or phone number to receive a link to get
                    back into your account.
                  </div>
                </div>
                <div className="login__section__inputs">
                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      Email or Phone Number
                    </IonLabel>
                    <input
                      className={
                        errors.talent_data !== undefined
                          ? "login-input-styles-error"
                          : "login-input-styles"
                      }
                      {...register("talent_data", {
                        required: "This field is required.",
                      })}
                      type="text"
                      placeholder="Email or Phone Number"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="talent_data"
                      as={<div className="error-message" />}
                    />
                  </div>

                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      New Password
                    </IonLabel>
                    <input
                      type={newPasswordShown ? "text" : "password"}
                      placeholder="Enter new password"
                   
                      onKeyPress={(e) => validation(e, "new")}
                      onKeyDown={(e) => validation(e, "new")}
                      onKeyUp={(e) => validation(e, "new")}
                      className={
                        errors.newPassword !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("newPassword", {
                        required: "This field is required.",
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message: "Password requirements not met. Please ensure that your password meets the criteria in the form.",
                        },
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="newPassword"
                      as={<div className="error-message" />}
                    />
                    <i
                      className="create-password__eye-icon"
                      onClick={() => togglePasswordVisiblity("newPasswordIcon")}
                    >
                      {newPasswordShown ? eyeSlash : eye}
                    </i>
                  </div>
                  <div className="create-password__guidelines">
                    <IonBadge
                      mode="md"
                      className={
                        ispwdlowercase == true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      lowercase letter
                    </IonBadge>
                    <IonBadge
                      mode="md"
                      className={
                        ispwduppercase == true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      uppercase letter
                    </IonBadge>
                    <br />
                    <IonBadge
                      mode="md"
                      className={
                        ispwdnumeric == true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      numbers (0-9)
                    </IonBadge>
                    <IonBadge
                      mode="md"
                      className={
                        ispwdlength == true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      8+ characters
                    </IonBadge>
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      Confirm New Password
                    </IonLabel>
                    <input
                      type={confirmPasswordShown ? "text" : "password"}
                      placeholder="Enter a password"
                      className={
                        errors.confirmPassword !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                   
                      {...register("confirmPassword", {
                        required: "This field is required.",
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message: "Password requirements not met. Please ensure that your password meets the criteria in the form.",
                        },
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="confirmPassword"
                      as={<div className="error-message" />}
                    />
                    <i
                      className="create-password__eye-icon"
                      onClick={() =>
                        togglePasswordVisiblity("confirmPasswordIcon")
                      }
                    >
                      {confirmPasswordShown ? eyeSlash : eye}
                    </i>
                  </div>
                </div>
                <div className="form-group">
                  <IonButton
                    className="form-group__button"
                    mode="md"
                    expand="block"
                    type="submit"
                  >
                    Submit
                  </IonButton>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
      <Modal
        title={title}
        description={description}
        buttonText={buttonText}
        isOpen={showModal}
        closeAction={closeModal}
      ></Modal>
    </LoginWrapper>
  );
};

export default Forgot;

import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./ExternalTalentReferralsList.css";
import back_arrow from "../../../assets/images/common/arrow-left-white.png";
import { getCustomerReferrals } from "../../../data-services/edit-profile";
import EmptyScreen from "../../../push-components/EmptyScreen/EmptyScreen";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { getModelReferrals } from "../../../data-services/edit-profile";

type ModalProps = {
  is_open_cust_referral: boolean;
  closeCustReferral: Function;
  title: string;
};

const ExternalTalentReferralsList: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { is_open_cust_referral, closeCustReferral, title } = props;

  const [modelReferralsErrorMessage, setModelReferralsErrorMessage] =
    useState<any>("");
  const [modelReferrals, setModelReferrals] = useState<any>([]);

  const referralInit = async () => {
    let model_ref_api: any = await getModelReferrals();
    if (model_ref_api?.success) {
      let model_referrals = [];
      for (
        let index = 0;
        index < model_ref_api.data.referrals.length;
        index++
      ) {
        let element = model_ref_api.data.referrals[index];
        model_referrals.push({
          name: element.friend_name,
          image: element.photo,
          status: element.status,
          point: element.points_earned,
          email: element.friend_email,
        });
      }
      setModelReferrals(model_referrals);
    } else if (model_ref_api?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (model_ref_api?.data?.errorCode == offlineErrorCode) {
      setModelReferralsErrorMessage(model_ref_api?.data?.message);
      showToastMessage(model_ref_api?.data?.message);
    } else if (model_ref_api?.message) {
      setModelReferralsErrorMessage(model_ref_api.message);
      showToastMessage(model_ref_api.message);
    } else {
      setModelReferralsErrorMessage(somethingWentWrongErrMsg);
      showToastMessage(somethingWentWrongErrMsg);
    }
  };

  useEffect(() => {
    (async () => {
      await referralInit();
    })();
  }, []);

  return (
    <IonModal isOpen={is_open_cust_referral} mode="ios">
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeCustReferral();
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="customer_referral">
          {modelReferrals.length > 0 &&
            modelReferrals.map((refe: any, index: number) => (
              <IonCard className="customer_referral__card" key={index}>
                <IonGrid className="customer_referral__grid">
                  <IonRow>
                    <IonCol size="2">
                      <IonAvatar className="customer_referral__img">
                        <img src={refe.image} alt="" />
                      </IonAvatar>
                    </IonCol>
                    <IonCol size="10">
                      <h2 className="customer_referral__titile">{refe.name}</h2>
                      {refe.email !== "" && (
                        <IonText className="customer_referral__email">
                          {refe.email}
                        </IonText>
                      )}

                      <div className="customer_referral__badges">
                        <IonBadge
                          className={
                            refe.status === "Pending"
                              ? "customer_referral__badge customer_referral__badge__blue"
                              : "customer_referral__badge customer_referral__badge__orange"
                          }
                        >
                          {refe.status}
                        </IonBadge>
                        {refe.point !== "" && (
                          <IonBadge className="customer_referral__badge">
                            Earned: {refe.point} Points
                          </IonBadge>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}
          {modelReferrals.length == 0 && (
            <EmptyScreen
              title={
                modelReferralsErrorMessage
                  ? modelReferralsErrorMessage
                  : "No Referrals"
              }
            />
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ExternalTalentReferralsList;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import FormHeader from "../../push-components/FormHeader/FormHeader";
import ProgressBar from "../../push-components/ProgressBar/ProgressBar";
import {
  getRequiredSignUpInformation,
  updateTalenProfiletData,
} from "../../data-services/signup";
import "./BecomeAmbassador.css";
import ProfileFileuploader from "../../push-components/ProfileFileuploader/ProfileFileuploader";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import ProfileAdditionalFileUploader from "../../push-components/ProfileFileuploader/ProfileAdditionalFileUploader";
import { talentFileUploader } from "../../data-services/assignmets";
import { logError } from "../../data-services/assignmets";

const AmbassadorForm: React.FC = () => {
  const history = useHistory();
  const url_params: any = useParams();
  const [afShowSection, setAfShowSection] = useState({
    step: 1,
    progress_bar: 0.4,
  });
  const [afRequireSignupInfo, setAfRequireSignupInfo] = useState<any>();
  // talent image state
  const [talentImagesError, setTalentImagesError] = useState<{
    headshot: boolean;
    midshot: boolean;
    bodyshot: boolean;
  }>({
    headshot: false,
    midshot: false,
    bodyshot: false,
  });

  const [intrestArCheckBox, setIntrestArCheckBox] = useState<any>([]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [talentIntrestObject, setTalentIntrestObject] = useState<any>("");
  const [talentPaintSizeObject, setTalentPaintSizeObject] = useState<any>("");
  const [talentShirtSizeObject, setTalentShirtSizeObject] = useState<any>("");
  const [refreshComponent, setRefreshComponent] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_ethnicity: "",
      talent_first_language: "",
      talent_second_language: "",
      talent_third_language: "",
      talent_height: "",
      talent_shirt_size: "",
      talent_paint_size: "",
      talent_shoe_size: "",
    },
    mode: "onBlur",
  });
  const afBackStepHandle = async () => {
    let back_step = afShowSection.step - 1;
    if (back_step) {
      let decrease_bar = afShowSection.progress_bar - 0.2;
      setAfShowSection({
        step: back_step,
        progress_bar: decrease_bar,
      });
    } else {
      history.push(`/BecomeAmbassador/${url_params.talent_signup_id}`);
    }
  };
  const afNextStepHandler = () => {
    //  check if there any validations error
    if (Object.keys(errors).length === 0) {
      let next_step = afShowSection.step + 1;
      let increase_bar = afShowSection.progress_bar + 0.2;
      setAfShowSection({
        step: next_step,
        progress_bar: increase_bar,
      });
    }
  };
  const ambassadorFormHandler = async () => {
    if (afShowSection.step >= 3) {
      //after step 3 submit for info and show upload file form
      await onSubmitAmbassadorForm();
      return;
    }
    afNextStepHandler();
  };

  const onSubmitAmbassadorForm = async () => {
    setDisabledSubmitBtn(true);
    let ambassador_form_data = {
      model_intrest: intrestArCheckBox.toString(),
      model_ethnicity: getValues("talent_ethnicity").toString(),
      model_primary_language: getValues("talent_first_language").toString(),
      model_second_language: getValues("talent_second_language").toString(),
      model_third_language: getValues("talent_third_language").toString(),
      model_height_id: getValues("talent_height").toString(),
      model_show_size: getValues("talent_shoe_size").toString(),
      model_pant_size: getValues("talent_paint_size").toString(),
      model_shirt_size: getValues("talent_shirt_size").toString(),
      model_user_id: url_params.talent_signup_id.toString(),
    };

    let singup_api_response = await updateTalenProfiletData({
      model: ambassador_form_data,
    });

    try {
      if (singup_api_response?.success) {
        afNextStepHandler();
      } else if (
        singup_api_response?.data?.errorCode == unauthorizedErrorCode
      ) {
        showToastMessage(
          "An error has occured! Please log in to proceed with profile updates."
        );
      } else if (singup_api_response?.data?.errorCode == offlineErrorCode) {
        showToastMessage(singup_api_response?.data?.message);
      } else if (singup_api_response?.message) {
        await showToastMessage(singup_api_response.message);
      } else {
        showToastMessage(
          "An error has occured! Please log in to proceed with profile updates."
        );
      }
    } catch (e: any) {
      // Re-enable Submit
      setDisabledSubmitBtn(false);
      showToastMessage(
        "An error has occured! Please log in to proceed with profile updates."
      );
      await logError(
        "Become Ambassador - onSubmitAmbassadorForm - Exception thrown",
        "Signup - Become Ambassador",
        JSON.stringify(e.message)
      );
    }

    setDisabledSubmitBtn(false);
  };
  const uploadTalentProfilePhotos = async (event: any) => {
    event.preventDefault();
    setDisabledSubmitBtn(true);
    setUploadProgress(5);
    let model_photo = new FormData(event.target);
    model_photo.append("type", "profile_photos");
    let head_shot: any = model_photo.get("head_shot");
    let midshot: any = model_photo.get("square_shot");
    let fullbodyshot: any = model_photo.get("url_shot");
    if (head_shot?.name === "") {
      setTalentImagesError({ ...talentImagesError, headshot: true });
      setDisabledSubmitBtn(false);
      return;
    }
    if (midshot?.name === "") {
      setTalentImagesError({ ...talentImagesError, midshot: true });
      setDisabledSubmitBtn(false);
      return;
    }
    if (fullbodyshot?.name === "") {
      setTalentImagesError({ ...talentImagesError, bodyshot: true });
      setDisabledSubmitBtn(false);
      return;
    }

    let additional_img = model_photo.getAll("photos[]");
    model_photo.delete("photos[]");
    for (let index = 0; index < additional_img.length; index++) {
      let file: any = additional_img[index];
      if (file.name) {
        model_photo.append("photos[]", file);
      }
    }
    let file_upload: any = await talentFileUploader(
      model_photo,
      "",
      handleProgress
    );

    try {
      if (file_upload?.success) {
        history.push(`/BecomeModel/${url_params.talent_signup_id}`);
      } else if (file_upload?.data?.errorCode == unauthorizedErrorCode) {
        showToastMessage(
          "An error has occured! Please log in to proceed with profile updates."
        );
      } else if (file_upload?.data?.errorCode == offlineErrorCode) {
        showToastMessage(file_upload?.message);
      } else if (file_upload?.message) {
        await showToastMessage(file_upload.message);
      } else {
        showToastMessage(
          "An error has occured! Please log in to proceed with profile updates."
        );
      }
    } catch (e: any) {
      // Re-enable Submit
      setDisabledSubmitBtn(false);
      setUploadProgress(0);
      showToastMessage(
        "An error has occured! Please log in to proceed with profile updates."
      );
      await logError(
        "Become Ambassador - uploadTalentProfilePhotos - Exception thrown",
        "Signup - Become Ambassador",
        JSON.stringify(e.message)
      );
    }
    setTalentImagesError({ headshot: false, midshot: false, bodyshot: false });
    setDisabledSubmitBtn(false);
    setUploadProgress(0);
  };

  const onRemoveCallback = useCallback(
    (id: string, type: string, ele: any) => {},
    []
  );
  const intrestValueHandler = (e: any) => {
    let element = document.querySelector(`#intrest_label${e.detail.value}`);
    if (e.detail.checked) {
      if (element) {
        element.classList.add("ambassador-form__checkbox--label");
      }
      intrestArCheckBox.push(e.detail.value);
    } else {
      if (element) {
        element.classList.remove("ambassador-form__checkbox--label");
      }
      let index_of = intrestArCheckBox.indexOf(e.detail.value);
      intrestArCheckBox.splice(index_of, 1);
    }

    setIntrestArCheckBox(intrestArCheckBox);
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  useEffect(() => {
    (async () => {
      let af_required_info = await getRequiredSignUpInformation();
      setAfRequireSignupInfo(af_required_info);
      setTalentIntrestObject(
        af_required_info.commonGenderFields.model_intrest_ar_ba
      );
      setTalentPaintSizeObject(
        af_required_info.commonGenderFields.model_pant_size
      );
      setTalentShirtSizeObject(
        af_required_info.commonGenderFields.model_shirt_size
      );
    })();
  }, [refreshComponent]);

  return (
    <IonPage>
      <FormHeader title="" backStepHandle={afBackStepHandle} />
      <ProgressBar progress_value={afShowSection.progress_bar} />

      <IonContent>
        <form
          onSubmit={handleSubmit(ambassadorFormHandler)}
          id="formdata"
          className="ambassador-form__form"
        >
          {afShowSection.step === 1 && (
            <section className="ambassador-form__section-1">
              <div className="ambassador-form__title">
                <h1>
                  What are you <br />
                  interested in doing?
                </h1>
              </div>
              <div className="ambassador-form__description">
                Select all the types of jobs that you would be interested in
                doing. You may update this information in your profile settings.
              </div>

              {talentIntrestObject &&
                Object.keys(talentIntrestObject).map(
                  (key: string, index: number) => (
                    <IonCard className="ambassador-form__card" key={index}>
                      <IonItem>
                        <label
                          id={`intrest_label${key}`}
                          className="ambassador-form__card__label"
                        >
                          {talentIntrestObject[key]}
                        </label>
                        <IonCheckbox
                          className="form-group__checkbox-styles"
                          mode="md"
                          slot="start"
                          value={key}
                          checked={intrestArCheckBox.includes(key)}
                          onIonChange={(e: any) => {
                            intrestValueHandler(e);
                          }}
                        ></IonCheckbox>
                      </IonItem>
                    </IonCard>
                  )
                )}

              <div className="ambassador-form__buttons">
                <IonButton
                  type="submit"
                  mode="ios"
                  expand="block"
                  className="form-group__button"
                >
                  Next
                </IonButton>
              </div>
            </section>
          )}

          {afShowSection.step === 2 && (
            <section className="ambassador-form__section-2">
              <div className="ambassador-form__title">
                <h1>Select your ethnicity and proficient languages</h1>
              </div>
              <div className="ambassador-form__description">
                Adding your ethnicity and language proficiency helps qualify you
                for more opportunities. You may update this information in your
                profile settings.
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Ethnicity
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_ethnicity !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_ethnicity", {
                    required: "This field is required.",
                  })}
                >
                  {afRequireSignupInfo &&
                    afRequireSignupInfo.commonGenderFields.model_ethnicity_ar.map(
                      (ethnicity: any) => (
                        <IonSelectOption
                          key={Object.keys(ethnicity)[0]}
                          value={Object.keys(ethnicity)[0]}
                        >
                          {ethnicity[Object.keys(ethnicity)[0]]}
                        </IonSelectOption>
                      )
                    )}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_ethnicity"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  First Language
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_first_language !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_first_language", {
                    required: "This field is required.",
                  })}
                >
                  {afRequireSignupInfo &&
                    afRequireSignupInfo.commonGenderFields.model_language.map(
                      (language: any) => (
                        <IonSelectOption
                          key={Object.keys(language)[0]}
                          value={Object.keys(language)[0]}
                        >
                          {language[Object.keys(language)[0]]}
                        </IonSelectOption>
                      )
                    )}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_first_language"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label">
                  Second Language
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className="form-group__input-styles"
                  {...register("talent_second_language")}
                >
                  {afRequireSignupInfo &&
                    afRequireSignupInfo.commonGenderFields.model_language.map(
                      (language: any) => (
                        <IonSelectOption
                          key={Object.keys(language)[0]}
                          value={Object.keys(language)[0]}
                        >
                          {language[Object.keys(language)[0]]}
                        </IonSelectOption>
                      )
                    )}
                </IonSelect>
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label">
                  Third Language
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className="form-group__input-styles"
                  {...register("talent_third_language")}
                >
                  {afRequireSignupInfo &&
                    afRequireSignupInfo.commonGenderFields.model_language.map(
                      (language: any) => (
                        <IonSelectOption
                          key={Object.keys(language)[0]}
                          value={Object.keys(language)[0]}
                        >
                          {language[Object.keys(language)[0]]}
                        </IonSelectOption>
                      )
                    )}
                </IonSelect>
              </div>

              <div className="ambassador-form__buttons">
                <IonButton
                  mode="ios"
                  expand="block"
                  type="submit"
                  className="form-group__button"
                >
                  Next
                </IonButton>
              </div>
            </section>
          )}

          {afShowSection.step === 3 && (
            <section className="ambassador-form__section-3">
              <div className="ambassador-form__title">
                <h1>
                  Share your basic <br /> measurements
                </h1>
              </div>
              <div className="ambassador-form__description">
                Add your basic measurements to get more job recommendations. You
                may add other details in your profile settings.
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Height
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_height !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_height", {
                    required: "This field is required.",
                  })}
                >
                  {afRequireSignupInfo &&
                    Object.keys(
                      afRequireSignupInfo.commonGenderFields.model_height_id
                    ).map((height: any) => (
                      <IonSelectOption key={height} value={height}>
                        {
                          afRequireSignupInfo.commonGenderFields
                            .model_height_id[height]
                        }
                      </IonSelectOption>
                    ))}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_height"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Shirt Size
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_shirt_size !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_shirt_size", {
                    required: "This field is required.",
                  })}
                >
                  {talentShirtSizeObject &&
                    Object.keys(talentShirtSizeObject).map(
                      (shirt_size: any) => {
                        return (
                          <IonSelectOption key={shirt_size} value={shirt_size}>
                            {talentShirtSizeObject[shirt_size]}
                          </IonSelectOption>
                        );
                      }
                    )}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_shirt_size"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Pant Size
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_paint_size !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_paint_size", {
                    required: "This field is required.",
                  })}
                >
                  {talentPaintSizeObject &&
                    Object.keys(talentPaintSizeObject).map(
                      (paint_size: any) => (
                        <IonSelectOption key={paint_size} value={paint_size}>
                          {talentPaintSizeObject[paint_size]}
                        </IonSelectOption>
                      )
                    )}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_paint_size"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonLabel className="form-group__label form-group__label--required">
                  Shoe Size
                </IonLabel>
                <IonSelect
                  mode="ios"
                  placeholder="--Select--"
                  className={
                    errors.talent_shoe_size !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("talent_shoe_size", {
                    required: "This field is required.",
                  })}
                >
                  {afRequireSignupInfo &&
                    Object.keys(
                      afRequireSignupInfo.commonGenderFields.model_show_size
                    ).map((shoe_size: any) => (
                      <IonSelectOption key={shoe_size} value={shoe_size}>
                        {afRequireSignupInfo.commonGenderFields.model_show_size[
                          shoe_size
                        ].replace("<br>", "")}
                      </IonSelectOption>
                    ))}
                </IonSelect>
                <ErrorMessage
                  errors={errors}
                  name="talent_shoe_size"
                  as={<div className="error-message" />}
                />
              </div>
              <div className="ambassador-form__buttons">
                <IonButton
                  mode="ios"
                  expand="block"
                  type="submit"
                  className="form-group__button"
                  disabled={disabledSubmitBtn}
                >
                  Next
                </IonButton>
              </div>
            </section>
          )}
        </form>
        {afShowSection.step >= 4 && (
          <section className="ambassador-form__section-4">
            <form onSubmit={uploadTalentProfilePhotos}>
              <div className="ambassador-form__title">
                <h1>Add additional photos</h1>
              </div>
              <div className="ambassador-form__description">
                Take or upload additional photos to let clients know you're
                accountable and ready to work. You may upload a maximum of 5,
                but must upload a minimum of 2.
              </div>

              <div className="af__file-upload">
                <div className="af__file-upload__widgets">
                  <label
                    htmlFor="one"
                    className="af__file-upload-widget-label af__file-required af__file-upload-widget-label-primary"
                  >
                    Headshot
                  </label>

                  <ProfileFileuploader
                    name="head_shot"
                    limit="1"
                    id="model_headshot"
                    files={[]}
                    cropp_ratio="headshot"
                    click={onRemoveCallback}
                  />
                </div>
                {/* hide it said by emil */}
                {/* <div className="af__file-upload__widgets">
                  <label
                    htmlFor="two"
                    className="af__file-upload-widget-label af__file-required af__file-upload-widget-label-primary"
                  >
                    Midshot
                  </label>
                  <ProfileFileuploader
                    name="square_shot"
                    limit="1"
                    id="model_midshot"
                    files={[]}
                    click={onRemoveCallback}
                  />
                </div> */}
                <div className="af__file-upload__widgets">
                  <label
                    htmlFor="three"
                    className="af__file-upload-widget-label af__file-upload-widget-label-primary"
                  >
                    BodyShot
                  </label>
                  <ProfileFileuploader
                    name="url_shot"
                    limit="1"
                    id="model_fullbody"
                    cropp_ratio="fullbody"
                    files={[]}
                    click={onRemoveCallback}
                  />
                </div>
              </div>
              <div className="af__file-upload__additional_photo">
                <ProfileAdditionalFileUploader
                  name="photos[]"
                  limit="200"
                  id="model_additional"
                  files={[]}
                />
              </div>
              {talentImagesError.headshot && (
                <div className="error-message">Head shot required!</div>
              )}
              {talentImagesError.midshot && (
                <div className="error-message">Mid shot required!</div>
              )}
              {talentImagesError.bodyshot && (
                <div className="error-message">Fullbody shot required!</div>
              )}

              <div className="ambassador-form__buttons">
                {disabledSubmitBtn && (
                  <IonProgressBar value={uploadProgress / 100} />
                )}
                <IonButton
                  mode="ios"
                  expand="block"
                  type="submit"
                  className="form-group__button"
                  disabled={disabledSubmitBtn}
                >
                  Next
                </IonButton>
              </div>
            </form>
          </section>
        )}
      </IonContent>
    </IonPage>
  );
};

export default AmbassadorForm;

import React from "react";
import { useParams } from "react-router";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import "./BecomehModel.css";
import check_icon from "../../assets/images/common/checked.png";
import star_img from "../../assets/images/push-model/star-image.png";

const BecomeModel: React.FC = () => {
  const url_params: any = useParams();
  return (
    <IonPage>
      <IonContent>
        <section className="become-model">
          <div className="become-model__image">
            <img src={star_img} alt="" />
          </div>

          <div className="become-model__title">
            <h1>
              Become a<br />
              <span>PUSH Model</span>
              <br />
              today!
            </h1>
          </div>
          <div className="become-model__description">
            Unlock more opportunities by becoming a<br />
            <span> PUSH Model</span> today!
          </div>
          <div className="become-model__additional-info-text">
            <p>Fill out additional information to apply for:</p>

            <div className="become-model__additional-info">
              <ul>
                <li>
                  <img src={check_icon} alt="" /> Modeling Opportunities
                </li>
                <li>
                  <img src={check_icon} alt="" /> Speaking Engagements
                </li>
                <li>
                  <img src={check_icon} alt="" /> Event Hosting
                </li>
                <li>
                  <img src={check_icon} alt="" /> Bartending and Waitressing
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="become-model__buttons">
          <IonButton
            className="form-group__button"
            expand="block"
            mode="ios"
            type="button"
            routerLink={`/ModalForm/${url_params.talent_signup_id}`}
          >
            Get Started
          </IonButton>
          {/* routerLink={`/BecomeInfluencer/${url_params.talent_signup_id}` */}
          <IonButton
            className="form-group__button"
            fill="clear"
            expand="block"
            mode="ios"
            type="button"
            href={`/Home`}
          >
            Not Now
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BecomeModel;

import React, { useState } from "react";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./InputTypes.css";
type componentProps = {
  question: string;
  answer?: string;
  question_id: number;
  options?: string;
  questionno?: number;
  requiredmessage?: boolean;
  allowHtmlQuestion?: any;
};
const InputTypeRange: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    allowHtmlQuestion,
  } = Props;
  const [cuptSizeStringValue, setCuptSizeStringValue] = useState<string>("");
  const [cuptSizeNumValue, setCuptSizeNumValue] = useState<number>(
    answer ? parseInt(answer) : 0
  );
  const optionsArray = options && options.length ? options.split("||") : null;

  return (
    <>
      <div className="take-test-question">
        <div>
          <div className="input-type-range__measurement">
            <div className="measurement__labels">
              <div className="edit-profile__titles">
                <IonText>
                  {questionno && <>{questionno}. </>}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: question,
                    }}
                  ></span>
                </IonText>
              </div>
              <IonLabel className="input-type-range__label">
                {cuptSizeNumValue}%
              </IonLabel>
            </div>
            <IonRange
              className="input-type-range__range-input"
              mode="ios"
              name={"answer_" + question_id.toString()}
              ticks={false}
              value={cuptSizeNumValue}
              min={0}
              max={100}
              step={1}
              snaps={true}
              onIonChange={(e: any) => setCuptSizeNumValue(e.detail.value)}
            />
            {optionsArray && (
              <div className="input-type-range__options">
                {optionsArray.map((option, index) => (
                  <div key={index}>{option}</div>
                ))}
              </div>
            )}
          </div>
        </div>

        {requiredmessage == true && (
          <div className="error-message">This field is required.</div>
        )}
      </div>
    </>
  );
};

export default InputTypeRange;

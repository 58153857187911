import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonBackButton,
  IonCard,
} from "@ionic/react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import "./W2Form.css";
import { getStatesForSignUp } from "../../../../data-services/signup";
import { get } from "../../../../storage/storage";
import { savePushPlacementForm } from "../../../../data-services/home-api";
import biweekly_pay from "../../../../assets/home-page/w2form/biweekly_pay.png";
import direct_deposit from "../../../../assets/home-page/w2form/direct_deposit.png";
import taxes_withheld from "../../../../assets/home-page/w2form/taxes_withheld.png";
import back_arrow from "../../../../assets/images/common/arrow-left.png";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/auth";
import { useHistory } from "react-router";
import {
  showToastMessage,
  unauthorizedErrMsg,
  somethingWentWrongErrMsg
} from "../../../../common/common";
import { logError } from "../../../../data-services/assignmets";

type ModalProps = {
  isOpen_w2_form: boolean;
  closeW2Form: Function;
};
let model_w2_status: string = "";
const W2Form: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_w2_form, closeW2Form } = props;
  let history = useHistory();
  const [isAgreeOpt, setIsAgreeOpt] = useState<boolean>(false);
  const [w2FormStateList, setW2FormStateList] = useState<any>("");
  const [placementApiRes, setPlacementApiRes] = useState<any>("");
  const [w2formSumitted, setw2FormSumitted] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      w2_talent_fname: "",
      w2_talent_lname: "",
      w2_talent_email: "",
      w2_talent_phone: "",
      w2_address: "",
      w2_state: "",
      w2_city: "",
      w2_zipcode: "",
      w2_agree_opt: false,
    },
    mode: "onBlur",
  });
  const w2FormSubmitHandler = async () => {
    if (!isAgreeOpt) {
      setError("w2_agree_opt", {
        type: "manual",
        message: "This field is required.",
      });
      return;
    }

    let w2_form_values = {
      firstName: getValues("w2_talent_fname"),
      lastName: getValues("w2_talent_lname"),
      email: getValues("w2_talent_email").trim(),
      phone: getValues("w2_talent_phone"),
      address: getValues("w2_address"),
      state: getValues("w2_state"),
      city: getValues("w2_city"),
      zip: getValues("w2_zipcode"),
    };
    try {
      let placement_api_res = await savePushPlacementForm(
        JSON.stringify(w2_form_values)
      );
      if (placement_api_res?.success) {
        setw2FormSumitted(true);
      } else {
        setPlacementApiRes(placement_api_res);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "W2 Form - w2FormSubmitHandler - Exception thrown",
          "Talent App - W2 Form",
          JSON.stringify(e.message)
        );
        
      }
    }
  };
  const gotoFaqPushPlacement = () => {
    history.push("/FAQsProfileManagement/p3");
  };
  useEffect((): any => {
    let isSubscribed = true;
    (async () => {
      if (isSubscribed) {
        model_w2_status = await get("login_model_w2_status");
        setValue("w2_talent_fname", await get("login_model_first_name"));
        setValue("w2_talent_lname", await get("login_model_last_name"));
        setValue("w2_talent_email", await get("login_model_email"));
        setValue("w2_talent_phone", await get("login_model_phone"));
        setValue("w2_address", await get("login_model_address"));
        setValue("w2_state", await get("login_model_state"));
        setValue("w2_city", await get("login_model_city"));
        setValue("w2_zipcode", await get("login_model_zipcode"));
        let data = await getStatesForSignUp();
        if (isSubscribed) setW2FormStateList(data);
      }
    })();
    return () => (isSubscribed = false);
  }, [isAgreeOpt, w2formSumitted]);

  return (
    <IonModal isOpen={isOpen_w2_form} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeW2Form();
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {!w2formSumitted && (
        <IonContent>
          <form onSubmit={handleSubmit(w2FormSubmitHandler)}>
            <section className="w2-form-sec">
              <div className="w2-from-sec__instructions">
                <div className="instruction__title">
                  <h1>WELCOME TO PUSH PLACEMENT</h1>
                </div>
                {model_w2_status == "3" && (
                  <div className="instruction--1">
                    You haven't been working for PUSH for more than 1 year and
                    the paperwork has to be updated.
                  </div>
                )}

                <div className="instruction--2">
                  Looks like you are interested in a PUSH Placement event. PUSH
                  Placement is a new company which actually employes talent.
                  Some of the benefits of working for PUSH Placement for
                  specific events are:
                </div>
                <div className="instruction__imgs">
                  <div className="instruction__img">
                    <img src={biweekly_pay} alt="" />
                  </div>
                  <div className="instruction__img">
                    <img src={direct_deposit} alt="" />
                  </div>
                  <div className="instruction__img">
                    <img src={taxes_withheld} alt="" />
                  </div>
                </div>
                <div className="instruction__imgs-labels">
                  <div className="instruction__imgs--label">
                    DIRECT <br /> DEPOSIT
                  </div>
                  <div className="instruction__imgs--label">
                    WEEKLY PAY <br /> SCHEDULE
                  </div>
                  <div className="instruction__imgs--label">
                    TAXES <br /> WITHHELD
                  </div>
                </div>
                <div className="instruction--3">
                  If you want to learn more about PUSH Placement&nbsp;
                  <label onClick={gotoFaqPushPlacement}>click here</label>.
                </div>

                <div className="instruction--4">
                  We will prompt our payroll processing partner, Payreel, to
                  email you information to complete the sign up process. You
                  should receive an email from Payreel with the subject "Welcome
                  to Payreel" within one business day. Be sure to check your
                  spam folder if it doesn't make it to your inbox.
                </div>
                <div className="instruction--5">
                  Below is the information we will send to Payreel to begin your
                  sign up process. Please confirm the information below is
                  accurate. It is VERY IMPORTANT to use your legal name for this
                  process. Please make update if need before submitting your
                  information
                </div>
              </div>

              <div>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Legal First Name
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="John"
                    className={
                      errors.w2_talent_fname !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                   
                    {...register("w2_talent_fname", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_talent_fname"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Legal Last Name
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="Doe"
                    className={
                      errors.w2_talent_lname !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                  
                    {...register("w2_talent_lname", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_talent_lname"
                    as={<div className="error-message" />}
                  />
                </div>

                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Email Address
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="Doe@gmail.com"
                    className={
                      errors.w2_talent_email !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    
                    {...register("w2_talent_email", {
                      required: "This field is required.",
                      pattern: {
                        value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
                        message: "Invalid email address",
                      },
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_talent_email"
                    as={<div className="error-message" />}
                  />
                </div>

                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Phone Number
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="8888888888"
                    className={
                      errors.w2_talent_phone !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
               
                    {...register("w2_talent_phone", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_talent_phone"
                    as={<div className="error-message" />}
                  />
                </div>

                <div className="form-group">
                  <IonLabel className="form-group__label">Address</IonLabel>
                  <input
                    type="text"
                    placeholder="Street number & name"
                    className={
                      errors.w2_address !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    
                    {...register("w2_address", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_address"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">State</IonLabel>
                  <IonSelect
                    placeholder="--Select--"
                    className={
                      errors.w2_state !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("w2_state", {
                      required: "This field is required.",
                    })}
                  >
                    {Object.keys(w2FormStateList).map(
                      (state_key, state_index) => (
                        <IonSelectOption key={state_key} value={state_key}>
                          {w2FormStateList[state_key]}
                        </IonSelectOption>
                      )
                    )}
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="w2_state"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">City</IonLabel>
                  <input
                    type="text"
                    placeholder="Eg. Los Angeles"
                    className={
                      errors.w2_city !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                 
                    {...register("w2_city", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_city"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Zip code</IonLabel>
                  <input
                    type="tel"
                    placeholder="Eg. 83971"
                    className={
                      errors.w2_zipcode !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                
                    {...register("w2_zipcode", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="w2_zipcode"
                    as={<div className="error-message" />}
                  />
                </div>
              </div>
              <div className="w2-form-sec__certifcate--4">
                <div>
                  <IonCheckbox
                    className="form-group__checkbox-styles"
                    mode="md"
                    {...register("w2_agree_opt", {
                      required: "This field is required.",
                    })}
                    checked={isAgreeOpt}
                    onIonChange={(e) => setIsAgreeOpt(e.detail.checked)}
                  ></IonCheckbox>
                </div>
                <IonLabel className="form-group__label">
                  I certify the information about is accurate
                </IonLabel>
              </div>

              <ErrorMessage
                errors={errors}
                name="w2_agree_opt"
                as={<div className="error-message" />}
              />
              <div className="w2-form__error">
                <div
                  dangerouslySetInnerHTML={{ __html: placementApiRes.message }}
                />
              </div>
              <div className="w2-form-sec__submit--btn">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="submit"
                  className="form-group__button"
                >
                  Submit
                </IonButton>
              </div>
            </section>
          </form>
        </IonContent>
      )}
      {w2formSumitted && (
        <IonContent>
          <IonCard className="w2-from-submit">
            <div>
              <h1>SUCCESS!</h1>

              <div className="w2-from-submit__div">
                We've sent your information over to our payroll partner,
                Payreel! Look for an email within the next business day with the
                subject "Welcome to Payreel."{" "}
              </div>
              <div className="w2-from-submit__div">
                This email will contain your log-in information to complete your
                paperwork. Once your paperwork is completed and verified, you
                will be able to apply for PUSH Placement jobs through your "My
                Opportunity" tab in your profile.
              </div>
              <div className="w2-from-submit__btn">
                <IonButton
                  mode="ios"
                  type="button"
                  className="form-group__button"
                  routerLink="/Opportunities"
                >
                  Go to Opportunities
                </IonButton>
              </div>
            </div>
          </IonCard>
        </IonContent>
      )}
    </IonModal>
  );
};
export default W2Form;

import React, { useEffect, useState } from "react";

import { IonIcon, IonItem, IonTitle, IonSpinner } from "@ionic/react";
import chevron_right from "../../assets/images/common/chevron-right.svg";
import "./MyRewardSliding.css";

import { useHistory } from "react-router";
import ItemRedeemCard from "../../components/ItemRedeemCard/ItemRedeemCard";
import RedeemRewardModal from "../../pages/MyRewards/RedeemRewardModal";
import { modelRewardsList } from "../../data-services/scoreboard";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
} from "../../common/common";
import ClaimRewardSuccessModal from "../../pages/MyRewards/ClaimRewardSuccessModal";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import info_circle from "../../assets/images/common/info-circle.svg";
import InfoButtonPopover from "../InfoButtonPopover/InfoButtonPopover";

const MyRewardSliding: React.FC = () => {
  const dispatch = useDispatch();
  const [rewardsArray, setRewardsArray] = useState<any>([]);
  const [talenRewardId, setTalenRewardId] = useState<string>("");
  const [targetReward, setTargetReward] = useState<any>("");
  const [isViewOnly, setIsViewOnly] = useState<any>(false);
  const [targetAttributesValuesChecks, setTargetAttributesValuesChecks] =
    useState<any>("");
  const [isOpenRewardModal, setIsOpenRewardModal] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [claimRewardSuccessMsg, setClaimRewardSuccessMsg] = useState<{
    show: boolean;
    title: string;
    message: string;
  }>({
    show: false,
    title: "",
    message: "",
  });
  const [showInfo, setShowInfo] = useState<string>("");
  const history = useHistory();
  const goToRewards = () => {
    history.push("/myRewards");
  };
  const redeemRewardHandler = (
    redeemId: string,
    checkedAttributesValues: any,
    viewOnly?: any
  ) => {
    // Parse the attribute ID and value ID

    let targetReward: any;
    let targetAttributesValuesChecks = [];
    for (const reward of rewardsArray) {
      if (reward.model_reward_id == redeemId) {
        targetReward = reward;
        targetAttributesValuesChecks =
          checkedAttributesValues["reward" + redeemId];
      }
    }
    setIsViewOnly(viewOnly);
    setTalenRewardId(redeemId);
    setTargetReward(targetReward);

    setTargetAttributesValuesChecks(targetAttributesValuesChecks);
    setIsOpenRewardModal(true);
  };
  const closeRewardModal = async (title: string = "", message: string = "") => {
    setIsOpenRewardModal(false);
    setIsViewOnly(false);
    if (title && message) {
      setClaimRewardSuccessMsg({ title: title, message: message, show: true });
      await talentRewardList();
    }
  };
  const closeClaimdModal = async () => {
    setIsViewOnly(false);
    await talentRewardList();
    setClaimRewardSuccessMsg({ title: "", message: "", show: false });
  };
  const talentRewardList = async (first_load?: boolean) => {
    if (first_load) {
      setShowSpinner(true);
    }

    let talent_rewards = await modelRewardsList(10);
    if (talent_rewards?.success) {
      if (talent_rewards?.data.rewards) {
        setRewardsArray(talent_rewards?.data.rewards);
      }
    } else if (talent_rewards?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (talent_rewards?.data?.errorCode == offlineErrorCode) {
      showToastMessage(talent_rewards?.data?.message);
    } else if (talent_rewards?.message) {
      await showToastMessage(talent_rewards.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setShowSpinner(false);
  };
  useEffect(() => {
    (async () => {
      await talentRewardList(true);
    })();
  }, []);
  return (
    <>
    <div className="scoreboard_reward">
      <IonItem>
        <IonTitle className="scoreboard_reward__title">
          My Rewards
          <IonIcon
            icon={info_circle}
            onClick={() => setShowInfo("my_rewards")}
          />
        </IonTitle>
        <div
          slot="end"
          className="scoreboard_reward__all-item"
          onClick={() => goToRewards()}
        >
          <span>See all</span> <IonIcon src={chevron_right}></IonIcon>
        </div>
      </IonItem>
      </div>
      {rewardsArray.length > 0 && (
        <div className="scoreboard_reward">
          <ItemRedeemCard
            items={rewardsArray}
            redeemRewardHandler={redeemRewardHandler}
          ></ItemRedeemCard>
        </div>
      )}
      {showSpinner && (
        <div className="slider-card_spinner">
          <IonSpinner color="primary" name="crescent" />
        </div>
      )}
      <RedeemRewardModal
        rewardModalType="save_modal"
        isOpenRewardModal={isOpenRewardModal}
        closeRewardModal={closeRewardModal}
        talenRewardId={talenRewardId}
        targetReward={targetReward}
        targetAttributesValuesChecks={targetAttributesValuesChecks}
        viewOnly={isViewOnly}
      />
      <ClaimRewardSuccessModal
        isOpenClaimModal={claimRewardSuccessMsg.show}
        closeClaimdModal={closeClaimdModal}
        title_message={{
          title: claimRewardSuccessMsg.title,
          message: claimRewardSuccessMsg.message,
        }}
      />
      {showInfo === "my_rewards" && (
        <InfoButtonPopover
          infoButtonClass={"my_rewards"}
          updatePopoverState={setShowInfo}
        ></InfoButtonPopover>
      )}
    </>
  );
};
export default MyRewardSliding;

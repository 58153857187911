import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonBackButton,
  IonItem,
  IonLabel,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonCard,
  IonCardContent,
  IonBadge
} from "@ionic/react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import backArrow from "../../../assets/images/common/arrow-left.png";
import "../AssignmentConfirm/AssignmentConfirm.css";
import PCardAgreement from "../../Home/SetupAccount/PCardAgreement/PCardAgreement";
import { set, get } from "../../../storage/storage";
import checkedIcon from "../../../assets/images/common/checked-3d.png";
import instantBookIcon from "../../../assets/images/opportunity/lightning_bolt.png";

type ModalProps = {
  isopen_assign_confirm: boolean;
  closeAssignConfirm: Function;
  opportunityDetail: any;
  multidaySelectedEventData?: any;
  applyOpportunityHandler: Function;
  opportunityDate: any;
};

const AssignmentConfirmAutoBook: React.FC<ModalProps> = (props) => {
  const {
    isopen_assign_confirm,
    closeAssignConfirm,
    opportunityDetail,
    multidaySelectedEventData,
    applyOpportunityHandler,
    opportunityDate,
  } = props;

  const [assignEventDate, setAssignEventDate] = useState<string>("");
  const [assignEventTime, setAssignEventTime] = useState<string>("");
  const [isOpen_PCardAgreement, setIsOpenPCardAgreement] =
    useState<boolean>(false);
  const [modelPCardAgreementConfirmed, setModelPCardAgreementConfirmed] =
    useState<boolean>(false);
  const [showPCardRequiredErrorMessage, setShowPCardRequiredErrorMessage] =
    useState<boolean>(false);

  const [workOnDatetime, setWorkOnDatetime] = useState<boolean>(false);
  const [early15Min, setEarly15min] = useState<boolean>(false);
  const [readyToWork, setReadyToWork] = useState<boolean>(false);
  const [friendlyAttitude, setFriendlyAttitude] = useState<boolean>(false);
  const [returnAllProducts, setReturnAllProducts] = useState<boolean>(false);
  const [confirmationsNotChecked, setConfirmationsNotChecked] =
    useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      work_on_date_time: false,
      early_15_minutes: false,
      ready_to_work: false,
      friendly_attitude: false,
      return_all_products: false,
      assign_event_date: "",
      assign_event_time: "",
    },
    mode: "onBlur",
  });
  const submitConfirmAssignment = async (status: any) => {
    setShowPCardRequiredErrorMessage(false);
    setConfirmationsNotChecked(false);
    let params;
    if (opportunityDate.type === "multiday") {
      // Book - Event IDs are already checked externally (e.g. multidaySelectedEventId)
      // Decline - All Event IDs will count towards Decline
      params = {
        status: status,
      };
    } else {
      params = {
        ids: [opportunityDetail.ue_id],
        status: status,
      };
    }
    if (status == "Booked") {
      // Confirm (Book)
      if (!workOnDatetime) {
        setConfirmationsNotChecked(true);
      } else if (!early15Min) {
        setConfirmationsNotChecked(true);
      } else if (!readyToWork) {
        setConfirmationsNotChecked(true);
      } else if (!friendlyAttitude) {
        setConfirmationsNotChecked(true);
      } else if (!returnAllProducts) {
        setConfirmationsNotChecked(true);
      } else if (
        opportunityDetail.assign_p_card_required == "1" &&
        !modelPCardAgreementConfirmed
      ) {
        setShowPCardRequiredErrorMessage(true);
      } else {
        applyOpportunityHandler(params);
      }
    } else {
      // Decline
      applyOpportunityHandler(params);
    }
  };
  const desnyAssignment = async () => {
    closeAssignConfirm("decline", opportunityDetail.ue_name);
  };

  const close_PCardAgreement = async (confirmed?: false) => {
    setIsOpenPCardAgreement(false);
    // Also hide the complete P-Card Agreement button,  show it as completed and set model p-card agreement completed
    if (confirmed) {
      await set("model_p_card_agreement_confirmed", 1);
      setModelPCardAgreementConfirmed(true);
      setShowPCardRequiredErrorMessage(false);
    }
  };

  useEffect(() => {
    (async () => {
      let model_p_card_agreement_confirmed = await get(
        "model_p_card_agreement_confirmed"
      );
      setModelPCardAgreementConfirmed(model_p_card_agreement_confirmed);
    })();
  }, []);

  return (
    <IonModal isOpen={isopen_assign_confirm} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar>
          <IonButton
            fill="clear"
            slot="start"
            onClick={() => closeAssignConfirm()}
          >
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form>
          <div className="assign-confirm">
            <div className="assign-confirm__event-info">
              <div>
                <h1>Are you committed?</h1>
              </div>
              <IonGrid>
                <IonRow>
                  <IonCol className="assign-confirm__event-container">
                    <IonLabel>Assignment:</IonLabel>
                    <IonText>
                      <div className="assign-confirm__event-container__event_name">
                        {opportunityDetail.ue_name}
                      </div>
                    </IonText>
                  </IonCol>
                </IonRow>

                {/* Multiday/ Multishift */}
                {opportunityDate.type === "multiday" && (
                  <>
                    <IonRow>
                      <IonCol>
                        <IonLabel>Dates & Times:</IonLabel>
                        <IonText>
                          <div className="assign-confirm__event-container__multiday-date-times">
                            {multidaySelectedEventData.map(
                              (event_data: any, index: number) => {
                                return (
                                  <div className="assign-confirm__event-container__multiday-date-times__date-time">
                                    <IonText>{event_data.datetime_str}</IonText>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </>
                )}

                {/* Singleday Overnight */}
                {opportunityDate.type === "not_influencer" &&
                  opportunityDate.dates.length > 1 && (
                    <>
                      <IonRow>
                        <IonCol>
                          <IonLabel>Dates & Times:</IonLabel>
                          <IonText>
                            <div className="assign-confirm__event-container__multiday-date-times">
                              {multidaySelectedEventData.map(
                                (event_data: any, index: number) => {
                                  return (
                                    <div className="assign-confirm__event-container__multiday-date-times__date-time">
                                      <IonText>
                                        {event_data.datetime_str}
                                      </IonText>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </>
                  )}

                {/* Singleday (until 12PM) */}
                {opportunityDate.type === "not_influencer" &&
                  opportunityDate.dates.length == 1 && (
                    <>
                      <IonRow>
                        <IonCol>
                          <IonLabel>Date:</IonLabel>
                          <IonText>
                            {opportunityDate.dates[0].start_date}
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonLabel>Time:</IonLabel>
                          <IonText>
                            {opportunityDate.dates[0].start_time} -{" "}
                            {opportunityDate.dates[0].end_time}
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </>
                  )}

                {/* Influencer Program */}
                {opportunityDate.type === "influencer" && (
                  <>
                    <IonRow>
                      <IonCol>
                        <IonLabel>Date:</IonLabel>
                        <IonText>
                          {opportunityDate.dates.start_date} -{" "}
                          {opportunityDate.dates.end_date}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </>
                )}
                <IonRow>
                  <IonCol>
                    <IonLabel>
                    <IonBadge className="auto-book-placeholder">
              <img
                  src={instantBookIcon}
                /> <span>Instant Book</span>
              </IonBadge>
                    </IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            {opportunityDetail.ue_p_card_agreement_required == "1" && ( 
              <div className="assign-confirm__event-info">
                <div>
                  <h1>P-Card Instructions</h1>
                </div>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <div
                          className="assign-confirm__talent-role"
                          dangerouslySetInnerHTML={{
                            __html:
                              opportunityDetail.ue_p_card_agreement_instructions_html,
                          }}
                        ></div>
                      </IonText>
                      {!modelPCardAgreementConfirmed && (
                        <>
                          <IonButton
                            mode="ios"
                            color="primary"
                            size="large"
                            onClick={() => setIsOpenPCardAgreement(true)}
                            className="form-group__button assignment-view__p-card-required-bttn"
                          >
                            Complete P-Card Agreement
                          </IonButton>
                          <PCardAgreement
                            isOpen_modal_agreement={isOpen_PCardAgreement}
                            closeModalAgreement={close_PCardAgreement}
                          />
                        </>
                      )}
                      {showPCardRequiredErrorMessage && (
                        <div className="error-message">
                          P-Card Agreement confirmation is required
                        </div>
                      )}
                      {modelPCardAgreementConfirmed && (
                        <p className="assignment-view__p-card-completed">
                          <img
                            className="assignment-view__p-card-completed-icon"
                            src={checkedIcon}
                            alt=""
                          />
                          <span>P-Card Agreement Completed</span>{" "}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}
            <div className="assign-confirm__checkboxs">
              <IonText className="assign-confirm__checkboxs__note">
                Before you confirm, please review the following to ensure you
                know exactly what is expected of you.
              </IonText>
              <div>
                <IonItem>
                  <div slot="start">
                    <IonCheckbox
                      mode="md"
                      className="form-group__checkbox-styles"
                      checked={workOnDatetime}
                      onIonChange={(e) => setWorkOnDatetime(e.detail.checked)}
                      {...register("work_on_date_time", {
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <IonText slot="start">
                    By confirming this Instant Book gig, I understand that I will
                    be officially booked for this assignment.
                    <ErrorMessage
                      errors={errors}
                      name="work_on_date_time"
                      as={<div className="error-message" />}
                    />
                  </IonText>
                </IonItem>

                <IonItem>
                  <div slot="start">
                    <IonCheckbox
                      mode="md"
                      className="form-group__checkbox-styles"
                      checked={early15Min}
                      onIonChange={(e) => setEarly15min(e.detail.checked)}
                      {...register("early_15_minutes", {
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <IonText slot="start">
                    I am 100% committed to the date(s) & time(s) selected for
                    this assignment.
                    <ErrorMessage
                      errors={errors}
                      name="early_15_minutes"
                      as={<div className="error-message" />}
                    />
                  </IonText>
                </IonItem>
                <IonItem>
                  <div slot="start">
                    <IonCheckbox
                      mode="md"
                      className="form-group__checkbox-styles"
                      checked={readyToWork}
                      onIonChange={(e) => setReadyToWork(e.detail.checked)}
                      {...register("ready_to_work", {
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <IonText slot="start">
                    If my availability changes, I must give a minimum of 72
                    hours notice and cancel this booking through the app, in
                    order to avoid penalties like losing my Instant Book status.
                    <ErrorMessage
                      errors={errors}
                      name="ready_to_work"
                      as={<div className="error-message" />}
                    />
                  </IonText>
                </IonItem>
                <IonItem>
                  <div slot="start">
                    <IonCheckbox
                      mode="md"
                      className="form-group__checkbox-styles"
                      checked={friendlyAttitude}
                      onIonChange={(e) => setFriendlyAttitude(e.detail.checked)}
                      {...register("friendly_attitude", {
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <IonText slot="start">
                    I will be receiving an event details email from my Booking
                    Agent soon, and will ask any questions to my Booking Agent
                    directly.
                    <ErrorMessage
                      errors={errors}
                      name="friendly_attitude"
                      as={<div className="error-message" />}
                    />
                  </IonText>
                </IonItem>
                <IonItem>
                  <div slot="start">
                    <IonCheckbox
                      mode="md"
                      className="form-group__checkbox-styles"
                      checked={returnAllProducts}
                      onIonChange={(e) =>
                        setReturnAllProducts(e.detail.checked)
                      }
                      {...register("return_all_products", {
                        required: "This field is required.",
                      })}
                    />
                  </div>
                  <IonText slot="start">
                    I will come prepared & ready to work by reviewing all
                    instructions, requirements and training info.
                    <ErrorMessage
                      errors={errors}
                      name="return_all_products"
                      as={<div className="error-message" />}
                    />
                  </IonText>
                </IonItem>
                {confirmationsNotChecked && (
                  <p className="selections-required">* Selections Required</p>
                )}
              </div>
            </div>
            <div className="assign-confirm__note">
              <IonCard>
                <IonCardContent>
                  <b>Note:</b>&nbsp;
                  <IonText>
                    To maintain Instant Book status, you must have a minimum 4-star
                    performance rating and zero cancellations in the past
                    quarter.
                  </IonText>
                </IonCardContent>
              </IonCard>
            </div>
            <div className="assign-confirm__submit-btn">
              <IonButton
                type="button"
                mode="ios"
                fill="outline"
                size="large"
                onClick={() => submitConfirmAssignment("Declined")}
              >
                Decline
              </IonButton>
              <IonButton
                type="button"
                mode="ios"
                color="primary"
                size="large"
                onClick={() => submitConfirmAssignment("Booked")}
              >
                Confirm
              </IonButton>
            </div>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default AssignmentConfirmAutoBook;

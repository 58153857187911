import React from "react";
import { IonLoading } from "@ionic/react";
import "./Loading.css";
type loaderProps = {
  showLoading: boolean;
};

const Loading: React.FC<loaderProps> = (props) => {
  const { showLoading } = props;
  return (
    <IonLoading
      isOpen={showLoading}
      message={"Please wait..."}
      translucent={true}
      cssClass="api_response__loading"
      spinner="circular"
    />
  );
};
export default Loading;

import React from "react";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import "./ProfileView.css";
type girdPorps = {
  elite_talent: boolean;
  age: number;
  height?: string;
  hired: string;
};

const AgeHeightHire: React.FC<girdPorps> = (props) => {
  const { elite_talent, age, height, hired } = props;

  return (
    <div className="model__age-height-hire">
      {age && (
        <div className="model__age-height-hire__age">
          <IonText className="age-height-hire__number">{age}</IonText>
          <IonText className="age-height-hire__text">Age</IonText>
        </div>
      )}
      {height && (
        <div className="model__age-height-hire__height">
          <IonText className="age-height-hire__number">{height}</IonText>
          <IonText className="age-height-hire__text">Height</IonText>
        </div>
      )}

      {(elite_talent && hired) && (
        <div className="model__age-height-hire__hire">
          <IonText className="age-height-hire__number">{hired}</IonText>
          <IonText className="age-height-hire__text">Hired</IonText>
        </div>
      )}
    </div>
  );
};
export default AgeHeightHire;

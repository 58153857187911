import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useStore } from "react-redux";
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonCard,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonLabel,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonProgressBar,
} from "@ionic/react";
import { decode } from "base64-arraybuffer";
import { useForm } from "react-hook-form";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toast } from "@capacitor/toast";

import "./Signup.css";
import "./../../../theme/theme-consumer.css";
import { ErrorMessage } from "@hookform/error-message";
import FormHeader from "../../../push-components/FormHeader/FormHeader";
import { authActions } from "../../../store/auth";
import ProgressBar from "../../../push-components/ProgressBar/ProgressBar";
import {
  getUserCameraPhoto,
  getUserCurrentPosition,
  setTalnetInfoInReduxStore,
  showToastMessage,
} from "../../../common/common";
import {
  validatePhoto,
  validatePhoneNumber,
  formatPhoneNumber,
  offlineErrorCode,
  somethingWentWrongErrMsg,
} from "../../../common/common";
import { getAppVersion } from "../../../data-services/general";
import {
  getStatesForSignUp,
  isMobileNumberValid,
  sendSignupDataToApi,
  isEmailAddressValid,
  getPoliciesContent,
  getTalentZipcode,
  generateTextVerificationCode,
  checkTextVerificationCode,
  generateEmailVerificationCode,
  checkEmailVerificationCode,
} from "../../../data-services/signup";
import moment from "moment";
import refesh_icon from "../../../assets/images/signup/img/reload.png";
import edit_icon from "../../../assets/images/signup/img/edit.png";
import take_selfie_eg1 from "../../../assets/images/signup/img/take-selfie-example-1.png";
import take_selfie_eg2 from "../../../assets/images/signup/img/take-selfie-example-2.png";
import take_selfie_eg3 from "../../../assets/images/signup/img/take-selfie-example-3.png";
import locatin_icon from "../../../assets/images/signup/img/icon-location.png";
import check_icon from "../../../assets/images/common/checked.png";
import PolicyModal from "../policyModal/policyModal";
import AvatarFileUploader from "../../../push-components/ProfileFileuploader/AvatarFileUploader";
import { isPlatform } from "@ionic/core";
import { async } from "@firebase/util";
import { talentFileUploader, logError } from "../../../data-services/assignmets";
import { get } from "../../../storage/storage";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
var initialScreenSize = window.innerHeight;
const Signup: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show_section, set_show_section] = useState({
    signup_step: 1,
    progress_bar: 0.1,
  });
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false); // true if phone number is valid
  const [isTermschecked, setIsTermsChecked] = useState<boolean>(false); //checke is terms privacy policy
  const [isReceivePushSMS, setIsReceivePushSMS] = useState<boolean>(true);
  const [isReceivePushEmail, setIsReceivePushEmail] = useState<boolean>(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isValidEmailId, setIsValidEmailId] = useState<boolean>(false);
  const [signupStateList, setSignupStateList] = useState<any>([]);
  const [generatedTalentDisplyName, setGeneratedTalentDisplyName] =
    useState<string>();
  // mobile section btn endabled/disabled
  const [mobileSecBtn, setMobileSecBtn] = useState<boolean>(false);
  // personal info btn
  const [personalInfoSecBtn, setPersonalInfoSecBtn] = useState<boolean>(false);
  // email section btn
  const [emailSecBtn, setEmailSecBtn] = useState<boolean>(false);
  // zipcode section btn
  const [zipcodeSecBtn, setZipcodeSecBtn] = useState<boolean>(false);
  const [signupError, setSignupError] = useState<any>("");
  // get current location btn
  const [getLocationBtn, setGetLocationBtn] = useState<boolean>(false);
  //
  const [selfieImage, setSelfieImage] = useState<any>("");

  const [ispwdlowercase, setIspwdlowercase] = useState(false);
  const [ispwduppercase, setIspwduppercase] = useState(false);
  const [ispwdnumeric, setIspwdnumeric] = useState(false);
  const [ispwdlength, setIspwdlength] = useState(false);

  // terms and privacy policy modal state
  const [showPolicyModal, setShowPolicyModal] = useState<boolean>(false);
  const [policiesContent, setPoliciesContent] = useState<any>("");
  const [policiesTitle, setPoliciesTitle] = useState<string>("");
  // birth date default input type
  const [birthDateInputType, setBirthDateInputType] = useState<string>("");
  const [showphotoloading, setShowPhotoLoading] = useState<boolean>(false);
  const [showPhotoValidateAlert, setShowPhotoValidateAlert] =
    useState<boolean>(false);
  const [showPhotoValidateMessage, setShowPhotoValidateMessage] =
    useState<any>("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [facePredictionError, setFacePredictionError] = useState<any>("");

  const [qrDetails, setQrDetails] = useState<any>();

  const getUUID = async () => {
    let uuid = localStorage.getItem('uuid');
    return uuid;
  }

  useEffect(() => {
    (async() => {
      let qrInfo = await get('qrInfo');
      console.log(qrInfo);
      setQrDetails({
        customer: {
          name: 'Sparkling Ice',
          logo: ''
        },
        eventId: qrInfo?.ue_id,
        modelUserId: qrInfo?.modelUserId,
        layoutDetails: {
          layId: 1,
          layLogo: '',
          layPrimaryColor: ''
        },
        signupDetails: {
          snpId: 1,
          snpText1: ''
        },
        signupFields: {
          firstName: {
            enabled: true,
            label: 'FIRST NAME',
            placeholder: ""
          },
          lastName: {
            enabled: true,
            label: 'LAST NAME',
            placeholder: ""
          },
          phone: {
            enabled: false,
            label: 'PHONE',
            placeholder: ""
          },
          email: {
            enabled: true,
            label: 'EMAIL ADDRESS',
            placeholder: ""
          },
          zipcode: {
            enabled: false,
            label: 'ZIP CODE',
            placeholder: "",
            ue_zipcode: qrInfo?.ue_zipcode
          }
        }
      });

    })();
    
  }, []);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      talent_mobile: "",
      talent_terms: false,
      talent_mobile_otp: "",
      promotion_text_msg: false,
      talent_fname: "",
      talent_lname: "",
      talent_display_name: "",
      talent_gender: "",
      talent_birth_date: "",
      talent_address: "",
      talent_apartment_no: "",
      talent_city: "",
      talent_country: "",
      talent_state: "",
      talent_email: "",
      marketing_emails: false,
      talent_passowrd: "",
      talent_zipcode: "",
    },
    mode: "onBlur",
  });

  const validation = (event: any, type: any) => {
    var re1 = new RegExp("(?=.*[A-Z])");
    var re2 = new RegExp("(?=.*[a-z])");
    var re3 = new RegExp("(?=.*[0-9])");

    if (re1.test(event.target.value)) {
      setIspwduppercase(true);
    } else {
      setIspwduppercase(false);
    }
    if (re2.test(event.target.value)) {
      setIspwdlowercase(true);
    } else {
      setIspwdlowercase(false);
    }
    if (re3.test(event.target.value)) {
      setIspwdnumeric(true);
    } else {
      setIspwdnumeric(false);
    }
    if (event.target.value.length >= 8) {
      setIspwdlength(true);
    } else {
      setIspwdlength(false);
    }
  };

  function showSelfieSection() {
    backStepHandle();
    setSelfieImage("");
    set_show_section({
      signup_step: 8,
      progress_bar: 0.8,
    });
  }

  const nextStepHandler = async () => {
    if (Object.keys(errors).length === 0) {
      //  check if there any validations error
      let next_step = show_section.signup_step + 1;
      let increase_bar = show_section.progress_bar + 0.1;
      set_show_section({
        signup_step: next_step,
        progress_bar: increase_bar,
      });
    }
  };
  const backStepHandle = async () => {
    let back_step = show_section.signup_step - 1;
    if (back_step) {
      let decrease_bar = show_section.progress_bar - 0.1;
      set_show_section({
        signup_step: back_step,
        progress_bar: decrease_bar,
      });
      setSelfieImage(""); //set empty take selft state
    } else {
      history.push("/Notifications");
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const singupFormHandler = async () => {
    if (show_section.signup_step === 1) {
      if (!isTermschecked) {
        setError("talent_terms", {
          type: "manual",
          message: "This field is required.",
        });
        return;
      }

      if (qrDetails?.signupFields?.phone.enabled === true) {
        setMobileSecBtn(true);
        let is_valid_mobile = await isMobileNumberValid(
          getValues("talent_mobile")
        ); // only passing inital 14 letter to api (213) 123-2132
        if (is_valid_mobile?.success) {
          let text_varification: any = await generateOTP(
            getValues("talent_mobile")
          );
          if (text_varification?.success) {
            nextStepHandler();
          } else {
            let error_msg = "";
            if (text_varification?.data?.errorCode == 0) {
              error_msg = text_varification?.data?.message;
            } else {
              error_msg = text_varification.message;
            }
            setError("talent_mobile", {
              type: "manual",
              message: error_msg,
            });
            showToastMessage(error_msg);
          }
          setMobileSecBtn(false);
        } else {
          setError("talent_mobile", {
            type: "manual",
            message: is_valid_mobile.message,
          });
          showToastMessage(is_valid_mobile.message);
          setMobileSecBtn(false);
        }
      }

      let hasEmail = getValues("talent_email");
      if (qrDetails?.signupFields?.email.enabled === true) {
        // only passing inital 14 letter to api (213) 123-2132
        await generateOTPEmail(
          getValues("talent_email")
        );
        nextStepHandler();
      }

    } else if (show_section.signup_step === 2) {
      setMobileSecBtn(true);

      if (qrDetails?.signupFields?.phone.enabled === true) {
        let verify_otp = await checkTextVerificationCode(
          getValues("talent_mobile"),
          getValues("talent_mobile_otp")
        );
        if (verify_otp?.success) {
          // nextStepHandler();
          // SUBMIT HERE!!!
          await onSubmitSignupForm();
        } else {
          setError("talent_mobile_otp", {
            type: "manual",
            message: verify_otp.message,
          });
        }
      }

      if (qrDetails?.signupFields?.email.enabled === true) {
        let verify_otp = await checkEmailVerificationCode(
          getValues("talent_email"),
          getValues("talent_mobile_otp")
        );
        if (verify_otp?.success) {
          // nextStepHandler();
          // SUBMIT HERE!!!
          await onSubmitSignupForm();
        } else {
          setError("talent_mobile_otp", {
            type: "manual",
            message: verify_otp.message,
          });
        }
      }

      setMobileSecBtn(false);
    // } else if (show_section.signup_step === 3) {
    //   setPersonalInfoSecBtn(true);
    //   nextStepHandler();
    //   setPersonalInfoSecBtn(false);
    // } else if (show_section.signup_step === 4) {
    //   let years = moment(moment(), "YYYY-MM-DD").diff(
    //     birthDateInputType,
    //     "years",
    //     false
    //   );

    //   if (years >= 18) {
        // talent age should be >= 18
      //   nextStepHandler();
      // } else {
      //   setError("talent_birth_date", {
      //     type: "manual",
      //     message: "You must be at least 18 years old to proceed",
      //   });
      // }

      // birthDateInputType
    }
  };

  const onSubmitSignupForm = async () => {
    setUploadProgress(5);
    setLoading(true);
    setZipcodeSecBtn(true);
    setSignupError("");
    const apiVersion = await getAppVersion();
    let versionFromTheAPI;
    let versionFromTheApplication;
    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      versionFromTheAPI = "WEB";
      versionFromTheApplication = "WEB";
    } else if (isPlatform("ios")) {
      versionFromTheAPI = apiVersion.ios;
      versionFromTheApplication = process.env.REACT_APP_VERSION_IOS;
    } else if (isPlatform("android")) {
      versionFromTheAPI = apiVersion.android;
      versionFromTheApplication = process.env.REACT_APP_VERSION_ANDROID;
    }

    let signupInfoObject = {
      // model_mobile: getValues("talent_mobile"),
      // model_phone: getValues("talent_mobile"),
      //model_terms:isTermschecked,
      model_receive_mail_alert: isReceivePushSMS ? "yes" : "no",
      model_fname: getValues("talent_fname"),
      model_lname: getValues("talent_lname"),
      // model_stage_name: getValues("talent_display_name"),
      // model_gender: getValues("talent_gender"),
      // model_birth_date: birthDateInputType, // getValues("talent_birth_date"),
      // model_address: getValues("talent_address"),
      // model_apartment_number: getValues("talent_apartment_no"),
      // model_city2: getValues("talent_city"),
      // model_state: getValues("talent_state"),
      // model_country: getValues("talent_country"),
      model_email: getValues("talent_email").trim(),
      // model_receive_mail: isReceivePushEmail ? "yes" : "no",
      // user_password: getValues("talent_passowrd"),
      model_zipcode: qrDetails?.signupFields?.zipcode.ue_zipcode ? qrDetails?.signupFields?.zipcode.ue_zipcode : getValues("talent_zipcode"),
      // user_name: getValues("talent_email"),
      isPushTalentMobile: "1",
      model_city: "0",
      model_suit_size_id: "0",
      model_neck_size_id: "0",
      model_chest_id: "0",
      model_waist_id: "0",
      model_bust_size_id: "0",
      model_hip_size: "0",
      model_dress_size_id: "0",
      model_bust_cup_id: "0",
      model_weight: "0",
      model_eye_color: "0",
      model_primary_language: "English",
      model_show_size: "0",
      model_intrest_ar: "0",
      model_looks_as_ar: "0",
      model_ethnicity_ar: "0",
      model_business_aptitude: "0",
      model_sales_aptitude: "0",
      model_speaking_capability: "0",
      model_min_hourly_rate: "0",
      model_min_flat_rate: "0",
      user_comes_from: "{ue_id:"+qrDetails?.eventId+",model_user_id:"+qrDetails?.modelUserId+"}",
      model_recruiter_agent_user_id: "0",
      versionFromTheApplication: versionFromTheAPI,
      versionFromTheAPI: versionFromTheApplication,
      uuid: localStorage.getItem('uuid')
      // facePredictionsError: facePredictionError
    };

    let singup_api_response = await sendSignupDataToApi(signupInfoObject);

    try {
      if (singup_api_response?.success) {
        let user_token = singup_api_response.authData.data.user_token;
        dispatch(
          authActions.login(
            setTalnetInfoInReduxStore(singup_api_response.authData.data)
          )
        );

        // let upload_selfie = new FormData();
        // upload_selfie.append("type", "selfie");

        // let blob = new Blob([new Uint8Array(decode(selfieImage[0].base64))], {
        //   type: "image/jpeg",
        // });
        // let file_obj = new File([blob], selfieImage[0].name, {
        //   type: "image/jpeg",
        //   lastModified: new Date().getTime(),
        // });

        // upload_selfie.append("files", file_obj);
        // let file_upload: any = await talentFileUploader(
        //   upload_selfie,
        //   user_token,
        //   handleProgress
        // );

        // if (file_upload?.success) {
        //   window.location.replace(
        //     `/Notifications/${singup_api_response.model_user_id}`
        //   );
        // } else {
        //   if (file_upload?.data?.errorCode == offlineErrorCode) {
        //     await showToastMessage(file_upload?.data?.message);
        //     setSignupError(file_upload?.data?.message);
        //   } else if (file_upload?.message) {
        //     showToastMessage(file_upload.message);
        //     setSignupError(file_upload.message);
        //   } else {
        //     showToastMessage(somethingWentWrongErrMsg);
        //     setSignupError(somethingWentWrongErrMsg);
        //   }
        // }
      } else {
        if (singup_api_response?.data?.errorCode == offlineErrorCode) {
          await showToastMessage(singup_api_response?.data?.message);
          setSignupError(singup_api_response?.data?.message);
        } else if (singup_api_response?.message) {
          showToastMessage(singup_api_response.message);
          setSignupError(singup_api_response.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
          setSignupError(somethingWentWrongErrMsg);
        }
      }
    } catch (e: any) {
      // setZipcodeSecBtn(false);
      showToastMessage(
        "Something went wrong, please try again! Our technical team has been notified."
      );
      setSignupError(
        "Something went wrong, please try again! Our technical team has been notified."
      );

      await logError(
        "Signup - onSubmitSignupForm - Exception thrown",
        "Talent App - Signup",
        JSON.stringify(e.message)
      );

      setLoading(false);
      setUploadProgress(0);
      return;
    }
    // setZipcodeSecBtn(false);
    window.location.href="/TakeTest/"+qrDetails?.eventId+"/0";
    // setTimeout(() => {
    //   history.push('/TakeTest/219890/0');
    // }, 300);
    
    setLoading(false);
    setUploadProgress(0);
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  const closePolicyModal = () => {
    setShowPolicyModal(false);
  };
  const getPoliciesAndTerms = async (
    policy_type: string,
    policy_title: string
  ) => {
    let policy_api = await getPoliciesContent(policy_type);
    if (policy_api?.success) {
      setShowPolicyModal(true);
      setPoliciesTitle(policy_title);
      setPoliciesContent(policy_api.data);
    } else {
      if (policy_api?.data?.errorCode == 0) {
        showToastMessage(policy_api?.data?.message);
      }
    }
  };
  const checkIsEmailValid = (event: any) => {
    // check is email valid and show checked icon on input field
    let regexp = /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i;
    setIsValidEmailId(regexp.test(event.target.value));
  };
  const usePhotoGallery = async () => {
    let image = await getUserCameraPhoto("base64");
    if (image?.base64String) {
      setSelfieImage([
        {
          name: "user_selfie.jpg",
          type: "image/jpeg",
          size: 789,
          file: "data:text/plain;base64," + image.base64String,
          base64: image.base64String,
          data: {},
        },
      ]);
    }
  };

  const getTalentCurrentPosition = async () => {
    setGetLocationBtn(true);
    const { latitude, longitude }: any = await getUserCurrentPosition();
    if (latitude && longitude) {
      let talent_zipcode = await getTalentZipcode(latitude, longitude);
      if (talent_zipcode?.success) {
        setValue("talent_zipcode", talent_zipcode.data.zipcode);
      } else {
        await Toast.show({
          text:
            talent_zipcode?.data?.errorCode == 0
              ? talent_zipcode?.data?.message
              : "Unable to fetch zipcode ",
        });
      }
    }
    setGetLocationBtn(false);
  };

  const formatAndSetPhoneNumber = (event: any) => {
    let phone_no = event.target.value;

    let formatted_no = formatPhoneNumber(phone_no);

    if (formatted_no) {
      setValue("talent_mobile", formatted_no);
      setIsValidPhone(validatePhoneNumber(formatted_no));
      return;
    } else {
      setIsValidPhone(false);
    }
  };

  const getStateListBaseOnCountry = async (country: string) => {
    setSignupStateList(await getStatesForSignUp(country));
  };

  const generateOTP = async (number: string) => {
    let text_varification: any = await generateTextVerificationCode(number);
    return text_varification;
  };

  const generateOTPEmail = async (email: string) => {
    let text_varification: any = await generateEmailVerificationCode(email);
    return text_varification;
  };

  const validateRequiredField = (value: any) => {
    // Trim the value to check if it's only white spaces
    if (value.trim() === "") {
      return "This field is required.";
    }
    return true; // Return true if validation passes
  };

  useEffect(() => {
    let headshot_image: any = document.querySelector("#talend_selfie");
    if (headshot_image) {
      headshot_image.addEventListener("click", (event: any) => {
        let files = event.target.files;
      });
    }
  }, [isValidPhone, selfieImage]);

  return (
    <IonPage>
      {/* <FormHeader title="Sign Up" backStepHandle={backStepHandle} /> */}
      {/* <ProgressBar progress_value={show_section.progress_bar} /> */}
      <IonContent className={`lay__talent-signup--container--${qrDetails?.theme} talent-signup-container talent-signup-step-${show_section.signup_step}`}>
        {typeof qrDetails != 'undefined' && 
          <div className={`lay__talent-signup--${qrDetails?.theme} talent-signup`}>
{console.log("qrDetails", qrDetails)}
            <form onSubmit={handleSubmit(singupFormHandler)}>

              {/* accepts mobile no section  */}
              { show_section.signup_step === 1 && (
                <section className="mobile talent-signup__section">

                  <div className="lay__logo">
                    <img src="/images/cons/cp1/logo.png" />
                  </div>
                  <div className="lay__lb">
                    <img src="/images/cons/cp1/icon-lb1.png" />
                  </div>
                  <div className="lay__rb">
                    <img src="/images/cons/cp1/icon-rb1.png" />
                  </div>
                  <div className="lay__bottom-bar">
                    <img src="/images/cons/cp1/bottom-bar--bluebg.png" />
                  </div>

                  {qrDetails?.signupDetails?.snpId === 1 && 
                  <div className="sn__id1">
                    <div className="talent-signup__section-title">
                      <img src="/images/cons/cp1/content1.png" />
                      {/* <div className="sn__content2">
                        Take our survey and enter to win one of 10 amazing prizes!
                      </div> */}
                    </div>
                  </div>}
                  
                  {/* F Name */}
                  {qrDetails?.signupFields?.firstName.enabled === true &&
                    <div className="form-group">
                      <IonLabel className="form-group__label">
                      {qrDetails?.signupFields?.firstName.label} 
                      </IonLabel>

                      <input
                        type="text"
                        placeholder={qrDetails?.signupFields?.firstName.placeholder} 
                        className={
                          errors.talent_fname !== undefined
                            ? "login-input-styles-error"
                            : "form-group__input-styles"
                        }
                        // id="phone-numbers"
                        {...register("talent_fname", {
                          required: "This field is required.",
                        })}
                      ></input>
                      
                      <ErrorMessage
                        errors={errors}
                        name="talent_fname"
                        as={<div className="error-message" />}
                      />
                    </div>
                  }

                  {/* L Name */}
                  {qrDetails?.signupFields?.lastName.enabled === true &&
                    <div className="form-group">
                    <IonLabel className="form-group__label">
                      {qrDetails?.signupFields?.lastName.label}
                    </IonLabel>

                    <input
                      type="text"
                      placeholder={qrDetails?.signupFields?.lastName.placeholder}
                      className={
                        errors.talent_lname !== undefined
                          ? "login-input-styles-error"
                          : "form-group__input-styles"
                      }
                      // id="phone-numbers"
                      {...register("talent_lname", {
                        required: "This field is required.",
                      })}
                    ></input>
                    
                    <ErrorMessage
                      errors={errors}
                      name="talent_lname"
                      as={<div className="error-message" />}
                    />
                    </div>
                  }

                  {/* Phone */}
                  {qrDetails?.signupFields?.phone.enabled === true &&
                    <div className="form-group">
                      <IonLabel className="form-group__label">
                      {qrDetails?.signupFields?.phone.label} 
                      </IonLabel>

                      <input
                        type="tel"
                        placeholder={qrDetails?.signupFields?.phone.placeholder} 
                        className={
                          errors.talent_mobile !== undefined
                            ? "login-input-styles-error"
                            : "form-group__input-styles"
                        }
                        // id="phone-numbers"
                        {...register("talent_mobile", {
                          required: "This field is required.",
                        })}
                        maxLength={14}
                        onInput={formatAndSetPhoneNumber}
                      ></input>
                      {isValidPhone && (
                        <img
                          className="talent-signup__check-icon"
                          src={check_icon}
                          alt=""
                        />
                      )}
                      <ErrorMessage
                        errors={errors}
                        name="talent_mobile"
                        as={<div className="error-message" />}
                      />
                    </div>
                  }

                  {/* Email */}
                  {qrDetails?.signupFields?.email.enabled === true &&
                    <div className="form-group">
                    <IonLabel className="form-group__label">
                      {qrDetails?.signupFields?.email.label}
                    </IonLabel>
                    <input
                      type="text"
                      placeholder={qrDetails?.signupFields?.email.placeholder}
                      className={
                        errors.talent_email !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_email", {
                        required: "This field is required.",
                        pattern: {
                          value:
                            /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
                          message: "Invalid email address",
                        },
                      })}
                      onChange={(e: any) => checkIsEmailValid(e)}
                    ></input>
                    {isValidEmailId && (
                      <img
                        className="talent-signup__check-icon"
                        src={check_icon}
                        alt=""
                      />
                    )}

                    <ErrorMessage
                      errors={errors}
                      name="talent_email"
                      as={<div className="error-message" />}
                    />
                  </div>
                  }

                  {/* Zip code */}
                  {qrDetails?.signupFields?.zipcode.enabled === true &&
                    <div className="form-group">
                      <IonLabel className="form-group__label">
                        {qrDetails?.signupFields?.zipcode.label}
                      </IonLabel>
                      <input
                        type="text"
                        placeholder={qrDetails?.signupFields?.zipcode.placeholder}
                        maxLength={10}
                        className={
                          errors.talent_zipcode !== undefined
                            ? "form-input-styles-error"
                            : "form-group__input-styles"
                        }
                        {...register("talent_zipcode", {
                          validate: validateRequiredField,
                        })}
                      ></input>
                      <ErrorMessage
                        errors={errors}
                        name="talent_zipcode"
                        as={<div className="error-message" />}
                      />
                    </div>
                  }

                  <div className="section-footer" style={{marginTop: '15px'}}>
                    <div className="mobile__terms">
                      <div>
                        <IonLabel>
                          <IonCheckbox
                            className="form-group__checkbox-styles"
                            mode="md"
                            {...register("talent_terms", {
                              required: "This field is required.",
                            })}
                            checked={isTermschecked}
                            onIonChange={(e) => setIsTermsChecked(e.detail.checked)}
                          ></IonCheckbox>
                          
                          By checking this box I provide my express written constent to receive emails from {qrDetails?.customer?.name} about future updates.
                          Msg & data rates may apply. Reply HELP for help and STOP to cancel. I agree to the&nbsp;
                          <a
                            onClick={() =>
                              getPoliciesAndTerms(
                                "getModelTermsOfUse",
                                "Terms of Use"
                              )
                            }
                          >
                            Terms of Use
                          </a>
                          ,&nbsp;
                          <a
                            onClick={() =>
                              getPoliciesAndTerms(
                                "getPrivacyPolicy",
                                "Privacy Policy"
                              )
                            }
                          >
                            Privacy Policy
                          </a> and&nbsp;
                          <a
                            onClick={() =>
                              getPoliciesAndTerms(
                                "sparklingIceGiveawayRules1",
                                "Sparkling Ice Giveaway Rules"
                              )
                            }
                          >
                            Sparkling Ice Giveaway Rules
                          </a>
                          .
                        </IonLabel>
                        <br />
                        <ErrorMessage
                          errors={errors}
                          name="talent_terms"
                          as={<div className="error-message" />}
                        />
                      </div>
                    </div>

                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      disabled={mobileSecBtn}
                    >
                      Submit
                    </IonButton>
                  </div>
                </section>
              )}

              {/* opt section  */}
              {show_section.signup_step === 2 && (
                <section className="verification-code talent-signup__section">

                  <div className="lay__logo">
                    <img src="/images/cons/cp1/logo--2.png" />
                  </div>
                  <div className="lay__lb">
                    <img style={{height: '274px'}} src="/images/cons/cp1/icon-lb2.png" />
                  </div>
                  <div className="lay__cb">
                    <img style={{height: '290px'}} src="/images/cons/cp1/icon-cb2.png" />
                  </div>
                  <div style={{width: '102px'}} className="lay__rb">
                    <img style={{height: 'auto'}} src="/images/cons/cp1/icon-rb2-1.png" />
                  </div>

                  <div className="lay__bottom-bar">
                    <img src="/images/cons/cp1/bottom-bar--bluebg.png" />
                  </div>

                  {qrDetails?.signupDetails?.snpId === 1 && 
                  <div className="sn__id1">
                    <div className="talent-signup__section-title">
                      <img src="/images/cons/cp1/content2.png" />
                      {/* <div className="sn__content2">
                        Take our survey and enter to win one of 10 amazing prizes!
                      </div> */}
                    </div>
                  </div>}

                  {/* <div className="talent-signup__section-title">
                    <h1>
                      Enter the 4-digit
                      <br />
                      verification code sent to:
                      <br />
                      
                      {qrDetails?.signupFields?.phone.enabled === true && 
                        <span className="verification-code__mobile-number">
                          {getValues("talent_mobile")}
                        </span>
                      }

                      {qrDetails?.signupFields?.email.enabled === true && 
                        <span className="verification-code__mobile-number">
                          {getValues("talent_email")}
                        </span>
                      }
                    </h1>
                  </div> */}
                  <div className="form-group">
                    <input
                      type="tel"
                      placeholder="#"
                      className={
                        errors.talent_mobile_otp !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_mobile_otp", {
                        required: "This field is required.",
                        minLength: {
                          value: 4,
                          message: "Minimum 4 digits required.",
                        },
                        maxLength: {
                          value: 4,
                          message: "Max 4 digits allowed",
                        },
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_mobile_otp"
                      as={<div className="error-message" />}
                    />
                  </div>
                  {/* <div className="verification-code__options">
                    <div
                      className="verification-code__option"
                      onClick={() => generateOTP(getValues("talent_mobile"))}
                    >
                      <img alt="" src={refesh_icon} />
                      Resend code
                    </div>
                    <div
                      className="verification-code__option"
                      onClick={backStepHandle}
                    >
                      <img alt="" src={edit_icon} />

                      {qrDetails?.signupFields?.phone.enabled === true && <>
                      Edit mobile number </>}
                      {qrDetails?.signupFields?.email.enabled === true && <>
                      Edit email address </>}

                    </div>
                  </div> */}
                  <div className="section-footer">
                    <div className="verification-code__promotion">
                      {/* <div>
                        <IonCheckbox
                          className="form-group__checkbox-styles talent-signup-checkbox"
                          mode="md"
                          {...register("promotion_text_msg", {
                            required: false,
                          })}
                          checked={isReceivePushSMS}
                          onIonChange={(e) =>
                            setIsReceivePushSMS(e.detail.checked)
                          }
                        ></IonCheckbox>
                      </div> */}
                      {/* <div className="promotion__descrition">
                        <div className="promotion__descrition-1">
                          Sign me up to receive PUSH news about events,
                          promotions, and activities via text message.
                        </div>
                        <div className="promotion__descrition-2">
                          You can unsubscribe at any time by replying "STOP" to
                          one of our texts. Standard message rates may apply. Reply HELP for help. Message frequency varies.
                        </div>
                      </div> */}
                    </div>
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      disabled={mobileSecBtn}
                    >
                      Submit
                    </IonButton>
                  </div>
                </section>
              )}
              {/* personal info section  */}
              {show_section.signup_step === 3 && (
                <section className="full-name talent-signup__section">
                  <div className="">
                    <h1>What's your name?</h1>
                  </div>
                  <div className="talent-signup__section-description full-name__padding-bottom">
                    Enter the name that appears on your
                    <span> driver's license</span>, <span>state ID</span>, or
                    <span> passport</span>.
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">First Name</IonLabel>
                    <input
                      type="text"
                      placeholder="John"
                      className={
                        errors.talent_fname !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_fname", {
                        validate: validateRequiredField,
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_fname"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">Last Name</IonLabel>
                    <input
                      type="text"
                      placeholder="Doe"
                      className={
                        errors.talent_lname !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_lname", {
                        validate: validateRequiredField,
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_lname"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      Display Name
                      <span className="optional-text"> (Optional)</span>
                    </IonLabel>
                    <input
                      type="text"
                      placeholder="John Doe"
                      className={
                        errors.talent_display_name !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_display_name")}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_display_name"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="talent-signup__section-description">
                    Please include the name you would like to appear on your
                    public profile if different from your legal first name.
                  </div>

                  <div className="section-footer">
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      disabled={personalInfoSecBtn}
                    >
                      Next
                    </IonButton>
                  </div>
                </section>
              )}
              {/* basic info sectoin */}
              {show_section.signup_step === 4 && (
                <section className="basic-information talent-signup__section">
                  <div className="">
                    <h1>
                      Enter your basic
                      <br />
                      information
                    </h1>
                  </div>
                  <div className="talent-signup__section-description">
                    Enter your gender and birth date to complete your profile.
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">Gender</IonLabel>
                    <IonSelect
                      placeholder="--Select--"
                      className={
                        errors.talent_gender !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_gender", {
                        required: "This field is required.",
                      })}
                    >
                      <IonSelectOption value="Female">Female</IonSelectOption>
                      <IonSelectOption value="Male">Male</IonSelectOption>
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_gender"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      Date of Birth
                    </IonLabel>
                    <IonDatetime
                      placeholder="MM/DD/YYYY"
                      mode="ios"
                      displayFormat="MM/DD/YYYY"
                      value={birthDateInputType}
                      className={
                        errors.talent_birth_date !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      onIonChange={(e: any) => {
                        setBirthDateInputType(e.detail.value);
                        setValue("talent_birth_date", e.detail.value);
                      }}
                    ></IonDatetime>
                    <input
                      placeholder="MM/DD/YYYY"
                      type="hidden"
                      className={
                        errors.talent_birth_date !== undefined
                          ? "form-input-styles-error birth_date"
                          : "form-group__input-styles birth_date"
                      }
                      {...register("talent_birth_date", {
                        required: "This field is required.",
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_birth_date"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="section-footer">
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      // onClick={() => nextStepHandler(5, 0.5)}
                    >
                      Next
                    </IonButton>
                  </div>
                </section>
              )}
              {/* address section  */}
              {show_section.signup_step === 5 && (
                <section className="street-address talent-signup__section">
                  <div className="talent-signup__section-title">
                    <h1>
                      Enter your street
                      <br />
                      address
                    </h1>
                  </div>

                  <div className="form-group">
                    <IonLabel className="form-group__label">Address</IonLabel>
                    <input
                      type="text"
                      placeholder="Street number & name"
                      className={
                        errors.talent_gender !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_address", {
                        validate: validateRequiredField,
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_address"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      Apartment Number
                      <span className="optional-text"> (Optional)</span>
                    </IonLabel>
                    <input
                      type="text"
                      maxLength={20}
                      placeholder="Eg. ERT232"
                      className="form-group__input-styles"
                      {...register("talent_apartment_no")}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_apartment_no"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">City</IonLabel>
                    <input
                      type="text"
                      placeholder="Eg. Los Angeles"
                      className={
                        errors.talent_city !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_city", {
                        validate: validateRequiredField,
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_city"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">Country</IonLabel>
                    <IonSelect
                      onIonChange={(e: any) =>
                        getStateListBaseOnCountry(e.detail.value)
                      }
                      placeholder="--Select--"
                      className={
                        errors.talent_country !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_country", {
                        validate: validateRequiredField,
                      })}
                    >
                      <IonSelectOption key="usa" value="USA">
                        USA
                      </IonSelectOption>
                      <IonSelectOption key="canada" value="Canada">
                        Canada
                      </IonSelectOption>
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_state"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">State</IonLabel>
                    <IonSelect
                      placeholder="--Select--"
                      className={
                        errors.talent_state !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_state", {
                        validate: validateRequiredField,
                      })}
                    >
                      {Object.keys(signupStateList).map(
                        (state_key, state_index) => (
                          <IonSelectOption key={state_key} value={state_key}>
                            {signupStateList[state_key]}
                          </IonSelectOption>
                        )
                      )}
                    </IonSelect>
                    <ErrorMessage
                      errors={errors}
                      name="talent_state"
                      as={<div className="error-message" />}
                    />
                  </div>

                  <div className="section-footer">
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      // onClick={() => nextStepHandler(6, 0.6)}
                    >
                      Next
                    </IonButton>
                  </div>
                </section>
              )}
              {/* email section  */}
              {show_section.signup_step === 6 && (
                <section className="email-address talent-signup__section">
                  <div className="talent-signup__section-title">
                    <h1>What's your email?</h1>
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">
                      Email Address
                    </IonLabel>
                    <input
                      type="text"
                      placeholder="Type an email"
                      className={
                        errors.talent_email !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_email", {
                        required: "This field is required.",
                        pattern: {
                          value:
                            /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
                          message: "Invalid email address",
                        },
                      })}
                      onChange={(e: any) => checkIsEmailValid(e)}
                    ></input>
                    {isValidEmailId && (
                      <img
                        className="talent-signup__check-icon"
                        src={check_icon}
                        alt=""
                      />
                    )}

                    <ErrorMessage
                      errors={errors}
                      name="talent_email"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="section-footer">
                    <div className="email-subcription">
                      <div>
                        <IonCheckbox
                          className="form-group__checkbox-styles talent-signup-checkbox"
                          mode="md"
                          {...register("marketing_emails", {
                            required: false,
                          })}
                          checked={isReceivePushEmail}
                          onIonChange={(e) =>
                            setIsReceivePushEmail(e.detail.checked)
                          }
                        ></IonCheckbox>
                      </div>
                      <div className="talent-signup__section-description">
                        I want to receive marketing and update emails from Push. I
                        can also opt out of receiving these at any time by tapping
                        unsubscribe in the emails.
                        <ErrorMessage
                          errors={errors}
                          name="marketing_emails"
                          as={<div className="error-message" />}
                        />
                      </div>
                    </div>
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      disabled={emailSecBtn}
                    >
                      Next
                    </IonButton>
                  </div>
                </section>
              )}
              {/* password sectino  */}
              {show_section.signup_step === 7 && (
                <section className="create-password talent-signup__section">
                  <div className="">
                    <h1>Create a password</h1>
                  </div>
                  <div className="talent-signup__section-description">
                    Please create a password using the security guidelines below.
                  </div>
                  <div className="form-group">
                    <IonLabel className="form-group__label">Password</IonLabel>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter a password"
                      className={
                        errors.talent_passowrd !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      onKeyPress={(e) => validation(e, "new")}
                      onKeyDown={(e) => validation(e, "new")}
                      onKeyUp={(e) => validation(e, "new")}
                      {...register("talent_passowrd", {
                        required: "This field is required.",
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                          message:
                            "Password requirements not met. Please ensure that your password meets the criteria in the form.",
                        },
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_passowrd"
                      as={<div className="error-message" />}
                    />

                    <i
                      className="create-password__eye-icon"
                      onClick={togglePasswordVisiblity}
                    >
                      {passwordShown ? eyeSlash : eye}
                    </i>
                  </div>
                  <div className="create-password__guidelines">
                    <IonBadge
                      mode="md"
                      className={
                        ispwdlowercase === true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      lowercase letter
                    </IonBadge>
                    <IonBadge
                      mode="md"
                      className={
                        ispwduppercase === true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      uppercase letter
                    </IonBadge>
                    <br />
                    <IonBadge
                      mode="md"
                      className={
                        ispwdnumeric === true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      numbers (0-9)
                    </IonBadge>
                    <IonBadge
                      mode="md"
                      className={
                        ispwdlength === true
                          ? "password__guideline highlight"
                          : "password__guideline"
                      }
                    >
                      8+ characters
                    </IonBadge>
                  </div>
                  <div className="section-footer">
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      // onClick={() => nextStepHandler(8, 0.8)}
                    >
                      Next
                    </IonButton>
                  </div>
                </section>
              )}
              {/* photo section  */}
              {show_section.signup_step === 8 && (
                <section className="take-selfie talent-signup__section">
                  <div className="talent-signup__section-title">
                    <h1>Take a selfie</h1>
                  </div>
                  {selfieImage === "" ? (
                    <>
                      <div>
                        <img src={take_selfie_eg1} alt="" />
                      </div>
                      <div>
                        <img src={take_selfie_eg2} alt="" />
                      </div>
                      <div>
                        <img src={take_selfie_eg3} alt="" />
                      </div>
                      <div className="take-selfie__photo-guideline">
                        <IonCard>
                          <p>
                            Clients will use your photo to confirm attendance to a
                            job, so make sure your photo identifies who you are.
                          </p>
                          <p>
                            If you don't meet above profile photo requirements,
                            your account may not be accessible until you update
                            your photo.
                          </p>
                          <p>
                            This will not be your profile photo. This photo is
                            just to verify your identity
                          </p>
                        </IonCard>
                      </div>
                      <div className="section-footer">
                        <IonButton
                          expand="block"
                          mode="ios"
                          type="button"
                          className="form-group__button section-next-button take-photo-button"
                          onClick={usePhotoGallery}
                        >
                          Take Photo
                        </IonButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="take-selfie__file--uploader">
                        {selfieImage && (
                          <AvatarFileUploader
                            name="talent_selfie"
                            limit="1"
                            files={[]}
                            avatar_cropp_ratio={{
                              showGrid: false,
                              ratio: "1:1",
                              minWidth: 256,
                              minHeight: 256,
                            }}
                            preloaded_file={JSON.stringify(selfieImage)}
                            id="talent_selfie"
                          />
                        )}
                      </div>
                      <IonLoading
                        cssClass="my-custom-class"
                        isOpen={showphotoloading}
                        message={"Please wait..."}
                        mode="ios"
                      />
                      <div className="section-footer">
                        <IonButton
                          expand="block"
                          mode="ios"
                          type="submit"
                          className="form-group__button section-next-button"
                        >
                          Next
                        </IonButton>
                      </div>
                    </>
                  )}
                </section>
              )}
              {/* zip sectino  */}
              {show_section.signup_step === 9 && (
                <section className="zip-code talent-signup__section">
                  <div className="talent-signup__section-title">
                    <h1>
                      Where do you want to
                      <br />
                      find jobs?
                    </h1>
                  </div>

                  <div className="form-group">
                    <IonLabel className="form-group__label">Zip Code</IonLabel>
                    <input
                      type="text"
                      placeholder="Eg. 83971"
                      maxLength={24}
                      className={
                        errors.talent_zipcode !== undefined
                          ? "form-input-styles-error"
                          : "form-group__input-styles"
                      }
                      {...register("talent_zipcode", {
                        validate: validateRequiredField,
                      })}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="talent_zipcode"
                      as={<div className="error-message" />}
                    />
                  </div>
                  <div className="zip-code__use-location-btn">
                    <IonButton
                      fill="clear"
                      onClick={getTalentCurrentPosition}
                      disabled={getLocationBtn}
                    >
                      <img src={locatin_icon} alt="" /> Use current location
                    </IonButton>
                  </div>

                  <div className="section-footer">
                    <div className="error-message">{signupError}</div>
                    {loading && <IonProgressBar value={uploadProgress / 100} />}
                    <IonButton
                      expand="block"
                      mode="ios"
                      type="submit"
                      className="form-group__button section-next-button"
                      disabled={zipcodeSecBtn}
                    >
                      Next
                    </IonButton>
                  </div>
                </section>
              )}
            </form>
          </div>}
      </IonContent>
      <IonAlert
        mode="ios"
        isOpen={showPhotoValidateAlert}
        onDidDismiss={() => setShowPhotoValidateAlert(false)}
        header={"Detect Human Face"}
        message={showPhotoValidateMessage}
        buttons={[
          {
            text: "Retake",
            handler: () => {
              showSelfieSection();
            },
          },
        ]}
      />
      <PolicyModal
        title={policiesTitle}
        heading={`heading`}
        description={policiesContent}
        isOpen={showPolicyModal}
        closeAction={closePolicyModal}
      />
    </IonPage>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonText,
  IonSearchbar,
} from "@ionic/react";
import "./NewInternalTalentReferrals.css";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import {
  createModelEventReferral,
  getAvailableInternalReferralsList,
} from "../../../data-services/edit-profile";
import Loading from "../../../push-components/loading/Loading";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
} from "../../../common/common";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SlidingCardWidget from "../../../push-components/SlidingCardWidget/SlidingCardWidget";

const ChevronRightIcon = <FontAwesomeIcon icon={faChevronRight} />;

let crew_none_filter_array: any = [];
let frinds_none_filter_array: any = [];
let wip_none_filter_array: any = [];

type InternalModalProps = {
  is_open_new_internal_referral: boolean;
  closeNewInernalModal: Function;
  event_id: string;
};

const NewInternalTalentReferrals: React.FC<InternalModalProps> = (Props) => {
  const { is_open_new_internal_referral, closeNewInernalModal, event_id } =
    Props;
  const dispatch = useDispatch();

  let history: any = useHistory();

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [talentCrew, setTalentCrew] = useState<any>([]);
  const [workInPast, setWorkInPast] = useState<any>([]);
  const [workInPastUnfiltered, setWorkInPastUnfiltered] = useState<any>([]);
  const [talentFriendsUnfiltered, setTalentFriendsUnfiltered] = useState<any>(
    []
  );
  const [groupedContacts, setGroupedContacts] = useState<any>([]);
  const [noData, setNoData] = useState<boolean>(false);
  const [checkedTalentsBoxesObjects, setCheckedTalentsBoxesObjects] =
    useState<any>([]);
  const [checkedTalentsBoxesModelUserIDs, setCheckedTalentsBoxesModelUserIDs] =
    useState<any>([]);

  const fetchTalentContactHandler = async () => {
    setShowLoader(true);
    let talent_contact: any = [];

    let api_response = await getAvailableInternalReferralsList(talent_contact);
    if (api_response?.success) {
      crew_none_filter_array = api_response.crew;
      frinds_none_filter_array = api_response.friends;
      wip_none_filter_array = api_response.worked_in_the_past;
      if (
        crew_none_filter_array.length == 0 &&
        frinds_none_filter_array.length == 0 &&
        wip_none_filter_array.length == 0
      ) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setTalentCrew(api_response.crew);
      setWorkInPast(api_response.worked_in_the_past);
      setWorkInPastUnfiltered(api_response.worked_in_the_past);
      setTalentFriendsUnfiltered(api_response.friends);
      groupTalentFriendsByStartingLetter(api_response.friends);
    } else if (api_response?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (api_response?.data?.errorCode == offlineErrorCode) {
      showToastMessage(api_response?.data?.message);
    } else if (api_response?.message) {
      showToastMessage(api_response.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setShowLoader(false);
  };

  const submitInvitations = async () => {
    setDisabledSubmitBtn(true);
    if (checkedTalentsBoxesObjects.length > 0) {
      let api_res: any = await createModelEventReferral(
        checkedTalentsBoxesObjects
      );
      if (api_res?.success) {
        await showToastMessage(api_res.message);
        history.push("/Opportunities");
      } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.data?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    setDisabledSubmitBtn(false);
  };

  /**
   * My Contacts - Search input change handler
   */
  const handleContactSearchChange = (event: any) => {
    const filteredContactOptions = talentFriendsUnfiltered.filter(
      (option: any) =>
        option.model_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    groupTalentFriendsByStartingLetter(filteredContactOptions);
  };

  /**
   * My Contacts - Checkbox input change handler
   * @param event
   */
  const handleContactCheckboxChange = (event: any) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckedTalentsBoxesObjects([
        ...checkedTalentsBoxesObjects,
        {
          model_user_id: event.detail.value.model_user_id,
          ue_id: event_id,
        },
      ]);
      setCheckedTalentsBoxesModelUserIDs([
        ...checkedTalentsBoxesModelUserIDs,
        value.model_user_id,
      ]);
    } else {
      setCheckedTalentsBoxesObjects(
        checkedTalentsBoxesObjects.filter(function (obj: any) {
          return obj.model_user_id !== event.detail.value.model_user_id;
        })
      );
      setCheckedTalentsBoxesModelUserIDs(
        checkedTalentsBoxesModelUserIDs.filter(
          (item: any) => item !== value.model_user_id
        )
      );
    }
  };

  /**
   * My Contacts - Create a list of contacts, grouped by their starting letters
   * @param contacts
   */
  const groupTalentFriendsByStartingLetter = (contacts: any) => {
    let groupedContactsArray: any = {};
    contacts.forEach((contact: any) => {
      let firstLetter = contact.model_name[0].toUpperCase();
      if (!groupedContactsArray[firstLetter]) {
        groupedContactsArray[firstLetter] = [];
      }
      groupedContactsArray[firstLetter].push(contact);
    });
    setGroupedContacts(groupedContactsArray);
  };

  const searchInReferralPreviouslyWorkedWithList = (ev: any) => {
    const target = ev.target as HTMLIonSearchbarElement;
    let text: any = target.value;
    if (text) {
      let obj: any = workInPastUnfiltered.filter((y: any) =>
        y.model_name.toLowerCase().includes(text.toLowerCase())
      );
      setWorkInPast(obj);
    }

    if (text == "") {
      setWorkInPast(wip_none_filter_array);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTalentContactHandler();
    })();
  }, []);
  return (
    <IonModal isOpen={is_open_new_internal_referral} mode="ios">
      <SimpleHeaderView
        title="Refer a Friend"
        bgcolor="primary"
        buttonType="undefined"
        backStepUrl="Opportunities"
      ></SimpleHeaderView>
      <IonContent>
        <div
          className={
            !workInPast.length
              ? "new_internal_referral new_internal_referral--incl-opps"
              : "new_internal_referral"
          }
        >
          {!showLoader && (
            <>
              <div className="new-referral__items">
                <IonList className="new-referral__items__list">
                  <div className="new-referral__items__list__header-container">
                    <IonListHeader>
                      <span>My Crew</span>
                    </IonListHeader>
                    <span className="new-referral__items__list__header-container__earn-points">
                      Earn Points +
                    </span>
                  </div>
                  {talentCrew.length > 0 && (
                    <>
                      {talentCrew.map((item: any, index: number) => (
                        <IonItem key={index}>
                          <IonAvatar
                            className="new-referral__items__list__img"
                            slot="start"
                          >
                            <img src={item.photo} alt="" />
                          </IonAvatar>
                          <div className="new-referral__items__list_detail">
                            <h2>{item.model_name}</h2>
                            <IonText className="new-referral__items__list_detail__points-text">
                              {checkedTalentsBoxesModelUserIDs.includes(
                                item.model_user_id
                              )
                                ? "+ 10 Points"
                                : ""}
                            </IonText>
                          </div>
                          <IonCheckbox
                            mode="md"
                            slot="end"
                            className="form-group__checkbox-styles"
                            key={index}
                            value={item}
                            checked={checkedTalentsBoxesModelUserIDs.includes(
                              item.model_user_id
                            )}
                            onIonChange={(e: any) =>
                              handleContactCheckboxChange(e)
                            }
                          ></IonCheckbox>
                        </IonItem>
                      ))}
                      <p className="new-referral__items__list__my-crew">
                        <a onClick={() => history.push("/MyCrew")}>
                          Add to Crew &nbsp;
                          <i className="faChevronRight-icon">
                            {ChevronRightIcon}
                          </i>
                        </a>
                      </p>
                    </>
                  )}
                  {!talentCrew.length && (
                    <p key={0}>
                      <p>
                        Add friends to your crew to easily share and refer to
                        upcoming opportunities!
                        <br />
                        <br />
                        Each time your friends complete an assignment that you
                        referrred them to, you'll earn 10 points!
                      </p>
                      <p className="new-referral__items__list__my-crew">
                        <a onClick={() => history.push("/MyCrew")}>
                          Add to Crew &nbsp;
                          <i className="faChevronRight-icon">
                            {ChevronRightIcon}
                          </i>
                        </a>
                      </p>
                    </p>
                  )}
                </IonList>
              </div>
              {workInPast.length > 0 && (
                <div className="new-referral__items">
                  <IonList className="new-referral__items__list">
                    <div className="new-referral__items__list__header-container">
                      <IonListHeader>
                        <span>
                          <span className="new-referral__items__list__header-container__hide-on-small-width">
                            Previously
                          </span>{" "}
                          Worked With
                        </span>
                      </IonListHeader>
                      <span className="new-referral__items__list__header-container__earn-points">
                        Earn Points +
                      </span>
                    </div>
                    <IonSearchbar
                      mode="ios"
                      className="mycrew_searchbar"
                      debounce={500}
                      placeholder="Who are you looking for?"
                      onIonChange={(ev: any) =>
                        searchInReferralPreviouslyWorkedWithList(ev)
                      }
                    ></IonSearchbar>
                    {workInPast.map((item: any, index: number) => (
                      <IonItem key={item.model_user_id}>
                        <IonAvatar
                          className="new-referral__items__list__img"
                          slot="start"
                        >
                          <img src={item.photo} alt="" />
                        </IonAvatar>
                        <div className="new-referral__items__list_detail">
                          <h2>{item.model_name}</h2>
                          <IonText className="new-referral__items__list_detail__points-text">
                            {checkedTalentsBoxesModelUserIDs.includes(
                              item.model_user_id
                            )
                              ? "+ 10 Points"
                              : ""}
                          </IonText>
                        </div>
                        <IonCheckbox
                          mode="md"
                          slot="end"
                          className="form-group__checkbox-styles"
                          key={item.model_user_id}
                          value={item}
                          checked={checkedTalentsBoxesModelUserIDs.includes(
                            item.model_user_id
                          )}
                          onIonChange={(e: any) =>
                            handleContactCheckboxChange(e)
                          }
                        ></IonCheckbox>
                      </IonItem>
                    ))}
                  </IonList>
                </div>
              )}
              {!workInPast.length && !talentCrew.length && (
                <div>
                  <SlidingCardWidget
                    slider_card_title="Additional Gigs"
                    list_route="Opportunities"
                    widget_type="nearby_opportunities"
                    no_data_text="You have no opportunities yet"
                    current_event_id={event_id}
                    additional_opps_widget={true}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </IonContent>
      <IonFooter mode="ios">
        {!showLoader && !noData && (
          <IonButton
            type="button"
            className="form-group__button new_internal_referral__submit_btn"
            expand="block"
            mode="ios"
            onClick={submitInvitations}
            // disabled={false}
            disabled={disabledSubmitBtn}
          >
            Submit
          </IonButton>
        )}
      </IonFooter>
      <Loading showLoading={showLoader} />
    </IonModal>
  );
};

export default NewInternalTalentReferrals;

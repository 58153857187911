import React, { useState } from "react";
import { IonButton, IonContent, IonLabel, IonPage } from "@ionic/react";
import "./ReferFriend.css";
import star_img from "../../assets/images/refer-friend/refer-push.png";
import ExternalTalentReferrals from "../Referrals/ExternalTalentReferrals/ExternalTalentReferrals";

const ReferFriend: React.FC = () => {
  const [isOpenExternalReferralModal, setIsOpenExternalReferralModal] =
  useState<boolean>(false);

  const closeExternalReferralModal = async () => {
    setIsOpenExternalReferralModal(false);
  };


  return (
    <IonPage>
      <IonContent>
          <div className="refer-friend__top-text">
            <IonLabel>Almost done!</IonLabel>
          </div>
          <section className="refer-friend">
          <div className="refer-friend__image">
            <img src={star_img} alt="" />
          </div>

          <div className="refer-friend__title">
            <h1>Refer a Friend</h1>
          </div>
          <div className="refer-friend__description">
            <p>
              Referring a friend to PUSH will allow you to <br /> unlock more
              opportunities
            </p>
            <p>
              Referrals may also be completed in you <br /> Settings.
            </p>
          </div>

          <div className="refer-friend__buttons">
            <IonButton
              expand="block"
              mode="ios"
              type="button"
              className="form-group__button"
              onClick={() => setIsOpenExternalReferralModal(true)}
            >
              Refer a friend
            </IonButton>
            <IonButton className="form-group__button" fill="clear" expand="block" mode="ios" type="button" href={`/Home`}>
              Not Now
            </IonButton>
          </div>
        </section>
      </IonContent>
      {isOpenExternalReferralModal && (<ExternalTalentReferrals
        is_open_external_referral={isOpenExternalReferralModal}
        closeNewExternalModal={closeExternalReferralModal}
        fromSignup={true}
      />)}
    </IonPage>
  );
};

export default ReferFriend;

/** */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { IonLabel, IonBadge, IonInput, IonButton } from "@ionic/react";
import "./ChangePasswordView.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "@splidejs/splide/dist/css/splide.min.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { showToastMessage } from "../../common/common";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const ChangePasswordView: React.FC<{
  passwordtype: string;
  callbackFromParent: any;
}> = (props) => {
  const { passwordtype, callbackFromParent } = props;
  const history = useHistory();
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [ispwdlowercase, setIspwdlowercase] = useState(false);
  const [ispwduppercase, setIspwduppercase] = useState(false);
  const [ispwdnumeric, setIspwdnumeric] = useState(false);
  const [ispwdlength, setIspwdlength] = useState(false);

  const togglePasswordVisiblity = (item: string) => {
    if (item == "currentPasswordIcon") {
      setCurrentPasswordShown(currentPasswordShown ? false : true);
    } else if (item == "newPasswordIcon") {
      setNewPasswordShown(newPasswordShown ? false : true);
    } else if (item == "confirmPasswordIcon") {
      setConfirmPasswordShown(confirmPasswordShown ? false : true);
    }
  };

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onBlur",
  });

  const changepasswordSubmitHandler: SubmitHandler<any> = (data) => {
    if (getValues("newPassword") !== getValues("confirmPassword")) {
      setError("confirmPassword", {
        type: "manual",
        message: "The new password and the confirmed password should match.",
      });
      showToastMessage(
        "The new password and the confirmed password should match."
      );
    } else {
      callbackFromParent(
        getValues("currentPassword"),
        getValues("newPassword"),
        getValues("confirmPassword")
      );
    }
  };

  const validation = (event: any, type: any) => {
    var re1 = new RegExp("(?=.*[A-Z])");
    var re2 = new RegExp("(?=.*[a-z])");
    var re3 = new RegExp("(?=.*[0-9])");

    if (re1.test(event.target.value)) {
      setIspwduppercase(true);
    } else {
      setIspwduppercase(false);
    }
    if (re2.test(event.target.value)) {
      setIspwdlowercase(true);
    } else {
      setIspwdlowercase(false);
    }
    if (re3.test(event.target.value)) {
      setIspwdnumeric(true);
    } else {
      setIspwdnumeric(false);
    }
    if (event.target.value.length >= 8) {
      setIspwdlength(true);
    } else {
      setIspwdlength(false);
    }
  };

  return (
    <>
      <form
        className="password-view-form"
        onSubmit={handleSubmit(changepasswordSubmitHandler)}
      >
        <div className="password-view-container">
          {props.passwordtype == "1" && (
            <div className="password_view">
              <div className="form-group">
                <IonLabel className="form-group__label">
                  Current Password
                </IonLabel>
                <input
                  type={currentPasswordShown ? "text" : "password"}
                  placeholder="Enter current password"
                  className={
                    errors.currentPassword !== undefined
                      ? "form-input-styles-error"
                      : "form-group__input-styles"
                  }
                  {...register("currentPassword", {
                    required: "This field is required.",
                  })}
                ></input>
                <ErrorMessage
                  errors={errors}
                  name="currentPassword"
                  as={<div className="error-message" />}
                />
                <i
                  className="create-password__eye-icon"
                  onClick={() => togglePasswordVisiblity("currentPasswordIcon")}
                >
                  {currentPasswordShown ? eyeSlash : eye}
                </i>
              </div>
            </div>
          )}
          <div className="password_view">
            <div className="form-group">
              <IonLabel className="form-group__label">New Password</IonLabel>
              <input
                type={newPasswordShown ? "text" : "password"}
                placeholder="Enter new password"
                onKeyPress={(e) => validation(e, "new")}
                onKeyDown={(e) => validation(e, "new")}
                onKeyUp={(e) => validation(e, "new")}
                className={
                  errors.newPassword !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("newPassword", {
                  required: "This field is required.",
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message: "Password requirements not met. Please ensure that your password meets the criteria in the form.",
                  },
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name="newPassword"
                as={<div className="error-message" />}
              />
              <i
                className="create-password__eye-icon"
                onClick={() => togglePasswordVisiblity("newPasswordIcon")}
              >
                {newPasswordShown ? eyeSlash : eye}
              </i>
            </div>
            <div className="create-password__guidelines">
              <IonBadge
                mode="md"
                className={
                  ispwdlowercase == true
                    ? "password__guideline highlight"
                    : "password__guideline"
                }
              >
                lowercase letter
              </IonBadge>
              <IonBadge
                mode="md"
                className={
                  ispwduppercase == true
                    ? "password__guideline highlight"
                    : "password__guideline"
                }
              >
                uppercase letter
              </IonBadge>
              <br />
              <IonBadge
                mode="md"
                className={
                  ispwdnumeric == true
                    ? "password__guideline highlight"
                    : "password__guideline"
                }
              >
                numbers (0-9)
              </IonBadge>
              <IonBadge
                mode="md"
                className={
                  ispwdlength == true
                    ? "password__guideline highlight"
                    : "password__guideline"
                }
              >
                8+ characters
              </IonBadge>
            </div>
          </div>
          <div className="password_view">
            <div className="form-group">
              <IonLabel className="form-group__label">
                Confirm New Password
              </IonLabel>
              <input
                type={confirmPasswordShown ? "text" : "password"}
                placeholder="Enter a password"
                className={
                  errors.confirmPassword !== undefined
                    ? "form-input-styles-error"
                    : "form-group__input-styles"
                }
                {...register("confirmPassword", {
                  required: "This field is required.",
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message: "Password requirements not met. Please ensure that your password meets the criteria in the form.",
                  },
                })}
              ></input>
              <ErrorMessage
                errors={errors}
                name="confirmPassword"
                as={<div className="error-message" />}
              />
              <i
                className="create-password__eye-icon"
                onClick={() => togglePasswordVisiblity("confirmPasswordIcon")}
              >
                {confirmPasswordShown ? eyeSlash : eye}
              </i>
            </div>
          </div>
        </div>
        <div className="changepassword-view__submit">
          <IonButton
            expand="block"
            mode="ios"
            type="submit"
            className="form-group__button"
          >
            Save
          </IonButton>
        </div>
      </form>
    </>
  );
};

export default ChangePasswordView;

import { CapacitorHttp } from '@capacitor/core';
import { get, set } from "../storage/storage";
import { Network } from "@capacitor/network";
import { noInternetResponse, unauthorizedResponse } from "../common/common";

export const getOpportunities = async (filters: string, excluded_event_id?: any) => {
  // Retrieve the status from the provided 'filters' query string
  let status = "";
  let order_by = "";
  var pairs = (filters[0] === "?" ? filters.substr(1) : filters).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    if (decodeURIComponent(pair[0]) === "status") {
      status = decodeURIComponent(pair[1] || "");
    }
    if (decodeURIComponent(pair[0]) === "order_by") {
      order_by = decodeURIComponent(pair[1] || "");
    }
  }

  if(order_by == "popular" || (status == 'Nearby')) {
    // Get opportunity from the storage
    let opps = await getOppsCache(status, excluded_event_id);
    if (opps?.success && opps?.data?.model_opportunities?.length) {
      return opps;
    }
  }
  
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    // Get Model Opps request
    let api_url = `${
      process.env.REACT_APP_HP_API_URL
    }/userEvent/getModelOpportunities?show=true&withTipalti=1${filters}`;

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken
      },
    };
    let upcoming_event_api_res = await CapacitorHttp.get(config);

    // Save opportunity in the storage
    if(order_by == "popular" || (status == 'Nearby')){
      setOppsCache(upcoming_event_api_res.data, status);
    }
    return upcoming_event_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const invalidateOppsCache = async () => {
  await set("cachedOppsAvailable", []);
  await set("cachedOppsPending", []);
  await set("cachedOppsDeclined", []);
  await set("cachedOppsNearby", []);
};

export const setOppsCache = async (opportunities: any, status: string) => {
  if (status === "Available") {
    await set("cachedOppsAvailable", opportunities);
  } else if (status === "Pending") {
    await set("cachedOppsPending", opportunities);
  } else if (status === "Declined") {
    await set("cachedOppsDeclined", opportunities);
  } else if (status === "Nearby") {
    await set("cachedOppsNearby", opportunities);
  }
};

export const getOppsCache = async (status: string, excluded_event_id?: any) => {
  let cachedOpps: { data: { model_opportunities: any[]; }; };
  if (status === "Available" || status === "Nearby") {
    if(status === "Available") {
      cachedOpps = await get("cachedOppsAvailable");
    } else {
      cachedOpps = await get("cachedOppsNearby");
    }
    if (
      typeof cachedOpps !== "undefined" &&
      cachedOpps?.data?.model_opportunities?.length > 0
    ) {
      if(excluded_event_id) {
        if (Array.isArray(excluded_event_id)) {
          excluded_event_id.forEach(id => {
            cachedOpps.data.model_opportunities = cachedOpps.data.model_opportunities.filter((event: any) => event.ue_id != id);
          });
        } else {
            cachedOpps.data.model_opportunities = cachedOpps.data.model_opportunities.filter((event: any) => event.ue_id != excluded_event_id);
        }
        
        if(cachedOpps.data.model_opportunities.length > 0) {
          return cachedOpps;
        }
      } else {
        return cachedOpps;
      }
    }
  } else if (status === "Pending") {
    let cachedOpps = await get("cachedOppsPending");
    if (
      typeof cachedOpps !== "undefined" &&
      cachedOpps?.data?.model_opportunities?.length > 0
    ) {
      return cachedOpps;
    }
  } else if (status === "Declined") {
    let cachedOpps = await get("cachedOppsDeclined");
    if (
      typeof cachedOpps !== "undefined" &&
      cachedOpps?.data?.model_opportunities?.length > 0
    ) {
      return cachedOpps;
    }
  }

  return null;
};

export const getOppFromCache = async (event_id: any) => {
  let opps = null;
  let found = false;
  for (const status of ["Available", "Nearby", "Pending", "Declined"]) {
    opps = await getOppsCache(status);
    if (opps?.success && opps?.data?.model_opportunities?.length) {
      let obj = opps.data.model_opportunities.find(
        (o: { ue_id: any }) => o.ue_id == event_id
      );
      if (obj) {
        found = true;
        opps.data.model_opportunities = [obj];
        break;
      }
      
    }
  }
  return found ? opps : null;
};

export const checkSession = async () => {
  const authToken = await get("login_user_token");
  if (!authToken) {
    return unauthorizedResponse;
  }
  let config: any = {
    url: encodeURI(
      `${process.env.REACT_APP_HP_API_URL}/userEvent/checkSession`
    ),
    headers: {
      "Content-Type": "application/json",
      token: authToken,
    },
  };

  let opportunity_res = await CapacitorHttp.get(config);
  return opportunity_res.data;
};

export const trackOppView = async (ue_id: any) => {
  const authToken = await get("login_user_token");
  if (!authToken) {
    return unauthorizedResponse;
  }
  let config: any = {
    url: encodeURI(
      `${process.env.REACT_APP_HP_API_URL}/userEvent/trackOppView`
    ),
    headers: {
      "Content-Type": "application/json",
      token: authToken,
    },
    data: {
      ue_id: ue_id
    },
  };

  let opportunity_res = await CapacitorHttp.post(config);
  return opportunity_res.data;
};

export const getModelOpportunities = async (op_event_id: number) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelOpportunities?ue_id=${op_event_id}&withTipalti=1`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let opportunity_res = await CapacitorHttp.get(config);
    return opportunity_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getModelTests = async (
  op_event_id: string,
  required_after_booking: string
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${
          process.env.REACT_APP_HP_API_URL
        }/userEvent/modelTestsConsumer?ue_id=${op_event_id}&includeFailedTests=1&required_after_booking=${required_after_booking}&token=${authToken}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let test_questions = await CapacitorHttp.get(config);
    return test_questions.data;
  } else {
    return noInternetResponse;
  }
};

export const saveModalTaskTestQuestions = async (
  submiTestQuestionsData: any
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let options: any = {
      url: encodeURI(
        `${
          process.env.REACT_APP_HP_API_URL
        }/userEvent/saveModelTests?token=${authToken}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
      data: submiTestQuestionsData,
    };
    let submitQuestions_res = await CapacitorHttp.post(options);
    return submitQuestions_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getEventTypes = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${
          process.env.REACT_APP_HP_API_URL
        }/userEvent/getEventTypes?token=${authToken}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let event_types = await CapacitorHttp.get(config);
    return event_types.data;
  } else {
    return noInternetResponse;
  }
};

export const getAssignmentTypes = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${
          process.env.REACT_APP_HP_API_URL
        }/userEvent/getAssignmentTypes?token=${authToken}`
      ),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let event_types = await CapacitorHttp.get(config);
    return event_types.data;
  } else {
    return noInternetResponse;
  }
};

export const saveModelOpportunitySelection = async (
  ue_id: any,
  am_status: string,
  latitude?: string,
  longitude?: string,
  ref?: string
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let options: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelOpportunitySelection`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        ue_id: ue_id,
        am_status: am_status,
        latitude: latitude ? latitude : null,
        longitude: longitude ? longitude : null,
        ref: ref? ref: null
      },
    };
    let api_res = await CapacitorHttp.post(options);
    if (api_res.data.success) {
      // Refresh the available opportunity cache
      await invalidateOppsCache();
      let opps;
      if (api_res?.data?.data?.cachedOppsAvailable) {
        try {
          opps = JSON.parse(api_res.data.data.cachedOppsAvailable);
          await setOppsCache(
            {
              success: true,
              message: "",
              data: { model_opportunities: opps.opportunitiesList },
            },
            "Available"
          );
        } catch (e) {
          // Do nothing, invalid or empty response
        }
      }
      if (api_res?.data?.data?.cachedOppsPending) {
        try {
          opps = JSON.parse(api_res.data.data.cachedOppsPending);
          await setOppsCache(
            {
              success: true,
              message: "",
              data: { model_opportunities: opps.opportunitiesList },
            },
            "Pending"
          );
        } catch (e) {
          // Do nothing, invalid or empty response
        }
      }

      if (api_res?.data?.data?.cachedOppsDeclined) {
        try {
          opps = JSON.parse(api_res.data.data.cachedOppsDeclined);
          await setOppsCache(
            {
              success: true,
              message: "",
              data: { model_opportunities: opps.opportunitiesList?.length > 0 },
            },
            "Declined"
          );
        } catch (e) {
          // Do nothing, invalid or empty response
        }
      }

      if (api_res?.data?.data?.cachedOppsNearby?.length > 0) {
        try {
          opps = JSON.parse(api_res.data.data.cachedOppsNearby);
          await setOppsCache(
            {
              success: true,
              message: "",
              data: { model_opportunities: opps.opportunitiesList },
            },
            "Nearby"
          );
        } catch (e) {
          // Do nothing, invalid or empty response
        }
      }
    }
    return api_res.data;
  } else {
    return noInternetResponse;
  }
};

/**
 * Save (Error) Log Info (Not necessarily event related)
 * @param message 
 * @param type 
 * @param data 
 * @returns 
 */
export const saveOpportunityLinkSource = async (source: any, ue_id: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      url: encodeURI(`${process.env.REACT_APP_HP_API_URL}/userEvent/saveOpportunityLinkSource`),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        source: source,
        ue_id: ue_id
      },
    };
    let api_response = await CapacitorHttp.post(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

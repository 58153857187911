import React, { Component, useEffect } from "react";
import "./jquery.fileuploader.min.css";
import "./jquery.fileuploader-theme-avatar.css";
import './jquery.fileuploader-theme-cropping-popup.css';

const $ = window.jQuery;
class AvatarFileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        props: props,
      name: "files",

      options: {
        addMore: true,
        files: props?.files,
        enableApi: true,

        extensions: ["image/*"],
        fileMaxSize: 10,
        changeInput: " ",
        theme: "avatar",
        thumbnails: {
			box: '<div class="fileuploader-wrapper">' +
					'<div class="fileuploader-items"></div>' +
					'<div class="fileuploader-droparea" data-action="fileuploader-input"><i class="fileuploader-icon-main"></i></div>' +
				   '</div>' +
					'<div class="fileuploader-menu">' +
						'<button type="button" class="fileuploader-menu-open"><i class="fileuploader-icon-menu"></i></button>' +
						'<ul>' +
							'<li><a data-action="fileuploader-input"><i class="fileuploader-icon-upload"></i> ${captions.upload}</a></li>' +
							'<li><a data-action="fileuploader-edit"><i class="fileuploader-icon-edit"></i> ${captions.edit}</a></li>' +
							'<li><a data-action="fileuploader-remove"><i class="fileuploader-icon-trash"></i> ${captions.remove}</a></li>' +
						'</ul>' +
					'</div>',
			item: '<div class="fileuploader-item">' +
				      '${image}' +
					  '<span class="fileuploader-action-popup" data-action="fileuploader-edit"></span>' +
					  '<div class="progressbar3" style="display: none"></div>' +
					'</div>',
			item2: null,
			itemPrepend: true,
			startImageRenderer: true,
            canvasImage: false,
			_selectors: {
				list: '.fileuploader-items'
			},
			popup: {
				arrows: false,
				onShow: function(item) {
					item.popup.html.addClass('is-for-avatar');
                    item.popup.html.on('click', '[data-action="remove"]', function(e) {
                        item.popup.close();
                        item.remove();
                    }).on('click', '[data-action="cancel"]', function(e) {
                        item.popup.close();
                    }).on('click', '[data-action="save"]', function(e) {
						if (item.editor && !item.isSaving) {
							item.isSaving = true;
                        	item.editor.save();
						}
						if (item.popup.close)
							item.popup.close();
                    });
                },
				onHide: function(item) {
					if (!item.isSaving && !item.uploaded && !item.appended) {
						item.popup.close = null;
						item.remove();
					}
				} 	
			},
			onItemShow: function(item) {
				if (item.choosed)
					item.html.addClass('is-image-waiting');
			},
			onImageLoaded: function(item, listEl, parentEl, newInputEl, inputEl) {
				
                // if (item.choosed && !item.isSaving) { orgnal code
				if (!item.isSaving) {
					if(item?.editor) {
						item.image.hide();
						item.popup.open();
						item.editor.cropper();
					} else {
					}
				} else if (item.data.isDefault)
					item.html.addClass('is-default');
				else if (item.image.hasClass('fileuploader-no-thumbnail'))
					item.html.hide();
            },
			onItemRemove: function(html) {
				html.fadeOut(250, function() {
					html.remove();
				});
			}
		},
		dragDrop: {
			container: '.fileuploader-wrapper'
		},
		editor: {
			maxWidth: 512,
			maxHeight: 512,
			quality: 90,
            cropper: props.avatar_cropp_ratio,
            // cropper: {
			// 	showGrid: false,
			// 	ratio: '1:1',
			// 	minWidth: 256,
			// 	minHeight: 256,
			// },
			onSave: function(base64, item, listEl, parentEl, newInputEl, inputEl) {
				// var api = $.fileuploader.getInstance(inputEl);
                
                // if (!base64)
                //     return;
				
				// // blob
				// item.editor._blob = api.assets.dataURItoBlob(base64, item.type);
				
				// if (item.upload) {
				// 	if (api.getFiles().length == 2 && (api.getFiles()[0].data.isDefault || api.getFiles()[0].upload))
				// 		api.getFiles()[0].remove();
				// 	parentEl.find('.fileuploader-menu ul a').show();
					
				// 	if (item.upload.send)
				// 		return item.upload.send();
				// 	if (item.upload.resend)
				// 		return item.upload.resend();
				// } else if (item.appended) {
				// 	var form = new FormData();
					
				// 	// hide current thumbnail (this is only animation)
				// 	item.image.addClass('fileuploader-loading').html('');
				// 	item.html.find('.fileuploader-action-popup').hide();
				// 	parentEl.find('[data-action="fileuploader-edit"]').hide();
					
				// 	// send ajax
				// 	form.append(inputEl.attr('name'), item.editor._blob);
				// 	form.append('fileuploader', true);
				// 	form.append('name', item.name);
				// 	form.append('editing', true);
				// 	$.ajax({
				// 		url: api.getOptions().upload.url,
				// 		data: form,
				// 		type: 'POST',
				// 		processData: false,
				// 		contentType: false
				// 	}).always(function() {
				// 		delete item.isSaving;
				// 		item.reader.read(function() {
				// 			item.html.find('.fileuploader-action-popup').show();
				// 			parentEl.find('[data-action="fileuploader-edit"]').show();
				// 			item.popup.html = item.popup.node = item.popup.editor = item.editor.crop = item.editor.rotation = item.popup.zoomer = null;
				// 			item.renderThumbnail();
				// 		}, null, true);
				// 	});
				// }
			}
        },
     
		afterRender: function(listEl, parentEl, newInputEl, inputEl) {
			var api = $.fileuploader.getInstance(inputEl);
			
			// remove multiple attribute
			inputEl.removeAttr('multiple');
            
            // set drop container
            api.getOptions().dragDrop.container = parentEl.find('.fileuploader-wrapper');
			
			// disabled input
			if (api.isDisabled()) {
				parentEl.find('.fileuploader-menu').remove();
			}
			
			// [data-action]
			parentEl.on('click', '[data-action]', function() {
				var $this = $(this),
					action = $this.attr('data-action'),
					item = api.getFiles().length ? api.getFiles()[api.getFiles().length-1] : null;
				
				switch (action) {
					case 'fileuploader-input':
						api.open();
						break;
					case 'fileuploader-edit':
						if (item && item.popup) {
							if (!$this.is('.fileuploader-action-popup'))
								item.popup.open();
							item.editor.cropper();
						}
						break;
					case 'fileuploader-retry':
						if (item && item.upload.retry)
							item.upload.retry();
						break;
					case 'fileuploader-remove':
						if (item)
							item.remove();
						break;
				}
			});
			
			// menu
			$('body').on('click', function(e) {
				var $target = $(e.target),
					$parent = $target.closest('.fileuploader');
				
				$('.fileuploader-menu').removeClass('is-shown');
				if ($target.is('.fileuploader-menu-open') || $target.closest('.fileuploader-menu-open').length)
					$parent.find('.fileuploader-menu').addClass('is-shown');
			});
		},
		onEmpty: function(listEl, parentEl, newInputEl, inputEl) {
			var api = $.fileuploader.getInstance(inputEl),
				defaultAvatar = inputEl.attr('data-fileuploader-default');
			
			if (defaultAvatar && !listEl.find('> .is-default').length)
				api.append({name: '', type: 'image/png', size: 0, file: defaultAvatar, data: {isDefault: true, popup: false, listProps: {is_default: true}}});
			
			parentEl.find('.fileuploader-menu ul a').hide().filter('[data-action="fileuploader-input"]').show();
		},
		
		captions: $.extend(true, {}, $.fn.fileuploader.languages['en'], {
			edit: 'Edit',
			upload: 'Upload',
			remove: 'Remove',
			errors: {
        		filesLimit: 'Only 1 file is allowed to be uploaded.',
			}
		})
      },
    };

    if (props && Array.isArray(props)) {
      this.state.name = props[0];
      this.state.options = props[1];
    } else if (props && typeof props === "object") {
      this.state.name = props.name;
      for (var key in props) {
        var val = props[key];

        if (typeof val != "string") continue;
        if (
          ["limit", "maxSize", "fileMaxSize", "theme", "listInput"].indexOf(
            key
          ) > -1
        )
          this.state.options[key] = val;
        if ("extensions" == key)
          this.state.options[key] = val.replace(/ /g, "").split(",");
        if ("files" == key) this.state.options[key] = JSON.parse(val);
      }
      if (props["disabled"]) this.state.options["limit"] = 0;
    }
  }

  componentDidMount() {
    this.$el = $(this.el);
    this.$el.fileuploader(
      $.extend(this.state.options, {
        enableApi: true,
      })
    );
    this.api = $.fileuploader.getInstance(this.$el);
  }

  componentWillUnmount() {
    if (this.api) this.api.destroy();
  }

  

  render() {
    let image = this.props.preloaded_file;
    return (
        <input
        type="file"
        name={this.state.name}
        ref={(el) => (this.el = el)}
        data-fileuploader-files={image}
        id={this.props.id}
      />
    );
  }
}


export default AvatarFileUploader;

import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar
} from "@ionic/react";
import "./AssignmentRecapConfirmation.css";
// TO be replaced with WARNING icon
import backArrow from "../../assets/images/common/arrow-left.png";
import bigCheckedIcon from "../../assets/images/common/checked-3d.png";
import ProgressBar from "../../push-components/ProgressBar/ProgressBar";

type ModalProps = {
  isOpen: boolean;
  closeModalHandler: Function;
  showCheckOut: boolean
};

const AssignmentRecapConfirmation: React.FC<ModalProps> = (Props) => {
  const history = useHistory();
  const { isOpen, closeModalHandler, showCheckOut } = Props;
  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <IonModal isOpen={isOpen} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar>
          <IonButton
            fill="clear"
            slot="start"
            onClick={() => closeModalHandler(false)}
          >
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <ProgressBar progress_value={1} />
      <IonContent>
        <div className="assignment-recap-confirmation">
          <div className="assignment-recap-confirmation__content">
            <img
              className="assignment-recap-confirmation__content__success-icon"
              src={bigCheckedIcon}
              alt=""
            />
            <h2>Report Submitted</h2>

            <>
              {showCheckOut ? (<div className="orange-border">
                <div className="orange-border__content">
                  Finished with your shift? Don't forget to check-out!
                </div>
                <p className="orange-border__content"></p>
                <div className="orange-border__content">
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    onClick={() => closeModalHandler(true)}
                  >
                    Check-Out
                  </IonButton>
                </div>
              </div>) : (<div className="orange-border">
                <div className="orange-border__content">
                  Thank you for submitting your report.
                </div>
                <p className="orange-border__content"></p>
                <div className="orange-border__content">
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    onClick={() => closeModalHandler(false)}
                  >
                    Done
                  </IonButton>
                </div>
              </div>)}
            </>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AssignmentRecapConfirmation;

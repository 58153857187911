import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonText,
  IonButton,
  IonCard,
  IonTitle,
  IonAlert,
  IonLoading,
  IonProgressBar,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { decode } from "base64-arraybuffer";
import backArrow from "../../../assets/images/common/arrow-left.png";
import "./AssignmentCheckin.css";
import take_selfie_eg1 from "../../../assets/images/signup/img/take-selfie-example-1.png";
import take_selfie_eg2 from "../../../assets/images/signup/img/take-selfie-example-2.png";
import take_selfie_eg3 from "../../../assets/images/signup/img/take-selfie-example-3.png";
import {
  talentEventCheckin,
  talentFileUploader,
  logError
} from "../../../data-services/assignmets";
import {
  getUserCameraPhoto,
  showToastMessage,
  validatePhoto,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
  unauthorizedErrMsg,
} from "../../../common/common";
import { get } from "../../../storage/storage";

type ModalProps = {
  isopen_checkin: boolean;
  closeCheckin: Function;
  checkin_event_id: string;
  latitude: string;
  longitude: string;
};

const AssignmentCheckin: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    isopen_checkin,
    closeCheckin,
    checkin_event_id,
    latitude,
    longitude,
  } = props;
  const [checkInSelfie, setCheckinSelfie] = useState<any>("");
  const [showphotoloading, setShowPhotoLoading] = useState<boolean>(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState<boolean>(false);
  const [showPhotoValidateAlert, setShowPhotoValidateAlert] =
    useState<boolean>(false);
  const [showPhotoValidateMessage, setShowPhotoValidateMessage] =
    useState<any>("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const takeCheckinPhoto = async () => {
    let image: any = await getUserCameraPhoto("base64");
    if (image?.base64String) {
      setCheckinSelfie({
        name: `check_in.jpg`,
        type: "image/jpeg",
        size: 789,
        file: image.base64String,
        data: "",
        ui_image: "data:text/plain;base64," + image.base64String
      });
    } else {
      showToastMessage("Error! Could not load the photo!");
    }
  };
  const closeCheckinModal = (success_msg: any = "") => {
    closeCheckin(success_msg);
    setCheckinSelfie("");
  };

  const confirmAlert = async () => {
    closeCheckinModal();
    setCheckinSelfie("");
  };

  const checkinFormSubmitHandler = async () => {
    setShowPhotoLoading(true);
    setDisableSubmitBtn(true);
    setUploadProgress(5);
    let imgClass = ".checkin-photo__selfie > ion-card > img";
    const facePredictions = await validatePhoto(imgClass);
    if (facePredictions?.length == 1) {
      setShowPhotoLoading(false);
      let checkin_payload: any = {
        latitude: latitude,
        longitude: longitude,
        checkInUeId: checkin_event_id,
        // In case the face prediction library fails, consider the selfie as fine but get the error message
        facePredictionsError: facePredictions?.errorMessage ? facePredictions?.errorMessage : ""
      };
    
      try {
        let checkin_api_res = await talentEventCheckin(checkin_payload);
        // Checkin request done
        setUploadProgress(50);
        if (checkin_api_res?.success) {
          let upload_selfie = new FormData();
          upload_selfie.append("type", "selfie");
          upload_selfie.append("ue_id", checkin_event_id);
          let blob = new Blob([new Uint8Array(decode(checkInSelfie.file))], {
            type: "image/jpeg",
          });
          let file_obj = new File([blob], checkInSelfie.name, {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
          upload_selfie.append("files", file_obj);
          let file_upload: any = await talentFileUploader(
            upload_selfie,
            "",
            handleProgress
          );
          // Fileupload Request ended
          setDisableSubmitBtn(false);
          if (file_upload?.success) {
            closeCheckinModal({ message: checkin_api_res.data.message });
          } else {
            if (file_upload?.data?.errorCode == unauthorizedErrorCode) {
              dispatch(authActions.logout());
              return;
            } else if (file_upload?.data?.errorCode == offlineErrorCode) {
              showToastMessage(file_upload?.data?.message);
            } else if (file_upload?.message) {
              showToastMessage(file_upload.message);
            } else {
              showToastMessage(somethingWentWrongErrMsg);
            }
          }
        } else {
          setDisableSubmitBtn(false);
          if (checkin_api_res?.data?.errorCode == unauthorizedErrorCode) {
            dispatch(authActions.logout());
            return;
          } else if (checkin_api_res?.data?.errorCode == offlineErrorCode) {
            showToastMessage(checkin_api_res?.data?.message);
          } else if (checkin_api_res?.message) {
            showToastMessage(checkin_api_res.message);
          } else {
            showToastMessage(somethingWentWrongErrMsg);
          }
        }
      } catch (e: any) {
        let user_id = await get("login_user_id");
        await logError(
          "Check In - Exception thrown - Face recognition failed for User " + user_id + " during Event " + checkin_event_id,
          "Talent App - Checkin",
          e.message
        )
        if (e.message == unauthorizedErrMsg) {
          dispatch(authActions.logout());
          return;
        } else {
          setDisableSubmitBtn(false);
          showToastMessage(e.message);
          setUploadProgress(0);
        }
      }
    } else if (facePredictions?.length > 1) {
      setShowPhotoLoading(false);
      setDisableSubmitBtn(false);
      setShowPhotoValidateMessage("Multiple Faces Found");
      setShowPhotoValidateAlert(true);
      return;
    } else {
      let user_id = await get("login_user_id");
      await logError(
        "Check In - Face recognition failed for User " + user_id + " during Event " + checkin_event_id,
        "Talent App - Checkin",
        JSON.stringify(facePredictions)
      )
      setShowPhotoLoading(false);
      setDisableSubmitBtn(false);
      setShowPhotoValidateMessage(`No Face Found, Please Retake Selfie`);
      setShowPhotoValidateAlert(true);
      return;
    }
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    if(progress > uploadProgress) {
      setUploadProgress(progress);
    }
  };

  useEffect(() => {}, [checkInSelfie]);

  return (
    <IonModal isOpen={isopen_checkin} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar mode="ios">
          <IonButton
            fill="clear"
            slot="start"
            onClick={() => closeCheckinModal()}
          >
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
          <IonTitle>Check In</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="checkin-photo-container">
          <div className="checkin-photo_title">
            <h1>Assignment Check In</h1>
            <IonText>
              Verify that you've arrived to your assignment by taking a photo of
              yourself at the venue.
            </IonText>
          </div>
          {!checkInSelfie && (
            <>
              <div className="checkin-photo__img_demo">
                <div>
                  <img src={take_selfie_eg1} alt="" />
                </div>
                <div>
                  <img src={take_selfie_eg2} alt="" />
                </div>
                <div>
                  <img src={take_selfie_eg3} alt="" />
                </div>
              </div>

              <div className="">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="button"
                  className="form-group__button"
                  onClick={takeCheckinPhoto}
                >
                  Take Photo
                </IonButton>
              </div>
            </>
          )}
          {checkInSelfie && (
            <div className="checkin-photo__selfie">
              <IonCard>
                <img src={checkInSelfie.ui_image} alt="" />
              </IonCard>
              <div className="checkin-photo__submit_btn">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="submit"
                  className="form-group__button"
                  disabled={disableSubmitBtn}
                  onClick={checkinFormSubmitHandler}
                >
                  Submit
                </IonButton>
              </div>
            </div>
          )}
          {disableSubmitBtn && <IonProgressBar value={uploadProgress / 100} />}
        </div>
      </IonContent>
      <IonAlert
        isOpen={showPhotoValidateAlert}
        onDidDismiss={() => setShowPhotoValidateAlert(false)}
        header={"Detect Human Face"}
        message={showPhotoValidateMessage}
        buttons={[
          {
            text: "Retake",
            handler: () => {
              setCheckinSelfie("");
            },
          },
        ]}
      />
    </IonModal>
  );
};
export default AssignmentCheckin;

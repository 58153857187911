/** */
import React, { useState, useEffect } from "react";
import {
  IonPopover,
  IonContent,
  IonLabel,
  IonText,
  IonTextarea,
  IonButton,
} from "@ionic/react";
import "./ContactUsPopover.css";
import close_x_circle from "../../assets/images/common/close_x_circle.png";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import { sendFAQContactUsNotification } from "../../data-services/faq";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";

type ContactUsPopover = {
  data: any;
  updatePopoverState: any;
};
const ContactUsPopover: React.FC<ContactUsPopover> = (props) => {
  const dispatch = useDispatch();
  const { data, updatePopoverState } = props;
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [contactMessage, setContactMessage] = useState<string>("");
  useState<any>({});
  const handleDismissPopover = () => {
    updatePopoverState("");
  };

  const claimModelRewardHandler = async () => {
    setDisabledSubmitBtn(true);
    let faq_contact_us_api = await sendFAQContactUsNotification(data.faq_id, contactMessage);
    if (faq_contact_us_api?.success) {
        showToastMessage("Your question has been sent. We'll get back to you with a response soon!");
        updatePopoverState("");
    } else if (faq_contact_us_api?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (faq_contact_us_api?.data?.errorCode == offlineErrorCode) {
      showToastMessage(faq_contact_us_api?.data?.message);
    } else if (faq_contact_us_api?.message) {
      showToastMessage(faq_contact_us_api.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setDisabledSubmitBtn(false);
  };

  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <IonPopover
      isOpen={true}
      onDidDismiss={() => handleDismissPopover()}
      cssClass="contactus-popover"
    >
      <IonContent class="ion-padding">
        <h2 className="contactus-popover__heading">
          Contact Us
          <img
            src={close_x_circle}
            className="contactus-popover__close"
            onClick={() => handleDismissPopover()}
          />{" "}
        </h2>
        <div>
          <div className="contactus-popover__input">
            <IonLabel>Contact Email</IonLabel>
            <IonText>{data.faq_contact_mail}</IonText>
          </div>
          <div className="contactus-popover__input">
            <IonLabel>FAQ</IonLabel>
            <IonText>{data.faq_question}</IonText>
          </div>
          <div className="contactus-popover__input">
            <IonLabel>Your Question</IonLabel>
            <IonTextarea
              className="form-input"
              placeholder="What would you like to know?"
              rows={7}
              onIonChange={(e: any) =>
                setContactMessage(e.detail.value)
              }
              value={contactMessage}
            ></IonTextarea>
          </div>
          <IonButton
            expand="block"
            className="contactus-popover__submit"
            onClick={claimModelRewardHandler}
            disabled={disabledSubmitBtn}
          >
            Submit
          </IonButton>
        </div>
      </IonContent>
    </IonPopover>
  );
};

export default ContactUsPopover;

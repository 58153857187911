import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonLabel,
  IonCheckbox,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { set, get } from "../../../../storage/storage";
import {
  showToastMessage,
  noInternetResponse,
} from "../../../../common/common";
import back_arrow from "../../../../assets/images/common/arrow-left.png";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/auth";
import { Network } from "@capacitor/network";
import { CapacitorHttp } from '@capacitor/core';

type ModalProps = {
  isOpen_modal_agreement: boolean;
  closeModalAgreement: Function;
  agreementText: string;
  ueId: number;
};

const CustomerAgreement: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_modal_agreement, closeModalAgreement, agreementText, ueId } = props;
  const [agreementchecked, setAgreementchecked] = useState<any>(false);
  const [isrequired, setIsrequired] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
    },
    mode: "onBlur",
  });

  const submitAgreement = async () => {
    let isChecked = agreementchecked == true ? 1 : 0;
    if (isChecked == 1) {
      setIsrequired(false);
      let response;
      let networkStatus = (await Network.getStatus()).connectionType;
      if (networkStatus !== "none") {
        const authToken = await get("login_user_token");
        if (!authToken) {
          dispatch(authActions.logout());
          return;
        }
        let url =
          `${process.env.REACT_APP_HP_API_URL}/userEvent/saveCustomerAgreement?token=` +
          authToken;
        const options = {
          url: url,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ` + authToken,
          },
          data: {
            ue_id: ueId
          },
        };
        response = await CapacitorHttp.post(options);
        response = response.data;
      } else {
        response = noInternetResponse;
      }
      if (response?.success) {
        await showToastMessage(response.message);
        closeModalAgreement(true);
      }
    } else {
      setIsrequired(true);
    }
  };

  return (
    <IonModal isOpen={isOpen_modal_agreement} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeModalAgreement(false);
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{padding: '15px'}}>
            <div
                dangerouslySetInnerHTML={{
                    __html: agreementText,
                }}
            ></div>
            <div>
                <div style={{display: 'flex', alignItems: 'flex-start', gap: '20px'}}>
                    <div>
                        <IonCheckbox
                            className={
                            isrequired === true
                                ? "push-agreement-view__agreecheck push-aggrement-check_required-field"
                                : "push-agreement-view__agreecheck"
                            }
                            mode="md"
                            onIonChange={(e) => setAgreementchecked(e.detail.checked)}
                        ></IonCheckbox>
                        </div>
                    <div style={{paddingTop: '3px'}}>
                        <IonLabel>
                            I agree to the above terms.
                        </IonLabel>
                        <br />
                        {isrequired && <div className="selections-required" style={{marginLeft: '0px'}}>This field is required.</div>}
                    </div>
                </div>
                <IonButton
                    expand="block"
                    mode="ios"
                    type="submit"
                    className="form-group__button section-next-button"
                    onClick={submitAgreement}
                >
                    Submit
                </IonButton>
            </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default CustomerAgreement;

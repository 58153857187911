import React, { useEffect, useState } from "react";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonIcon,
  IonItem,
  IonTitle,
  IonText,
  IonSpinner,
} from "@ionic/react";

import "./PointBoard.css";

import chevron_right from "../../assets/images/common/chevron-right.svg";
import chevron_right_grey from "../../assets/images/common/chevron-right-grey.svg";
import { useHistory } from "react-router";
import info_circle from "../../assets/images/common/info-circle.svg";
import InfoButtonPopover from "../InfoButtonPopover/InfoButtonPopover";

type pointData = {
  scoreBoradData: any;
};
const PointBoard: React.FC<pointData> = (props) => {
  const { scoreBoradData } = props;
  const [showInfo, setShowInfo] = useState<string>("");
  const history = useHistory();
  const gotoMyPoint = () => {
    history.push("/MyPoints");
  };
  return (
    <div className="scoreboard_my_point">
      <IonItem>
        <IonTitle className="scoreboard_my_point__title">
          My Points
          <IonIcon
            icon={info_circle}
            onClick={() => setShowInfo("revenue_my_points")}
          />
        </IonTitle>

        <div
          slot="end"
          className="scoreboard_my_point__all-item"
          onClick={gotoMyPoint}
        >
          <span>See all</span> <IonIcon src={chevron_right}></IonIcon>
        </div>
      </IonItem>
      <IonCard className="scoreboard_my_point__card">
        <div className="scoreboard_my_point__card__point">
          <IonText>PENDING POINTS</IonText>
          <h1>
            {!scoreBoradData ? (
              <IonSpinner name="dots"></IonSpinner>
            ) : (
              scoreBoradData?.points?.pending
            )}
          </h1>
        </div>
        <div className="scoreboard_my_point__card__point">
          <IonText>AVAILABLE POINTS</IonText>

          <h1>
            {!scoreBoradData ? (
              <IonSpinner name="dots"></IonSpinner>
            ) : (
              scoreBoradData?.points?.amount
            )}
          </h1>
        </div>

        <IonGrid className="scoreboard_my_point__gird">
          <IonRow>
            <IonCol
              size="6"
              className="scoreboard_my_point__col_left scoreboard_my_point__line"
            >
              <h2>
                {!scoreBoradData ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  scoreBoradData?.points?.hours
                )}
              </h2>
              <IonText>Hours Worked</IonText>
            </IonCol>
            <IonCol
              size="6"
              className="scoreboard_my_point__col_right scoreboard_my_point__line"
            >
              <h2>
                {!scoreBoradData ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  scoreBoradData?.points?.customer_referrals
                )}
              </h2>
              <IonText>Refer a Customer</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6" className="scoreboard_my_point__col_left">
              <h2>
                {!scoreBoradData ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  scoreBoradData?.points?.internal_talent_referrals
                )}
              </h2>
              <IonText>Refer a Crew Member</IonText>
            </IonCol>
            <IonCol size="6" className="scoreboard_my_point__col_right ">
              <h2>
                {!scoreBoradData ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  scoreBoradData?.points?.external_talent_referrals
                )}
              </h2>
              <IonText>Invite Friends to Join PUSH</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        {showInfo === "revenue_my_points" && (
          <InfoButtonPopover
            infoButtonClass={"revenue_my_points"}
            updatePopoverState={setShowInfo}
          ></InfoButtonPopover>
        )}
      </IonCard>
    </div>
  );
};
export default PointBoard;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonContent,
  IonLabel,
  IonPage,
  IonTitle,
  IonRange,
  IonText,
  IonSpinner,
} from "@ionic/react";

import "./NotificationRate.css";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { authActions } from "../../../store/auth";
import { getModelProfileData } from "../../../data-services/edit-profile";

import { updateTalenProfiletData } from "../../../data-services/signup";
import {
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg
} from "../../../common/common";

const NotificationRate: React.FC = () => {
  const [minimumhourrate, setMinimumhourrate] = useState<any>(0);
  const dispatch = useDispatch();
  const [showSpinnerTillApiResponse, setShowSpinnerTillApiResponse] =
    useState<boolean>(false);

  const [refreshComponent, setRefreshComponent] = useState<boolean>(false);

  const epInit = async () => {
    let profile_data: any = await getModelProfileData();
    if (!profile_data?.success) {
      if (profile_data?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (profile_data?.data?.errorCode == offlineErrorCode) {
        showToastMessage(profile_data?.data?.message);
      } else if (profile_data?.message) {
        await showToastMessage(profile_data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    profile_data = profile_data.data;
    setMinimumhourrate(profile_data.model.model_min_hourly_rate);
  };

  const onSubmitTalentEditMinRate = async (event: any) => {
    event.preventDefault();
    setShowSpinnerTillApiResponse(true);

    let edit_profile_form: any = {
      model_min_hourly_rate: minimumhourrate,
    };

    let update_api_response = await updateTalenProfiletData({
      model: edit_profile_form,
    });
    if (update_api_response?.success) {
      await showToastMessage("Successfully Updated!");
    } else if (update_api_response?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (update_api_response?.data?.errorCode == offlineErrorCode) {
      showToastMessage(update_api_response?.data?.message);
    } else if (update_api_response?.message) {
      showToastMessage(update_api_response.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setShowSpinnerTillApiResponse(false);
    setRefreshComponent(!refreshComponent);
  };

  useEffect(() => {
    (async () => {
      await epInit();
    })();
  }, [refreshComponent]);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Rate"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <form
          className="talent_rate__form"
          onSubmit={onSubmitTalentEditMinRate}
        >
          <section className="talent-rate">
            <div>
              <IonLabel className="talent-rate__label">
                Minimum Hourly Rate
              </IonLabel>
              <IonTitle className="talent-rate__hr_rate">
                ${minimumhourrate}.00 / hr
              </IonTitle>
              <IonRange
                className="talent-rate__range_picker"
                mode="ios"
                step={5}
                value={minimumhourrate}
                min={10}
                max={100}
                onIonChange={(e) => {
                  e.detail.value as number;
                  setMinimumhourrate(e.detail.value);
                }}
              />
              <div className="talent-rate__hr_rates">
                <IonText className="talent-rate__hr_rates__min">$10</IonText>
                <IonText className="talent-rate__hr_rates__max">$100</IonText>
              </div>
            </div>

            {showSpinnerTillApiResponse ? (
              <IonButton className="form-group__button talent-rate__submit_btn">
                <IonSpinner name="dots"></IonSpinner>
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                mode="ios"
                type="submit"
                className="form-group__button talent-rate__submit_btn"
              >
                Save
              </IonButton>
            )}
          </section>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default NotificationRate;

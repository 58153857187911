import React, { useState } from "react";
import { IonCheckbox, IonItem, IonText } from "@ionic/react";
import "./InputTypes.css";
type componentProps = {
  question: string;
  answer?: any;
  question_id: number;
  options: string;
  questionno?: number;
  requiredmessage?: boolean;
  allowHtmlQuestion?: any;
};
const InputTypeCheckbox: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    allowHtmlQuestion
  } = Props;

  const [answervalue, setAnswerValue] = useState<any>(answer);

  const changevalue = (data: any) => {
    answervalue[data] = !answervalue[data];
    setAnswerValue(answervalue);
  };

  return (
    <div className="take-test-question take-test-question__checkbox">
      <div className="take-test-question__label">
        {/* {questionno && <>{questionno}. </>} */}
        {allowHtmlQuestion ? (
          <span
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          ></span>
        ) : (
          <>{question}</>
        )}
      </div>
      {options &&
        options.split("||").map((data: any, index: number) => (
          <div key={index} className="take-test-question__checkbox__container">
            <input
              type="checkbox"
              slot="start"
              name={`checkbox_${index}-answer_${question_id}`}
              value={data}
              className="input-type__checkbox"
              defaultChecked={answervalue[data]}
              onChange={(e: any) => changevalue(data)}
            />
            <span>{data}</span>
          </div>
        ))}
      <input type="hidden" name={`checkbox_20-answer_${question_id}`} />
      {requiredmessage === true && (
        <div className="error-message">This field is required.</div>
      )}
    </div>
  );
};

export default InputTypeCheckbox;

/** */
import React from "react";

import { IonTitle, IonText } from "@ionic/react";
import "./EmptyScreen.css";
import empty_icon from "../../assets/images/my-revenue/icons/empty_icon.png";
type EmptyScreenProps = {
    title:string;
    description?:string
    icon?:any
}
const EmptyScreen: React.FC<EmptyScreenProps> = (props) => {
    const {title,description,icon} = props
  return (
    <div className="empty-screen">
      <img src={(!icon)?empty_icon:icon} alt="" className="empty-screen__img" />
      <h2 className="empty-screen__title">{title}</h2>
      <IonText className="empty-screen__text">
       {description}
      </IonText>
    </div>
  );
};

export default EmptyScreen;

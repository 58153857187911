import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonBackButton,
  IonItem,
  IonLabel,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonCard,
  IonCardContent,
  IonInput,
  IonDatetime,
  IonAlert,
} from "@ionic/react";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import backArrow from "../../../assets/images/common/arrow-left.png";
import "./AssignmentConfirm.css";
import { saveModelConfirmationSelection } from "../../../data-services/assignmets";
import { showToastMessage } from "../../../common/common";
import PCardAgreement from "../../Home/SetupAccount/PCardAgreement/PCardAgreement";
import { set, get } from "../../../storage/storage";
import checkedIcon from "../../../assets/images/common/checked-3d.png";

type ModalProps = {
  isopen_assign_confirm: boolean;
  closeAssignConfirm: Function;
  assign_event_name: string;
  assign_id: number;
  assign_date: string;
  assign_time: string;
  assign_talent_role: string;
  assign_p_card_required?: any;
  assign_p_card_instructions?: any;
  backup_type?: any;
  backup_type_str?: any;
  backup_instructions?: any;
  auto_book_enabled?: any;
  requiredCustomerAgreementText: string;
};

const AssignmentConfirm: React.FC<ModalProps> = (props) => {
  const {
    isopen_assign_confirm,
    closeAssignConfirm,
    assign_id,
    assign_event_name,
    assign_date,
    assign_time,
    assign_talent_role,
    assign_p_card_required,
    assign_p_card_instructions,
    backup_type,
    backup_type_str,
    backup_instructions,
    auto_book_enabled,
    requiredCustomerAgreementText
  } = props;
  let splited_start_time: any = assign_time.split(":");
  let remove_seconds: string = splited_start_time[2].replace("00", "");
  let modified_start_time: string = `${splited_start_time[0]}:${splited_start_time[1]}${remove_seconds}`;
  const [assignEventDate, setAssignEventDate] = useState<string>("");
  const [assignEventTime, setAssignEventTime] = useState<string>("");
  const [isOpen_PCardAgreement, setIsOpenPCardAgreement] =
    useState<boolean>(false);
  const [modelPCardAgreementConfirmed, setModelPCardAgreementConfirmed] =
    useState<boolean>(false);
  const [showPCardRequiredErrorMessage, setShowPCardRequiredErrorMessage] =
    useState<boolean>(false);

  const [workOnDatetime, setWorkOnDatetime] = useState<boolean>(false);
  const [early15Min, setEarly15min] = useState<boolean>(false);
  const [readyToWork, setReadyToWork] = useState<boolean>(false);
  const [friendlyAttitude, setFriendlyAttitude] = useState<boolean>(false);
  const [returnAllProducts, setReturnAllProducts] = useState<boolean>(false);
  const [confirmationsNotChecked, setConfirmationsNotChecked] =
    useState<boolean>(false);

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      work_on_date_time: false,
      early_15_minutes: false,
      ready_to_work: false,
      friendly_attitude: false,
      return_all_products: false,
      assign_event_date: "",
      assign_event_time: "",
    },
    mode: "onBlur",
  });
  const submitConfirmAssignment = async () => {
    setShowPCardRequiredErrorMessage(false);
    if (!workOnDatetime) {
      setConfirmationsNotChecked(true);
    } else if (!early15Min && backup_type == 1) {
      setConfirmationsNotChecked(true);
    } else if (!readyToWork) {
      setConfirmationsNotChecked(true);
    } else if (!friendlyAttitude) {
      setConfirmationsNotChecked(true);
    } else if (!returnAllProducts) {
      setConfirmationsNotChecked(true);
    } else if (assign_p_card_required == "1" && !modelPCardAgreementConfirmed) {
      setShowPCardRequiredErrorMessage(true);
    } else {
      // //removing second from time
      // let splited_time: any = assign_time.split(":");
      // let second_remove: string = splited_time[2].replace("00", "");
      // let modified_time: string = `${splited_time[0]}:${splited_time[1]}${second_remove}`;
      // if (assignEventDate.toString() !== assign_date.toString()) {
      //   setError("assign_event_date", {
      //     type: "manual",
      //     message: "Event Date must be same.",
      //   });
      // } else if (assignEventTime.toString() !== modified_time.toString()) {
      //   setError("assign_event_time", {
      //     type: "manual",
      //     message: "Event Time must be same.",
      //   });
      // } else {
      let confirmation = await saveModelConfirmationSelection({
        ue_id: assign_id,
        selection: 1,
        reason: "",
      });
      if (confirmation?.success) {
        closeAssignConfirm();
        showToastMessage(confirmation.message);
      } else {
        showToastMessage(confirmation.message);
      }

      // }
    }
  };
  const desnyAssignment = async () => {
    // await saveModelConfirmationSelection({ ue_id: assign_id, selection: 0,reason :"" });
    closeAssignConfirm("decline", assign_event_name);
  };

  const close_PCardAgreement = async (confirmed?: false) => {
    setIsOpenPCardAgreement(false);
    // Also hide the complete P-Card Agreement button,  show it as completed and set model p-card agreement completed
    if (confirmed) {
      await set("model_p_card_agreement_confirmed", 1);
      setModelPCardAgreementConfirmed(true);
      setShowPCardRequiredErrorMessage(false);
    }
  };

  useEffect(() => {
    (async () => {
      let model_p_card_agreement_confirmed = await get(
        "model_p_card_agreement_confirmed"
      );
      setModelPCardAgreementConfirmed(model_p_card_agreement_confirmed);
    })();
  }, []);

  return (
    <IonModal isOpen={isopen_assign_confirm} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar>
          <IonButton
            fill="clear"
            slot="start"
            onClick={() => closeAssignConfirm()}
          >
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form>
          <div className="assign-confirm">
            <div className="assign-confirm__event-info">
              <div>
                <h1>Are you committed?</h1>
              </div>
              <IonGrid>
                <IonRow>
                  <IonCol className="assign-confirm__event-container">
                    <IonLabel>Assignment:</IonLabel>
                    <IonText>
                      <div className="assign-confirm__event-container__event_name">
                        {assign_event_name}
                      </div>
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Date:</IonLabel>
                    <IonText>{assign_date}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonLabel>Time:</IonLabel>
                    <IonText>{modified_start_time}</IonText>
                  </IonCol>
                </IonRow>
                {backup_type == 1 && (
                  <IonRow>
                    <IonCol>
                      <IonLabel>Talent Role:</IonLabel>
                      <IonText>
                        <div
                          className="assign-confirm__talent-role"
                          dangerouslySetInnerHTML={{
                            __html: assign_talent_role,
                          }}
                        ></div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
                {backup_type != 1 && (
                  <IonRow>
                    <IonCol>
                      <IonLabel>
                        Backup Instructions:{" "}
                        <span className="assign-confirm__backup-instructions-label">
                          {backup_type_str}
                        </span>
                      </IonLabel>
                      <br />
                      <br />
                      <IonText>
                        <div
                          className="assign-confirm__backup-instructions"
                          dangerouslySetInnerHTML={{
                            __html: backup_instructions,
                          }}
                        ></div>
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </div>
            {assign_p_card_required == "1" && (
              <div className="assign-confirm__event-info">
                <div>
                  <h1>P-Card Instructions</h1>
                </div>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        <div
                          className="assign-confirm__talent-role"
                          dangerouslySetInnerHTML={{
                            __html: assign_p_card_instructions,
                          }}
                        ></div>
                      </IonText>
                      {!modelPCardAgreementConfirmed && (
                        <>
                          <IonButton
                            mode="ios"
                            color="primary"
                            size="large"
                            onClick={() => setIsOpenPCardAgreement(true)}
                            className="form-group__button assignment-view__p-card-required-bttn"
                          >
                            Complete P-Card Agreement
                          </IonButton>
                          <PCardAgreement
                            isOpen_modal_agreement={isOpen_PCardAgreement}
                            closeModalAgreement={close_PCardAgreement}
                          />
                        </>
                      )}
                      {showPCardRequiredErrorMessage && (
                        <div className="error-message">
                          P-Card Agreement confirmation is required
                        </div>
                      )}
                      {modelPCardAgreementConfirmed && (
                        <p className="assignment-view__p-card-completed">
                          <img
                            className="assignment-view__p-card-completed-icon"
                            src={checkedIcon}
                            alt=""
                          />
                          <span>P-Card Agreement Completed</span>{" "}
                        </p>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}
            
            <div className="assign-confirm__checkboxs">
              <IonText className="assign-confirm__checkboxs__note">
                Before you confirm, please review the following to ensure you
                know exactly what is expected of you.
              </IonText>
              {/* No Backup - Regular Booking */}
              {backup_type == 1 && (
                <div>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={workOnDatetime}
                        onIonChange={(e) => setWorkOnDatetime(e.detail.checked)}
                        {...register("work_on_date_time", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will work the dates and times above.
                      <ErrorMessage
                        errors={errors}
                        name="work_on_date_time"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={early15Min}
                        onIonChange={(e) => setEarly15min(e.detail.checked)}
                        {...register("early_15_minutes", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will be 15 minutes early for this assignment.
                      <ErrorMessage
                        errors={errors}
                        name="early_15_minutes"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={readyToWork}
                        onIonChange={(e) => setReadyToWork(e.detail.checked)}
                        {...register("ready_to_work", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will arrive prepared and be ready to work.
                      <ErrorMessage
                        errors={errors}
                        name="ready_to_work"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={friendlyAttitude}
                        onIonChange={(e) =>
                          setFriendlyAttitude(e.detail.checked)
                        }
                        {...register("friendly_attitude", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will have an upbeat, outgoing, and friendly attitude.
                      <ErrorMessage
                        errors={errors}
                        name="friendly_attitude"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={returnAllProducts}
                        onIonChange={(e) =>
                          setReturnAllProducts(e.detail.checked)
                        }
                        {...register("return_all_products", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will return all products, uniforms, and/or materials I
                      was given.
                      <ErrorMessage
                        errors={errors}
                        name="return_all_products"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  
                  {confirmationsNotChecked && (
                    <p className="selections-required">* Selections Required</p>
                  )}
                </div>
              )}
              {/* On-Site Backup */}
              {backup_type == 2 && (
                <div>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={workOnDatetime}
                        onIonChange={(e) => setWorkOnDatetime(e.detail.checked)}
                        {...register("work_on_date_time", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will arrive on-site at the date & time above and be
                      willing to work if needed.
                      <ErrorMessage
                        errors={errors}
                        name="work_on_date_time"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>

                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={readyToWork}
                        onIonChange={(e) => setReadyToWork(e.detail.checked)}
                        {...register("ready_to_work", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will follow all instructions provided on the event
                      details email.
                      <ErrorMessage
                        errors={errors}
                        name="ready_to_work"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={friendlyAttitude}
                        onIonChange={(e) =>
                          setFriendlyAttitude(e.detail.checked)
                        }
                        {...register("friendly_attitude", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will have an upbeat, outgoing, and friendly attitude.
                      <ErrorMessage
                        errors={errors}
                        name="friendly_attitude"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={returnAllProducts}
                        onIonChange={(e) =>
                          setReturnAllProducts(e.detail.checked)
                        }
                        {...register("return_all_products", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will return all products, uniforms, and/or materials I
                      was given.
                      <ErrorMessage
                        errors={errors}
                        name="return_all_products"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  {confirmationsNotChecked && (
                    <p className="selections-required">* Selections Required</p>
                  )}
                </div>
              )}
              {/* On-Call Backup */}
              {backup_type == 3 && (
                <div>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={workOnDatetime}
                        onIonChange={(e) => setWorkOnDatetime(e.detail.checked)}
                        {...register("work_on_date_time", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will be available to work the date & time above if
                      needed.
                      <ErrorMessage
                        errors={errors}
                        name="work_on_date_time"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={readyToWork}
                        onIonChange={(e) => setReadyToWork(e.detail.checked)}
                        {...register("ready_to_work", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will follow all instructions provided on the event
                      details email.
                      <ErrorMessage
                        errors={errors}
                        name="ready_to_work"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={friendlyAttitude}
                        onIonChange={(e) =>
                          setFriendlyAttitude(e.detail.checked)
                        }
                        {...register("friendly_attitude", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will have an upbeat, outgoing, and friendly attitude.
                      <ErrorMessage
                        errors={errors}
                        name="friendly_attitude"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  <IonItem>
                    <div slot="start">
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={returnAllProducts}
                        onIonChange={(e) =>
                          setReturnAllProducts(e.detail.checked)
                        }
                        {...register("return_all_products", {
                          required: "This field is required.",
                        })}
                      />
                    </div>
                    <IonText slot="start">
                      I will return all products, uniforms, and/or materials I
                      was given.
                      <ErrorMessage
                        errors={errors}
                        name="return_all_products"
                        as={<div className="error-message" />}
                      />
                    </IonText>
                  </IonItem>
                  {confirmationsNotChecked && (
                    <p className="selections-required">* Selections Required</p>
                  )}
                </div>
              )}
            </div>
            <div className="assign-confirm__note">
              <IonCard>
                <IonCardContent>
                  <b>Note:</b>&nbsp;
                  <IonText>
                    Good feedback from your booking agents and clients will
                    result in good reviews and more work. Bad feedback will
                    result in negative reviews and no work.
                  </IonText>
                </IonCardContent>
              </IonCard>
            </div>
            {/* <div className="assign-confirm__event-datetime">
              <IonText className="assign-confirm__event-datetime__note">
                Please enter the date and time of this assignment below to
                confirm that you are absolutely committed and understand what is
                expected of you.
              </IonText>

              <div className="form-group">
                <IonDatetime
                  mode="ios"
                  displayFormat="MM/DD/YYYY"
                  className="assign-confirm__event-datetime__date"
                  onIonChange={(e: any) => {
                    setAssignEventDate(
                      moment(e.detail.value).format("MM/DD/YYYY")
                    );
                    setValue("assign_event_date", " ");
                  }}
                ></IonDatetime>
                <IonLabel className="form-group__label">
                  Assignment Date
                </IonLabel>
                <input
                  type="text"
                  placeholder="MM/DD/YYYY"
                  className={
                    errors.assign_event_date !== undefined
                      ? "form-input-styles-error birth_date"
                      : "form-group__input-styles birth_date"
                  }
                  {...register("assign_event_date", {
                    required: "This field is required.",
                  })}
                ></input>

                <ErrorMessage
                  errors={errors}
                  name="assign_event_date"
                  as={<div className="error-message" />}
                />
              </div>

              <div className="form-group">
                <IonDatetime
                  mode="ios"
                  // pickerFormat="h:mm A"
                  minuteValues={[0, 15, 30, 45]}
                  displayFormat="h:mm A"
                  className="assign-confirm__event-datetime__date"
                  onIonChange={(e: any) => {
                    setAssignEventTime(moment(e.detail.value).format("h:mm A"));
                    setValue("assign_event_time", " ");
                  }}
                ></IonDatetime>
                <IonLabel className="form-group__label">
                  Assignment Start Time
                </IonLabel>
                <input
                  type="text"
                  placeholder="HH:mm"
                  className={
                    errors.assign_event_time !== undefined
                      ? "form-input-styles-error birth_date"
                      : "form-group__input-styles birth_date"
                  }
                  {...register("assign_event_time", {
                    required: "This field is required.",
                  })}
                ></input>

                <ErrorMessage
                  errors={errors}
                  name="assign_event_time"
                  as={<div className="error-message" />}
                />
              </div>
            </div> */}
            <div className="assign-confirm__submit-btn">
              <IonButton
                type="button"
                mode="ios"
                fill="outline"
                size="large"
                
              >
                Decline
              </IonButton>
              <IonButton type="button" mode="ios" color="primary" size="large" onClick={() => submitConfirmAssignment()}>
                Confirm
              </IonButton>
            </div>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default AssignmentConfirm;

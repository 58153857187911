import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonSearchbar,
  IonText,
  IonFooter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonInput,
  IonLabel,
  isPlatform
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { get } from "../../../storage/storage";
import "./ExternalTalentReferrals.css";
import {
  createModelReferral,
  getAvailableModelReferralList,
} from "../../../data-services/edit-profile";
import Loading from "../../../push-components/loading/Loading";
import exclamation_mark from "../../../assets/images/common/exclamation-mark-in-a-circle-svgrepo-com.svg";
import {
  getUserContactList,
  showToastMessage,
  sortUserContactNumbers,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
  formatPhoneNumber,
  validatePhoneNumber
} from "../../../common/common";
import { useHistory, useParams } from "react-router";
import "./ManuallyAddReferrals/ManuallyAddReferrals.css";
import back_arrow from "../../../assets/images/common/arrow-left-white.png";
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { getAvailableReferralCampaign } from "../../../data-services/home-api";
import { Capacitor } from "@capacitor/core";

let talent_phone_sorted_contact: any = [];

type ExternalModalProps = {
  is_open_external_referral: boolean;
  closeNewExternalModal: Function;
  fromSignup?: boolean;
  knownReferralPoints?: any;
  eventId?: any;
  overrideDescription?:any;
};

const ExternalTalentReferrals: React.FC<ExternalModalProps> = (Props) => {
  const { is_open_external_referral, closeNewExternalModal, fromSignup, knownReferralPoints, eventId, overrideDescription } = Props;
  const dispatch = useDispatch();
  let url_param: any = useParams();
  let history: any = useHistory();
  const [talentContactList, setTalentContactList] = useState<any>([]);
  const [talentFriendsList, setTalentFriendsList] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [checkedTalentsBoxesObjects, setCheckedTalentsBoxesObjects] =
    useState<any>([]);
  const [checkedTalentsBoxesModelUserIDs, setCheckedTalentsBoxesModelUserIDs] =
    useState<any>([]);
  const [groupedContacts, setGroupedContacts] = useState<any>([]);
  const [referralMobileFormatted, setReferralMobileFormatted] = useState<any>("");
  const [referralMobileErrorMessage, setReferralMobileErrorMessage] = useState<any>(false);
  const [externalReferralPoints, setExternalReferralPoints] = useState<any>(null);

  const isAndroidApp = () => {
    return isPlatform('android') && Capacitor.isNativePlatform();
  };
  
  const fetchTalentContactHandler = async (refresh: boolean = false) => {
    let is_permited: any = await getUserContactList();

    setShowLoader(true);

    // Show the amount of points you can earn via External Talent Referral
    if(knownReferralPoints) {
      setExternalReferralPoints(knownReferralPoints);
    } else {
      let availableReferralCampaign = await getAvailableReferralCampaign();
      if (availableReferralCampaign?.success) {
        setExternalReferralPoints(availableReferralCampaign?.data?.referralPoints);
      } else if (
        availableReferralCampaign?.data?.errorCode == unauthorizedErrorCode
      ) {
        dispatch(authActions.logout());
        return;
      } else if (availableReferralCampaign?.data?.errorCode == offlineErrorCode) {
        showToastMessage(availableReferralCampaign?.data?.message);
      } else if (availableReferralCampaign?.message) {
        showToastMessage(availableReferralCampaign.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }

    if (is_permited.granted) {
      let contact_list: any = is_permited.contacts?.contacts;
      let talent_contact = sortUserContactNumbers(contact_list);

      if (talent_contact.length > 0) {
        if (isAndroidApp()) {
          // Do not send to server.
        } else {
          let api_response = await getAvailableModelReferralList(talent_contact);
          if (!api_response?.success) {
            if (api_response?.data?.errorCode == unauthorizedErrorCode) {
              dispatch(authActions.logout());
              return;
            } else if (api_response?.data?.errorCode == offlineErrorCode) {
              showToastMessage(api_response?.data?.message);
            } else if (api_response?.message) {
              showToastMessage(api_response.message);
            } else {
              showToastMessage(somethingWentWrongErrMsg);
            }
          } else {
            // not invited contact
            if (
              api_response.data.available_accounts &&
              api_response.data.available_accounts.length > 0
            ) {
              let available = api_response.data.available_accounts;
              for (let index = 0; index < available.length; index++) {
                let element = available[index];
                for (let i = 0; i < talent_contact.length; i++) {
                  let a_cont: any = talent_contact[i];
                  if (a_cont.id2 === element) {
                    a_cont["disabled"] = false;
                  }
                }
              }
            }
  
            // invited contact
            if (
              api_response.data.invited_accounts &&
              api_response.data.invited_accounts.length > 0
            ) {
              let invited = api_response.data.invited_accounts;
              for (let index = 0; index < invited.length; index++) {
                let element = invited[index];
                for (let i = 0; i < talent_contact.length; i++) {
                  let a_cont: any = talent_contact[i];
                  if (a_cont.id2 === element) {
                    a_cont["disabled"] = true;
                  }
                }
              }
            }
  
            // created accounts
            if (
              api_response.data.created_accounts &&
              api_response.data.created_accounts.length > 0
            ) {
              let created = api_response.data.created_accounts;
              for (let index = 0; index < created.length; index++) {
                let element = created[index];
                for (let i = 0; i < talent_contact.length; i++) {
                  let a_cont: any = talent_contact[i];
                  if (a_cont.id2 === element) {
                    a_cont["disabled"] = true;
                  }
                }
              }
            }
          }
        }
        
        let sorted_contact = [];

        for (let j = 0; j < talent_contact.length; j++) {
          let element: any = talent_contact[j];
          if(!element.name.trim().length) {
            continue;
          }
          if (element.phoneNumbers.length > 0) {
            for (let o = 0; o < element.phoneNumbers.length; o++) {
              let num: any = element.phoneNumbers[o];
              sorted_contact.push({
                id2: element.id2,
                name: element.name.replace(/undefined/g, ""),
                contact: num,
                disabled: element.disabled,
              });
            }
          } else {
            for (let s = 0; s < element.emails.length; s++) {
              let em: any = element.emails[s];
              sorted_contact.push({
                id2: element.id2,
                name: element.name.replace(/undefined/g, ""),
                contact: em,
                disabled: element.disabled,
              });
            }
          }
        }
      
        // Sort the contacts by their Names
        sorted_contact.sort(function (a, b) {
          let nameA = a.name.trim().toLowerCase();
          let nameB = b.name.trim().toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        talent_phone_sorted_contact = sorted_contact;
        setTalentContactList(sorted_contact);
        groupTalentFriendsByStartingLetter(sorted_contact);
        
      } else {
        showToastMessage("Could not read the contacts list.");
        setShowLoader(false);
      }
    } else {
      if(refresh) {
        showToastMessage("Please enable contacts access in your device settings.");
      }
    }
    setShowLoader(false);
  };
  const sendInvitationHandler = (event: any) => {
    if (event.detail.checked) {
      let friends_array: any = [
        ...talentFriendsList,
        {
          email: event.detail.value.contact,
          id2: event.detail.value.id2,
        },
      ];
      setTalentFriendsList(friends_array);
    } else {
      let filter_array: any = talentFriendsList.filter(function (obj: any) {
        return obj.id2 !== event.detail.value.id2;
      });
      setTalentFriendsList(filter_array);
    }
  };
  const filterTalentContactHandler = (ev: any) => {
    const target = ev.target as HTMLIonSearchbarElement;
    if (target.value) {
      let search_str: any = target.value;
      let obj: any = talentContactList.filter((x: any) =>
        x.name.toLowerCase().includes(search_str.toLowerCase())
      );
      setTalentContactList(obj);
    } else {
      setTalentContactList(talent_phone_sorted_contact);
    }
  };

  /**
   * My Contacts - Search input change handler
   */
  const handleContactSearchChange = (event: any) => {
    const filteredContactOptions = talentContactList.filter((option: any) =>
      option.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    groupTalentFriendsByStartingLetter(filteredContactOptions);
  };

  /**
   * My Contacts - Checkbox input change handler
   * @param event
   */
  const handleContactCheckboxChange = (event: any) => {
    if (event.target.checked) {
      setCheckedTalentsBoxesObjects([
        ...checkedTalentsBoxesObjects,
        {
          email: event.detail.value.contact,
          id2: event.detail.value.id2,
        },
      ]);
      setCheckedTalentsBoxesModelUserIDs([
        ...checkedTalentsBoxesModelUserIDs,
        event.detail.value.id2,
      ]);
    } else {
      setCheckedTalentsBoxesObjects(
        checkedTalentsBoxesObjects.filter(function (obj: any) {
          return obj.id2 !== event.detail.value.id2;
        })
      );
      setCheckedTalentsBoxesModelUserIDs(
        checkedTalentsBoxesModelUserIDs.filter(
          (item: any) => item !== event.detail.value.id2
        )
      );
    }
  };

  /**
   * My Contacts - Create a list of contacts, grouped by their starting letters
   * @param contacts
   */
  const groupTalentFriendsByStartingLetter = (contacts: any) => {
    let groupedContactsArray: any = {};
    contacts.forEach((contact: any) => {
      let firstLetter = contact.name[0].toUpperCase();
      if (!groupedContactsArray[firstLetter]) {
        groupedContactsArray[firstLetter] = [];
      }
      groupedContactsArray[firstLetter].push(contact);
    });
    setGroupedContacts(groupedContactsArray);
  };

  const formatAndSetPhoneNumber = (event: any) => {
    let phone_no = event.target.value;

    let formatted_no = formatPhoneNumber(phone_no);

    if (formatted_no) {
      setReferralMobileFormatted(formatted_no);
      setReferralMobileErrorMessage("");
    }
    
  };


  const submitInvitations = async () => {
   
    if(referralMobileFormatted.length && !validatePhoneNumber(referralMobileFormatted)) {
        setReferralMobileErrorMessage("Invalid phone number!");
        return false;
    } else {
      setReferralMobileErrorMessage("");
    }
   
    setDisabledSubmitBtn(true);
    if (checkedTalentsBoxesObjects.length || referralMobileFormatted.length) {
      if(referralMobileFormatted.length) {
        checkedTalentsBoxesObjects.push({
          email: referralMobileFormatted
        });
      }
      let api_res: any = await createModelReferral(checkedTalentsBoxesObjects, eventId);
      if (api_res?.success) {
        await showToastMessage(api_res.message);
        closeManulReferral();
      } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.data?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } else {
      showToastMessage("Please enter a phone number or select a contact to proceed.");
    }
    setDisabledSubmitBtn(false);
  };
  const closeManulReferral = () => {
    if(fromSignup) {
      window.location.href = "/Home";
    } else if (url_param.source === "myprofile") {
      history.push("/Referrals");
    } else {
      history.push("/Home");
    }
  };

  const openAppSettings = () => {
    /**
     * Note that the only supported option by Apple is "App".
     * Using other options might break in future iOS versions
     * or have your app rejected from the App Store.
     */
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails, 
      optionIOS: IOSSettings.App
    })
  }

  useEffect(() => {
    (async () => {
      await fetchTalentContactHandler();
    })();
  }, []);
  return (
    <IonModal isOpen={is_open_external_referral} mode="ios">
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>New Referral</IonTitle>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                if(fromSignup) { 
                  window.location.href = "/Home";
                } else {
                  closeNewExternalModal();
                }
                
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="new-referral">
          <div>
            <h1 className="new-referral__title">Invite Friends</h1>
            <IonText className="new_referral__text">
              {overrideDescription ? overrideDescription : "Nothing is more fun than working with your friends! Invite your contacts to join PUSH and earn points once they create an account with us!"}
            </IonText>
            <div>
              <span className="new_referral__text new-referral__title__earn-points">
                + Earn {externalReferralPoints} Points
              </span>
            </div>
          </div>
          {/* <div className="multi-fields">
            <div id="dynamicfield_1" className="multi-field">
              <input
                className="form-group__input-styles"
                type="tel"
                name="fridends_contact[]"
                placeholder="(___) ___ ____"
                maxLength={14}
                onInput={formatAndSetPhoneNumber}
                value={referralMobileFormatted}
              ></input>
              <div className="error-message">{referralMobileErrorMessage}</div>
            </div>
          </div> */}
          {!showLoader && (<div className="new-referral__items">
            <IonList className="new-referral__items__list">
              <IonListHeader key={0}>My Contacts</IonListHeader>
              {talentContactList.length > 0 && (
                <div>
                  <IonSearchbar
                    className="new-referral__searchbar"
                    placeholder="&nbsp;&nbsp;Who are you looking for?"
                    debounce={500}
                    onIonChange={(ev: any) => handleContactSearchChange(ev)}
                  ></IonSearchbar>
                  {Object.keys(groupedContacts).map((firstLetter) => (
                    <div key={firstLetter}>
                      <IonListHeader key={firstLetter} className="new-referral__items__list_contact-first-letter">
                        <span>{firstLetter}</span>
                      </IonListHeader>
                      <IonList lines="none">
                        {groupedContacts[firstLetter].map(
                          (contact: any, index: number) => (
                            <IonItem
                              key={contact.id2}
                              className="new-referral__items__list_contact-item"
                            >
                              <div className="new-referral__items__list_detail">
                                <h2>{contact.name}</h2>
                                <IonText className="new-referral__items__list_detail__points-text">
                                  {!eventId && checkedTalentsBoxesModelUserIDs.includes(
                                    contact.id2
                                  )
                                    ? "+ 25 Points"
                                    : ""}
                                </IonText>
                              </div>
                              {!contact.disabled && (
                                <IonCheckbox
                                mode="md"
                                slot="end"
                                className="form-group__checkbox-styles"
                                key={contact.id2}
                                value={contact}
                                checked={checkedTalentsBoxesModelUserIDs.includes(
                                  contact.id2
                                )}
                                onIonChange={(e: any) =>
                                  handleContactCheckboxChange(e)
                                }
                              ></IonCheckbox>
                              )}
                              {contact.disabled && (
                                <IonLabel className="new-referral__items__list_detail__points-already-push-account"><span>PUSH</span></IonLabel>
                              )}
                            </IonItem>
                          )
                        )}
                      </IonList>
                    </div>
                  ))}
                </div>
              )}
              {talentContactList.length == 0 && (
                <div>
                  <div className="new-referral__items__list__no-contact">
                  <div className="new-referral__items__list__no-contact__flex">
                    <img src={exclamation_mark} />
                    <span>
                      Please update app permissions in phone settings to allow
                      access to contacts.
                    </span>
                    </div>
                    <div style={{textAlign: 'center'}}>
                    <br/>
                      <IonButton
                        type="button"
                        // expand="block"
                        mode="ios"
                        onClick={openAppSettings}
                      >
                        Open Settings
                      </IonButton>
                      
                      <div onClick={() => fetchTalentContactHandler(true)} className="new-referral__items__list__no-contact__refresh"><span>Refresh</span></div>
                    </div>
                    
                  </div>
                </div>
              )}
            </IonList>
          </div>)}
        </div>
      </IonContent>
      <IonFooter className={fromSignup ? "new-referral__footer new-referral__footer--signup" : "new-referral__footer"} mode="ios">
        <IonButton
          type="button"
          className={fromSignup ? "form-group__button new-referral__submit_btn new-referral__submit_btn--signup" : "form-group__button new-referral__submit_btn"}
          expand="block"
          mode="ios"
          onClick={submitInvitations}
          disabled={disabledSubmitBtn}
        >
          Submit
        </IonButton>
        {fromSignup && (
           <IonButton className="form-group__button" fill="clear" expand="block" mode="ios" type="button" href={`/Home`}>
           Not Now
          </IonButton>
        )}
      </IonFooter>
      <Loading showLoading={showLoader} />
    </IonModal>
  );
};

export default ExternalTalentReferrals;

import { IonButton, IonCard} from "@ionic/react";
import React from "react";
import "./LockField.css";
import locked_icon from "../../assets/images/common/lock-big.png";
type ModalProps = {
  description: string;
  closeAction: Function;
};

const LockField: React.FC<ModalProps> = (props) => {
  const { description, closeAction } = props;

  return (
    <IonCard className="lock_field_card">
      <div>
        <img src={locked_icon} alt="" className="lock_field_card__img" />
        <div className="lock_field_card__text">{description}</div>
        <IonButton
          expand="block"
          mode="ios"
          type="button"
          className="form-group__button lock_field_card__btn"
          onClick={() => closeAction()}>
          Upgrade Now
        </IonButton>
      </div>
    </IonCard>
  );
};
export default LockField;

import react, { RefObject, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  IonCard,
  IonCol,
  IonGrid,
  IonProgressBar,
  IonRow,
  IonIcon,
  IonSpinner,
} from "@ionic/react";
import { get } from "../../../storage/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./RevenueAndPoints.css";
import xMoreBadgesIcon from "../../../assets/badges/x_more.svg";
import xMoreBadgesGrayIcon from "../../../assets/badges/x_more_gray.svg";
import eliteBadge from "../../../assets/badges/elite_badge.svg";
import sample_img from "../../../assets/model-image.png";
import {
  scoreboardLanding,
  getTalentBadges,
} from "../../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import model from "../../../assets/images/common/model.png";
import info_circle from "../../../assets/images/common/info-circle.svg";
import InfoButtonPopover from "../../../push-components/InfoButtonPopover/InfoButtonPopover";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";

// Define the type of the container ref
type ContainerRefType = RefObject<HTMLDivElement>;

const ChevronRightIcon = <FontAwesomeIcon icon={faChevronRight} />;

const RevenueAndPoints: React.FC = () => {
  const dispatch = useDispatch();
  const containerRef: ContainerRefType = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [talentHeadshotImage, setTalentHeadshotImage] = useState<string>("");
  const [talentFullname, setTalentFullname] = useState<string>("");
  const [showInfo, setShowInfo] = useState<string>("");
  const [talentBadges, setTalentBadges] = useState<any>([]);
  const [talentBadgesAll, setTalentBadgesAll] = useState<any>([]);
  const [talentBadgesLoaded, setTalentBadgesLoaded] = useState<any>(false);
  const [remainingBadges, setRemainingBadges] = useState<any>(0);
  const [atLeastOneHiddenBadgeIsEarned, setAtLeastOneHiddenBadgeIsEarned] =
    useState<any>(false);
  const [scoreboardData, setScoreboardData] = useState<{
    is_talent_elite: boolean;
    elite_progress: number;
    account_setup_complete: boolean;
    covid19Vaccinated: boolean;
    influencer: boolean;
    brand_ambassador: boolean;
    model: boolean;
    current_quarter: string;
    points: number;
  }>({
    is_talent_elite: false,
    elite_progress: 0,
    account_setup_complete: false,
    covid19Vaccinated: false,
    influencer: false,
    brand_ambassador: false,
    model: false,
    current_quarter: "",
    points: 0,
  });

  const container = containerRef.current;
  const badgeWidth = 38; // Change this to the actual width of your badge

  const navigateTo = () => {
    history.push("/Payments");
  };

  function updateVisibleBadges() {
    if (container) {
      const containerWidth = container.offsetWidth;
      const newVisibleBadges = Math.floor(containerWidth / badgeWidth) - 1;
      const remainingBadges = talentBadgesAll.length - newVisibleBadges;
      setTalentBadges(talentBadgesAll.slice(0, newVisibleBadges));
      setAtLeastOneHiddenBadgeIsEarned(
        talentBadgesAll
          .slice(newVisibleBadges, talentBadgesAll.length - 1)
          .some((badge: any) => badge.tb_earned)
      );
      setRemainingBadges(remainingBadges);
    } else {
      setAtLeastOneHiddenBadgeIsEarned(false);
      setTalentBadges(talentBadgesAll.slice(0, 3));
      setRemainingBadges(talentBadgesAll.length - 3);
    }
    setTalentBadgesLoaded(true);
  }

  useEffect(() => {
    (async () => {
      let scoreboard_api = await scoreboardLanding();
      if (scoreboard_api?.success) {
        let elite_preg = scoreboard_api.data.elite_progress
          ? scoreboard_api.data.elite_progress.replace("%", "")
          : 0;
        setScoreboardData({
          is_talent_elite: await get("login_model_is_elite"),
          elite_progress: elite_preg,
          current_quarter: scoreboard_api.data.current_quarter,
          account_setup_complete:
            scoreboard_api.data.badges.account_setup_complete,
          covid19Vaccinated: scoreboard_api.data.badges.covid19Vaccinated,
          influencer: scoreboard_api.data.badges.influencer,
          brand_ambassador: scoreboard_api.data.badges.brand_ambassador,
          model: scoreboard_api.data.badges.model,
          points: scoreboard_api.data.points.amount,
        });
      } else {
        if (scoreboard_api?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (scoreboard_api?.data?.errorCode == offlineErrorCode) {
          showToastMessage(scoreboard_api?.data?.message);
        } else if (scoreboard_api?.message) {
          await showToastMessage(scoreboard_api.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }

      let badges_api = await getTalentBadges(true);
      if (badges_api?.success) {
        setTalentBadgesAll(badges_api.data.talent_badges);

        updateVisibleBadges();
      } else {
        if (badges_api?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (badges_api?.data?.errorCode == offlineErrorCode) {
          showToastMessage(badges_api?.data?.message);
        } else if (badges_api?.message) {
          await showToastMessage(badges_api.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }

      let talentHeadshot = await get("login_user_headshot");
      if (talentHeadshot) {
        setTalentHeadshotImage(talentHeadshot);
      }
      let talent_last_name = await get("login_model_last_name");
      talent_last_name = talent_last_name ? talent_last_name : "";
      setTalentFullname(
        `${await get("login_model_first_name")} ${talent_last_name
          .toUpperCase()
          .charAt(0)}.`
      );

      // Add an event listener for the resize event
      window.addEventListener("resize", updateVisibleBadges);

      window.addEventListener("orientationchange", updateVisibleBadges);
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", updateVisibleBadges);
        window.removeEventListener("orientationchange", updateVisibleBadges);
      };
    })();
  }, [talentHeadshotImage, talentFullname]);
  return (
    <>
      <IonGrid className="revenu-points">
        <IonRow>
          <IonCol size="6" className="revenu-points__left_col">
            <IonCard className="home-profile-status home-profile-status__right">
              <div className="home-profile-status__info">
                <div
                  className={
                    scoreboardData.is_talent_elite
                      ? "profile-status__info__img-container profile-status__info__img-container--elite"
                      : "profile-status__info__img-container"
                  }
                >
                  <div className="profile-status__info__img profile-status__info__img--elite">
                    <img
                      alt=""
                      src={
                        talentHeadshotImage ? talentHeadshotImage : sample_img
                      }
                    />
                  </div>

                  {scoreboardData.is_talent_elite && (
                    <div className="profile-status__info__elite-img">
                      <img
                        className={
                          scoreboardData.is_talent_elite
                            ? "profile-status__info__img-elite-badge"
                            : "profile-status__info__img-elite-badge profile-status__info__img-elite-badge--gray"
                        }
                        src={eliteBadge}
                      ></img>
                    </div>
                  )}
                </div>
                <div className="profile-status__info__name">
                  <h5>{talentFullname}</h5>
                </div>

                <div className="point-status__info__points">
                  <div>
                    <span>
                      {scoreboardData.points}
                      <span className="point-status__info__points__hide-on-low-res">
                        {" "}
                      </span>
                      P
                      <span className="point-status__info__points__hide-on-low-res">
                        OINT{scoreboardData.points != 1 ? "S" : ""}
                      </span>
                    </span>
                    <IonIcon
                      icon={info_circle}
                      onClick={() => setShowInfo("home_points")}
                    />
                  </div>
                  <div className="point-status__info__view_all">
                    <span onClick={() => history.push("/MyPoints")}>
                      <i className="">{ChevronRightIcon}</i>
                    </span>
                  </div>
                </div>

                {/* {scoreboardData.is_talent_elite && 
                 <div className="profile-status__elite_badge">
                 <img
                          className=""
                          src={elite_golden_badge}
                          alt=""
                        />
                 </div>
                } */}
              </div>
            </IonCard>
          </IonCol>
          <IonCol size="6" className="revenu-points__right_col">
            <IonCard className="home-point-status home-point-status__left">
              <div className="home-point-status__info">
                <div
                  ref={containerRef}
                  className="point-status__info__imgs"
                  onClick={() => history.push("/Badges")}
                >
                  {talentBadgesLoaded && (
                    <>
                      {talentBadges.map((item: any, index: any) => (
                        <img
                          className={
                            !item.tb_earned
                              ? "point-status__info__imgs__not-earned"
                              : ""
                          }
                          alt=""
                          src={item.tb_photo}
                        />
                      ))}
                      {remainingBadges > 0 && (
                        <div
                          onClick={() => history.push("/Badges")}
                          className="point-status__info__imgs__remaining-container"
                        >
                          <img
                            src={
                              atLeastOneHiddenBadgeIsEarned
                                ? xMoreBadgesIcon
                                : xMoreBadgesGrayIcon
                            }
                          ></img>
                          <div className="point-status__info__imgs__remaining-container__remaining">
                            +{remainingBadges}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {!talentBadgesLoaded && (
                    <div className="centered-spinner">
                      <IonSpinner color="primary" name="crescent" />
                    </div>
                  )}
                </div>

                <div>
                  <div className="profile-status__info__account">
                    <div className="account--elite">
                      <span onClick={() => history.push("/MyScoreboard")}>
                        <span className="profile-status__info__account__hide-on-lower-res">
                          {scoreboardData.current_quarter}
                        </span>{" "}
                        Elite{" "}
                        <span className="profile-status__info__account__hide-on-low-res">
                          Progress
                        </span>
                      </span>
                      <IonIcon
                        icon={info_circle}
                        onClick={() => setShowInfo("home_elite_status")}
                      />
                    </div>
                    <div
                      onClick={() => history.push("/MyScoreboard")}
                      className="account--progress"
                    >
                      {scoreboardData.elite_progress}%
                    </div>
                  </div>
                  <div
                    onClick={() => history.push("/MyScoreboard")}
                    className="profile-status__info__progress"
                  >
                    <IonProgressBar
                      value={scoreboardData.elite_progress / 100}
                    ></IonProgressBar>
                  </div>
                </div>
              </div>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
      {showInfo === "home_elite_status" && (
        <InfoButtonPopover
          infoButtonClass={"home_elite_status"}
          updatePopoverState={setShowInfo}
        ></InfoButtonPopover>
      )}

      {showInfo === "home_points" && (
        <InfoButtonPopover
          infoButtonClass={"home_points"}
          updatePopoverState={setShowInfo}
        ></InfoButtonPopover>
      )}

      {/* <div className="home-revenue-card">
        <IonItem>
          <IonTitle  className="home-revenue-card__title">
            My Revenue
          </IonTitle>
          <IonText
            slot="end"
            className="home-revenue-card__view_all_item"
            onClick={() => navigateTo()}
          >
            View details &nbsp;
            <i className="">{ChevronRightIcon}</i>
          </IonText>
        </IonItem>
        <TabMenu></TabMenu>
      </div> */}
    </>
  );
};
export default RevenueAndPoints;

import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import "./AssignmentRecapSuccess.css";
import { star, starOutline } from "ionicons/icons";
import backArrow from "../../../assets/images/common/arrow-left.png";

type ModalProps = {
  isopen_assign_recap_success: boolean;
  closeAssignRecapSuccess: Function;
};

const AssignmentRecapSuccess: React.FC<ModalProps> = (props) => {
  const {
    isopen_assign_recap_success,
    closeAssignRecapSuccess,
  } = props;

  const [ratingBookingAgent, setRatingBookingAgent] = useState<any>(0);
  const [ratingOverallExp, setRatingOverallExp] = useState<any>(2);

  const ratings: any = [1, 2, 3, 4, 5];
  const renderElement = (ratingtype: any, index: any) => {
    let rating = 0;
    if (ratingtype == 1) {
      rating = ratingBookingAgent;
    } else {
      rating = ratingOverallExp;
    }

    if (index <= rating) {
      return (
        <IonIcon
        key={index}
          onClick={(_) =>
            ratingtype == 1 ? setRatingBookingAgent(index) : setRatingOverallExp(index)
          }
          className="assign-recap-success-container__fastart-icon assign-recap-success-container__rating-highlight"
          icon={star}
          size="large"
        />
      );
    } else {
      return (
        <IonIcon
        key={index}
          onClick={(_) =>
            ratingtype == 1 ? setRatingBookingAgent(index) : setRatingOverallExp(index)
          }
          className="assign-recap-success-container__fastart-icon"
          icon={starOutline}
          size="large"
        />
      );
    }
  };
  
  return (
    <IonModal isOpen={isopen_assign_recap_success} mode="ios">
      <IonHeader className="assign-recap-success__header">
        <IonToolbar color="primary">
          <IonButton
            fill="clear"
            slot="start"
            onClick={() => closeAssignRecapSuccess()}
          >
            <img
              alt=""
              src={backArrow}
              className="assign-recap-success__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
    <IonContent>
      <div className="assign-recap-success-container">
      <IonRow>
        <p className="assign-recap-success-container__title">
          Thank you for submitting your recap
        </p>
      </IonRow>
      <IonCard className="assign-recap-success-container__card-container">
        <p className="assign-recap-success-container__sub-title">
          Rate your Booking Agent
        </p>
        <div className="assign-recap-success-container__rating">
          {ratings.map((itm: any, index: any) => renderElement(1, itm))}
        </div>
      </IonCard>
      <IonCard className="assign-recap-success-container__card-container">
        <p className="assign-recap-success-container__sub-title">
          Rate your Overall Experience with PUSH
        </p>
        <div className="assign-recap-success-container__rating">
          {ratings.map((itm: any, index: any) => renderElement(2, itm))}
        </div>
      </IonCard>
      
      <div className="assign-section-footer">
                  <IonButton
                    expand="block"
                    mode="ios"
                    className="form-group__button"
                  >
                    Share
                  </IonButton>
                </div>
                </div>
    </IonContent>
  </IonModal>
  );
};

export default AssignmentRecapSuccess;

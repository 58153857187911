import { CapacitorHttp } from '@capacitor/core';
import { get, set } from "../storage/storage";
import axios from "axios";
import { Network } from "@capacitor/network";
import { noInternetResponse, unauthorizedResponse } from "../common/common";
export const getUpcomingEvents = async (upcomingType = "onlyUpcoming") => {
  if(!upcomingType) {
    upcomingType = "onlyUpcoming";
  }
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/modelupcomingevents?`+upcomingType+`=1&order_by=recent`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let upcoming_event_api_res = await CapacitorHttp.get(config);
    return upcoming_event_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getAndCacheTalentThumb = async (headshot: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/makeThumb?imagePah=${process.env.REACT_APP_HP_IMAGE_URL}/${headshot}&width=200&height=200`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let thumb_api_response: any = await CapacitorHttp.get(config);
    if (thumb_api_response?.data?.data?.thumb) {
      await set("login_user_headshot", thumb_api_response.data.data.thumb);
    }
  } else {
    return noInternetResponse;
  }
};

export const savePushPlacementForm = async (form_body: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/savePushPlacementForm`,
      headers: {
        "Content-Type": "text/plain",
        token: authToken,
      },
      data: form_body,
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const changePassword = async (form_body: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/changePassword`,
      headers: {
        "Content-Type": "text/plain",
        token: authToken,
      },
      data: form_body,
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const generateAgreement = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/generateAgreement`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let thumb_api_response: any = await CapacitorHttp.get(config);
    return thumb_api_response.data;
  } else {
    return noInternetResponse;
  }
};

export const generateWorkForm = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/generateWorkForm`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let thumb_api_response: any = await CapacitorHttp.get(config);
    return thumb_api_response.data;
  } else {
    return noInternetResponse;
  }
};

export const getAccountNotifications = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelAccountNotifications`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let notification_data = await CapacitorHttp.get(config);
    return notification_data.data;
  } else {
    return noInternetResponse;
  }
};

export const getTipaltiPaymentsPage = async (requestedPage: any, requestParams?: any) => {
  if(!requestParams) {
    requestParams = "";
  }
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getTipaltiPaymentsPage?requested_page=${requestedPage}&request_params=${requestParams}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let notification_data = await CapacitorHttp.get(config);
    return notification_data.data;
  } else {
    return noInternetResponse;
  }
};

export const saveTipaltiPaymentPaperworkStatus = async (status: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveTipaltiPaymentPaperworkStatus?status=${status}`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let notification_data = await CapacitorHttp.get(config);
    return notification_data.data;
  } else {
    return noInternetResponse;
  }
};

export const saveWorkFormw9 = async (form_body: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/saveWorkForm`,
      headers: {
        "Content-Type": "text/plain",
        token: authToken,
      },
      data: form_body,
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};
export const saveModelDeposit = async (form_body: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/saveModelDeposit`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: form_body,
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const getModelLocations = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelLocations`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let api_res = await CapacitorHttp.get(config);
    return api_res.data;
  } else {
    return noInternetResponse;
  }
};
export const insertModelLocation = async (form_body: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/insertModelLocation
      `,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: form_body,
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const selectModelLocation = async (location_id: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/selectModelLocation`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { location_id: location_id },
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const saveTalentVaccineAnswer = async (formData: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    const options: any = {
      method: "post",
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/saveTalentVaccineAnswer`
      ),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        token: authToken,
      },
    };
    let api_response = await axios(options);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

export const deleteModelLocation = async (location_id: string) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/deleteModelLocation`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: { location_id: location_id },
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const markFirstTimeLoginSlideShowAsSeen = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/markFirstTimeLoginSlideShowAsSeen?withTipalti=1&withTalentIntroVideo=1`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {},
    };
    let response = await axios(config);
    await set("model_mobile_first_time_slides_shown", 1);
    return response.data;
  } else {
    return noInternetResponse;
  }
}

export const showTalentAppInstabookIntroHome = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "GET",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/showTalentAppInstabookIntroHome`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {},
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
}

export const setTalentSetting = async (setting_name: any, setting_value: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_HP_API_URL}/userEvent/setTalentSetting`,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        setting_name: setting_name,
        setting_value: setting_value
      },
    };
    let response = await axios(config);
    return response.data;
  } else {
    return noInternetResponse;
  }
}




export const getDownloadNewAppImage = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getDownloadNewAppImage?withTipalti=1`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let thumb_api_response: any = await CapacitorHttp.get(config);
    return thumb_api_response.data;
  } else {
    return noInternetResponse;
  }
};

export const getModelCTANotifications = async (typeOfContent?: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelCTANotifications?withVirtualCasting=1&typeOfContent=${typeOfContent}&withVirtualCastingHideUpdate=1`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let api_response: any = await CapacitorHttp.get(config);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};

export const getAvailableReferralCampaign = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let config: any = {
      url: encodeURI(
        `${process.env.REACT_APP_HP_API_URL}/userEvent/getAvailableReferralCampaign`
      ),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let api_response: any = await CapacitorHttp.get(config);
    return api_response.data;
  } else {
    return noInternetResponse;
  }
};


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { set, clear, get } from "../storage/storage";


const initialAuthState = {
  is_authenticated: false,
  login_user_id: "",
  login_user_name: "",
  login_user_password: "",
  login_user_reg_date: "",
  login_user_lastlogin_datetime: "",
  login_user_comes_from: "",
  login_user_type: "",
  login_user_status: "",
  login_user_acquired: "",
  login_user_flag_account: "",
  login_user_token: "",
  login_user_token_expire: "",
  login_user_confirm_hash: "",
  login_user_is_contact_id: "",
  login_user_one_signal_token: "",
  login_model_first_name: "",
  login_model_hasto_fill_paperwork: "",
  login_model_country: "",
};

//  create the thunk
export const isUserloggedIn = createAsyncThunk(
  "users/fetchByIdStatus",
  async () => {
    let loginUserObject = {
      is_authenticated: true,
      token: await get("login_user_token"),
      login_user_id: await get("login_user_id"),
      login_user_name: await get("login_user_name"),
      login_user_type: await get("login_user_type"),
      login_user_status: await get("login_user_status"),
      login_model_first_name: await get("login_model_first_name"),
    };
    if(loginUserObject.token){

      return loginUserObject;
    }else{
      await clear();
      return {
        is_authenticated: false,
        token: "",
        login_user_id: "",
        login_user_name: "",
        login_user_type: "",
        login_user_status: "",
        login_model_first_name: "",
        };
    }
  }
);

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      let action_values = action.payload;

      state.is_authenticated = action_values.is_authenticated;
      state.login_user_id = action_values.user_id;
      state.login_user_name = action_values.user_name;
      state.login_user_password = action_values.user_password;
      state.login_user_reg_date = action_values.user_reg_date;
      state.login_user_lastlogin_datetime = action_values.user_lastlogin_datetime;
      state.login_user_comes_from = action_values.user_comes_from;
      state.login_user_type = action_values.user_type;
      state.login_user_status = action_values.user_status;
      state.login_user_acquired = action_values.user_acquired;
      state.login_user_flag_account = action_values.user_flag_account;
      state.login_user_token = action_values.user_token;
      state.login_user_token_expire = action_values.user_token_expire;
      state.login_user_confirm_hash = action_values.user_confirm_hash;
      state.login_user_is_contact_id = action_values.user_is_contact_id;
      state.login_user_one_signal_token = action_values.user_one_signal_token;
      state.login_model_first_name = action_values.modelFirstName;
      state.login_model_hasto_fill_paperwork = action_values.modelHasToFillPaperwork;
      state.login_model_country = action_values.modelCountry;
    },
    logout(state) {
      state.is_authenticated = false;
      state.login_user_id = "";
      state.login_user_name = "";
      state.login_user_password = "";
      state.login_user_reg_date = "";
      state.login_user_lastlogin_datetime = "";
      state.login_user_comes_from = "";
      state.login_user_type = "";
      state.login_user_status = "";
      state.login_user_acquired = "";
      state.login_user_flag_account = "";
      state.login_user_token = "";
      state.login_user_token_expire = "";
      state.login_user_confirm_hash = "";
      state.login_user_is_contact_id = "";
      state.login_user_one_signal_token = "";
      state.login_model_first_name = "";
      state.login_model_hasto_fill_paperwork = "";
      state.login_model_country = "";

      const clearStore = async () => {
        await clear();
      };
      clearStore();

      window.location.href = '/';
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(isUserloggedIn.fulfilled, (state, action) => {
      let action_values = action.payload;
      
      state.is_authenticated = action_values.is_authenticated;
      state.login_user_id = action_values.user_id;
      state.login_user_name = action_values.user_name;
      state.login_user_password = action_values.user_password;
      state.login_user_reg_date = action_values.user_reg_date;
      state.login_user_lastlogin_datetime =action_values.user_lastlogin_datetime;
      state.login_user_comes_from = action_values.user_comes_from;
      state.login_user_type = action_values.user_type;
      state.login_user_status = action_values.user_status;
      state.login_user_acquired = action_values.user_acquired;
      state.login_user_flag_account = action_values.user_flag_account;
      state.login_user_token = action_values.user_token;
      state.login_user_token_expire = action_values.user_token_expire;
      state.login_user_confirm_hash = action_values.user_confirm_hash;
      state.login_user_is_contact_id = action_values.user_is_contact_id;
      state.login_user_one_signal_token = action_values.user_one_signal_token;
      state.login_model_first_name = action_values.modelFirstName;
      state.login_model_hasto_fill_paperwork = action_values.modelHasToFillPaperwork;
      state.login_model_country = action_values.modelCountry;
    });
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;

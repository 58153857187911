import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IonSlides, IonSlide, IonIcon, IonButton } from "@ionic/react";
import { get, set } from "../../../storage/storage";
import { useDispatch } from "react-redux";
import "./CTASlider.css";

import { setTalentSetting } from "../../../data-services/home-api";
import instantBookIcon from "../../../assets/images/opportunity/lightning_bolt.png";
import remove_outline from "../../../assets/images/common/cta_checkmark.svg";
import { getModelCTANotifications } from "../../../data-services/home-api";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import { authActions } from "../../../store/auth";
import check_black from "../../../assets/images/common/check_black.svg";
import { saveModelOpportunitySelection } from "../../../data-services/opportunities";

type CTASliderProps = {
  talentRequiredAction: any;
  setWidgetLoaded: Function;
};

const CTASlider: React.FC<CTASliderProps> = (props) => {
  const dispatch = useDispatch();
  const { talentRequiredAction, setWidgetLoaded } = props;
  const history = useHistory();
  const ionSlidesRef = React.useRef<HTMLIonSlidesElement>(null);
  const [slideOpts, setSlideOpts] = useState<any>({
    direction: "horizontal",
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 15,
  });
  const [showInstaBookIntro, setShowInstaBookIntro] = useState(false);
  const [showIntroVideo, setShowIntroVideo] = useState(false);
  const [ctaItems, setCTAItems] = useState<any>(0);

  const [showPaymentFormNotice, setShowPaymentFormNotice] =
    useState<any>(false);
  const [showW2FormNotice, setShowW2FormNotice] = useState<any>(false);
  const [ctaNotifications, setCTANotifications] = useState<any>([]);

  const handleSlideDidChange = (event: CustomEvent) => {};

  const hideContent = (type?: any, value?: any) => {
    // Set CTA Items to 0 in order to HIDE the Slider while it is re-rendering
    // Trying to hide one Slide will crash the component
    setCTAItems(0);
    // setReloadSlider(true);
    if (type == "instaBook") {
      loadSliders("instaBook");
    } else if (type == "hide_referral_campaigns_until") {
      loadSliders("hide_referral_campaigns_until", value);
    } else if(type == "virtualCasting") {
      loadSliders("virtualCasting", value);
    }

    // setReloadSlider(false);
  };

  const loadSliders = async (hiddenContent?: any, hiddenContentValue?: any) => {
    // login_model_show_insta_book_intro && login_expand_tapp_insta_book_preview
    let showInstaBook =
      (await get("login_model_show_insta_book_intro")) == 1 &&
      (await get("login_expand_tapp_insta_book_preview")) == 1;
    let showIntroVideo = !(await get("login_has_intro_video"));
    let items = 0;
    let instaBookHiddenContent = false;

    // Payment Form Notice
    if (
      talentRequiredAction.remaining === 1 &&
      (talentRequiredAction.items.tipaltiPaymentStatus == "0" ||
        talentRequiredAction.items.tipaltiPaymentStatus == "1" ||
        talentRequiredAction.items.tipaltiPaymentStatus == null)
    ) {
      items++;
      setShowPaymentFormNotice(true);
    }

    // W2 Form Notice
    if (
      talentRequiredAction.remaining === 1 &&
      (talentRequiredAction.items.w2form == "1" ||
        talentRequiredAction.items.w2form == "4")
    ) {
      items++;
      setShowW2FormNotice(true);
    }

    // Dynamic Notice
    // TBD
    // title - required
    // text - required
    // button?:
    // canBeHidden: true

    // Insta Book Intro Notice
    if (showInstaBook && hiddenContent != "instaBook") {
      setShowInstaBookIntro(showInstaBook);
      items++;
    } else if (hiddenContent == "instaBook") {
      instaBookHiddenContent = true;
      // InstaBook Hide Button clicked scenario
      setShowInstaBookIntro(false);
      set("login_expand_tapp_insta_book_preview", 0);
      setTalentSetting("expand_tapp_insta_book_preview", 0);
    }

    if (showIntroVideo) {
      setShowIntroVideo(showIntroVideo);
      items++;
    }

    let tempCTANotifications;
    // Dynamic content to hide
    if (hiddenContent && !instaBookHiddenContent && hiddenContent != "virtualCasting") {
        setTalentSetting(hiddenContent, hiddenContentValue);
        tempCTANotifications = ctaNotifications.filter(
          (item: any) => item?.setting_name != hiddenContent
        );
    } else {
      if(hiddenContent == "virtualCasting") {
        await setTalentSetting("hide_virtual_casting_" + hiddenContentValue, hiddenContentValue);

      }
      let modelCTANotifications = await getModelCTANotifications();
      if (modelCTANotifications?.success) {
        // Dynamic content
        tempCTANotifications = modelCTANotifications?.data?.ctaNotifications;
      } else if (
        modelCTANotifications?.data?.errorCode == unauthorizedErrorCode
      ) {
        dispatch(authActions.logout());
        return;
      } else if (modelCTANotifications?.data?.errorCode == offlineErrorCode) {
        showToastMessage(modelCTANotifications?.data?.message);
      } else if (modelCTANotifications?.message) {
        showToastMessage(modelCTANotifications.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }

    if (tempCTANotifications?.length) {
      setCTANotifications(tempCTANotifications);
      items = items + tempCTANotifications.length;
    } else {
      setCTANotifications([]);
    }
    setCTAItems(items);

    // Prevent sliding if there is only one item shown
    if (items == 1) {
      setSlideOpts({
        direction: "horizontal",
        slidesPerView: 1,
        speed: 400,
        allowSlidePrev: false,
        allowSlideNext: false,
      });
    }
    setWidgetLoaded(true);
  };

  useEffect(() => {
    (async () => {
      loadSliders();
    })();
  }, []);

  return (
    <div id="home__cta-slider">
      {ctaItems > 0 && (
        <IonSlides
          pager={ctaItems > 1}
          ref={ionSlidesRef}
          options={slideOpts}
          onIonSlideDidChange={handleSlideDidChange}
        >
          {/* One Action Item - Tipalti Not Done or Pending Scenario */}
          {showPaymentFormNotice && (
            <IonSlide>
              <div className="orange-border">
                <div className="orange-border__content">
                  {talentRequiredAction.items.tipaltiPaymentStatus == "0" ||
                  talentRequiredAction.items.tipaltiPaymentStatus == null
                    ? "Please complete your payment paperwork and setup your payment method. Please note: The review process can take up to 1 business day."
                    : "Your paperwork is complete and is now being reviewed for approval. Please note: The review process can take up to 1 business day."}
                </div>
                <p className="orange-border__content"></p>
                <div className="orange-border__content">
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    onClick={() =>
                      history.push(
                        talentRequiredAction.items.tipaltiPaymentStatus ==
                          "0" ||
                          talentRequiredAction.items.tipaltiPaymentStatus ==
                            null
                          ? "/PaymentDetails"
                          : "/FAQsProfileManagement/p3"
                      )
                    }
                  >
                    {talentRequiredAction.items.tipaltiPaymentStatus == "0" ||
                    talentRequiredAction.items.tipaltiPaymentStatus == null
                      ? "Setup Payment"
                      : "Learn More"}
                  </IonButton>
                </div>
              </div>
            </IonSlide>
          )}
          {/* One Action Item - Payreel Onboarding */}
          {showW2FormNotice && (
            <IonSlide>
              <div className="orange-border">
                <div className="orange-border__content">
                  Your W2 Form is complete and is now being reviewed for
                  approval
                </div>
                <p className="orange-border__content"></p>
                <div className="orange-border__content cta-slider-preview__buttons">
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    style={{ width: '130px', height: '40px' }}
                    onClick={() => history.push("/FAQsProfileManagement/p3")}
                  >
                    Learn More
                  </IonButton>
                </div>
              </div>
            </IonSlide>
          )}
          {ctaNotifications.length > 0 &&
            ctaNotifications.map((item: any, index: any) => (
              <IonSlide>
                {item?.type == "virtual_casting" ? (
                  <div className="virtual_casting__container">
                    <img
                      className="virtual_casting__container__banner"
                      src={item?.preview_photo}
                    />
                    <div onClick={() => hideContent("virtualCasting", item?.ue_id)} className="virtual_casting__container__bottom-right">
                      {" "}
                      {/* <img src={check_black} alt="" /> */}
                      <IonIcon
                        src={remove_outline}
                        slot="icon-only"
                      ></IonIcon>{" "}
                    </div>
                    <div className="virtual_casting__container__centered cta-slider-preview__buttons">
                      <IonButton
                        mode="ios"
                        size="large"
                        color="dark"
                        strong={false}
                        style={{ width: '130px', height: '40px' }}
                        onClick={() => history.push(item?.route)}
                      >
                        Learn More
                      </IonButton>
                    </div>
                  </div>
                ) : (
                  <div className="orange-border">
                    {item?.header && (
                      <div className="orange-border__content">
                        <strong>{item?.header}</strong>
                      </div>
                    )}
                    {item?.description && (
                      <p className="orange-border__content">
                        {item?.description}
                      </p>
                    )}
                    {(item?.route || item?.hide) && (
                      <div className="orange-border__content cta-slider-preview__action-items cta-slider-preview__buttons">
                        <div className="cta-slider-preview__action-items__centering-placeholder"></div>
                        {item?.route ? (
                          <IonButton
                            mode="ios"
                            fill="outline"
                            size="large"
                            style={{ width: '130px', height: '40px' }}
                            onClick={() => history.push(item?.route)}
                          >
                            Learn More
                          </IonButton>
                        ) : (
                          <div className="cta-slider-preview__action-items__centering-placeholder"></div>
                        )}
                        {item?.hide && (
                          <div
                            onClick={() =>
                              hideContent(
                                item?.setting_name,
                                item?.setting_value
                              )
                            }
                            className="orange-border__content cta-slider-preview__colapse-icon-container"
                          >
                            {" "}
                            <IonIcon
                              src={remove_outline}
                              slot="icon-only"
                            ></IonIcon>{" "}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </IonSlide>
            ))}

          {showInstaBookIntro && (
            <IonSlide>
              <div className="orange-border cta-slider-preview">
                <div className="orange-border__content">
                  <strong>
                    Introducing Instant Book{" "}
                    <img
                      className="homepage__content__instabook-icon"
                      src={instantBookIcon}
                    />
                  </strong>
                </div>
                <p className="orange-border__content">
                  Skip the Applicants line and sign up for gigs instantly!
                </p>
                <div className="orange-border__content cta-slider-preview__action-items cta-slider-preview__buttons">
                  <div className="cta-slider-preview__action-items__centering-placeholder"></div>
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    style={{ width: '130px', height: '40px' }}
                    onClick={() => history.push("/FAQsProfileManagement/p5")}
                  >
                    Learn More
                  </IonButton>
                  <div
                    onClick={() => hideContent("instaBook")}
                    className="orange-border__content cta-slider-preview__colapse-icon-container"
                  >
                    {" "}
                    <IonIcon
                      src={remove_outline}
                      slot="icon-only"
                    ></IonIcon>{" "}
                  </div>
                </div>
              </div>
            </IonSlide>
          )}
          {showIntroVideo && (
            <IonSlide>
              <div className="orange-border cta-slider-preview">
                <p className="orange-border__content">
                  Make your profile stand out from the rest by creating a
                  personal intro video!
                </p>
                <div className="orange-border__content cta-slider-preview__buttons">
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    style={{ width: '130px', height: '40px' }}
                    onClick={() => history.push("/ManageIntroVideo")}
                  >
                    Learn More
                  </IonButton>
                </div>
              </div>
            </IonSlide>
          )}
        </IonSlides>
      )}
    </div>
  );
};
export default CTASlider;

import React, { useState, useEffect } from "react";

import {
  IonCard,
  IonCardContent,
  IonText,
  IonBadge,
  IonButton,
  IonAlert,
} from "@ionic/react";

import StarIconOrange from "../../assets/images/common/star-fill-orange.svg";
import "./EventCardList.css";
import AssignmentDecline from "../../pages/AssignmentView/AssignmentDecline/AssignmentDecline";
import AssignmentConfirm from "../../pages/AssignmentView/AssignmentConfirm/AssignmentConfirm";
import { 
  getUserCurrentPosition, 
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg, } from "../../common/common";
import AssignmentCheckin from "../../pages/AssignmentView/AssignmentCheckin/AssignmentCheckin";
import {
  modelResumeWork,
  modelTakeBreak,
  talentEventCheckout,
  saveModelDC
} from "../../data-services/assignmets";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import { useHistory } from "react-router";
import moment from "moment";
import pointsMultiplied from "../../assets/images/opportunity/3fcd9cf2361f5ffd0e5851b19721d403.gif";
import instantBookIcon from "../../assets/images/opportunity/lightning_bolt.png";

type opportunityDetails = {
  card_event_type: string;
  card_event_route_to: string;
  card_miles_badge: string;
  card_waitlist_only?: any;
  model_level_badge?: any;
  card_event_image: string;
  card_event_date: string;
  card_event_title: string;
  card_event_address: string;
  card_event_payment: string;
  card_event_points: any;
  event_action_detail?: any;
  card_event_points_multiplier?: any;
  card_auto_book_enabled?: any;
  reinit?: Function;
  openOppViewModal?: (event_id: any) => any;
  card_event_id?: any;
  oppConfirmationStatusList?: any;
};
const EventCardList: React.FC<opportunityDetails> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    card_event_type,
    card_event_route_to,
    card_miles_badge,
    card_waitlist_only,
    model_level_badge,
    card_event_image,
    card_event_date,
    card_event_title,
    card_event_address,
    card_event_payment,
    card_event_points,
    event_action_detail,
    card_event_points_multiplier,
    card_auto_book_enabled,
    reinit,
    openOppViewModal,
    card_event_id,
    oppConfirmationStatusList
  } = props;

  const [isOpenAssignDecline, setIsOpenAssignDecline] = useState<{
    event_name: string;
    event_start_date: any;
    is_open: boolean;
    event_id: any;
  }>({ event_name: "", event_start_date: "", is_open: false, event_id: "" });
  const [isOpenAssignConfirm, setIsOpenAssignConfirm] = useState<{
    event_name: string;
    start_date: string;
    start_time: string;
    talent_role: string;
    event_id: string;
    is_open: boolean;
    assign_p_card_required: boolean;
    assign_p_card_instructions: string;
    backupTypeInt: any;
    backupType: any;
    backupInstructions: any;
    autoBookEnabled: any;
    requiredCustomerAgreementText: string;
  }>({
    event_name: "",
    start_date: "",
    start_time: "",
    talent_role: "",
    event_id: "",
    is_open: false,
    assign_p_card_required: false,
    assign_p_card_instructions: "",
    backupTypeInt: 1,
    backupType: "",
    backupInstructions: "",
    autoBookEnabled: false,
    requiredCustomerAgreementText: ""
  });
  const [enabledChekinBtn, setEnabledChekinBtn] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: "", longitude: "" });
  const [isOpenCheckin, setIsOpenCheckin] = useState<{
    is_open_checkin: boolean;
    event_id: string;
  }>({ is_open_checkin: false, event_id: "" });
  const [checkinConfirmed, setCheckinConfirmed] = useState<{
    show: boolean;
    message: string;
  }>({ show: false, message: "" });

  const [confirmationBadge, setConfirmationBadge] = useState<string>("");

  const closeAssignDecline = () => {
    setIsOpenAssignDecline({ event_name: "", event_start_date: "", is_open: false, event_id: "" });
  };
  const closeAssignConfirm = async (
    param: string = "",
    event_name: string = "",
    event_id: any = "",
    event_start_date: any = ""
  ) => {
    if (param === "decline") {
      setIsOpenAssignConfirm({
        event_name: "",
        start_date: "",
        start_time: "",
        talent_role: "",
        event_id: "",
        is_open: false,
        assign_p_card_required: false,
        assign_p_card_instructions: "",
        backupTypeInt: 1,
        backupType: "",
        backupInstructions: "",
        autoBookEnabled: false,
        requiredCustomerAgreementText: ""
      });
      setIsOpenAssignDecline({
        event_name: event_name,
        event_start_date: event_start_date,
        is_open: true,
        event_id: event_id,
      });
    } else {
      setIsOpenAssignConfirm({
        event_name: "",
        start_date: "",
        start_time: "",
        talent_role: "",
        event_id: "",
        is_open: false,
        assign_p_card_required: false,
        assign_p_card_instructions: "",
        backupTypeInt: 1,
        backupType: "",
        backupInstructions: "",
        autoBookEnabled: false,
        requiredCustomerAgreementText: ""
      });
      if (reinit) {
        await reinit();
      }
    }
  };
  const checkInHandler = async (event_id: string) => {
    setEnabledChekinBtn(true); // disabled checkin button till getUserCurrentPosition() response
    let { latitude, longitude }: any = await getUserCurrentPosition();
    if (latitude && longitude) {
      setCoordinates({ latitude: latitude, longitude: longitude });
      setIsOpenCheckin({ is_open_checkin: true, event_id: event_id });
    }
    setEnabledChekinBtn(false);
  };
  const closeCheckin = async (success_msg: any = "") => {
    setIsOpenCheckin({ is_open_checkin: false, event_id: "" });
    if (reinit) {
      await reinit();
    }
    if (success_msg) {
      setCheckinConfirmed({ show: true, message: success_msg.message });
    }
  };
  const takeBreackHandler = async (event_id: string) => {
    setEnabledChekinBtn(true);
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let break_api = await modelTakeBreak({
      checkInUeId: event_id,
      latitude,
      longitude,
    });
    if (break_api?.success) {
      await showToastMessage(break_api.data.message);
    } else {
      await showToastMessage(break_api.data.message);
    }
    if (reinit) {
      await reinit();
    }
    setEnabledChekinBtn(false);
  };
  const resumeWorkHandler = async (event_id: string) => {
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let resume_api = await modelResumeWork({
      checkInUeId: event_id,
      latitude,
      longitude,
    });
    if (resume_api?.success) {
      await showToastMessage(resume_api.data.message);
    } else {
      await showToastMessage(resume_api.data.message);
    }
    if (reinit) {
      await reinit();
    }
    setEnabledChekinBtn(false);
  };
  const checkhOutHandler = async (fore_redirect: string, event_id: string) => {
    setEnabledChekinBtn(true);
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let break_api = await talentEventCheckout({
      checkInUeId: event_id,
      latitude,
      longitude,
    });
    if (break_api?.success) {
      if (fore_redirect == "1") {
        history.push(`/AssignmentRecap/${event_id}`);
      }
    } else {
      await showToastMessage(break_api.data.message);
    }
    if (reinit) {
      await reinit();
    }
    setEnabledChekinBtn(false);
  };
  const takeGradeTest = async (event_id: string) => {
    let status_type = 1; // pass one for Grade test
    history.push(`/TakeTest/${event_id}/${status_type}`);
  };

  const gotoEventDetail = (link: string, event_id: any) => {
    if(event_id && openOppViewModal) {
      openOppViewModal(event_id);
    } else {
      history.push(link);
    }
  };

  /**
   * Confirm Model DBC/ DOC
   * @param event_id 
   * @param type 
   * @returns 
   */
  const submitDC = async(event_id: string, type: string) => {
    setEnabledChekinBtn(true);
    let dc_api = await saveModelDC(
      event_id,
      type
    );
    if (dc_api?.success) {
      await showToastMessage(dc_api?.message);
    } else {
      if (dc_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (dc_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(dc_api?.message);
      } else if (dc_api?.message) {
        await showToastMessage(dc_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    if (reinit) {
      await reinit();
    }
    setEnabledChekinBtn(false);
  }

  useEffect(() => {
    (async () => {
      // Used for View Opportunity in Modal - Apply - Decline
      // While on the Opportunities List, add a Badge with the confirmation selection (Applied, Declined, etc)
      if(oppConfirmationStatusList?.length) {
        const foundItem = oppConfirmationStatusList.find((item: any) => item.event_id === card_event_id);
        if(foundItem) {
          setConfirmationBadge(foundItem.status);
        }
      }
      
    })();
  }, [oppConfirmationStatusList]);

  return (
    <>
      <IonCard className="event-list__event__card">
        <div
          className="event-list__event__card__img"
          onClick={() => gotoEventDetail(card_event_route_to, card_event_id)}
        >
          <img alt="" src={card_event_image} />
          <div className="event-list__event_badges">
            {card_auto_book_enabled && (
              <IonBadge className="auto-book-placeholder">
              <img
                  src={instantBookIcon}
                /> <span>Instant Book</span>
              </IonBadge>
            )}
            {card_miles_badge && (
              <IonBadge className="event-list__event__card__mile">
                {card_miles_badge}
              </IonBadge>
            )}
            {card_waitlist_only && (
              <IonBadge className="event-list__event__card__mile">
                {card_waitlist_only}
              </IonBadge>
            )}

            {confirmationBadge && (
              <IonBadge className="event-list__event__card__confirmation-status">
                <span
                    style={{
                      backgroundColor: `${confirmationBadge == "Pending" || confirmationBadge == "Booked" ? "#fe6102" : "#FA3D28"}`,
                    }}
                  ></span>{confirmationBadge}
              </IonBadge>
            )}

            {card_event_type === "opportunities" &&
              model_level_badge.length > 0 &&
              model_level_badge.map((level: string, index: number) => (
                <IonBadge className="event-list__event__card__wait" key={index}>
                  {level}
                </IonBadge>
              ))}
          </div>
          {event_action_detail?.backupType && (
            <IonBadge className="event-list__event__card__toolbar-bottom">
              <span className="event-list__event__card__toolbar-bottom__label">
                {event_action_detail?.backupType}
              </span>
            </IonBadge>
          )}
        </div>

        <IonCardContent>
          <IonBadge className="event-list__event__card__date">
            {card_event_date}
          </IonBadge>
          {card_event_points_multiplier &&
            card_event_points_multiplier > 1 && (
              <>
              <IonText className="slider_view__points">
                {card_event_points_multiplier}X Points
                <img className="slider_view__points__img" src={pointsMultiplied} />
              </IonText>
              
            </>
            )}
          <div
            className="event-list__event__card__title"
            onClick={() => gotoEventDetail(card_event_route_to, card_event_id)}
          >
            <h3>{card_event_title}</h3>
          </div>
          {!(
            event_action_detail?.showConfirmation ||
            event_action_detail?.showDBC ||
            event_action_detail?.showDOC ||
            event_action_detail?.canModelCheckIn ||
            event_action_detail?.canModelCheckOut ||
            event_action_detail?.canresumework ||
            event_action_detail?.hasToTakeTests
          ) && (
            <>
              <IonText className="event-list__event__card__address">
                {card_event_address}
              </IonText>
              <div className="event-list__event__card__est">
                {(card_event_payment || card_event_points) && (<IonText>
                  Est. Pay: {card_event_payment}{" "}
                  {card_event_points && (
                    <span className={card_event_points_multiplier && card_event_points_multiplier > 1 ? "points-background" : ""}>
                      {card_event_points}
                    </span>
                  )}
                </IonText>)}
              </div>
            </>
          )}

          {(event_action_detail?.showConfirmation ||
            event_action_detail?.hasToTakeTests) && (
            <IonText className="event-list__event__card__address">
              Please respond ASAP to confirm this booking
            </IonText>
          )}
          {(event_action_detail?.showDOC) && (
            <div>
              <p className="slider_view__cta">
                Please confirm that you are all set for today's shift.
              </p>
            </div>
          )}

          {(event_action_detail?.showDBC) && (
            <div>
              <p className="slider_view__cta">
                Please confirm that you are 100% ready to go for tomorrow's assignment.
              </p>
            </div>
          )}
          {(event_action_detail?.canModelCheckIn ||
            event_action_detail?.canModelCheckOut ||
            event_action_detail?.canresumework) &&
            event_action_detail?.event_start_time &&
            event_action_detail?.event_end_time && (
              <div>
                <IonText className="event-list__event__card__address">
                  
                {event_action_detail?.ue_is_in_store_report ? (
                  <>Please check-in when you arrive on-site to begin your on-store report.</>
                ) :(
                  <> Shift Time:{" "}
                  {moment(
                    event_action_detail?.event_start_time,
                    "HH:mm:ss A"
                  ).format("LT")}{" "}
                  -{" "}
                  {moment(
                    event_action_detail?.event_end_time,
                    "HH:mm:ss A"
                  ).format("LT")}</>
                ) }
                 
                </IonText>
              </div>
            )}
          {event_action_detail?.showConfirmation && (
            <div className="assignment-view__action_btn">
              <IonButton
                mode="ios"
                slot="start"
                fill="outline"
                size="large"
                className="form-group__button"
                onClick={() =>
                  setIsOpenAssignDecline({
                    event_name: card_event_title,
                    event_start_date: event_action_detail?.event_start_date, 
                    is_open: true,
                    event_id: event_action_detail?.event_id,
                  })
                }
              >
                Decline
              </IonButton>
              <IonButton
                mode="ios"
                slot="end"
                color="primary"
                size="large"
                className="form-group__button"
                onClick={() =>
                  setIsOpenAssignConfirm({
                    event_name: card_event_title,
                    start_date: event_action_detail?.event_start_date,
                    start_time: event_action_detail?.event_start_time,
                    talent_role: event_action_detail?.talent_role,
                    event_id: event_action_detail?.event_id,
                    is_open: true,
                    assign_p_card_required:
                      event_action_detail?.ue_p_card_agreement_required,
                    assign_p_card_instructions:
                      event_action_detail?.ue_p_card_agreement_instructions_html,
                    backupTypeInt: event_action_detail?.backupTypeInt,
                    backupType: event_action_detail?.backupType,
                    backupInstructions: event_action_detail?.backupInstructions,
                    autoBookEnabled: event_action_detail?.autoBookEnabled,
                    requiredCustomerAgreementText: event_action_detail?.requiredCustomerAgreementText
                  })
                }
              >
                Confirm
              </IonButton>
            </div>
          )}
          {(event_action_detail?.showDBC || event_action_detail?.showDOC) && (
            <div className="assignment-view__action_btn">
              <IonButton
                mode="ios"
                slot="start"
                fill="outline"
                size="large"
                className="form-group__button"
                disabled={enabledChekinBtn}
                onClick={() =>
                  setIsOpenAssignDecline({
                    event_name: card_event_title,
                    event_start_date: event_action_detail?.event_start_date, 
                    is_open: true,
                    event_id: event_action_detail?.event_id,
                  })
                }
              >
                Decline
              </IonButton>
              <IonButton
                mode="ios"
                slot="end"
                color="primary"
                size="large"
                className="form-group__button"
                disabled={enabledChekinBtn}
                onClick={() =>
                  submitDC(event_action_detail?.event_id, event_action_detail?.showDBC ? "DBC" : "DOC")
                }
              >
                Confirm
              </IonButton>
            </div>
          )}
          {event_action_detail?.canModelCheckIn && (
            <div className="assignment-view__action_btn">
              <IonButton
                mode="ios"
                size="large"
                onClick={() => checkInHandler(event_action_detail?.event_id)}
                className="form-group__button"
                disabled={enabledChekinBtn}
              >
                Check In
              </IonButton>
              {/* <IonButton
                          mode="ios"
                          fill="outline"
                          size="large"
                          onClick={() =>
                            setIsOpenAssignDecline({
                              event_name: item.title,
                              is_open: true,
                            })
                          }
                        >
                          Cancel
                        </IonButton> */}
            </div>
          )}
          {event_action_detail?.canModelCheckOut && (
            <div className="assignment-view__action_btn">
              {event_action_detail?.cantakebreak && (
                <IonButton
                  mode="ios"
                  fill="outline"
                  size="large"
                  disabled={enabledChekinBtn}
                  className="form-group__button"
                  onClick={() =>
                    takeBreackHandler(event_action_detail?.event_id)
                  }
                >
                  Take Break
                </IonButton>
              )}
              {event_action_detail?.canresumework && (
                <IonButton
                  mode="ios"
                  fill="outline"
                  size="large"
                  disabled={enabledChekinBtn}
                  className="form-group__button"
                  onClick={() =>
                    resumeWorkHandler(event_action_detail?.event_id)
                  }
                >
                  Return to Work
                </IonButton>
              )}

              <IonButton
                mode="ios"
                color="primary"
                size="large"
                className="form-group__button"
                onClick={() =>
                  checkhOutHandler(
                    event_action_detail?.force_recap_on_checkout,
                    event_action_detail?.event_id
                  )
                }
                disabled={enabledChekinBtn}
              >
                Check Out
              </IonButton>
            </div>
          )}
          {event_action_detail?.hasToTakeTests && (
            <div className="assignment-view__take_test">
              <IonButton
                expand="block"
                mode="ios"
                type="button"
                className="form-group__button"
                onClick={() => takeGradeTest(event_action_detail?.event_id)}
              >
                Take Test
              </IonButton>
            </div>
          )}
        </IonCardContent>
      </IonCard>
      <AssignmentDecline
        assign_event_name={isOpenAssignDecline.event_name}
        assign_event_start_date={isOpenAssignDecline.event_start_date}
        isopen_assign_decline={isOpenAssignDecline.is_open}
        assign_event_id={isOpenAssignDecline.event_id}
        closeAssignDecline={closeAssignDecline}
      />
      {isOpenAssignConfirm.is_open && (
        <AssignmentConfirm
          isopen_assign_confirm={isOpenAssignConfirm.is_open}
          closeAssignConfirm={closeAssignConfirm}
          assign_event_name={isOpenAssignConfirm.event_name}
          assign_date={isOpenAssignConfirm.start_date}
          assign_time={isOpenAssignConfirm.start_time}
          assign_talent_role={isOpenAssignConfirm.talent_role}
          assign_id={Number(isOpenAssignConfirm.event_id)}
          assign_p_card_required={isOpenAssignConfirm.assign_p_card_required}
          assign_p_card_instructions={
            isOpenAssignConfirm.assign_p_card_instructions
          }
          requiredCustomerAgreementText={isOpenAssignConfirm.requiredCustomerAgreementText}
          backup_type={isOpenAssignConfirm?.backupTypeInt}
          backup_type_str={isOpenAssignConfirm?.backupType}
          backup_instructions={isOpenAssignConfirm?.backupInstructions}
          auto_book_enabled={isOpenAssignConfirm?.autoBookEnabled}
        />
      )}

      {isOpenCheckin?.is_open_checkin && (
        <AssignmentCheckin
          isopen_checkin={isOpenCheckin.is_open_checkin}
          closeCheckin={closeCheckin}
          checkin_event_id={isOpenCheckin.event_id}
          latitude={coordinates.latitude}
          longitude={coordinates.longitude}
        />
      )}
      <IonAlert
        isOpen={checkinConfirmed.show}
        mode="ios"
        onDidDismiss={() =>
          setCheckinConfirmed({ ...checkinConfirmed, show: false })
        }
        header={"Check In is successful"}
        message={checkinConfirmed.message}
        buttons={[
          {
            text: "CONTINUE",
            handler: () => {},
          },
        ]}
      />
    </>
  );
};

export default EventCardList;

import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonButton,
  IonCardTitle,
} from "@ionic/react";
import "./MyRewards.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import {
  modelClaimedRewardsList,
  modelRewardsList,
} from "../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
} from "../../common/common";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import Loading from "../../push-components/loading/Loading";

const RewardHistory: React.FC = () => {
  const [claimedRewardList, setClaimdRewardList] = useState<any>([]);
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);
  const rhInit = async () => {
    setWaitingForApiResonse(true);
    let api_res = await modelClaimedRewardsList();
    if (api_res?.success) {
      if (api_res?.data.rewards) {
        setClaimdRewardList(api_res?.data.rewards);
      }
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setWaitingForApiResonse(false);
  };

  useEffect(() => {
    (async () => {
      await rhInit();
    })();
  }, []);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Reward History"
        bgcolor="primary"
        buttonType="undefine"
      ></SimpleHeaderView>
      <IonContent>
        {claimedRewardList.length > 0 && (
          <div className="my_reward_list">
            {claimedRewardList.map((item: any, index: any) => (
              <IonCard
                className="my_reward_list__card claimed_reward_list"
                key={index}
              >
                <div className="my_reward_list__card_badge">
                  {`${Math.round(Number(item.model_reward_points))} POINTS`}
                </div>
                <div className="my_reward_list__card_img-container">
                  <img
                    src={item.image}
                    className="my_reward_list__card_img"
                    alt=""
                  />
                </div>
                <IonCardContent className="my_reward_list__card_content">
                  <div>
                    <IonCardTitle className="my_reward_list__card_lesser_title">
                      {item.model_reward_name}
                    </IonCardTitle>
                    <p>{item.model_reward_subtitle}</p>
                  </div>
                  <div className="my_reward_list__card_content__attributes">
                    {item.attributes.map((attribute: any, index: any) => (
                      <div>
                        {attribute.mra_attribute_name == "Color" && (
                          <div className="my_reward_list__card_content__attributes__color">
                            <span>{attribute.mra_attribute_name}:</span>
                            <div
                              className="my_reward_list__card_content__attributes__color__item"
                              key={index}
                            >
                              {attribute.mra_value_name === "#ffffff" ||
                              attribute.mra_value_name.toLowerCase() ===
                                "white" ? (
                                <div
                                  style={{
                                    backgroundColor: attribute.mra_value_name,
                                    border: "1px solid black",
                                  }}
                                  className="my_reward_list__card_content__attributes__color__item__type"
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: attribute.mra_value_name,
                                  }}
                                  className="my_reward_list__card_content__attributes__color__item__type"
                                ></div>
                              )}
                            </div>
                          </div>
                        )}
                        {attribute.mra_attribute_name != "Color" && (
                          <div className="my_reward_list__card_content__attributes__value">
                            <span>{attribute.mra_attribute_name}:</span>
                            <div
                              className="my_reward_list__card_content__attributes__value__item"
                              key={index}
                            >
                              <div
                                style={{
                                  border: "1px solid black",
                                }}
                                className="my_reward_list__card_content__attributes__value__item__type"
                              >
                                {attribute.mra_value_name}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {item.model_reward_msrp != 0 && (
                    <p className="my_reward_list__card_content__msrp">
                      MSRP: {item.model_reward_msrp}
                    </p>
                  )}
                  <p className="my_reward_list__card_text">
                    {`Redeemed : ${item.model_reward_date}`}
                  </p>
                </IonCardContent>
              </IonCard>
            ))}
          </div>
        )}
        {(!waitingForApiResonse && claimedRewardList.length == 0) && (
          <EmptyScreen title="No Rewards" description="" />
        )}
      </IonContent>
      <Loading showLoading={waitingForApiResonse} />
    </IonPage>
  );
};

export default RewardHistory;

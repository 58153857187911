import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonText, IonIcon, IonCard } from "@ionic/react";
import { useHistory, useParams, useLocation } from "react-router";
import "./FAQsProfileManagement.css";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import ContactUsPopover from "../../../push-components/ContactUsPopover/ContactUsPopover";
import { getFAQPages } from "../../../data-services/faq";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { unauthorizedErrorCode, offlineErrorCode, showToastMessage, somethingWentWrongErrMsg } from "../../../common/common";
import down_arrow from "../../../assets/images/common/down_arrow_white.png";
import plus_icon from "../../../assets/images/common/plug-icon.svg";

const FAQsProfileManagement: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const url_params: any = useParams();
  const navparameter = url_params.id;

  const [questionsData, setQuestionsData] = useState<any>([]);
  const [showContactUsPopover, setShowContactUsPopover] = useState<any>("");
  const [contactUsPopoverData, setContactUsPopoverData] = useState<any>({});
  const [title, setTitle] = useState<any>("");
  const [isContentVisible, setIsContentVisible] = useState<any>({});

  const navigateTo = (page: any, faq: any, faq_from: any) => {
    // Able to preserve the navigated position
    sessionStorage.setItem(`lastFaqId`, faq_from);
    history.push(`/FAQsProfileManagement/${page}#${faq}`);
  };

  const getQuestions = async () => {
    const response = await getFAQPages();
    if (response?.success && response.data.order.indexOf(navparameter) !== -1) {
      let objects = [];
      setTitle(response.data.categories[response.data.order.indexOf(navparameter)]);
      objects = response.data.faqs_arr.filter((obj: any) => obj.page === navparameter);
      setQuestionsData(objects);

      const isContectVisibleLocal: { [key: string]: boolean } = {};
      for (const object of objects) {
        isContectVisibleLocal[object.faq_id] = false;
      }

      const anchor = window.location.hash.slice(1);
      if (anchor) {
        const id_element = document.querySelector(`#${anchor}`);
        if (id_element) {
          isContectVisibleLocal[anchor] = true;
          id_element.scrollIntoView();
        }
      } else {
        const lastFaqId = sessionStorage.getItem("lastFaqId");
        // If a different id was last saved, scroll to the FAQ element with the matching id
        if (lastFaqId) {
          const faqElem = document.querySelector(`#${lastFaqId}`);
          if (faqElem) {
            isContectVisibleLocal[lastFaqId] = true;
            faqElem.scrollIntoView();
          }
        }
      }
     
      setIsContentVisible(isContectVisibleLocal);
    } else {
      if (response?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (response?.data?.errorCode == offlineErrorCode) {
        showToastMessage(response?.data?.message);
      } else if (response?.message) {
        showToastMessage(response.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };

  const openContactFAQForm = async (faq_id: any, faq_contact_mail: any, faq_question: any) => {
    setContactUsPopoverData({
      faq_id: faq_id,
      faq_contact_mail: faq_contact_mail,
      faq_question: faq_question
    });

    setShowContactUsPopover("yes");
  }

  const toggleContent = (faq_id: any) => {
    isContentVisible[faq_id] = !isContentVisible[faq_id];
    setIsContentVisible({...isContentVisible});
  };

  useEffect(() => {
    getQuestions();
  }, [location]);

  return (
    <IonPage>
      <SimpleHeaderView
        title={title}
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent className="faqs-profilemanagement-container">
        {questionsData.map((item: any, index: any) => (
          <div
            key={item.faq_id}
            id={item.faq_id}
            className="faqs-profilemanagement-container__item"
          >
            <p className="faqs-profilemanagement-container__title" onClick={() => toggleContent(item.faq_id)}>
            <IonCard className= {isContentVisible[item.faq_id] ? "faqs-profilemanagement-container_btn_active faqs-profilemanagement-container_btn" : "faqs-profilemanagement-container_btn"}>
              <div className="faqs-profilemanagement-container__title__accordion">
                    <IonText className="faqs-profilemanagement-container__btn_text extra_margin_top">
                      {item.key}{" "}
                    </IonText>
                      {/* <IonIcon
                        className="faqs-profilemanagement-container__icon_right"
                        src={check_icon}
                      /> */}
                      {(isContentVisible[item.faq_id]) && (
                        <img
                          className="faqs-profilemanagement-container__icon_right"
                          src={down_arrow}
                          alt=""
                        />
                      )}
                      {(!isContentVisible[item.faq_id]) && (
                          <IonIcon
                          className="faqs-profilemanagement-container__icon_right"
                          src={plus_icon}
                        />
                      )}
                  </div>
                  </IonCard>
            </p>
            {isContentVisible[item.faq_id] && (
            <>
            <p
              className="faqs-profilemanagement-container__content"
              dangerouslySetInnerHTML={{ __html: item.value }}
            ></p>

            {item.faq_links.map((faq_link: any, index2: any) => (
              <>
                <br />
                <p className="faqs-profilemanagement-container__content">
                  To find more about {faq_link.fl_link_to_faq_description},{" "}
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      navigateTo(
                        "p" + faq_link.faq_fp_id,
                        "q" + faq_link.fl_link_to_faq_id,
                        item.faq_id
                      )
                    }
                  >
                    click here
                  </a>
                </p>
              </>
            ))}
            <br />
            <p className="faqs-profilemanagement-container__content">
              If you still have questions,{" "}
              <a href="javascript:void(0)" onClick={() => openContactFAQForm(item.faq_id, item.faq_contact_mail, item.key)}>
                contact us
              </a>
            </p>
            {item.faq_id && <img src={item.faq_image}></img>}
            <br/>
            </>
            )}
          </div>
        ))}
      </IonContent>
      {showContactUsPopover === "yes" && (
        <ContactUsPopover
          data={contactUsPopoverData}
          updatePopoverState={setShowContactUsPopover}
        ></ContactUsPopover>
      )}
    </IonPage>
  );
};

export default FAQsProfileManagement;

/** */
import { IonButton, IonCard, IonCardContent, IonCardTitle } from "@ionic/react";
import "./ItemRedeemCard.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { setRewardFavoriteStatus } from "../../data-services/scoreboard";
import orange_heart from "../../assets/images/common/orange_heart2.svg";
import outlined_heart from "../../assets/images/common/outlined_heart.svg";
import sold_out_icon from "../../assets/images/rewards/sold_out.png";

const ItemRedeemCard: React.FC<any> = (props) => {
  const [checkedAttributesValues, setCheckedAttributesValues] = useState<any>(
    {}
  );
  const [favoriteRewardsStatus, setFavoriteRewardsStatus] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  const mrInit = async () => {
    // Have Reward data
    // Have Attribute Values checks data
    // Initialize object of rewards / attributes / values with all values as false (nothing is checked)
    for (const reward of props.items) {
      checkedAttributesValues["reward" + reward.model_reward_id] = {};
      favoriteRewardsStatus["reward" + reward.model_reward_id] =
        reward.favorite_reward;
      if (reward?.attributes?.length) {
        for (const attribute of reward.attributes) {
          checkedAttributesValues["reward" + reward.model_reward_id][
            "attribute" + attribute.attribute.ra_id
          ] = {};
          if (attribute?.values?.length) {
            for (const value of attribute.values) {
              checkedAttributesValues["reward" + reward.model_reward_id][
                "attribute" + attribute.attribute.ra_id
              ]["value" + value.marav_id] = false;
            }
          }
        }
      }
    }
    setCheckedAttributesValues(checkedAttributesValues);
    setFavoriteRewardsStatus(favoriteRewardsStatus);
    setLoading(false);
  };

  const favoriteRewardHandler = async (rewardId: any) => {
    // Create a copy of the current favoriteRewardsStatus object so the state hook triggers when setting favoriteRewardsStatus
    let newArray = Object.assign({}, favoriteRewardsStatus);

    // Iterate through the rewardsArray and set the proper favorite status ONLY for the given reward
    for (const reward of props.items) {
      if (reward.model_reward_id == rewardId) {
        newArray["reward" + reward.model_reward_id] =
          favoriteRewardsStatus["reward" + reward.model_reward_id] == 1 ? 0 : 1;
        setRewardFavoriteStatus(
          rewardId,
          newArray["reward" + reward.model_reward_id]
        );
      }
    }
    setFavoriteRewardsStatus(newArray);
  };

  useEffect(() => {
    (async () => {
      await mrInit();
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      {props.items.length > 0 && (
        <Splide
          options={{
            fixedWidth: "264px",
            arrows: false,
            pagination: false,
            perPage: 1,
            gap: "16px",
            perMove: 1,
          }}
        >
          {props.items.map((item: any, index: any) => (
            <SplideSlide key={index}>
              <IonCard className={item?.mar_amount == 0 ? "redeem_point__card--sold-out redeem_point__card" : "redeem_point__card"}>
                <div className={item?.mar_amount == 0 ? "redeem_point__card_img-container--sold-out redeem_point__card_img-container" : "redeem_point__card_img-container"}>
                  <div className="redeem_point__card_badge">
                    {`${Math.round(Number(item.model_reward_points))} POINT` + ( Number(item.model_reward_points) != 1 ? "S" : "")}
                  </div>
                  <div className="my_reward_list__card_favorite">
                    <img
                      src={
                        favoriteRewardsStatus[
                          "reward" + item.model_reward_id
                        ] == 1
                          ? orange_heart
                          : outlined_heart
                      }
                      onClick={() =>
                        favoriteRewardHandler(item.model_reward_id)
                      }
                    ></img>
                  </div>
                  <img
                    src={item.image}
                    className="redeem_point__card_img"
                    alt=""
                    onClick={() =>
                      props.redeemRewardHandler(
                        item.model_reward_id,
                        checkedAttributesValues,
                        true
                      )
                    }
                  />
                  {item?.mar_amount == 0 && (<img
                      src={sold_out_icon}
                      className="redeem_point__card_img__sold-out"
                      alt=""
                    />)}
                </div>

                <IonCardContent>
                  <IonCardTitle className="redeem_point__card_title">
                    {item.model_reward_name}
                  </IonCardTitle>
                  <p className="redeem_point__card_text">
                    {" "}
                    {item?.track_percentage != 100
                      ? `Track this goal: ${item.track_percentage}%`
                      : ""}
                  </p>
                  {(item?.track_percentage == 100 && item?.mar_amount > 0 ) && (
                    <span
                      onClick={() =>
                        props.redeemRewardHandler(
                          item.model_reward_id,
                          checkedAttributesValues
                        )
                      }
                      className="redeem_point__card_btn"
                    >
                      REDEEM
                    </span>
                  )}
                  {(item?.track_percentage == 100 && item?.mar_amount == 0 ) && (
                    <span
                      
                      className="redeem_point__card_btn redeem_point__card_btn--disabled"
                    >
                      REDEEM
                    </span>
                  )}
                </IonCardContent>
              </IonCard>
            </SplideSlide>
          ))}
        </Splide>
      )}
    </div>
  );
};

export default ItemRedeemCard;

/** */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IonIcon,
  IonToolbar,
  IonRow,
  IonCol,
  IonLabel,
  IonHeader,
  IonText,
  IonGrid,
  IonSpinner,
} from "@ionic/react";
import "./HeaderView.css";
import location_icon from "../../assets/images/common/location_icon.svg";
import dropdown_icon from "../../assets/images/common/dropdown_icon.png";

import "@splidejs/splide/dist/css/splide.min.css";

import { notifications, chatbubbleEllipses } from "ionicons/icons";
import { get, set } from "../../storage/storage";
import { notificationCountHandler, showToastMessage } from "../../common/common";
import { getAccountNotifications } from "../../data-services/home-api";
import AddLocation from "../../push-components/AddLocation/AddLocation";
import { useDispatch } from "react-redux";
import { BadgeService } from "../../components/Badge/BadgeService";

let app_noti_count: any = 0;
type HeaderProps = {
  refreshOppotunities?: Function;
};
const HeaderView: React.FC<HeaderProps> = (props) => {
  const dispatch = useDispatch();
  const { refreshOppotunities } = props;
  const history = useHistory();
  const [showAddLocationModal, setShowAddLocationModal] =
    useState<boolean>(false);
  const [modelSelectedLocation, setModelSelectedLocation] =
    useState<string>("");

  const goToAppNotification = () => {
    history.push("/AppNotification");
  };
  const getTalentAccountNotifications = async () => {
    // let account_notification = await dispatch(await request(await getAccountNotifications())).payload;
    let account_notification = await getAccountNotifications();
    if (account_notification?.success) {
      let noti_object = account_notification.data.notifications;
      let noti_count = await notificationCountHandler(noti_object);
      BadgeService.badgeSet(noti_count);
      return noti_count;
    } else {
      showToastMessage(account_notification.message);
      return 0;
    }
  };
  const closeAddLocationModal = async (zipcode: string = "") => {
    setModelSelectedLocation(await get("you_are_in"));
    setShowAddLocationModal(false);
    if (refreshOppotunities) {
      refreshOppotunities();
    }
  };

  useEffect(() => {
    (async () => {
      app_noti_count  = await getTalentAccountNotifications();
      //= await getTalentAccountNotifications();
      setModelSelectedLocation(await get("you_are_in"));
    })();
  }, [modelSelectedLocation]);
  const goToChatList = () => {
    history.push("/ChatList");
  };

  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonGrid>
          <IonRow>
            <IonCol size="8">
              <div className="your_location">
                <IonGrid>
                  <IonRow>
                    <IonCol size="1">
                      <IonIcon
                        src={location_icon}
                        className="your_location__icon"
                      />
                    </IonCol>
                    <IonCol size="10">
                      <div
                        className="your_location__text"
                        onClick={() => setShowAddLocationModal(true)}
                      >
                        <IonLabel className="your_location_text__placeholder">
                          You are in
                        </IonLabel>
                        <IonText className="your_location_text__location_name">
                        {!modelSelectedLocation ? (
                            <IonSpinner name="dots"></IonSpinner>
                          ) : (
                            <>{modelSelectedLocation}</>
                          )}
                          
                        </IonText>
                      </div>
                    </IonCol>
                    <IonCol size="1">
                      <img
                        src={dropdown_icon}
                        alt=""
                        className="your_location__icon2"
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="notifcation_bell" onClick={goToAppNotification}>
                {app_noti_count != 0 && (
                  <span className="notifcation_bell__noti_badge"></span>
                )}

                <IonIcon size="large" icon={notifications} />
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="chat_notification" onClick={goToChatList}>
                {false && (
                  <span className="chat_notification__noti_badge"></span>
                )}
                <IonIcon size="large" icon={chatbubbleEllipses} />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>

      <AddLocation
        closeLocationModal={closeAddLocationModal}
        isLcationModalOpen={showAddLocationModal}
      />
    </IonHeader>
  );
};

export default HeaderView;

import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import { noInternetResponse, unauthorizedResponse } from "../common/common";
import { Network } from "@capacitor/network";

export const getFAQPages = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let url = `${process.env.REACT_APP_HP_API_URL}/userEvent/getModelFaqPages`;

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    const response = await CapacitorHttp.get(options);

    return response.data;
  } else {
    return noInternetResponse;
  }
};

export const sendFAQContactUsNotification = async (
  faq_id: any,
  message: any
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let url = `${process.env.REACT_APP_HP_API_URL}/userEvent/sendFAQContactUsNotification`;

    const options = {
      url: url,
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        faq_id: faq_id,
        message: message,
      },
    };
    const response = await CapacitorHttp.post(options);

    return response.data;
  } else {
    return noInternetResponse;
  }
};

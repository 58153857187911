import React from "react";
import { IonButton, IonButtons, IonHeader, IonTitle, IonToolbar,isPlatform } from '@ionic/react'
import arrow_back from "../../assets/images/signup/img/arrow-left.png";
import './FormHeader.css'
interface props_type  {
    backStepHandle:Function
    title:string
}

const FormHeader:React.FC<props_type> = (props) =>{
    const {backStepHandle ,title} = props;
    return (
        <IonHeader>
          <IonToolbar mode="ios">
            <IonButtons
              slot="start"
              onClick={() => backStepHandle()}
              className="form-header__back-btn">
              <IonButton><img alt="" src={arrow_back} /></IonButton>
            </IonButtons>
            {title && 
             <IonTitle>
             {title}
           </IonTitle>
            }
           
          </IonToolbar>
        </IonHeader>
    )
}

export default FormHeader
import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonText,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import back_arrow from "../../assets/images/common/arrow-left.png";
import "./DirectDepositForm.css";
import { getStatesForSignUp } from "../../data-services/signup";
import { get } from "../../storage/storage";
import { saveModelDeposit } from "../../data-services/home-api";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  offlineErrorCode,
  unauthorizedErrMsg
} from "../../common/common";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import { logError } from "../../data-services/assignmets";

type ModalProps = {
  is_open_deposit_form: boolean;
  closeDepositForm: Function;
};

const DirectDepositForm: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { is_open_deposit_form, closeDepositForm } = props;
  const [iAcceptTerm, setIAcceptTerm] = useState<boolean>(false);
  const [stateList, setStateList] = useState<any>([]);
  const [steps, setSteps] = useState<number>(1);
  const [accountType, setAccountType] = useState<string>("");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bank_name: "",
      talent_country: "",
      talent_state: "",
      talent_city: "",
      transit_no: "",
      account_no: "",
      institution_no: "",
      employee_name: "",
      account_type: "",
      sin_no: "",
      i_accetp: false,
    },
    mode: "onBlur",
  });
  const depositFormSubmitHandler = async () => {
    if (steps == 1) {
      nextStepHandler();
      return;
    }
    if (!iAcceptTerm) {
      setError("i_accetp", {
        type: "manual",
        message: "This field is required.",
      });
      return;
    }
    let form_data = {
      accountBankName: getValues("bank_name"),
      accountCountry: getValues("talent_country"),
      accountState: getValues("talent_state"),
      accountCity: getValues("talent_city"),
      accountRoutingTransit: getValues("transit_no"),
      accountNumber: getValues("account_no"),
      accountFinancialInstitutionNumber: getValues("institution_no"),
      accountType: getValues("account_type"),
      accountEmployeeName: getValues("employee_name"),
      accountSS: getValues("sin_no"),
    };
    setDisabledSubmitBtn(true);
    try {
      let save_api: any = await saveModelDeposit(form_data);
      if (save_api?.success) {
        await showToastMessage(
          "Your Full Service Direct Deposit registration is now complete!"
        );
        closeDepositForm();
      } else {
        if (save_api?.data?.errorCode == offlineErrorCode) {
          await showToastMessage(save_api?.data?.message);
        } else if (save_api?.message) {
          showToastMessage(save_api.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }
      setDisabledSubmitBtn(false);
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Direct Deposit Form - depositFormSubmitHandler - Exception thrown",
          "Talent App - Direct Deposit Form",
          JSON.stringify(e.message)
        );
        
      }
      setDisabledSubmitBtn(false);
    }
  };
  const getStateListBaseOnCountry = async (country: string) => {
    setStateList(await getStatesForSignUp(country));
  };
  const nextStepHandler = async () => {
    //  check if there any validations error
    if (Object.keys(errors).length === 0) {
      let next_step = steps + 1;
      setSteps(next_step);
    }
  };
  const backStepHandler = () => {
    if (steps == 2) {
      setSteps(1);
    } else {
      closeDepositForm();
    }
  };
  const formatsinNumber = (event: any) => {
    let ss_n = event.target.value;
    if (/^[0-9\ \)\(\-\/]+$/.test(ss_n)) {
      // check is number

      let cleaned = ("" + ss_n).replace(/\D/g, "");
      let formatted_no: any = "";
      let match: any = [];
      if (cleaned.length === 3) {
        match = cleaned.match(/^(\d{3})$/);
        formatted_no = `${match[1]}-`;
      } else if (cleaned.length === 5) {
        match = cleaned.match(/^(\d{3})(\d{2})$/);
        formatted_no = `${match[1]}-${match[2]}-`;
      } else if (cleaned.length === 9) {
        match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        formatted_no = `${match[1]}-${match[2]}-${match[3]}`;
      }
      if (formatted_no) {
        setValue("sin_no", formatted_no);
        return;
      }
    }
    // return null
  };
  useEffect((): any => {
    let isSubscribed = true;
    (async () => {
      getStateListBaseOnCountry("Canada");
      setValue("talent_country", "Canada");
      setValue("talent_state", await get("login_model_state"));
      setValue("talent_city", await get("login_model_city"));
    })();
    return () => (isSubscribed = false);
  }, []);

  return (
    <IonModal isOpen={is_open_deposit_form} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start" onClick={backStepHandler}>
            <IonButton>
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <section className="deposit_form">
          <form onSubmit={handleSubmit(depositFormSubmitHandler)}>
            {steps === 1 && (
              <div className="deposit_form__step_1">
                <div className="form-group">
                  <IonLabel className="form-group__label">Bank Name</IonLabel>
                  <input
                    type="text"
                    placeholder="Bank Name"
                    className={
                      errors.bank_name !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                 
                    {...register("bank_name", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="bank_name"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Country</IonLabel>
                  <IonSelect
                    onIonChange={(e: any) =>
                      getStateListBaseOnCountry(e.detail.value)
                    }
                    placeholder="--Select--"
                    className={
                      errors.talent_country !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("talent_country", {
                      required: "This field is required.",
                    })}
                  >
                    <IonSelectOption key="usa" value="USA">
                      USA
                    </IonSelectOption>
                    <IonSelectOption key="canada" value="Canada">
                      Canada
                    </IonSelectOption>
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="talent_country"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">State</IonLabel>
                  <IonSelect
                    placeholder="--Select--"
                    className={
                      errors.talent_state !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                    {...register("talent_state", {
                      required: "This field is required.",
                    })}
                  >
                    {Object.keys(stateList).map((state_key, state_index) => (
                      <IonSelectOption key={state_key} value={state_key}>
                        {stateList[state_key]}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <ErrorMessage
                    errors={errors}
                    name="talent_state"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">City</IonLabel>
                  <input
                    type="text"
                    placeholder="Eg. Los Angeles"
                    className={
                      errors.talent_city !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
             
                    {...register("talent_city", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="talent_city"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Transit No</IonLabel>
                  <input
                    type="text"
                    placeholder="Transit No"
                    className={
                      errors.transit_no !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
               
                    {...register("transit_no", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="transit_no"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">Account No</IonLabel>
                  <input
                    type="text"
                    placeholder="Account No"
                    className={
                      errors.account_no !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
          
                    {...register("account_no", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="account_no"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Financial Institution No
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="Financial Institution No"
                    className={
                      errors.institution_no !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
               
                    {...register("institution_no", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="institution_no"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Account Type
                  </IonLabel>
                  <IonRadioGroup
                    onIonChange={(e: any) => {
                      setAccountType(e.detail.value);
                      setValue("account_type", e.detail.value);
                    }}
                    value={accountType}
                    {...register("account_type", {
                      required: "This field is required.",
                    })}
                  >
                    <IonItem className="deposit_form__account_type">
                      <IonRadio value="Checking" mode="md" slot="start" />{" "}
                      Checking
                    </IonItem>
                    <IonItem className="deposit_form__account_type">
                      <IonRadio value="Savings" mode="md" slot="start" />{" "}
                      Savings weeks
                    </IonItem>
                    <IonItem className="deposit_form__account_type">
                      <IonRadio value="Other" mode="md" slot="start" /> Other
                    </IonItem>
                  </IonRadioGroup>
                  <ErrorMessage
                    errors={errors}
                    name="account_type"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="deposit_form__submit--btn">
                  <IonButton
                    expand="block"
                    mode="ios"
                    type="submit"
                    className="form-group__button"
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            )}
            {steps === 2 && (
              <div className="deposit_form__step_2">
                <div className="deposite_from__imp_note">
                  <h2>
                    Important! Please read and sign before completing and
                    submitting.
                  </h2>
                  <IonText>
                    I hereby authorize PUSH MODELS, either directly or through
                    its payroll service provider, to deposit any amounts owed
                    me, by initiating credit entries to my account at the
                    financial institution (hereinafter "Bank") indicated on this
                    form. Further, I authorize Bank to accept and to credit any
                    credit entries indicated PUSH MODELS, either directly or
                    through its payroll service provider, to my account. In the
                    event that PUSH MODELS deposits funds erroneously into my
                    account, I authorize PUSH MODELS, either directly or through
                    its payroll service provider, to debit my account for an
                    amount not to exceed the original amount of the erroneous
                    credit. Lastly, I understand and accept that there will be
                    $2.00 service charge deducted from each payment. This
                    authorization is to remain in full force and effect until
                    PUSH MODELS and Bank have received written notice from me of
                    its termination in such time and in such manner as to afford
                    Employer and Bank reasonable opportunity to act on it.
                  </IonText>
                </div>
                <div className="w2-form-sec__certifcate--4">
                  <div>
                    <IonCheckbox
                      className="form-group__checkbox-styles"
                      mode="md"
                      {...register("i_accetp", {
                        required: "This field is required.",
                      })}
                      checked={iAcceptTerm}
                      onIonChange={(e) => setIAcceptTerm(e.detail.checked)}
                    ></IonCheckbox>
                  </div>
                  <IonLabel className="form-group__label">I Accept</IonLabel>

                  <ErrorMessage
                    errors={errors}
                    name="i_accetp"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">
                    Employee Name
                  </IonLabel>
                  <input
                    type="text"
                    placeholder="Employee Name"
                    className={
                      errors.employee_name !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
               
                    {...register("employee_name", {
                      required: "This field is required.",
                    })}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="employee_name"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="form-group">
                  <IonLabel className="form-group__label">SIN</IonLabel>
                  <input
                    type="tel"
                    placeholder="000-00-0000"
                    className={
                      errors.sin_no !== undefined
                        ? "form-input-styles-error"
                        : "form-group__input-styles"
                    }
                
                    {...register("sin_no", {
                      required: "This field is required.",
                    })}
                    onInput={formatsinNumber}
                    maxLength={11}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name="sin_no"
                    as={<div className="error-message" />}
                  />
                </div>
                <div className="deposit_form__clear_both"></div>
                <div className="deposit_form__submit--btn">
                  <IonButton
                    expand="block"
                    mode="ios"
                    type="submit"
                    className="form-group__button"
                    disabled={disabledSubmitBtn}
                  >
                    Next
                  </IonButton>
                </div>
              </div>
            )}
          </form>
        </section>
      </IonContent>
    </IonModal>
  );
};
export default DirectDepositForm;

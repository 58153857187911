import { Badge } from '@capawesome/capacitor-badge';

export const BadgeService = {
    badgeSet: async (count: number) => {
      if (!process.env?.REACT_APP_IS_NATIVE) {
        return;
      }
      const isSupported = await Badge.isSupported();
      if (!isSupported) {
        return;
      }
      const hasPermissions = await Badge.checkPermissions();
      if(!hasPermissions) {
        return;
      }
  
      await Badge.set({ count });
    },
    
    badgeGet: async () => {
      if (!process.env?.REACT_APP_IS_NATIVE) {
        return;
      }
      const isSupported = await Badge.isSupported();
      if (!isSupported) {
        return;
      }
      const hasPermissions = await Badge.checkPermissions();
      if(!hasPermissions) {
        return;
      }
      const result = await Badge.get();
      return result.count;
    }
}
import React, { useState } from "react";
import { IonItem, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import "./InputTypes.css";
type componentProps = {
  question: string;
  question_id: number;
  options: string;
  questionno?: number;
  requiredmessage?: boolean;
  othermessage?: boolean;
  answer?: any;
  allowHtmlQuestion?: any;
};
const InputTypePercentage: React.FC<componentProps> = (Props) => {
  const {
    question,
    answer,
    question_id,
    options,
    questionno,
    requiredmessage,
    othermessage,
    allowHtmlQuestion
  } = Props;
  const [answerValue, setAnswerValue] = useState<any>(answer);
  const [optionsList, setOptionList] = useState<Array<any>>([
    { key: "0%", value: "0" },
    { key: "10%", value: "10" },
    { key: "20%", value: "20" },
    { key: "30%", value: "30" },
    { key: "40%", value: "40" },
    { key: "50%", value: "50" },
    { key: "60%", value: "60" },
    { key: "70%", value: "70" },
    { key: "80%", value: "80" },
    { key: "90%", value: "90" },
    { key: "100%", value: "100" },
  ]);

  const changevalue = (val: any, data: any) => {
    answerValue[data] = val;
    setAnswerValue(answerValue);
  };

  return (
    <div className="take-test-question">
      <div className="take-test-question__label">
          {questionno && <>{questionno}. </>}
          {allowHtmlQuestion ? (
            <span
              dangerouslySetInnerHTML={{
                __html: question,
              }}
            ></span>
          ) : (
            <>{question}</>
          )}
        </div>
      <div className="input-type-percentage__optionlist">
        {options &&
          options.split("||").map((data: any, index: number) => (
            <div className="input-type-percentage__optionlist-child">
              <IonText>{data}</IonText>
              <IonSelect
                value={answerValue[data]}
                name={"answer_" + question_id.toString() + "_" + data}
                placeholder="--Select--"
                className="form-group__input-styles"
                onIonChange={(e: any) => changevalue(e.detail.value, data)}
              >
                {optionsList &&
                  optionsList.map((data: any, index: number) => (
                    <IonSelectOption key={index} value={data.value}>
                      {data.key}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </div>
          ))}
      </div>
      {requiredmessage == true && (
        <div className="error-message">This field is required.</div>
      )}
      {othermessage == true && (
        <div className="error-message">
          The Total of the dropdown for the question must be 100%.
        </div>
      )}
    </div>
  );
};

export default InputTypePercentage;

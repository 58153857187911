import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonRow,
  IonFooter,
  IonAlert,
  IonModal,
  IonToolbar,
  IonLabel,
  IonTextarea,
} from "@ionic/react";

import "./AssignmentRecapRating.css";
import { star, starOutline } from "ionicons/icons";
import { useHistory, useParams } from "react-router";
import back_arrow_white from "../../../assets/images/common/arrow-left-white.png";
import bbb_icon from "../../../assets/images/common/bbb_icon.svg";
import glassdoor_icon from "../../../assets/images/common/glassdoor_icon.png";
import push_pop_socket from "../../../assets/images/recap/push_pop_socket.jpg";
import yelp_icon from "../../../assets/images/common/yelp_icon.png";
import facebook_icon from "../../../assets/images/common/facebook_icon.png";
import close_x_circle from "../../../assets/images/common/close_x_circle.png";
import {
  getAssignmentRecapFeedback,
  saveRecapAssignmentRating,
  socialMediaPlatformsForRating,
} from "../../../data-services/assignmets";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  offlineErrorCode,
  unauthorizedErrorCode,
} from "../../../common/common";
import { authActions } from "../../../store/auth";

type ModalProps = {
  show_rating_modal: boolean;
  closeRatingModal: Function;
  rating_ue_id: string;
};

const AssignmentRecapRating: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { show_rating_modal, closeRatingModal, rating_ue_id } = props;
  const [bookingAgentRating, setbookingAgentRating] = useState<any>(0);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [pushAgentRating, setPushAgentRating] = useState<any>(2);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [showSocialPlatforms, setShowSocialPlatforms] =
    useState<boolean>(false);
  const [platformData, setPlatformData] = useState<any>([]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);

  const handleMessageChange = (event: any) => {
    setFeedbackText(event.target.value);
  };

  const ratings: any = [1, 2, 3, 4, 5];
  const renderElement = (ratingType: any, index: any) => {
    let rating = 0;
    if (ratingType == 1) {
      rating = bookingAgentRating;
    } else {
      rating = pushAgentRating;
    }

    if (index <= rating) {
      return (
        <IonIcon
          onClick={(_) =>
            ratingType == 1
              ? setbookingAgentRating(index)
              : setPushAgentRating(index)
          }
          className="app-rating-container__fastart-icon app-rating-container__rating-highlight"
          icon={star}
          size="large"
          key={index}
        />
      );
    } else {
      return (
        <IonIcon
          key={index}
          onClick={(_) =>
            ratingType == 1
              ? setbookingAgentRating(index)
              : setPushAgentRating(index)
          }
          className="app-rating-container__fastart-icon"
          icon={starOutline}
          size="large"
        />
      );
    }
  };

  const submitRecapAssignmentRating = async () => {
    setDisabledSubmitBtn(true);
    if (bookingAgentRating > 0 && pushAgentRating > 0) {
      let postJsonData = {
        ue_id: rating_ue_id,
        feedback: feedbackText,
        booking_agent_rating: bookingAgentRating,
        push_agency_rating: pushAgentRating,
      };
      copyFun();
      let rating_test = await saveRecapAssignmentRating(postJsonData);
      if (rating_test?.success) {
        if (pushAgentRating == 5 && bookingAgentRating == 5) {
          let platform_api = await socialMediaPlatformsForRating();
          if (platform_api?.success) {
            setPlatformData(platform_api.data);
            setShowSocialPlatforms(true);
          } else {
            closeRatingModal();
          }
        } else {
          history.push(`/AssignmentView/${rating_ue_id}`);
        }
      } else if (rating_test?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (rating_test?.data?.errorCode == offlineErrorCode) {
        showToastMessage(rating_test?.data?.message);
      } else if (rating_test?.message) {
        showToastMessage(rating_test.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } else {
      setShowErrorAlert(true);
    }
    setDisabledSubmitBtn(false);
  };

  // copy talent feedback text
  const selection = window.getSelection();
  const range = document.createRange();
  const textToCopy = document.getElementById("textToCopy");
  const copyFun = function () {
    if (textToCopy) {
      range.selectNodeContents(textToCopy);
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
      }
    }
  };

  useEffect(() => {
    (async () => {
      let recap_feedback = await getAssignmentRecapFeedback(rating_ue_id);
      if (recap_feedback?.success) {
        let feedback_details: any = recap_feedback;
        setbookingAgentRating(feedback_details.ratingForBookingAgent);
        setPushAgentRating(feedback_details.ratingForPushAgency);
        setFeedbackText(feedback_details.message);
      } else {
        if (recap_feedback?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (recap_feedback?.data?.errorCode == offlineErrorCode) {
          showToastMessage(recap_feedback?.data?.message);
        } else if (recap_feedback?.message) {
          showToastMessage(recap_feedback.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }
    })();
    setFeedbackText("");
  }, []);
  useEffect(() => {}, [showSocialPlatforms]);
  return (
    <IonModal isOpen={show_rating_modal} mode="ios">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => closeRatingModal()}>
              <img src={back_arrow_white} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-rating-container">
          <IonRow className="app-rating-container__title-view">
            <p className="app-rating-container__title">
              Thank you for submitting your recap
            </p>
          </IonRow>
          <IonCard className="app-rating-container__card-container">
            <p className="app-rating-container__sub-title">
              Rate your Booking Agent
            </p>
            <div className="app-rating-container__rating">
              {ratings.map((item: any, index: any) => renderElement(1, item))}
            </div>
          </IonCard>

          <IonCard className="app-rating-container__card-container">
            <p className="app-rating-container__sub-title">
              Rate your Overall Experience with PUSH
            </p>
            <div className="app-rating-container__rating">
              {ratings.map((item: any, index: any) => renderElement(2, item))}
            </div>
          </IonCard>
          {((pushAgentRating < 5 || bookingAgentRating < 5) && (pushAgentRating != 0 && bookingAgentRating != 0)) && (
            <div>
              <p className="app-rating-container__feedback">
                What suggestions (if any) do you have to better improve your
                overall experiences?
              </p>
              <IonTextarea
                className="form-group__input-styles"
                value={feedbackText}
                onIonChange={handleMessageChange}
                rows={5}
              />
            </div>
          )}
          {(pushAgentRating == 5 && bookingAgentRating == 5) && (
            <div>
              <p className="app-rating-container__feedback">
                Awesome! Post your 5-Star testimonial on a site below and we'll
                send you a FREE Push Pop Socket! Send your name and a link
                to your review to info@thepushagency.com when done.
                <img src={push_pop_socket}/>
              </p>
              <IonTextarea
                id="textToCopy"
                className="form-group__input-styles"
                value={feedbackText}
                onIonChange={handleMessageChange}
                rows={5}
              />
              <p className="app-rating-container__feedback">
                Would you please spread the word? Click one of the buttons below
                to share your review.
              </p>
            </div>
          )}
          <IonButton
            onClick={() => submitRecapAssignmentRating()}
            expand="block"
            mode="ios"
            type="button"
            className="form-group__button app-rating_submit_btn"
            disabled={disabledSubmitBtn}
          >
            {(pushAgentRating == 5 && bookingAgentRating == 5) ? "Share" : "Submit"}
          </IonButton>
        </div>
      </IonContent>
      {(showSocialPlatforms && (pushAgentRating == 5 && bookingAgentRating == 5)) && (
        <>
          <div className="app-rating__social_paltforms_backdrop"></div>
          <IonFooter className="app-rating__social_share_modal">
            <div className="app-rating__social_paltforms">
              <img
                src={close_x_circle}
                className="app-rating__social_paltforms__closed"
                onClick={() => history.push(`/AssignmentView/${rating_ue_id}`)}
              />
              <h2>Share about the PUSH Agency</h2>
              <div className="app-rating__social_paltforms__img">
                {platformData.length > 0 &&
                  platformData.map((data: any, index: number) => {
                    if (data.name === "Glassdoor" && data.enabled === "Yes") {
                      return (
                        <div key={index}>
                          <div className="social_platform__img">
                            <a
                              href={data.feedback_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={glassdoor_icon} alt="" />
                            </a>
                          </div>
                          <label>Glassdoor</label>
                        </div>
                      );
                    }
                    if (data.name === "FACEBOOK" && data.enabled === "Yes") {
                      return (
                        <div key={index}>
                          <div className="social_platform__img">
                            <a
                              href={data.feedback_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={facebook_icon} alt="" />
                            </a>
                          </div>
                          <label>Facebook</label>
                        </div>
                      );
                    }
                    if (data.name === "Yelp" && data.enabled === "Yes") {
                      return (
                        <div key={index}>
                          <div className="social_platform__img">
                            <a
                              href={data.feedback_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={yelp_icon} alt="" />
                            </a>
                          </div>
                          <label>Yelp</label>
                        </div>
                      );
                    }
                    if (data.name === "BBB" && data.enabled === "Yes") {
                      return (
                        <div key={index}>
                          <div className="social_platform__img ">
                            <a
                              href={data.feedback_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={bbb_icon}
                                alt=""
                                className="social_plateform__bbb"
                              />
                            </a>
                          </div>
                          <label>The BBB</label>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </IonFooter>
        </>
      )}

      <IonAlert
        isOpen={showErrorAlert}
        onDidDismiss={() => setShowErrorAlert(false)}
        cssClass="my-custom-class"
        header={"Error"}
        message={"Please rate us!"}
        buttons={["OK"]}
      />
    </IonModal>
  );
};

export default AssignmentRecapRating;

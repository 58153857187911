import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonContent,
  IonLabel,
  IonText,
  IonButton,
  IonCard,
  IonSelectOption,
  IonSelect,
  IonFooter,
  IonTextarea,
  IonButtons,
  IonProgressBar,
  IonCheckbox,
  IonIcon,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import cameraIcon from "../../../assets/images/common/camera-icon.png";
import sadFace from "../../../assets/images/common/red-x.png";
import "./AssignmentDecline.css";
import "./AssignmentDeclineAddMore.css";
import FileUploaderAddmore from "../../../push-components/ProfileFileuploader/FileUploaderAddMore.js";
import backArrow from "../../../assets/images/common/arrow-left.png";

import {
  getUserCameraPhoto,
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
} from "../../../common/common";
import {
  talentFileUploader,
  saveModelConfirmationSelection,
  logError
} from "../../../data-services/assignmets";
import { decode } from "base64-arraybuffer";
import { useParams } from "react-router";
import info_circle from "../../../assets/images/common/info-circle.svg";
import InfoButtonPopover from "../../../push-components/InfoButtonPopover/InfoButtonPopover";

type ModalProps = {
  isopen_assign_decline: boolean;
  closeAssignDecline: Function;
  assign_event_name: string;
  assign_event_id?: any;
  assign_event_start_date?: any
};
let image_count = 1;
const AssignmentDecline: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  let url_param: any = useParams();
  const {
    isopen_assign_decline,
    closeAssignDecline,
    assign_event_name,
    assign_event_id,
    assign_event_start_date
  } = props;
  const [adFormSubmited, setAdFormSubmited] = useState<boolean>(false);
  const [showReason, setShowReason] = useState<boolean>(false);
  const [acknowledgedPCancellationPolicy, setAcknowledgedPCancellationPolicy] =
    useState<boolean>(false);
  const [cameraPhoto, setCameraPhoto] = useState<
    {
      name: string;
      type: string;
      size: number;
      file: string;
      data: any;
      ui_image: any;
      extension: any;
    }[]
  >([]);
  const [errors, setErrors] = useState<{
    reason_option: boolean;
    reason_text: boolean;
    reason_img: boolean;
    server_error: string;
    acknowledged_cancellation_policy: boolean;
  }>({
    reason_option: false,
    reason_text: false,
    reason_img: false,
    server_error: "",
    acknowledged_cancellation_policy: false,
  });
  const [enabledSubmitBtn, setEnabledSubmitBtn] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showInfo, setShowInfo] = useState<string>("");

  const submitDeclineAssignmentHandler = async (event: any) => {
    event.preventDefault();
    setEnabledSubmitBtn(true);
    setErrors({
      reason_option: false,
      reason_text: false,
      reason_img: false,
      server_error: "",
      acknowledged_cancellation_policy: false,
    });
    setUploadProgress(5);

    let decline_form = new FormData(event.target);
    let decline_option = decline_form.get("reason_option");
    let decline_text = decline_form.get("reason_text");
    let all_files = decline_form.getAll("files[]");
    decline_form.delete("files[]");
    decline_form.delete("fileuploader-list-files");
    // image  uploaded by file upload library
    for (let index = 0; index < all_files.length; index++) {
      let file: any = all_files[index];

      if (file.name) {
        decline_form.append("files[]", file);
      }
    }
    // image  uploaded by file mobile camera
    for (let i = 0; i < cameraPhoto.length; i++) {
      let camera: any = cameraPhoto[i];
      let blob = new Blob([new Uint8Array(decode(camera.file))], {
        type: "image/jpeg",
      });
      let file_obj = new File([blob], camera.name, {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      });
      decline_form.append("files[]", file_obj);
    }
    if (decline_option === "") {
      setErrors({ ...errors, reason_option: true });
      setEnabledSubmitBtn(false);
      setUploadProgress(0);
      return;
    }
    if (decline_option === "Other" && !decline_text) {
      setErrors({ ...errors, reason_text: true });
      setEnabledSubmitBtn(false);
      setUploadProgress(0);
      return;
    }
    if (decline_form.getAll("files[]").length === 0) {
      setErrors({ ...errors, reason_img: true });
      setEnabledSubmitBtn(false);
      setUploadProgress(0);
      return;
    }

    if (!acknowledgedPCancellationPolicy) {
      setErrors({ ...errors, acknowledged_cancellation_policy: true });
      setEnabledSubmitBtn(false);
      setUploadProgress(0);
      return;
    }

    let event_id: any;

    // Check if the Event ID is provided as a param (Assignments Vertical/ Horizontal Sliders) instead from the page's URL (Assignment)
    if (
      typeof assign_event_id !== "undefined" &&
      assign_event_id !== "undefined"
    ) {
      event_id = assign_event_id;
    } else {
      event_id = url_param.assign_event_id;
    }

    decline_form.append("ue_id", event_id);
    decline_form.append("type", "cancel_assignment");
    try {
      // Fileupload API request
      let file_upload: any = await talentFileUploader(
        decline_form,
        "",
        handleProgress
      );
      if (file_upload?.success) {
        let cancel_reason = decline_option ;
        // Save confirmation selection API request
        let confirmation = await saveModelConfirmationSelection({
          ue_id: event_id,
          selection: "0",
          reason: cancel_reason,
          note: decline_text
        });
        setUploadProgress(100);
        if (confirmation?.success) {
          setAdFormSubmited(true);
        } else if (confirmation?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (confirmation?.data?.errorCode == offlineErrorCode) {
          showToastMessage(confirmation?.data?.message);
          setErrors({ ...errors, server_error: confirmation?.data?.message });
        } else if (confirmation?.message) {
          showToastMessage(confirmation.message);
          setErrors({ ...errors, server_error: confirmation.message });
        } else {
          showToastMessage(somethingWentWrongErrMsg);
          setErrors({ ...errors, server_error: somethingWentWrongErrMsg });
        }
      } else if (file_upload?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (file_upload?.data?.errorCode == offlineErrorCode) {
        showToastMessage(file_upload?.data?.message);
        setErrors({ ...errors, server_error: file_upload?.data?.message });
      } else if (file_upload?.message) {
        showToastMessage(file_upload.message);
        setErrors({ ...errors, server_error: file_upload.message });
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        setErrors({ ...errors, server_error: somethingWentWrongErrMsg });
      }
      setEnabledSubmitBtn(false);
      setUploadProgress(0);
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignments Decline - submitDeclineAssignmentHandler - Exception thrown",
          "Talent App - Decline",
          JSON.stringify(e.message)
        );
        
      }
      setEnabledSubmitBtn(false);
      setUploadProgress(0);
    }
  };
  const closeModalHandler = () => {
    closeAssignDecline();
    setAdFormSubmited(false);
  };

  const removeImage = (index: any) => {
    cameraPhoto.splice(index, 1);
    setCameraPhoto(cameraPhoto);
  };

  const takePhoto = async () => {
    let image = await getUserCameraPhoto("base64");
    if (image?.base64String) {
      image_count = image_count + 1;
      if (image.base64String) {
        setCameraPhoto([
          ...cameraPhoto,
          {
            name: `image${image_count}.${image.format}`,
            type: "image/jpeg",
            size: 789,
            file: image.base64String,
            data: "",
            ui_image: "data:text/plain;base64," + image.base64String,
            extension: image.format,
          },
        ]);
      }
    }
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  useEffect(() => {}, [cameraPhoto, errors]);

  return (
    <IonModal isOpen={isopen_assign_decline} mode="ios">
      {!adFormSubmited && (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={closeModalHandler}>
                  <img
                    alt=""
                    src={backArrow}
                    className="assign-decline__header__back"
                  />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen={true}>
            <form
              className="assign-decline"
              onSubmit={submitDeclineAssignmentHandler}
            >
              <div>
                <div>
                  <div className="assign-decline__confirm">
                    <h1>Are you sure you want to cancel?</h1>
                    <IonText>
                      Cancelling your attendance for an assignment within 72
                      hours of the start date may negatively impact your PUSH
                      status and eligibility for future bookings.
                    </IonText>
                  </div>
                  <div className="assign-decline__reason">
                    <div className="form-group">
                      <IonLabel className="field-required">
                        Cancel for the Following Reason:
                      </IonLabel>
                      <IonSelect
                        placeholder="--Select Reason--"
                        name="reason_option"
                        onIonChange={(e: any) =>
                          setShowReason(e.detail.value == "Other")
                        }
                        className={
                          errors.reason_option
                            ? "form-input-styles-error"
                            : "form-group__input-styles"
                        }
                      >
                        <IonSelectOption value="Illness / Injury">
                          Illness / Injury
                        </IonSelectOption>
                        <IonSelectOption value="Transportation">
                          Transportation
                        </IonSelectOption>
                        <IonSelectOption value="No longer interested / available">
                          No longer interested / available
                        </IonSelectOption>
                        <IonSelectOption value="Accepted a gig somewhere else">
                          Accepted a gig elsewhere
                        </IonSelectOption>
                        <IonSelectOption value="Other">Other</IonSelectOption>
                      </IonSelect>
                      {errors.reason_option && (
                        <div className="error-message">
                          This field is required
                        </div>
                      )}
                    </div>
                    <div className="form-input-group">
                      <IonLabel mode="md" className={showReason ? "field-required" : ""}>
                        Notes:
                      </IonLabel>
                      <IonTextarea
                        name="reason_text"
                        className={
                          errors.reason_text
                            ? "form-input-styles-error"
                            : "form-group__input-styles"
                        }
                        placeholder="Insert"
                        rows={5}
                        mode="md"
                      ></IonTextarea>
                      {errors.reason_text && (
                        <div className="error-message">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="assign-decline__verification">
                    <IonLabel className="field-required">
                      Photo Verification
                    </IonLabel>
                    <IonText>
                      Upload a photo to verify the reason for your cancellation.
                      All documentation will be taken into consideration.
                    </IonText>
                  </div>
                  {errors.reason_img && (
                    <div className="error-message">Image is required</div>
                  )}
                  <div className="assign-decline__upload-btn">
                    <FileUploaderAddmore name="files[]" />
                    <input type="hidden" name="files[]" />
                    <div className="assign-decline_take-camera">
                      <IonCard onClick={takePhoto}>
                        <img alt="" src={cameraIcon} />
                        <IonLabel>Take Photo</IonLabel>
                      </IonCard>
                      <div className="assign-decline_photo-items">
                        {cameraPhoto.map((item: any, index: number) => (
                          <div
                            key={index}
                            className="assign-decline_photo-list"
                          >
                            <img
                              className="img_file"
                              alt=""
                              src={item.ui_image}
                            />
                            <div className="assign-decline_camera-img">
                              <span className="img_name">{item.name}</span>
                              <button onClick={() => removeImage(index)}>
                                <i className="fileuploader-icon-remove remove-color"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="assign-decline__confirm-text">
                    <IonText>
                      By pressing Unbook, you will be removed from this
                      assignment. Please also contact your Booking Agent.
                    </IonText>
                  </div>
                  <div className="assign-decline__checkbox">
                    <div>
                      <IonCheckbox
                        mode="md"
                        className="form-group__checkbox-styles"
                        checked={acknowledgedPCancellationPolicy}
                        onIonChange={(e) =>
                          setAcknowledgedPCancellationPolicy(e.detail.checked)
                        }
                      />
                    </div>
                    <IonLabel className="field-required">
                      I have read and understand the PUSH Cancellation Policy{" "}
                      <IonIcon
                        icon={info_circle}
                        onClick={() => setShowInfo("cancellation_policy")}
                      />
                    </IonLabel>
                  </div>
                  {errors.acknowledged_cancellation_policy && (
                    <div className="error-message">This field is required</div>
                  )}
                </div>
              </div>
              <IonFooter className="assign-decline__submit-btn">
                {errors.server_error !== "" && (
                  <div className="error-message">{errors.server_error}</div>
                )}
                {enabledSubmitBtn && (
                  <IonProgressBar value={uploadProgress / 100} />
                )}
                <IonButton
                  expand="block"
                  mode="ios"
                  type="submit"
                  className=" form-group__button"
                  disabled={enabledSubmitBtn}
                >
                  Submit
                </IonButton>
              </IonFooter>
            </form>
          </IonContent>
        </>
      )}
      {showInfo === "cancellation_policy" && (
        <InfoButtonPopover
          infoButtonClass={"cancellation_policy"}
          updatePopoverState={setShowInfo}
        ></InfoButtonPopover>
      )}
      {adFormSubmited  && (
        <IonContent>
          <div className="assign-decline__cancel-confirm">
            <img
              className="assign-decline__sadFace-icon"
              src={sadFace}
              alt=""
            />
            <h2 className="assign-decline__confirm-title">
              Cancellation Confirmation
            </h2>
            <div className="assign-decline__confirm-subTitle">
              You are no longer booked for '{assign_event_name}'{assign_event_start_date ? (" starting on " + assign_event_start_date) : ""}.
            </div>

            <IonButton
              fill="clear"
              className="assign-decline_view-scroeboard-btn form-group__button"
              routerLink="/Opportunities"
            >
              View More Nearby Gigs
            </IonButton>
          </div>
        </IonContent>
      )}
    </IonModal>
  );
};
export default AssignmentDecline;

import { configureStore,createAsyncThunk } from '@reduxjs/toolkit'
import authReducer from './auth'
import menuReducer from './menuShowHide'
import { Network } from "@capacitor/network";
// const logger = (store) => (next) => async (action) => {
//     // Cumva trebuie sa fac aici sa mearga async ...nu prea vrea doar punand async action ....
//    let networkStatus = (await Network.getStatus()).connectionType;
//     console.log('dispatching', action);
//     console.log('networkStatus', networkStatus);
//     let result = next(action)
//     console.log('next state', store.getState())
//     return result
//   }

const store  = configureStore({
    reducer: { auth: authReducer, menu:menuReducer },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;
import { CapacitorHttp } from '@capacitor/core';
import { get } from "../storage/storage";
import axios from "axios";
import { Network } from "@capacitor/network";
import { noInternetResponse, unauthorizedResponse } from "../common/common";

export const scoreboardLanding = async (fromDate?: any, toDate?: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url =
      `${process.env.REACT_APP_HP_API_URL}/userEvent/scoreboardLanding` +
      (fromDate && toDate
        ? `?start_date=` + fromDate + `&end_date=` + toDate
        : ``);

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let score_api_res = await CapacitorHttp.get(config);
    return score_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const modelRewardsList = async (limit: number = 10) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/modelRewardsList?limit=${limit}`;

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let reward_api_res = await CapacitorHttp.get(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getAvailableRewardsByCategories = async (limit: number = 10) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/getAvailableRewardsByCategories2308?limit=${limit}`;

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let reward_api_res = await CapacitorHttp.get(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getRewardInfo = async (rewardId: any) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/getRewardInfo?reward_id=${rewardId}`;

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let reward_api_res = await CapacitorHttp.get(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};


export const getAvailableRewardsByCategory = async (category: any, limit: number = 10) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/getAvailableRewardsByCategory2308?limit=${limit}&category=${category}`;

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let reward_api_res = await CapacitorHttp.get(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const claimModelReward = async (
  id: string,
  email: string,
  address: string,
  finalCheckedAttributesValues?: any,
  eliteReward?: any
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = eliteReward ? `${process.env.REACT_APP_HP_API_URL}/userEvent/claimModelEliteReward` : `${process.env.REACT_APP_HP_API_URL}/userEvent/claimModelReward`;
    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        model_reward_id: id,
        model_email: email,
        model_address: address,
        checked_attributes_and_values: finalCheckedAttributesValues
      },
    };
    let reward_api_res = await CapacitorHttp.post(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const modelClaimedRewardsList = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/modelClaimedRewardsList`;
    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let reward_api_res = await CapacitorHttp.get(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const modelPointsList = async () => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/modelPointsList`;
    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let point_api_res = await CapacitorHttp.get(config);
    return point_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const setRewardFavoriteStatus = async (
  rewardID: any,
  favorite: any,
) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url = `${process.env.REACT_APP_HP_API_URL}/userEvent/setRewardFavoriteStatus`;
    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
      data: {
        rewardID: rewardID,
        favorite: favorite,
      },
    };
    let reward_api_res = await CapacitorHttp.post(config);
    return reward_api_res.data;
  } else {
    return noInternetResponse;
  }
};

export const getTalentBadges = async (always_include_elite: any = 0) => {
  let networkStatus = (await Network.getStatus()).connectionType;
  if (networkStatus !== "none") {
    const authToken = await get("login_user_token");
    if (!authToken) {
      return unauthorizedResponse;
    }
    let api_url =
      `${process.env.REACT_APP_HP_API_URL}/userEvent/getTalentBadges?always_include_elite=${always_include_elite}`

    let config: any = {
      url: encodeURI(api_url),
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    };
    let score_api_res = await CapacitorHttp.get(config);
    return score_api_res.data;
  } else {
    return noInternetResponse;
  }
};


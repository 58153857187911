import React, { useEffect, useState } from "react";
import { IonPopover, isPlatform } from "@ionic/react";
import exclamation_mark from "../../assets/warning-triangle.svg";
import appstore from "../../assets/appstore.png";
import googleplay from "../../assets/googleplay.png";
import "./AppUpdatePopover.css";
interface Props {
  showPopover: boolean;
  setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
  newAppVersionPopoverImage: any;
}

const AppUpdatePopover: React.FC<Props> = ({
  showPopover,
  setShowPopover,
  newAppVersionPopoverImage,
}) => {
  const [downloadImage, setDownloadImage] = useState<any>("");
  const [downloadLink, setDownloadLink] = useState<any>("");
  const [platform, setPlatform] = useState<any>("");

  useEffect(() => {
    (async () => {
      if (isPlatform("mobileweb") && isPlatform("desktop")) {
      } else {
        if (isPlatform("ios")) {
          setPlatform("ios");
          setDownloadImage(appstore);
          setDownloadLink(
            "https://apps.apple.com/us/app/push-models-mobile/id858506392"
          );
        }

        if (isPlatform("android")) {
          setPlatform("android");
          setDownloadImage(googleplay);
          setDownloadLink(
            "https://play.google.com/store/apps/details?id=com.jonathan.pushmodels&hl=en&gl=US"
          );
        }
      }
    })();
  }, []);

  return (
    <IonPopover
      isOpen={showPopover}
      onDidDismiss={() => setShowPopover(false)}
      showBackdrop={true}
      cssClass="app-update-popover"
    >
      <div className="app-update-popover__content">
        <div>
          {newAppVersionPopoverImage == "" && (
            <h2 className="app-update-popover__content__exclamation-mark-container">
              <img src={exclamation_mark}></img>
            </h2>
          )}

          {newAppVersionPopoverImage != "" && (
            <h2 className="app-update-popover__content__random_image_container">
              <img src={newAppVersionPopoverImage}></img>
            </h2>
          )}

          <h1>MIND BLOWING RELEASE READY FOR DOWNLOAD!</h1>
          <p>
            Update to the latest version of our app & take advantage of awesome
            new features!
          </p>
        </div>
      </div>
      {downloadImage && (
        <div style={{ padding: "16px" }}>
          <a
            className="app-update-popover__content__download-link"
            href={downloadLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={
                platform == "ios"
                  ? "app-update-popover__content__download-image--appstore"
                  : "app-update-popover__content__download-image--googleplay"
              }
              src={downloadImage}
            />
          </a>
        </div>
      )}
    </IonPopover>
  );
};

export default AppUpdatePopover;

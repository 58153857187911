import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import {
  IonActionSheet,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import "./Referrals.css";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import new_referal from "../../assets/images/common/referal_new_ref.png";
import custome_referal from "../../assets/images/common/referal_custom_ref.png";
import internal_referal from "../../assets/images/common/referal_internal_ref.png";
import external_referal from "../../assets/images/common/referal_extrenal_ref.png";
import NewCustomerReferral from "./NewCustomerReferral/NewCustomerReferral";
import CustomerReferrals from "./CustomerReferrals/CustomerReferrals";
import {
  showToastMessage,
  noInternetMsg,
} from "../../common/common";
import { useHistory } from "react-router";
import InternalTalentReferrals from "./InternalTalentReferrals/InternalTalentReferrals";
import ExternalTalentReferralsList from "./ExternalTalentReferrals/ExternalTalentReferralsList";
import { Network } from "@capacitor/network";

const Referrals: React.FC = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [isOpenNewReferralModal, setIsOpenNewReferralModal] =
    useState<boolean>(false);
  const [isOpenCustomerReferralModal, setIsOpenCustomerReferralModal] =
    useState<boolean>(false);
  const [isOpenModelReferralModal, setIsOpenModelReferralModal] =
    useState<boolean>(false);
  const [isOpenInternalReferrals, setIsOpenInternalReferrals] =
    useState<boolean>(false);
  const closeNewReferralModal = async () => {
    setIsOpenNewReferralModal(false);
    await referralInit();
  };
  const closeInternalReferral = () => {
    setIsOpenInternalReferrals(false);
  };

  const referralInit = async () => {
    let networkStatus = (await Network.getStatus()).connectionType;
    if (networkStatus === "none") {
      showToastMessage(noInternetMsg);
    }
   
  };

  useEffect(() => {
    (async () => {
      await referralInit();
    })();
  }, []);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Referrals"
        bgcolor="primary"
        buttonType="undefined"
      ></SimpleHeaderView>
      <IonContent>
        <div className="referal_content">
          <IonGrid>
            <IonRow>
              <IonCol size="6" className="referal_col__left">
                <IonCard
                  className="referal_card"
                  onClick={() => setShowActionSheet(true)}
                >
                  <img src={new_referal} alt="" />
                  <IonText>
                    Make New
                    <br />
                    Referral
                  </IonText>
                </IonCard>
              </IonCol>
              <IonCol size="6" className="referal_col__right">
                <IonCard
                  className="referal_card"
                  onClick={() => setIsOpenCustomerReferralModal(true)}
                >
                  <img src={custome_referal} alt="" />
                  <IonText>
                    Customer
                    <br />
                    Referrals
                  </IonText>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6" className="referal_col__left">
                <IonCard
                  className="referal_card"
                  onClick={() => setIsOpenInternalReferrals(true)}
                >
                  <img src={internal_referal} alt="" />
                  <IonText>
                    Internal Talent
                    <br />
                    Referrals
                  </IonText>
                </IonCard>
              </IonCol>
              <IonCol size="6" className="referal_col__right">
                <IonCard
                  className="referal_card"
                  onClick={() => setIsOpenModelReferralModal(true)}
                >
                  <img src={external_referal} alt="" />
                  <IonText>
                    External Talent
                    <br />
                    Referrals
                  </IonText>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          mode="ios"
          cssClass="referal_action_items"
          header="Make new referral"
          buttons={[
            {
              text: "Customer Referrals",
              role: "add new ",
              handler: () => {
                setIsOpenNewReferralModal(true);
              },
            },
            // {
            //   text: "Internal Talent Referrals",
            //   icon: "share",
            //   handler: () => {

            //     history.push('/NewInternalTalentReferrals/myprofile')
            //   },
            // },
            {
              text: "External Talent Referrals",
              handler: () => {
                history.push("/ExternalTalentReferrals/myprofile");
              },
            },
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {},
            },
          ]}
        ></IonActionSheet>
      </IonContent>
      <NewCustomerReferral
        is_open_referral={isOpenNewReferralModal}
        closeReferral={closeNewReferralModal}
      />
      {/* for cutomer referrals */}
      {isOpenCustomerReferralModal && (<CustomerReferrals
        is_open_cust_referral={isOpenCustomerReferralModal}
        closeCustReferral={() => setIsOpenCustomerReferralModal(false)}
        title="Customer Referrals"
      />)}
      {/* external talent referral list  */}
      {isOpenModelReferralModal && (<ExternalTalentReferralsList
        is_open_cust_referral={isOpenModelReferralModal}
        closeCustReferral={() => setIsOpenModelReferralModal(false)}
        title="External Talent Referrals"
      />)}
      {isOpenInternalReferrals && (<InternalTalentReferrals
        is_open_internal_referral={isOpenInternalReferrals}
        closeInternalReferral={closeInternalReferral}
      />)}
    </IonPage>
  );
};

export default Referrals;

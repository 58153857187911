import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth";
import { useHistory } from "react-router";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonProgressBar,
  IonAlert,
  IonIcon,
} from "@ionic/react";
import "./ManageIntroVideo.css";
import "./IntroVideoFileUpload.css";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  unauthorizedErrMsg,
  offlineErrorCode,
} from "../../../common/common";
import { set } from "../../../storage/storage";
import {
  logError,
  getModelIntroVideo,
  deleteModelIntroVideo,
} from "../../../data-services/assignmets";
import FileUploaderAddmore from "../../../push-components/ProfileFileuploader/FileUploaderAddMore.js";
import { talentFileUploader } from "../../../data-services/assignmets";
import { closeCircle } from "ionicons/icons";

// Declare Plyr as a global variable
declare global {
  interface Window {
    Plyr: any;
  }
}

const ManageIntroVideo: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentTalentIntroVideo, setCurrentTalentIntroVideo] =
    useState<any>(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

  const fetchIntroVideo = async () => {
    try {
      let model_intro_video_api: any = await getModelIntroVideo();
      if (!model_intro_video_api?.success) {
        // Error
        if (model_intro_video_api?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (model_intro_video_api?.data?.errorCode == offlineErrorCode) {
          showToastMessage(model_intro_video_api?.message);
        } else if (model_intro_video_api?.message) {
          await showToastMessage(model_intro_video_api.data.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      } else {
        // Success
        if (model_intro_video_api?.data?.talent_intro_video) {
          // We have an existing Talent Intro Video
          setCurrentTalentIntroVideo(
            model_intro_video_api?.data?.talent_intro_video
          );
          if (window.Plyr) {
            setTimeout(() => {
              // Get all video elements with a specific class or any other criteria
              const videoElements = document.querySelectorAll(
                ".talent-intro-video-container video"
              );
              const videoArray = Array.from(videoElements);
              // Loop through each video element and initialize Plyr
              for (const videoElement of videoArray) {
                const player = new window.Plyr(videoElement, {
                  controls: ["play", "volume", "fullscreen", "play-large"],
                  fullscreen: {
                    enabled: true,
                  },
                });
              }
            }, 500);
          }
        }
      }
    } catch (e: any) {
      // Fileupload Exception
      // Re-enable Submit
      setDisabledSubmitBtn(false);
      setUploadProgress(0);
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Manage Intro Video - fetchIntroVideo - Exception thrown",
          "Talent App - Manage Intro Video",
          JSON.stringify(e.message)
        );
      }
    }
  };

  /**
   * Submit form handler
   * @param event
   * @returns
   */
  const onSubmit = async (event: any) => {
    // Disable Submit + Show loading progress
    event.preventDefault();
    setDisabledSubmitBtn(true);
    setUploadProgress(5);
    try {
     
      let model_photo = new FormData(event.target);
      if(!model_photo.get("type") || model_photo.get("type") != "talent_intro_video") {
        model_photo.delete("type");
        model_photo.append("type", "talent_intro_video");
      }



      // Fileupload Started
      let file_upload: any = await talentFileUploader(
        model_photo,
        "",
        handleProgress
      );

      // Fileupload Finished
      if (file_upload?.success) {
        // Fileupload Success
        await showToastMessage(
          currentTalentIntroVideo
            ? "Successfully Replaced!"
            : "Successfully Added!"
        );
        await set("login_has_intro_video", true);
        history.push("/MyAccount");
      } else if (file_upload?.data?.errorCode == unauthorizedErrorCode) {
        // Fileupload Error
        dispatch(authActions.logout());
        return;
      } else if (file_upload?.data?.errorCode == offlineErrorCode) {
        showToastMessage(file_upload?.data?.message);
      } else if (file_upload?.message) {
        showToastMessage(file_upload.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
      // Re-enable Submit
      setDisabledSubmitBtn(false);
      setUploadProgress(0);
    } catch (e: any) {
      // Fileupload Exception
      // Re-enable Submit
      setDisabledSubmitBtn(false);
      setUploadProgress(0);
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Manage Intro Video - onSubmit - Exception thrown",
          "Talent App - Manage Intro Video",
          JSON.stringify(e.message)
        );
      }
    }
  };

  const handleProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadProgress(progress);
  };

  const deleteIntroVideo = async () => {
    try {
      let delete_api = await deleteModelIntroVideo();
      if (delete_api?.success) {
        showToastMessage(delete_api.message);
        setCurrentTalentIntroVideo(null);
        await set("login_has_intro_video", false);
      } else if (delete_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (delete_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(delete_api?.data?.message);
      } else if (delete_api?.message) {
        showToastMessage(delete_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Manage Intro Video - deleteIntroVideo - Exception thrown",
          "Talent App - Manage Intro Video",
          JSON.stringify(e.message)
        );
      }
      setDisabledSubmitBtn(false);
    }
  };

  useEffect(() => {
    fetchIntroVideo();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="ios" color="primary">
          <IonTitle>Create Intro Video</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" mode="md"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={onSubmit} className="manage-intro-video-container">
          <div className="manage-intro-video">
            <h3>Make your profile stand out!</h3>

            <p className="manage-intro-video__info">
              Add a quick video to introduce yourself so that booking agents & potential customers can get to know you better!
            </p>
            <p className="manage-intro-video__info">
              Profiles with intro videos are moved to the top of the applicant's
              list and are <strong>twice as likely</strong> to be selected
            </p>

            <p className="orange-border">
              <div className="orange-border__content">
                <strong>Helpful Tips:</strong>
              </div>
              <p className="orange-border__content"></p>
              <div className="orange-border__content orange-border__content--left">
                <ul>
                  <li>
                    Include details like promotional and professional
                    experience, special skills, accomplishments, etc
                  </li>
                  <li>Video length must be 30 seconds or less</li>
                  <li>Use a room with good lightning and a plain background</li>
                  <li>Recommend landscape mode</li>
                  <li>Keep it short, sweet, and have fun with it!</li>
                  <li>Most importantly, be yourself :)</li>
                </ul>
              </div>
            </p>
            <p className="manage-intro-video__info">
              <strong>Please note:</strong> This video will be displayed
              publicly on your PUSH talent profile.
            </p>
            {currentTalentIntroVideo && (
              <p className="talent-intro-video-container">
                <div>
                  <IonIcon
                    icon={closeCircle}
                    onClick={() => {
                      setShowDeleteAlert(true);
                    }}
                  />
                  <video controls>
                    <source
                      src={`${currentTalentIntroVideo.full_path}`}
                      type={`video/${currentTalentIntroVideo.extension}`}
                    />
                    Video not supported.
                  </video>
                  {(currentTalentIntroVideo?.status_str && currentTalentIntroVideo?.status_color ) && (
                    <div className="talent-intro-video-container__status" style={{ color: currentTalentIntroVideo?.status_color }}>{currentTalentIntroVideo?.status_str}</div>
                  )}
                </div>
              </p>
            )}
            <p>
              <div className="manage-intro-video__upload-btn">
                <FileUploaderAddmore limit="1" name="files" />
              </div>
              <input type="hidden" name="type" value="talent_intro_video" />
            </p>
            {currentTalentIntroVideo && (
              <p className="manage-intro-video__current-video-notice">
                Only one video can be saved on your profile. To replace an
                existing video, choose 'Upload Video'.
              </p>
            )}
          </div>
          <div className="manage-intro-video__submit">
            {disabledSubmitBtn && (
              <IonProgressBar value={uploadProgress / 100} />
            )}
            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button"
              disabled={disabledSubmitBtn}
            >
              Save
            </IonButton>
          </div>
        </form>
      </IonContent>
      <IonAlert
        isOpen={showDeleteAlert}
        // mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={"Are You Sure?"}
        message={"Please confirm if you want to delete the intro video"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "Yes",
            handler: () => {
              deleteIntroVideo();
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default ManageIntroVideo;

import React, { useEffect, useState } from "react";
import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonSpinner,
} from "@ionic/react";
import "./AssignmentView.css";
import CalendarIcon from "../../assets/images/opportunity/op_calendar.png";
import ContactIcon from "../../assets/images/opportunity/op_contact.png";
import documentIcon from "../../assets/images/common/document_icon.svg";
import downloadICon from "../../assets/images/common/download_icon.svg";
import groupChat from "../../assets/images/common/group-chat.png";
import direactionIcon from "../../assets/images/common/direaction-icon.png";
import event_placeholder from "../../assets/images/common/event_placeholder_img.jpg";
import { useHistory, useParams } from "react-router";
import {
  canModelResumWork,
  canModelTakeBreak,
  getassignmentquestion,
  getModelAssignment,
  modelResumeWork,
  modelTakeBreak,
  talentEventCheckout,
  saveModelDC,
} from "../../data-services/assignmets";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import AssignmentRecapSuccess from "../AssignmentRecap/AssignmentRecapSuccess/AssignmentRecapSuccess";
import AssignmentDecline from "../AssignmentView/AssignmentDecline/AssignmentDecline";
import AssignmentConfirm from "../AssignmentView/AssignmentConfirm/AssignmentConfirm";
import {
  getUserCurrentPosition,
  showToastMessage,
  unauthorizedErrorCode,
  offlineErrorCode,
  somethingWentWrongErrMsg,
} from "../../common/common";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import AssignmentCheckin from "./AssignmentCheckin/AssignmentCheckin";
import upload_img from "../../assets/images/common/cloud-upload.png";
import { access } from "fs";
import { async } from "@firebase/util";
import Loading from "../../push-components/loading/Loading";
import AssignmentRecapReceipts from "../AssignmentRecap/AssignmentRecapReceipts";
import UploadEventPhotosWithoutRecap from "../AssignmentRecap/UploadEventPhotosWithoutRecap";
import PCardAgreement from "../Home/SetupAccount/PCardAgreement/PCardAgreement";
import { set, get } from "../../storage/storage";
import checkedIcon from "../../assets/images/common/checked-3d.png";
import DollarSignIcon from "../../assets/images/common/doller-round.svg";
import DoublePointsIcon from "../../assets/images/opportunity/2x.png";
import TriplePointsIcon from "../../assets/images/opportunity/3x.png";
import info_circle from "../../assets/images/common/info-circle.svg";
import CustomerAgreement from "../Home/SetupAccount/CustomerAgreement/CustomerAgreement";

// Declare Plyr as a global variable
declare global {
  interface Window {
    Plyr: any;
  }
}

const AssignmentView: React.FC = () => {
  const dispatch = useDispatch();
  const av_url_param: any = useParams();
  const history = useHistory();
  const [assignmentDetails, setAssignmentDetails] = useState<any>(null);
  const [isOpenAssignDecline, setIsOpenAssignDecline] =
    useState<boolean>(false);
  const [isOpenAssignConfirm, setIsOpenAssignConfirm] =
    useState<boolean>(false);
  const [isOpenAssignRecapSuccess, setIsOpenAssignRecapSuccess] =
    useState<boolean>(false);
  const [isOpenCheckin, setIsOpenCheckin] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: "", longitude: "" });
  const [enabledChekinBtn, setEnabledChekinBtn] = useState<boolean>(false);
  const [checkinConfirmed, setCheckinConfirmed] = useState<{
    show: boolean;
    message: string;
  }>({ show: false, message: "" });
  const [assignmentRecaptPhotos, setAssignmentRecapPhotos] = useState<any>([]);
  const [assignmentRecaptVideos, setAssignmentRecapVideos] = useState<any>([]);
  const [assignmentRecaptRecipt, setAssignmentRecapRecipt] = useState<any>([]);
  const [recapEventData, setRecapEventData] = useState<any>({});
  const [isOpen_PCardAgreement, setIsOpenPCardAgreement] =
    useState<boolean>(false);
  const [modelPCardAgreementConfirmed, setModelPCardAgreementConfirmed] =
    useState<boolean>(false);

  const [isOpen_CustomerAgreement, setIsOpenCustomerAgreement] =
    useState<boolean>(false);
  const [modelCustomerAgreementConfirmed, setModelCustomerAgreementConfirmed] =
    useState<boolean>(false);

  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);
  const [showRecapReceiptModal, setShowRecapReceiptModal] =
    useState<boolean>(false);
  const [componentRefresh, setComponentRefresh] = useState<boolean>(false);
  const [showRecapPhotoModal, setShowRecapPhotoModal] =
    useState<boolean>(false);

  const avInit = async () => {
    setWaitingForApiResonse(true);
    let assignment_detail = await getModelAssignment(
      av_url_param.assign_event_id
    );

    // Redirect back to Home in case talent is no longer booked to this assignment or the assignment is deleted
    if (!assignment_detail?.data?.userEvent) {
      showToastMessage("Assignment is no longer available!");
      history.replace("/Home");
      return;
    }
    if (assignment_detail?.success) {
      let assignment_info: any = assignment_detail.data.userEvent[0];

      let can_take_break = await canModelTakeBreak(
        av_url_param.assign_event_id
      );
      let can_resum_work = await canModelResumWork(
        av_url_param.assign_event_id
      );
      assignment_info.cantakebreak = can_take_break?.data?.allowed
        ? true
        : false;
      assignment_info.canresumework = can_resum_work?.data?.allowed
        ? true
        : false;
      let photos: any = await getassignmentquestion(
        av_url_param.assign_event_id
      );

      let model_p_card_agreement_confirmed = await get(
        "model_p_card_agreement_confirmed"
      );
      setModelPCardAgreementConfirmed(model_p_card_agreement_confirmed);

      setModelCustomerAgreementConfirmed(assignment_info?.requiredCustomerAgreementText === "");

      if (photos?.success) {
        setRecapEventData(photos.data);
        let event_photos: any = photos.data.images;
        let only_event_img = [];
        let only_receipt_img = [];
        if (event_photos.length) {
          for (let index = 0; index < event_photos.length; index++) {
            const element = event_photos[index];
            if (element.mrd_is_receipt == 0) {
              only_event_img.push(element);
            } else {
              only_receipt_img.push(element);
            }
          }
          setAssignmentRecapPhotos(only_event_img);
          setAssignmentRecapRecipt(only_receipt_img);
        }
        if (photos?.data?.videos?.length) {
          setAssignmentRecapVideos(photos?.data?.videos);
        }
      }

      setAssignmentDetails(assignment_info);
    } else if (assignment_detail?.data?.errorCode == offlineErrorCode) {
      showToastMessage(assignment_detail?.data?.message);
    } else if (assignment_detail?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (assignment_detail?.message) {
      showToastMessage(assignment_detail.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setWaitingForApiResonse(false);
    if (window.Plyr) {
      // Get all video elements with a specific class or any other criteria
      const videoElements = document.querySelectorAll(
        ".assignment-view__photos_each video"
      );
      const videoArray = Array.from(videoElements);

      // Loop through each video element and initialize Plyr
      for (const videoElement of videoArray) {
        const player = new window.Plyr(videoElement, {
          controls: ["play", "volume", "fullscreen"],
          fullscreen: {
            enabled: true,
          },
        });
      }
    }
  };
  const closeAssignDecline = () => {
    setIsOpenAssignDecline(false);
  };
  const closeAssignConfirm = async (param: string = "") => {
    if (param === "decline") {
      setIsOpenAssignConfirm(false);
      setIsOpenAssignDecline(true);
    } else {
      setIsOpenAssignConfirm(false);
      await avInit();
    }
  };

  const close_PCardAgreement = async (confirmed?: false) => {
    setIsOpenPCardAgreement(false);

    if (confirmed) {
      // Also hide the complete P-Card Agreement button,  show it as completed and set model p-card agreement completed
      await set("model_p_card_agreement_confirmed", 1);
      setModelPCardAgreementConfirmed(true);
    }
  };

  const close_CustomerAgreement = async (confirmed?: false) => {
    setIsOpenCustomerAgreement(false);

    if (confirmed) {
      setModelCustomerAgreementConfirmed(true);
    }
  };

  const closeAssignRecapSuccess = () => {
    setIsOpenAssignRecapSuccess(false);
  };

  const closeCheckin = async (success_msg: any = "") => {
    setIsOpenCheckin(false);
    await avInit();
    if (success_msg) {
      setCheckinConfirmed({ show: true, message: success_msg.message });
    }
  };

  const accessRecapHandler = async () => {
    history.push(`/AssignmentRecap/${assignmentDetails.ue_id}`);
  };

  const checkInHandler = async () => {
    setEnabledChekinBtn(true); // disabled checkin button till getUserCurrentPosition() response
    let { latitude, longitude }: any = await getUserCurrentPosition();
    if (latitude && longitude) {
      setCoordinates({ latitude: latitude, longitude: longitude });
      setIsOpenCheckin(true);
    }
    setEnabledChekinBtn(false);
  };

  const checkhOutHandler = async (param: string) => {
    setEnabledChekinBtn(true);
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let break_api = await talentEventCheckout({
      checkInUeId: assignmentDetails.ue_id,
      latitude,
      longitude,
    });
    if (break_api?.success) {
      if (param == "1") {
        history.push(`/AssignmentRecap/${assignmentDetails.ue_id}`);
      }
    } else {
      if (break_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (break_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(break_api?.message);
      } else if (break_api?.message) {
        await showToastMessage(break_api.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await avInit();
    setEnabledChekinBtn(false);
  };

  const takeBreackHandler = async () => {
    setEnabledChekinBtn(true);
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let break_api = await modelTakeBreak({
      checkInUeId: assignmentDetails.ue_id,
      latitude,
      longitude,
    });
    if (break_api.success) {
      showToastMessage(break_api.data.message);
    } else {
      if (break_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (break_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(break_api?.message);
      } else if (break_api?.message) {
        await showToastMessage(break_api.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await avInit();
    setEnabledChekinBtn(false);
  };

  const resumeWorkHandler = async () => {
    let { latitude, longitude }: any = await getUserCurrentPosition();
    let resume_api = await modelResumeWork({
      checkInUeId: assignmentDetails.ue_id,
      latitude,
      longitude,
    });
    if (resume_api?.success) {
      showToastMessage(resume_api.data.message);
    } else {
      if (resume_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (resume_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(resume_api?.message);
      } else if (resume_api?.message) {
        await showToastMessage(resume_api.data.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await avInit();
    setEnabledChekinBtn(false);
  };

  const takeGradeTest = () => {
    if (assignmentDetails.hasToTakeTests) {
      let status_type = 1; // pass one for Grade test
      history.push(`/TakeTest/${assignmentDetails.ue_id}/${status_type}`);
    }
  };

  const closeRecapReceiptModal = async (refresh_com: boolean = true) => {
    if (refresh_com) {
      setComponentRefresh(!componentRefresh);
    }
    setShowRecapReceiptModal(false);
  };

  const closeRecapPhotoModal = async (refresh_com: boolean = true) => {
    if (refresh_com) {
      setComponentRefresh(!componentRefresh);
    }
    setShowRecapPhotoModal(false);
  };

  const copyBookedTalentReferralLink = (content: any) => {
    navigator.clipboard.writeText(content);
    showToastMessage("Copied to clipboard");
  };

  /**
   * Confirm Model DBC/ DOC
   * @param event_id
   * @param type
   * @returns
   */
  const submitDC = async (event_id: string, type: string) => {
    setEnabledChekinBtn(true);
    let dc_api = await saveModelDC(event_id, type);
    if (dc_api?.success) {
      await showToastMessage(dc_api?.message);
    } else {
      if (dc_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (dc_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(dc_api?.message);
      } else if (dc_api?.message) {
        await showToastMessage(dc_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
    await avInit();
    setEnabledChekinBtn(false);
  };

  useEffect(() => {
    (async () => {
      await avInit();
    })();
  }, [componentRefresh]);
  return (
    <IonPage>
      <IonContent>
        <IonHeader class="ion-no-border assignment-view__header">
          {assignmentDetails && (
            <>
              <img
                src={
                  assignmentDetails.bannerPath == 0
                    ? event_placeholder
                    : assignmentDetails.bannerPath
                }
                alt=""
              />
            </>
          )}

          <IonToolbar
            mode="ios"
            color="translucent"
            className="assignment-view__header__toolbar"
          >
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="/"
                mode="md"
                className="assignment-view__header__toolbar__back-btn"
              />
            </IonButtons>
            {/* <IonButton
              fill="clear"
              mode="ios"
              slot="end"
              className="assignment-view__header__toolbar__menu-btn"
            >
              <img src={menuIcon} alt="" />
            </IonButton> */}
            <IonButton
              fill="clear"
              mode="ios"
              slot="end"
              className="assignment-view__header__toolbar__menu-btn"
            >
              <img src={groupChat} alt="" />
            </IonButton>
          </IonToolbar>
          {assignmentDetails?.isBackup && (
            <IonToolbar
              mode="ios"
              color="translucent"
              className="assignment-view__header__toolbar-bottom"
            >
              <span className="assignment-view__header__toolbar-bottom__label">
                {assignmentDetails?.backupType}
              </span>
            </IonToolbar>
          )}
        </IonHeader>
        {!waitingForApiResonse && (
          <div className="assignment-view-content">
            <div className="assignment-view__event">
              <IonItem className="assignment-view__event__booked">
                {!assignmentDetails ? (
                  <IonSpinner name="dots"></IonSpinner>
                ) : (
                  <IonBadge slot="start">
                    <span style={{ backgroundColor: "red" }}></span>{" "}
                    {assignmentDetails.ue_status}
                  </IonBadge>
                )}
              </IonItem>
              <div className="assignment-view__event__name">
                <h2>{assignmentDetails && <>{assignmentDetails.ue_name}</>}</h2>
              </div>
              <IonItem className="assignment-view__event__status">
                {assignmentDetails && (
                  <>
                    <img
                      slot="start"
                      src={assignmentDetails.customer_image_url}
                      alt=""
                    />
                    <IonText slot="start">
                      {assignmentDetails.customer_company}
                    </IonText>
                  </>
                )}
              </IonItem>
            </div>

            <div className="assignment-view__notice">
              {assignmentDetails?.showConfirmation && (
                <IonCard>
                  <IonTitle>Reminder</IonTitle>
                  <IonText>
                    Please respond ASAP to confirm this booking!
                  </IonText>
                  <div className="assignment-view__action_btn">
                    <IonButton
                      mode="ios"
                      slot="start"
                      fill="outline"
                      size="large"
                      className="form-group__button"
                      onClick={() => setIsOpenAssignDecline(true)}
                    >
                      Decline
                    </IonButton>
                    <IonButton
                      mode="ios"
                      slot="end"
                      color="primary"
                      size="large"
                      className="form-group__button"
                      onClick={() => setIsOpenAssignConfirm(true)}
                    >
                      Confirm
                    </IonButton>
                  </div>
                </IonCard>
              )}
              {assignmentDetails?.showDBC && (
                <IonCard>
                  <IonTitle>Day Before Event Confirmation</IonTitle>
                  <IonText>
                    Please confirm that you are 100% ready to go for tomorrow's
                    assignment.
                  </IonText>
                  <div className="assignment-view__action_btn">
                    <IonButton
                      mode="ios"
                      slot="start"
                      fill="outline"
                      size="large"
                      className="form-group__button"
                      onClick={() => setIsOpenAssignDecline(true)}
                      disabled={enabledChekinBtn}
                    >
                      Decline
                    </IonButton>
                    <IonButton
                      mode="ios"
                      slot="end"
                      color="primary"
                      size="large"
                      className="form-group__button"
                      onClick={() => submitDC(assignmentDetails.ue_id, "DBC")}
                      disabled={enabledChekinBtn}
                    >
                      Confirm
                    </IonButton>
                  </div>
                </IonCard>
              )}
              {assignmentDetails?.showDOC && (
                <IonCard>
                  <IonTitle>Day Of Event Confirmation</IonTitle>
                  <IonText>
                    Please confirm that you are all set for today's shift
                  </IonText>
                  <div className="assignment-view__action_btn">
                    <IonButton
                      mode="ios"
                      slot="start"
                      fill="outline"
                      size="large"
                      className="form-group__button"
                      onClick={() => setIsOpenAssignDecline(true)}
                      disabled={enabledChekinBtn}
                    >
                      Decline
                    </IonButton>
                    <IonButton
                      mode="ios"
                      slot="end"
                      color="primary"
                      size="large"
                      className="form-group__button"
                      onClick={() => submitDC(assignmentDetails.ue_id, "DOC")}
                      disabled={enabledChekinBtn}
                    >
                      Confirm
                    </IonButton>
                  </div>
                </IonCard>
              )}
              {assignmentDetails?.booked_talent_referral_link && (
                <IonCard className="booked-talent-referral-link">
                  <div className="booked-talent-referral-link__title">
                    {assignmentDetails?.booked_talent_referral_request_sent ==
                    "1"
                      ? "Last minute openings for your assignment"
                      : "Refer your friends"}
                  </div>
                  <IonText>
                    Share this link and earn up to{" "}
                    {assignmentDetails?.booked_talent_referral_points} points
                    for each friend that works with you! Invite your crew to
                    apply or invite new friends to join & apply!
                    <p className="booked-talent-referral-link__link">
                      {assignmentDetails?.booked_talent_referral_link}
                    </p>
                  </IonText>
                  <div className="assignment-view__action_btn">
                    <IonButton
                      mode="ios"
                      slot="start"
                      fill="outline"
                      size="large"
                      className="form-group__button"
                      onClick={() =>
                        copyBookedTalentReferralLink(
                          assignmentDetails?.booked_talent_referral_link
                        )
                      }
                    >
                      Copy Link
                    </IonButton>
                  </div>
                </IonCard>
              )}
              {!assignmentDetails ? (
                <IonSpinner name="dots"></IonSpinner>
              ) : (
                <>
                  {!assignmentDetails?.isInfluencer &&
                    assignmentDetails?.scheduleDates &&
                    assignmentDetails?.scheduleDates.map(
                      (event_time: any, index: number) => (
                        <IonItem
                          className="assignment-view__notice__timing"
                          key={index}
                        >
                          <img slot="start" alt="" src={CalendarIcon} />
                          <div>
                            <IonLabel className="assignment-view__notice__timing__date">
                              {event_time.ed_start_date}
                            </IonLabel>
                            <IonLabel className="assignment-view__notice__timing__time">
                              {event_time.ed_start_time} -{" "}
                              {event_time.ed_end_time}
                            </IonLabel>
                          </div>
                        </IonItem>
                      )
                    )}
                  {assignmentDetails?.isInfluencer &&
                    assignmentDetails?.scheduleDates && (
                      <IonItem className="assignment-view__notice__timing">
                        <img slot="start" alt="" src={CalendarIcon} />
                        <div>
                          <IonLabel className="assignment-view__notice__timing__date">
                            {assignmentDetails?.scheduleDates[0].ed_start_date}
                          </IonLabel>
                          <IonLabel className="assignment-view__notice__timing__time">
                            {assignmentDetails?.scheduleDates[0].ed_start_time}{" "}
                            - {assignmentDetails?.scheduleDates[0].ed_end_time}
                          </IonLabel>
                        </div>
                      </IonItem>
                    )}
                  <IonItem className="assignment-view__notice__timing">
                    {assignmentDetails?.ue_worked_hours_points_multiplier ==
                    2 ? (
                      <>
                        <img
                          className="assignment-view__notice__timing__points-multiplied"
                          alt=""
                          slot="start"
                          src={DoublePointsIcon}
                        />
                      </>
                    ) : (
                      <>
                        {assignmentDetails?.ue_worked_hours_points_multiplier ==
                        3 ? (
                          <>
                            <img
                              className="assignment-view__notice__timing__points-multiplied"
                              alt=""
                              slot="start"
                              src={TriplePointsIcon}
                            />
                          </>
                        ) : (
                          <>
                            <img alt="" slot="start" src={DollarSignIcon} />
                          </>
                        )}
                      </>
                    )}
                    <div>
                      <IonLabel className="assignment-view__notice__timing__date">
                        Estimated Total Pay
                      </IonLabel>
                      <IonLabel className="assignment-view__notice__timing__pay">
                        {assignmentDetails?.estPayoutStr}{" "}
                        {assignmentDetails?.estPointsStr && (
                          <span
                            className={
                              assignmentDetails?.ue_worked_hours_points_multiplier &&
                              assignmentDetails?.ue_worked_hours_points_multiplier >
                                1
                                ? "points-background"
                                : ""
                            }
                          >
                            {assignmentDetails?.estPointsStr}{" "}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonItem>
                  {assignmentDetails?.backupPoints && (
                    <IonItem className="assignment-view__notice__timing">
                      <div className="assignment-view__notice__backup-bonus-placeholder"></div>
                      <div>
                        <IonLabel className="assignment-view__notice__timing__date assignment-view__notice__timing__backup-bonus-container">
                          <span>Backup Point Bonus </span>
                          <IonIcon
                            icon={info_circle}
                            onClick={() =>
                              history.push(assignmentDetails?.backupFAQLink)
                            }
                            className="assignment-view__notice__timing__backup-info"
                          />
                        </IonLabel>
                        <IonLabel className="assignment-view__notice__timing__pay">
                          {assignmentDetails?.backupPoints}
                        </IonLabel>
                      </div>
                    </IonItem>
                  )}
                </>
              )}

              {/* <div className="assignment-view__notice__link">
              <a href="">
                Add to calendar &nbsp;
                <i className="faChevronRight-icon">{ChevronRightIcon}</i>
              </a>
            </div> */}
            </div>
            {assignmentDetails?.hasToTakeTests && (
              <div className="assignment-view__action_btn">
                <IonButton
                  expand="block"
                  mode="ios"
                  type="button"
                  className="form-group__button"
                  onClick={takeGradeTest}
                >
                  Take Test
                </IonButton>
              </div>
            )}
            {assignmentDetails?.canModelCheckIn && (
              <div className="assignment-view__action_btn">
                {/* <IonButton
                mode="ios"
                fill="outline"
                size="large"
                onClick={() => setIsOpenAssignDecline(true)}
              >
                Cancel
              </IonButton> */}
                <IonButton
                  mode="ios"
                  color="primary"
                  size="large"
                  expand="block"
                  className="form-group__button"
                  onClick={checkInHandler}
                  disabled={enabledChekinBtn}
                >
                  Check In
                </IonButton>
              </div>
            )}
            {assignmentDetails?.canModelCheckOut && (
              <div className="assignment-view__action_btn">
                {assignmentDetails?.cantakebreak && (
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    className="form-group__button"
                    disabled={enabledChekinBtn}
                    onClick={() => takeBreackHandler()}
                  >
                    Take Break
                  </IonButton>
                )}
                {assignmentDetails?.canresumework && (
                  <IonButton
                    mode="ios"
                    fill="outline"
                    size="large"
                    className="form-group__button"
                    disabled={enabledChekinBtn}
                    onClick={() => resumeWorkHandler()}
                  >
                    Return to Work
                  </IonButton>
                )}

                <IonButton
                  mode="ios"
                  color="primary"
                  size="large"
                  className="form-group__button"
                  onClick={() =>
                    checkhOutHandler(
                      assignmentDetails?.ue_force_recap_on_checkout
                    )
                  }
                  disabled={enabledChekinBtn}
                >
                  Check Out
                </IonButton>
              </div>
            )}
            <div className="assignment-view__location">
              <IonItem>
                <IonTitle slot="start">Location</IonTitle>
              </IonItem>
              {/* <div className="assignment-view__location__img">
              <img src={assignmentSliderPlaceholder} alt="" />
            </div> */}
              {!assignmentDetails ? (
                <IonSpinner name="dots"></IonSpinner>
              ) : (
                <>
                  <div className="assignment-view__location__add">
                    <IonText>{assignmentDetails?.ue_venue} </IonText>
                    <IonText>{`${assignmentDetails?.ue_street_address}, ${assignmentDetails?.ue_nearest_city}, ${assignmentDetails?.ue_state} ${assignmentDetails?.ue_zipcode}`}</IonText>

                    {assignmentDetails.google_map_link !== "" && (
                      <IonItem>
                        <img src={direactionIcon} alt="" />

                        <a
                          href={assignmentDetails.google_map_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get Direction
                        </a>
                      </IonItem>
                    )}
                  </div>
                </>
              )}
            </div>
            {assignmentDetails?.ue_meeting_area && (
              <div className="assignment-view__meeting">
                <IonTitle>Meeting Location</IonTitle>
                <IonItem>
                  {/* <img src={locationIcon} alt="" /> */}
                  <IonText>{assignmentDetails.ue_meeting_area}</IonText>
                </IonItem>
              </div>
            )}
            {(assignmentDetails?.ue_contact_phone ||
              assignmentDetails?.ue_contact_person) && (
              <div className="assignment-view__contact">
                <IonTitle>On-Site Contact</IonTitle>
                <br />
                <IonItem>
                  <img slot="start" alt="" src={ContactIcon} />
                  <div>
                    <IonLabel className="contact__name">
                      {assignmentDetails?.ue_contact_person}
                    </IonLabel>
                    <IonLabel className="contact__no">
                      {assignmentDetails?.ue_contact_phone}
                    </IonLabel>
                  </div>
                </IonItem>
              </div>
            )}
            {assignmentDetails?.ue_event_type && (
              <div className="assignment-view__types">
                <IonItem>
                  <IonTitle slot="start">Event Type</IonTitle>
                </IonItem>
                <IonItem>
                  {!assignmentDetails ? (
                    <IonSpinner name="dots"></IonSpinner>
                  ) : (
                    <IonBadge style={{ backgroundColor: "skyblue" }}>
                      {assignmentDetails.ue_event_type}
                    </IonBadge>
                  )}
                </IonItem>
              </div>
            )}

            {(assignmentDetails?.ue_talent_role ||
              (assignmentDetails?.event_documents &&
                assignmentDetails?.event_documents.length > 0)) && (
              <div className="assignment-view__doing">
                {assignmentDetails?.ue_talent_role && (
                  <>
                    <IonTitle>What you'll be doing</IonTitle>
                    <br />
                    <IonText>
                      {!assignmentDetails ? (
                        <IonSpinner name="dots"></IonSpinner>
                      ) : (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: assignmentDetails.ue_talent_role,
                            }}
                          ></div>
                        </>
                      )}
                    </IonText>
                  </>
                )}

                {assignmentDetails?.event_documents &&
                  assignmentDetails?.event_documents.length > 0 && (
                    <>
                      <br /> <br />
                      <IonLabel>View Training Doc here:</IonLabel>
                      {assignmentDetails.event_documents.map(
                        (doc: any, index: number) => (
                          <IonItem
                            key={index}
                            className="assignment-view__doing__doc"
                          >
                            <img slot="start" alt="" src={documentIcon} />

                            <IonLabel>{doc[0]}</IonLabel>

                            <a
                              href={doc[1]}
                              target="_blank"
                              rel="noreferrer"
                              slot="end"
                            >
                              <IonIcon
                                src={downloadICon}
                                className="assignment-view__doing__download"
                              />
                            </a>
                          </IonItem>
                        )
                      )}
                    </>
                  )}
              </div>
            )}
            {assignmentDetails?.products &&
              assignmentDetails?.products?.length > 0 && (
                <div className="assignment-view__doing">
                  <IonItem>
                    <IonTitle slot="start">Documents</IonTitle>
                  </IonItem>
                  {assignmentDetails.products.map((doc: any, index: number) => (
                    <IonItem
                      key={index}
                      className="assignment-view__doing__doc"
                    >
                      <img slot="start" alt="" src={documentIcon} />

                      <IonLabel>{doc?.cp_name}</IonLabel>

                      <a
                        href={doc?.cp_training_document}
                        target="_blank"
                        rel="noreferrer"
                        slot="end"
                      >
                        <IonIcon
                          src={downloadICon}
                          className="assignment-view__doing__download"
                        />
                      </a>
                    </IonItem>
                  ))}
                </div>
              )}

            {assignmentDetails?.attire_html && (
              <div className="assignment-view__attirs">
                <IonItem>
                  <IonTitle slot="start">Attire Requirements</IonTitle>
                </IonItem>
                <IonText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: assignmentDetails.attire_html,
                    }}
                  ></div>
                </IonText>
              </div>
            )}

            {assignmentDetails?.backupInstructions && (
              <div className="assignment-view__attirs">
                <IonItem>
                  <IonTitle slot="start">Backup Info</IonTitle>
                </IonItem>
                <IonText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: assignmentDetails.backupInstructions,
                    }}
                  ></div>
                </IonText>
              </div>
            )}

            {assignmentDetails?.requirements_html && (
              <div className="opportunity-view__requrement">
                <IonItem>
                  <IonTitle slot="start">Requirements</IonTitle>
                </IonItem>
                <IonText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: assignmentDetails.requirements_html,
                    }}
                  ></div>
                </IonText>
              </div>
            )}
            {/* <div className="assignment-view__parking">
            <IonTitle>Parking</IonTitle>
            <IonItem>
              <img src={locationIcon} alt="" />
              <a href=""> Lot Available</a>
            </IonItem>
          </div> */}

            {assignmentDetails?.ue_additional_details_html && (
              <div className="assignment-view__expect">
                <IonItem>
                  <IonTitle slot="start">Additional Details</IonTitle>
                </IonItem>
                <IonText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: assignmentDetails.ue_additional_details_html,
                    }}
                  ></div>
                </IonText>
              </div>
            )}

            {assignmentDetails?.ue_p_card_agreement_required == "1" && (
              <div className="assignment-view__expect">
                <IonItem>
                  <IonTitle slot="start">P-Card Instructions</IonTitle>
                </IonItem>
                <IonText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        assignmentDetails.ue_p_card_agreement_instructions_html,
                    }}
                  ></div>
                </IonText>
                {!modelPCardAgreementConfirmed && (
                  <>
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setIsOpenPCardAgreement(true)}
                      className="form-group__button assignment-view__p-card-required-bttn"
                    >
                      Complete P-Card Agreement
                    </IonButton>
                    <PCardAgreement
                      isOpen_modal_agreement={isOpen_PCardAgreement}
                      closeModalAgreement={close_PCardAgreement}
                    />
                  </>
                )}
                {modelPCardAgreementConfirmed && (
                  <p className="assignment-view__p-card-completed">
                    <img
                      className="assignment-view__p-card-completed-icon"
                      src={checkedIcon}
                      alt=""
                    />
                    <span>P-Card Agreement Completed</span>{" "}
                  </p>
                )}
              </div>
            )}

            {assignmentDetails?.requiredCustomerAgreementText !== "" && !modelCustomerAgreementConfirmed && (
              <div className="assignment-view__expect">
                <IonItem>
                  <IonTitle slot="start">Customer agreement</IonTitle>
                </IonItem>
                <IonText>
                  <div>
                    You must agree to the customer terms.
                  </div>
                </IonText>
                {!modelCustomerAgreementConfirmed && (
                  <>
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setIsOpenCustomerAgreement(true)}
                      className="form-group__button assignment-view__p-card-required-bttn"
                    >
                      View Agreement
                    </IonButton>
                    <CustomerAgreement
                      isOpen_modal_agreement={isOpen_CustomerAgreement}
                      closeModalAgreement={close_CustomerAgreement}
                      agreementText={assignmentDetails?.requiredCustomerAgreementText}
                      ueId={assignmentDetails?.ue_id}
                    />
                  </>
                )}
              </div>
            )}

            {assignmentDetails?.assignment_status === "Booked" &&
              (assignmentDetails?.ue_status === "Closed" ||
                assignmentDetails?.ue_status === "Live") && (
                <div className="assignment-view__photos">
                  <IonTitle>Photos</IonTitle>
                  {assignmentRecaptPhotos.length === 0 && (
                    <IonText>
                      You have no photos
                      {assignmentDetails?.require_recap ? " yet" : ""}.
                    </IonText>
                  )}
                  <Splide
                    options={{
                      arrows: false,
                      pagination: false,
                      perPage: 3,
                      gap: "5px",
                      fixedWidth: "none",
                      perMove: 1,
                      // type: "loop",
                    }}
                  >
                    {assignmentRecaptPhotos.length > 0 &&
                      assignmentRecaptPhotos.map((itm: any, index: any) => (
                        <SplideSlide key={index}>
                          <div className="assignment-view__photos_each">
                            <img src={`${itm.full_path}`} alt="" />
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                  {assignmentRecaptVideos.length > 0 && (
                    <>
                      <IonTitle className="assignment-view__videos__heading">
                        Videos
                      </IonTitle>
                      <Splide
                        options={{
                          arrows: false,
                          pagination: false,
                          perPage: 3,
                          gap: "5px",
                          fixedWidth: "none",
                          perMove: 1,
                          // type: "loop",
                        }}
                      >
                        {assignmentRecaptVideos.length > 0 &&
                          assignmentRecaptVideos.map((itm: any, index: any) => (
                            <SplideSlide key={index}>
                              <div className="assignment-view__photos_each">
                                <video controls>
                                  <source
                                    src={`${itm.full_path}`}
                                    type={`video/${itm.extension}`}
                                  />
                                  Video not supported.
                                </video>
                              </div>
                            </SplideSlide>
                          ))}
                      </Splide>
                    </>
                  )}
                  <IonButton
                    onClick={() => setShowRecapPhotoModal(true)}
                    mode="ios"
                    expand="block"
                    fill="outline"
                    className="assignment-view__upload_photos"
                  >
                    {" "}
                    <img alt="" src={upload_img} /> &nbsp; &nbsp;Upload Photos &
                    Videos
                  </IonButton>
                </div>
              )}
            {assignmentDetails?.assignment_status === "Booked" &&
              (assignmentDetails?.ue_status === "Closed" ||
                assignmentDetails?.ue_status === "Live") && (
                <div className="assignment-view__photos">
                  <IonTitle>Receipts</IonTitle>
                  {assignmentRecaptRecipt.length === 0 && (
                    <IonText>
                      You have no receipts
                      {assignmentDetails?.require_recap ? " yet" : ""}.
                    </IonText>
                  )}
                  <Splide
                    options={{
                      arrows: false,
                      pagination: false,
                      perPage: 3,
                      gap: "5px",
                      fixedWidth: "none",
                      perMove: 1,
                      // type: "loop",
                    }}
                  >
                    {assignmentRecaptRecipt.length > 0 &&
                      assignmentRecaptRecipt.map((itm: any, index: any) => (
                        <SplideSlide key={index}>
                          <div className="assignment-view__photos_each">
                            <img src={`${itm.full_path}`} alt="" />
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                  {assignmentDetails?.can_upload_receipts?.success && (
                    <IonButton
                      onClick={() => setShowRecapReceiptModal(true)}
                      mode="ios"
                      expand="block"
                      fill="outline"
                      className="assignment-view__upload_photos"
                    >
                      {" "}
                      <img alt="" src={upload_img} /> &nbsp; &nbsp;Upload
                      Receipts
                    </IonButton>
                  )}
                  {!assignmentDetails?.can_upload_receipts?.success && (
                    <p>
                      <IonText className="assignment-view__photos__receipts-not-uploadable">
                        {assignmentDetails.can_upload_receipts.message}
                      </IonText>
                    </p>
                  )}
                </div>
              )}

            {assignmentDetails?.showAssignmentCancellation && (
              <div className="assignment-view__cancel-assignment">
                <IonItem>
                  <IonTitle slot="start">Cancellation Policy</IonTitle>
                </IonItem>
                <IonText>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: assignmentDetails.cancellationPolicy,
                    }}
                  ></div>
                  <br />
                  <div>
                    To cancel an assignment, please contact your Booking Agent
                    first, then select the button below to begin the
                    cancellation form.
                  </div>
                </IonText>
                <br />
                <div className="assignment-view__cancel-assignment__cancel-button">
                  <IonButton
                    mode="ios"
                    size="large"
                    className="assignment-view__cancel-assignment__cancel-button-container__button"
                    onClick={() => setIsOpenAssignDecline(true)}
                  >
                    Cancel Assignment
                  </IonButton>
                </div>
              </div>
            )}

            {assignmentDetails?.failedAndNoRetriesLeft && (
              <p className="assignment-view__failed-test">
                Failed the test. You can't confirm, please contact your booking
                manager.
              </p>
            )}
            {assignmentDetails?.require_recap && (
              <div className="assignment-view__recap">
                <IonTitle>{assignmentDetails?.report_form_title}</IonTitle>
                <IonText>
                  {!recapEventData?.event?.recap_completed && (
                    <>
                      {assignmentDetails?.ue_is_in_store_report ? (
                        <>Please complete your {assignmentDetails?.report_form_title.toLowerCase()}.</>
                      ) : (
                        <>
                          Completion of this assignment requires a recap.{" "}
                          {!assignmentDetails?.ue_allow_complete_recap_if_live && (
                            <>The recap will become available after check-in.</>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {recapEventData?.event?.recap_completed && (
                    <>Thank you for submitting your recap.</>
                  )}
                </IonText>
                <div className="assignment-view__submit-btn">
                  <IonButton
                    expand="block"
                    mode="ios"
                    type="submit"
                    className="form-group__button"
                    onClick={accessRecapHandler}
                  >
                    {+assignmentDetails?.ue_is_in_store_report ? (
                      <>Fill Report</>
                    ) : (
                      <>Access Recap</>
                    )}
                  </IonButton>
                </div>
              </div>
            )}
          </div>
        )}
      </IonContent>
      {assignmentDetails && (
        <>
          <AssignmentDecline
            assign_event_name={assignmentDetails?.ue_name}
            assign_event_start_date={
              assignmentDetails?.eventtime[0]?.ed_start_date
            }
            isopen_assign_decline={isOpenAssignDecline}
            closeAssignDecline={closeAssignDecline}
          />
          {isOpenCheckin && (
            <AssignmentCheckin
              isopen_checkin={isOpenCheckin}
              closeCheckin={closeCheckin}
              checkin_event_id={assignmentDetails?.ue_id}
              latitude={coordinates.latitude}
              longitude={coordinates.longitude}
            />
          )}
        </>
      )}

      {assignmentDetails && assignmentDetails.eventtime && (
        <AssignmentConfirm
          isopen_assign_confirm={isOpenAssignConfirm}
          closeAssignConfirm={closeAssignConfirm}
          assign_event_name={assignmentDetails?.ue_name}
          assign_date={assignmentDetails?.eventtime[0]?.ed_start_date}
          assign_time={assignmentDetails?.eventtime[0]?.ed_start_time}
          assign_talent_role={assignmentDetails?.ue_talent_role}
          assign_id={assignmentDetails?.ue_id}
          assign_p_card_required={
            assignmentDetails?.ue_p_card_agreement_required
          }
          assign_p_card_instructions={
            assignmentDetails?.ue_p_card_agreement_instructions_html
          }
          backup_type={assignmentDetails?.backupTypeInt}
          backup_type_str={assignmentDetails?.backupType}
          backup_instructions={assignmentDetails?.backupInstructions}
          requiredCustomerAgreementText={assignmentDetails?.requiredCustomerAgreementText}
        />
      )}

      <AssignmentRecapSuccess
        isopen_assign_recap_success={isOpenAssignRecapSuccess}
        closeAssignRecapSuccess={closeAssignRecapSuccess}
      />
      {/* checin api success message */}
      <IonAlert
        isOpen={checkinConfirmed.show}
        mode="ios"
        onDidDismiss={() =>
          setCheckinConfirmed({ ...checkinConfirmed, show: false })
        }
        header={"Check In is successful"}
        message={checkinConfirmed.message}
        buttons={[
          {
            text: "CONTINUE",
            handler: () => {},
          },
        ]}
      />
      <Loading showLoading={waitingForApiResonse} />

      {assignmentDetails?.assignment_status === "Booked" &&
        (assignmentDetails?.ue_status === "Closed" ||
          assignmentDetails?.ue_status === "Live") && (
          <>
            <AssignmentRecapReceipts
              recapEventData={recapEventData}
              recap_receipt_show={showRecapReceiptModal}
              recapReceiptClosed={closeRecapReceiptModal}
              recap_receipt_ue_id={assignmentDetails.ue_id}
              recap_receipt_ue_name={assignmentDetails.ue_name}
              recap_receipt_ue_status={assignmentDetails.ue_status}
              recap_receipt_ue_customer_name={
                assignmentDetails.customer_company
              }
              recap_receipt_ue_customer_image={
                recapEventData?.event?.customer_logo
              }
              recap_receipt_ue_rf_id={assignmentDetails?.ue_rf_id}
            />

            {showRecapPhotoModal && (
              <UploadEventPhotosWithoutRecap
                photo_test_show={showRecapPhotoModal}
                photoTestClosed={closeRecapPhotoModal}
                photo_ue_id={assignmentDetails.ue_id}
                photo_ue_name={assignmentDetails.ue_name}
                photo_ue_status={assignmentDetails.ue_status}
                photo_ue_customer_name={assignmentDetails.customer_company}
                photo_ue_customer_image={recapEventData?.event?.customer_logo}
                recap_existing_photo={recapEventData?.images}
                recap_existing_video={recapEventData?.videos}
              />
            )}
          </>
        )}
    </IonPage>
  );
};

export default AssignmentView;

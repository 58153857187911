import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonBackButton,
} from "@ionic/react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import "./AddSocialPlatforms.css";
import back_arrow from "../../assets/images/common/arrow-left.png";
import { saveInfluencerSocialMediaPlatforms } from "../../data-services/signup";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import { authActions } from "../../store/auth";

type ModalProps = {
  isOpenPlatformModal: boolean;
  closePlatformModal: Function;
  updateInfo: any;
  orignalApiObject: any;
};

const AddSocialPlatforms: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const {
    isOpenPlatformModal,
    closePlatformModal,
    updateInfo,
    orignalApiObject,
  } = props;

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      platfrom_url: "",
      platform_followers: "",
    },
    mode: "onBlur",
  });
  const formSubmitHandler = async () => {
    let all_platform = orignalApiObject;
    for (let index = 0; index < all_platform.length; index++) {
      const element = all_platform[index];
      if (updateInfo.id == element.smp_id && updateInfo.type === "connect") {
        element.msmp_url = getValues("platfrom_url");
        element.msmp_followers_number = getValues("platform_followers");
      }
    }
    let api_res = await saveInfluencerSocialMediaPlatforms(all_platform);
    if (api_res?.success) {
      closePlatformModal();
    } else {
      if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.data?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };

  useEffect((): any => {
    setValue("platfrom_url", "");
    setValue("platform_followers", "");
  }, [updateInfo]);

  return (
    <IonModal isOpen={isOpenPlatformModal} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonTitle>{updateInfo?.title ? updateInfo.title : "Add Platforms"}</IonTitle>
          <IonButtons slot="start" onClick={() => closePlatformModal()}>
            <IonButton>
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          onSubmit={handleSubmit(formSubmitHandler)}
          className="platform_container"
        >
          <div className="form-group">
            <IonLabel className="form-group__label">URL</IonLabel>
            <input
              type="text"
              placeholder="Type complete url"
              className={
                errors.platfrom_url !== undefined
                  ? "form-input-styles-error"
                  : "form-group__input-styles"
              }
          
              {...register("platfrom_url", {
                required: "This field is required.",
              })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="platfrom_url"
              as={<div className="error-message" />}
            />
          </div>
          <div className="form-group">
            <IonLabel className="form-group__label">Followers</IonLabel>
            <input
              type="number"
              placeholder="Followers"
              className={
                errors.platform_followers !== undefined
                  ? "form-input-styles-error"
                  : "form-group__input-styles"
              }
       
              {...register("platform_followers", {
                required: "This field is required.",
              })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="platform_followers"
              as={<div className="error-message" />}
            />
          </div>
          <div className="platform_container__btn">
            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button"
            >
              Submit
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default AddSocialPlatforms;

/** */
import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonRow,
  IonCard,
  IonCol,
  IonGrid,
  IonAvatar,
  IonIcon,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import "./Favourite.css";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import { modelPointsList } from "../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import Loading from "../../push-components/loading/Loading";
import plus_circle_orange from "../../assets/images/common/plus_circle_orange.png";
import customer_icon from "../../assets/images/common/customer.png";
import InfoButtonPopover from "../../push-components/InfoButtonPopover/InfoButtonPopover";
import introVideo_icon from "../../assets/images/common/intro-video.png";

import { personCircle } from "ionicons/icons";

const MyPoints: React.FC = () => {
  const dispatch = useDispatch();
  const [talentPointList, setTalentPointList] = useState<any>([]);
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<string>("");

  useEffect(() => {
    (async () => {
      setWaitingForApiResonse(true);
      let api_res = await modelPointsList();
      if (api_res?.success) {
        if (api_res.data.model_points) {
          setTalentPointList(api_res.data.model_points);
        }
      } else if (api_res?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_res?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_res?.data?.message);
      } else if (api_res?.message) {
        showToastMessage(api_res.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
      setWaitingForApiResonse(false);
    })();
  }, []);

  const clickInfoButtonHandler = () => {
    setShowInfo("home_points");
  };

  return (
    <IonPage>
      <SimpleHeaderView
        title="My Points"
        bgcolor="primary"
        buttonType="undefined"
        clickInfoButtonHandler={clickInfoButtonHandler}
      ></SimpleHeaderView>
      <IonContent>
        <div className="my_point__content">
          {talentPointList.length > 0 &&
            talentPointList.map((point: any, index: number) => (
              <IonCard className="my_point__card" key={index}>
                <IonGrid className="my_point__card__grid">
                  <IonRow>
                    <IonCol
                      className="my_point__card__grid__photo__container"
                      sizeSm="1"
                      sizeXs="2"
                    >
                      {point?.has_photo && (
                        <div className="my_point__card__grid__photo--actual">
                          {" "}
                          <IonAvatar className="my_point__card__grid__photo">
                            <img src={point.photo} alt="" />
                          </IonAvatar>
                        </div>
                      )}
                      {!point?.has_photo && (
                        <>
                          {(point.type != "Customer" && point.type != "talent-intro-video") && (
                            <div className="my_point__card__grid__photo--placeholder">
                              <IonIcon icon={personCircle} />
                            </div>
                          )}

                          {point.type == "Customer" && (
                            <div className="my_point__card__grid__photo--actual">
                              {" "}
                              <IonAvatar className="my_point__card__grid__photo my_point__card__grid__photo--customer">
                                <img src={customer_icon} alt="" />
                              </IonAvatar>
                            </div>
                          )}

                          {point.type == "talent-intro-video" && (
                            <div className="my_point__card__grid__photo--actual">
                              {" "}
                              <IonAvatar className="my_point__card__grid__photo my_point__card__grid__photo--intro-video">
                                <img src={introVideo_icon} alt="" />
                              </IonAvatar>
                            </div>
                          )}
                        </>
                      )}
                    </IonCol>
                    <IonCol sizeSm="11" sizeXs="10">
                      <IonGrid className="my_point__card__grid">
                        <IonRow>
                          <IonCol size="12">
                            <div className="my_point__card__name">
                              {point.name}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <div className="my_point__card__date">
                              {point.date}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <div
                              className={
                                point.points != "Pending"
                                  ? "my_point__card__point"
                                  : ""
                              }
                            >
                              {point.points}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <div className="my_point__card__status">
                              {point.status}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <div className="my_point__card__time"></div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ))}
          {talentPointList.length == 0 && (
            <EmptyScreen title="No Points" description="" />
          )}
        </div>
      </IonContent>
      {showInfo === "home_points" && (
        <InfoButtonPopover
          infoButtonClass={"home_points"}
          updatePopoverState={setShowInfo}
        ></InfoButtonPopover>
      )}
      <Loading showLoading={waitingForApiResonse} />
    </IonPage>
  );
};

export default MyPoints;

import React from "react";
import { useParams } from "react-router";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import "./BecomeAmbassador.css";
import check_icon from "../../assets/images/common/checked.png";
import push_ambassador from "../../assets/images/push-ambassador/psuh-ambassador.png";

const BecomeAmbassador: React.FC = () => {
  const url_params: any = useParams();
  return (
    <IonPage>
      <IonContent>
        <section className="ambassador">
          <div className="ambassador__image">
            <img src={push_ambassador} alt="" />
          </div>

          <div className="ambassador__title">
            <h1>
              Become a<br />
              <span>PUSH Ambassador</span>
              <br />
              today!
            </h1>
          </div>
          <div className="ambassador__description">
            Unlock more higher paying opportunities by becoming a
            <span> PUSH Ambassador</span> today!
          </div>
          <div className="ambassador__additional-info-text">
            <p>Fill out additional information to apply for:</p>
            <div className="ambassador__additional-info">
              <ul>
                <li>
                  <img src={check_icon} alt="" /> Street Teams
                </li>
                <li>
                  <img src={check_icon} alt="" /> Trade Shows
                </li>
                <li>
                  <img src={check_icon} alt="" /> Demos
                </li>
                <li>
                  <img src={check_icon} alt="" /> Special Events
                </li>
              </ul>
            </div>
          </div>
        </section>
        <div className="ambassador__buttons">
          <IonButton
            className="form-group__button"
            expand="block"
            mode="ios"
            type="button"
            routerLink={`/AmbassadorForm/${url_params?.talent_signup_id}`}
          >
            Get Started
          </IonButton>
          {/* routerLink={`/BecomeModel/${url_params?.talent_signup_id}` */}
          <IonButton
            className="form-group__button"
            fill="clear"
            expand="block"
            mode="ios"
            type="button"
            href={`/Home`}
          >
            Not Now
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BecomeAmbassador;

import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonLabel,
  IonCheckbox,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import "./PCardAgreement.css";
import { set, get } from "../../../../storage/storage";
import {
  showToastMessage,
  noInternetResponse,
} from "../../../../common/common";
import back_arrow from "../../../../assets/images/common/arrow-left.png";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../store/auth";
import { Network } from "@capacitor/network";
import { CapacitorHttp } from '@capacitor/core';

type ModalProps = {
  isOpen_modal_agreement: boolean;
  closeModalAgreement: Function;
};

const PCardAgreement: React.FC<ModalProps> = (props) => {
  const dispatch = useDispatch();
  const { isOpen_modal_agreement, closeModalAgreement } = props;
  const [agreementchecked, setAgreementchecked] = useState<any>(false);
  const [isrequired, setIsrequired] = useState(false);
  const [model_fname, setModelFname] = useState("");
  const [model_lname, setModelLname] = useState("");
  const [model_state, setModelState] = useState("");
  const [company, setCompany] = useState("");

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
    },
    mode: "onBlur",
  });
  const submitAgreement = async () => {
    let isChecked = agreementchecked == true ? 1 : 0;
    if (isChecked == 1) {
      setIsrequired(false);
      let response;
      let networkStatus = (await Network.getStatus()).connectionType;
      if (networkStatus !== "none") {
        const authToken = await get("login_user_token");
        if (!authToken) {
          dispatch(authActions.logout());
          return;
        }
        let url =
          `${process.env.REACT_APP_HP_API_URL}/userEvent/savePCardAgreement?token=` +
          authToken;
        const options = {
          url: url,
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ` + authToken,
          },
          data: {},
        };
        response = await CapacitorHttp.post(options);
        response = response.data;
      } else {
        response = noInternetResponse;
      }
      if (response?.success) {
        await showToastMessage(response.message);
        await set("model_p_card_agreement_confirmed", 1);
        closeModalAgreement(true);
      }
    } else {
      setIsrequired(true);
    }
  };

  useEffect((): any => {
    let isSubscribed = true;
    (async () => {
      setModelFname(await get("login_model_first_name"));
      setModelLname(await get("login_model_last_name"));
      setModelState(await get("login_model_state"));
      setCompany(await get("login_model_state") == "CA" ? "Placement" : "Agency");
    })();
    return () => (isSubscribed = false);
  }, []);

  const currentDate = new Date();
  const month = currentDate.toLocaleString('default', { month: 'short' });
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  return (
    <IonModal isOpen={isOpen_modal_agreement} mode="ios">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                closeModalAgreement(false);
              }}
            >
              <img src={back_arrow} alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="push-aggrement-view">
        <div className="push-aggrement-view">
          <div className="push-aggrement-view__main-header">
            {model_state !== "CA" && (
              <h1>PUSH Agency P-Card Agreement</h1>
            )}
            {model_state == "CA" && (
              <h1>PUSH Placement P-Card Agreement</h1>
            )}
          </div>
          <div className="push-aggrement-view__paragraph">
            <p>
              Hi! Thank you for working on behalf of The PUSH {company} ("PUSH").
              To successfully complete your role in this campaign, we are
              providing a Purchase Card (“P-card”) with which to make the
              necessary purchases.
            </p>
          </div>
          <div className="push-aggrement-view__header">
            <p> The PUSH {company} P-Card Guidelines</p>
          </div>
          <div className="push-aggrement-view__paragraph">
            <p>
              {" "}
              By use of the provided P-Card, you agree to the following terms:
              <ul>
                <li>
                  The P-Card is to be used only for the purchase of approved
                  supplies as outlined in your booking instructions.
                </li>
                <li>
                  The card (or card information if a virtual card is provided)
                  must be maintained with the highest level of security. If the
                  card is lost or stolen, or if you suspect the card or account
                  number has been compromised, you agree to immediately notify
                  your PUSH booking agent. Oral notification must be followed by
                  written confirmation.
                </li>
                <li>
                  You agree to cease use of the card upon completion of your
                  assigned responsibilities for this campaign or should your
                  participation in the campaign be terminated.
                </li>
                <li>
                  Misuse of the card—including but not limited to personal use,
                  and/or unauthorized use and/or fraudulent use—will result in
                  disciplinary action.
                </li>
              </ul>
              <strong>Right Of Offset: The PUSH {company} may reduce the amount of any
              payment otherwise payable to you by the amount of any charges not
              approved by PUSH or deemed to be relating to the responsibilities
              of your role. Your signature below indicates your consent to such
              reduction.</strong>
            </p>
          </div>
          <div className="push-aggrement-view__paragraph">
            <p>
              Please contact your booking agent when you receive your card in
              the mail. We will activate the card for your use. DO NOT call the
              number that comes with the card.{" "}
            </p>
          </div>
          <div className="push-aggrement-view__paragraph">
            <p>
              These cards do have a set limit and if you try to use it for over
              that limit, it will decline.
              <ul>
                <li>
                  If, due to insufficient amount of funds loaded onto the card
                  (unforeseen expenses which must be approved in advance) your
                  booking agent may increase the debit card allowance as
                  applicable or communicate a reduced purchase amount .
                </li>
                <li>
                  The P-Card will not process if you attempt to exceed the set
                  purchase amount.
                </li>
              </ul>
            </p>
            <div className="push-aggrement-view__paragraph">
              <p className="push-aggrement-view__paragraph__signature">
                <div>
                  <div>{model_fname} {model_lname}</div> <hr /> <div className="push-aggrement-view__paragraph__signature__label">Printed Name</div>
                </div>
                <div>
                  <div>{model_fname} {model_lname}</div> <hr /> <div className="push-aggrement-view__paragraph__signature__label">Signature</div>
                </div>
                <div>
                  <div>{month} {day}, {year}</div> <hr /> <div className="push-aggrement-view__paragraph__signature__label">Date</div>
                </div>
              </p>
            </div>
          </div>
          <div className="push-aggrement-view__section-footer">
            <div className="push-aggrement-view__mobile_terms">
              <div>
                <IonCheckbox
                  className={
                    isrequired === true
                      ? "push-agreement-view__agreecheck push-aggrement-check_required-field"
                      : "push-agreement-view__agreecheck"
                  }
                  mode="md"
                  onIonChange={(e) => setAgreementchecked(e.detail.checked)}
                ></IonCheckbox>
              </div>
              <div>
                <IonLabel>
                  I agree to the PUSH Agency P-Card Guidelines
                </IonLabel>
                <br />
              </div>
            </div>
            <IonButton
              expand="block"
              mode="ios"
              type="submit"
              className="form-group__button section-next-button"
              onClick={submitAgreement}
            >
              Submit
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default PCardAgreement;

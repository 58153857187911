/** */
import React from "react";

import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
} from "@ionic/react";
import "./PaidRevenue.css";

type EventRevenu = {
  payout_str: string;
  work_hours?: string;
  adjustments: any;
  event_name: string;
  date: string;
  time: string;
  pay_date: string;
};

const EventRevenueCard: React.FC<EventRevenu> = (props) => {
  const {
    payout_str,
    work_hours,
    adjustments,
    event_name,
    date,
    time,
    pay_date,
  } = props;

  return (
    <IonCard className="paind-revenue-card">
      <IonGrid>
        <IonRow>
          <IonCol size="6" className="paind-revenue-card__dates">
            <h1>{event_name}</h1>
            <IonLabel className="paind-revenue-card__dates__date">
              {date}
            </IonLabel>
            <IonLabel className="paind-revenue-card__dates__time">
              {time}
            </IonLabel>
          </IonCol>
          <IonCol size="6" className="paind-revenue-card__amounts">
            <h1>{payout_str}</h1>
            <IonLabel className="paind-revenue-card__amounts__date">
              {pay_date}
            </IonLabel>
            {adjustments.length > 0 && adjustments.map((adj:any,index:number)=>
              <IonLabel className="paind-revenue-card__amounts__amount" key={index}>
             {adj.option_name} : {adj.total_str}
            </IonLabel>
            )}
            
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default EventRevenueCard;

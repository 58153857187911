import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonIcon,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonButton,
} from "@ionic/react";
import {
  swapVerticalOutline
} from "ionicons/icons";
import "./Assignments.css";
import { getModelUpcomingevents, logError } from "../../data-services/assignmets";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import EventCardList from "../../push-components/EventCardList/EventCardList";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import { async } from "@firebase/util";
import Loading from "../../push-components/loading/Loading";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import event_placeholder from "../../assets/images/common/event_placeholder_img.jpg";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  offlineErrorCode,
  unauthorizedErrorCode,
  unauthorizedErrMsg
} from "../../common/common";
const Assignments: React.FC = () => {
  const dispatch = useDispatch();
  const [assignmentTabBtn, setAssignmentTabBtn] =
    useState<string>("onlyUpcoming");
  const [assignmentApiRes, setAssignmentApiRes] = useState<any>([]);
  const [sortAssignmentBy, setSortAssignmentBy] = useState<string>("recent");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const assignmentTabHandler = async (tab: string) => {
    setAssignmentTabBtn(tab);
    await getTalentUpcomingEvents(`&${tab}=1&order_by=${sortAssignmentBy}`);
  };

  const getTalentUpcomingEvents = async (filter_type: string = "") => {
    setShowLoader(true);
    try {
      let api_response = await getModelUpcomingevents(filter_type);
      if (api_response?.success) {
        setAssignmentApiRes(api_response.data?.userEvent);
      } else if (api_response?.data?.errorCode == offlineErrorCode) {
        showToastMessage(api_response?.data?.message);
      } else if (api_response?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (api_response?.message) {
        showToastMessage(api_response.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignments - getTalentUpcomingEvents - Exception thrown",
          "Talent App - Assignments",
          JSON.stringify(e.message)
        )
        
      }
      setShowLoader(false);
    }
    setShowLoader(false);
  };
  const assignmentSortingHandler = async (sort_by: string) => {
    setSortAssignmentBy(sort_by);
    await getTalentUpcomingEvents(`&${assignmentTabBtn}=1&order_by=${sort_by}`);
  };
  const searchByTextHandler = async (search_text: string) => {
    await getTalentUpcomingEvents(
      `&${assignmentTabBtn}=1&order_by=${sortAssignmentBy}&search_name=${search_text}`
    );
  };
  const reInit = async () => {
    await getTalentUpcomingEvents(
      `&${assignmentTabBtn}=1&order_by=${sortAssignmentBy}`
    );
  };
  useEffect(() => {
    (async () => {
      await getTalentUpcomingEvents(
        `&${assignmentTabBtn}=1&order_by=${sortAssignmentBy}`
      );
    })();
  }, []);
  return (
    <IonPage>
      <SimpleHeaderView
        title="Assignments"
        bgcolor="primary"
        buttonType="search"
        clickEventHandler={() => {}}
        onTextChange={(val: any) => {
          searchByTextHandler(val);
        }}
        searchPlaceholder="Search Assignment"
      ></SimpleHeaderView>
      <IonContent className="assignment" fullscreen>
        <IonHeader>
          <div className="assignmet__tabs">
            <IonButton
              fill="clear"
              className={
                assignmentTabBtn === "onlyUpcoming"
                  ? "assignmet__tab assignmet__tab__active"
                  : "assignmet__tab"
              }
              onClick={() => assignmentTabHandler("onlyUpcoming")}
            >
              Upcoming
            </IonButton>
            <IonButton
              fill="clear"
              className={
                assignmentTabBtn === "pendingReport"
                  ? "assignmet__tab assignmet__tab__active"
                  : "assignmet__tab"
              }
              onClick={() => assignmentTabHandler("pendingReport")}
            >
              Recap
            </IonButton>
            <IonButton
              fill="clear"
              className={
                assignmentTabBtn === "filledReport"
                  ? "assignmet__tab assignmet__tab__active"
                  : "assignmet__tab"
              }
              onClick={() => assignmentTabHandler("filledReport")}
            >
              Completed
            </IonButton>
          </div>
        </IonHeader>

        <div className="assignment__content">
          <div className="assignment__filters">
            <div className="assignment__filter">
              <IonIcon
                slot="icon-only"
                icon={swapVerticalOutline}
                className="assignment__filter__icon"
              />
              <IonLabel className="assignment__filter__lable">
                Sort by :
              </IonLabel>
              <IonSelect
                value={sortAssignmentBy}
                onIonChange={(e: any) =>
                  assignmentSortingHandler(e.detail.value)
                }
                className="assignment__filter__select"
              >
                <IonSelectOption value="recent">Recents</IonSelectOption>
                <IonSelectOption value="older">Older</IonSelectOption>
              </IonSelect>
            </div>
            {/* <IonButton fill="clear" className="assignment__filter__btn">
              <IonIcon icon={funnelOutline} />
            </IonButton> */}
          </div>
          {assignmentApiRes && assignmentApiRes.length !== 0 && (
            <div className="assignment__event-list">
              {assignmentApiRes.map((assignment: any, index: number) => (
                <EventCardList
                  key={index}
                  card_event_type="assignments"
                  card_event_route_to={`/AssignmentView/${assignment.ue_id}`}
                  card_miles_badge={
                    assignment.distanceMiles
                      ? `${Math.floor(assignment.distanceMiles)} Miles Away`
                      : ""
                  }
                  card_event_image={
                    assignment.bannerPath == 0
                      ? event_placeholder
                      : assignment.bannerPath
                  }
                  card_event_date={assignment?.startDateUI.toUpperCase()}
                  card_event_title={assignment.ue_name}
                  card_event_address={`${
                    assignment.ue_nearest_city !== "" &&
                    `${assignment.ue_nearest_city},`
                  } ${assignment.ue_state} ${assignment.ue_zipcode}`}
                  card_event_payment={`${assignment.estPayoutStr}`}
                  card_event_points={`${assignment.estPointsStr}`}
                  card_event_points_multiplier={`${assignment?.ue_worked_hours_points_multiplier}`}
                  event_action_detail={{
                    event_id: assignment.ue_id,
                    event_start_date: assignment?.eventtime[0]?.ed_start_date,
                    event_start_time: assignment?.eventtime[0]?.ed_start_time,
                    event_end_time: assignment?.eventtime?.length
                      ? assignment.eventtime[assignment.eventtime.length - 1]
                          .ed_end_time
                      : null,
                    talent_role: assignment.ue_talent_role,
                    force_recap_on_checkout:
                      assignment.ue_force_recap_on_checkout,
                    showConfirmation: assignment?.showConfirmation,
                    showDBC: assignment?.showDBC,
                    showDOC: assignment?.showDOC,
                    hasToTakeTests: assignment?.hasToTakeTests,
                    canModelCheckIn: assignment?.canModelCheckIn,
                    canModelCheckOut: assignment?.canModelCheckOut,
                    canresumework: assignment?.canresumework,
                    cantakebreak: assignment?.cantakebreak,
                    require_recap: assignment?.require_recap,
                    ue_p_card_agreement_required:
                      assignment?.ue_p_card_agreement_required,
                    ue_p_card_agreement_instructions_html:
                      assignment?.ue_p_card_agreement_instructions_html,
                    ue_worked_hours_points_multiplier:
                      assignment?.ue_worked_hours_points_multiplier,
                    backupTypeInt: assignment?.backupTypeInt,
                    backupType: assignment?.backupType,
                    backupInstructions: assignment?.backupInstructions,
                    autoBookEnabled: assignment?.autoBookEnabled,
                    ue_is_in_store_report: assignment?.ue_is_in_store_report,
                    requiredCustomerAgreementText: assignment?.requiredCustomerAgreementText
                  }}
                  card_auto_book_enabled={assignment?.autoBookEnabled}
                  reinit={reInit}
                />
              ))}
            </div>
          )}
          {!assignmentApiRes && (
            <EmptyScreen title="No Assignments" description="" />
          )}
        </div>
        <Loading showLoading={showLoader} />
      </IonContent>
    </IonPage>
  );
};

export default Assignments;

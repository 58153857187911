import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonButton } from "@ionic/react";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";

import { useHistory, useParams } from "react-router";
import "./Notifications.css";
import notification_bell from "../../assets/images/notification/notificaton-bell.png";
import { showToastMessage } from "../../common/common";
import { CapacitorHttp } from '@capacitor/core';
import { get } from "../../storage/storage";

const Notifcations: React.FC = () => {
  const url_params: any = useParams();
  const nullEntry: any[] = [];
  const history = useHistory()
  const [notifications, setnotifications] = useState(nullEntry);
  const [pushToken, setPushToken] = useState<Token>();
  
  const checkPushNotifications = async () => {
    if (!process.env?.REACT_APP_IS_NATIVE) {
        return;
    }

    const permissions = await PushNotifications.checkPermissions();
    if (permissions.receive === 'granted') {
        return;
    }

    // const sawThePopup = await get("dontWantPushNotifications");
    // if (sawThePopup) {
    //     return;
    // }
    // setShowPushNotificationsPopup(true);
  }

  const pushNotificationsNoThanksAction = () => {
    // setShowPushNotificationsPopup(false);
    // set("dontWantPushNotifications", true);
  }

  const pushNotificationsContinueAction = async () => {
    // if (!process.env?.REACT_APP_IS_NATIVE) {
    //   return;
    // }
    
    const registerForPush = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            async (token: Token) => {
                const url = `${process.env.REACT_APP_HP_API_URL}/userEvent/addFirebasePushNotificationToken`;
                const options = {
                    url: encodeURI(url),
                    headers: {
                       "Content-Type": "application/json",
                        token: await get("login_user_token"),
                    },
                    data: {pushNotificationToken: token?.value}
                };
                const response = await CapacitorHttp.post(options);
            }
        );

        // Some issue with our setup and push will not work
        // PushNotifications.addListener('registrationError',
        //     (error: any) => {
        //         alert('Error on registration: ' + JSON.stringify(error));
        //     }
        // );

        history.push(`/BecomeAmbassador/${url_params?.talent_signup_id}`)
    }

    const permissions = await PushNotifications.checkPermissions();

    if (permissions.receive === 'granted') {
        registerForPush();
    } else {
        let res = await PushNotifications.requestPermissions();
        if (res.receive === 'denied') {
            // setHasPushNotificationsDisabled(true);
            // set("dontWantPushNotifications", true);
            // Leave the popup open, new text will appear
            // return;

            // Proceed to the next registration step (today). Pass it as a prop in the future.
            history.push(`/BecomeAmbassador/${url_params?.talent_signup_id}`)
        }
        else {
            registerForPush();
        }
    }

    // setShowPushNotificationsPopup(false);
  }

  return (
    <IonPage>
      <IonContent>
        <div className="section-container">
          <section className="nofitication">
            <div className="nofitication__image">
              <img src={notification_bell} alt="" />
            </div>

            <div className="nofitication__title">
              <h1>Notifications</h1>
            </div>
            <div className="nofitication__description">
              We share new jobs via notificatons, so please ensure that you
              Allow notifications.
            </div>

            <div className="section-footer nofitication__buttons">
              <IonButton
                className="form-group__button"
                expand="block"
                mode="ios"
                type="button"
                onClick={pushNotificationsContinueAction}
              >
                Allow Notifications
              </IonButton>
              <IonButton
                className="form-group__button"
                fill="clear"
                expand="block"
                mode="ios"
                type="button"
                routerLink={`/BecomeAmbassador/${url_params?.talent_signup_id}`}
              >
                Deny
              </IonButton>
            </div>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notifcations;
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonSpinner,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import "./ExternalTalentReferrals.css";

import backArrow from "../../../assets/images/common/arrow-left-white.png";
import referralStars from "../../../assets/images/refer-friend/referral-stars.svg";
import { get } from "../../../storage/storage";

import { Share } from "@capacitor/share";
import { logError } from "../../../data-services/assignmets";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../../common/common";
import { authActions } from "../../../store/auth";
import ExternalTalentReferrals from "./ExternalTalentReferrals";
import { getAvailableReferralCampaign } from "../../../data-services/home-api";

type ModalProps = {
  closeModalHandler?: Function;
  isOpenNewReferralModalOptions?: any;
  setIsWidgetLoading?: Function
};

const ExternalTalentReferralsOptions: React.FC<ModalProps> = (Props) => {
  const dispatch = useDispatch();
  const { closeModalHandler, isOpenNewReferralModalOptions, setIsWidgetLoading } = Props;
  const [modelUserId, setModelUserId] = useState<any>(null);
  const [defaultExternalReferralPoints, setDefaultExternalReferralPoints] = useState<any>(0);
  const [externalReferralPoints, setExternalReferralPoints] = useState<any>(0);
  const [referralCampaign, setReferralCampaign] = useState<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isOpenExternalReferralModal, setIsOpenExternalReferralModal] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setModelUserId(await get("login_user_id"));
      setDefaultExternalReferralPoints(await get("login_defaultExternalReferralPoints"));
      let availableReferralCampaign = await getAvailableReferralCampaign();
      if (availableReferralCampaign?.success) {
        setReferralCampaign(availableReferralCampaign?.data?.availableReferralCampaign);
        setExternalReferralPoints(availableReferralCampaign?.data?.referralPoints);
      } else if (
        availableReferralCampaign?.data?.errorCode == unauthorizedErrorCode
      ) {
        dispatch(authActions.logout());
        return;
      } else if (availableReferralCampaign?.data?.errorCode == offlineErrorCode) {
        showToastMessage(availableReferralCampaign?.data?.message);
      } else if (availableReferralCampaign?.message) {
        showToastMessage(availableReferralCampaign.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }

      setLoaded(true);
      if(setIsWidgetLoading){
        setIsWidgetLoading(false);
      }
      

    })();
  }, []);

  /**
   * My Contacts - Create a list of contacts, grouped by their starting letters
   * @param contacts
   */
  const shareReferralLink = async () => {
    try {
      // Specify the link, text, and title for sharing
      const shareOptions = {
        dialogTitle: "Invite Friends to Join PUSH",
        title: "Join PUSH Agency!",
        text:
          "Looking for part-time gigs in the " +
          (referralCampaign?.merc_city
            ? referralCampaign?.merc_city + " "
            : "") +
          "area? Join the PUSH promo network today to gain access to fun & flexible opportunities, rep awesome brands and earn rewards!",
        url:
          "https://pushmodels.com/model_personal_detail.php?ref=er_" +
          modelUserId,
      };
      // Call the Capacitor Share plugin to share the specified content
      await Share.share(shareOptions);
    } catch (error: any) {
      if (error?.message != "Share canceled") {
        await logError(
          "External Talent Referrals Options - shareReferralLink",
          "Talent App - External Talent Referral",
          JSON.stringify(error.message) + " Model user ID: " + modelUserId
        );
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };

  const openExternalReferralModal = async (type?: any) => {
    // Invite Contacts
    setIsOpenExternalReferralModal(true);
  };

  const closeExternalReferralModal = async () => {
   
    setIsOpenExternalReferralModal(false);
  };

  return (
    <>
      {!loaded && (
        <div className="slider-card_spinner">
          <IonSpinner color="primary" name="crescent" />
        </div>
      )}

      {loaded && (
        <IonModal
          cssClass="external-talent-referral-options"
          isOpen={closeModalHandler ? isOpenNewReferralModalOptions : true}
          mode="ios"
          animated={closeModalHandler ? true : false}
        >
          <div className="external-talent-referral-options-modal-background"></div>
          <IonHeader className="assign-confirm__header ion-no-border">
            <IonToolbar>
              {closeModalHandler ? (
                <IonButton
                  fill="clear"
                  slot="start"
                  onClick={() => closeModalHandler(0)}
                >
                  <img
                    alt=""
                    src={backArrow}
                    className="assign-confirm__header__back"
                  />
                </IonButton>
              ) : (
                <IonButtons slot="start">
                  <IonBackButton
                    defaultHref="/"
                    mode="md"
                    className="assign-confirm__header__back"
                  />
                </IonButtons>
              )}
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="application-confirm__content">
              <div className="opportunities-survey__application-confirm">
                <img
                  className="external-talent-referral-options__stars-icon"
                  src={referralStars}
                  alt=""
                />
                <h1 className="external-talent-referral-options__heading">
                  EARN{" "}
                  {referralCampaign?.multiplier_description
                    ? referralCampaign?.multiplier_description
                    : ""}{" "}
                  POINTS
                </h1>
                <div>
                  Invite friends to join PUSH and earn{" "}
                  {referralCampaign?.merc_points
                    ? referralCampaign?.merc_points
                    : defaultExternalReferralPoints}{" "}
                  points for each referral that signs up!
                </div>
                <div className="external-talent-referral-options__offer-until">
                  {referralCampaign?.merc_expires_on
                    ? referralCampaign?.merc_expires_on
                    : ""}
                </div>
                <br />
                <>
                  <div>
                    <IonButton
                      mode="ios"
                      className="external-talent-referral-options__share-referral-link"
                      fill="outline"
                      size="large"
                      onClick={() => shareReferralLink()}
                    >
                      SHARE MY REFERRAL LINK
                    </IonButton>
                    <IonButton
                      mode="ios"
                      className="external-talent-referral-options__invite-contacts"
                      fill="clear"
                      size="large"
                      onClick={() =>
                        closeModalHandler
                          ? closeModalHandler(2, externalReferralPoints)
                          : openExternalReferralModal()
                      }
                    >
                      INVITE CONTACTS
                    </IonButton>
                  </div>
                </>
              </div>
            </div>
          </IonContent>
        </IonModal>
      )}
      {isOpenExternalReferralModal && (
        <ExternalTalentReferrals
          is_open_external_referral={isOpenExternalReferralModal}
          closeNewExternalModal={closeExternalReferralModal}
          knownReferralPoints={externalReferralPoints}
        />
      )}
      
    </>
  );
};

export default ExternalTalentReferralsOptions;

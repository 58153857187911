import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { IonIcon, IonItem, IonSpinner, IonTitle } from "@ionic/react";

import { authActions } from "../../store/auth";
import { getUpcomingEvents } from "../../data-services/home-api";

import "./SlidingCardWidget.css";
import chevron_right from "../../assets/images/common/chevron-right.svg";
import event_placeholder from "../../assets/images/common/event_placeholder_img.jpg";
import ItemCard from "../../components/ItemCard/ItemCard";
import { getOpportunities } from "../../data-services/opportunities";
import {
  getModelUpcomingevents,
  logError,
} from "../../data-services/assignmets";
import { get } from "../../storage/storage";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  unauthorizedErrMsg,
} from "../../common/common";
import info_circle from "../../assets/images/common/info-circle.svg";
import InfoButtonPopover from "../../push-components/InfoButtonPopover/InfoButtonPopover";

type componentsTypes = {
  slider_card_title: string;
  list_route: string;
  widget_type: string;
  no_data_text: string;
  current_event_id?: string;
  refresh_sliding_card?: boolean;
  setWidgetLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
  openOppViewModal?: (event_id: any) => any;
  additional_opps_widget?: boolean;
};

const SlidingCardWidget: React.FC<componentsTypes> = (props) => {
  const {
    slider_card_title,
    list_route,
    widget_type,
    no_data_text,
    current_event_id,
    refresh_sliding_card,
    setWidgetLoaded,
    openOppViewModal,
    additional_opps_widget
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [talenUpcomingEvents, setTalentUpcomingEvents] = useState<any>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showReinitSpinner, setShowReinitSpinner] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<string>("");
  const navigateTo = (route_to: string) => {
    window.location.href = `/${route_to}`;
  };

  /**
   * Get the list of upcoming assignments
   * @param ctaOnly - only events that have a call to action button (like checkin, confirm, etc)
   * @returns [] - list of events
   */
  const fetchUpcomingEventInfo = async (ctaOnly: any, upcomingType?: any) => {
    setShowSpinner(true);
    setTalentUpcomingEvents([]);
    try {
      let fetch_upcoming_events: any = await getUpcomingEvents(upcomingType);
      if (fetch_upcoming_events?.success) {
        let upcoming_event_arr: any = fetch_upcoming_events.data.userEvent;
        if (upcoming_event_arr) {
          let event_array: any = [];
          for (let i = 0; i < upcoming_event_arr.length; i++) {
            let element = upcoming_event_arr[i];
            let event_end_time = "";
            if (element?.eventtime?.length) {
              event_end_time =
                element.eventtime[element.eventtime.length - 1].ed_end_time;
            }

            // Show the Upcoming assignments even if they don't have a CTA
            // if (
            //   typeof ctaOnly !== "undefined" &&
            //   ctaOnly === true &&
            //   !element?.showConfirmation &&
            //   !element?.hasToTakeTests &&
            //   !element?.canModelCheckIn &&
            //   !element?.canModelCheckOut &&
            //   !element?.canresumework &&
            //   element?.fillRecapCTA == "0"
            // ) {
            //   // Skip the events that don't have a CTA
            //   continue;
            // }

            event_array.push({
              detail_view_link: `/AssignmentView/${element.ue_id}`,
              image:
                element.bannerPath == 0
                  ? event_placeholder
                  : element.bannerPath,
              hint: element.startDateUI.toUpperCase(),
              title: element.ue_name,
              address: `${
                element.ue_nearest_city !== "" && `${element.ue_nearest_city},`
              } ${element.ue_state} ${element.ue_zipcode}`,
              payment: `${element.estPayoutStr}`,
              points: `${element.estPointsStr}`,
              rating: element.eventModelPoints,
              event_id: element.ue_id,
              event_start_date: element?.eventtime[0]?.ed_start_date,
              event_start_time: element?.eventtime[0]?.ed_start_time,
              event_end_time: event_end_time,
              talent_role: element.ue_talent_role,
              force_recap_on_checkout: element.ue_force_recap_on_checkout,
              showConfirmation: element?.showConfirmation,
              showDBC: element?.showDBC,
              showDOC: element?.showDOC,
              hasToTakeTests: element?.hasToTakeTests,
              fillRecapCTA: element?.fillRecapCTA,
              fillRecapCTAText: element?.fillRecapCTAText,
              canModelCheckIn: element?.canModelCheckIn,
              canModelCheckOut: element?.canModelCheckOut,
              canresumework: element?.canresumework,
              cantakebreak: element?.cantakebreak,
              require_recap: element?.require_recap,
              ue_p_card_agreement_required:
                element?.ue_p_card_agreement_required,
              ue_p_card_agreement_instructions_html:
                element?.ue_p_card_agreement_instructions_html,
              requiredCustomerAgreementText: element?.requiredCustomerAgreementText,
              ue_worked_hours_points_multiplier:
                element?.ue_worked_hours_points_multiplier,
              backupTypeInt: element?.backupTypeInt,
              backupType: element?.backupType,
              backupInstructions: element?.backupInstructions,
              autoBookEnabled: element?.autoBookEnabled,
              ue_is_in_store_report: element?.ue_is_in_store_report,
              show_check_out_and_complete_recap_buttons: element?.show_check_out_and_complete_recap_buttons,
              show_check_out_and_break_return_buttons: element?.show_check_out_and_break_return_buttons,
              show_complete_recap_button_only: element?.show_complete_recap_button_only,
              show_check_in_button_only: element?.show_check_in_button_only
            });
          }
          setTalentUpcomingEvents(event_array);
        }
      } else if (
        fetch_upcoming_events?.data?.errorCode == unauthorizedErrorCode
      ) {
        dispatch(authActions.logout());
        return;
      } else if (fetch_upcoming_events?.data?.errorCode == offlineErrorCode) {
        showToastMessage(fetch_upcoming_events?.data?.message);
      } else if (fetch_upcoming_events?.message) {
        await showToastMessage(fetch_upcoming_events.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
      if (typeof setWidgetLoaded !== "undefined") {
        setWidgetLoaded(true);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Sliding Card Widget - fetchUpcomingEventInfo - Exception thrown",
          "Talent App - Sliding Card Widget",
          JSON.stringify(e.message)
        );
      }
      if (typeof setWidgetLoaded !== "undefined") {
        setWidgetLoaded(true);
      }
      setShowSpinner(false);

    }

    setShowSpinner(false);
  };

  const fetchNearbyOpportunityInfo = async () => {
    setShowSpinner(true);
    let filter_param = `&status=Available&limit=10&order_by=popular`;
    if (await get("none_primary_address")) {
      filter_param = `${filter_param}&search_latitude=${await get(
        "latitude"
      )}&search_longitude=${await get(
        "longitude"
      )}&search_location_distance=1000`;
    }

    if (current_event_id) {
      // Array - Nearby Opps navigation
      if (Array.isArray(current_event_id)) {
        current_event_id.forEach(id => {
          filter_param = `${filter_param}&excluded_event_id[]=${id}`;
        });
      // Non Array - Nearby Opps one single event
      } else {
        filter_param = `${filter_param}&excluded_event_id=${current_event_id}`;
      }
    }

    let fetch_opportunities: any = await getOpportunities(
      filter_param,
      current_event_id
    );
    if (fetch_opportunities?.success) {
      let opportunities_arr: any = fetch_opportunities.data.model_opportunities;
      if (opportunities_arr) {
        let oppo_array: any = [];
        for (let i = 0; i < opportunities_arr.length; i++) {
          let element = opportunities_arr[i];
          oppo_array.push({
            event_id: element.ue_id,
            detail_view_link: `/OpportunitiesView/${element.ue_id}`,
            image:
              element.bannerPath == 0 ? event_placeholder : element.bannerPath,
            hint: element.startDateUI.toUpperCase(),
            title: `${element.ue_name}`,
            address: `${
              element.ue_nearest_city !== "" && `${element.ue_nearest_city},`
            } ${element.ue_state} ${element.ue_zipcode}`,
            payment: `${element.estPayoutStr}`,
            points: `${element.estPointsStr}`,
            ue_worked_hours_points_multiplier:
              element?.ue_worked_hours_points_multiplier,
            rating: element.eventModelPoints,
            autoBookEnabled: element?.autoBookEnabled,
            card_miles_badge: element?.distance_str,
            card_waitlist_only: element?.waitlist_only
          });
        }
        setTalentUpcomingEvents(oppo_array);
      }
    } else if (fetch_opportunities?.data?.errorCode == unauthorizedErrorCode) {
      dispatch(authActions.logout());
      return;
    } else if (fetch_opportunities?.data?.errorCode == offlineErrorCode) {
      showToastMessage(fetch_opportunities?.data?.message);
    } else if (fetch_opportunities?.message) {
      showToastMessage(fetch_opportunities.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }

    setShowSpinner(false);
  };

  const fetchCompletedEventInfo = async () => {
    setShowSpinner(true);
    try {
      let fetch_upcoming_events: any = await getModelUpcomingevents(
        `&filledReport=1&order_by=recent`
      );
      if (fetch_upcoming_events?.success) {
        let upcoming_event_arr: any = fetch_upcoming_events.data.userEvent;
        if (upcoming_event_arr) {
          let event_array: any = [];
          for (let i = 0; i < upcoming_event_arr.length; i++) {
            let element = upcoming_event_arr[i];
            event_array.push({
              detail_view_link: `/AssignmentView/${element.ue_id}`,
              image: element.bannerPath,
              hint: element.startDateUI.toUpperCase(),
              title: element.ue_name,
              address: `${
                element.ue_nearest_city !== "" && `${element.ue_nearest_city},`
              } ${element.ue_state} ${element.ue_zipcode}`,
              payment: `${element.estPayoutStr}`,
              points: `${element.estPointsStr}`,
              ue_worked_hours_points_multiplier:
                element?.ue_worked_hours_points_multiplier,
              rating: element.eventModelPoints,
            });
          }
          setTalentUpcomingEvents(event_array);
        }
      } else if (
        fetch_upcoming_events?.data?.errorCode == unauthorizedErrorCode
      ) {
        dispatch(authActions.logout());
        return;
      } else if (fetch_upcoming_events?.data?.errorCode == offlineErrorCode) {
        showToastMessage(fetch_upcoming_events?.data?.message);
      } else if (fetch_upcoming_events?.message) {
        showToastMessage(fetch_upcoming_events.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }
    } catch (e: any) {
      if (e.message == unauthorizedErrMsg) {
        dispatch(authActions.logout());
        return;
      } else {
        showToastMessage(somethingWentWrongErrMsg);
        await logError(
          "Assignments - fetchCompletedEventInfo - Exception thrown",
          "Talent App - Assignments",
          JSON.stringify(e.message)
        );
      }
      setShowSpinner(false);
    }

    setShowSpinner(false);
  };
  const reInit = async () => {
    if (widget_type === "my_assignments") {
      await fetchUpcomingEventInfo(false);
    }
    if (widget_type === "my_assignments_with_cta") {
      await fetchUpcomingEventInfo(true, "upcomingAndRecapCTA");
    }
    if (widget_type === "nearby_opportunities") {
      await fetchNearbyOpportunityInfo();
    }
    if (widget_type === "completed_event") {
      await fetchCompletedEventInfo();
    }
  };
  useEffect(() => {
    (async () => {
      if (widget_type === "my_assignments") {
        await fetchUpcomingEventInfo(false);
      }
      if (widget_type === "my_assignments_with_cta") {
        await fetchUpcomingEventInfo(true, "upcomingAndRecapCTA");
      }
      if (widget_type === "nearby_opportunities") {
        await fetchNearbyOpportunityInfo();
      }
      if (widget_type === "completed_event") {
        await fetchCompletedEventInfo();
      }
    })();
  }, [refresh_sliding_card]);
  return (
    <>
      {(widget_type !== "my_assignments_with_cta" ||
        (widget_type === "my_assignments_with_cta" &&
          talenUpcomingEvents.length > 0)) && (
        <>
          <IonItem className="slider-card-header">
            <IonTitle className="ass-slider-card__title">
              {slider_card_title}
              {widget_type === "nearby_opportunities" && (
                <IonIcon
                  icon={info_circle}
                  onClick={() => setShowInfo("nearby_opportunities_slider")}
                />
              )}
            </IonTitle>
            <div
              slot="end"
              onClick={() => navigateTo(list_route)}
              className="ass-slider-card___all-item"
            >
              <span>See all</span> <IonIcon src={chevron_right}></IonIcon>
            </div>
          </IonItem>

          <>
            {showInfo === "nearby_opportunities_slider" && (
              <InfoButtonPopover
                infoButtonClass={"nearby_opportunities_slider"}
                updatePopoverState={setShowInfo}
              ></InfoButtonPopover>
            )}
          </>
          {!showSpinner && (
            <div
              className={
                widget_type == "my_assignments_with_cta" ||
                widget_type == "my_assignments"
                  ? "ass-slider-card ass-slider-card--assignments-with-cta"
                  : "ass-slider-card"
              }
            >
              <div>
                <ItemCard
                  title="My Assignments"
                  items={talenUpcomingEvents}
                  emptyString={no_data_text}
                  reinit={reInit}
                  openOppViewModal={openOppViewModal}
                  additional_opps_widget={additional_opps_widget}
                ></ItemCard>
              </div>
            </div>
          )}
        </>
      )}
      {showSpinner && (
        <div className="slider-card_spinner">
          <IonSpinner color="primary" name="crescent" />
        </div>
      )}
    </>
  );
};

export default SlidingCardWidget;

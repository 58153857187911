import React, { useEffect, useState, useRef } from "react";

import {
  IonContent,
  IonPage,
  IonItem,
  IonTitle,
  IonText,
  IonBadge,
} from "@ionic/react";

import "./ChatList.css";

import { initializeApp } from "firebase/app";

import { useHistory } from "react-router";

import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
  getDocs,
} from "firebase/firestore";
import SimpleHeaderView from "../../../push-components/SimpleHeaderView/SimpleHeaderView";
import { get } from "../../../storage/storage";
import no_chate from "../../../assets/images/common/no-chats.png";
import sample_img from "../../../assets/chat-user.png";

const ChatList: React.FC = () => {
  const history = useHistory();
  const [UserList, setUserList] = useState([]);
  const [TotalMessageCount, setTotalMessageCount] = useState(0);
  const [senderid, setSenderid] = useState<string>("");
  const [talentFname, setTalentFname] = useState("");
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  let isSubscribed = true;
  useEffect(() => {
    (async () => {
      setTalentFname(await get("login_model_first_name"));
    })();
  }, []);

  return (
    <>
      <IonPage>
        <SimpleHeaderView
          title="Direct Messages"
          counter={TotalMessageCount}
          bgcolor="primary"
          buttonType="undefined"
        ></SimpleHeaderView>
        <IonContent
          ref={contentRef}
          scrollEvents={true}
          className="chatUserListView"
        >
          <div className="chat-container">
            {UserList.length === 0 && (
              <div className="no-user-chats">
                <img src={no_chate} alt="" className="no-user-chats__img" />
                <IonTitle className="no-user-chats__title">Coming Soon!</IonTitle>
                <IonText className="no-user-chats__text">
                  <br/>
                  Hey {talentFname}, welcome to the future of the PUSH Agency!
                  <br /><br/>
                  Consider this your personal message portal where you will soon be able to speak directly to your booking agents about up and coming assignments.
                  <br/> <br/>
                  We are so excited to have you be a part of our growing Agency!
                </IonText>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
export default ChatList;

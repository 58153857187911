import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  IonContent,
  IonButton,
  IonText,
  IonModal,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import "./ApplicationConfirmation.css";
import bigCheckedIcon from "../../assets/images/common/checked-3d.png";
import backArrow from "../../assets/images/common/arrow-left.png";
import whitebackArrow from "../../assets/images/common/arrow-left-white.png";
import { Share } from "@capacitor/share";
import { logError } from "../../data-services/assignmets";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
} from "../../common/common";
import { authActions } from "../../store/auth";
import { useDispatch } from "react-redux";
type ModalProps = {
  isopen_ac_modal: boolean;
  closeAcModal: Function;
  event_name: string;
  event_id?: string;
  openNewInternalModal: Function;
  autoBookRedirectToAssignmentId?: any;
  showIntroVideoAnnouncement?: any;
  event_application_confirmation_photo?: any;
  event_referral_link?: any;
  event_city?: any;
  openExternalReferralModal?: any;
};

const ApplicationConfirmation: React.FC<ModalProps> = (Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isopen_ac_modal,
    closeAcModal,
    event_name,
    event_id,
    openNewInternalModal,
    autoBookRedirectToAssignmentId,
    showIntroVideoAnnouncement,
    event_application_confirmation_photo,
    event_referral_link,
    event_city,
    openExternalReferralModal
  } = Props;

  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${event_application_confirmation_photo})`,
  };

  /**
   * My Contacts - Create a list of contacts, grouped by their starting letters
   * @param contacts
   */
  const shareReferralLink = async (link?: any) => {
    try {
      // Specify the link, text, and title for sharing
      const shareOptions = {
        dialogTitle: "Invite Friends to Join PUSH",
        title: "Join PUSH Agency!",
        text:
          "Looking for part-time gigs in the " +
          (event_city ? event_city + " " : "") +
          "area? Join the PUSH promo network today to gain access to fun & flexible opportunities, rep awesome brands and earn rewards!",
        url: link,
      };
      // Call the Capacitor Share plugin to share the specified content
      await Share.share(shareOptions);
    } catch (error: any) {
      if (error?.message != "Share canceled") {
        await logError(
          "View Opportunity - shareReferralLink",
          "Talent App - View Opportunity - Share Referral Link",
          JSON.stringify(error.message) + " Link: " + link
        );
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };

  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <>
      {/* Default Application Confirmation View - No Background Photo */}
      {event_application_confirmation_photo == "" ||
      event_application_confirmation_photo == null ? (
        <>
          <IonModal isOpen={isopen_ac_modal} mode="ios">
            {/* <IonHeader className="assign-confirm__header">
              <IonToolbar>
                {autoBookRedirectToAssignmentId ? (
                  <IonButton
                    fill="clear"
                    slot="start"
                    onClick={() =>
                      history.push(
                        `/AssignmentView/${autoBookRedirectToAssignmentId}`
                      )
                    }
                  >
                    <img
                      alt=""
                      src={backArrow}
                      className="assign-confirm__header__back"
                    />
                  </IonButton>
                ) : (
                  <IonButton
                    fill="clear"
                    slot="start"
                    onClick={() => closeAcModal()}
                  >
                    <img
                      alt=""
                      src={backArrow}
                      className="assign-confirm__header__back"
                    />
                  </IonButton>
                )}
              </IonToolbar>
            </IonHeader> */}
            <IonContent>
              <div 
              style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}
              onClick={() => {dispatch(authActions.logout()); window.location.href="/"; }}
              >
                <h1 style={{fontSize: '50px', fontWeight: 'bold', textAlign: 'center'}}>REWARD<br/>HERE</h1>
              </div>
            </IonContent>
          </IonModal>
        </>
      ) : (
        <>
          {/* Application Confirmation View With Background Photo */}
          <IonModal
            cssClass="application_confirm"
            isOpen={isopen_ac_modal}
            mode="ios"
          >
            <div
              style={backgroundImageStyle}
              className="application_confirm-modal-background"
            ></div>
            <IonHeader className="assign-confirm__header ion-no-border">
              <IonToolbar>
                {autoBookRedirectToAssignmentId ? (
                  <IonButton
                    fill="clear"
                    slot="start"
                    onClick={() =>
                      history.push(
                        `/AssignmentView/${autoBookRedirectToAssignmentId}`
                      )
                    }
                  >
                    <img
                      alt=""
                      src={whitebackArrow}
                      className="assign-confirm__header__back"
                    />
                  </IonButton>
                ) : (
                  <IonButton
                    fill="clear"
                    slot="start"
                    onClick={() => closeAcModal()}
                  >
                    <img
                      alt=""
                      src={whitebackArrow}
                      className="assign-confirm__header__back"
                    />
                  </IonButton>
                )}
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="application-confirm__content">
                <div className="opportunities-survey__application-confirm opportunities-survey__application-confirm--white">
                  <h2>
                    {autoBookRedirectToAssignmentId
                      ? "Booking Confirmation"
                      : "Application Received!"}
                  </h2>
                  <IonText>
                    {autoBookRedirectToAssignmentId ? (
                      <>You are now officially booked for {event_name}.</>
                    ) : (
                      <></>
                    )}
                  </IonText>
                  {!autoBookRedirectToAssignmentId ? (
                    <div className="transparent-white-text-border">
                      <div className="transparent-white-text-border__content">
                        <span>
                          Invite friends to apply & earn{" "}
                          <strong>500 points</strong> if they are selected!
                        </span>
                      </div>

                      <p className="transparent-white-text-border__content"></p>
                      <div className="transparent-white-text-border__content application-confirm__content__referral-buttons">
                        {event_referral_link && (
                          <IonButton
                            mode="ios"
                            strong={false}
                            size="large"
                            fill="outline"
                            color="white"
                            onClick={() =>
                              shareReferralLink(event_referral_link)
                            }
                          >
                            SHARE MY REFERRAL LINK
                          </IonButton>
                        )}
                        <IonButton
                          mode="ios"
                          strong={false}
                          size="large"
                          fill="clear"
                          onClick={() => openExternalReferralModal()}
                        >
                          INVITE CONTACTS
                        </IonButton>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <IonButton
                    fill="clear"
                    className="opportunities-survey__view-opportunities"
                    onClick={() => closeAcModal()}
                  >
                    View Nearby Gigs
                  </IonButton>
                </div>
              </div>
            </IonContent>
          </IonModal>
        </>
      )}
    </>
  );
};

export default ApplicationConfirmation;

import React, { useEffect, useState } from "react";

import {
  IonContent,
  IonPage,
  IonButton,
  IonFooter,
  IonText,
  IonModal,
  IonHeader,
  IonToolbar,
} from "@ionic/react";
import "./ErrorScreen.css";
import errorIcon from "../../assets/images/common/errorIcon.png";

import backArrow from "../../assets/images/common/arrow-left.png";
type ModalProps = {
  isopen_es_modal: boolean;
  gradedTestFailed: boolean;
  closeEcModal: Function;
};
const ErrorScreen: React.FC<ModalProps> = (Props) => {
  const { isopen_es_modal, gradedTestFailed, closeEcModal } = Props;
  useEffect(() => {
    (async () => {})();
  }, []);
  return (
    <IonModal isOpen={isopen_es_modal} mode="ios">
      <IonHeader className="assign-confirm__header">
        <IonToolbar>
          <IonButton fill="clear" slot="start" onClick={() => closeEcModal()}>
            <img
              alt=""
              src={backArrow}
              className="assign-confirm__header__back"
            />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="opportunities-survey__error-screen">
          <div>
            <img
              className="opportunities-survey__error-icon"
              src={errorIcon}
              alt=""
            />
            <h2>Confirmation Test</h2>
            <IonText>
              {gradedTestFailed
                ? "You have failed to pass the test. Please contact your Booking Manager in order to re-take the test!"
                : "You did not pass the confirmation test required to participate in this event. Please try again to be able to participate in this assignment."}
            </IonText>
          </div>
          <IonButton
            expand="block"
            mode="ios"
            type="button"
            className="opportunities-survey__error-footer form-group__button"
            onClick={() => closeEcModal()}
          >
            {gradedTestFailed ? "Ok" : "Retake"}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ErrorScreen;

import React, { RefObject, useRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  IonContent,
  IonPage,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonIcon,
  IonSpinner,
} from "@ionic/react";

import "./Favourite.css";

import performance from "../../assets/images/common/performance.svg";
import experiance from "../../assets/images/common/experiance.svg";
import cancellations from "../../assets/images/common/cancellations.svg";
import proactive from "../../assets/images/common/proactive.svg";
import info_circle from "../../assets/images/common/info-circle.svg";
import info_circle_orang from "../../assets/images/common/info-circle-orang.svg";
import elite_golden_badge from "../../assets/images/common/elite-golden-badge.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HeaderView from "../../components/HeaderView/HeaderView";
import {
  scoreboardLanding,
  getTalentBadges,
} from "../../data-services/scoreboard";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode
} from "../../common/common";
import { get } from "../../storage/storage";
import MyRewardSliding from "../../push-components/MyRewardSliding/MyRewardSliding";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import InfoButtonPopover from "../../push-components/InfoButtonPopover/InfoButtonPopover";
import plus_icon from "../../assets/images/common/plug-icon.svg";
import down_arrow from "../../assets/images/common/down_arrow_orange.svg";

const ChevronRightIcon = <FontAwesomeIcon icon={faChevronRight} />;

// Define the type of the container ref
type ContainerRefType = RefObject<HTMLDivElement>;

const Favourite: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const fav_url_param: any = useParams();
  const containerRef: ContainerRefType = useRef<HTMLDivElement>(null);
  const [scoreBoradData, setScoreBoardData] = useState<any>(null);
  const [goldenEliteBadge, setGoldenEliteBadge] = useState<boolean>(true);
  const [talentBadges, setTalentBadges] = useState<any>([]);
  const [talentBadgesAll, setTalentBadgesAll] = useState<any>([]);
  const [talentBadgesLoaded, setTalentBadgesLoaded] = useState<any>(false);
  const [showInfoAboutStrenth, setShowInfoAboutStrenth] = useState<string>("");
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [areElitePerksVisible, setAreElitePerksVisible] = useState<boolean>(false);

  // Place the updateVisibleBadges and container here so the containerRef is fully set (after scoreboard api data is loaded)
  // Otherwise, it can sometimes be null
  const container = containerRef.current;
  const badgeWidth = 40; // Change this to the actual width of your badge
  function updateVisibleBadges() {
    if (container) {
      const containerWidth = container.offsetWidth - 15;
      const newVisibleBadges = Math.floor(containerWidth / badgeWidth) - 1;
      setTalentBadges(talentBadgesAll.slice(0, newVisibleBadges));
    } else {
      setTalentBadges(talentBadgesAll.slice(0, 3));
    }
    setTalentBadgesLoaded(true);
  }

  const setProgress = (percentage: any) => {
    setTimeout(function () {
      percentage = Number(percentage.replace("%", ""));
      const meters = document.querySelectorAll("svg[data-value] .meter");
      meters.forEach((path: any) => {
        let length = path.getTotalLength();
        let to = length * ((100 - percentage) / 100);
        path.getBoundingClientRect();
        let a: any = document.getElementById("white");
        a.style.strokeDashoffset = Math.max(0, to);
        path.style.strokeDashoffset = Math.max(0, to);
      });
    }, 2000);
  };

  const toggleContent = () => {
    setAreElitePerksVisible(!areElitePerksVisible);
  };

  useEffect(() => {
    (async () => {
      setGoldenEliteBadge(await get("login_model_is_elite"));
      if(fav_url_param?.display == "displayElitePerks"){
        setAreElitePerksVisible(true);
      }
      let scoreboard_api = await scoreboardLanding();
      if (scoreboard_api?.success) {
        if (scoreboard_api.data.elite_progress) {
          setProgress(scoreboard_api.data.elite_progress);
        }
        setScoreBoardData(scoreboard_api.data);
      } else if (scoreboard_api?.data?.errorCode == unauthorizedErrorCode) {
        dispatch(authActions.logout());
        return;
      } else if (scoreboard_api?.data?.errorCode == offlineErrorCode) {
        showToastMessage(scoreboard_api?.data?.message);
      } else if (scoreboard_api?.message) {
        await showToastMessage(scoreboard_api.message);
      } else {
        showToastMessage(somethingWentWrongErrMsg);
      }

      let badges_api = await getTalentBadges();
      if (badges_api?.success) {
        setTalentBadgesAll(badges_api.data.talent_badges);

        updateVisibleBadges();
      } else {
        if (badges_api?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        } else if (badges_api?.data?.errorCode == offlineErrorCode) {
          showToastMessage(badges_api?.data?.message);
        } else if (badges_api?.message) {
          await showToastMessage(badges_api.message);
        } else {
          showToastMessage(somethingWentWrongErrMsg);
        }
      }

      setDataLoaded(true);

      // Add an event listener for the resize event
      window.addEventListener("resize", updateVisibleBadges);
      window.addEventListener("orientationchange", updateVisibleBadges);
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", updateVisibleBadges);
        window.removeEventListener("orientationchange", updateVisibleBadges);
      };
    })();
    // talentBadgesAll for dependency so badges list can properly load
  }, [dataLoaded]);
  return (
    <IonPage>
      <HeaderView></HeaderView>
      <IonContent>
        <div className="scoreboard_container">
          <>
            <div className="scoreboard_container__elite-perks">
              <IonCard>
                <IonGrid>
                  <IonRow>
                    <IonCol
                      size="8"
                      className="scoreboard_container__elite-perks__label"
                    >
                      <img
                        className="scoreboard_status__elite_badge"
                        src={
                          "https://app.pushmodels.com/images/talent_badges/elite.svg"
                        }
                        alt=""
                      />
                      <IonLabel className="scoreboard_status_title">
                        ELITE Perks
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      size="4"
                      className="scoreboard_container__elite-perks__learn-more"
                      onClick={() => toggleContent()}
                    >
                      {!areElitePerksVisible && (
                        <>
                        <span>Learn More</span>{" "}
                        <IonIcon
                          className="scoreboard_container__elite-perks__learn-more__extend"
                          src={plus_icon}
                          
                        />
                      </>
                      )}
                      {areElitePerksVisible && (
                        <IonIcon
                        className="scoreboard_container__elite-perks__learn-more__collapse"
                        src={down_arrow}
                        
                      />
                      )}

                      
                    </IonCol>
                  </IonRow>
                  {areElitePerksVisible && (<IonRow>
                    <IonCol
                      size="12"
                      className="scoreboard_container__elite-perks__details"
                    >
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>Earn 250 points when achieved!</span>
                      </div>
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>2X points on assignments!</span>
                      </div>
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>Receive PUSH Elite branded swag</span>
                      </div>
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>
                          Top of the applicants list when applying for gigs
                        </span>
                      </div>
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>No payment processing fees</span>
                      </div>
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>Elite badge displayed on public profile</span>
                      </div>
                      <div className="scoreboard_container__elite-perks__details__perk">
                        <IonIcon
                          className="scoreboard_container__elite-perks__details__icon"
                          src={plus_icon}
                        />{" "}
                        <span>More to come!</span>
                      </div>
                    </IonCol>
                  </IonRow>)}
                </IonGrid>
              </IonCard>
            </div>
            <div className="scoreboard_status">
              {goldenEliteBadge && (
                <IonCard>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="9">
                        <IonLabel className="scoreboard_status_title">
                          Current Status:ELITE
                        </IonLabel>
                        <br />
                        <IonLabel className="scoreboard_status_interval">
                          {!scoreBoradData ? (
                            <IonSpinner name="dots"></IonSpinner>
                          ) : (
                            scoreBoradData?.elite_status_message
                          )}
                        </IonLabel>
                      </IonCol>
                      <IonCol size="3">
                        <img
                          className="scoreboard_status__golden_badge"
                          src={elite_golden_badge}
                          alt=""
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              )}

              <IonCard>
                <IonGrid>
                  <IonRow>
                    <IonCol size="11">
                      <IonLabel className="scoreboard_status_title">
                        {!scoreBoradData ? (
                          <IonSpinner name="dots"></IonSpinner>
                        ) : (
                          scoreBoradData?.elite_progress_message
                        )}
                      </IonLabel>
                      <br />
                      <IonLabel className="scoreboard_status_interval">
                        {!scoreBoradData ? (
                          <IonSpinner name="dots"></IonSpinner>
                        ) : (
                          scoreBoradData?.status_interval
                        )}
                      </IonLabel>
                    </IonCol>
                    <IonCol size="1">
                      <IonIcon
                        className="scoreboard_status__info_icon"
                        icon={
                          !goldenEliteBadge ? info_circle : info_circle_orang
                        }
                        onClick={() =>
                          setShowInfoAboutStrenth("scoreboard_what_is_elite")
                        }
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <div className="scoreboard_status_progressbar">
                  <p className="scoreboard_status_progressbar__percentage">
                    {!scoreBoradData ? (
                      <IonSpinner name="dots"></IonSpinner>
                    ) : (
                      scoreBoradData?.elite_progress
                    )}
                  </p>
                  <svg
                    height="200"
                    width="200"
                    viewBox="0 0 200 200"
                    data-value="40"
                  >
                    <path
                      className="bg"
                      strokeLinecap="round"
                      stroke="#ffe9dc"
                      d="M41 149.5a77 77 0 1 1 117.93 0"
                      fill="none"
                    />
                    <path
                      id="wire"
                      className="meter"
                      strokeLinecap="round"
                      stroke="#fe6102"
                      d="M41 149.5a77 77 0 1 1 117.93 0"
                      fill="none"
                      strokeDasharray="350"
                      strokeDashoffset="350"
                    />
                    <path
                      id="white"
                      className="white"
                      d="M41 149.5a77 77 0 1 1 117.93 0"
                      fill="none"
                    />
                  </svg>
                  <p className="scoreboard_status_progressbar__elite">ELITE</p>
                </div>

                <div
                  ref={containerRef}
                  className="scoreboard_status__talent_badge"
                >
                  {talentBadgesLoaded && (
                    <>
                      {talentBadges &&
                        talentBadges.map((item: any, index: any) => (
                          <img
                            key={item.tb_id}
                            className={
                              !item.tb_earned
                                ? "scoreboard_status__talent_badge__not-earned"
                                : ""
                            }
                            alt=""
                            src={item.tb_photo}
                          />
                        ))}
                      {talentBadges.length > 0 && (
                        <div
                          key={0}
                          className="scoreboard_status__talent_badge__remaining-container"
                        >
                          <span onClick={() => history.push("/Badges")}>
                            <i className="">{ChevronRightIcon}</i>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {!talentBadgesLoaded && (
                    <div className="centered-spinner">
                      <IonSpinner color="primary" name="crescent" />
                    </div>
                  )}
                </div>
              </IonCard>
            </div>
            <div className="about_talent_grid">
              <IonGrid>
                <IonRow>
                  <IonCol size="6" className="about_talent_grid_col_left">
                    <IonCard className="performance_card">
                      <IonGrid className="performance_card__grid">
                        <IonRow>
                          <IonCol size="9" className="performance_card__img">
                              <img alt="" src={performance} />
                            
                          </IonCol>
                          <IonCol
                            size="3"
                            className="performance_card__icon"
                            onClick={() =>
                              setShowInfoAboutStrenth("scoreboard_performance")
                            }
                          >
                              <IonIcon icon={info_circle} />

                          </IonCol>
                        </IonRow>
                        <IonRow className="performance_card__title">
                          <h1>Performance</h1>
                        </IonRow>
                        <IonRow className="performance_card__score">
                            <>
                              {" "}
                              {!scoreBoradData ? (
                                <IonSpinner name="dots"></IonSpinner>
                              ) : (
                                <>
                                  <h1>
                                    {" "}
                                    {scoreBoradData?.performance?.score} / 5
                                  </h1>{" "}
                                  <label>score</label>
                                </>
                              )}
                            </>
                        </IonRow>
                        <IonRow className="performance_card__bottom_img">
                          {scoreBoradData?.performance?.icon && (
                            <img
                              src={scoreBoradData?.performance.icon}
                              alt=""
                            />
                          )}
                          <span>
                            {!scoreBoradData ? (
                              <IonSpinner name="dots"></IonSpinner>
                            ) : (
                              <>{scoreBoradData?.performance?.status_message}</>
                            )}
                          </span>
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                  <IonCol size="6" className="about_talent_grid_col_right">
                    <IonCard className="performance_card">
                      <IonGrid className="performance_card__grid">
                        <IonRow>
                          <IonCol size="9" className="performance_card__img">
                              <img alt="" src={experiance} />
                            
                          </IonCol>
                          <IonCol
                            size="3"
                            className="performance_card__icon"
                            onClick={() =>
                              setShowInfoAboutStrenth("scoreboard_experience")
                            }
                          >
                              <IonIcon icon={info_circle} />

                          </IonCol>
                        </IonRow>
                        <IonRow className="performance_card__title">
                          <h1>Experience</h1>
                        </IonRow>
                        <IonRow className="performance_card__score">
                            <>
                              {!scoreBoradData ? (
                                <IonSpinner name="dots"></IonSpinner>
                              ) : (
                                <>
                                  <h1>{`${scoreBoradData?.experience.score} / ${scoreBoradData?.experience.total}`}</h1>{" "}
                                  <label>jobs</label>
                                </>
                              )}
                            </>
                        </IonRow>
                        <IonRow className="performance_card__bottom_img">
                          {!scoreBoradData ? (
                            <IonSpinner name="dots"></IonSpinner>
                          ) : (
                            <>
                              {scoreBoradData?.experience.icon && (
                                <img
                                  src={scoreBoradData?.experience.icon}
                                  alt=""
                                />
                              )}
                              <span>
                                {scoreBoradData?.experience.status_message}
                              </span>
                            </>
                          )}
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" className="about_talent_grid_col_left">
                    <IonCard className="performance_card">
                      <IonGrid className="performance_card__grid">
                        <IonRow>
                          <IonCol size="9" className="performance_card__img">
                              <img alt="" src={cancellations} />
                            
                          </IonCol>
                          <IonCol
                            size="3"
                            className="performance_card__icon"
                            onClick={() =>
                              setShowInfoAboutStrenth(
                                "scoreboard_cancellations"
                              )
                            }
                          >
                              <IonIcon icon={info_circle} />
                            
                          </IonCol>
                        </IonRow>
                        <IonRow className="performance_card__title">
                          <h1>Cancellations</h1>
                        </IonRow>
                        <IonRow className="performance_card__score">
                          
                            <>
                              {!scoreBoradData ? (
                                <IonSpinner name="dots"></IonSpinner>
                              ) : (
                                <>
                                  {" "}
                                  <h1>
                                    {" "}
                                    {scoreBoradData?.cancellations.score}
                                  </h1>{" "}
                                  <label>score</label>
                                </>
                              )}
                            </>
                        </IonRow>
                        <IonRow className="performance_card__bottom_img">
                          {!scoreBoradData ? (
                            <IonSpinner name="dots"></IonSpinner>
                          ) : (
                            <>
                              {scoreBoradData?.cancellations.icon && (
                                <img
                                  src={scoreBoradData?.cancellations.icon}
                                  alt=""
                                />
                              )}
                              <span>
                                {scoreBoradData?.cancellations?.status_message}
                              </span>
                            </>
                          )}
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                  <IonCol size="6" className="about_talent_grid_col_right">
                    <IonCard className="performance_card">
                      <IonGrid className="performance_card__grid">
                        <IonRow>
                          <IonCol size="9" className="performance_card__img">
                              <img alt="" src={proactive} />
                           
                          </IonCol>
                          <IonCol
                            size="3"
                            className="performance_card__icon"
                            onClick={() =>
                              setShowInfoAboutStrenth("scoreboard_proactive")
                            }
                          >
                              <IonIcon icon={info_circle} />

                          </IonCol>
                        </IonRow>
                        <IonRow className="performance_card__title">
                          <h1>Proactive</h1>
                        </IonRow>
                        <IonRow className="performance_card__score">
                            <>
                              {!scoreBoradData ? (
                                <IonSpinner name="dots"></IonSpinner>
                              ) : (
                                <>
                                  <h1>
                                    {" "}
                                    {scoreBoradData?.proactive.score} / 5
                                  </h1>{" "}
                                  <label>score</label>
                                </>
                              )}
                            </>
                        </IonRow>
                        <IonRow className="performance_card__bottom_img">
                          {!scoreBoradData ? (
                            <IonSpinner name="dots"></IonSpinner>
                          ) : (
                            <>
                              {" "}
                              {scoreBoradData?.proactive.icon && (
                                <img
                                  src={scoreBoradData?.proactive.icon}
                                  alt=""
                                />
                              )}
                              <span>
                                {scoreBoradData?.proactive?.status_message}
                              </span>
                            </>
                          )}
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <MyRewardSliding />
          </>
        </div>
      </IonContent>
      {showInfoAboutStrenth === "scoreboard_what_is_elite" && (
        <InfoButtonPopover
          infoButtonClass={"scoreboard_what_is_elite"}
          updatePopoverState={setShowInfoAboutStrenth}
        ></InfoButtonPopover>
      )}
      {showInfoAboutStrenth === "scoreboard_performance" && (
        <InfoButtonPopover
          infoButtonClass={"scoreboard_performance"}
          updatePopoverState={setShowInfoAboutStrenth}
        ></InfoButtonPopover>
      )}
      {showInfoAboutStrenth === "scoreboard_experience" && (
        <InfoButtonPopover
          infoButtonClass={"scoreboard_experience"}
          updatePopoverState={setShowInfoAboutStrenth}
        ></InfoButtonPopover>
      )}
      {showInfoAboutStrenth === "scoreboard_cancellations" && (
        <InfoButtonPopover
          infoButtonClass={"scoreboard_cancellations"}
          updatePopoverState={setShowInfoAboutStrenth}
        ></InfoButtonPopover>
      )}
      {showInfoAboutStrenth === "scoreboard_proactive" && (
        <InfoButtonPopover
          infoButtonClass={"scoreboard_proactive"}
          updatePopoverState={setShowInfoAboutStrenth}
        ></InfoButtonPopover>
      )}
    </IonPage>
  );
};
export default Favourite;

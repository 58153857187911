import React, { useState, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import {
  IonContent,
  IonPage,
  IonIcon,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonButton,
  IonModal
} from "@ionic/react";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
} from "../../common/common";
import moment from "moment";
import "./Opportunities.css";
import {
  funnelOutline,
  swapVerticalOutline
} from "ionicons/icons";
import { useHistory } from "react-router";
import { authActions } from "./../../store/auth";
import {
  getOpportunities,
  invalidateOppsCache,
} from "../../data-services/opportunities";
import EventCardList from "../../push-components/EventCardList/EventCardList";
import SimpleHeaderView from "../../push-components/SimpleHeaderView/SimpleHeaderView";
import EventFilters from "../../push-components/EventFilters/EventFilters";
import Loading from "../../push-components/loading/Loading";
import event_placeholder from "../../assets/images/common/event_placeholder_img.jpg";
import EmptyScreen from "../../push-components/EmptyScreen/EmptyScreen";
import { get } from "../../storage/storage";
import OpportunitiesView from "../OpportunitiesView/OpportunitiesView";

const Opportunities: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [allMyOpportunites, setAllMyOpportunites] = useState<any>([]);
  const [myOpportunities, setMyOpportunities] = useState<any>([]);

  const [opportunityTabBtn, setOpportunityTabBtn] =
    useState<string>("Nearby");
  const [isOpenOpportunityFilter, setIsOpenOpportunityFilter] =
    useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [opportunitySort, setOpportunitySort] =
    useState<string>("popular");

  const [oppViewModalEventID, setOppViewModalEventID] = useState<any>(
    false
  );
  const [oppViewModalOpen, setOppViewModalOpen] = useState<any>(false);

  const [oppViewModalEventIDs, setOppViewModalEventIDs] = useState<any>([]);
  

  const [oppConfirmationStatusList, setOppConfirmationStatusList] = useState<any>([]);

  const handleOppViewDismiss = () => {
    closeOppViewModal();
  };

  const opportunityTabHandler = async (tab: string) => {
    setOpportunityTabBtn(tab);
    await getTalentOpprtunity(`&order_by=${opportunitySort}&status=${tab}`);
  };

  const oppFilterClose = () => {
    setIsOpenOpportunityFilter(false);
  };
  const searchFieldShowHinde = () => {};
  const applyEventFilters = async (filter_param: any) => {
    oppFilterClose();
    await invalidateOppsCache();
    let search_param = `&order_by=${opportunitySort}&status=${opportunityTabBtn}`;
    if (filter_param.start_date !== "") {
      let day: any = moment(filter_param.start_date).date();
      day = day < 10 ? `0${day}` : day;
      let month: any = moment(filter_param.start_date).month() + 1;
      month = month < 10 ? `0${month}` : month;
      let year = moment(filter_param.start_date).year();
      search_param = `${search_param}&start_day=${day}&start_month=${month}&start_year=${year}`;
    }
    if (filter_param.end_date !== "") {
      let day: any = moment(filter_param.end_date).date();
      day = day < 10 ? `0${day}` : day;
      let month: any = moment(filter_param.end_date).month() + 1;
      month = month < 10 ? `0${month}` : month;
      let year = moment(filter_param.end_date).year();
      search_param = `${search_param}&end_day=${day}&end_month=${month}&end_year=${year}`;
    }
    if (filter_param.hour_rate > 0) {
      search_param = `${search_param}&search_min_hourly_rate=${filter_param.hour_rate}`;
    }
    if (filter_param.location_zipcode !== "") {
      search_param = `${search_param}&search_location_zipcode=${filter_param.location_zipcode}`;
    }
    if (filter_param.event_type.length > 0) {
      let types = "";
      filter_param.event_type.map(
        (type: string) => (types = `${types}&search_event_type[]=${type}`)
      );

      search_param = `${search_param}${types}`;
    }

    if (filter_param.assignment_type.length > 0) {
      let types = "";
      filter_param.assignment_type.map(
        (type: string) => (types = `${types}&search_assignment_type[]=${type}`)
      );

      search_param = `${search_param}${types}`;
    } 

    if (filter_param.search_location_distance > 0) {
      search_param = `${search_param}&search_location_distance=${filter_param.search_location_distance}`;
    }
    if (filter_param.account_levels.length > 0) {
      let level = "";
      filter_param.account_levels.map(
        (type: string) => (level = `${level}&account_levels=${type}`)
      );

      search_param = `${search_param}${level}`;
    }
    getTalentOpprtunity(search_param);
  };

  const setSearchText = async (val: string) => {
    if(val) {
      let tempMyOpportunities = allMyOpportunites.filter(function (
        obj: any
      ) {
        return obj?.ue_name.toLowerCase().includes(val.toLowerCase());
      });
  
      setMyOpportunities([...tempMyOpportunities]);
    } else {
      setMyOpportunities([...allMyOpportunites]);
    }
    
  };

  const setOpportunitySortAndGetTalentOpportunity = async (value: string) => {
    // When changing Order By, it's better to do one function call rather than having them both separately inside onIonChange 
    // Using setOpportunitySort and getTalentOpprtunity inside onIonChange  - 2 identical getTalentOpprtunity calls
    // Using setOpportunitySortAndGetTalentOpportunity - 1 getTalentOpprtunity call
    await setOpportunitySort(value);
    await getTalentOpprtunity(`&order_by=${value}&status=${opportunityTabBtn}`, value);
  }

  const getTalentOpprtunity = async (filter_param: string = "", selected_order_by: string = "", prevent_caching = false) => {
    
    // When changing the sort by, onIonChange triggers like 4 times. Reduce it to one api call
    // e.g.: ue_start_date to ue_model_price will provide the following calls given (opportunitySort -> selected_order_by:)
    // 1. ue_start_date -> model_price (OK) 
    // 2. ue_start_date -> ue_start_date (not ok)
    // 3. ue_start_date -> model_price (not ok but will trigger api call unfortunately) 
    // 4. model_price -> model_price (not ok) 
    if(selected_order_by) {
      if(opportunitySort === selected_order_by) {
        return;
      }
    }
    setShowLoader(true);
    if (await get("none_primary_address")) {
      let is_exist = filter_param.includes("search_location_distance");
      if (!is_exist) {
        // check this filter already exist or not
        filter_param = `${filter_param}&search_location_distance=1000`;
      }
      filter_param = `${filter_param}&search_latitude=${await get(
        "latitude"
      )}&search_longitude=${await get("longitude")}`;
    }
    let fetch_my_opportunities: any = await getOpportunities(filter_param);
    if (fetch_my_opportunities?.success) {
      let opportunities_arr: any =
        fetch_my_opportunities.data.model_opportunities;
      let oppo_array: any = [];
      for (let i = 0; i < opportunities_arr.length; i++) {
        let element = opportunities_arr[i];
        oppo_array.push({
          opp_id: element.ue_id,
          ue_name: element?.ue_name,
          image:
            element.bannerPath == 0 ? event_placeholder : element.bannerPath,
          opp_event_date: element.startDateTimeUI.toUpperCase(),
          title: element.ue_name,
          address: `${element.ue_nearest_city},  ${element.ue_state}` + (element.ue_event_type != 'Virtual Casting' ? ` ${element.ue_zipcode}` : ''),
          payment: element.ue_event_type != 'Virtual Casting' ? `${element.estPayoutStr}` : "",
          points: `${element.estPointsStr}`,
          miles_away: element.distance_str,
          waitlist_only: element.waitlist_only,
          model_level: Object.values(element.modelAccountLevelRequired),
          ue_worked_hours_points_multiplier: element?.ue_worked_hours_points_multiplier,
          autoBookEnabled: element?.autoBookEnabled
        });
      }
      setMyOpportunities(oppo_array);
      setAllMyOpportunites(oppo_array);

      // Clear the just generated opps cache if requested
      // Use case: initiating the Opp page with GET filters (e.g.: Instant Book only)
      if(prevent_caching) {
        await invalidateOppsCache();
      }
    } else if (
      fetch_my_opportunities?.data?.errorCode == unauthorizedErrorCode
    ) {
      dispatch(authActions.logout());
      return;
    } else if (fetch_my_opportunities?.data?.errorCode == offlineErrorCode) {
      showToastMessage(fetch_my_opportunities?.data?.message);
    } else if (fetch_my_opportunities?.message) {
      showToastMessage(fetch_my_opportunities.message);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setShowLoader(false);
  };

  const closeOppViewModal = () => {
    setOppViewModalOpen(false);
  };

  const openOppViewModal = (event_id: any) => {
    setOppViewModalEventID(event_id);
    let newOppViewEventIds = [...oppViewModalEventIDs];
    newOppViewEventIds.push(event_id)
    setOppViewModalEventIDs(newOppViewEventIds);
    setOppViewModalOpen(true);
  }

  const setOppConfirmationStatus = (event_id: any, status: any) => {
    let tempOppConfirmationStatusList = oppConfirmationStatusList.filter((item: any) => item.event_id !== event_id);;
    tempOppConfirmationStatusList.push({
      event_id: event_id, 
      status: status
    });
    setOppConfirmationStatusList([...tempOppConfirmationStatusList]);
  }

  useEffect(() => {
    closeOppViewModal();
    (async () => {

      // When initiating the Opportunities page, check if we have predefined search filters from GET
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const instaBookOnly = urlParams.get("instaBookOnly");
      let instaBookFilter = "";

      if (instaBookOnly) {
        await invalidateOppsCache();
        instaBookFilter = `&search_assignment_type[]=assignment_type_instant_book`;
      }

      await getTalentOpprtunity(
        `&order_by=${opportunitySort}&status=${opportunityTabBtn}` + instaBookFilter,
        '',
        instaBookOnly ? true : false
      );
    })();
  }, []);

  return (
    <IonPage>
      <SimpleHeaderView
        title="Nearby Gigs"
        bgcolor="primary"
        buttonType="search"
        clickEventHandler={searchFieldShowHinde}
        onTextChange={(val: any) => setSearchText(val)}
        searchPlaceholder="Search Opportunity"
      ></SimpleHeaderView>

      <IonContent className="opportunity">
        <IonHeader>
          <div className="opportunity-tabs">
            <IonButton
              fill="clear"
              className={
                opportunityTabBtn === "Nearby"
                  ? "opportunity-tabs__tab opportunity-tabs__active"
                  : "opportunity-tabs__tab"
              }
              onClick={() => opportunityTabHandler("Nearby")}
            >
              Available
            </IonButton>
            <IonButton
              fill="clear"
              className={
                opportunityTabBtn === "Pending"
                  ? "opportunity-tabs__tab opportunity-tabs__active"
                  : "opportunity-tabs__tab"
              }
              onClick={() => opportunityTabHandler("Pending")}
            >
              Pending
            </IonButton>
            <IonButton
              fill="clear"
              className={
                opportunityTabBtn === "Declined"
                  ? "opportunity-tabs__tab opportunity-tabs__active"
                  : "opportunity-tabs__tab"
              }
              onClick={() => opportunityTabHandler("Declined")}
            >
              Declined
            </IonButton>
          </div>
        </IonHeader>
        <div className="opportunity-content">
          <div className="opportunity__filters">
            <div className="opportunity__filter">
              <IonIcon
                slot="icon-only"
                icon={swapVerticalOutline}
                className="opportunity__filter__icon"
              />
              <IonLabel className="opportunity__filter__lable">
                Sort by :
              </IonLabel>
              <IonSelect
                value={opportunitySort}
                onIonChange={(e: any) => {
                  setOpportunitySortAndGetTalentOpportunity(e.detail.value);
                }}
                className="opportunity__filter__select"
              >
                <IonSelectOption value="popular">Popular</IonSelectOption>
                <IonSelectOption value="ue_start_date">Recents</IonSelectOption>
                <IonSelectOption value="ue_model_price">Rate</IonSelectOption>
                <IonSelectOption value="distance_miles">
                  Distance
                </IonSelectOption>
              </IonSelect>
            </div>
            <IonButton
              fill="clear"
              className="opportunity__filter__btn"
              onClick={() => setIsOpenOpportunityFilter(true)}
            >
              <IonIcon icon={funnelOutline} />
            </IonButton>
          </div>
          <div className="opportunity__event-list">
            {myOpportunities.length !== 0 &&
              myOpportunities.map((opportunity: any, index: number) => (
                <EventCardList
                  key={index}
                  card_event_id={opportunity.opp_id}
                  card_event_type="opportunities"
                  card_event_route_to={`/OpportunitiesView/${opportunity.opp_id}`}
                  card_miles_badge={opportunity.miles_away}
                  card_waitlist_only={opportunity.waitlist_only}
                  model_level_badge={opportunity.model_level}
                  card_event_image={opportunity.image}
                  card_event_date={opportunity.opp_event_date}
                  card_event_title={opportunity.title}
                  card_event_address={opportunity.address}
                  card_event_payment={opportunity.payment}
                  card_event_points = {opportunity.points}
                  card_event_points_multiplier={opportunity.ue_worked_hours_points_multiplier}
                  card_auto_book_enabled={opportunity?.autoBookEnabled}
                  openOppViewModal={openOppViewModal}
                  oppConfirmationStatusList={oppConfirmationStatusList}
                />
              ))}
          </div>
          {!showLoader && myOpportunities.length == 0 && (
            <EmptyScreen title="No Opportunities" description="" />
          )}
        </div>
      </IonContent>
      <EventFilters
        isOpen_opp_filter={isOpenOpportunityFilter}
        closeOppFilter={oppFilterClose}
        applyEventFilters={applyEventFilters}
      />
      <Loading showLoading={showLoader} />
      <IonModal isOpen={oppViewModalOpen} onDidDismiss={handleOppViewDismiss}  mode="md">
          <OpportunitiesView
            modal_event_id={oppViewModalEventID}
            closeOppViewModal={closeOppViewModal}
            openOppViewModal={openOppViewModal}
            oppViewModalEventIDs={oppViewModalEventIDs}
            setOppConfirmationStatus={setOppConfirmationStatus}
          />
      </IonModal>
    </IonPage>
  );
};

export default Opportunities;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { authActions } from "../../store/auth";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonBadge,
  IonText,
  IonTitle,
  IonCheckbox,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import { Share } from "@capacitor/share";

import {
  personCircle,
  home,
  calendarClearOutline,
  starOutline,
  logoUsd,
  arrowBackOutline,
} from "ionicons/icons";

import {
  getModelOpportunities,
  saveModelOpportunitySelection,
  getOppFromCache,
  saveOpportunityLinkSource,
  checkSession,
  trackOppView,
} from "../../data-services/opportunities";
import SlidingCardWidget from "../../push-components/SlidingCardWidget/SlidingCardWidget";
import { get } from "../../storage/storage";
import "./OpportunitiesView.css";
import CalendarIcon from "../../assets/images/common/calender-round.svg";
import DollarSignIcon from "../../assets/images/common/doller-round.svg";
import DoublePointsIcon from "../../assets/images/opportunity/2x.png";
import TriplePointsIcon from "../../assets/images/opportunity/3x.png";
import redX from "../../assets/images/common/red-x.png";
import waitlistIcon from "../../assets/images/common/waitlist.png";
import warningIcon from "../../assets/images/opportunity/op_warning.png";
import { async } from "@firebase/util";
import locked_icon from "../../assets/images/common/lock-big.png";
import SmallModal from "../../push-components/SmallModal/SmallModal";
import {
  showToastMessage,
  somethingWentWrongErrMsg,
  unauthorizedErrorCode,
  offlineErrorCode,
  getUserCurrentPositionIfGranted,
  calculateDistance
} from "../../common/common";
import { logError } from "../../data-services/assignmets";
import W2Form from "../Home/SetupAccount/W2Form/W2Form";
import AddVaccination from "../Home/SetupAccount/AddVaccination/AddVaccination";
import Modal from "../../components/Modal/Modal";
import event_placeholder from "../../assets/images/common/event_placeholder_img.jpg";
import Loading from "../../push-components/loading/Loading";
import ApplicationConfirmation from "../TakeTest/ApplicationConfirmation";
import TooFarAwayWarning from "../TakeTest/TooFarAwayWarning";
import NewInternalTalentReferrals from "../Referrals/NewInternalTalentReferrals/NewInternalTalentReferrals";
import menuIcon from "../../assets/images/opportunity/op_share.svg";
import AssignmentConfirmAutoBook from "../AssignmentView/AssignmentConfirmAutoBook/AssignmentConfirmAutoBook";
import instantBookIcon from "../../assets/images/opportunity/lightning_bolt.png";
import ExternalTalentReferrals from "../Referrals/ExternalTalentReferrals/ExternalTalentReferrals";

type ModalProps = {
  modal_event_id?: any;
  closeOppViewModal?: () => any;
  openOppViewModal?: (event_id: any) => any;
  oppViewModalEventIDs?: any;
  setOppConfirmationStatus?: (event_id: any, status: any) => any;
};

const OpportunitiesView: React.FC<ModalProps> = (Props) => {
  const dispatch = useDispatch();
  const op_url_param: any = useParams();

  const history = useHistory();

  const ionSlidesRef = React.useRef<HTMLIonSlidesElement>(null);

  const {
    modal_event_id,
    closeOppViewModal,
    openOppViewModal,
    oppViewModalEventIDs,
    setOppConfirmationStatus,
  } = Props;

  const [opportunityDetail, setOpportunityDetail] = useState<any>([]);
  const [testActionType, setTestActionType] = useState<string>("");
  const [assignmentStatus, setAssignmentStatus] = useState<string>("");

  const [autoBookRedirectToAssignmentId, setAutoBookRedirectToAssignmentId] =
    useState<boolean>(false);

  /**
   * Related to Unlock Opportunity feature
   * */
  const [showUpgradeBtn, setShowUpgradeBtn] = useState<boolean>(false);

  const [openInternalReferral, setOpenInternalReferral] =
    useState<boolean>(false);
  const [upgradeAccountModalPopup, setUpgradeAccountModalPopup] = useState<{
    is_show: boolean;
    message: string;
  }>({ is_show: false, message: "" });
  const [w2OnboardingPopup, setW2OnboardingPopup] = useState<boolean>(false);
  const [opportunityDate, setOpprtunityDate] = useState<{
    type: string;
    dates: any;
  }>({ type: "", dates: [] });
  const [disabledBtnTillRes, setDisabledBtnTillRes] = useState<boolean>(false);
  const [isOpenW2Form, setIsopenW2Form] = useState<boolean>(false);
  const [isOpenAddVaccine, setIsopenAddVaccine] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [selectedEventCount, setSelectedEventCount] = useState<number>(0);
  const [multidayEstimatedPayout, setMultidayEstimatedPayout] =
    useState<number>(0);
  const [multidayModelPoints, setMultidayModelPoints] = useState<number>(0);

  const [waitlistOnly, setWaitlistOnly] = useState<any>("");
  const [multidaySelectedEventId, setMultidaySelectedEventId] = useState<any>(
    []
  );
  const [multidayNoShiftSelected, setMultidayNoShiftSelected] =
    useState<any>(false);

  const [multidaySelectedEventData, setMultidaySelectedEventData] =
    useState<any>([]);
  const [isOpenTestConfirmModal, setIsOpenTestConfirmModal] =
    useState<boolean>(false);
  const closeTestConfirmModal = () => {
    setIsOpenTestConfirmModal(false);
    if (modal_event_id && closeOppViewModal) {
      closeOppViewModal();
    }
  };
  const [multidayDeSelectedEventId, setMultidayDeSelectedEventId] =
    useState<any>([]);
  const [waitingForApiResonse, setWaitingForApiResonse] =
    useState<boolean>(false);
  const [isOpenAssignConfirm, setIsOpenAssignConfirm] =
    useState<boolean>(false);
  const [multidayAllEventIds, setMultidayAllEventIds] = useState<any>([]);
  const [hasIntroVideo, setHasIntroVideo] = useState<any>(false);
  const [modelLatitude, setModelLatitude] = useState<any>(null);
  const [modelLongitude, setModelLongitude] = useState<any>(null);
  // To be changed later to false once deployed
  const [showTooFarAwayWarning, setShowTooFarAwayWarning] =
    useState<any>(false);


  const [isOpenExternalReferralModal, setIsOpenExternalReferralModal] =
    useState<boolean>(false);

  const [ref, setRef] = useState<any>("");


  const openExternalReferralModal = async (type?: any) => {
    // Invite Contacts
    setIsOpenExternalReferralModal(true);
  };


const closeExternalReferralModal = async () => {
    setIsOpenExternalReferralModal(false);
};

  const closeAddVaccine = () => {
    setIsopenAddVaccine(false);
  };
  const closeW2Form = () => {
    setIsopenW2Form(false);
  };
  const applyOpportunityHandler = async (options: any = "") => {
    // Store the selected confirmation status in order to show it on the Opportunities list when this page is opened in the modal view
    let statusStr = "";
    setMultidayNoShiftSelected(false);
    if (opportunityDate.type === "multiday" && options.status !== "Declined") {
      if (!(multidaySelectedEventId && multidaySelectedEventId?.length)) {
        setMultidayNoShiftSelected(true);
        return;
      }
    }

    setDisabledBtnTillRes(true);
    setIsOpenAssignConfirm(false);

    if (
      testActionType === "takeTest" &&
      (!options?.status || options.status == "Pending")
    ) {
      // Apply scenario

      // Approach: send an extra param - instantBook=1
      let status_type = assignmentStatus === "Booked" ? 1 : 0;
      if (opportunityDate.type === "multiday") {
        // Multiday + Take Test - include selected/ declined opps
        let multidaySelectedEventIdsParam;
        let multidayDeselectedEventIdsParam;
        // Prevent sending empty string value as it might cause the url to not be recognized and redirected to home
        if (multidaySelectedEventId && multidaySelectedEventId?.length) {
          multidaySelectedEventIdsParam = multidaySelectedEventId;
        } else {
          multidaySelectedEventIdsParam = 0;
        }

        if (multidayDeSelectedEventId && multidayDeSelectedEventId?.length) {
          multidayDeselectedEventIdsParam = multidayDeSelectedEventId;
        } else {
          multidayDeselectedEventIdsParam = 0;
        }
        history.push(
          `/TakeTestMultiday/${opportunityDetail.ue_id}/${status_type}/${multidaySelectedEventIdsParam}/${multidayDeselectedEventIdsParam}` +
            (opportunityDetail?.autoBookEnabled ? `/1` : ``) + (ref ? "?ref=" + ref : "")
        );
      } else {
        // Singleday + Take Test - we have only one event id
        history.push(
          `/TakeTest/${opportunityDetail.ue_id}/${status_type}` +
            (opportunityDetail?.autoBookEnabled ? `/1` : ``) + (ref ? "?ref=" + ref : "")
        );
      }
    } else if (
      testActionType === "showApplyDecline" ||
      testActionType === "showDecline" ||
      testActionType === "showApply"
    ) {
      // Decline/ Apply (/ Book) without having to take test/ survey
      if (opportunityDate.type === "multiday") {
        // Multiday + Auto Book + Decline - decline all shifts
        if (options.status == "Declined") {
          setMultidayDeSelectedEventId(multidayAllEventIds);
          setMultidaySelectedEventId([]);
        }
        // Multiday
        let apply_res;
        let decline_res;
        // For Multiday/ Multishift, send Apply/ Decline request if there is any event checked/ unchecked for that
        if (multidaySelectedEventId.length) {
          apply_res = await saveModelOpportunitySelection(
            multidaySelectedEventId,
            opportunityDetail?.autoBookEnabled ? "Booked" : "Pending",
            await get("latitude"),
            await get("longitude"),
            ref
          );
        }
        if (multidayDeSelectedEventId.length) {
          decline_res = await saveModelOpportunitySelection(
            multidayDeSelectedEventId,
            "Declined",
            await get("latitude"),
            await get("longitude"),
            ref
          );
        }

        if (
          (multidaySelectedEventId.length && !apply_res?.success) ||
          (multidayDeSelectedEventId.length && !decline_res?.success)
        ) {
          if (
            (multidaySelectedEventId.length &&
              apply_res?.data?.errorCode == unauthorizedErrorCode) ||
            (multidayDeSelectedEventId.length &&
              decline_res?.data?.errorCode == unauthorizedErrorCode)
          ) {
            dispatch(authActions.logout());
            return;
          } else if (
            multidaySelectedEventId.length &&
            apply_res?.data?.errorCode == offlineErrorCode
          ) {
            await showToastMessage(apply_res?.data?.message);
          } else if (multidaySelectedEventId.length && apply_res?.message) {
            await showToastMessage(apply_res?.message);
          } else if (
            multidayDeSelectedEventId.length &&
            decline_res?.data?.errorCode == offlineErrorCode
          ) {
            await showToastMessage(apply_res?.data?.message);
          } else if (multidayDeSelectedEventId.length && decline_res?.message) {
            await showToastMessage(decline_res?.message);
          } else {
            await showToastMessage(somethingWentWrongErrMsg);
          }
        } else {
          // Refresh opportunity data
          await oppInit();
          if (
            !multidaySelectedEventId.length &&
            multidayDeSelectedEventId.length
          ) {
            statusStr = "Declined";
            await showToastMessage("Response Received");
          } else {
            statusStr = opportunityDetail?.autoBookEnabled
              ? "Booked"
              : "Applied";
            if (
              typeof multidaySelectedEventId[0] !== "undefined" &&
              opportunityDetail.autoBookEnabled
            ) {
              setAutoBookRedirectToAssignmentId(multidaySelectedEventId[0]);
            }
            setIsOpenTestConfirmModal(true);
          }
        }
      } else {
        // Singleday
        let save_res = await saveModelOpportunitySelection(
          options.ids,
          options.status,
          await get("latitude"),
          await get("longitude"),
          ref
        );
        if (!save_res?.success) {
          if (save_res?.data?.errorCode == unauthorizedErrorCode) {
            dispatch(authActions.logout());
            return;
          } else if (save_res?.data?.errorCode == offlineErrorCode) {
            showToastMessage(save_res?.data?.message);
          } else if (save_res?.message) {
            showToastMessage(save_res?.message);
          } else {
            await showToastMessage(somethingWentWrongErrMsg);
          }
        } else {
          // Refresh opportunity data
          await oppInit();
          statusStr = options.status == "Pending" ? "Applied" : options.status;
          if (options.status == "Pending" || options.status == "Booked") {
            if (
              typeof options.ids[0] !== "undefined" &&
              opportunityDetail.autoBookEnabled
            ) {
              setAutoBookRedirectToAssignmentId(options.ids[0]);
            }
            setIsOpenTestConfirmModal(true);
          } else {
            await showToastMessage("Response Received");
          }
        }
      }
    } else if (testActionType === "premiumOnly") {
    } else if (testActionType === "influencerOnly") {
    } else if (testActionType === "W2required") {
    } else if (testActionType === "tipaltiRequired") {
    } else if (testActionType === "PPOnboarding") {
    } else if (testActionType === "VaccineRequired") {
    } else if (testActionType === "Booked" || testActionType === "Declined") {
    }
    setDisabledBtnTillRes(false);

    // Here I should parse the selected Opportunity confirmation (Applied, Declined, Booked)
    if (setOppConfirmationStatus) {
      setOppConfirmationStatus(
        modal_event_id ? modal_event_id : op_url_param.event_id,
        statusStr
      );
    }
  };

  /**
   * Instant Book - Book click button handler
   * @param bypassLocationCheck
   * @returns
   */
  const bookClickHandler = async (bypassLocationCheck?: any) => {
    if (!bypassLocationCheck) {
      const event_latitude = Number(opportunityDetail?.ue_latitude);
      const event_longitude = Number(opportunityDetail?.ue_longitude);
      if (event_latitude && event_longitude) {
        // Show the Too Far Away warning if Location is enabled and the model is too far away from the selected location
        let { latitude, longitude }: any =
          await getUserCurrentPositionIfGranted();
        if (latitude && longitude) {
          const distance_miles = calculateDistance(
            latitude,
            longitude,
            opportunityDetail?.ue_latitude,
            opportunityDetail?.ue_longitude
          );
          if (distance_miles > 100) {
            setShowTooFarAwayWarning(true);
            return;
          }
        } else if (modelLatitude && modelLongitude) {
          const distance_miles = calculateDistance(
            modelLatitude,
            modelLongitude,
            opportunityDetail?.ue_latitude,
            opportunityDetail?.ue_longitude
          );
          if (distance_miles > 100) {
            setShowTooFarAwayWarning(true);
            return;
          }
        }
      }
    }

    // Singleday (+ Overnight) - just open the Open Assign Confirm modal
    // Multiday - check that we have at least one event selected, if not show an error message
    setMultidayNoShiftSelected(false);
    if (opportunityDate.type === "multiday") {
      if (multidaySelectedEventId && multidaySelectedEventId?.length) {
        setIsOpenAssignConfirm(true);
      } else {
        setMultidayNoShiftSelected(true);
      }
    } else {
      setIsOpenAssignConfirm(true);
    }
  };

  const closeUpgradeModal = () => {
    history.push("/EditProfile");
  };
  const oppInit = async () => {
    setPageLoading(true);
    let opportunity_detail;
    let opps;
    opps = await getOppFromCache(
      modal_event_id ? modal_event_id : op_url_param.event_id
    );
    if (opps?.success && opps?.data?.model_opportunities?.length) {
      opportunity_detail = opps;
    } else {
      if (!opps?.success) {
        if (opps?.data?.errorCode == unauthorizedErrorCode) {
          dispatch(authActions.logout());
          return;
        }
      }
      setPageLoading(true);
      setWaitingForApiResonse(true);
      opportunity_detail = await getModelOpportunities(
        modal_event_id ? modal_event_id : op_url_param.event_id
      );
    }

    if (
      opportunity_detail?.success &&
      opportunity_detail?.data?.model_opportunities?.length > 0
    ) {
      let event_details = opportunity_detail.data.model_opportunities[0];
      setOpportunityDetail(event_details);
      setAssignmentStatus(event_details.assignmentStatus);
      setTestActionType(event_details.groupActionItem.type);
      setWaitlistOnly(event_details.waitlist_only);
      if (event_details.isPartOfMultiday || event_details.isPartOfMultishift) {
        if (event_details.groupActionItem.type === "unlockAccountLevel") {
          setUpgradeAccountModalPopup({
            is_show: true,
            message: event_details.groupActionItem.text,
          });
        }
        let dates = event_details.scheduledDatesGroupPerDate;
        let all_date = Object.keys(dates).map((event_time: any) => {
          return {
            start_date: dates[event_time][0].start_date,
            start_time: dates[event_time][0].start_time,
            end_time: dates[event_time][0].end_time,
          };
        });
        setOpprtunityDate({ dates: all_date, type: "multiday" });
      } else if (!event_details.isInfluencer) {
        let dates = event_details.scheduledDates;
        let all_date = dates.map((sch_date: any) => {
          return {
            start_date: sch_date.ed_start_date,
            start_time: sch_date.ed_start_time,
            end_time: sch_date.ed_end_time,
          };
        });
        setOpprtunityDate({ dates: all_date, type: "not_influencer" });
        if (event_details.actionItem.type === "unlockAccountLevel") {
          setUpgradeAccountModalPopup({
            is_show: true,
            message: event_details.actionItem.text,
          });
        }

        // apply decline status for single day event
        if (
          event_details.groupActionItem.type === "showApplyDecline" &&
          event_details.actionItem.type === "showApplyDecline"
        ) {
          setTestActionType(event_details.groupActionItem.type);
        } else if (
          event_details.groupActionItem.type === "showApplyDecline" &&
          event_details.actionItem.type === "showDecline"
        ) {
          setTestActionType(event_details.actionItem.type);
        } else if (
          event_details.groupActionItem.type === "showApplyDecline" &&
          event_details.actionItem.type === "showApply"
        ) {
          setTestActionType(event_details.actionItem.type);
        } else if (
          event_details.groupActionItem.type === "showApplyDecline" &&
          event_details.actionItem.type === "showText"
        ) {
          setTestActionType(event_details.actionItem.type);
        }
      } else {
        setOpprtunityDate({
          dates: {
            start_date: event_details.ue_start_date,
            end_date: event_details.ue_end_date,
          },
          type: "influencer",
        });
      }
      let date_group = event_details?.scheduledDatesGroup;
      let estimated_payout = 0;
      let model_points = 0;
      let event_shift_count = 0;
      let selected_event_ids = [];
      let selected_event_data = [];
      let deselected_event_ids = [];
      let event_ids = [];
      for (let index = 0; index < date_group.length; index++) {
        let shift = date_group[index];
        if (shift.checked) {
          estimated_payout = estimated_payout + shift.estimated_payout;
          model_points = model_points + Number(shift.ue_model_points);
          event_shift_count = event_shift_count + 1;
          selected_event_ids.push(shift.event_id);
          selected_event_data.push({
            event_id: shift.event_id,
            estimated_payout: shift.estimated_payout,
            model_points: Number(shift.ue_model_points),
            datetime_str:
              shift.start_date +
              " " +
              shift.start_time +
              " - " +
              shift.end_time,
          });
          let a_checkbox: any = document.querySelector(
            `#event_${shift.event_id}`
          );
          if (a_checkbox) {
            a_checkbox.checked = true;
          }
        } else {
          deselected_event_ids.push(shift.event_id);
        }
        event_ids.push(shift.event_id);
      }

      setHasIntroVideo(await get("login_has_intro_video"));

      setModelLatitude(await get("login_model_latitude"));
      setModelLongitude(await get("login_model_longitude"));

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const refParam = urlParams.get("ref");

      if (refParam) {
        setRef(refParam);
      }

      const utmSource = urlParams.get("utm_source");
      if (utmSource) {
        saveOpportunityLinkSource(
          utmSource,
          modal_event_id ? modal_event_id : op_url_param.event_id
        );
      }
      setSelectedEventCount(event_shift_count);
      setMultidayEstimatedPayout(estimated_payout);
      setMultidayModelPoints(model_points);
      setMultidaySelectedEventId(selected_event_ids);
      setMultidaySelectedEventData(selected_event_data);
      setMultidayDeSelectedEventId(deselected_event_ids);
      setMultidayAllEventIds(event_ids);
    } else {
      showToastMessage(somethingWentWrongErrMsg);
    }
    setWaitingForApiResonse(false);
    setPageLoading(false);
    const checkSessionResult = await checkSession();
    if (!checkSessionResult?.success) {
      dispatch(authActions.logout());
    }

    trackOppView(modal_event_id ? modal_event_id : op_url_param.event_id);
  };
  const multishiftEventHandler = async (e: any) => {
    let model_selection = e.detail.value;
    let newSelectedEventIds = [];
    let newDeSelectedEventIds = [];
    let newSelectedEventData: any[] = [];

    // Checked scenario
    if (e.detail.checked) {
      // Prevent adding an Opportunity ID that is already in the list of checked Event IDs
      // Since onIonChange event triggers multiple times when checking a checkbox
      if (!multidaySelectedEventId.includes(model_selection.event_id)) {
        newSelectedEventIds = [
          ...multidaySelectedEventId,
          model_selection.event_id,
        ];
        newSelectedEventData = [
          ...multidaySelectedEventData,
          {
            event_id: model_selection.event_id,
            estimated_payout: model_selection.estimated_payout,
            model_points: Number(model_selection.ue_model_points),
            datetime_str:
              model_selection.start_date +
              " " +
              model_selection.start_time +
              " - " +
              model_selection.end_time,
          },
        ];
      } else {
        newSelectedEventIds = [...multidaySelectedEventId];
        newSelectedEventData = [...multidaySelectedEventData];
      }

      // Remove the Opportunity from the unchecked list
      newDeSelectedEventIds = multidayDeSelectedEventId.filter(function (
        obj: any
      ) {
        return obj !== model_selection.event_id;
      });
    } else {
      // Remove the Opportunity from the checked list
      newSelectedEventIds = multidaySelectedEventId.filter(function (obj: any) {
        return obj !== model_selection.event_id;
      });

      newSelectedEventData = multidaySelectedEventData.filter(function (
        obj: any
      ) {
        return obj.event_id !== model_selection.event_id;
      });

      // Prevent adding an Opportunity ID that is already in the list of un-checked Event IDs
      // Since onIonChange event triggers multiple times when checking a checkbox
      if (!multidayDeSelectedEventId.includes(model_selection.event_id)) {
        newDeSelectedEventIds = [
          ...multidayDeSelectedEventId,
          model_selection.event_id,
        ];
      } else {
        newDeSelectedEventIds = [...multidayDeSelectedEventId];
      }
    }

    setMultidaySelectedEventId(newSelectedEventIds);
    setMultidaySelectedEventData(newSelectedEventData);
    setMultidayDeSelectedEventId(newDeSelectedEventIds);
    // Estimated Payout
    setMultidayModelPoints(
      newSelectedEventData.reduce(
        (acc: any, obj: any) => acc + Number(obj.model_points),
        0
      )
    );
    setMultidayEstimatedPayout(
      newSelectedEventData.reduce(
        (acc: any, obj: any) => acc + obj.estimated_payout,
        0
      )
    );
    // Count checked Opportunities
    setSelectedEventCount(newSelectedEventData.length);
  };
  const closeNewInernalModal = () => {
    setOpenInternalReferral(false);
  };
  const openNewInternalModal = () => {
    setOpenInternalReferral(true);
  };

  const closeAssignConfirm = async (param: string = "") => {
    setIsOpenAssignConfirm(false);
    await oppInit();
  };

  const closeTooFarAwayWarningModal = async (openConfirm?: any) => {
    setShowTooFarAwayWarning(false);
    if (openConfirm) {
      bookClickHandler(true);
    }
  };

  /**
   * My Contacts - Create a list of contacts, grouped by their starting letters
   * @param contacts
   */
  const shareReferralLink = async (link?: any) => {
    try {
      // Specify the link, text, and title for sharing
      const shareOptions = {
        dialogTitle: "Invite Friends to Join PUSH",
        title: "Join PUSH Agency!",
        text:
          "Looking for part-time gigs in the " +
          (opportunityDetail?.ue_nearest_city
            ? opportunityDetail?.ue_nearest_city + " "
            : "") +
          "area? Join the PUSH promo network today to gain access to fun & flexible opportunities, rep awesome brands and earn rewards!",
        url: link,
      };
      // Call the Capacitor Share plugin to share the specified content
      await Share.share(shareOptions);
    } catch (error: any) {
      if (error?.message != "Share canceled") {
        await logError(
          "View Opportunity - shareReferralLink",
          "Talent App - View Opportunity - Share Referral Link",
          JSON.stringify(error.message) + " Link: " + link
        );
        showToastMessage(somethingWentWrongErrMsg);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await oppInit();
    })();
  }, [modal_event_id ? modal_event_id : op_url_param.event_id]);

  return (
    <IonPage>
      {pageLoading && (
        <div className="slider-card_spinner">
          <IonSpinner color="primary" name="crescent" />
        </div>
      )}
      {!pageLoading && (
        <IonContent
          className={
            opportunityDetail?.event_display_status == "No longer available"
              ? "opportunity-view--disabled"
              : ""
          }
        >
          {opportunityDetail?.ue_event_type != "Virtual Casting" ? (
            /* Opportunity Layout*/
            <>
              <IonHeader class="ion-no-border opportunity-view__header">
                <img
                  src={
                    opportunityDetail.bannerPath == 0
                      ? event_placeholder
                      : opportunityDetail.bannerPath
                  }
                  alt=""
                />
                <IonToolbar
                  mode="ios"
                  color="translucent"
                  className="opportunity-view__header__top-toolbar"
                >
                  <IonButtons slot="start">
                    {modal_event_id && closeOppViewModal ? (
                      <IonButton
                        className="opportunity-view__header__toolbar__back-btn"
                        onClick={() => {
                          closeOppViewModal();
                        }}
                      >
                        <IonIcon slot="icon-only" icon={arrowBackOutline} />
                      </IonButton>
                    ) : (
                      <IonBackButton
                        defaultHref="/"
                        mode="md"
                        className="opportunity-view__header__toolbar__back-btn"
                      />
                    )}
                  </IonButtons>

                  {opportunityDetail?.event_display_status !==
                    "No longer available" && (
                    <IonButton
                      fill="clear"
                      mode="ios"
                      slot="end"
                      className="opportunity-view__header__menu-btn"
                      onClick={openNewInternalModal}
                    >
                      <img src={menuIcon} alt="" />
                    </IonButton>
                  )}
                </IonToolbar>
                <IonToolbar
                  mode="ios"
                  color="translucent"
                  className="opportunity-view__header__bottom-toolbar"
                >
                  {waitlistOnly && (
                    <IonBadge className="opportunity-view__header__waitlist-badge">
                      WAITLIST
                    </IonBadge>
                  )}
                  {opportunityDetail?.autoBookEnabled && (
                    <IonBadge className="auto-book-placeholder">
                      <img src={instantBookIcon} /> <span>Instant Book</span>
                    </IonBadge>
                  )}
                </IonToolbar>
              </IonHeader>
              <div className="opportunity-view-content">
                <div className="assignment-view__event opportunity-view-content__content--margin">
                  <div className="opportunity-view__event__booked">
                    <IonBadge>
                      <span
                        style={{
                          backgroundColor: `${opportunityDetail.event_display_color}`,
                        }}
                      ></span>
                      {opportunityDetail.event_display_status}
                    </IonBadge>
                    {opportunityDetail?.modelAccountLevelRequired &&
                      Object.values(
                        opportunityDetail.modelAccountLevelRequired
                      )?.map((item: any, index: number) => (
                        <IonBadge key={index}>{item}</IonBadge>
                      ))}
                  </div>
                  <div className="opportunity-view__event__name">
                    <h2>{opportunityDetail.ue_name}</h2>
                  </div>
                  <IonItem className="assignment-view__event__status">
                    <img
                      slot="start"
                      src={opportunityDetail?.customer_image_url}
                      alt=""
                    />
                    <IonText slot="start">
                      {opportunityDetail?.customer_company}
                    </IonText>
                  </IonItem>
                </div>
                <div className="opportunity-view__notice opportunity-view-content__content--margin">
                  {opportunityDate.type === "multiday" &&
                    opportunityDate.dates.map(
                      (event_time: any, index: number) => {
                        return (
                          <IonItem
                            className="opportunity-view__notice__timing"
                            key={index}
                          >
                            <img slot="start" alt="" src={CalendarIcon} />
                            <div>
                              <IonLabel className="opportunity-view__notice__timing__date">
                                {event_time.start_date}
                              </IonLabel>
                              <IonLabel className="opportunity-view__notice__timing__time">
                                {event_time.start_time} - {event_time.end_time}
                              </IonLabel>
                            </div>
                          </IonItem>
                        );
                      }
                    )}

                  {opportunityDate.type === "not_influencer" &&
                    opportunityDate.dates.map(
                      (sch_date: any, index: number) => {
                        return (
                          <IonItem
                            className="opportunity-view__notice__timing"
                            key={index}
                          >
                            <img slot="start" alt="" src={CalendarIcon} />
                            <div>
                              <IonLabel className="opportunity-view__notice__timing__date">
                                {sch_date.start_date}
                              </IonLabel>
                              <IonLabel className="opportunity-view__notice__timing__time">
                                {sch_date.start_time} - {sch_date.end_time}
                              </IonLabel>
                            </div>
                          </IonItem>
                        );
                      }
                    )}

                  {opportunityDate.type === "influencer" && (
                    <IonItem className="opportunity-view__notice__timing">
                      <img slot="start" alt="" src={CalendarIcon} />
                      <div>
                        <IonLabel className="opportunity-view__notice__timing__date">
                          {opportunityDate.dates.start_date} -{" "}
                          {opportunityDate.dates.end_date}
                        </IonLabel>
                      </div>
                    </IonItem>
                  )}
                  <IonItem className="opportunity-view__notice__timing">
                    {opportunityDetail?.ue_worked_hours_points_multiplier ==
                    2 ? (
                      <>
                        <img
                          className="opportunity-view__notice__timing__points-multiplied"
                          alt=""
                          slot="start"
                          src={DoublePointsIcon}
                        />
                      </>
                    ) : (
                      <>
                        {opportunityDetail?.ue_worked_hours_points_multiplier ==
                        3 ? (
                          <>
                            <img
                              className="opportunity-view__notice__timing__points-multiplied"
                              alt=""
                              slot="start"
                              src={TriplePointsIcon}
                            />
                          </>
                        ) : (
                          <>
                            <img alt="" slot="start" src={DollarSignIcon} />
                          </>
                        )}
                      </>
                    )}

                    <div>
                      <IonLabel className="opportunity-view__notice__timing__date">
                        Estimated Total Pay
                      </IonLabel>
                      <IonLabel className="opportunity-view__notice__timing__pay">
                        {opportunityDetail?.estPayoutStr}{" "}
                        {opportunityDetail?.estPointsStr && (
                          <span
                            className={
                              opportunityDetail?.ue_worked_hours_points_multiplier &&
                              opportunityDetail?.ue_worked_hours_points_multiplier >
                                1
                                ? "points-background"
                                : ""
                            }
                          >
                            {opportunityDetail?.estPointsStr}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonItem>
                </div>
                <div className="assignment-view__location opportunity-view-content__content--margin">
                  <IonItem>
                    <IonTitle slot="start">Location</IonTitle>
                  </IonItem>
                  {opportunityDetail && (
                    <>
                      <div className="assignment-view__location__add">
                        <IonText>{opportunityDetail?.ue_venue} </IonText>
                        <IonText>{`${opportunityDetail?.ue_nearest_city}, ${opportunityDetail?.ue_state} ${opportunityDetail?.ue_zipcode}`}</IonText>
                      </div>
                    </>
                  )}
                </div>

                {opportunityDetail?.ue_event_type && (
                  <div className="opportunity-view__types opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Event Type</IonTitle>
                    </IonItem>
                    <IonItem>
                      <IonBadge style={{ backgroundColor: "#FE6102" }}>
                        {opportunityDetail?.ue_event_type}
                      </IonBadge>
                    </IonItem>
                  </div>
                )}
                {opportunityDetail?.ue_talent_role && (
                  <div className="opportunity-view__requrement opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">What you'll be doing</IonTitle>
                    </IonItem>
                    <IonText>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: opportunityDetail.ue_talent_role,
                        }}
                      ></div>
                    </IonText>
                  </div>
                )}
                {opportunityDetail?.attire_html && (
                  <div className="opportunity-view__requrement opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Attire Requirements</IonTitle>
                    </IonItem>
                    <IonText>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: opportunityDetail.attire_html,
                        }}
                      ></div>
                    </IonText>
                  </div>
                )}
                {opportunityDetail?.requirements_html && (
                  <div className="opportunity-view__requrement opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Requirements</IonTitle>
                    </IonItem>
                    <IonText>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: opportunityDetail.requirements_html,
                        }}
                      ></div>
                    </IonText>
                  </div>
                )}
                {opportunityDate.type === "multiday" && (
                  <div className="opportunity-view__requrement opportunity-view__shift opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Shifts</IonTitle>
                    </IonItem>
                    <IonText>Select the shifts you would like to work</IonText>

                    <IonItem>
                      <IonTitle className="opportunity-view__shift__est-total">
                        Est. {selectedEventCount}/
                        {opportunityDetail.scheduledDatesGroup.length} shifts =
                        ${multidayEstimatedPayout}{" "}
                        <span
                          className={
                            opportunityDetail?.ue_worked_hours_points_multiplier &&
                            opportunityDetail?.ue_worked_hours_points_multiplier >
                              1
                              ? "points-background"
                              : ""
                          }
                        >
                          + {multidayModelPoints} point
                          {multidayModelPoints != 1 ? "s" : ""}
                        </span>
                      </IonTitle>
                    </IonItem>
                    <IonGrid className="opportunity-view__shift__grid">
                      {opportunityDetail.scheduledDatesGroup.length > 0 &&
                        opportunityDetail.scheduledDatesGroup.map(
                          (date: any, index: number) => (
                            <IonRow key={date.event_id}>
                              <IonCol size="1">
                                <IonCheckbox
                                  key={date.event_id}
                                  mode="md"
                                  className="form-group__checkbox-styles"
                                  id={`event_${date.event_id}`}
                                  disabled={date.disabled}
                                  value={date}
                                  onIonChange={(e: any) =>
                                    multishiftEventHandler(e)
                                  }
                                  checked={multidaySelectedEventId.includes(
                                    date.event_id
                                  )}
                                />
                              </IonCol>
                              <IonCol
                                size="4"
                                className="opportunity-view__shift__date"
                              >
                                <IonLabel>{date.start_date}</IonLabel>
                              </IonCol>
                              <IonCol
                                size="7"
                                className="opportunity-view__shift__hr-rate"
                              >
                                <IonBadge>
                                  <IonLabel>
                                    {date.start_time} - {date.end_time}{" "}
                                    {date.rateText}
                                  </IonLabel>
                                </IonBadge>
                              </IonCol>
                            </IonRow>
                          )
                        )}
                      {multidayNoShiftSelected && (
                        <IonRow key={-1}>
                          <IonCol size="12">
                            <div className="selections-required">
                              * Selections Required
                            </div>
                          </IonCol>
                        </IonRow>
                      )}
                    </IonGrid>
                  </div>
                )}

                {opportunityDetail.groupStatusText !== "" && (
                  <div className="opportunity-view__additional-info opportunity-view-content__content--margin">
                    <div className="opportunity-view__shift__decline-info">
                      <img alt="" src={warningIcon} />
                      <IonText>
                        {opportunityDetail?.groupStatusText?.replace(
                          "<br />",
                          " "
                        )}
                      </IonText>
                    </div>
                    {opportunityDate.type === "multiday" && (
                      <div className="opportunity-view__shift__decline-info">
                        <img src={redX}></img>
                        <IonText>
                          To decline this opportunity, press 'Decline' without
                          selecting any shifts
                        </IonText>
                      </div>
                    )}

                    {waitlistOnly && (
                      <div className="opportunity-view__shift__decline-info">
                        <img src={waitlistIcon}></img>
                        <IonText>
                          This opportunity is currently filled and is now
                          accepting applicants to the waitlist. Apply to the
                          waitlist to let a Booking Agent know that you are
                          interested in working if a position becomes available.
                        </IonText>
                      </div>
                    )}
                    {opportunityDetail?.autoBookEnabled && (
                      <div className="orange-border">
                        <div className="orange-border__content">
                          <span>
                            This is an <strong>Instant Book</strong> opportunity.
                            To officially book yourself for this gig, select
                            'Book'
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {testActionType === "takeTest" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={applyOpportunityHandler}
                      className="opportunity-view__accpects_apply"
                    >
                      Take Survey
                    </IonButton>
                  </div>
                )}
                {testActionType === "premiumOnly" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    Premium models only!
                  </div>
                )}
                {testActionType === "showApplyDecline" &&
                  opportunityDate.type === "multiday" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: multidayAllEventIds,
                            status: "Declined",
                          })
                        }
                        disabled={
                          disabledBtnTillRes || multidaySelectedEventId.length
                        }
                      >
                        Decline
                      </IonButton>
                      {opportunityDetail.autoBookEnabled ? (
                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() => bookClickHandler()}
                          disabled={disabledBtnTillRes}
                        >
                          Book
                        </IonButton>
                      ) : (
                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={applyOpportunityHandler}
                          className="opportunity-view__accpects_apply"
                          disabled={disabledBtnTillRes}
                        >
                          Apply
                        </IonButton>
                      )}
                    </div>
                  )}
                {testActionType === "showApplyDecline" &&
                  opportunityDate.type === "not_influencer" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: [opportunityDetail.ue_id],
                            status: "Declined",
                          })
                        }
                        disabled={disabledBtnTillRes}
                      >
                        Decline
                      </IonButton>
                      {/* Open Auto book confirmation popup */}
                      {opportunityDetail?.autoBookEnabled ? (
                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() => bookClickHandler()}
                          disabled={disabledBtnTillRes}
                        >
                          Book
                        </IonButton>
                      ) : (
                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() =>
                            applyOpportunityHandler({
                              ids: [opportunityDetail.ue_id],
                              status: "Pending",
                            })
                          }
                          disabled={disabledBtnTillRes}
                        >
                          Apply
                        </IonButton>
                      )}
                    </div>
                  )}
                {testActionType === "showDecline" &&
                  opportunityDate.type === "not_influencer" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: [opportunityDetail.ue_id],
                            status: "Declined",
                          })
                        }
                        disabled={disabledBtnTillRes}
                      >
                        Decline
                      </IonButton>
                      <IonButton
                        mode="ios"
                        color="primary"
                        size="large"
                        disabled={true}
                      >
                        Apply
                      </IonButton>
                    </div>
                  )}
                {testActionType === "showApply" &&
                  opportunityDate.type === "not_influencer" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        disabled={true}
                      >
                        Decline
                      </IonButton>
                      {opportunityDetail?.autoBookEnabled ? (
                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() => bookClickHandler()}
                          disabled={disabledBtnTillRes}
                        >
                          Book
                        </IonButton>
                      ) : (
                        <IonButton
                          mode="ios"
                          color="primary"
                          size="large"
                          onClick={() =>
                            applyOpportunityHandler({
                              ids: [opportunityDetail.ue_id],
                              status: "Pending",
                            })
                          }
                          disabled={disabledBtnTillRes}
                        >
                          Apply
                        </IonButton>
                      )}
                    </div>
                  )}
                {testActionType === "influencerOnly" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={applyOpportunityHandler}
                      className="opportunity-view__accpects_apply"
                      fill="outline"
                    >
                      <img
                        src={opportunityDetail?.groupActionItem.logo}
                        width="100"
                        alt=""
                      />
                    </IonButton>
                  </div>
                )}
                {testActionType === "W2required" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setIsopenW2Form(true)}
                      className="opportunity-view__accpects_apply"
                    >
                      Paperwork Required
                    </IonButton>
                  </div>
                )}
                {testActionType === "tipaltiRequired" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => history.push("/PaymentDetails")}
                      className="opportunity-view__accpects_apply"
                    >
                      Payment Paperwork Required
                    </IonButton>
                  </div>
                )}
                {testActionType === "PPOnboarding" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setW2OnboardingPopup(true)}
                      className="opportunity-view__accpects_apply"
                    >
                      {opportunityDetail?.groupActionItem.text}
                    </IonButton>
                  </div>
                )}
                {testActionType === "VaccineRequired" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setIsopenAddVaccine(true)}
                      className="opportunity-view__accpects_apply"
                    >
                      {opportunityDetail?.groupActionItem.text}
                    </IonButton>
                  </div>
                )}
                {(testActionType === "Booked" ||
                  testActionType === "Declined") && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    {opportunityDetail?.groupActionItem.text}
                  </div>
                )}
                {testActionType === "showText" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    {opportunityDetail?.groupActionItem.text}
                  </div>
                )}

                <div className="opportunity-view-content__content--margin">
                  <SlidingCardWidget
                    slider_card_title="Additional Gigs"
                    list_route="Opportunities"
                    widget_type="nearby_opportunities"
                    no_data_text="You have no opportunities yet"
                    current_event_id={
                      oppViewModalEventIDs?.length
                        ? oppViewModalEventIDs
                        : modal_event_id
                        ? modal_event_id
                        : op_url_param.event_id
                    }
                    openOppViewModal={openOppViewModal}
                    additional_opps_widget={true}
                  />
                </div>
                {modal_event_id && (
                  <IonTabBar slot="bottom">
                    <IonTabButton href="/Home" tab="home">
                      <IonIcon icon={home} />
                    </IonTabButton>

                    <IonTabButton tab="calendar" href="/MyCalendar">
                      <IonIcon icon={calendarClearOutline} />
                    </IonTabButton>

                    <IonTabButton tab="star" href="/MyScoreboard">
                      <IonIcon icon={starOutline} />
                    </IonTabButton>
                    <IonTabButton tab="payments" href="/Payments">
                      <IonIcon icon={logoUsd} />
                    </IonTabButton>

                    <IonTabButton href="/MyAccount" tab="my-account">
                      <IonIcon icon={personCircle} />
                    </IonTabButton>
                  </IonTabBar>
                )}
              </div>
            </>
          ) : (
            /* Virtual Casting Layout*/
            <>
              <IonHeader class="ion-no-border opportunity-view__header">
                <IonSlides
                  pager={opportunityDetail?.ue_details_photos?.length > 1}
                  ref={ionSlidesRef}
                  options={
                    opportunityDetail?.ue_details_photos?.length > 1
                      ? {
                          direction: "horizontal",
                          slidesPerView: 1,
                          speed: 400,
                          spaceBetween: 15,
                          autoplay: {
                            delay: 3000,
                          },
                        }
                      : {
                          direction: "horizontal",
                          slidesPerView: 1,
                          speed: 400,
                          allowSlidePrev: false,
                          allowSlideNext: false,
                        }
                  }
                  className="opportunity-view__header__photos-slider"
                >
                  {opportunityDetail?.ue_details_photos?.map(
                    (ue_detail_photo: any, index: number) => (
                      <IonSlide>
                        <img src={ue_detail_photo} alt="" />
                      </IonSlide>
                    )
                  )}
                </IonSlides>
                <IonToolbar
                  mode="ios"
                  color="translucent"
                  className="opportunity-view__header__top-toolbar"
                >
                  <IonButtons slot="start">
                    {modal_event_id && closeOppViewModal ? (
                      <IonButton
                        className="opportunity-view__header__toolbar__back-btn opportunity-view__header__toolbar__back-btn--virtual-casting"
                        onClick={() => {
                          closeOppViewModal();
                        }}
                      >
                        <IonIcon slot="icon-only" icon={arrowBackOutline} />
                      </IonButton>
                    ) : (
                      <IonBackButton
                        defaultHref="/"
                        mode="md"
                        className="opportunity-view__header__toolbar__back-btn opportunity-view__header__toolbar__back-btn--virtual-casting"
                      />
                    )}
                  </IonButtons>

                  {opportunityDetail?.event_display_status !==
                    "No longer available" && (
                    <IonButton
                      fill="clear"
                      mode="ios"
                      slot="end"
                      className="opportunity-view__header__menu-btn opportunity-view__header__menu-btn--virtual-casting"
                      onClick={() => shareReferralLink(opportunityDetail?.referral_link)}
                    >
                      <img src={menuIcon} alt="" />
                    </IonButton>
                  )}
                </IonToolbar>
                <IonToolbar
                  mode="ios"
                  color="translucent"
                  className="opportunity-view__header__bottom-toolbar"
                >
                  {waitlistOnly && (
                    <IonBadge className="opportunity-view__header__waitlist-badge">
                      WAITLIST
                    </IonBadge>
                  )}
                  {opportunityDetail?.autoBookEnabled && (
                    <IonBadge className="auto-book-placeholder">
                      <img src={instantBookIcon} /> <span>Instant Book</span>
                    </IonBadge>
                  )}
                </IonToolbar>
              </IonHeader>
              <div className="opportunity-view-content">
                <div className="assignment-view__event opportunity-view-content__content--margin">
                  <div className="opportunity-view__event__booked">
                    <IonBadge>
                      <span
                        style={{
                          backgroundColor: `${opportunityDetail.event_display_color}`,
                        }}
                      ></span>
                      {opportunityDetail.event_display_status}
                    </IonBadge>
                    {opportunityDetail?.modelAccountLevelRequired &&
                      Object.values(
                        opportunityDetail.modelAccountLevelRequired
                      )?.map((item: any, index: number) => (
                        <IonBadge key={index}>{item}</IonBadge>
                      ))}
                  </div>
                  <div className="opportunity-view__event__name">
                    <h2>{opportunityDetail.ue_name}</h2>
                  </div>
                  <IonItem className="assignment-view__event__status">
                    <img
                      slot="start"
                      src={opportunityDetail?.customer_image_url}
                      alt=""
                    />
                    <IonText slot="start">
                      {opportunityDetail?.customer_company}
                    </IonText>
                  </IonItem>
                </div>
                <div className="opportunity-view__notice opportunity-view-content__content--margin">
                  <IonItem className="opportunity-view__notice__timing">
                    <img slot="start" alt="" src={CalendarIcon} />
                    <div>
                      <IonLabel className="opportunity-view__notice__timing__date">
                        Estimated Start Date
                      </IonLabel>
                      <IonLabel>{opportunityDetail?.ue_start_date}</IonLabel>
                    </div>
                  </IonItem>
                  <IonItem className="opportunity-view__notice__timing">
                    <>
                      <img alt="" slot="start" src={DollarSignIcon} />
                    </>
                    <div>
                      <IonLabel className="opportunity-view__notice__timing__date">
                        Estimated Pay
                      </IonLabel>
                      <div className="opportunity-view__notice__timing__pay html-editor-field">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: opportunityDetail?.estPayoutStr,
                          }}
                        ></span>
                      </div>
                    </div>
                  </IonItem>

                  <div className="gray-border">
                    <div className="gray-border__content">
                      <span>
                        Invite friends to apply & earn{" "}
                        <strong>
                          {opportunityDetail?.ue_internal_referral_points}{" "}
                          points
                        </strong>{" "}
                        if they are selected!
                      </span>
                    </div>

                    <p className="gray-border__content"></p>
                    <div className="gray-border__content opportunity-view__referral-buttons">
                      {opportunityDetail?.referral_link && (
                        <IonButton
                          mode="ios"
                          color="dark"
                          strong={false}
                          size="large"
                          onClick={() =>
                            shareReferralLink(opportunityDetail?.referral_link)
                          }
                        >
                          SHARE MY REFERRAL LINK
                        </IonButton>
                      )}
                      <IonButton
                        mode="ios"
                        color="dark"
                        strong={false}
                        size="large"
                        fill="clear"
                        onClick={openExternalReferralModal}
                      >
                        INVITE CONTACTS
                      </IonButton>
                    </div>
                  </div>
                </div>
                <div className="assignment-view__location opportunity-view-content__content--margin">
                  <IonItem>
                    <IonTitle slot="start">Location</IonTitle>
                  </IonItem>
                  {opportunityDetail && (
                    <>
                      <div className="assignment-view__location__add">
                        <IonText>{`${opportunityDetail?.ue_nearest_city}, ${opportunityDetail?.ue_state}`}</IonText>
                      </div>
                    </>
                  )}
                </div>

                {opportunityDetail?.ue_event_types?.length && (
                  <div className="opportunity-view__types opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Program Type</IonTitle>
                    </IonItem>
                    <div className="opportunity-view__types__container">
                      {opportunityDetail?.ue_event_types.map(
                        (event_type: any, index: number) => (
                          <IonBadge style={{ backgroundColor: "#FE6102" }}>
                            {event_type}
                          </IonBadge>
                        )
                      )}
                    </div>
                  </div>
                )}
                {opportunityDetail?.ue_talent_role && (
                  <div className="opportunity-view__requrement opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Program Details</IonTitle>
                    </IonItem>
                    <IonText>
                      <div
                        className="html-editor-field"
                        dangerouslySetInnerHTML={{
                          __html: opportunityDetail.ue_talent_role,
                        }}
                      ></div>
                    </IonText>
                  </div>
                )}
                {opportunityDetail?.requirements_html && (
                  <div className="opportunity-view__requrement opportunity-view-content__content--margin">
                    <IonItem>
                      <IonTitle slot="start">Requirements</IonTitle>
                    </IonItem>
                    <IonText>
                      <div
                        className="html-editor-field"
                        dangerouslySetInnerHTML={{
                          __html: opportunityDetail.requirements_html,
                        }}
                      ></div>
                    </IonText>
                  </div>
                )}

                {opportunityDetail.groupStatusText !== "" && (
                  <div className="opportunity-view__additional-info opportunity-view-content__content--margin">
                    <div className="opportunity-view__shift__decline-info">
                      <img alt="" src={warningIcon} />
                      <IonText>
                        {opportunityDetail?.groupStatusText?.replace(
                          "<br />",
                          " "
                        )}
                      </IonText>
                    </div>
                    {testActionType === "takeTest" && (
                      <div className="orange-border">
                        <div className="orange-border__content">
                          <span>
                            To sign up for this casting call, you must complete
                            a questionnaire. <br /> Select{" "}
                            <strong>Apply</strong> to proceed
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {testActionType === "takeTest" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      size="large"
                      className="opportunity-view__accpects_decline"
                      onClick={() =>
                        applyOpportunityHandler({
                          ids: [opportunityDetail.ue_id],
                          status: "Declined",
                        })
                      }
                      disabled={disabledBtnTillRes}
                    >
                      Decline
                    </IonButton>
                    {/* Open Auto book confirmation popup */}

                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() =>
                        applyOpportunityHandler({
                          ids: [opportunityDetail.ue_id],
                          status: "Pending",
                        })
                      }
                      disabled={disabledBtnTillRes}
                    >
                      Apply
                    </IonButton>
                  </div>
                )}

                {testActionType === "showApplyDecline" &&
                  opportunityDate.type === "not_influencer" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: [opportunityDetail.ue_id],
                            status: "Declined",
                          })
                        }
                        disabled={disabledBtnTillRes}
                      >
                        Decline
                      </IonButton>
                      {/* Open Auto book confirmation popup */}

                      <IonButton
                        mode="ios"
                        color="primary"
                        size="large"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: [opportunityDetail.ue_id],
                            status: "Pending",
                          })
                        }
                        disabled={disabledBtnTillRes}
                      >
                        Apply
                      </IonButton>
                    </div>
                  )}
                {testActionType === "showDecline" &&
                  opportunityDate.type === "not_influencer" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: [opportunityDetail.ue_id],
                            status: "Declined",
                          })
                        }
                        disabled={disabledBtnTillRes}
                      >
                        Decline
                      </IonButton>
                      <IonButton
                        mode="ios"
                        color="primary"
                        size="large"
                        disabled={true}
                      >
                        Apply
                      </IonButton>
                    </div>
                  )}
                {testActionType === "showApply" &&
                  opportunityDate.type === "not_influencer" && (
                    <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                      <IonButton
                        mode="ios"
                        size="large"
                        className="opportunity-view__accpects_decline"
                        disabled={true}
                      >
                        Decline
                      </IonButton>
                      <IonButton
                        mode="ios"
                        color="primary"
                        size="large"
                        onClick={() =>
                          applyOpportunityHandler({
                            ids: [opportunityDetail.ue_id],
                            status: "Pending",
                          })
                        }
                        disabled={disabledBtnTillRes}
                      >
                        Apply
                      </IonButton>
                    </div>
                  )}
                {testActionType === "W2required" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setIsopenW2Form(true)}
                      className="opportunity-view__accpects_apply"
                    >
                      Paperwork Required
                    </IonButton>
                  </div>
                )}
                {testActionType === "tipaltiRequired" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => history.push("/PaymentDetails")}
                      className="opportunity-view__accpects_apply"
                    >
                      Payment Paperwork Required
                    </IonButton>
                  </div>
                )}
                {testActionType === "PPOnboarding" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setW2OnboardingPopup(true)}
                      className="opportunity-view__accpects_apply"
                    >
                      {opportunityDetail?.groupActionItem.text}
                    </IonButton>
                  </div>
                )}
                {testActionType === "VaccineRequired" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    <IonButton
                      mode="ios"
                      color="primary"
                      size="large"
                      onClick={() => setIsopenAddVaccine(true)}
                      className="opportunity-view__accpects_apply"
                    >
                      {opportunityDetail?.groupActionItem.text}
                    </IonButton>
                  </div>
                )}
                {(testActionType === "Booked" ||
                  testActionType === "Declined") && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    {opportunityDetail?.groupActionItem.text}
                  </div>
                )}
                {testActionType === "showText" && (
                  <div className="opportunity-view__accpets opportunity-view-content__content--margin">
                    {opportunityDetail?.groupActionItem.text}
                  </div>
                )}

                <div className="opportunity-view-content__content--margin">
                  <SlidingCardWidget
                    slider_card_title="Additional Gigs"
                    list_route="Opportunities"
                    widget_type="nearby_opportunities"
                    no_data_text="You have no opportunities yet"
                    current_event_id={
                      oppViewModalEventIDs?.length
                        ? oppViewModalEventIDs
                        : modal_event_id
                        ? modal_event_id
                        : op_url_param.event_id
                    }
                    openOppViewModal={openOppViewModal}
                    additional_opps_widget={true}
                  />
                </div>
                {modal_event_id && (
                  <IonTabBar slot="bottom">
                    <IonTabButton href="/Home" tab="home">
                      <IonIcon icon={home} />
                    </IonTabButton>

                    <IonTabButton tab="calendar" href="/MyCalendar">
                      <IonIcon icon={calendarClearOutline} />
                    </IonTabButton>

                    <IonTabButton tab="star" href="/MyScoreboard">
                      <IonIcon icon={starOutline} />
                    </IonTabButton>
                    <IonTabButton tab="payments" href="/Payments">
                      <IonIcon icon={logoUsd} />
                    </IonTabButton>

                    <IonTabButton href="/MyAccount" tab="my-account">
                      <IonIcon icon={personCircle} />
                    </IonTabButton>
                  </IonTabBar>
                )}
              </div>
            </>
          )}
        </IonContent>
      )}

      <SmallModal
        isOpen={upgradeAccountModalPopup.is_show}
        closeAction={closeUpgradeModal}
        description={upgradeAccountModalPopup.message}
        buttonText="Upgrade Now"
        logo={locked_icon}
      />
      <Modal
        isOpen={w2OnboardingPopup}
        closeAction={() => {
          setW2OnboardingPopup(false);
        }}
        description={`We've sent your information over to our payroll partner, Payreel! Look for an email
        within the next business day with the subject "Welcome to Payreel."`}
        buttonText="Close"
      />
      <W2Form isOpen_w2_form={isOpenW2Form} closeW2Form={closeW2Form} />
      <AddVaccination
        isOpen_add_vaccine={isOpenAddVaccine}
        closeAddVaccine={closeAddVaccine}
      />
      <Loading showLoading={waitingForApiResonse} />
      {isOpenTestConfirmModal && (
        <ApplicationConfirmation
          isopen_ac_modal={isOpenTestConfirmModal}
          closeAcModal={closeTestConfirmModal}
          event_name={opportunityDetail?.ue_name}
          event_id={modal_event_id ? modal_event_id : op_url_param.event_id}
          openNewInternalModal={openNewInternalModal}
          autoBookRedirectToAssignmentId={autoBookRedirectToAssignmentId}
          showIntroVideoAnnouncement={!hasIntroVideo}
          event_application_confirmation_photo={
            opportunityDetail?.application_confirmation_photo
          }
          event_referral_link={
            opportunityDetail?.referral_link
          }
          event_city={
            opportunityDetail?.ue_nearest_city
          }
          openExternalReferralModal = {openExternalReferralModal}
        />
      )}
      {showTooFarAwayWarning && (
        <TooFarAwayWarning
          isOpen={showTooFarAwayWarning}
          closeModalHandler={closeTooFarAwayWarningModal}
        />
      )}
      {openInternalReferral && (
        <NewInternalTalentReferrals
          is_open_new_internal_referral={openInternalReferral}
          closeNewInernalModal={closeNewInernalModal}
          event_id={modal_event_id ? modal_event_id : op_url_param.event_id}
        />
      )}

      {isOpenAssignConfirm && (
        <AssignmentConfirmAutoBook
          isopen_assign_confirm={isOpenAssignConfirm}
          closeAssignConfirm={closeAssignConfirm}
          opportunityDetail={opportunityDetail}
          multidaySelectedEventData={multidaySelectedEventData}
          opportunityDate={opportunityDate}
          applyOpportunityHandler={applyOpportunityHandler}
        />
      )}

    {isOpenExternalReferralModal && (
        <ExternalTalentReferrals
          is_open_external_referral={isOpenExternalReferralModal}
          closeNewExternalModal={closeExternalReferralModal}
          knownReferralPoints={opportunityDetail?.ue_internal_referral_points}
          eventId={opportunityDetail?.ue_id}
          overrideDescription={"Invite friends to apply & earn "+ opportunityDetail?.ue_internal_referral_point+" points if they are selected!"}
        />
      )}
    </IonPage>
  );
};

export default OpportunitiesView;
